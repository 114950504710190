<template>
  <transition name="fade-transition" mode="out-in">
    <v-col v-if="showLoading" key="canvas_loading" class="tw-h-full">
      <div class="tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        />
      </div>
    </v-col>
    <template v-else>
      <transition name="fade-transition" mode="out-in">
        <router-view />
      </transition>
    </template>
  </transition>
</template>

<script>
export default {
  props: {
    id: {
      type: [Number, String],
      default: '',
    },
    accessControl: {
      type: [Number, String],
      default: '',
    },
  },
  computed: {
    showLoading() {
      return this.$store.state.registries.access_control.show.isLoading
    },
  },
  mounted() {
    console.log(this.id)
    console.log(this.accessControl)
    this.$store.dispatch('registries/accessControlShow', {
      id: this.accessControl,
      loading: true,
    })
  },
}
</script>

<style></style>
