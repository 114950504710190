// import { refreshAction, loginAction } from "../api/auth";
// import { initAdminConstants } from "@/api/constants";
import { ConstantsServiceInstance } from '@/services/ConstantsService'
import LicenseResource from '@/services/LicenseResource'
import { formErrorsHandler } from '@/utils/formErrorsHandler'
import { tenancy } from '@/utils/tenancy'
export const actions = {
  // from router beforeEach
  async initApp({ dispatch }) {
    await dispatch('loadApp')
  },
  async loadApp({ commit, dispatch, state }) {
    if (state.appLoaded) {
      return
    }
    const tenant = tenancy()
    commit('setGlobalState', { tenant })
    commit('setAppIsLoading', true)
    // const [data_card_token, data_order] =
    dispatch('language/initLocale')

    await Promise.all([
      dispatch('init').catch((err) => {
        console.log(err)
      }),
      dispatch('session/initUser').catch((err) => {
        console.log(err)
      }),
    ])
    commit('setGlobalState', { appReady: true })
    commit('setAppIsLoading', false)
  },
  async startApp() {},
  // async startApp({ commit, dispatch }) {
  //   commit("setAppIsLoading", true);
  //   // const [data_card_token, data_order] =
  //   await Promise.all([
  //     dispatch("init"),
  //     dispatch("session/initUser").catch((err) => {
  //       console.log(err);
  //     }),
  //   ]);
  //   commit("setAppIsLoading", false);
  // },

  // Start login and init user
  // initUser(context) {
  //   context.commit("authenticateCold");
  //   return refreshAction()
  //     .then((res) => {
  //       context.dispatch("login", res.data);
  //       return res;
  //     })
  //     .catch((err) => {
  //       throw window.errorsValidate(err);
  //     });
  // },
  // loginUser(context, body) {
  //   return loginAction(body)
  //     .then((res) => {
  //       return context.dispatch("login", res.data);
  //     })
  //     .catch((err) => {
  //       throw window.errorsValidate(err);
  //     });
  // },
  // // dispatch
  // async login({ dispatch, commit }, body) {
  //   commit("loginSuccess", body);

  //   commit("setAppIsLoading", true);
  //   await dispatch("initConstants");
  //   commit("setAppIsLoading", false);
  //   // dispatch('on_boarding/setOnBoardingUserFields');
  //   // await dispatch('initConstants'); or some other sort of constants
  // },

  init({ commit }) {
    commit('setAppLoaded', true)
    // return;
    // commit('setAppIsLoading', true);
    return LicenseResource.index()
      .then(({ data: { license } }) => {
        commit('constants/setTenant', {
          has_duration: license.has_duration,
          end_date: license.end_date,
          start_date: license.start_date,
        })
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        // commit('setGlobalLoading', false);
      })
  },
  initAuthedConstants({ state, commit }) {
    if (state.session.user.roles.find((row) => row.name === 'admin')) {
      return ConstantsServiceInstance.adminConstants()
        .then(({ data }) => {
          commit('constants/init', data)
        })
        .catch((err) => {
          // window.errorsValidate(err);

          throw formErrorsHandler(err)
        })
    }
    // end
    return null
  },
}
