export const GlobalEventConfigBase = ({ default_language = '' }) => ({
  default_language,
})

export const GlobalEventConfigForm = () => GlobalEventConfigBase({})
export const GlobalEventConfigDB = ({
  default_language: default_language_obj,
}) =>
  GlobalEventConfigBase({
    default_language: default_language_obj.name,
  })
