<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="640px"
    content-class="tw-rounded-3xl"
  >
    <v-form
      ref="form"
      v-model="valid"
      :disabled="form_loading"
      lazy-validation
      enctype="multipart/form-data"
      @submit.prevent="submitEvent"
    >
      <v-card class="tw-relative tw-rounded-3xl tw-border-0 tw-shadow-md">
        <ModalHeader @close-modal="closeModal">{{ $t('app.attendees_actions.labels.import') }}</ModalHeader>
        <v-divider />
        <v-card-text class="tw-p-0" style="max-height: 700px">
          <v-responsive class="tw-py-0">
            <v-container fluid>
              <v-row class="mb-5">
                <v-col cols="12" class="mx-auto pa-5">
                  <ul class="tw-divide-y tw-divide-gray-200 tw-pl-0">
                    <li
                      class="tw-items-center tw-py-4 sm:tw-flex sm:tw-justify-between"
                    >
                      <div class="tw-flex tw-flex-col">
                        <!-- <p
                        id="privacy-option-1-label"
                        class="
                          tw-text-sm tw-font-medium tw-text-gray-900 tw-mb-0
                        "
                      >
                        Restabler todas las reglas
                      </p> -->
                        <h3
                          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          {{ $t('app.attendees_actions.labels.lang') }}
                        </h3>
                        <p
                          id="privacy-option-1-description"
                          class="tw-mb-0 tw-text-sm tw-text-gray-500"
                        >
                          {{ $t('app.attendees_actions.labels.lang_detail') }}
                        </p>
                      </div>

                      <div
                        class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
                      >
                        <v-select
                          v-model="form.lang_key"
                          :items="languages"
                          :label="$t('app.language')"
                          outlined
                          :disabled="form_strategy_loading"
                          rounded
                          hide-details=""
                          dense
                          class="tw-w-64"
                        />
                      </div>
                    </li>
                    <li
                      class="tw-items-center tw-py-4 sm:tw-flex sm:tw-justify-between"
                    >
                      <div class="tw-flex tw-flex-col">
                        <!-- <p
                        id="privacy-option-1-label"
                        class="
                          tw-text-sm tw-font-medium tw-text-gray-900 tw-mb-0
                        "
                      >
                        Restabler todas las reglas
                      </p> -->
                        <h3
                          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          {{ $t('app.attendees_actions.labels.model') }}
                        </h3>
                        <p
                          id="privacy-option-1-description"
                          class="tw-mb-0 tw-text-sm tw-text-gray-500"
                        >
                          {{ $t('app.attendees_actions.labels.model_detail') }}
                        </p>
                      </div>

                      <div
                        class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
                      >
                        <AppBtn
                          :loading="download_template_loading"
                          color="transparent-border"
                          @click="downloadTemplate"
                        >
                          {{ $t('app.attendees_actions.labels.dow_model') }}
                        </AppBtn>
                      </div>
                    </li>
                  </ul>
                  <!-- Description list with tw-inline editing -->
                  <div class="tw-mt-10 tw-divide-y tw-divide-gray-200">
                    <div class="tw-space-y-1">
                      <h3
                        class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        {{ $t('app.attendees_actions.labels.file') }}
                      </h3>
                      <p
                        class="tw-mb-0 tw-max-w-2xl tw-text-sm tw-text-gray-500"
                      >
                        {{ $t('app.attendees_actions.labels.file_detail') }}
                      </p>
                    </div>
                    <!-- <div class="tw-mt-6"> -->
                    <div class="tw-mt-2">
                      <dl class="tw-divide-y tw-divide-gray-200">
                        <div
                          class="tw-py-4 sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-4 sm:tw-py-5"
                        >
                          <dt
                            class="tw-sr-only tw-text-sm tw-font-medium tw-text-gray-500"
                          >
                            Estrategia
                          </dt>
                          <dd
                            class="tw-mt-1 tw-items-center tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0 sm:tw-flex sm:tw-justify-between"
                          >
                            <span class="tw-flex-grow">
                              <!-- <v-select
                              v-model="access_list_type"
                              :items="access_list_types"
                              label="Estrategia"
                              outlined
                              :disabled="form_strategy_loading"
                              rounded
                              hide-details=""
                              dense
                              class="tw-w-64"
                            /> -->
                              <v-file-input
                                v-model="form.file"
                                counter
                                label="File input"
                                rounded
                                prepend-icon=""
                                prepend-inner-icon="attach_file"
                                placeholder="Select your files"
                                outlined
                                :show-size="1000"
                              >
                                <template #selection="{ index, text }">
                                  <v-chip v-if="index < 2" dark label small>
                                    {{ text }}
                                  </v-chip>

                                  <span
                                    v-else-if="index === 2"
                                    class="text-overline grey--text text--darken-3 mx-2"
                                  >
                                    +{{ form.file.length - 2 }} File(s)
                                  </span>
                                </template>
                              </v-file-input>
                            </span>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <transition name="fade-transition" mode="out-in">
                <v-row v-if="errorAlert">
                  <v-col cols="12">
                    <v-alert
                      v-model="errorAlert"
                      dismissible
                      color="red"
                      border="left"
                      elevation="2"
                      colored-border
                      icon="error"
                      transition="fade-transition"
                    >
                      {{ errorMsg }}
                    </v-alert>
                  </v-col>
                </v-row>
              </transition>
            </v-container>
          </v-responsive>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <AppBtn
            color="transparent"
            :disabled="form_loading"
            @click="closeModal"
          >
            {{ $t('app.cancel') }}
          </AppBtn>
          <AppBtn color="primary" type="submit" :loading="form_loading">
            {{ $t('app.import') }}
          </AppBtn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rules } from '@/utils/rules'
// import { makeFormData } from '@/utils/makeFormData'

import ModalHeader from '@/components/ModalHeader'
import { mapGetters, mapState } from 'vuex'
import AppBtn from '@/components/App/AppBtn.vue'
import { axiosDownloadable } from '@/utils/axiosDownloadable'
import { makeFormData } from '@/utils/makeFormData'
import { slugify } from '@/utils/slugify'
const UploadTypeKeys = {
  list: 'list',
  file: 'file',
}
export default {
  components: {
    ModalHeader,
    AppBtn,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    files: [],
    UploadTypeKeys,
    form: {
      lang_key: 'es',
      file: null,
    },
    errorAlert: '',
    errorMsg: '',
    download_template_loading: false,
    exam: '',
    rules,
    isLoading: false,
    form_loading: false,
    form_strategy_loading: false,
    valid: true,
    mountedStrategy: {
      access_list_type: { name: '' },
    },
    access_list_type: '',
    isLoadingRooms: false,
  }),
  computed: {
    isUploadTypeList() {
      return this.form.upload_type == UploadTypeKeys.list
    },
    isUploadTypeFile() {
      return this.form.upload_type == UploadTypeKeys.file
    },
    saveDisabled() {
      if (this.form_strategy_loading) {
        return true
      }
      return this.access_list_type == this.mountedStrategy.access_list_type.name
    },
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    ...mapGetters({
      access_list_types: ['constants/access_list_types'],
      languages: ['constants/languages'],
    }),

    ...mapState({
      show: (state) => state.registries.show.data,
    }),
  },
  methods: {
    downloadTemplate() {
      this.download_template_loading = true
      this.$store
        .dispatch('registries/exportParticipantsTemplate', {
          payload: {
            ...this.form,
          },
        })
        .then((res) => {
          axiosDownloadable(
            res,
            `import-template-${slugify(this.show.label)}-${this.form.lang_key}`,
          )
        })
        .catch((err) => {
          this.$store.dispatch('notifications/addNotification', {
            title: 'Error exportando',
            body: err,
          })
        })
        .finally(() => {
          this.download_template_loading = false
        })
    },
    closeModal() {
      // this.$store.dispatch('registries/initUpdateForm')
      this.open = false
    },
    submitEvent() {
      this.errorAlert = false
      this.form_loading = true
      this.form_strategy_loading = true
      const formData = makeFormData(this.form)
      this.$store
        .dispatch('registries/importParticipants', {
          payload: formData,
        })
        .then(() => {
          this.$emit('refresh-index')
          this.open = false
        })
        .catch((err) => {
          this.errorAlert = true
          this.errorMsg = err
        })
        .finally(() => {
          this.form_strategy_loading = false
          this.form_loading = false
        })
    },
  },
}
</script>
