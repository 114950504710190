<template>
<v-menu offset-y rounded="lg" :close-on-click="false">
    <template #activator="{on: menu, attrs}">
        <v-tooltip bottom>
            <template #activator="{on: tooltip}">
                <v-btn class="tw-bg-transparent tw-font-bold tw-normal-case tw-text-gray-800 tw-shadow-none focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-50" color="white" rounded fab small dark v-bind="attrs" v-on="{...tooltip, ...menu}">
                    <!-- <GIcon name="notifications" class="tw-h-6 tw-w-6" /> -->
                    <v-badge :content="Counter.length" :value="Counter.length" color="green" overlap>
                        <GIcon name="notifications" class="tw-h-6 tw-w-6" />
                    </v-badge>
                </v-btn>
            </template>
            <span>{{ $t('app.notification') }}</span>
        </v-tooltip>
    </template>
    <v-card max-width="400" width="400" height="460" class="tw-p-4 tw-overflow-y-scroll bz-scroll">
        <div class="bz-ntf-card-header">
            <span class="bz-title text-overline">{{ $t('app.notification') }}</span>
            <span class="bz-clear" @click="deleteAll">{{ $t('app.notify.labels.clear') }}</span>
        </div>
        <v-divider />
        <template v-if="notifications.length > 0">
            <v-list>
                <NotificationListItem v-for="ntf in notifications" :key="ntf.notification_id" :ntf="ntf" @active-broadcast="activeBroadcast" />
            </v-list>
        </template>
        <template v-else>
            <div class="bz-none-ntf">
                <v-icon class="bz-none-icon">notifications_none</v-icon>
                {{ $t('app.notify.labels.null') }}
            </div> 
        </template>
    </v-card>
</v-menu>
</template>

<script>
// import { mapState } from 'vuex'
import { mapFields } from '@/helpers'
import GIcon from '@/components/GIcon.vue'
import NotificationListItem from '@/components/NotificationListItem.vue'
// import { diffForHumans } from '@/utils/dateHelpers'


export default {
    components: {
        GIcon,
        NotificationListItem,
    },
    props: {
        notifications: {
            type: [Object, Array],
            default: () => ({}),
        },
    },
    computed: {
        // ...mapState({
        //     notifications: (state) => state.event.constants.notifications,
        // }),
        ...mapFields({
            fields: ['notifyModal','notifyInfo'],
            base: ['notifications'],
            mutation: 'notifications/setNotification',
        }),
        // Rows() {
        //     return this.notifications.map((ntf) => {
        //         return {
        //             ...ntf,
        //             date: diffForHumans(ntf.created_at),
        //             data: JSON.parse(ntf.data),
        //         }
        //     })
        // },
        Counter() {
            return this.notifications.filter((row) => row.read_at === null)
        },
    },
    methods: {
        activeBroadcast(ntf) {
            const {
                body,
                title,
                event_broadcast_style_type_id,
                duration,
                has_sound,
                action_label,
            } = ntf

            if(event_broadcast_style_type_id == 1) {
                this.$store.dispatch('notifications/addNotification', {
                    title,
                    body,
                    duration,
                    has_sound,
                    action_label,
                })
            }
            if(event_broadcast_style_type_id == 2) {
                this.notifyModal = true
                this.notifyInfo = ntf
            }
        },
        deleteAll() {
            this.$store.dispatch('event/deleteAll')
        },
        getNotifications() {
            this.$store.dispatch('event/getNotifications')
        },
    },
}
</script>

<style lang="scss">
.bz-scroll {
    &::-webkit-scrollbar {
        width: 2px;
    }
}

.tw-ntf-rounded {
    border-radius: .8rem;
}

.bz-ntf-card-header {
    padding: 0 0 .5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bz-title {
        font-size: .9rem !important;
    }

    .bz-clear {
        cursor: pointer;
        color: red;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        transition: all ease .3s;
        &:hover {
            color: #ff00008c;
        }
    }
}

.bz-cursor {
    cursor: pointer;
}

.bz-hover {
    &:hover {
        .bz-actions {
            opacity: 1;
        }

        .bz-ntf-card-content {
            .bz-title-time {
                .bz-time {
                    opacity: 0;
                }
            }
        }
    }
}

.bz-read {
    background: #f7f7f7;
}

.bz-ntf-card-content {
    width: 100%;

    .bz-title-time {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .bz-title {
            font-size: 14px;
            font-weight: 700;
            width: 60%;
            margin-bottom: .2rem;
        }
    }

    .bz-description {
        font-size: 11px;
        color: #606060;
    }

    .bz-badge {
        background: red;
    }
}

.bz-time {
    color: #cbcbcb;
    font-size: 11px;
    font-weight: 600;
    margin-top: .3rem;
}

.bz-actions {
    transition: all ease .3s;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;

    .bz-icon {
        font-size: 16px !important;
    }
}

.bz-none-ntf{
    height: 90%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1.1rem;
    color: #e0e0e0;
    .bz-none-icon {
        font-size: 5rem;
        color: #e0e0e0;
    }
}
</style>
