import {
  dd_mm_yyyy,
  has_time_bracket,
  hh_mm,
  time_bracket_type,
} from '@/utils/dateHelpers'

export const DurationBase = ({
  has_time_bracket = false,
  time_bracket_type = 'start_end',
  time_bracket_tz = '',
  start_date = '',
  start_time = '',
  end_date = '',
  end_time = '',
}) => ({
  has_time_bracket,
  time_bracket_type,
  time_bracket_tz,
  start_date,
  start_time,
  end_date,
  end_time,
})
export const setDuration = (duration_obj) =>
  DurationBase({
    has_time_bracket: has_time_bracket(
      duration_obj.start_date_time,
      duration_obj.end_date_time,
    ),
    time_bracket_type: time_bracket_type(
      duration_obj.start_date_time,
      duration_obj.end_date_time,
    ),
    time_bracket_tz: duration_obj.time_bracket_tz,
    start_date: dd_mm_yyyy(
      duration_obj.start_date_time,
      duration_obj.time_bracket_tz,
    ),
    start_time: hh_mm(
      duration_obj.start_date_time,
      duration_obj.time_bracket_tz,
    ),
    end_date: dd_mm_yyyy(
      duration_obj.end_date_time,
      duration_obj.time_bracket_tz,
    ),
    end_time: hh_mm(duration_obj.end_date_time, duration_obj.time_bracket_tz),
  })
