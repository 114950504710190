<template>
  <span
    :class="outerDot"
    class="tw-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center tw-rounded-full"
    aria-hidden="true"
  >
    <span class="tw-h-2 tw-w-2 tw-rounded-full" :class="innerDot" />
  </span>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    outerDot() {
      return this.active ? 'tw-bg-green-100' : 'tw-bg-gray-100'
    },
    innerDot() {
      return this.active ? 'tw-bg-green-400' : 'tw-bg-gray-400'
    },
  },
}
</script>

<style></style>
