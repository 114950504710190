<template>
  <v-card ref="logins_list" class="tw-rounded-3xl tw-border-0 tw-shadow">
    <v-app-bar dark color="primary" class="bz-app-bar tw-shadow">
      <v-toolbar-title class="tw-text-sm">Check-Ins</v-toolbar-title>
      <v-spacer/>
    </v-app-bar>
    <v-container class="tw-overflow-hidden tw-p-0" fluid>
      <v-data-table :headers="headers" :items="formatedRows" :options.sync="options" :items-per-page="5">
        <template #body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="tw-text-xs">{{ item.virtual_room ? item.virtual_room.label : '' }}</td>
              <td class="tw-text-xs">{{ item.created_at }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { commonTimeFormat, diffForHumans } from '@/utils/dateHelpers'
import EventRegistryResource from '@/services/EventRegistryResource'
export default {
  props: {
    attendee: {
      type: Object,
      default: () => ({}),
    },
    registry: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    search: '',
    imageModal: false,
    rows: [],
    form: {
      search_enum: 1,
      filter_by: 'first_name',
      filter_type: 'all',
    },
    loading: false,
    options: {},
    filters: [
      { text: 'Email', value: 'email' },
      { text: 'Nombre', value: 'first_name' },
      { text: 'Apellidos', value: 'last_name' },
    ],
  }),
  computed: {
    headers() {
      return [
        {
          text:  this.$t('pages.event.mod_dash.table.location'),
          align: 'start',
          sortable: true,
          value: 'virtual_room',
        },
        {
          text: this.$t('pages.event.mod_dash.table.time'),
          align: 'start',
          sortable: false,
          value: 'created_at',
        },
      ]
    },
    formatedRows() {
      return this.rows.map((row) => {
        return {
          ...row,
          created_at: commonTimeFormat(row.created_at),
          timeSinceLastUsedAt: diffForHumans(row.created_at),
        }
      })
    },
    footer_props() {
      const obj = {
        'items-per-page-options': [10, 20, 30, 40, 50],
      }
      return obj
    },
    filter_types() {
      return [
        { text: this.$t('admin.enums.all'), value: 'all' },
        ...this.languages,
      ].map((row) => ({ ...row, text: this.$t(`admin.enums.${row.value}`) }))
    },
    ...mapGetters({
      languages: ['constants/languages'],
    }),
  },
  watch: {
    options: {
      handler() {
        this.getRows()
      },
      deep: true,
    },
    form: {
      handler() {
        this.getRows()
      },
      deep: true,
    },
    search: {
      handler() {
        this.getRows()
      },
      deep: true,
    },
  },
  methods: {
    showItem(login) {
      console.log({ login })
    },
    getRows() {
      this.loading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      const params = {
        payload: {
          params: {
            page: page,
            rows_per_page: itemsPerPage,
            sort_by: sortBy[0],
            sort_desc_or_asc: sortDesc[0]
              ? sortDesc[0]
                ? 'desc'
                : 'asc'
              : 'desc',
            search: this.search,
            ...this.form,
          },
        },
      }
      const service = EventRegistryResource.Checkins(
        this.registry.id,
        this.attendee.id,
      ).index(params)

      service
        .then((res) => {
          const { data } = res.data
          this.rows = data
          // this.totalRows = total
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style></style>
