<template>
  <div
    ref="user_list"
    v-resize="onResize"
    class="tw-flex tw-h-full tw-flex-col"
  >
    <div v-if="searchTab" class="tw-w-full tw-rounded-t-lg">
      <div class="tw-flex tw-items-center tw-space-x-2 tw-px-4 tw-py-5">
        <!-- tw-px-4  -->
        <div class="tw-relative tw-flex tw-flex-1 tw-items-center tw-space-x-3">
          <div class="tw-min-w-0 tw-flex-1">
            <v-text-field
              v-model="search"
              :label="$t('app.search')"
              outlined
              rounded
              hide-details=""
              dense
              prepend-inner-icon="search"
            >
              <!-- append-icon="" -->
              <template #append>
                <!-- <v-btn
                  x-small
                  rounded
                  class="tw-px-3 tw--mr-4"
                  height="24"
                  @click="toggleActive"
                >
                  <span v-if="!onlyActive">Todos</span>
                  <span v-else>Solo activos</span>
                </v-btn> -->
                <v-menu offset-y>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      x-small
                      rounded
                      fab
                      class="tw--mr-4 focus:tw-outline-none focus:tw-ring focus:tw-ring-white tw-bg-primary-500"
                      height="24"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <g-icon
                        name="filter_solid"
                        class="tw-h-3.5 tw-w-3.5 tw-text-white"
                      />
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group
                      v-model="filters"
                      color="primary"
                      multiple
                    >
                      <template v-for="(item, i) in filterAbles">
                        <v-list-item
                          :key="`item-${i}`"
                          :value="item.value"
                          active-class="primary--text text--accent-4"
                        >
                          <template #default="{ active }">
                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                color="primary accent-4"
                              />
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title v-text="item.text" />
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </template>
                      <!-- <v-list-item @click="toggleActive">
                        <v-list-item-title>Solo activos</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="toggleOnlyThisLocation">
                        <v-list-item-title>En esta ubicación</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="hasReps" @click="toggleOnlyReps">
                        <v-list-item-title>En esta ubicación</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-checkbox
                          v-model="filters"
                          label="John"
                          value="John"
                        />
                      </v-list-item>
                      <v-list-item>
                        <v-checkbox
                          v-model="filters"
                          label="Jacob"
                          value="Jacob"
                        />
                      </v-list-item> -->
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </template>
            </v-text-field>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!searchTab" class="tw-w-full tw-rounded-t-lg tw-bg-primary-500">
      <div class="tw-flex tw-items-center tw-space-x-2 tw-px-4 tw-py-5">
        <div class="">
          <v-btn
            fab
            x-small
            class="text--white tw-normal-case tw-shadow-sm focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-300"
            @click="view = 'HOME'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="tw-h-4 tw-w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </v-btn>
        </div>
        <!-- tw-px-4  -->
        <div class="tw-relative tw-flex tw-flex-1 tw-items-center tw-space-x-3">
          <div class="tw-min-w-0 tw-flex-1">
            <v-text-field
              v-model="search"
              :label="$t('app.search')"
              outlined
              rounded
              hide-details=""
              dark
              dense
              prepend-inner-icon="search"
            >
              <!-- append-icon="" -->
              <template #append>
                <!-- <v-btn
                  x-small
                  rounded
                  class="tw-px-3 tw--mr-4"
                  height="24"
                  @click="toggleActive"
                >
                  <span v-if="!onlyActive">Todos</span>
                  <span v-else>Solo activos</span>
                </v-btn> -->
                <v-menu offset-y>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      x-small
                      rounded
                      fab
                      class="tw--mr-4 focus:tw-outline-none focus:tw-ring focus:tw-ring-white"
                      height="24"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <g-icon
                        name="filter_solid"
                        class="tw-h-3.5 tw-w-3.5 tw-text-white"
                      />
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group
                      v-model="filters"
                      color="primary"
                      multiple
                    >
                      <template v-for="(item, i) in filterAbles">
                        <v-list-item
                          :key="`item-${i}`"
                          :value="item.value"
                          active-class="primary--text text--accent-4"
                        >
                          <template #default="{ active }">
                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                color="primary accent-4"
                              />
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title v-text="item.text" />
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </template>
                      <!-- <v-list-item @click="toggleActive">
                        <v-list-item-title>Solo activos</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="toggleOnlyThisLocation">
                        <v-list-item-title>En esta ubicación</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="hasReps" @click="toggleOnlyReps">
                        <v-list-item-title>En esta ubicación</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-checkbox
                          v-model="filters"
                          label="John"
                          value="John"
                        />
                      </v-list-item>
                      <v-list-item>
                        <v-checkbox
                          v-model="filters"
                          label="Jacob"
                          value="Jacob"
                        />
                      </v-list-item> -->
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </template>
            </v-text-field>
          </div>
        </div>
      </div>
    </div>
    <div
      id="messages"
      ref="scrollToMe"
      class="tw-scrolling-touch scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 tw-flex tw-flex-col tw-space-y-4 tw-overflow-y-auto"
    >
      <ul
        v-if="filteredUsers.length > 0"
        class="tw-w-full tw-divide-y tw-divide-gray-200 tw-pl-0"
      >
        <!-- <UsersListItem
          v-for="user in filteredUsers"
          :key="user.email"
          :user="user"
        /> -->
        <v-virtual-scroll
          :item-height="72"
          :items="filteredUsers"
          :height="height"
          class="bz-scroll"
        >
          <template #default="{ item }">
            <UsersListItem :user="item" />
          </template>
        </v-virtual-scroll>
      </ul>
      <div v-else>
        <div class="tw-px-4 sm:tw-px-6 lg:tw-px-16">
          <div
            class="tw-pt-10 tw-pb-16 tw-text-center sm:tw-pt-24 sm:tw-pb-36 lg:tw-pt-40 lg:tw-pb-56"
            style=""
          >
            <svg
              width="96"
              height="96"
              fill="none"
              class="tw-mx-auto tw-mb-6 tw-text-gray-900"
            >
              <path
                d="M36 28.024A18.05 18.05 0 0025.022 39M59.999 28.024A18.05 18.05 0 0170.975 39"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <ellipse
                cx="37.5"
                cy="43.5"
                rx="4.5"
                ry="7.5"
                fill="currentColor"
              />
              <ellipse
                cx="58.5"
                cy="43.5"
                rx="4.5"
                ry="7.5"
                fill="currentColor"
              />
              <path
                d="M24.673 75.42a9.003 9.003 0 008.879 5.563m-8.88-5.562A8.973 8.973 0 0124 72c0-7.97 9-18 9-18s9 10.03 9 18a9 9 0 01-8.448 8.983m-8.88-5.562C16.919 68.817 12 58.983 12 48c0-19.882 16.118-36 36-36s36 16.118 36 36-16.118 36-36 36a35.877 35.877 0 01-14.448-3.017"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M41.997 71.75A14.94 14.94 0 0148 70.5c2.399 0 4.658.56 6.661 1.556a3 3 0 003.999-4.066 12 12 0 00-10.662-6.49 11.955 11.955 0 00-7.974 3.032c1.11 2.37 1.917 4.876 1.972 7.217z"
                fill="currentColor"
              />
            </svg>

            <i18n
              v-if="search"
              path="app.sorry_no_results"
              tag="p"
              class="tw-mb-3 tw-text-lg tw-font-medium tw-leading-5 tw-text-gray-900"
            >
              <template #term>“{{ search }}". </template>
            </i18n>
            <p
              v-else
              class="tw-mb-3 tw-text-lg tw-font-medium tw-leading-5 tw-text-gray-900"
            >
              {{ $t('app.no_users') }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tw-hidden tw-border-t-2 tw-border-gray-200 tw-px-4 tw-pt-2 tw-pb-2 sm:tw-mb-0"
    />
  </div>
</template>

<script>
import { mapFields } from '@/helpers'
import { mapState } from 'vuex'
import { log } from '@/utils/logging'
import UsersListItem from './UsersListItem'
import GIcon from '../GIcon.vue'
import { UserListKeys, UserListLabels } from '@/enums/UserListKeys'
export default {
  components: {
    UsersListItem,
    GIcon,
  },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    searchTab: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    height: 520,
    search: '',
    filter: [],
    // items: [
    //   { text: 'Solo activos', value: 'active' },
    //   { text: 'En esta ubicación', value: 'location' },
    //   { text: 'Representantes', value: 'reps' },
    // ],
    debug_items: [{ name: 'tits', id: 1 }],
  }),
  computed: {
    filterAbles() {
      return Object.keys(UserListKeys)
        .map((row, index) => ({
          value: UserListKeys[row],
          text: this.$i18n.t(
            `pages.event.chat.list.${UserListLabels[index].text}`,
          ),
        }))
        .filter((row) => {
          if (row.value == UserListKeys.reps) {
            return this.hasReps
          } else {
            return true
          }
        })
    },
    ...mapFields({
      fields: [
        'users',
        'usersLocation',
        'onlyActive',
        'onlyThisLocation',
        'onlyReps',
        'filters',
      ],
      base: ['event', 'socket'],
      mutation: 'event/setSocket',
    }),
    hasReps() {
      return this.reps.length
    },
    ...mapState({
      reps: (state) => state.event.show.data.reps,
      current_user: (state) => state.session.user,
      slug: (state) => state.event.show.data.slug,
      registry: (state) => state.event.constants.registry,
      current_config_language_key: (state) =>
        state.event.constants.event_configs.data.current_config_language_key,
    }),

    userInThisLocation() {
      return this.usersLocation
        .filter((row) => row.slug === this.slug)
        .filter((row) => row.registry_uuid === this.registry.uuid)
        .map((row) => row.user_id)
    },
    usersHybrid() {
      return this.users
        .map((row) => 
          row.attendees.filter(
            (item) =>
              item.language.name === this.current_config_language_key &&
              item.current_checkin_id !== null,
          ),
        )
    },
    activeUsersHybrid() {
      return this.users.map((row) =>
        row.attendees.filter(
          (item) =>
            item.language.name === this.current_config_language_key &&
            item.current_checkin_id !== null &&
            item.current_checkout == null,
        ),
      ).reduce((acc, cur) => {
        return acc.concat(cur)
      }, []).map((row) => row.user_id)
    },
    filteredUsers() {
      return this.users
        .filter((row) => row.id !== this.current_user.id)
        .filter((row) => {
          // if (!this.onlyActive && !this.onlyThisLocation) {
          if (!this.filters.includes(UserListKeys.active)) {
            return true
          }
          return row.is_online
        })
        .filter((row) => {
          if (!this.filters.includes(UserListKeys.location)) {
            return true
          }
          return this.userInThisLocation.includes(row.id)
        })
        .filter((row) => {
          if (!this.filters.includes(UserListKeys.reps)) {
            return true
          }
          return this.reps.map((row) => row.id).includes(row.id)
        })
        .filter((row) => {
          if (!this.filters.includes(UserListKeys.hybrid)) {
            return true
          }
          return this.activeUsersHybrid.includes(row.id)
        })
        .filter((row) => {
          return (
            row.first_name
              .toLocaleLowerCase()
              .indexOf(this.search.toLocaleLowerCase()) > -1 ||
            row.last_name
              .toLocaleLowerCase()
              .indexOf(this.search.toLocaleLowerCase()) > -1 ||
            row.email
              .toLocaleLowerCase()
              .indexOf(this.search.toLocaleLowerCase()) > -1
          )
        })
    },
    view: {
      get() {
        return this.$store.state.chat.view
      },
      set(val) {
        this.$store.commit('chat/setState', { view: val })
      },
    },
  },
  watch: {
    // onlyThisLocation: {
    //   handler(newVal) {
    //     if (newVal && !this.filter.includes(1)) {
    //       this.filter = [...this.filter, 1]
    //     }
    //     if (!newVal && this.filter.includes(1)) {
    //       this.filter = this.filter.filter((row) => row !== 1)
    //     }
    //   },
    // },
    // onlyActive: {
    //   handler(newVal) {
    //     if (newVal && !this.filter.includes(0)) {
    //       this.filter = [...this.filter, 0]
    //     }
    //     if (!newVal && this.filter.includes(0)) {
    //       this.filter = this.filter.filter((row) => row !== 0)
    //     }
    //   },
    // },
  },
  mounted() {
    this.onResize()
    if (this.onlyActive) {
      this.filter = [...this.filter, 0]
    }
    if (this.onlyThisLocation) {
      this.filter = [...this.filter, 1]
    }
  },
  methods: {
    onResize() {
      // console.log('window w ', window.innerWidth)
      // console.log('window y ', window.innerHeight)
      if (this.$refs.user_list) {
        const { height } = this.$refs.user_list.getBoundingClientRect()
        console.log(height)
        this.height = height - 80
      }
    },
    toggleActive() {
      this.onlyActive = !this.onlyActive
    },
    toggleOnlyThisLocation() {
      this.onlyThisLocation = !this.onlyThisLocation
    },
    toggleOnlyReps() {
      this.onlyThisLocation = !this.onlyThisLocation
    },
    viewAttendee(user) {
      console.log(user)
      this.$store.commit('event/setCard', { attendee: user, modal: true })
    },
    talkToPerson(user) {
      log(user.id)
      this.$store.commit('chat/setState', { view: 'CHAT', talking_to: user })
    },
  },
}
</script>

<style lang="scss">
.bz-scroll {
    // &::-webkit-scrollbar {
    //     width: 2px;
    // }
    /* width */
    &::-webkit-scrollbar {
      width: 20px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: grey;
      border-radius: 10px;
    }
}</style>
