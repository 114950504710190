import gql from 'graphql-tag'

export const eventNotification = gql`
  subscription eventNotification {
    eventNotification {
      id
      body
      title
      language
      has_sound
      duration
      overlay_type
      action_label
      overlay_image_url
      event_broadcast_style_type
      registry_id
      clickable {
        click_action_type_id
        click_action_type {
          name
        }
        external_url_link
        link_location_virtual_room_id
        link_channel_id
        link_thread_id
        content_item_element {
          audio_item_image
          audio_item_type_id
          audio_item_url
          can_chat
          can_download
          can_send_data
          channels {
            id
          }
          content_library_id
          content_type {
            name
            id
            label
          }
          content_type_id
          description
          doorway_item_room_id
          download_item_url
          duration {
            created_at
            duration_id
            duration_type
            end_date_time
            id
            start_date_time
            time_bracket_tz
            updated_at
          }
          id

          iframe_item_html
          iframe_item_type_id
          iframe_item_url
          is_searchable
          label
          language_id
          link_item_url
          private_label
          target_link_type_id
          thread_id
          video_item_preview
          video_item_type_id
          video_item_url
        }
      }
    }
  }
`
export const eventUserNotification = gql`
  subscription eventUserNotification {
    eventUserNotification {
      id
      type
      notifiable_type
      notifiable_id
      data {
        user_id
        title
        body
        body_label
      }
      read_at
      created_at
      updated_at
    }
  }
`

export const eventUsersHybridGp = gql`
  subscription eventUsersHybrid {
    eventUsersHybrid {
      status
    }
  }
`