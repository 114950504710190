<template>
  <v-dialog
    v-model="open"
    width="640"
    content-class="tw-self-end tw-rounded-3xl sm:tw-self-auto"
  >
    <!-- <template v-slot:activator="{ on }">
        <v-btn color="red lighten-2" dark v-on="on">Click Me</v-btn>
    </template>-->

    <v-card class="tw-shadow-md">
      <v-container fluid class="tw-relative tw-z-10 tw-p-0">
        <div class="tw-absolute tw-right-3 tw-top-3">
          <v-btn
            fab
            x-small
            text
            class="focus:tw-outline-none focus:tw-ring"
            @click="open = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </div>
      </v-container>
      <v-card-text
        v-if="
          notifyInfo.overlay_type ==
          EventBroadcastOverlayStyleKeys.overlay_plain
        "
        class="pt-2"
      >
        <h1 class="text-center mb-2 pt-5 pb-5 tw-text-2xl">
          {{ notifyInfo.title }}
        </h1>
        <p class="tw-text-lg" v-html="notifyInfo.body" />
      </v-card-text>
      <v-card-text
        v-if="
          notifyInfo.overlay_type ==
          EventBroadcastOverlayStyleKeys.overlay_image
        "
        class="tw-relative tw-z-0 tw-p-0"
      >
        <v-img
          :src="notifyInfo.overlay_image_url"
          class="tw-aspect-w-1 tw-aspect-h-1"
        />
      </v-card-text>

      <v-divider />

      <v-card-actions v-if="notifyInfo.clickable">
        <!-- <v-spacer></v-spacer> -->
        <v-container class="tw-px-0 tw-py-2">
          <div
            class="tw-flex tw-flex-col tw-justify-center sm:tw-flex-row sm:tw-space-x-3"
          >
            <RenderlessClickableHandle>
              <template slot-scope="{ slotClickActionEvent }">
                <AppBtn
                  color="primary"
                  @click="
                    () => {
                      open = false
                      return slotClickActionEvent({
                        clickable: notifyInfo.clickable,
                      })
                    }
                  "
                >
                  {{ notifyInfo.action_label }}
                </AppBtn>
              </template>
            </RenderlessClickableHandle>
          </div>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- </div> -->
</template>
<script>
import { mapState } from 'vuex'
import AppBtn from '@/components/App/AppBtn.vue'
import { simpleSound } from '@/utils/simpleSound'
import RenderlessClickableHandle from '@/components/Event/RenderlessClickableHandle.vue'
import { EventBroadcastOverlayStyleKeys } from '@/enums/EventBroadcastOverlayStyleKeys'

export default {
  components: {
    AppBtn,
    RenderlessClickableHandle,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    EventBroadcastOverlayStyleKeys,
  }),
  computed: {
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    ...mapState({
      notifyInfo: (state) => state.notifications.notifyInfo,
    }),
  },
  mounted() {
    if (this.notifyInfo.has_sound) {
      simpleSound()
    }
    if (this.notifyInfo.duration != 0) {
      setTimeout(() => {
        this.open = false
      }, this.notifyInfo.duration)
    }
  },
  methods: {
    clickEvent(item) {
      this.open = false
      this.clickActionEvent(item)
    },
    answerMsg() {
      this.$store.commit('chat/setState', {
        showChat: true,
        view: 'HOME',
      })
      this.$nextTick().then(() => {
        this.$store.commit('chat/setState', {
          showChat: true,
          view: 'CHAT',
          talking_to: this.msgMeta.partner_user,
        })
      })
      this.$store.commit('notifications/notificationsRemove', this.id)
    },
  },
}
</script>
