<template>
  <renderless-layout>
    <v-container fluid class="py-0">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="11" xl="9" class="mx-auto">
          <v-container fluid class="sm:py-3">
            <v-row>
              <v-col cols="12" class="px-5 tw-pt-0 sm:tw-pt-10">
                <div class="tw-flex tw-justify-between">
                  <div>
                    <primary-title :dark="false">
                      {{ $t('admin.events.new_event') }}
                    </primary-title>
                    <div
                      class="tw-mt-2 tw-h-1 tw-w-20 tw-rounded tw-bg-primary-500"
                    />
                  </div>
                  <div>
                    <v-btn
                      class="primary--text text--darken-4 tw-px-8 tw-font-black tw-normal-case focus:tw-outline-none"
                      color="primary lighten-5"
                      text
                      rounded
                      type="button"
                      dark
                      @click="$router.push({ name: 'admin.registries.index' })"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="tw-mr-2 tw--ml-1 tw-h-6 tw-w-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                      {{ $t('app.cancel') }}
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-form
              ref="form"
              v-model="valid"
              :disabled="form_disabled_state"
              lazy-validation
              enctype="multipart/form-data"
              @submit.prevent="submitEvent"
            >
              <v-row class="mb-5 tw-pt-6">
                <v-col cols="12" md="3" class="px-5 pt-5 mx-auto">
                  <form-description>
                    <template #title>
                      {{ $t('admin.app.details') }}
                    </template>
                    <template #copy>
                      {{ $t('admin.events.form_description') }}
                    </template>
                  </form-description>
                </v-col>
                <v-col cols="12" md="9" class="mx-auto pa-5">
                  <v-card
                    class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-shadow-md"
                  >
                    <v-container class="pa-5">
                      <v-row>
                        <v-col cols="12" sm="7" class="pb-0">
                          <v-text-field
                            v-model="label"
                            :label="$t('admin.app.name')"
                            validate-on-blur
                            :rules="[rules.required]"
                            outlined
                            rounded
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" class="pb-0">
                          <v-text-field
                            v-model="slug"
                            validate-on-blur
                            :rules="[rules.slug]"
                            label="URL Slug*"
                            outlined
                            rounded
                          />
                        </v-col>
                        <v-col cols="12" sm="6" class="pb-0">
                          <v-select
                            v-model="language_key"
                            :items="languages"
                            :label="$t('admin.app.default_lang')"
                            validate-on-blur
                            :rules="[rules.required]"
                            outlined
                            rounded
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container class="pa-5">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <h3
                            id="duration-title"
                            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                          >
                            {{ $t('admin.app.duration_title') }}
                          </h3>
                          <div
                            class="tw-mt-2 sm:tw-flex sm:tw-items-start sm:tw-justify-between"
                          >
                            <div
                              class="tw-max-w-xl tw-text-sm tw-text-gray-500"
                            >
                              <p id="duration-description " class="tw-mb-0">
                                {{ $t('admin.events.duration_description') }}
                              </p>
                            </div>
                            <div
                              class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
                            >
                              <v-switch
                                v-model="has_time_bracket"
                                inset
                                large
                              />
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <div v-if="has_time_bracket">
                        <v-row class="tw-mt-4">
                          <v-col cols="12" sm="6" class="pb-0">
                            <label class="tw-block tw-pb-2 tw-text-gray-600">
                              {{ $t('admin.app.opens') }}
                            </label>
                            <div class="tw-grid tw-grid-cols-2 tw-gap-4">
                              <div>
                                <date-input v-model="start_date" />
                              </div>
                              <div>
                                <v-text-field
                                  v-model="start_time"
                                  :label="$t('admin.app.time')"
                                  outlined
                                  rounded
                                  type="time"
                                />
                              </div>
                            </div>
                          </v-col>
                          <v-col cols="12" sm="6" class="pb-0">
                            <label class="tw-block tw-pb-2 tw-text-gray-600">
                              {{ $t('admin.app.closes') }}
                            </label>
                            <div class="tw-grid tw-grid-cols-2 tw-gap-4">
                              <div>
                                <date-input
                                  v-model="end_date"
                                  :date-min="start_date"
                                />
                              </div>
                              <div>
                                <v-text-field
                                  v-model="end_time"
                                  :label="$t('admin.app.time')"
                                  outlined
                                  rounded
                                  type="time"
                                />
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" class="pb-0">
                            <timezones-autocomplete
                              v-model="time_bracket_tz"
                              :rules="[rules.required]"
                            />
                          </v-col>
                        </v-row>
                      </div>
                    </v-container>
                    <v-container class="pa-5">
                      <!-- <div class="tw-px-4 sm:tw-px-6"> -->
                      <div>
                        <div>
                          <h3
                            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                          >
                            {{ $t('admin.app.extra_config') }}
                          </h3>
                          <p
                            class="tw-mt-1 tw-mb-0 tw-text-sm tw-text-gray-500"
                          >
                            {{ $t('admin.app.extra_config_description') }}
                          </p>
                        </div>
                        <ul
                          class="tw-mt-2 tw-divide-y tw-divide-gray-200 tw-pl-0"
                        >
                          <li
                            class="tw-flex tw-items-center tw-justify-between tw-py-4"
                          >
                            <div class="tw-flex tw-flex-col">
                              <p
                                id="privacy-option-1-label"
                                class="tw-mb-0 tw-text-sm tw-font-medium tw-text-gray-900"
                              >
                                {{ $t('admin.events.passwords') }}
                              </p>
                              <p
                                id="privacy-option-1-description"
                                class="tw-mb-0 tw-text-sm tw-text-gray-500"
                              >
                                {{ $t('admin.events.passwords_description') }}
                              </p>
                            </div>

                            <div
                              class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
                            >
                              <v-switch v-model="has_password" inset large />
                            </div>
                          </li>
                          <li
                            class="tw-flex tw-items-center tw-justify-between tw-py-4"
                          >
                            <div class="tw-flex tw-flex-col">
                              <p
                                id="privacy-option-2-label"
                                class="tw-mb-0 tw-text-sm tw-font-medium tw-text-gray-900"
                              >
                                {{ $t('admin.events.export_contacts') }}
                              </p>
                              <p
                                id="privacy-option-2-description"
                                class="tw-mb-0 tw-text-sm tw-text-gray-500"
                              >
                                {{
                                  $t('admin.events.export_contacts_description')
                                }}
                              </p>
                            </div>
                            <div
                              class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
                            >
                              <v-switch
                                v-model="can_export_connections"
                                inset
                                large
                              />
                            </div>
                          </li>
                          <li
                            class="tw-flex tw-items-center tw-justify-between tw-py-4"
                          >
                            <div class="tw-flex tw-flex-col">
                              <p
                                id="privacy-option-2-label"
                                class="tw-mb-0 tw-text-sm tw-font-medium tw-text-gray-900"
                              >
                                {{ $t('admin.events.only_default_lang') }}
                              </p>
                              <p
                                id="privacy-option-2-description"
                                class="tw-mb-0 tw-text-sm tw-text-gray-500"
                              >
                                {{
                                  $t(
                                    'admin.events.only_default_lang_description',
                                  )
                                }}
                              </p>
                            </div>
                            <div
                              class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
                            >
                              <v-switch
                                v-model="is_only_active_for_default_lang"
                                inset
                                large
                              />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
              <row-separator />
              <v-row class="mb-5">
                <v-col cols="12" md="9" class="px-0 py-0 ml-auto">
                  <v-card
                    class="mx-auto pa-5 tw-rounded-3xl tw-border-transparent tw-bg-transparent tw-shadow-none"
                  >
                    <transition name="fade-transition" mode="out-in">
                      <v-row v-if="errorAlert">
                        <v-col cols="12">
                          <v-alert
                            v-model="errorAlert"
                            dismissible
                            color="red"
                            border="left"
                            elevation="2"
                            colored-border
                            icon="error"
                            transition="fade-transition"
                          >
                            {{ errorMsg }}
                          </v-alert>
                        </v-col>
                      </v-row>
                    </transition>
                    <v-card-actions>
                      <v-row>
                        <v-col cols="12" class="pa-0">
                          <div class="tw-flex tw-justify-end">
                            <AppBtn
                              icon="check"
                              color="primary"
                              :loading="isLoading"
                              type="submit"
                            >
                              {{ $t('app.save') }}
                            </AppBtn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </renderless-layout>
</template>

<script>
import DateInput from '@/components/FormInputs/DateInput'
import TimezonesAutocomplete from '@/components/TimezonesAutocomplete'
import FormDescription from '@/components/FormDescription'
import RowSeparator from '@/components/RowSeparator'
import PrimaryTitle from '@/components/Titles/PrimaryTitle.vue'
import { mapFields } from '@/helpers'
import { mapGetters } from 'vuex'
import { rules } from '@/utils/rules'
import AppBtn from '@/components/App/AppBtn.vue'

export default {
  components: {
    PrimaryTitle,
    FormDescription,
    RowSeparator,
    TimezonesAutocomplete,
    DateInput,
    AppBtn,
  },
  data: () => ({
    isLoading: false,
    form_disabled_state: false,
    valid: true,
    errorAlert: false,
    errorMsg: '',
    rules,
  }),
  computed: {
    ...mapFields({
      fields: [
        'has_time_bracket',
        'label',
        'slug',
        'language_key',
        'start_date',
        'start_time',
        'end_date',
        'end_time',
        'time_bracket_tz',
        'has_password',
        'can_export_connections',
        'is_only_active_for_default_lang',
      ],
      base: ['registries', 'create', 'data'],
      mutation: 'registries/setCreate',
    }),
    ...mapGetters({
      languages: ['constants/languages'],
    }),
  },

  beforeDestroy() {
    console.log('destroyed')
    this.$store.commit('registries/cleanCreate')
  },

  methods: {
    submitEvent() {
      this.errorAlert = false
      if (!this.$refs.form.validate()) {
        return
      }
      this.isLoading = true
      this.form_disabled_state = true
      this.$store
        .dispatch('registries/store')
        .then(() => {
          this.$router.push({ name: 'admin.registries.index' })
        })
        .catch((err) => {
          this.errorAlert = true
          this.errorMsg = err
        })
        .finally(() => {
          this.isLoading = false
          this.form_disabled_state = false
        })
    },
  },
}
</script>

<style></style>
