<template>
<div>
    <v-list-item two-line class="tw-px-4">
        <v-list-item-avatar size="34" class="tw-mt-0 tw-mr-2">
            <v-img :src="user.profile_photo_url" />
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title class="tw-text-xs tw-font-semibold">
                {{ fullname }}
                <v-icon :color="calendarItem.status_claim ? 'green':'#d9d9d9'" small>
                    handshake
                </v-icon>
            </v-list-item-title>
            <v-list-item-subtitle class="tw-text-gray-400 tw-flex tw-items-center" style="font-size: 10px">
                <v-icon small dense disabled class="tw-mr-1">subject</v-icon> {{ calendarItem.registry_calendar_item.label }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="tw-text-gray-400 tw-flex tw-items-center" style="font-size: 10px">
                <v-icon small dense disabled class="tw-mr-1">event</v-icon> {{ calendarItem.date_request }}
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
            <v-list-item-action-text class="tw-text-gray-300" style="font-size: 10px">
                {{ calendarItem.date_laps }} 
            </v-list-item-action-text>
            <div class="tw-flex tw-items-center tw-space-x-1">
                <v-btn v-if="isReceived" icon color="green" small :loading="loading_acpt" :disabled="calendarItem.status_claim" @click="acceptApt()">
                    <v-icon>check_circle</v-icon>
                </v-btn>
                <v-btn v-if="!isReceived" icon color="green" small @click="editApt()">
                    <v-icon>update</v-icon>
                </v-btn>
                <v-btn icon color="red" small @click="cancelApt()">
                    <v-icon>cancel</v-icon>
                </v-btn>
            </div>
        </v-list-item-action>
    </v-list-item>
    <v-divider />
</div>
</template>

<script>
export default {
    name: 'EventCalendarLiveNotificationsItem',
    props: {
        user: {
            type: [Array, Object],
            default: () => ({}),
        },
        calendarItem: {
            type: [Array, Object],
            default: () => ({}),
        },
        isReceived: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        loading_acpt: false,
    }),
    computed: {
        fullname() {
            return `${this.user.first_name} ${this.user.last_name}`
        },
    },
    methods: {
        acceptApt() {
            console.log('🚀 ~ file: EventBookAppointmentUserItemRequest.vue:39 ~ acceptApt ~ acceptApt:')
            this.loading_acpt = true
            const payload = {
                body: {
                    request_id: this.calendarItem.id,
                    registry_calendar_item_id: this.calendarItem.registry_calendar_item_id,
                    status_claim: true,
                },
            }
            return this.$store.dispatch('event/acceptedReceivedCalendar', payload)
                .then(() => {
                    this.loading_acpt = false
                    this.$emit('accepted-success')
                })
        },
        editApt() {
            console.log('🚀 ~ file: EventBookAppointmentUserItemRequest.vue:49 ~ editApt ~ editApt:')
        },
        cancelApt() {
            console.log('🚀 ~ file: EventBookAppointmentUserItemRequest.vue:36 ~ cancelApt ~ cancelApt:')
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
