import i18n from '@/i18n'

const DayOfTheWeekEs = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
]
const DayOfTheWeekEn = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
]

const MonthEs = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'setiembre',
    'octubre',
    'noviembre',
    'diciembre',
]
const MonthEn = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
]

export const dateDetailFormat = (date) => {
    const nd = new Date(date)
    const dna = i18n.locale == 'es' ? DayOfTheWeekEs[nd.getDay()] : DayOfTheWeekEn[nd.getDay()]
    const dnu = nd.getDate()
    const mon = i18n.locale == 'es' ? MonthEs[nd.getMonth()] : MonthEn[nd.getMonth()]
    // h:m am or pm
    var hours = nd.getHours()
    var minutes = nd.getMinutes()
    var ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes
    const strTime = hours + ':' + minutes + ' ' + ampm

    return `${dna}, ${dnu} ${mon} ${strTime}`
}