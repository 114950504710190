<template>
  <v-autocomplete
    v-model="input_value"
    :items="rows"
    :loading="isLoading"
    :search-input.sync="search"
    :item-text="useNotes ? 'notes' : 'name'"
    item-value="id"
    :label="label"
    outlined
    rounded
    hide-no-data
    v-bind="$attrs"
    :multiple="multiple"
    v-on="$listeners"
  >
    <template #selection="data">

      <v-chip
        v-if="multiple"
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
      >
        <v-avatar left>
          <v-img :src="data.item.avatar" />
        </v-avatar>
        {{ data.item.name }}
      </v-chip>
      
      <v-list-item-content 
        v-if="useNotes" 
        v-bind="data.attrs"
        :input-value="data.selected"
        @click="data.select"
      >
        <v-list-item-title v-html="data.item.notes" />
      </v-list-item-content>

      <v-chip
        v-if="!multiple && !useNotes"
        v-bind="data.attrs"
        :input-value="data.selected"
        @click="data.select"
      >
        <v-avatar left>
          <v-img :src="data.item.avatar" />
        </v-avatar>
        {{ data.item.name }}
      </v-chip>

    </template>

    <template v-if="useNotes" #item="data">
      <v-list-item-content>
        <v-list-item-title v-html="data.item.notes" />
      </v-list-item-content>
    </template>
    
    <template v-else #item="data">
      <v-list-item-avatar>
        <img :src="data.item.avatar" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="data.item.name" />
        <v-list-item-subtitle v-html="data.item.email" />
      </v-list-item-content>
    </template>
  
  </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: [Array, Number],
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    filterName: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Usuarios',
    },
    useNotes: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    search: null,
  }),
  computed: {
    ...mapState({
      items: (state) => state.constants.run_time.users,
      isLoading: (state) => state.constants.is_loading.users,
    }),
    filterRows() {
      let filteredItems = this.items


      if (this.filterName) {
        filteredItems = filteredItems.filter((row) => {
          return row.roles.map((role) => role.name).includes(this.filterName)
        })
      }

      if (this.useNotes) {
        filteredItems = filteredItems.filter((row) => row.notes && row.notes.trim() !== '')
      }
      return filteredItems
    },
    rows() {
      return this.filterRows.map((row) => ({
        ...row,
        name: `${row.first_name} ${row.last_name}`,
      }))
    },
    input_value: {
      get() {
        return this.value
      },
      set(val) {
        // console.log(val)
        this.$emit('input', val)
      },
    },
  },
  mounted() {
    if (this.items.length > 0) return
    this.loadRows()
  },
  methods: {
    loadRows() {
      this.$store.dispatch('constants/loadUsers')
    },
    remove(item) {
      // const index = this.input_value.indexOf(item.id)
      // if (index >= 0) this.input_value.splice(index, 1)
      this.input_value = this.input_value.filter((row) => row != item.id)
    },
  },
}
</script>

<style></style>
