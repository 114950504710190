import Resource from './BaseResource'

class RegistriesResource extends Resource {
  /**
   * The constructor for the PhotoboothConfigsResource.
   */
  constructor() {
    super('/api/v1/admin/registries')
  }

  PhotoboothConfigs(id) {
    this.child = `${id}/photo-booth-configs`
    return this
  }
}
export default new RegistriesResource()
