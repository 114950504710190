export const UserBase = ({
  first_name = '',
  last_name = '',
  email = '',
  roles = ['user'],
  notes = '',
}) => ({
  first_name,
  last_name,
  email,
  roles,
  notes,
})
