<template>
    <v-dialog
        v-model="open"
        persistent
        scrollable
        max-width="640px"
        content-class="tw-rounded-3xl tw-w-full"
    >
        <v-form
            ref="form"
            v-model="valid"
            :disabled="is_loading"
            lazy-validation
            enctype="multipart/form-data"
            @submit.prevent="update"
        >
            <v-card class="tw-rounded-3xl tw-border-0 tw-shadow-md">
                <ModalHeader>
                    {{ $t('app.config') }}
                    <template #right>
                        <div class="tw-flex tw-items-center tw-space-x-3">
                        <v-fab-transition>
                            <v-btn
                                fab
                                x-small
                                class="focus:tw-outline-none focus:tw-ring"
                                @click="closeModal"
                            >
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-fab-transition>
                        </div>
                    </template>
                </ModalHeader>
                <v-divider />
                <v-card-text class="tw-p-0">
                    <v-responsive class="tw-py-0">
                        <v-container class="tw-bg-gray-50" fluid>
                            <v-row class="mb-5">
                                <v-col cols="12" lg="12" class="mx-auto pa-5">
                                    <v-card class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-shadow-md">
                                        <v-container class="pa-5">
                                            <ToggleGroup v-model="credential.has_customlink">
                                                <template #title>{{ $t('bz2.qr.setting.title') }}</template>
                                                <template #description>
                                                    {{ $t('admin.app.url_includes') }}
                                                    <span class="tw-font-bold tw-underline">
                                                        https://
                                                    </span>
                                                </template>
                                                <template #extras>
                                                    <div>
                                                        <v-text-field
                                                            v-model="credential.qr_custom_link"
                                                            :label="$t('admin.app.link')"
                                                            outlined
                                                            rounded
                                                        />
                                                    </div>
                                                </template>
                                            </ToggleGroup>
                                        </v-container>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-responsive>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <AppBtn
                        type="button"
                        color="transparent"
                        :disabled="is_loading"
                        @click="closeModal"
                    >
                        {{ $t('app.cancel') }}
                    </AppBtn>
                    <AppBtn type="submit" :loading="is_loading" color="primary">
                        {{ $t('app.save') }}
                    </AppBtn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import AppBtn from '@/components/App/AppBtn.vue'
import { mapState } from 'vuex'
import ModalHeader from '@/components/ModalHeader'
import ToggleGroup from '../FormInputs/ToggleGroup.vue'
import CredentialResource from '@/services/CredentialResource'
export default {
    components: {
        AppBtn,
        ModalHeader,
        ToggleGroup,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        is_loading: false,
        valid: true,
    }),
    computed: {
        ...mapState({
            credential: (state) => state.registries.show.data.credential,
            registry: (state) => state.registries.show.data,
        }),
        open: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
    methods: {
        closeModal() {
            this.open = false
        },
        update() {
            this.$store.commit('setAppIsLoading', true, { root: true })
            CredentialResource.update(
                this.registry.id,
                {
                    has_customlink: this.credential.has_customlink,
                    qr_custom_link: this.credential.qr_custom_link,
                },
            )
                .finally(() => {
                    this.$store.commit('setAppIsLoading', false, { root: true })
                    this.open = false
                })
        },
    },
}
</script>