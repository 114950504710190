<template>
  <UserProfileTabWrapper>
    <div class="tw-flex tw-h-full tw-flex-col">
      <!-- Body -->
      <v-form
ref="form" v-model="valid" lazy-validation class="" :disabled="isUpdating" autocomplete="off"
        @submit.prevent="updateProfile">
        <!-- ---------------------- New UI Profile ---------------------- -->
        <!-- Photo -->
        <div class="tw-w-full tw-relative tw-flex tw-justify-center" :style="cssProps">
          <div
            class="tw-w-36 tw-h-36 tw-absolute tw-flex tw-items-center tw-justify-center tw-rounded-full" :style="cssPropsPhoto">
            <img class="tw-h-full tw-w-full tw-rounded-full tw-p-1 tw-shadow-lg" :src="form.avatar" alt="" />
            <!-- Change Overlay -->
            <div
              class="tw-w-full tw-h-full tw-cursor-pointer tw-absolute tw-rounded-full tw-opacity-0 hover:tw-opacity-100 hover:tw-bg-black hover:tw-bg-opacity-75 tw-flex tw-items-center tw-justify-center"
              style="transition: all ease .3s;" :disabled="isUpdating" :loading="isUpdating"
              @click="toggle_dropzone = !toggle_dropzone">
              <v-icon class="tw-text-white" style="font-size: 34px;">
                photo_camera
              </v-icon>
            </div>
          </div>
        </div>
        <!-- My account -->
        <section :id="'my_account'" class="tw-pt-24">
          <div class="tw-mx-auto tw-max-w-3xl tw-divide-y tw-divide-gray-200 tw-py-4 tw-px-4 md:tw-px-0">
            <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 md:tw-gap-4">
              <div>
                <v-text-field
v-model="form.first_name" :label="$t('forms.first_name')" :rules="[rules.testEmpty]"
                  type="text" outlined validate-on-blur required rounded class="tw-text-sm" />
              </div>
              <div>
                <v-text-field
v-model="form.last_name" :label="$t('forms.last_name')" :rules="[rules.testEmpty]"
                  type="text" outlined validate-on-blur required rounded class="tw-text-sm" />
              </div>
              <div>
                <v-text-field
ref="email" v-model="form.email" :disabled="true" prepend-inner-icon="alternate_email"
                  name="email" :label="$t('forms.email')" type="email" outlined validate-on-blur :rules="emailRules"
                  rounded class="tw-text-sm" />
              </div>
            </div>
          </div>
        </section>
        <!-- Personal information -->
        <section v-if="hasDefaultFields" :id="'account_info'">
          <div class="tw-mx-auto tw-max-w-3xl tw-divide-y tw-divide-gray-200 tw-py-4 tw-px-4 md:tw-px-0">
            <div>
              <h2 class="tw-text-lg tw-text-center md:tw-text-left tw-font-medium tw-leading-6 tw-text-gray-900">
                {{ $t('pages.event.profile.profile.my_info') }}
              </h2>
            </div>
            <div class="tw-mt-2 tw-flex tw-py-4">
              <div class="tw-mt-6 tw-flex-grow tw-space-y-2">
                <div class="tw-grid tw-grid-1 md:tw-grid-cols-2 tw-gap-x-6 tw-gap-y-2">
                  <template v-for="default_field in default_fields">
                    <div v-if="default_field.is_active" :key="default_field.name">
                      <select-countries
v-if="default_field.name === 'country_code'" v-model="form[default_field.name]"
                        :label="default_field.label" :rules="[rules.testEmpty]" outlined rounded validate-on-blur />
                      <v-text-field
v-else v-model="form[default_field.name]" :label="default_field.label"
                        :name="default_field.name" :rules="[rules.testEmpty]" type="text" outlined validate-on-blur
                        rounded class="tw-text-sm" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- Aditional Information -->
        <section v-if="hasCustomFields" :id="'account_custom'">
          <div class="tw-mx-auto tw-max-w-3xl tw-divide-y tw-divide-gray-200 tw-py-4 tw-px-4 md:tw-px-0">
            <div>
              <h2 class="tw-text-lg tw-text-center md:tw-text-left tw-font-medium tw-leading-6 tw-text-gray-900">
                {{ $t('pages.event.profile.profile.additional_info') }}
              </h2>
            </div>
            <div class="tw-mt-2 tw-flex tw-py-4">
              <div class="tw-mt-6 tw-flex-grow tw-space-y-2">
                <div class="tw-grid tw-w-full tw-grid-cols-12 tw-gap-6">
                  <div
v-for="custom_field in registry_form_submission" :key="custom_field.uid" class="tw-col-span-12"
                    :class="matchCols[custom_field.cols]">
                    <template v-if="custom_field.field_type == 'input'">
                      <v-text-field
v-model="custom_field.model" :label="custom_field.label" :name="custom_field.name"
                        :rules="[rules.testEmpty]" type="text" outlined validate-on-blur required rounded
                        class="tw-text-sm" />
                    </template>
                    <template v-if="custom_field.field_type == 'textarea'">
                      <v-textarea
v-model="custom_field.model" :label="custom_field.label" :name="custom_field.name"
                        :rules="[rules.testEmpty]" outlined validate-on-blur required rounded class="tw-text-sm" />
                    </template>
                    <template v-if="custom_field.field_type == 'radio'">
                      <v-radio-group
v-model="custom_field.model" class="tw-mt-0 tw-text-sm" :rules="[rules.testEmpty]"
                        :label="custom_field.label">
                        <v-radio
v-for="option in custom_field.options" :key="option.uid" :label="option.text"
                          :value="option.value" />
                      </v-radio-group>
                    </template>
                    <template v-if="custom_field.field_type == 'checkbox'">
                      <div>
                        <label class="tw-block tw-text-sm tw-font-medium tw-text-slate-900">
                          {{ custom_field.label }}
                        </label>
                        <div class="tw-mt-3">
                          <v-checkbox
v-for="option in custom_field.options" :key="option.uid"
                            v-model="custom_field.model_multiple" class="tw-mt-0 tw-text-sm" :rules="[rules.testEmpty]"
                            :label="option.text" :value="option.value" />
                        </div>
                      </div>
                    </template>
                    <template
v-if="
                      custom_field.field_type == 'select'
                      && custom_field.label != 'Departments'
                      && custom_field.label != 'Provinces'
                      && custom_field.label != 'Districts'
                    ">
                      <v-select
v-model="custom_field.model" :label="custom_field.label" outlined
                        :rules="[rules.testEmpty]" rounded :items="custom_field.options" />
                    </template>
                    <template v-if="custom_field.field_type == 'number'">
                      <v-text-field
v-model="custom_field.model" :label="custom_field.label" :name="custom_field.name"
                        :rules="[rules.testEmpty]" outlined validate-on-blur required rounded
                        :maxlength="custom_field.characters" counter class="tw-text-sm" @keypress="onlyNumber" />
                    </template>
                    <template v-if="custom_field.field_type == 'date'">
                      <v-text-field
v-model="custom_field.model" :label="custom_field.label" :name="custom_field.name"
                        :rules="[rules.testEmpty]" type="date" outlined validate-on-blur required rounded
                        class="tw-text-sm" />
                    </template>
                    <template v-if="custom_field.field_type == 'select' && custom_field.label == 'Departments'">
                      <DepartmentsAutocomplete
v-model="custom_field.model" :rules="[rules.testEmpty]"
                        @change="listProvinceByDepartments" />
                    </template>
                    <template v-if="custom_field.field_type == 'select' && custom_field.label == 'Provinces'">
                      <ProvincesAutocomplete
v-model="custom_field.model" :search="itemsProvinces"
                        :rules="[rules.testEmpty]" @change="listDistricByProvinces" />
                    </template>
                    <template v-if="custom_field.field_type == 'select' && custom_field.label == 'Districts'">
                      <DistrictsAutocomplete
v-model="custom_field.model" :search="itemsDistritcs"
                        :rules="[rules.testEmpty]" />
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- Overlay Photo -->
        <v-overlay
:value="toggle_dropzone" :class="{
          'tw-backdrop-filter-blur tw-z-50 tw-backdrop-blur tw-backdrop-brightness-50 tw-backdrop-filter':
            toggle_dropzone,
        }">
          <div>
            <div class="tw-w-full">
              <v-card class="mx-auto tw-mb-6 tw-rounded-3xl tw-bg-white tw-shadow-md">
                <v-container class="tw-bg-white">
                  <dropzone
:width="300" :height="300" :bypass="'no-check'" media-group="nav_item"
                    @on-success="uploadedFile" />
                </v-container>
              </v-card>
              <div class="tw-flex tw-justify-end">
                <v-btn
class="tw-px-8 tw-font-black tw-normal-case focus:tw-outline-none" text rounded type="button" dark
                  @click="toggle_dropzone = false">
                  <svg
xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                    class="tw-mr-2 tw--ml-1 tw-h-6 tw-w-6">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  {{ $t('app.cancel') }}
                </v-btn>
              </div>
            </div>
          </div>
        </v-overlay>
        <!-- ---------------------- End UI Profile ---------------------- -->
      </v-form>
      <!-- Footer -->
      <section
        class="tw-pointer-events-auto tw-sticky tw-bottom-0 tw-z-10 tw-flex tw-w-full tw-items-center tw-bg-gray-50 tw-px-2 tw-py-3 tw-shadow-md">
          <v-spacer />
          <AppBtn color="white" icon="refresh" class="tw-mr-2" @click="openUpdatePasswordModal">
            {{ $t('pages.event.profile.profile.password') }}
          </AppBtn>
          <AppBtn :disabled="isUpdating" :loading="isUpdating" color="primary" icon="check" @click="updateProfile">
            {{ $t('app.save') }}
          </AppBtn>
      </section>
    </div>
    <UpdatePasswordProfileModal v-model="updatePasswordModal" :current-form-uuid="current_form.uuid" :user="user" />
  </UserProfileTabWrapper>
</template>

<script>
import Dropzone from '@/components/Dropzone'

import { mapState } from 'vuex'
import UpdatePasswordProfileModal from '@/components/Event/UpdatePasswordProfileModal.vue'

import UserProfileTabWrapper from './UserProfileTabWrapper.vue'
import SelectCountries from '@/components/FormInputs/SelectCountries.vue'
import { SET_ACTIVE } from '@/graphql/queries/userQueries'
import AppBtn from '../App/AppBtn.vue'
import { addModelText, onlyNumber } from '@/utils/Settings'
import DepartmentsAutocomplete from '@/components/DepartmentsAutocomplete'
import ProvincesAutocomplete from '@/components/ProvincesAutocomplete'
import DistrictsAutocomplete from '@/components/DistrictsAutocomplete'

const base_tabs = [
  { name: 'Mi Cuenta', href: '#', current: true, target: 'my_account' },
  { name: 'Datos personas', href: '#', current: false, target: 'account_info' },
  {
    name: 'Datos adicionales',
    href: '#',
    current: false,
    target: 'account_custom',
  },
]

const matchCols = {
  1: 'sm:tw-col-span-1',
  2: 'sm:tw-col-span-2',
  3: 'sm:tw-col-span-3',
  4: 'sm:tw-col-span-4',
  5: 'sm:tw-col-span-5',
  6: 'sm:tw-col-span-6',
  7: 'sm:tw-col-span-7',
  8: 'sm:tw-col-span-8',
  9: 'sm:tw-col-span-9',
  10: 'sm:tw-col-span-10',
  11: 'sm:tw-col-span-11',
  12: 'sm:tw-col-span-12',
}
export default {
  components: {
    SelectCountries,
    UserProfileTabWrapper,
    UpdatePasswordProfileModal,
    Dropzone,
    AppBtn,
    // Components Depa, Prov, Dist
    DepartmentsAutocomplete,
    ProvincesAutocomplete,
    DistrictsAutocomplete,
  },
  data: () => ({
    matchCols,
    updatePasswordModal: false,
    toggle_dropzone: false,
    base_tabs,
    show_password: false,
    show_password_confirmation: false,
    errorAlert: '',
    errorMsg: '',
    rules: {
      numericZero: (v) => !!parseFloat(v) || 'Campo no puede ser 0',
      testEmpty: (value) => !!value || 'Campo no puede estar vacio.',
      min: (v) => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => "The email and password you entered don't match",
    },
    mobile_menu: false,
    valid: true,
    emailRules: [
      (v) => !!v || 'E-mail no puede estar vacio',
      (v) => /.+@.+/.test(v) || 'E-mail no es valido',
    ],
    form: {
      avatar:
        'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=320&amp;h=320&amp;q=80',
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      password_confirmation: '',
      company: '',
      title: '',
      country_code: '',
      address: '',
      city: '',
      phone_number: '',
    },
    registry_form_submission: [],
    default_fields: [],
    // Selects Prov - Dist
    itemsProvinces: null,
    itemsDistritcs: null,
    onlyNumber,
    cssProps:{
      height: '160px',
      backgroundImage: `url(${require('@/assets/prints/pantalla-final.png')})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    cssPropsPhoto:{
      bottom: '-40%',
      background: '#3565af',
    },
  }),
  computed: {
    tabs() {
      return this.base_tabs.filter((row) => {
        if (row.target == 'account_info') {
          return this.hasDefaultFields
        }
        if (row.target == 'account_custom') {
          return this.hasCustomFields
        }
        return true
      })
    },
    hasDefaultFields() {
      // return this.default_fields.length > 0
      return this.default_fields.filter((row) => row.is_active).length > 0
    },
    hasCustomFields() {
      return this.registry_form_submission.length > 0
    },
    current_form() {
      return this.registry.forms.find(
        (row) => row.language.name === this.current_language_key,
      )
    },
    ...mapState({
      isUpdating: (state) => state.event.userProfile.isUpdating,
      user: (state) => state.session.user,
      current_language_key: (state) =>
        state.event.constants.event_configs.data.current_config_language_key,
      registry: (state) => state.event.constants.registry,
      profiles: (state) => state.event.constants.profiles,
    }),
    profile() {
      return this.profiles.find(
        (row) => row.registry_form_id === this.current_form.id,
      )
    },
  },
  mounted() {
    this.initForm()
  },
  methods: {
    // TODO: Search
    listProvinceByDepartments(e) {
      this.itemsProvinces = e
    },
    listDistricByProvinces(e) {
      this.itemsDistritcs = e
    },
    // TODO: End Search
    openUpdatePasswordModal() {
      this.updatePasswordModal = true
    },
    // onIntersect(entries, observer) {
    onIntersect(entries) {
      if (entries[0].intersectionRatio >= 0.5) {
        const target = entries[0].target.id
        this.base_tabs.map((row) => {
          return {
            ...row,
            current: row.target == target,
          }
        })
      }
    },
    scrollToRow(target) {
      console.log('scroll log')
      this.$vuetify.goTo(`#${target}`, {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic',
      })
    },
    uploadedFile(res) {
      this.form.avatar = res.media_element.link
      this.toggle_dropzone = false
    },
    initForm() {
      const current_form = JSON.parse(JSON.stringify(this.current_form))
      console.log(current_form.default_fields)
      console.log(current_form.fields)
      this.registry_form_submission = current_form.fields
      // .map((item) => {
      //   return { ...item, model: item.field_type === 'checkbox' ? [] : '' }
      // })
      this.default_fields = current_form.default_fields.filter(
        (row) => row.name !== 'email',
      )
      this.setInitialFormData()
      this.setAdditionalData()
    },
    setInitialFormData() {
      this.form.email = this.user.email
      this.form.avatar = this.user.avatar
      this.form.first_name = this.user.first_name
      this.form.last_name = this.user.last_name

      this.form.company = this.profile.company
      this.form.title = this.profile.title
      this.form.country_code = this.profile.country_code
      this.form.address = this.profile.address
      this.form.city = this.profile.city
      this.form.phone_number = this.profile.phone_number
    },
    updateProfile() {
      if (!this.$refs.form.validate()) {
        return
      }
      const payload = {
        uuid: this.current_form.uuid,
        body: {
          ...this.form,
          registry_form_submission: addModelText(this.registry_form_submission),
        },
      }
      this.$store
        .dispatch('event/updateProfile', payload)
        .then(() => {
          this.$apollo.mutate({
            mutation: SET_ACTIVE,
          })
          this.initForm()
          this.$store.dispatch('notifications/addNotification', {
            title: this.$t('pages.event.profile.updated.profile.title'),
            body: this.$t('pages.event.profile.updated.profile.body'),
          })
        })
        .catch((err) => {
          this.errorAlert = true
          this.errorMsg = err
        })
    },
    setAdditionalData() {
      if (
        !this.profile.registry_form_submission ||
        !this.registry_form_submission
      ) {
        return
      }
      this.registry_form_submission = this.registry_form_submission.map(
        (row) => {
          const registry_form_submission =
            this.profile.registry_form_submission.find(
              (user_row) => user_row.name == row.name,
            )

          console.log('setAdditionalData', registry_form_submission)
          const model = this.formSubmissionModel(registry_form_submission)
          const model_multiple = this.formSubmissionModelMultiple(
            registry_form_submission,
          )
          return { ...row, model, model_multiple }
        },
      )
    },
    formSubmissionModel(registry_form_submission) {
      console.log('formSubmissionModel func', registry_form_submission)
      return registry_form_submission.model
    },
    formSubmissionModelMultiple(registry_form_submission) {
      // if (registry_form_submission.input_type !== 'checkbox') {
      //   return []
      // }
      return registry_form_submission.model_multiple
    },
    passwordMatch() {
      return this.form.password === this.form.password_confirmation
        ? true
        : 'Contraseña no es igual'
    },
  },
}
</script>

<style>

</style>
