<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="1366px"
    content-class="tw-rounded-3xl"
  >
    <v-card class="tw-relative tw-rounded-3xl tw-border-0 tw-shadow-md">
      <ModalHeader @close-modal="closeModal">
        {{ $t('app.preview') }}
      </ModalHeader>
      <v-divider />
      <v-card-text class="tw-p-0">
        <v-responsive class="tw-py-0">
          <v-container id="iframe-wrapper" class="tw-bg-gray-100 tw-p-0" fluid>
            <iframe
              v-if="iframeType == 'link'"
              ref="iframe"
              :src="iframeUrl"
              frameborder="0"
              class="tw-w-full"
              allow="camera *; geolocation *; microphone *; autoplay *;fullscreen *"
              sandbox="allow-scripts allow-same-origin allow-scripts allow-popups allow-forms allow-pointer-lock"
            />
            <iframe
              v-else
              ref="iframe_srcdoc"
              :srcdoc="iframeHtml"
              frameborder="0"
              class="tw-w-full"
            />
          </v-container>
        </v-responsive>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import ModalHeader from '@/components/ModalHeader'

export default {
  components: {
    ModalHeader,
  },
  props: {
    iframeHtml: {
      type: String,
      default: '',
    },
    iframeUrl: {
      type: String,
      default: '',
    },
    iframeType: {
      type: String,
      default: 'html',
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loaded: false,
    iframe: {
      height: '800px',
      style: null,
      wrapperStyle: null,
    },
  }),
  computed: {
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    open: {
      handler(newVal) {
        if (newVal) {
          this.init()
        }
      },
    },
  },
  methods: {
    init() {
      setTimeout(() => {
        if (this.$refs.iframe) {
          this.$refs.iframe.style.height = '800px'
          this.$refs.iframe.style.opacity = '1'
          this.$refs.iframe.parentElement.style.height = 'auto'
        } else {
          console.log(this.$refs.iframe_srcdoc.contentWindow.bodyHeight)
          console.log(
            this.$refs.iframe_srcdoc.contentWindow.document.body.scrollHeight,
          )
          this.$refs.iframe_srcdoc.style.height =
            this.$refs.iframe_srcdoc.contentWindow.document.body.scrollHeight +
            'px'
          this.$refs.iframe_srcdoc.style.opacity = '1'
          this.$refs.iframe_srcdoc.parentElement.style.height = 'auto'
        }
      }, 1200)
    },
    closeModal() {
      this.open = false
    },
  },
}
</script>
