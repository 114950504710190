<template>
  <renderless-layout>
    <v-container fluid class="tw-p-0">
      <StickyHeader :nav="nav" />
      <v-row>
        <v-col cols="12" sm="12" class="mx-auto tw-container tw-max-w-7xl">
          <v-container fluid class="tw-py-6">
            <ConfigEventBroadcastsForm
              v-model="form"
              :error-alert.sync="errorAlert"
              :error-msg="errorMsg"
              :is-loading="isLoading"
              @submit-form="submitEvent"
            />
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </renderless-layout>
</template>

<script>
import StickyHeader from '@/components/StickyHeader.vue'
import ConfigEventBroadcastsForm from './ConfigEventBroadcastsForm.vue'
import { mapState } from 'vuex'
import {
  EventBroadcastBase,
  EventBroadcastHydrate,
} from '@/models/EventBroadcast'
export default {
  components: {
    StickyHeader,
    ConfigEventBroadcastsForm,
  },
  data: () => ({
    isLoading: false,
    errorAlert: false,
    errorMsg: '',
    form: EventBroadcastBase({ duration: 0 }),
  }),
  computed: {
    nav() {
      return [
        {
          name: this.$t('admin.app.general_config'),
          route: {
            name: 'admin.configs.home',
          },
        },
        {
          name: this.$t('admin.events.items.labels.notifications'),
          route: {
            name: 'admin.configs.event-broadcasts.index',
          },
        },
        {
          name: this.$t('app.create'),
          route: {},
        },
      ]
    },
    ...mapState({
      broadcast: (state) => state.event_config.broadcasts.show.data,
      isBeingCopied: (state) =>
        state.event_config.broadcasts.show.isBeingCopied,
    }),
  },
  mounted() {
    // check if object is present
    if (!this.isBeingCopied) {
      return
    }
    this.form = EventBroadcastHydrate(this.broadcast)
    this.$store.commit('event_config/setBroadcastShow', {
      isBeingCopied: false,
    })
  },

  methods: {
    submitEvent() {
      this.isLoading = true

      this.$store
        .dispatch('event_config/broadcastStore', { payload: { ...this.form } })
        .then(() => {
          this.$router.push({ name: 'admin.configs.event-broadcasts.index' })
        })
        .catch((err) => {
          this.errorAlert = true
          this.errorMsg = err
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style></style>
