/**
 * @typedef {Object} FileParts
 * @property {String} name
 * @property {String} ext
 */

/**
 *
 * @param {String} filename
 * @returns {FileParts}
 */
export const breakFileIntoParts = (filename) => {
  /**
   * Some edge cases to consider
   * 1. filename.whatever_not_extension
   * 2. filename
   */

  // eslint-disable-next-line no-useless-escape
  const [name, ext] = filename.split(/\.(?=[^\.]+$)/)
  return {
    name,
    ext: ext.toLowerCase(),
  }
}
