import $axios from '@/axios-instance.js'

class UserAttendeesServiceClass {
  baseUrl = '/api/v1/admin/users'
  child = 'attendees'

  // Read all - findAll
  index(user, params) {
    return $axios.get(`${this.baseUrl}/${user}/${this.child}`, params)
  }

  store(user, payload, params) {
    return $axios.post(`${this.baseUrl}/${user}/${this.child}`, payload, params)
  }

  update(user, attendee, payload, params) {
    return $axios.post(
      `${this.baseUrl}/${user}/${this.child}/${attendee}`,
      { ...payload, _method: 'PUT' },
      params,
    )
  }

  // Read one - show
  show(user, attendee, params) {
    return $axios.get(
      `${this.baseUrl}/${user}/${this.child}/${attendee}`,
      params,
    )
  }
}
export const UserAttendeesService = new UserAttendeesServiceClass()
