import axios from 'axios'

export async function download(dataurl, filename) {
  // let a = document.createElement('a')
  // a.href = dataurl
  // a.target = '_blank'
  // a.setAttribute('download', filename)
  // a.click()
  const blob = await axios.get(dataurl, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
    responseType: 'blob',
  })
  const a = document.createElement('a')
  const href = window.URL.createObjectURL(blob.data)
  a.href = href
  a.download = filename
  a.click()
  // fetch(dataurl).then(function (t) {
  //   return t.blob().then((b) => {
  //     var a = document.createElement('a')
  //     a.href = URL.createObjectURL(b)
  //     a.setAttribute('download', filename)
  //     a.click()
  //   })
  // })
}
