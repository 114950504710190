<template>
  <renderless-layout>
    <v-container fluid class="tw-flex tw-h-full tw-flex-col tw-p-0">
      <StickyHeader :nav="nav">
        <template #actions>
          <div class="tw-flex tw-items-center tw-space-x-3">
            <LanguageSelect
              v-model="lang_key"
              dark
              :label="$t('admin.app.lang_version')"
            />
            <AppBtn
              icon="cog"
              color="transparent-dark"
              @click="openCalendarSettings"
            >
              {{ $t('app.config') }}
            </AppBtn>
            <AppBtn
              color="primary-light"
              icon="g-next"
              g-next="CalendarPlus"
              @click="createEvent"
            >
              {{ $t('admin.app.new_calendar_event') }}
            </AppBtn>
            <!-- <AppBtn
              color="white"
              icon="check"
              :to="{ name: 'admin.configs.access-control.create' }"
            >
              {{ $t('app.save') }}
            </AppBtn> -->
          </div>
        </template>
      </StickyHeader>
      <v-row class="tw-m-0 tw-flex-1 tw-p-0">
        <transition name="fade-transition" mode="out-in">
          <v-col v-if="is_loading" key="canvas_loading" class="tw-h-full">
            <div class="tw-flex tw-h-full tw-items-center tw-justify-center">
              <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
              />
            </div>
          </v-col>
          <template v-else>
            <v-col
              cols="12"
              sm="12"
              class="mx-auto tw-container tw-flex tw-max-w-5xl"
            >
              <transition name="fade-transition" mode="out-in">
                <v-container
                  v-if="Object.keys(parsedEvents).length > 0"
                  key="events_calendar"
                  fluid
                  class="tw-space-y-6 tw-py-6"
                >
                  <v-card
                    v-for="(row, index) in parsedEvents"
                    :key="index"
                    class="tw-rounded-3xl tw-shadow-md"
                  >
                    <v-container class="tw-p-0" fluid>
                      <div class="">
                        <div
                          class="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-px-4 tw-py-5 sm:tw-flex-nowrap sm:tw-px-6"
                        >
                          <div>
                            <h3
                              class="tw-text-2xl tw-font-medium tw-leading-6 tw-text-gray-900"
                            >
                              Program {{ index }}
                            </h3>
                            <!-- <p
                        class="
                          tw-mb-0
                          tw-mt-1
                          tw-max-w-2xl
                          tw-text-sm
                          tw-text-gray-500
                        "
                      >
                        Detalles del email programado.
                      </p> -->
                          </div>
                        </div>
                        <div
                          v-for="savedEvent in row"
                          :key="savedEvent.uuid"
                          class="tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-p-0"
                        >
                          <dl class="sm:tw-divide-y sm:tw-divide-gray-200">
                            <div
                              class="tw-py-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-py-5 sm:tw-px-6"
                            >
                              <dt class="tw-space-y-1">
                                <p
                                  class="tw-mb-0 tw-text-lg tw-font-medium tw-text-gray-700"
                                >
                                  {{ savedEvent.label }}
                                </p>
                                <p
                                  class="tw-mb-0 tw-text-xs tw-font-medium tw-text-gray-400"
                                >
                                  {{ savedEvent.uuid }}
                                </p>
                              </dt>
                              <dd class="tw-mt-1 sm:tw-col-span-2 sm:tw-mt-0">
                                <p
                                  class="tw-mb-0 tw-text-sm tw-font-medium tw-text-gray-500"
                                >
                                  {{ savedEvent.time_range }}
                                </p>
                                <p class="tw-text-sm tw-text-gray-900">
                                  {{ savedEvent.description }}
                                </p>
                                <div
                                  class="tw-mt-3 tw-flex tw-justify-end tw-space-x-3"
                                >
                                  <AppBtn
                                    color="transparent-danger"
                                    icon="trash_bin"
                                    @click="deleteCalendarItem(savedEvent)"
                                  >
                                    {{ $t('app.delete') }}
                                  </AppBtn>
                                  <AppBtn
                                    color="white"
                                    icon="pencil_edit"
                                    @click="editCalendarItem(savedEvent)"
                                  >
                                    {{ $t('app.edit') }}
                                  </AppBtn>
                                </div>
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </v-container>
                  </v-card>
                </v-container>
                <div
                  v-else
                  key="empty_events_calendar"
                  class="tw-w-full tw-justify-center tw-space-y-6 tw-py-6"
                >
                  <button
                    v-ripple
                    type="button"
                    class="tw-relative tw-block tw-w-full tw-rounded-lg tw-border-2 tw-border-dashed tw-border-gray-300 tw-p-8 tw-text-center tw-transition hover:tw-border-gray-400 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2 lg:tw-py-40"
                    @click="createEvent"
                  >
                    <GIcon
                      class="tw-mx-auto tw-h-12 tw-w-12 tw-text-gray-400"
                      name="plus"
                    />
                    <span
                      class="tw-mt-2 tw-block tw-text-sm tw-font-medium tw-text-gray-900"
                    >
                      {{ $t('admin.app.new_calendar_event') }}
                    </span>
                  </button>
                </div>
              </transition>
            </v-col>
          </template>
        </transition>
      </v-row>
      <CalendarItemModal
        v-model="calendar_item_modal"
        :event-item="eventItem"
        :calendar-id="currentCalendar.id"
        :lang-key="lang_key"
        :event-item-id="eventId"
        @submit-success="getData"
      />
      <AppConfirmDialog ref="deleteModal" />

      <CalendarSettingsModal v-model="calendarSettingsModal" />
    </v-container>
  </renderless-layout>
</template>

<script>
import StickyHeader from '@/components/StickyHeader'
import AppBtn from '@/components/App/AppBtn.vue'
import { mapState } from 'vuex'
import LanguageSelect from '@/components/LanguageSelect'
import CalendarSettingsModal from '@/components/Calendars/CalendarSettingsModal.vue'
import CalendarItemModal from '@/components/Calendars/CalendarItemModal.vue'
import { CalendarItemBase, CalendarItemHydrate } from '@/models/Calendar'
import { format } from 'date-fns/esm'
import { groupBy } from '@/helpers'
import AppConfirmDialog from '@/components/App/AppConfirmDialog.vue'
import GIcon from '@/components/GIcon.vue'

export default {
  components: {
    LanguageSelect,
    StickyHeader,
    AppBtn,
    CalendarSettingsModal,
    CalendarItemModal,
    AppConfirmDialog,
    GIcon,
  },
  data: () => ({
    calendar_item_modal: false,
    calendarSettingsModal: false,
    is_loading: false,
    lang_key: 'es',
    eventId: '',
    eventItem: CalendarItemBase({}),
    calendars: [],
  }),
  computed: {
    parsedEvents() {
      const events = this.calendars.find(
        (row) => row.id == this.currentCalendar.id,
      )
      if (!events) {
        return
      }
      return groupBy(
        events.events.map((row) => ({
          ...row,
          formated_date_stamp: format(
            new Date(row.start_date_time),
            'yyyy-MM-dd',
          ),
          time_range: `${format(
            new Date(row.start_date_time),
            'hh:mm a',
          )} - ${format(new Date(row.end_date_time), 'hh:mm a')}`,
        })),
        'formated_date_stamp',
      )
    },
    currentCalendar() {
      return this.registry.calendars.find(
        (row) => row.language.name == this.lang_key,
      )
    },
    nav() {
      return [
        {
          name: this.$t('app.event'),
          route: { name: 'admin.registries.index' },
        },
        {
          name: this.registry.label,
          route: {
            name: 'admin.registries.show.home',
            params: { id: this.registry.id },
          },
        },
        {
          name: this.$t(`admin.events.items.labels.calendars`),
          route: {},
        },
      ]
    },

    ...mapState({
      registry: (state) => state.registries.show.data,
    }),
  },
  created() {
    this.getData()
  },
  methods: {
    openCalendarSettings() {
      this.calendarSettingsModal = true
    },
    openCalendarItem() {
      this.calendar_item_modal = true
    },

    createEvent() {
      this.eventItem = CalendarItemBase({})
      this.openCalendarItem()
    },
    editCalendarItem(calendarItem) {
      this.eventItem = CalendarItemHydrate(calendarItem)
      this.openCalendarItem()
    },
    async deleteCalendarItem(calendarItem) {
      const has_accepted = await this.$refs.deleteModal.open(
        'Eliminar',
        'Evento sera eliminado',
        {
          color: 'red',
        },
      )
      if (has_accepted) {
        this.$store
          .dispatch('registries/destroyCalendarItems', {
            payload: {},
            calendar: this.currentCalendar.id,
            id: calendarItem.id,
          })
          .then(() => {
            this.getData()
          })

          .catch((err) => {
            this.$store.dispatch('notifications/addNotification', {
              title: 'Error eliminando usuario',
              body: err,
            })
          })
      }
      // this.eventItem = CalendarItemHydrate(calendarItem)
      // this.openCalendarItem()
    },
    getData() {
      this.is_loading = true
      return this.$store
        .dispatch('registries/indexCalendars', { payload: {} })
        .then(({ data }) => {
          this.calendars = data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.is_loading = false
        })
    },
  },
}
</script>
