<template>
    <UserProfileTabWrapper>
      <v-container fluid>
        <section class="tw-py-6 tw-px-4 sm:tw-p-6 lg:tw-pb-8">
          <div class="tw-mx-auto tw-max-w-5xl">
            <v-card class="tw-rounded-3xl tw-shadow-md tw-overflow-hidden">
              <v-tabs v-model="activeTab" grow>
                <v-tab v-for="form in customFormArray" :key="form.id">
                  {{ form.title }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="activeTab">
                <v-tab-item v-for="form in customFormArray" :key="form.id">
                  <v-form :ref="`form_${form.id}`" v-model="valid[form.id]" lazy-validation>
                    <v-container class="tw-px-6 tw-py-4">
                      <div class="tw-my-4">
                        <DynamicFields :fields="form.fields" />
                      </div>
                      <AppBtn
                        color="primary"
                        :loading="loading"
                        icon="check"
                        @click="submitForm(form)"
                      >
                        {{ $t('app.register') }}
                      </AppBtn>
                    </v-container>
                  </v-form>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </div>
        </section>
      </v-container>
      <!-- Loading Dialog -->
      <v-dialog v-model="loading" persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            {{ loadingText }}
            <v-progress-linear indeterminate color="white" class="mb-0" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </UserProfileTabWrapper>
  </template>
  
  <script>
  import DynamicFields from '@/components/DynamicFields.vue'
  import UserProfileTabWrapper from '@/components/Event/UserProfileTabWrapper.vue'
  import { addModelText } from '@/utils/Settings'
  import { mapState } from 'vuex'
  import AppBtn from '@/components/App/AppBtn.vue'
  
  export default {
    components: {
      DynamicFields,
      UserProfileTabWrapper,
      AppBtn,
    },
    props: {
      customForm: {
        type: Array,
        required: true,
      },
    },
    data: () => ({
      activeTab: 0,
      valid: {}, // Validación específica por formulario
      loading: false,
      loadingText: '',
    }),
    computed: {
      ...mapState({
        current_language_key: (state) => state.registry.show.current_language_key,
      }),
      customFormArray() {
        return Array.isArray(this.customForm) ? this.customForm : [this.customForm]
      },
    },
    methods: {
      submitForm(form) {
        const formRef = this.$refs[`form_${form.id}`] // Acceder al formulario específico por referencia
  
        if (formRef && formRef[0].validate()) {
          // Iniciar el proceso de registro
          this.loading = true
          this.loadingText = this.$t('app.registering_user')
  
          // Preparar el payload para enviar
          const payload = {
            custom_form_id: form.id,
            form_submission: addModelText(form.fields),
            language: this.current_language_key,
            assistance: 1,
          }
  
          // Enviar la información
          return this.$store.dispatch('forms/entrieCustomForm', payload)
            .then(() => {
              this.loadingText = this.$t('app.registered')
            })
            .catch((err) => {
              this.loadingText = this.$t('app.user_not_exists')
              console.error(err)
            })
            .finally(() => {
              this.clearFormFields(form)
              this.loading = false
            })
        } else {
          console.log('Formulario no válido')
        }
      },
      clearFormFields(form) {
        // Limpiar los campos del formulario
        form.fields.forEach(field => {
          field.model = ''
        })
      },
    },
  }
  </script>
  