import $axios from '@/axios-instance.js'

export default class EventConfigsService {
  baseUrl = '/api/v1/admin/event-configs'

  // Create
  store(params) {
    return $axios.post(this.baseUrl, params)
  }

  // Read all - findAll
  index(params) {
    return $axios.get(this.baseUrl, params)
  }

  // Read one - show
  show(id, params) {
    return $axios.get(this.baseUrl + `/${id}`, params)
  }
}
export const EventConfigsServiceInstance = new EventConfigsService()
