<template>
  <component :is="componentFile" />
</template>

<script>
// import from folder
// get all names in certain fashion
// register components
// check requirements
import camelCase from 'lodash/camelCase'
import toUpper from 'lodash/toUpper'
const pascalCase = (str) => camelCase(str).replace(/^(.)/, toUpper)
const files = require.context('@/assets/icon-set/', false, /\.icon.svg$/)
const modules = {}
files.keys().forEach((key) => {
  if (key === './index.js') return
  modules[pascalCase(key.replace(/(\.\/|\.icon.svg)/g, ''))] =
    files(key).default
})
export default {
  components: {
    ...modules,
  },
  props: {
    componentName: {
      type: String,
      required: true,
      default: Object.keys(modules)[0],
      validator: (v) => {
        const isValid = Object.keys(modules).includes(v)
        if (!isValid) {
          console.error(
            'Wrong value for the `componentName` prop. ' +
              `Given: "${v}", expected one of: "${Object.keys(modules)}".`,
          )
        }
        return isValid
      },
    },
  },
  computed: {
    componentFile() {
      if (Object.keys(modules).includes(this.componentName)) {
        return this.componentName
      }
      return null
    },
  },
}
</script>
