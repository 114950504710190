import { dd_mm_yyyy, has_time_bracket, hh_mm } from '@/utils/dateHelpers'
export const EventConfigBase = ({
  label = '',
  // default_language = "",
  header_url = '',
  virtual_room_start_id = 0,
  home_room = {},
  closed_event_url = '',
  start_date = '',
  start_time = '',
  end_date = '',
  end_time = '',
  time_bracket_tz = 'America/Lima',
  is_active = 1,
  has_time_bracket = true,
  ui_color = '#3200B8',
  header_color = '#3200B8',
  footer_color = '#3200B8',
  titles_color = '#3200B8',
  //
  leaderboard_image_url = '',
  leaderboard_header_text = '',
  leaderboard_body_text = '',
  has_leaderboard = false,
  can_show_rule_names = false,
  has_filter_list_by_commas = false,
  filter_list_by_commas = '',
  leaderboard_time_bracket_tz = '',
  leaderboard_start_date = '',
  leaderboard_start_time = '',
  leaderboard_end_date = '',
  leaderboard_end_time = '',
}) => ({
  label,
  // default_language,
  virtual_room_start_id,
  header_url,
  home_room,
  start_date,
  start_time,
  end_date,
  end_time,
  has_time_bracket,
  time_bracket_tz,
  is_active,
  closed_event_url,
  ui_color,
  header_color,
  footer_color,
  titles_color,
  // leaderboard stuff
  leaderboard_image_url,
  leaderboard_header_text,
  leaderboard_body_text,
  has_leaderboard,
  can_show_rule_names,
  leaderboard_time_bracket_tz,
  leaderboard_start_date,
  leaderboard_start_time,
  leaderboard_end_date,
  leaderboard_end_time,
  filter_list_by_commas,
  has_filter_list_by_commas,
})
export const EventConfigForm = () => {
  return EventConfigBase({})
}
export const EventConfigDB = ({
  // // default_language: default_language_obj,
  virtual_room_start_id,
  header_url,
  label,
  is_active,
  ui_color,
  header_color,
  footer_color,
  headers_color,
  time_bracket_tz,
  start_date_time,
  end_date_time,

  leaderboard_image_url = '',
  leaderboard_header_text = '',
  leaderboard_body_text = '',
  has_leaderboard = false,
  can_show_rule_names = false,
  leaderboard_start_date_time = '',
  leaderboard_end_date_time = '',
  leaderboard_time_bracket_tz = '',
  filter_list_by_commas = '',
}) =>
  EventConfigBase({
    label,
    header_url,
    virtual_room_start_id,
    // // default_language: default_language_obj.name,
    ui_color,
    header_color,
    footer_color,
    headers_color,
    is_active: !!is_active,
    time_bracket_tz: time_bracket_tz,
    has_time_bracket: has_time_bracket(start_date_time, end_date_time),
    start_date: dd_mm_yyyy(start_date_time, time_bracket_tz),
    start_time: hh_mm(start_date_time, time_bracket_tz),
    end_date: dd_mm_yyyy(end_date_time, time_bracket_tz),
    end_time: hh_mm(end_date_time, time_bracket_tz),

    leaderboard_image_url,
    leaderboard_header_text,
    leaderboard_body_text,
    has_leaderboard,
    can_show_rule_names,
    has_filter_list_by_commas: !!filter_list_by_commas,
    filter_list_by_commas,
    leaderboard_time_bracket_tz,
    leaderboard_start_date: dd_mm_yyyy(
      leaderboard_start_date_time,
      leaderboard_time_bracket_tz,
    ),
    leaderboard_start_time: hh_mm(
      leaderboard_start_date_time,
      leaderboard_time_bracket_tz,
    ),
    leaderboard_end_date: dd_mm_yyyy(
      leaderboard_end_date_time,
      leaderboard_time_bracket_tz,
    ),
    leaderboard_end_time: hh_mm(
      leaderboard_end_date_time,
      leaderboard_time_bracket_tz,
    ),
  })
