<template>
  <v-overlay
    :value="search_box_modal"
    :class="{
      'tw-backdrop-filter-blur tw-z-50 tw-backdrop-blur tw-backdrop-brightness-50 tw-backdrop-filter':
        search_box_modal,
    }"
  >
    <!-- x-show="isOpen" -->
    <div
      role="combobox"
      aria-haspopup="listbox"
      aria-expanded="true"
      class="tw-fixed tw-inset-0 tw-z-50 tw-flex tw-items-start tw-justify-center tw-pt-16 sm:tw-pt-24"
    >
      <!-- <div
        x-show="isOpen"
        x-transition:enter="tw-ease-out tw-duration-200"
        x-transition:enter-start="tw-opacity-0"
        x-transition:enter-end="tw-opacity-100"
        x-transition:leave="tw-ease-in tw-duration-150"
        x-transition:leave-start="tw-opacity-100"
        x-transition:leave-end="tw-opacity-0"
        class="tw-fixed tw-inset-0 tw-transition-opacity tw-bg-gray-800 tw-bg-opacity-60"
      ></div> -->
      <div
        class="tw-fixed tw-inset-0 tw-bg-gray-100 tw-bg-opacity-5 tw-transition-opacity"
        @click="close()"
      />
      <transition
        enter-class="tw-duration-200 tw-ease-out"
        enter-active-class="tw-scale-95 tw-opacity-0"
        enter-to-class="tw-scale-100 tw-opacity-100"
        leave-class="tw-duration-150 tw-ease-in"
        leave-active-class="tw-scale-100 tw-opacity-100"
        leave-to-class="tw-scale-95 tw-opacity-0"
        mode="in-out"
      >
        <div
          v-show="search_box_modal"
          class="tw-relative tw-w-full tw-max-w-lg tw-transform tw-px-4 tw-transition-all"
        >
          <div
            class="tw-overflow-hidden tw-rounded-lg tw-bg-white tw-shadow-md"
          >
            <form
              role="search"
              novalidate=""
              class="tw-relative tw-flex tw-items-center tw-pr-4"
              :class="search ? 'tw-shadow-sm' : ''"
              @submit.prevent="go()"
            >
              <input
                ref="search_input"
                v-model="search"
                type="search"
                aria-autocomplete="list"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
                style="caret-color: #6b7280"
                class="tw--mr-9 tw-flex-auto tw-appearance-none tw-bg-transparent tw-py-4 tw-pl-4 tw-pr-12 tw-text-base tw-text-gray-600 tw-placeholder-gray-500 focus:tw-outline-none sm:tw-text-sm"
                :placeholder="$t('app.search') + '...'"
                aria-label="Search components"
                aria-controls="search-results"
                aria-activedescendant="search-result-0"
                @keydown.prevent.escape=""
                @keydown.prevent.arrow-up="selectUp()"
                @keydown.prevent.arrow-down="selectDown()"
              />
              <!-- :aria-activedescendant="'search-result-' + selected" -->
              <svg
                width="20"
                height="20"
                fill="none"
                class="tw-pointer-events-none tw-flex-none tw-text-gray-400"
              >
                <circle
                  cx="8.5"
                  cy="8.5"
                  r="5.75"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.25 17.25L13 13"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </form>
            <ul
              v-show="search && filtered_rooms.length > 0"
              id="search-results"
              ref="results"
              role="listbox"
              class="tw-divide-y tw-divide-gray-200 tw-overflow-y-auto tw-rounded-b-lg tw-border-t tw-border-gray-200 tw-pl-0 tw-text-sm tw-font-medium"
              style="max-height: 18.375rem; display: none"
            >
              <!-- tw-max-h-72 -->
              <template
                v-for="({ slug, label, uuid, room_type }, i) in filtered_rooms"
              >
                <li
                  :id="'search-result-' + i"
                  :key="uuid"
                  role="option"
                  :aria-selected="(selected === i).toString()"
                  @mouseover="selected = i"
                  @click="close"
                >
                  <!-- :href="url" -->
                  <router-link
                    :to="{
                      name: 'event.route',
                      params: {
                        slug,
                      },
                    }"
                    tabindex="-1"
                    class="tw-flex tw-justify-between tw-p-4"
                    :class="{ 'tw-bg-secondary-50': selected === i }"
                  >
                    <span
                      class="tw-whitespace-nowrap"
                      :class="{
                        'tw-text-gray-900': selected !== i,
                        'tw-text-secondary-600': selected === i,
                      }"
                    >
                      {{ label }}
                    </span>
                    <span class="tw-ml-4 tw-text-right tw-text-gray-500">{{
                      room_type.label
                    }}</span>
                  </router-link>
                </li>
              </template>
            </ul>
            <div
              v-show="search && filtered_rooms.length === 0"
              class="tw-bg-gray-50 tw-p-16 tw-text-center"
              style="display: none"
            >
              <h2 class="tw-mb-2 tw-font-semibold tw-text-gray-900">
                {{ $t('pages.event.search.no_content_title') }}
              </h2>
              <p class="tw-text-sm tw-text-gray-700">
                {{ $t('pages.event.search.no_content_body') }}
              </p>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </v-overlay>
</template>

<script>
import {
  computed,
  ref,
  getCurrentInstance,
  watch,
  readonly,
} from '@vue/composition-api'
export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const search_box_modal = computed(() => {
      return vm.$store.state.event.search.modal
    })
    const close = () => {
      vm.$store.commit('event/setSearch', { modal: false })
    }
    const open = () => {
      vm.$store.commit('event/setSearch', { modal: true })
    }
    const rooms = computed(() => {
      return vm.$store.state.event.constants.virtual_rooms
    })
    const search = ref('')
    const filtered_rooms = computed(() => {
      return rooms.value.filter((row) => {
        return (
          row.label
            .toLocaleLowerCase()
            .indexOf(search.value.toLocaleLowerCase()) > -1
        )
      })
    })
    const selected = ref(0)
    watch(search, function () {
      selected.value = 0
    })
    watch(selected, function (val) {
      vm.$nextTick(function () {
        // console.log(val)
        // console.log(vm.$refs.results.children[val])
        vm.$refs.results.children[val] &&
          vm.$refs.results.children[val].scrollIntoView({
            // block: 'end',
            block: 'nearest',
          })
      })
    })
    watch(search_box_modal, (val) => {
      if (val) {
        vm.$nextTick(function () {
          vm.$refs.search_input.focus()
        })
      }
    })
    const escapeHandler = (e) => {
      if (e.key === 'Escape' && search_box_modal.value) {
        close()
      }
    }
    const openSearch = (e) => {
      if (75 === e.which && (e.ctrlKey || e.metaKey))
        return e.preventDefault(), open()
      var n = e.target || e.srcElement,
        r = n.tagName
      n.isContentEditable ||
        'INPUT' === r ||
        'SELECT' === r ||
        'TEXTAREA' === r ||
        (191 === e.which && (e.preventDefault(), open()))
    }
    // window/
    document.addEventListener('keydown', openSearch)
    document.addEventListener('keydown', escapeHandler)
    vm.$once('hook:destroyed', () => {
      console.log('destroyed')
      document.removeEventListener('keydown', escapeHandler)
      document.removeEventListener('keydown', openSearch)
    })
    return {
      search_box_modal,
      close,
      selected,
      search,
      filtered_rooms,
      selectUp() {
        const num = readonly(selected)
        let e = num.value - 1
        e < 0 && (e = filtered_rooms.value.length - 1), (selected.value = e)
      },
      selectDown() {
        const num = readonly(selected)
        let e = num.value + 1
        e > filtered_rooms.value.length - 1 && (e = 0), (selected.value = e)
      },
      go: function () {
        const num = readonly(selected)
        let e = num.value
        const { slug } = filtered_rooms.value[e]
        vm.$router.push({
          name: 'event.route',
          params: {
            slug,
          },
        })
        close()
      },
    }
  },
}
</script>

<style></style>
