<template>
  <li>
    <div class="tw-relative tw-pb-8">
      <span
        v-if="canShowLine"
        class="tw-absolute tw-top-5 tw-left-5 tw--ml-px tw-h-full tw-w-0.5 tw-bg-gray-200"
        aria-hidden="true"
      />
      <div class="tw-relative tw-flex tw-items-start tw-space-x-3">
        <div class="tw-relative">
          <img
            class="tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-400 tw-ring-8 tw-ring-white"
            :src="questionAnswer.user.avatar"
            alt=""
          /><span
            class="tw-absolute tw--bottom-0.5 tw--right-1 tw-rounded-tl tw-bg-white tw-px-0.5 tw-py-px"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="tw-h-5 tw-w-5 tw-text-gray-400"
            >
              <path
                fill-rule="evenodd"
                d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
                clip-rule="evenodd"
              /></svg
          ></span>
        </div>
        <div class="tw-min-w-0 tw-flex-1">
          <div class="tw-flex tw-justify-between">
            <div>
              <div class="tw-text-sm">
                <a href="#" class="tw-font-medium tw-text-gray-900">
                  {{ questionAnswer.user.first_name }}
                  {{ questionAnswer.user.last_name }}
                </a>
              </div>
              <p class="tw-mt-0.5 tw-mb-0 tw-text-sm tw-text-gray-500">
                <!-- Commented -->
                {{ questionAnswer.parsedCreatedAt }}
              </p>
            </div>
            <div class="tw-flex tw-space-x-3">
              <div v-if="canShowVisibility">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <button
                      v-ripple
                      class="tw-pointer-events-none tw-h-5 tw-w-5 tw-rounded-full focus:tw-outline-none focus:tw-ring"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <svg
                        v-if="questionAnswer.is_public"
                        xmlns="http://www.w3.org/2000/svg"
                        class="tw-h-5 tw-w-5 tw-text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        />
                      </svg>
                      <svg
                        v-if="!questionAnswer.is_public"
                        xmlns="http://www.w3.org/2000/svg"
                        class="tw-h-5 tw-w-5 tw-text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                        />
                      </svg>
                    </button>
                  </template>
                  <span>
                    Item
                    {{ questionAnswer.is_public ? 'Visible' : 'Hidden' }}
                  </span>
                </v-tooltip>
              </div>
              <v-menu
                v-if="canShowList"
                offset-y
                transition="scale-transition"
                content-class=" tw-py-1 tw-mt-2 tw-bg-white tw-rounded-md tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 "
              >
                <template #activator="{ on, attrs }">
                  <button
                    type="button"
                    class="tw-inline-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span class="tw-sr-only">Open options</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="tw-h-5 tw-w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                      />
                    </svg>
                  </button>
                </template>
                <v-list class="tw-w-40">
                  <v-list-item
                    v-if="hasLine && isAdmin"
                    @click="toggleVisibilityComment"
                  >
                    <v-list-item-title> Make visible </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="canDelete" @click="editComment">
                    <v-list-item-title> Edit </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="canDelete" @click="deleteComment">
                    <v-list-item-title> Delete </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="tw-mt-2 tw-text-sm tw-text-gray-700">
            <transition name="fade-transition" mode="out-in">
              <p v-if="!isEditMode" key="view_mode" class="tw-mb-0">
                {{ questionAnswer.body }}
              </p>

              <div
                v-if="isEditMode"
                key="edit_mode"
                class="tw-min-w-0 tw-flex-1"
              >
                <form @submit.prevent="submit">
                  <div>
                    <v-textarea
                      v-model="body"
                      :disabled="loading"
                      auto-grow
                      dense
                      outlined
                      hide-details=""
                      solo
                      filled
                      class="tw-max-h-64 tw-overflow-y-auto"
                      :placeholder="$t('app.msg_placeholder')"
                      rows="3"
                      @keydown.enter.exact.prevent
                      @keyup.enter.exact="submit"
                    />
                  </div>
                  <div
                    class="tw-mt-6 tw-flex tw-items-center tw-justify-end tw-space-x-3"
                  >
                    <AppBtn
                      platform
                      color="transparent"
                      :disabled="loading"
                      type="button"
                      @click="cancelUpdate"
                    >
                      Cancelar
                    </AppBtn>
                    <AppBtn
                      platform
                      color="white"
                      :disabled="loading"
                      type="submit"
                    >
                      Actualizar
                    </AppBtn>
                  </div>
                </form>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <AppConfirmDialog ref="deleteModal" />
  </li>
</template>

<script>
import {
  deleteQuestionAnswer,
  updateQuestionAnswer,
} from '@/graphql/mutations/threadMutations'
import AppBtn from '../App/AppBtn.vue'
import AppConfirmDialog from '../App/AppConfirmDialog.vue'
import { mapState } from 'vuex'
import { isObjectEmpty } from '@/utils/isObjectEmpty'
export default {
  components: { AppBtn, AppConfirmDialog },
  props: {
    questionAnswer: {
      type: Object,
      default: () => ({}),
    },
    hasLine: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    body: '',
    loading: false,
    isEditMode: false,
  }),
  computed: {
    ...mapState({
      current_user: (state) => state.session.user,
    }),
    canShowVisibility() {
      if (!this.hasLine) {
        return false
      }
      return this.isUserComment || this.isAdmin
    },
    canShowList() {
      return this.isAdmin || this.isUserComment
    },
    canDelete() {
      return this.isAdmin || this.isUserComment
    },
    isUserComment() {
      return this.questionAnswer.user.id == this.current_user.id
    },
    isAdmin() {
      return this.current_user.roles.map((row) => row.name).includes('admin')
    },
    canShowLine() {
      if (!this.hasLine) {
        return false
      }
      if (this.isAdmin) {
        return true
      }
      return !isObjectEmpty(this.questionAnswer.reply)
    },
  },
  methods: {
    toggleVisibilityComment() {
      this.loading = true
      this.body = this.questionAnswer.body
      this.update(!this.questionAnswer.is_public)
    },
    async deleteComment() {
      const has_accepted = await this.$refs.deleteModal.open(
        'Eliminar',
        'Comentario sera eliminado',
        {
          color: 'red',
        },
      )
      if (has_accepted) {
        this.$apollo.mutate({
          mutation: deleteQuestionAnswer,
          variables: {
            content: {
              id: this.questionAnswer.id,
            },
          },
        })
      }
    },
    cancelUpdate() {
      this.isEditMode = false
    },
    editComment() {
      this.body = this.questionAnswer.body
      this.isEditMode = true
    },
    update(is_public) {
      this.$apollo
        .mutate({
          mutation: updateQuestionAnswer,
          variables: {
            content: {
              body: this.body,
              id: this.questionAnswer.id,
              is_public: is_public ? 1 : 0,
            },
          },
        })
        .finally(() => {
          this.body = ''
          this.isEditMode = false
          this.loading = false
        })
    },
    submit() {
      if (this.body === '') {
        return
      }
      this.loading = true
      this.update(this.questionAnswer.is_public)
    },
  },
}
</script>

<style></style>
