import { Marked } from '@ts-stack/markdown'
export const EventEmailBase = ({
  sender_name = '',
  sender_email = '',
  tracker_code = '',
  subject = '',
  header_img = '',
  header_img_click_url = '',
  footer_img = '',
  footer_img_click_url = '',
  body_img = '',
  body_img_click_url = '',
  has_header_img = false,
  has_footer_img = false,
  is_simple = '',
  body = '',
  is_send_certificate = false,
}) => ({
  sender_name,
  sender_email,
  tracker_code,
  subject,
  header_img,
  header_img_click_url,
  footer_img,
  footer_img_click_url,
  body_img,
  body_img_click_url,
  has_header_img,
  has_footer_img,
  is_simple,
  body,
  is_send_certificate,
})

export const EventEmailHydrate = ({ body, ...fields }) => ({
  ...fields,
  body: Marked.parse(body),
})
