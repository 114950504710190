<template>
<v-container class="fill-height tw-flex tw-items-start tw-overflow-y-scroll tw-bg-gray-100" fluid>
    <v-row id="registerForm" class="tw-py-2">
        <!-- <pre class="tw-overflow-y-scroll">
            {{ current_language_key }}
        </pre> -->
        <v-col cols="12">
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                autocomplete="off"
                :disabled="loading"
                @submit.prevent="register"
            >
                <div class="tw-mx-auto tw-max-w-lg tw-space-y-6 tw-px-4 sm:tw-px-6 md:tw-max-w-5xl lg:tw-px-8">
                    <v-card class="tw-rounded-3xl tw-shadow-md">
                        <v-container class="tw-px-4 tw-py-6 sm:tw-p-6">
                            <v-alert
                                v-if="alertCreate"
                                text
                                :type="alertType"
                                dismissible
                                class="tw-rounded-2xl"
                            >
                                {{ alertMsg }}
                            </v-alert>
                            <div>
                                <h2 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                                    {{ $t('pages.registry.register.main_form_title') }}
                                </h2>
                            </div>
                            <div class="tw-mt-6">
                                <div>
                                    <!-- Email -->
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                ref="email"
                                                v-model="form.email"
                                                prepend-inner-icon="alternate_email"
                                                :label="$t('forms.email')"
                                                type="email"
                                                outlined
                                                validate-on-blur
                                                :rules="[rules.required, rules.email]"
                                                rounded
                                            />
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <TicketAutocomplete
                                                v-if="registry.is_multi_ticket"
                                                v-model="ticket_id"
                                                :tickets="registry.registry_tickets"
                                            />
                                        </v-col>
                                    </v-row>
                                </div>
                                <div v-if="registry.has_password">
                                    <!-- Password -->
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                v-model="form.password"
                                                :label="$t('forms.password')"
                                                :rules="[rules.required]"
                                                outlined
                                                :append-icon="
                                                    show_password 
                                                    ? 'visibility_off' 
                                                    : 'visibility'
                                                "
                                                :type="
                                                    show_password ? 'text' : 'password'
                                                "
                                                validate-on-blur
                                                rounded
                                                @click:append="show_password = !show_password"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                v-model="form.password_confirmation"
                                                :label="$t('forms.confirm_password')"
                                                :append-icon="
                                                    show_password_confirmation
                                                    ? 'visibility_off'
                                                    : 'visibility'
                                                "
                                                :type="
                                                    show_password_confirmation ? 'text' : 'password'
                                                "
                                                :rules="[rules.required, passwordMatch]"
                                                outlined
                                                validate-on-blur
                                                required
                                                rounded
                                                @click:append="
                                                    show_password_confirmation =
                                                    !show_password_confirmation
                                                "
                                            />
                                        </v-col>
                                    </v-row>
                                </div>
                                <div>
                                    <!-- Firstname and Lastname -->
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                v-model="form.first_name"
                                                :label="$t('forms.first_name')"
                                                :rules="[rules.required]"
                                                type="text"
                                                outlined
                                                validate-on-blur
                                                required
                                                rounded
                                            />
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                v-model="form.last_name"
                                                :label="$t('forms.last_name')"
                                                :rules="[rules.required]"
                                                type="text"
                                                outlined
                                                validate-on-blur
                                                required
                                                rounded
                                            />
                                        </v-col>
                                    </v-row>
                                </div>
                                <div>
                                    <!-- Default Fields -->
                                    <div class="tw-grid tw-gap-x-6 tw-gap-y-2 lg:tw-grid-cols-2">
                                        <template v-for="default_field in default_fields">
                                            <div
                                                v-if="default_field.is_active"
                                                :key="default_field.name"
                                            >
                                                <select-countries
                                                    v-if="default_field.name === 'country_code'"
                                                    v-model="form[default_field.name]"
                                                    :label="default_field.label"
                                                    :rules="
                                                        default_field.is_required ? [rules.required] : []
                                                    "
                                                    outlined
                                                    rounded
                                                    validate-on-blur
                                                />
                                                <v-text-field
                                                    v-else
                                                    v-model="form[default_field.name]"
                                                    :label="default_field.label"
                                                    :name="default_field.name"
                                                    :rules="
                                                        default_field.is_required ? [rules.required] : []
                                                    "
                                                    type="text"
                                                    outlined
                                                    validate-on-blur
                                                    rounded
                                                />
                                            </div>
                                        </template>
                                    </div>
                                    <!-- Custom Fields -->
                                    <div class="tw-grid tw-gap-x-6 tw-gap-y-4 tw-grid-cols-12">
                                        <div 
                                            v-for="custom_field in registry_form_submission"
                                            :key="custom_field.uid"
                                            class="tw-col-span-12"
                                            :class="matchCols[custom_field.cols]"
                                        >
                                            <template v-if="custom_field.field_type == 'input'">
                                                <v-text-field
                                                    v-model="custom_field.model"
                                                    :label="custom_field.label"
                                                    :name="custom_field.name"
                                                    :rules="
                                                        custom_field.is_required ? [rules.required] : []
                                                    "
                                                    type="text"
                                                    outlined
                                                    validate-on-blur
                                                    required
                                                    rounded
                                                />
                                            </template>
                                            <template v-if="custom_field.field_type == 'textarea'">
                                                <v-textarea
                                                    v-model="custom_field.model"
                                                    :label="custom_field.label"
                                                    :rules="
                                                        custom_field.is_required ? [rules.required] : []
                                                    "
                                                    :name="custom_field.name"
                                                    outlined
                                                    validate-on-blur
                                                    required
                                                    rounded
                                                />
                                            </template>
                                            <template v-if="custom_field.field_type == 'radio'">
                                                <v-radio-group
                                                    v-model="custom_field.model"
                                                    class="tw-mt-0"
                                                    :label="custom_field.label"
                                                    :rules="
                                                        custom_field.is_required ? [rules.required] : []
                                                    "
                                                >
                                                    <v-radio
                                                        v-for="option in custom_field.options"
                                                        :key="option.uid"
                                                        :label="option.text"
                                                        :value="option.value"
                                                    />
                                                </v-radio-group>
                                            </template>
                                            <template v-if="custom_field.field_type == 'checkbox'">
                                                <div>
                                                    <label
                                                        for="first_name"
                                                        class="tw-block tw-text-sm tw-font-medium tw-text-slate-900"
                                                    >
                                                        {{ custom_field.label }}
                                                    </label>
                                                    <v-checkbox
                                                        v-for="option in custom_field.options"
                                                        :key="option.uid"
                                                        v-model="custom_field.model_multiple"
                                                        class="tw-mt-0"
                                                        :rules="
                                                            custom_field.is_required
                                                            ? [rules.array_not_empty]
                                                            : []
                                                        "
                                                        :label="option.text"
                                                        :value="option.value"
                                                    />
                                                </div>
                                            </template>
                                            <template 
                                                v-if="
                                                    custom_field.field_type == 'select' 
                                                    && custom_field.label != 'Departments' 
                                                    && custom_field.label != 'Provinces'
                                                    && custom_field.label != 'Districts'
                                                "
                                            >
                                                <v-select
                                                    v-model="custom_field.model"
                                                    :label="custom_field.label"
                                                    outlined
                                                    rounded
                                                    :rules="
                                                        custom_field.is_required ? [rules.required] : []
                                                    "
                                                    :items="custom_field.options"
                                                />
                                            </template>
                                            <template v-if="custom_field.field_type == 'number'">
                                                <v-text-field
                                                    v-model="custom_field.model"
                                                    :label="custom_field.label"
                                                    :name="custom_field.name"
                                                    :rules="
                                                        custom_field.is_required ? [rules.required] : []
                                                    "
                                                    outlined
                                                    validate-on-blur
                                                    required
                                                    rounded
                                                    :maxlength="custom_field.characters"
                                                    counter
                                                    @keypress="onlyNumber"
                                                />
                                            </template>
                                            <template v-if="custom_field.field_type == 'date'">
                                                <v-text-field
                                                    v-model="custom_field.model"
                                                    :label="custom_field.label"
                                                    :name="custom_field.name"
                                                    :rules="
                                                        custom_field.is_required ? [rules.required] : []
                                                    "
                                                    type="date"
                                                    outlined
                                                    validate-on-blur
                                                    required
                                                    rounded
                                                />
                                            </template>
                                            <template v-if="custom_field.field_type == 'select' && custom_field.label == 'Departments'">
                                                <DepartmentsAutocomplete 
                                                    v-model="custom_field.model" 
                                                    :rules="custom_field.is_required ? [rules.required] : []"
                                                    @change="listProvinceByDepartments"
                                                />
                                            </template>
                                            <template v-if="custom_field.field_type == 'select' && custom_field.label == 'Provinces'">
                                                <ProvincesAutocomplete 
                                                    v-model="custom_field.model" 
                                                    :search="itemsProvinces" 
                                                    :rules="custom_field.is_required ? [rules.required] : []"
                                                    @change="listDistricByProvinces"
                                                />
                                            </template>
                                            <template v-if="custom_field.field_type == 'select' && custom_field.label == 'Districts'">
                                                <DistrictsAutocomplete 
                                                    v-model="custom_field.model"
                                                    :search="itemsDistritcs"
                                                    :rules="custom_field.is_required ? [rules.required] : []"
                                                />
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tw-flex tw-flex-col tw-justify-between sm:tw-flex-row">
                                <div />
                                <div class="tw-flex tw-items-center tw-justify-center">
                                    <v-switch
                                        v-if="registry.has_payment"
                                        v-model="has_payment"
                                        inset
                                        :label="$t('pages.event.mod_dash.actions.paid')"
                                        class="tw-mr-6"
                                    />
                                </div>
                            </div>
                        </v-container>
                    </v-card>
                </div>
            </v-form>
        </v-col>
    </v-row>
    <v-dialog
        v-model="loading"
        hide-overlay
        persistent
        width="250"
        content-class="tw-rounded-2xl"
    >
        <v-card color="primary" dark class="tw-rounded-2xl">
            <v-card-text class="tw-pt-3 tw-text-xs tw-text-center">
                {{ $t('app.registering_user') }}
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0 tw-mt-1"
                />
            </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import SelectCountries from '@/components/FormInputs/SelectCountries.vue'
import { rules } from '@/utils/rules'
import TicketAutocomplete from '../TicketAutocomplete.vue'
import { matchCols } from '@/utils/formCols'
import { addModelText, onlyNumber } from '@/utils/Settings'
import DepartmentsAutocomplete from '@/components/DepartmentsAutocomplete'
import ProvincesAutocomplete from '@/components/ProvincesAutocomplete'
import DistrictsAutocomplete from '@/components/DistrictsAutocomplete'
import {
    mapState,
} from 'vuex'
export default {
    components: {
        SelectCountries,
        TicketAutocomplete,
        // Components Depa, Prov, Dist
        DepartmentsAutocomplete,
        ProvincesAutocomplete,
        DistrictsAutocomplete,
    },
    data: () => ({
        matchCols,
        loading: false,
        show_password: false,
        show_password_confirmation: false,
        errorAlert: '',
        errorMsg: '',
        rules,
        valid: true,
        form: {
            email: '',
            first_name: '',
            last_name: '',
            password: '',
            password_confirmation: '',
            company: '',
            title: '',
            country_code: '',
            address: '',
            city: '',
            phone_number: '',
        },
        registry_form_submission: [],
        default_fields: [],
        ticket_id: '',
        has_payment: false,
        alertCreate: false,
        alertType: '',
        alertMsg: '',
         // Selects Depa - Prov - Dist
        itemsProvinces: null,
        itemsDistritcs: null,
        onlyNumber,
    }),
    computed: {
        ...mapState({
            current_language_key: (state) => state.registry.show.current_language_key,
            registry: (state) => state.event.constants.registry,
            registry_status: (state) => state.registry.show.registry_status,
        }),
        current_form() {
            return this.registry.forms.find(
                (row) => row.language.name === this.current_language_key,
            )
        },
    },
    watch: {
        alertCreate(new_val){
            if(new_val){
                setTimeout(()=>{this.alertCreate=false}, 5000)
            }
        },
        current_language_key: {
            handler() {
                this.$nextTick(() => {
                    this.initForm()
                })
            },
        },
    },
    mounted() {
        this.initForm()
    },
    methods: {
        // TODO: Search
        listProvinceByDepartments(e) {
            this.itemsProvinces = e
        },
        listDistricByProvinces(e) {
            this.itemsDistritcs = e
        },
        // TODO: End Search
        initForm() {
            const current_form = JSON.parse(JSON.stringify(this.current_form))
            console.log(current_form)
            this.registry_form_submission = current_form.fields
            this.default_fields = current_form.default_fields.filter(
                (row) => row.name !== 'email',
            )
        },
        passwordMatch() {
            return this.form.password === this.form.password_confirmation
                ? true
                : 'Contraseña no es igual'
        },
        scrollTopForm() {
            const element = document.getElementById('registerForm')
            element.scrollIntoView()
        },
        register() {
            // console.log('Forms',this.registry_form_submission, this.form)
            /**
             * key -> value
             * custom -> [ ...fields ]
             */
            if (!this.$refs.form.validate()) {
                return
            }
            this.errorAlert = false
            const payload = {
                uuid: this.current_form.uuid,
                body: {
                    ...this.form,
                    registry_form_submission: addModelText(this.registry_form_submission),
                    registry_ticket_id: this.ticket_id,
                    has_payment: this.has_payment,
                },
            }
            this.loading = true
            return this.$store.dispatch('session/registerEventMod', payload).then(() => {
                this.scrollTopForm()
                this.alertCreate = true
                this.alertType = 'success'
                this.alertMsg = this.$t(`app.registered_user`)
            }).catch((err) => {
                this.alertCreate = true
                this.alertType = 'error'
                this.alertMsg = err
                // this.errorAlert = true
                // this.errorMsg = err
            }).finally(() => {
                this.form = {
                    email: '',
                    first_name: '',
                    last_name: '',
                    password: '',
                    password_confirmation: '',
                    company: '',
                    title: '',
                    country_code: '',
                    address: '',
                    city: '',
                    phone_number: '',
                }
                const current_form = JSON.parse(JSON.stringify(this.current_form))
                this.registry_form_submission = current_form.fields
                this.ticket_id = '',
                this.has_payment = false,
                this.loading = false
            })
        },
    },
}
</script>

<style>
</style>
