var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"640","content-class":"tw-self-end tw-rounded-3xl sm:tw-self-auto"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{staticClass:"tw-shadow-md"},[_c('v-container',{staticClass:"tw-relative tw-z-10 tw-p-0",attrs:{"fluid":""}},[_c('div',{staticClass:"tw-absolute tw-right-3 tw-top-3"},[_c('v-btn',{staticClass:"focus:tw-outline-none focus:tw-ring",attrs:{"fab":"","x-small":"","text":""},on:{"click":function($event){_vm.open = false}}},[_c('v-icon',[_vm._v("close")])],1)],1)]),(
        _vm.notifyInfo.overlay_type ==
        _vm.EventBroadcastOverlayStyleKeys.overlay_plain
      )?_c('v-card-text',{staticClass:"pt-2"},[_c('h1',{staticClass:"text-center mb-2 pt-5 pb-5 tw-text-2xl"},[_vm._v(" "+_vm._s(_vm.notifyInfo.title)+" ")]),_c('p',{staticClass:"tw-text-lg",domProps:{"innerHTML":_vm._s(_vm.notifyInfo.body)}})]):_vm._e(),(
        _vm.notifyInfo.overlay_type ==
        _vm.EventBroadcastOverlayStyleKeys.overlay_image
      )?_c('v-card-text',{staticClass:"tw-relative tw-z-0 tw-p-0"},[_c('v-img',{staticClass:"tw-aspect-w-1 tw-aspect-h-1",attrs:{"src":_vm.notifyInfo.overlay_image_url}})],1):_vm._e(),_c('v-divider'),(_vm.notifyInfo.clickable)?_c('v-card-actions',[_c('v-container',{staticClass:"tw-px-0 tw-py-2"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-justify-center sm:tw-flex-row sm:tw-space-x-3"},[_c('RenderlessClickableHandle',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var slotClickActionEvent = ref.slotClickActionEvent;
return [_c('AppBtn',{attrs:{"color":"primary"},on:{"click":function () {
                    _vm.open = false
                    return slotClickActionEvent({
                      clickable: _vm.notifyInfo.clickable,
                    })
                  }}},[_vm._v(" "+_vm._s(_vm.notifyInfo.action_label)+" ")])]}}],null,false,789826945)})],1)])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }