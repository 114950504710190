import $axios from '@/axios-instance.js'

class ThreadsResponsesServiceClass {
  baseUrl = '/api/v1/admin/threads'
  child = 'responses'

  // Read all - findAll
  index(channel, params) {
    return $axios.get(`${this.baseUrl}/${channel}/${this.child}`, params)
  }

  // Read one - show
  show(channel, id, params) {
    return $axios.get(`${this.baseUrl}/${channel}/${this.child}/${id}`, params)
  }
}
export const ThreadsResponsesService = new ThreadsResponsesServiceClass()
