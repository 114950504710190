import { EventEmailBase } from './EventEmail'

export const RegistryEmailBase = ({
  registry_email_type = '',
  is_active = true,
  email = {},
  lang_key = '',
}) => ({
  registry_email_type,
  is_active,
  lang_key,
  email: EventEmailBase(email),
})
