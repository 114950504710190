<template>
  <v-menu
    v-model="date_menu"
    :close-on-content-click="false"
    transition="scale-transition"
    max-width="290px"
    min-width="290px"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-if="!isRange"
        ref="date"
        v-model="input_value"
        readonly
        v-bind="attrs"
        type="text"
        rounded
        :label="$t('admin.app.date')"
        outlined
        :color="inputColor"
        :dark="inputDark"
        :dense="isDense"
        v-on="on"
      />
      <v-text-field
        v-else
        ref="date"
        v-model="dateRangeText"
        readonly
        v-bind="attrs"
        type="text"
        rounded
        :label="$t('admin.app.date')"
        outlined
        :color="inputColor"
        :dark="inputDark"
        :dense="isDense"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="input_value"
      :min="dateMin"
      no-title
      :range="isRange"
      class="taypa-radius"
      @input="closeModal"
    />
    <!--  -->
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Array],
      default: '',
    },
    dateMin: {
      type: String,
      default: new Date(new Date().setDate(new Date().getDate() - 1))
        .toISOString()
        .substr(0, 10),
    },
    inputColor: {
      type: String,
      default: 'primary',
    },
    inputDark: {
      type: Boolean,
      default: false,
    },
    isDense: {
      type: Boolean,
      default: false,
    },
    isRange: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    date_menu: false,
  }),
  computed: {
    dateRangeText() {
      return this.value.join(' ~ ')
    },
    input_value: {
      get() {
        return this.value
      },
      set(val) {
        console.log(val)
        this.$emit('input', val)
      },
    },
  },
  methods: {
    closeModal() {
      if (this.isRange) {
        return
      }
      this.date_menu = false
    },
  },
}
</script>

<style></style>
