export const mutations = {
  setAppIsLoading(state, bool) {
    state.appIsLoading = bool
  },
  setAppLoaded(state, bool) {
    state.appLoaded = bool
  },
  isLoading(state, payload) {
    state.isLoading = payload
  },
  notificationsRemove(state, payload) {
    state.notifications = state.notifications.filter((n) => n.id !== payload)
  },
  drawer(state, { bool }) {
    state.drawer = bool
  },
  setLeftDrawer(state, { bool }) {
    state.leftDrawer = bool
  },
  setFullScreenLayout(state, { bool }) {
    state.full_screen_layout = bool
  },
  setGlobalState(state, obj) {
    Object.keys(obj).forEach((k) => (state[k] = obj[k]))
  },
}
