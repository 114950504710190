<template>
  <v-dialog
    v-model="open"
    :persistent="!!video.can_chat"
    :content-class="
      video.can_chat
        ? 'tw-w-full tw-rounded-3xl tw-max-w-7xl 2xl:tw-max-w-screen-2xl fill-height tw-max-h-[640px]'
        : 'tw-w-full tw-rounded-3xl tw-max-w-5xl 2xl:tw-max-w-7xl  tw-max-h-full'
    "
  >
    <ElementVideo :video="video" :can-close="true" />
    <!-- <v-card
      class="tw-relative tw-rounded-3xl tw-border-0 tw-bg-black tw-bg-opacity-75 tw-shadow-md"
      :class="[video.can_chat ? 'fill-height' : 'tw-aspect-h-9']"
    >
      <v-container
        id="iframe-wrapper"
        class="tw-flex-nowrap tw-p-0"
        :class="[video.can_chat ? 'fill-height' : 'tw-aspect-h-9']"
        fluid
      >
        <v-container
          class="tw-flex-1 tw-flex-grow tw-bg-transparent tw-p-0 tw-shadow-none"
          fluid
        >
          <AppPlyr v-if="simpleVideoItemType == 'uploaded'">
            <video
              controls
              crossorigin
              playsinline
              :data-poster="simpleVideoItemPreview"
            >
              <source :src="video.video_item_url" type="video/mp4" />
            </video>
          </AppPlyr>

          <vimeo-player
            v-if="simpleVideoItemType == 'vimeo'"
            ref="player"
            class="tw-aspect-w-16 tw-aspect-h-9"
            :video-url="video.video_item_url"
          />
          <video-embed
            v-if="simpleVideoItemType == 'youtube'"
            :src="video.video_item_url"
            class="tw-aspect-w-16 tw-aspect-h-9"
          />
        </v-container>
        <EventRoomContentElementChat
          v-if="video.can_chat && loaded"
          :can-close="true"
          :parrent-channel-id="channel_id"
          :can-chat="video.can_chat"
          :can-thread="video.can_thread"
          :parrent-thread-id="thread_id"
        />
      </v-container>
    </v-card> -->
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { VideoValues } from '@/enums/VideoKeys'
import ElementVideo from './ElementVideo.vue'
export default {
  components: {
    ElementVideo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    channel_id: 0,
    thread_id: 0,
    loaded: false,
  }),
  computed: {
    ...mapState({
      video: (state) => state.event.contentVideo.contentVideoData,
    }),
    // iframeLink() {
    //   if (ContentTypeKeys.download == this.contentItemData.content_type.name) {
    //     return this.contentItemData.download_item_url
    //   }
    //   return this.contentItemData.link_item_url
    // },
    simpleVideoItemType() {
      return VideoValues[this.video.video_item_type_id]
    },
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  mounted() {
    console.log(this.video.video_item_url)
    console.log(this.video.can_chat)
    console.log(this.video.can_thread)
    console.log(this.video.video_item_url)
    console.log(this.video.is_autoplay)
    if (this.video.channels[0]) {
      this.channel_id = this.video.channels[0].id
    }
    if (this.video.threads[0]) {
      this.thread_id = this.video.threads[0].id
    }
    this.$nextTick(() => {
      this.loaded = true
    })
    // console.log(this.video.channels[0].id)
  },

  methods: {
    closeModal() {
      this.open = false
    },
  },
}
</script>

<style></style>
