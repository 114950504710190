<template>
  <h4 class="tw-text-2xl tw-font-bold tw-text-primary-50" :class="textColor">
    <slot />
  </h4>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    textColor() {
      return this.dark ? 'tw-text-gray-50' : 'tw-text-gray-900'
    },
  },
}
</script>

<style></style>
