<template>
  <footer class="tw-bg-landing-primary" aria-labelledby="footerHeading">
    <h2 id="footerHeading" class="tw-sr-only">Footer</h2>
    <div 
      class="bz-footer md:tw-flex tw-items-center tw-mx-auto tw-max-w-md sm:tw-max-w-7xl tw-py-8 tw-px-4"
      :class="section.length === 0 ? 'tw-justify-center':'tw-justify-between'"
    >
      <!-- All rights reserved -->
      <div class="tw-text-gray-100 tw-text-center md:tw-text-left">
        &copy; {{ year }} BIZZEVENTS, All rights reserved.
      </div>
      <!-- Social Media -->
      <div class="tw-text-center md:tw-text-right">
        <div v-if="section.length !== 0" class="bz_social_media tw-pt-4 md:tw-pt-0">
          <span class="bz_social_media_title">{{ section.title }}</span>
          <a
            v-for="logo in processedSection"
            :key="logo.uid"
            :href="logo.link"
            target="_blank">
            <img
              :src="logo.logo_url"
              class="bz-icon-social"
              :alt="logo.uid"
            />
          </a>  
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    section: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    year: new Date().getFullYear(),
  }),
  computed: {
    processedSection() {
      return this.section?.logos
    },
  },
}
</script>

<style>
.bz-icon-social {
  width: 1.8rem;
  height: auto;
  margin: 0 4px 0 4px;
}
.bz_social_media {
    display: flex;
    align-items: center;
    justify-content: center;
}
.bz_social_media_title {
  margin-right: 0.5rem;
  font-size: 14px;
}
</style>
