<template>
    <UserProfileTabWrapper>
        <div class="tw-flex tw-h-full tw-flex-col tw-px-4 lg:tw-px-6 tw-py-8">
            <!-- ------------------ Automatic Matchmaking ------------------ -->
            <div class="tw-mb-4">
                <v-card class="tw-rounded-xl tw-w-full tw-shadow-md">
                    <v-toolbar color="primary" class="tw-h-10 bz-tbm-title tw-font-semibold" flat dark>{{ $t('bz2.event.matchmaking.match_automatic_title') }}</v-toolbar>
                    <v-card-text class="tw-flex">
                        <AppBtn type="button" color="transparent-primary" :loading="user_match_loading" icon-center="refresh" class="tw-mx-auto tw-mr-2" fab small @click="automatch"/>
                        <div class="tw-w-full">
                            <template v-if="!user_match_loading">
                                <UserItemMaking :user="user_match"/>
                            </template>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
            <!-- ------------------ Custom | Filters  ------------------ -->
            <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-4">
                <v-card class="tw-rounded-xl tw-shadow-md tw-px-4 tw-py-2 tw-col-span-12 lg:tw-col-span-4">
                    <div class="tw-flex tw-items-center tw-justify-between tw-pt-4 tw-pb-2">
                        <div class="tw-flex tw-items-center">
                            <v-icon>tune</v-icon>
                            <span class="tw-ml-2 tw-font-semibold">Filters</span>
                        </div>
                        <v-chip 
                            v-if="users_match.length != 0"
                            outlined 
                            small
                            color="orange" 
                            @click="filterClean"
                        >{{ $t('bz2.event.matchmaking.filter_clean') }}</v-chip>
                    </div>
                    <v-divider />
                    <div v-for="cf in current_fields" :key="cf.uid" class="tw-pt-6">
                        <p class="mb-2 tw-font-semibold">{{cf.label}} </p>
                        <template v-if="cf.field_type == 'select' || cf.field_type == 'checkbox'">
                            <v-chip-group v-model="filter.preferences" column multiple active-class="secondary--text">
                                <v-chip 
                                    v-for="jobs in cf.options" :key="jobs.uid"
                                    filter 
                                    outlined 
                                    small
                                    :value="jobs.value"
                                >
                                    {{ jobs.text }}
                                </v-chip>
                            </v-chip-group>
                        </template>
                        <template v-if="cf.field_type == 'number'">
                            <v-range-slider
                                v-model="filter.range_age"
                                max="100"
                                min="18"
                                thumb-label
                                ticks
                                hide-details="auto"
                            />
                        </template>
                    </div>
                    <div class="tw-pt-6 tw-pb-4">
                        <AppBtn type="button" color="primary" class="tw-mx-auto" block @click="filterMatch">
                            {{ $t('admin.ticket.items.action.button') }}    
                        </AppBtn>
                    </div>
                </v-card>
                <v-card class="tw-rounded-xl tw-shadow-md tw-px-4 tw-py-6 tw-col-span-12 lg:tw-col-span-8 tw-space-y-2">
                    <v-progress-linear
                        :active="users_match_loading"
                        :indeterminate="users_match_loading"
                        absolute
                        top
                        color="primary"
                    />
                    <template v-if="users_match.length === 0">
                        <div class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">
                            <g-icon
                                name="matchmaking"
                                class="tw-h-10 tw-w-10 tw-text-gray-400"
                            />
                        </div>
                    </template>
                    <template v-else>
                        <v-data-table
                            :headers="headers"
                            :items="formatUsersMatch"
                            :options.sync="options"
                            :items-per-page="5"
                            :server-items-length="totalRows"
                            class="elevation-0"
                            :footer-props="footer_props"
                        >
                            <template #body="{ items }">
                                <tbody v-if="items.length" key="main-table">
                                    <tr v-for="item in items" :key="item.id" class="tw-cursor-pointer tw-transition sm:tw-py-4">
                                        <td class="tw-text-xs">
                                            {{ item.fullname }}
                                        </td>
                                        <td class="tw-text-xs">
                                            <v-chip v-for="s in item.sector" :key="s.uid" x-small color="secondary" outlined class="tw-ml-1">
                                                {{ s.model }}
                                            </v-chip>
                                        </td>
                                        <td class="tw-text-xs">
                                            <RenderlessClickableBzdHandle>
                                                <template slot-scope="{ slotViewAttendee, slotAddContact, slotOpenChat, contact_loading, slotAdded }">
                                                    <div class="tw-flex tw-space-x-2 tw-justify-center lg:tw-justify-start">
                                                        <v-btn color="indigo" icon elevation="0" rounded @click="slotViewAttendee(item)">
                                                            <v-icon>account_box</v-icon>
                                                        </v-btn>
                                                        <v-btn 
                                                            color="success" 
                                                            icon 
                                                            elevation="0" 
                                                            :loading="contact_loading" 
                                                            rounded 
                                                            :disabled="slotAdded(item.id)"
                                                            @click="slotAddContact(item)"
                                                        >
                                                            <v-icon>
                                                                {{ slotAdded(item.id) ? 'how_to_reg': 'person_add' }}
                                                            </v-icon>
                                                        </v-btn>
                                                        <v-btn color="secondary" icon elevation="0" rounded>
                                                            <v-icon>calendar_month</v-icon>
                                                        </v-btn>
                                                        <v-btn 
                                                            color="primary" 
                                                            icon 
                                                            elevation="0" 
                                                            rounded
                                                            @click="slotOpenChat(item)"
                                                        >
                                                            <v-icon>chat</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </template>
                                            </RenderlessClickableBzdHandle>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </template>
                </v-card>
            </div>
        </div>
    </UserProfileTabWrapper>
</template>
<script>
import UserProfileTabWrapper from './UserProfileTabWrapper.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import UserItemMaking from '@/components/Event/UserItemMaking.vue'
import { mapFields } from '@/helpers'
import { mapState } from 'vuex'
import GIcon from '@/components/GIcon.vue'
import RenderlessClickableBzdHandle from '@/components/Event/RenderlessClickableBzdHandle.vue'
export default {
    components: {
        UserProfileTabWrapper,
        AppBtn,
        UserItemMaking,
        GIcon,
        RenderlessClickableBzdHandle,
    },
    data: () => ({
        filter: {
            preferences: [],
            range_age: [18, 100],
        },
        user_match: {},
        user_match_loading: false,
        users_match: [],
        totalRows: 0,
        options: {},
        users_match_loading: false,
    }),
    computed: {
        headers() {
            return [
                {
                    text: this.$t('admin.app.name'),
                    align: 'start',
                    sortable: false,
                    value:'fullname',
                },
                {
                    text: 'Sector',
                    align: 'start',
                    sortable: false,
                    value:'sector',
                },
                {
                    text: 'Actions',
                    align: 'start',
                    sortable: false,
                    value:'actions',
                },
            ].filter((row) => !row.hidden)
        },
        formatUsersMatch() {
            return this.users_match.map((row) => {
                return {
                    ...row,
                    fullname: `${row.first_name} ${row.last_name}`,
                    sector: row.attendees.find(
                        (attendee) => attendee.language.name == this.current_lang_key,
                    ).registry_form_submission,
                }
            })
        },
        footer_props() {
            const obj = {
                'items-per-page-options': [5, 10, 20, 30, 40, 50],
            }
            return obj
        },
        ...mapFields({
            fields: [
                'users',
            ],
            base: ['event','socket'],
            mutation: 'event/setSocket',
        }),
        ...mapState({
            registry: (state) => state.event.constants.registry,
            registry_configs: (state) => state.event.constants.registry.configs,
            current_lang_key: (state) => state.event.constants.event_configs.data.current_config_language_key,
            profiles: (state) => state.event.constants.profiles,
        }),
        current_form() {
            return this.registry.forms.find(
                (row) => row.language.name === this.current_lang_key,
            )
        },
        current_registry_form() {
            return this.registry_configs.find((row) => row.language.name === this.current_lang_key)
        },
        current_fields() {
            return this.current_form.fields.filter(
                (row) => row.is_active,
            )
        },
        profile() {
            return this.profiles.find(
                (row) => row.registry_form_id === this.current_form.id,
            )
        },
        custom_fields_models() {
            return this.profile.registry_form_submission.map(
                (row) => row.model, 
            )
        },
    },
    watch: {
        options: {
            handler() {
                this.filterMatch()
            },
            deep: true,
        },
    },
    mounted() {
        this.automatch()
    },
    methods: {
        automatch() {
            this.user_match_loading = true
            this.$store.dispatch('event/getAutomaticMatch', {
                params: {
                    filter: this.custom_fields_models,
                    registry_form_id: this.current_registry_form.id,
                },
            })
            .then((res) => {
                this.user_match = res.user
                this.user_match_loading = false
            })
        },
        filterMatch() {
            this.users_match_loading = true

            const { page, itemsPerPage } = this.options
            const params = {
                params: {
                    page: page,
                    rowsPerPage: itemsPerPage ? itemsPerPage : 5,
                    filter: this.filter,
                    registry_form_id: this.current_registry_form.id,
                },
            }

            this.$store.dispatch('event/getFilterMatch', params)
            .then((res) => {
                const { total, data } = res.users
                this.users_match = data
                this.totalRows = total
            })
            .finally(() => {
                this.users_match_loading = false
            })
        },
        onPageChange() {
            this.filterMatch()
        },
        filterClean() {
            console.log('clean')
            this.users_match = []
            this.filter.preferences = []
            this.filter.range_age = [18, 100]
        },
    },
}
</script>
<style>
.bz-tbm-title .v-toolbar__content{
    height: 2.5rem !important;
}
</style>