<template>
  <!-- :class="roundedClasses"
    class="
      tw-relative tw-p-6 tw-bg-white tw-group
      group
      focus-within:tw-ring-2
      focus-within:tw-ring-inset
      focus-within:ring-primary-500
    " -->
  <div
    v-ripple
    v-bind="$attrs"
    :class="[
      indexItem === 0
        ? 'tw-rounded-tl-3xl tw-rounded-tr-3xl sm:tw-rounded-tr-none'
        : '',
      indexItem === 1 ? 'sm:tw-rounded-tr-3xl' : '',
      indexItem === lengthItems - 2 && lengthItems % 2 === 0
        ? 'sm:tw-rounded-bl-3xl'
        : '',
      indexItem === lengthItems - 1 && lengthItems % 2 === 0
        ? 'tw-rounded-bl-3xl tw-rounded-br-3xl sm:tw-rounded-bl-none'
        : '',
      indexItem === lengthItems - 1 && lengthItems % 2 !== 0
        ? 'sm:tw-rounded-bl-3xl'
        : '',
      'tw-group tw-relative tw-bg-white tw-p-6 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-primary-500',
    ]"
    v-on="$listeners"
  >
    <div>
      <span>
        <v-img v-if="markerType == 'img'" class="tw-h-16 tw-w-16" :src="icon" />
        <g-icon
          v-else
          :name="icon"
          :g-next="gNext"
          :md-icon="mdIcon"
          class="tw-h-16 tw-w-16 tw-text-primary-500"
        />
      </span>
    </div>
    <div class="tw-mt-4">
      <h3 class="tw-text-lg tw-font-bold tw-leading-6 tw-text-black">
        <button
          v-if="itemType === 'button'"
          type="button"
          class="tw-text-left focus:tw-outline-none"
        >
          <span class="tw-absolute tw-inset-0" aria-hidden="true" />
          {{ name }}
        </button>
        <router-link
          v-if="itemType === 'router-link'"
          :to="{ path: routeTo }"
          class="tw-text-left focus:tw-outline-none"
        >
          <span class="tw-absolute tw-inset-0" aria-hidden="true" />
          {{ name }}
        </router-link>
      </h3>
      <p class="tw-mt-2 tw-text-sm tw-text-gray-500">
        {{ details }}
      </p>
    </div>
    <span
      class="tw-pointer-events-none tw-absolute tw-top-6 tw-right-6 tw-text-gray-300 group-hover:tw-text-gray-400"
      aria-hidden="true"
    >
      <svg
        class="tw-h-6 tw-w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"
        />
      </svg>
    </span>
  </div>
</template>

<script>
import GIcon from '@/components/GIcon.vue'
export default {
  components: {
    GIcon,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    markerType: {
      type: String,
      default: 'img',
    },
    details: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    mdIcon: {
      type: String,
      default: '',
    },
    gNext: {
      type: String,
      default: '',
    },
    indexItem: {
      type: Number,
      default: 0,
    },
    lengthItems: {
      type: Number,
      default: 0,
    },
    itemType: {
      type: String,
      default: 'button',
      validator(value) {
        return ['button', 'router-link'].includes(value)
      },
    },
    routeTo: {
      type: String,
      default: '',
    },
  },
  computed: {
    index_item_postion() {
      return this.indexItem + 1
    },
    length_items_semi_last() {
      return this.lengthItems - 1
    },
    roundedClasses() {
      const index_item = this.checkItemPosition
      return {
        first: 'tw-rounded-tl-3xl tw-rounded-tr-3xl sm:tw-rounded-tr-none',
        second: 'sm:tw-rounded-tr-3xl',
        middle: '',
        semilast: 'sm:tw-rounded-bl-3xl',
        last: 'tw-rounded-bl-3xl tw-rounded-br-3xl sm:tw-rounded-bl-none',
      }[index_item]
    },
    checkItemPosition() {
      if (this.index_item_postion === 1) {
        return 'first'
      }
      if (this.index_item_postion === 2) {
        return 'second'
      }
      if (this.index_item_postion === this.length_items_semi_last) {
        return 'semilast'
      }
      if (this.index_item_postion === this.lengthItems) {
        return 'last'
      }
      return 'middle'
    },
  },
}
</script>

<style></style>
