/**
 *
 * @param {number} numerator
 * @param {number} denominator
 * @returns {array}
 */
export const greatestCommonDivisor = (numerator, denominator) => {
  let gcd = function gcd(a, b) {
    return b ? gcd(b, a % b) : a
  }
  gcd = gcd(numerator, denominator)
  return [numerator / gcd, denominator / gcd]
}
