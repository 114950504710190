import Resource from './BaseResource'

class CredentialResource extends Resource {
  /**
   * The constructor for the CredentialResource.
   */
  constructor() {
    super('/api/v1/admin/credential')
  }
}

export default new CredentialResource()
