<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="1024px"
    content-class="tw-rounded-t-3xl  fill-height tw-max-h-[640px] tw-w-full"
  >
    <v-card class="fill-height tw-border-0 tw-shadow-md">
      <v-container class="tw-p-0" fluid>
        <div
          class="tw-relative tw-grid tw-grid-cols-6 tw-items-center tw-rounded-t-3xl tw-bg-primary-500 tw-pl-5"
        >
          <div class="tw-col-span-5 tw-flex tw-items-end">
            <v-tabs
              v-model="tab"
              dark
              background-color="tw-bg-primary-500"
              show-arrows
            >
              <v-tabs-slider color="primary lighten-3" />
              <v-tab
                v-for="item in items"
                :key="item.name"
                :href="`#${item.name}`"
                class="tw-py-3 tw-px-6"
              >
                {{ item.label }}
              </v-tab>
            </v-tabs>
          </div>
          <div
            class="tw-flex tw-h-full tw-items-center tw-justify-end tw-space-x-3 tw-px-2"
          >
            <v-select
              v-model="currentSelectedSlug"
              :label="$t('pages.event.container.upper_nav.user_dropdown.rooms')"
              :items="myRooms"
              rounded
              hide-details=""
              outlined
              dense
              dark
            />
            <v-fab-transition>
              <v-btn
                fab
                x-small
                class="focus:tw-outline-none focus:tw-ring"
                @click="open = false"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </v-fab-transition>
          </div>
        </div>
      </v-container>
      <v-divider />

      <v-card-text class="fill-height tw-p-0">
        <v-responsive class="fill-height tw-py-0">
          <v-tabs-items v-model="tab" class="fill-height">
            <v-tab-item
              :key="RepTabKeys.live"
              :value="RepTabKeys.live"
              class="fill-height"
            >
              <RepDashboardLiveTab
                :current-selected-slug="currentSelectedSlug"
              />
            </v-tab-item>
            <v-tab-item
              :key="RepTabKeys.visited"
              :value="RepTabKeys.visited"
              class="fill-height"
            >
              <RepDashboardVisitedTab
                :current-selected-slug="currentSelectedSlug"
              />
            </v-tab-item>
            <v-tab-item
              :key="RepTabKeys.reps"
              :value="RepTabKeys.reps"
              class="fill-height"
            >
              <!-- <v-container class="tw-bg-gray-100" fluid>
                <v-row class="mb-5">
                  <v-col cols="12" sm="6" class="mx-auto pa-5">
                    <v-card
                      class="
                        mx-auto
                        tw-divide-y
                        tw-divide-gray-200
                        tw-shadow-md
                        tw-rounded-3xl
                      "
                    >
                      <v-container class="pa-5"> Rep </v-container>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container> -->
              <RepDashboardRepsTab
                :current-selected-slug="currentSelectedSlug"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-responsive>
      </v-card-text>

      <v-divider />

      <v-card-actions v-if="false">
        <!-- <v-alert
            v-model="errorAlert"
            dismissible
            color="red"
            border="left"
            elevation="2"
            colored-border
            icon="error"
            transition="fade-transition"
          >
            {{ errorMsg }}
          </v-alert> -->
        <v-spacer />

        <AppBtn type="button" color="transparent" :disabled="form_loading">
          Cancelar
        </AppBtn>
        <AppBtn type="submit" :loading="form_loading" color="primary">
          Guardar
        </AppBtn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AppBtn from '@/components/App/AppBtn.vue'
import { mapFields } from '@/helpers'
import RepDashboardLiveTab from './RepDashboardLiveTab.vue'
import RepDashboardVisitedTab from './RepDashboardVisitedTab.vue'
import RepDashboardRepsTab from './RepDashboardRepsTab.vue'
import { RepTabKeys } from '@/enums/RepTabKeys'
import { mapState } from 'vuex'
// import { mapState } from 'vuex'
export default {
  components: {
    AppBtn,
    RepDashboardVisitedTab,
    RepDashboardRepsTab,
    RepDashboardLiveTab,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form_loading: false,
    RepTabKeys,
  }),

  computed: {
    ...mapFields({
      fields: ['tab', 'currentSelectedSlug'],
      base: ['event', 'rep_dashboard'],
      mutation: 'event/setRepDashboard',
    }),

    items() {
      return Object.keys(RepTabKeys).map((row) => ({
        name: RepTabKeys[row],
        label: this.$i18n.t(`pages.event.rep_dash.${RepTabKeys[row]}`),
      }))
    },
    myRooms() {
      return this.my_rooms.map((row) => ({
        text: row.label,
        value: row.slug,
      }))
    },
    // ...mapFields({
    //   fields: ['users', 'usersLocation'],
    //   base: ['event', 'socket'],
    //   mutation: 'event/setSocket',
    // }),
    // activeUsers() {
    //   // return this.users.filter((user) => user.is_online).length
    //   // return this.usersLocation.length
    //   return this.usersLocation.filter(
    //     (row) => row.registry_uuid === this.registry.uuid,
    //   ).length
    // },

    ...mapState({
      // registry: (state) => state.event.constants.registry,
      // users: (state) => state.event.socket.users,
      my_rooms: (state) => state.event.constants.rep_rooms,
    }),
    // userInThisLocation() {
    //   return this.usersLocation
    //     .filter(
    //       (row) =>
    //         row.slug === 'sponsor-hall' &&
    //         row.registry_uuid === this.registry.uuid,
    //     )
    //     .map((row) => row.user_id)
    // },
    // usersinMyRoom() {
    //   return this.users.filter((row) =>
    //     this.userInThisLocation.includes(row.id),
    //   )
    // },
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>

<style></style>
