var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],class:[
    _vm.indexItem === 0
      ? 'tw-rounded-tl-3xl tw-rounded-tr-3xl sm:tw-rounded-tr-none'
      : '',
    _vm.indexItem === 1 ? 'sm:tw-rounded-tr-3xl' : '',
    _vm.indexItem === _vm.lengthItems - 2 && _vm.lengthItems % 2 === 0
      ? 'sm:tw-rounded-bl-3xl'
      : '',
    _vm.indexItem === _vm.lengthItems - 1 && _vm.lengthItems % 2 === 0
      ? 'tw-rounded-bl-3xl tw-rounded-br-3xl sm:tw-rounded-bl-none'
      : '',
    _vm.indexItem === _vm.lengthItems - 1 && _vm.lengthItems % 2 !== 0
      ? 'sm:tw-rounded-bl-3xl'
      : '',
    'tw-group tw-relative tw-bg-white tw-p-6 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-primary-500' ]},'div',_vm.$attrs,false),_vm.$listeners),[_c('div',[_c('span',[(_vm.markerType == 'img')?_c('v-img',{staticClass:"tw-h-16 tw-w-16",attrs:{"src":_vm.icon}}):_c('g-icon',{staticClass:"tw-h-16 tw-w-16 tw-text-primary-500",attrs:{"name":_vm.icon,"g-next":_vm.gNext,"md-icon":_vm.mdIcon}})],1)]),_c('div',{staticClass:"tw-mt-4"},[_c('h3',{staticClass:"tw-text-lg tw-font-bold tw-leading-6 tw-text-black"},[(_vm.itemType === 'button')?_c('button',{staticClass:"tw-text-left focus:tw-outline-none",attrs:{"type":"button"}},[_c('span',{staticClass:"tw-absolute tw-inset-0",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.name)+" ")]):_vm._e(),(_vm.itemType === 'router-link')?_c('router-link',{staticClass:"tw-text-left focus:tw-outline-none",attrs:{"to":{ path: _vm.routeTo }}},[_c('span',{staticClass:"tw-absolute tw-inset-0",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.name)+" ")]):_vm._e()],1),_c('p',{staticClass:"tw-mt-2 tw-text-sm tw-text-gray-500"},[_vm._v(" "+_vm._s(_vm.details)+" ")])]),_c('span',{staticClass:"tw-pointer-events-none tw-absolute tw-top-6 tw-right-6 tw-text-gray-300 group-hover:tw-text-gray-400",attrs:{"aria-hidden":"true"}},[_c('svg',{staticClass:"tw-h-6 tw-w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"currentColor","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }