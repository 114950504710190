export const ExportableForm = ({
  type,
  per = '',
  virtual_room = '',
  device = '',
  session = '',
  range = '',
  concurrency_range = '',
  country_code = '',
  content_element_id = '',
}) => {
  return {
    type,
    per,
    virtual_room,
    device,
    range,
    concurrency_range,
    session,
    country_code,
    content_element_id,
  }
}
