import { v4 } from '@/helpers'
import { dd_mm_yyyy, hh_mm } from '@/utils/dateHelpers'
import { ClickableBase, setClickableFromDB } from './Clickable'

export const CalendarBase = ({
  language_id = 0,
  registry_id = 0,
  is_active = false,
}) => ({
  language_id,
  registry_id,
  is_active,
})
export const CalendarItemBase = ({
  registry_calendar_id = 0,
  uuid = v4(),
  // time related fields
  time_bracket_tz = '',
  start_date = '',
  start_time = '',
  end_date = '',
  end_time = '',
  label = '',
  description = '',
  image_url = '',
  clickable = ClickableBase({}),
  // -------- Appointment -------- //
  is_appointment= false,
  type_appointment= '',
  color_appointment= '#3565AF',
  virtual_room_id= '',
  notify_appointment= true,
  notify_type_appointment= 'five_min',
  notify_start_date_time_appointment= '',
  visibility_appointment= 0,
  reserved_by_id_appointment= '',
  status_appointment= false,
  user_id=0 ,
}) => ({
  registry_calendar_id,
  uuid,
  label,
  description,
  image_url,
  //
  time_bracket_tz,
  start_date,
  start_time,
  end_date,
  end_time,
  clickable,
  // -------- Appointment -------- //
  is_appointment,
  type_appointment,
  color_appointment,
  virtual_room_id,
  notify_appointment,
  notify_type_appointment,
  notify_start_date_time_appointment,
  visibility_appointment,
  reserved_by_id_appointment,
  status_appointment,
  user_id,
})

export const CalendarItemHydrate = ({
  time_bracket_tz = '',
  start_date_time = '',
  end_date_time = '',
  clickable,
  ...fields
}) => {
  return CalendarItemBase({
    ...fields,
    clickable: clickable ? setClickableFromDB(clickable) : ClickableBase({}),
    time_bracket_tz: time_bracket_tz,
    start_date: dd_mm_yyyy(start_date_time, time_bracket_tz),
    start_time: hh_mm(start_date_time, time_bracket_tz),
    end_date: dd_mm_yyyy(end_date_time, time_bracket_tz),
    end_time: hh_mm(end_date_time, time_bracket_tz),
  })
}
