<template>
  <v-autocomplete
    v-model="input_value"
    :items="virtual_rooms"
    :loading="isLoading"
    :search-input.sync="search"
    item-text="label"
    item-value="id"
    :label="$t('admin.enums.virtual_room')"
    outlined
    rounded
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- <template #no-data>
      <v-list-item>
        <v-list-item-title>
          Buscar la
          <strong>sala virtual</strong>
        </v-list-item-title>
      </v-list-item>
    </template> -->
    <template #item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-text="item.label" />
        <v-list-item-subtitle v-text="item.room_type.label" />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    filterRoomTypeName: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Sala Virtual',
    },
  },
  data: () => ({
    // items: [],
    search: null,
    // isLoading: false,
  }),
  computed: {
    // items() {
    //   return this.$store.getters["constants/districts"];
    // },
    ...mapState({
      items: (state) => state.constants.run_time.virtual_rooms,
      isLoading: (state) => state.constants.is_loading.virtual_rooms,
    }),
    virtual_rooms() {
      if (this.filterRoomTypeName) {
        return this.items.filter((row) => {
          return this.filterRoomTypeName == row.room_type.name
        })
      }
      return this.items
    },
    input_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    search() {
      // Items have already been loaded
      if (this.items.length > 0) return
      this.loadRooms()
    },
  },
  mounted() {
    // this.mountedRoomElement = this.roomElement
    if (this.items.length > 0) return
    this.loadRooms()
  },
  methods: {
    loadRooms() {
      this.$store.dispatch('constants/loadRooms')
      // this.isLoading = true;
      // VirtualRoomsServiceInstance.searchAsYouType()
      //   .then((res) => {
      //     this.items = res.data.data;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => (this.isLoading = false));
    },
  },
}
</script>

<style></style>
