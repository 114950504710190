import $axios from '@/axios-instance.js'

class UsersCredentialServiceClass {
    baseUrl = '/api/v1/admin/credentials'

    index(params) {
        return $axios.get(this.baseUrl, params)
    }
}

export const UsersCredentialService = new UsersCredentialServiceClass()
