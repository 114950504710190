import { short_uid } from '@/helpers'
export const LinkModel = ({
  uid = short_uid(),
  color = 'primary',
  label = '',
  link_url = '',
  action = 'link_types',
}) => ({
  uid,
  label,
  color,
  link_url,
  action,
})
