export const BeaconService = (body, tenant) => {
  if (document.visibilityState === 'hidden') {
    const cleanBody = Object.entries(body).reduce((acc, [key, value]) => {
      if (value) {
        acc[key] = value
      }
      return acc
    }, {})
    const searchParam = new URLSearchParams({
      _token: body._token,
      tenant: tenant,
      ...cleanBody,
    })
    const url = `${
      process.env.NODE_ENV === 'production'
        ? process.env.VUE_APP_LARAVEL_BACKEND_PROD
        : process.env.VUE_APP_LARAVEL_BACKEND_DEV
    }/api/v1/hooks/app-core-vital?${searchParam}`

    // Object.entries(body).forEach
    // const payload = makeFormData(body)

    // for (let pair of payload.entries()) {
    //   console.log(pair[0] + ', ' + pair[1])
    // }
    // let blob = new Blob([JSON.stringify(body)], { type: "application/json" });
    // navigator.sendBeacon(url, payload)
    navigator.sendBeacon(url)
  }
}
