<template>
    <v-btn
:loading="loading" :disabled="loading" rounded block class="tw-capitalize tw-shadow" :class="[
        mode ? 'tw-text-white' : 'tw-text-black',
        color
    ]" v-bind="$attrs" v-on="$listeners">
        <v-icon left dark>
            {{ icon }}
        </v-icon>
        {{ label }}
    </v-btn>
</template>
<script>
export default {
    props: {
        icon: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: '',
        },
        mode: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
}
</script>