<template>
  <!-- <full-screen-layout> -->
  <renderless-layout :on="true">
    <div class="home">
      <header
        class="
          tw-relative
          tw-bg-gradient-to-r
          tw-from-blue-800
          tw-to-cyan-600
          tw-pb-36
        "
      >
        <div class="tw-absolute tw-inset-0">
          <img
            aria-hidden="true"
            class="tw-h-full tw-w-full tw-object-cover tw-object-center"
            :src="require('@/assets/bg_login_bizzdome.jpg')"
            alt="Office"
          />
          <div
            class="
              tw-absolute
              tw-inset-0
              tw-bg-gradient-to-r
              tw-from-primary-900
              tw-to-transparent
            "
            style="mix-blend-mode: multiply"
          />
        </div>
        <div
          class="
            tw-relative tw-mx-auto tw-max-w-3xl tw-px-4
            sm:tw-px-6
            lg:tw-max-w-7xl lg:tw-px-8
          "
        >
          <div
            class="
              tw-relative tw-flex tw-flex-wrap tw-items-center tw-justify-center
              lg:tw-justify-between
            "
          >
            <!-- Logo -->
            <div
              class="
                tw-absolute tw-left-0 tw-hidden tw-flex-shrink-0 tw-py-5
                lg:tw-static lg:tw-block
              "
            >
              <a href="#" class="tw-pointer-events-none" tabindex="-1">
                <span class="tw-sr-only">Workflow</span>
                <v-img
                  :src="require('@/assets/logo-bizzdome-blanco.svg')"
                  class="tw-h-20 tw-w-auto"
                  contain
                >
                  <template #placeholder>
                    <v-row
                      class="fill-height ma-0 transparent"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        size="32"
                        indeterminate
                        color="primary"
                      />
                    </v-row>
                  </template>
                </v-img>
              </a>
            </div>

            <!-- Right section on desktop -->
            <div
              class="
                tw-hidden
                lg:tw-ml-4 lg:tw-flex lg:tw-items-center lg:tw-py-5 lg:tw-pr-0.5
              "
            >
              <!-- <button
                type="button"
                class="
                  tw-flex-shrink-0 tw-p-1 tw-rounded-full tw-text-primary-100
                  hover:tw-text-white hover:tw-bg-white hover:tw-bg-opacity-10
                  focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-white
                "
                @click="drawer = true"
              >
                <span class="tw-sr-only">View notifications</span>
                <svg
                  class="tw-w-6 tw-h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  />
                </svg>
              </button> -->

              <!-- Profile dropdown -->
              <div class="tw-relative tw-ml-4 tw-flex-shrink-0">
                <div>
                  <!-- content-class="tw-absolute tw-w-48 tw-py-1 tw-mt-2 tw-bg-white tw-rounded-md tw-shadow-lg tw--right-2 tw-ring-1 tw-ring-black tw-ring-opacity-5" -->
                  <v-menu
                    offset-y
                    left
                    bottom
                    content-class="tw-py-1 tw-mt-2 tw-bg-white tw-rounded-md tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5"
                  >
                    <template #activator="{ on, attrs }">
                      <button
                        id="user-menu"
                        type="button"
                        class="
                          tw-flex
                          tw-rounded-full
                          tw-bg-white
                          tw-text-sm
                          tw-ring-2
                          tw-ring-white
                          tw-ring-opacity-20
                          focus:tw-outline-none focus:tw-ring-opacity-100
                        "
                        aria-haspopup="true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span class="tw-sr-only">Open user menu</span>
                        <!-- src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80" -->
                        <img
                          class="tw-h-8 tw-w-8 tw-rounded-full tw-object-cover"
                          :src="user.avatar"
                          alt=""
                        />
                      </button>
                    </template>
                    <v-list>
                      <v-list-item
                        :to="{
                          name: 'admin.users.show',
                          params: { uuid: user.uuid },
                        }"
                      >
                        <v-list-item-title>
                          {{
                            $t(
                              'pages.event.container.upper_nav.user_dropdown.profile',
                            )
                          }}
                        </v-list-item-title>
                      </v-list-item>
                      <!-- <v-list-item>
                        <v-list-item-title>Configuraciones</v-list-item-title>
                      </v-list-item> -->
                      <v-list-item @click="logout">
                        <v-list-item-title>{{
                          $t('app.logout')
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <!--
              Profile dropdown panel, show/hide based on dropdown state.

              Entering: ""
                From: ""
                To: ""
              Leaving: "tw-transition tw-ease-in tw-duration-75"
                From: "tw-transform tw-opacity-100 tw-scale-100"
                To: "tw-transform tw-opacity-0 tw-scale-95"
            -->
                <!-- <div
                class="tw-absolute tw-z-40 tw-w-48 tw-py-1 tw-mt-2 tw-origin-top-right tw-bg-white tw-rounded-md tw-shadow-lg tw--right-2 tw-ring-1 tw-ring-black tw-ring-opacity-5"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu"
              >
                <a
                  href="#"
                  class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100"
                  role="menuitem"
                  >Your Profile</a
                >
                <a
                  href="#"
                  class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100"
                  role="menuitem"
                  >Settings</a
                >
                <a
                  href="#"
                  class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100"
                  role="menuitem"
                  >Sign out</a
                >
              </div> -->
              </div>
            </div>

            <!-- <div
            class="tw-w-full tw-py-5 lg:tw-border-t lg:tw-border-white lg:tw-border-opacity-20"
          >
            <div
              class="lg:tw-grid lg:tw-grid-cols-3 lg:tw-gap-8 lg:tw-items-center"
            >
              <div class="tw-hidden lg:tw-block lg:tw-col-span-2">
                <nav class="tw-flex tw-space-x-4">
                  <a
                    href="#"
                    class="tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-text-white tw-bg-white tw-bg-opacity-0 tw-rounded-md hover:tw-bg-opacity-10"
                    aria-current="page"
                  >
                    Home
                  </a>

                  <a
                    href="#"
                    class="tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-bg-white tw-bg-opacity-0 tw-rounded-md tw-text-cyan-100 hover:tw-bg-opacity-10"
                    aria-current="false"
                  >
                    Profile
                  </a>

                  <a
                    href="#"
                    class="tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-bg-white tw-bg-opacity-0 tw-rounded-md tw-text-cyan-100 hover:tw-bg-opacity-10"
                    aria-current="false"
                  >
                    Resources
                  </a>

                  <a
                    href="#"
                    class="tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-bg-white tw-bg-opacity-0 tw-rounded-md tw-text-cyan-100 hover:tw-bg-opacity-10"
                    aria-current="false"
                  >
                    Company Directory
                  </a>

                  <a
                    href="#"
                    class="tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-bg-white tw-bg-opacity-0 tw-rounded-md tw-text-cyan-100 hover:tw-bg-opacity-10"
                    aria-current="false"
                  >
                    Openings
                  </a>
                </nav>
              </div>
              <div class="tw-px-12 lg:tw-px-0">
                <div class="tw-w-full tw-max-w-xs tw-mx-auto lg:tw-max-w-md">
                  <label for="search" class="tw-sr-only">Search</label>
                  <div
                    class="tw-relative tw-text-white focus-within:tw-text-gray-600"
                  >
                    <div
                      class="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3 tw-pointer-events-none"
                    >
                      <svg
                        class="tw-w-5 tw-h-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      id="search"
                      class="tw-block tw-w-full tw-py-2 tw-pl-10 tw-pr-3 tw-leading-5 tw-text-white tw-placeholder-white tw-bg-white tw-border tw-border-transparent tw-rounded-md tw-bg-opacity-20 focus:tw-text-gray-900 focus:tw-outline-none focus:tw-bg-opacity-100 focus:tw-border-transparent focus:tw-placeholder-gray-500 focus:tw-ring-0 sm:tw-text-sm"
                      placeholder="Search"
                      type="search"
                      name="search"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> -->

            <!-- Menu button -->
            <div class="tw-absolute tw-right-0 tw-hidden tw-flex-shrink-0">
              <!-- Mobile menu button -->
              <button
                class="
                  text-cyan-200
                  tw-inline-flex
                  tw-items-center
                  tw-justify-center
                  tw-rounded-md
                  tw-bg-transparent
                  tw-p-2
                  hover:tw-bg-white hover:tw-bg-opacity-10 hover:tw-text-white
                  focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-white
                "
                aria-expanded="false"
              >
                <span class="tw-sr-only">Open main menu</span>
                <!--
              Heroicon name: menu

              Menu open: "tw-hidden", Menu closed: "tw-block"
            -->
                <svg
                  class="tw-block tw-h-6 tw-w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <!--
              Heroicon name: x

              Menu open: "tw-block", Menu closed: "tw-hidden"
            -->
                <svg
                  class="tw-hidden tw-h-6 tw-w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <!--
      Mobile menu overlay, show/hide based on mobile menu state.

      Entering: "tw-duration-150 tw-ease-out"
        From: "tw-opacity-0"
        To: "tw-opacity-100"
      Leaving: "tw-duration-150 tw-ease-in"
        From: "tw-opacity-100"
        To: "tw-opacity-0"
    -->
        <!-- <div
        class="tw-fixed tw-inset-0 tw-z-20 tw-bg-black tw-bg-opacity-25 lg:tw-hidden"
        aria-hidden="true"
      ></div> -->

        <!--
      Mobile menu, show/hide based on mobile menu state.

      Entering: "tw-duration-150 tw-ease-out"
        From: "tw-opacity-0 tw-scale-95"
        To: "tw-opacity-100 tw-scale-100"
      Leaving: "tw-duration-150 tw-ease-in"
        From: "tw-opacity-100 tw-scale-100"
        To: "tw-opacity-0 tw-scale-95"
    -->
        <!-- <div
        class="tw-absolute tw-inset-x-0 tw-top-0 tw-z-30 tw-w-full tw-max-w-3xl tw-p-2 tw-mx-auto tw-transition tw-origin-top tw-transform lg:tw-hidden"
      >
        <div
          class="tw-bg-white tw-divide-y tw-divide-gray-200 tw-rounded-lg tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5"
        >
          <div class="tw-pt-3 tw-pb-2">
            <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
              <div>
                <img
                  class="tw-w-auto tw-h-8"
                  src="https://tailwindui.com/img/logos/workflow-mark-cyan-600.svg"
                  alt="Workflow"
                />
              </div>
              <div class="tw--mr-2">
                <button
                  type="button"
                  class="tw-inline-flex tw-items-center tw-justify-center tw-p-2 tw-text-gray-400 tw-bg-white tw-rounded-md hover:tw-text-gray-500 hover:tw-bg-gray-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:ring-cyan-500"
                >
                  <span class="tw-sr-only">Close menu</span>
                  <svg
                    class="tw-w-6 tw-h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div class="tw-px-2 tw-mt-3 tw-space-y-1">
              <a
                href="#"
                class="tw-block tw-px-3 tw-py-2 tw-text-base tw-font-medium tw-text-gray-900 tw-rounded-md hover:tw-bg-gray-100 hover:tw-text-gray-800"
                >Home</a
              >
              <a
                href="#"
                class="tw-block tw-px-3 tw-py-2 tw-text-base tw-font-medium tw-text-gray-900 tw-rounded-md hover:tw-bg-gray-100 hover:tw-text-gray-800"
                >Profile</a
              >
              <a
                href="#"
                class="tw-block tw-px-3 tw-py-2 tw-text-base tw-font-medium tw-text-gray-900 tw-rounded-md hover:tw-bg-gray-100 hover:tw-text-gray-800"
                >Resources</a
              >
              <a
                href="#"
                class="tw-block tw-px-3 tw-py-2 tw-text-base tw-font-medium tw-text-gray-900 tw-rounded-md hover:tw-bg-gray-100 hover:tw-text-gray-800"
                >Company Directory</a
              >
              <a
                href="#"
                class="tw-block tw-px-3 tw-py-2 tw-text-base tw-font-medium tw-text-gray-900 tw-rounded-md hover:tw-bg-gray-100 hover:tw-text-gray-800"
                >Openings</a
              >
            </div>
          </div>
          <div class="tw-pt-4 tw-pb-2">
            <div class="tw-flex tw-items-center tw-px-5">
              <div class="tw-flex-shrink-0">
                <img
                  class="tw-w-10 tw-h-10 tw-rounded-full"
                  src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                  alt=""
                />
              </div>
              <div class="tw-flex-1 tw-min-w-0 tw-ml-3">
                <div
                  class="tw-text-base tw-font-medium tw-text-gray-800 tw-truncate"
                >
                  Rebecca Nicholas
                </div>
                <div
                  class="tw-text-sm tw-font-medium tw-text-gray-500 tw-truncate"
                >
                  rebecca.nicholas@example.com
                </div>
              </div>
              <button
                class="tw-flex-shrink-0 tw-p-1 tw-ml-auto tw-text-gray-400 tw-bg-white tw-rounded-full hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:ring-cyan-500"
              >
                <span class="tw-sr-only">View notifications</span>
                <svg
                  class="tw-w-6 tw-h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  />
                </svg>
              </button>
            </div>
            <div class="tw-px-2 tw-mt-3 tw-space-y-1">
              <a
                href="#"
                class="tw-block tw-px-3 tw-py-2 tw-text-base tw-font-medium tw-text-gray-900 tw-rounded-md hover:tw-bg-gray-100 hover:tw-text-gray-800"
                >Your Profile</a
              >
              <a
                href="#"
                class="tw-block tw-px-3 tw-py-2 tw-text-base tw-font-medium tw-text-gray-900 tw-rounded-md hover:tw-bg-gray-100 hover:tw-text-gray-800"
                >Settings</a
              >
              <a
                href="#"
                class="tw-block tw-px-3 tw-py-2 tw-text-base tw-font-medium tw-text-gray-900 tw-rounded-md hover:tw-bg-gray-100 hover:tw-text-gray-800"
                >Sign out</a
              >
            </div>
          </div>
        </div>
      </div> -->
      </header>
      <main class="tw-relative tw--mt-24 tw-pb-8">
        <!-- <div style="padding: 56.25% 0 0 0; position: relative">
          <iframe
            src="https://vimeo.com/event/1226312/embed"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
          ></iframe>
        </div> -->
        <!-- lg:tw-max-w-7xl -->
        <div class="tw-mx-auto tw-max-w-3xl tw-px-4 sm:tw-px-6 lg:tw-px-8">
          <h1 class="tw-sr-only">Profile</h1>
          <!-- Main 3 column tw-grid -->
          <div
            class="
              tw-grid tw-grid-cols-1 tw-items-start tw-gap-4
              lg:tw-grid-cols-3 lg:tw-gap-8
            "
          >
            <!-- Left column -->
            <!-- lg:tw-col-span-2 -->
            <div class="tw-grid tw-grid-cols-1 tw-gap-4 lg:tw-col-span-3">
              <!-- Welcome panel -->
              <section aria-labelledby="profile-overview-title">
                <div
                  class="
                    tw-overflow-hidden tw-rounded-3xl tw-bg-white tw-shadow
                  "
                >
                  <h2 id="profile-overview-title" class="tw-sr-only">
                    Profile Overview
                  </h2>
                  <div class="tw-bg-white tw-p-6">
                    <div
                      class="
                        sm:tw-flex sm:tw-items-center sm:tw-justify-between
                      "
                    >
                      <div class="sm:tw-flex sm:tw-space-x-5">
                        <div class="tw-flex-shrink-0">
                          <img
                            class="
                              tw-mx-auto
                              tw-h-20
                              tw-w-20
                              tw-rounded-full
                              tw-object-cover
                            "
                            :src="user.avatar"
                            :alt="user.first_name"
                          />
                        </div>
                        <div
                          class="
                            tw-mt-4 tw-text-center
                            sm:tw-mt-0 sm:tw-pt-1 sm:tw-text-left
                          "
                        >
                          <p
                            class="
                              tw-mb-0 tw-text-sm tw-font-medium tw-text-gray-600
                            "
                          >
                            {{ $t('app.welcome') }}
                          </p>
                          <p
                            class="
                              tw-mb-0 tw-text-xl tw-font-bold tw-text-gray-900
                              sm:tw-text-2xl
                            "
                          >
                            {{ fullName }}
                          </p>
                          <p
                            class="
                              tw-mb-0 tw-text-sm tw-font-medium tw-text-gray-600
                            "
                          >
                            {{ user.formatedRoles }}
                          </p>
                        </div>
                      </div>
                      <div class="tw-mt-5 tw-flex tw-justify-center sm:tw-mt-0">
                        <div class="tw-flex tw-items-center tw-space-x-2">
                          <AppBtn
                            icon="g-next"
                            g-next="UserCircle"
                            color="transparent"
                            :to="{
                              name: 'admin.users.show',
                              params: { uuid: user.uuid },
                            }"
                          >
                            {{
                              $t(
                                'pages.event.container.upper_nav.user_dropdown.profile',
                              )
                            }}
                          </AppBtn>
                          <AppBtn icon="cog" color="primary" @click="goToEvent">
                            {{ $t('app.go_to_event') }}
                          </AppBtn>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="
                      tw-grid
                      tw-grid-cols-1
                      tw-divide-y
                      tw-divide-gray-200
                      tw-border-t
                      tw-border-gray-200
                      tw-bg-gray-50
                      sm:tw-grid-cols-3 sm:tw-divide-y-0 sm:tw-divide-x
                    "
                  >
                    <div
                      class="
                        tw-px-6 tw-py-5 tw-text-center tw-text-sm tw-font-medium
                      "
                    >
                      <span class="tw-text-gray-900">
                        {{ registries_count }}
                      </span>
                      <span class="tw-text-gray-600">
                        {{ $t('app.events') }}
                      </span>
                    </div>

                    <div
                      class="
                        tw-px-6 tw-py-5 tw-text-center tw-text-sm tw-font-medium
                      "
                    >
                      <span class="tw-text-gray-900">
                        {{ virtual_rooms_count }}
                      </span>
                      <span class="tw-text-gray-600">
                        {{ $t('app.rooms') }}</span
                      >
                    </div>

                    <div
                      class="
                        tw-px-6 tw-py-5 tw-text-center tw-text-sm tw-font-medium
                      "
                    >
                      <span class="tw-text-gray-900">{{ users_count }}</span>
                      <span class="tw-text-gray-600">
                        {{ $t('app.visitors') }}</span
                      >
                    </div>
                  </div>
                </div>
              </section>

              <!-- Actions panel -->
              <section aria-labelledby="quick-links-title">
                <div
                  class="
                    tw-divide-y
                    tw-divide-gray-200
                    tw-overflow-hidden
                    tw-rounded-3xl
                    tw-bg-gray-200
                    tw-shadow
                    sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-px sm:tw-divide-y-0
                  "
                >
                  <h2 id="quick-links-title" class="tw-sr-only">Quick links</h2>
                  <home-quick-link
                    v-for="(link, index) in quick_links"
                    :key="link.name"
                    :index-item="index"
                    :length-items="quick_links.length"
                    :name="link.name"
                    :details="link.details"
                    :icon="link.icon"
                    :route-to="link.route"
                    :marker-type="link.marketType"
                    item-type="router-link"
                  />
                </div>
              </section>
            </div>

            <!-- Right column -->
            <div v-if="false" class="tw-grid tw-grid-cols-1 tw-gap-4">
              <!-- Announcements -->
              <section aria-labelledby="announcements-title">
                <div
                  class="
                    tw-overflow-hidden tw-rounded-3xl tw-bg-white tw-shadow
                  "
                >
                  <div class="tw-p-6">
                    <h2
                      id="announcements-title"
                      class="tw-text-base tw-font-medium tw-text-gray-900"
                    >
                      Novedades
                    </h2>
                    <div class="tw-mt-6 tw-flow-root">
                      <ul
                        class="tw--my-5 tw-divide-y tw-divide-gray-200 tw-pl-0"
                      >
                        <li v-for="row in 4" :key="row" class="tw-py-5">
                          <div
                            class="
                              focus-within:ring-cyan-500
                              tw-relative
                              focus-within:tw-ring-2
                            "
                          >
                            <h3
                              class="
                                tw-text-sm tw-font-semibold tw-text-gray-800
                              "
                            >
                              <a
                                href="#"
                                class="hover:tw-underline focus:tw-outline-none"
                              >
                                <!-- Extend touch target to entire panel -->
                                <span
                                  class="tw-absolute tw-inset-0"
                                  aria-hidden="true"
                                />
                                Novedad
                              </a>
                            </h3>
                            <p
                              class="
                                line-clamp-2
                                tw-mt-1 tw-text-sm tw-text-gray-600
                              "
                            >
                              Cum qui rem deleniti. Suscipit in dolor veritatis
                              sequi aut. Vero ut earum quis deleniti. Ut a sunt
                              eum cum ut repudiandae possimus. Nihil ex tempora
                              neque cum consectetur dolores.
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="tw-mt-6">
                      <v-btn
                        class="
                          tw-flex
                          tw-w-full
                          tw-items-center
                          tw-justify-center
                          tw-rounded-md
                          tw-border
                          tw-border-gray-300
                          tw-bg-white
                          tw-px-4
                          tw-py-2
                          tw-text-sm
                          tw-font-medium
                          tw-text-gray-700
                          tw-shadow-sm
                          hover:tw-bg-gray-50
                          focus:tw-outline-none
                          focus:tw-ring
                          focus:tw-ring-primary-300
                        "
                      >
                        Ver Todas
                      </v-btn>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
      <footer>
        <div
          class="
            tw-mx-auto tw-max-w-3xl tw-px-4
            sm:tw-px-6
            lg:tw-max-w-7xl lg:tw-px-8
          "
        >
          <div
            class="
              tw-border-t
              tw-border-gray-200
              tw-py-8
              tw-text-center
              tw-text-sm
              tw-text-gray-500
              sm:tw-text-left
            "
          >
            <span class="tw-block sm:tw-inline"
              >&copy; {{ year }} BIZZEVENTS</span
            >
            <span class="tw-block sm:tw-inline"> All rights reserved. </span>
          </div>
          <SimpleRender v-if="false" label="label" />
        </div>
      </footer>
    </div>
  </renderless-layout>
  <!-- </full-screen-layout> -->
</template>

<script>
// @ is an alias to /src
// import gql from 'graphql-tag'
import AppBtn from '@/components/App/AppBtn.vue'
import HomeQuickLink from '@/components/HomeQuickLink.vue'
import { mapGetters, mapState } from 'vuex'
// import FullScreenLayout from "@/layouts/FullScreenLayout";
const links = [
  {
    route: '/admin/registries',
    name: 'Eventos',
    label: 'events',
    details:
      ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
    marketType: 'img',
    icon: require('@/assets/icons/ico-06.svg'),
  },
  {
    route: '/admin/users',
    name: 'Cuentas de Usuario',
    label: 'users',
    details:
      ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
    marketType: 'img',
    icon: require('@/assets/icons/ico-01.svg'),
  },
  {
    route: '/admin/booths',
    name: 'Salas Virtulares',
    label: 'rooms',
    details:
      ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
    marketType: 'img',
    icon: require('@/assets/icons/ico-03.svg'),
  },
  {
    route: '/admin/content-library',
    name: 'Librería de Contenido',
    label: 'content',

    details:
      ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
    marketType: 'img',
    icon: require('@/assets/icons/ico-05.svg'),
  },
  {
    route: '/admin/emails',
    name: 'Emails',
    label: 'emails',
    details:
      ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
    marketType: 'svg',
    icon: 'email',
    // icon: 'rss',
  },
  {
    route: '/admin/metrics',
    name: 'Rastreo y Métricas',
    label: 'metrics',
    details:
      ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
    marketType: 'img',
    icon: require('@/assets/icons/ico-04.svg'),
  },

  {
    route: '/admin/threads',
    name: 'Preguntas y respuestas',
    label: 'qa',
    details:
      'Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
    marketType: 'svg',
    icon: 'questions',
  },
  {
    route: '/admin/channels',
    name: 'Mensajes',
    label: 'messages',
    details:
      ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
    marketType: 'svg',
    icon: 'message',
  },
  // {
  //   route: '/admin/forms',
  //   name: 'Formularios',
  //   label: 'forms',
  //   details:
  //     ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
  //   marketType: 'img',
  //   icon: require('@/assets/icons/ico-06.svg'),
  // },
  {
    route: '/admin/configs',
    name: 'Configuración',
    label: 'config',
    details:
      ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
    marketType: 'img',
    icon: require('@/assets/icons/ico-02.svg'),
  },
  {
    route: '/admin/changelog',
    name: 'Configuración',
    label: 'changelog',
    details:
      ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
    marketType: 'img',
    icon: require('@/assets/icons/ico-07.svg'),
  },
  // {
  //   route: '/admin/surveys',
  //   name: 'Encuestas',
  //   details:
  //     'Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
  //   marketType: 'svg',
  //   icon: 'chart',
  // },
]
const event_route = { name: 'event.home.rewrite' }
import SimpleRender from './SimpleRender'
import { formatterArray } from '@/utils/formatterArray'
import { HomeAdminDashboardService } from '@/services/HomeAdminDashboardService'
import { NotificationStatusKeys } from '@/enums/NotificationStatusKeys'
export default {
  name: 'AppHome',
  components: {
    HomeQuickLink,
    SimpleRender,
    AppBtn,
  },
  data: () => ({
    event_route,
    users_count: 0,
    virtual_rooms_count: 0,
    registries_count: 0,
    loading: false,
    year: new Date().getFullYear(),
  }),
  computed: {
    quick_links() {
      return links.map((link) => ({
        ...link,
        name: this.$t(`admin.home.labels.${link.label}`),
        details: this.$t(`admin.home.description.${link.label}`),
      }))
      // return links.filter((link) => {
      //   return link.name === 'general'
      //     ? !this.license.can_concurrent_events
      //     : true
      // })
    },
    ...mapGetters({
      fullName: ['session/fullName'],
    }),
    ...mapState({
      userStore: (state) => state.session.user,
      license: (state) => state.constants.data.license,
    }),
    user() {
      return {
        ...this.userStore,
        formatedRoles: formatterArray().format(
          this.userStore.roles.map((row) => this.$t(`app.roles.${row.name}`)),
        ),
      }
    },
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        return this.$store.commit('drawer', { bool: val })
      },
    },
  },

  mounted() {
    HomeAdminDashboardService.make()
      .execute()
      .then(({ data: { users, virtual_rooms, registries } }) => {
        this.users_count = users
        this.virtual_rooms_count = virtual_rooms
        this.registries_count = registries
      })
      .catch((err) => {
        this.$store.dispatch('notifications/addNotification', {
          title: 'Error',
          status: NotificationStatusKeys.error,
          body: err,
        })
      })
  },
  methods: {
    logout() {
      this.$store.commit('session/setLogoutModal', {
        logoutModal: true,
      })
    },
    goToEvent() {
      this.$store.commit('session/setAdmin', {
        pre_event_modal: true,
        can_remember: false,
      })
    },
  },
}
</script>
