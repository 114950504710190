import { RoleKeys } from '@/enums/RoleKeys'
import router from '@/router'
import { AuthServiceInstance } from '@/services/AuthService'
import { formErrorsHandler } from '@/utils/formErrorsHandler'
// state
const initState = () => {
  return {
    active: false,
    token: '',
    jwt_token: '',
    user: {
      roles: [],
    },
    logout: {
      route: 'auth.login',
    },
    logoutModal: false,
    admin: {
      pre_event_modal: false,
      to_route: null,
      can_remember: false,
      previous_route: null,
      loaded_registry: {
        registry_uuid: null,
      },
    },
    validAdmin: false,
  }
}

// getters
const getters = {
  // classes -> class 
  // 1. tengo caso nulo 
  // 2. class.ticket.id || .key // RegistryTicket  
  isAdmin(_, getters) {
    return getters.rolesLookUp.includes(RoleKeys.admin)
  },
  isRep(_, getters) {
    return getters.rolesLookUp.includes(RoleKeys.representative)
  },
  isModerator(_, getters) {
    return getters.rolesLookUp.includes(RoleKeys.moderator)
  },
  rolesLookUp({ user }) {
    return user.roles.map((row) => row.name)
  },
  token: ({ token }) => {
    return token
  },
  jwt_token: ({ jwt_token }) => {
    return jwt_token
  },
  role: ({ user }) => {
    return user.roles.map((row) => row.name)
  },
  fullName: ({ user }) => {
    return `${user.first_name} ${user.last_name}`
  },
}

// actions
const actions = {
  logout({ commit, state, rootState }) {
    // is_user_in_event
    // is_user_in_event
    // let slug ='';
    // if(rootState.event.constants.is_user_in_event){
    //   slug=rootState.event.constants.registry.slug
    // }else{
    //   slug
    // }
    //  = rootState.event.constants.is_user_in_event || 'home'
    // const slug = rootState.registry.show.data.slug || 'home'
    const slug = rootState.event.constants.is_user_in_event
      ? rootState.event.constants.registry.slug
      : ''

    console.log('normal logout triggers')
    console.log(slug)
    commit('logoutComplete')
    router
      .push({
        name: state.logout.route,
        params: { slug },
      })
      .then(() => {
        console.log('promise')
      })
  },
  axiosLogout({ commit, rootState, getters, state }) {
    if (!state.active) {
      // return
    }
    console.log('axios logout triggers')
    if (rootState.event.constants.is_user_in_event) {
      commit('logoutComplete')
      router.push({
        name: 'registry.thank-you',
        params: { slug: rootState.event.constants.registry.slug },
      })
      // .then(() => {})
      return
    }
    let isAdmin
    try {
      isAdmin = getters.role ? getters.role.includes('admin') : false
    } catch (error) {
      isAdmin = false
      console.log('Error: ', error)
    }

    if (isAdmin) {
      commit('logoutComplete')
      router.push({
        name: 'auth.login',
      })
      return
    }
    const slug = rootState.registry.show.data.slug || 'home'
    commit('logoutComplete')
    router.push({
      name: 'registry.home',
      params: { slug },
    })
  },
  // Start login and init user
  initUser(context) {
    if (!window.localStorage.getItem('token')) {
      return
    }
    context.commit('authenticateCold')
    //  refreshAction()
    return AuthServiceInstance.refreshAction()
      .then((res) => {
        return context.dispatch('login', res.data)
        // return res;
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  loginUser({ dispatch }, body) {
    return AuthServiceInstance.loginAction(body)
      .then((res) => {
        return dispatch('login', res.data)
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  loginUserViaEvent({ dispatch }, { uuid = '', body = {} }) {
    return AuthServiceInstance.loginViaEventAction(uuid, body)
      .then((res) => {
        return dispatch('login', res.data)
      })
      .catch((err) => {
        if (err.response.data.errors.isAdmin) {
          localStorage.setItem('validAdmin', err.response.data.errors.isAdmin[0])
        }
        throw formErrorsHandler(err)
      })
  },
  // loginRegistry(context, body){
  //   return AuthServiceInstance.fetchRegistryFields()
  // },
  // registeryRegistry(context, body){
  //   return AuthServiceInstance.fetchRegistryFields()
  // },
  // dispatch
  async login({ commit, dispatch, rootState }, body) {
    commit('loginSuccess', body)
    if (!rootState.appIsLoading) {
      console.log('Long constants')
      commit('setAppIsLoading', true, { root: true })
      await dispatch('initAuthedConstants', null, { root: true }) // or some other sort of constants
      commit('setAppIsLoading', false, { root: true })
    } else {
      console.log('Good constants')
      await dispatch('initAuthedConstants', null, { root: true }) // or some other sort of constants
    }
    // dispatch('on_boarding/setOnBoardingUserFields');
  },
  registerEvent({ dispatch, commit }, { uuid = '', body = {} }) {
    return AuthServiceInstance.registerEvent(uuid, body)
      .then(({ data }) => {
        if (data.registry_status == 'open') {
          return dispatch('login', data)
        }
        commit(
          'registry/setShow',
          { registry_status: data.registry_status },
          { root: true },
        )
        return
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  registerEventMod(_, { uuid = '', body = {} }) {
    return AuthServiceInstance.registerEvent(uuid, body)
      .then(() => {
        console.log('Good Register')
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  verifyUserRegistry(_, { registry_uuid, payload = {} }) {
    return AuthServiceInstance.verifyUserRegistry(registry_uuid, payload)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  loginViaEventVerifiedRegistry(
    { dispatch, commit },
    { registry_uuid, payload },
  ) {
    return AuthServiceInstance.loginViaEventVerifiedRegistry(
      registry_uuid,
      payload,
    )
      .then(({ data }) => {
        commit('setAdmin', {
          loaded_registry: {
            registry_uuid,
            form_id: payload.form_id,
          },
        })
        return dispatch('login', data)
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  registerViaEventVerifiedRegistry(
    { dispatch, commit },
    { uuid = '', form_uuid = '', form_id = '', body = {} },
  ) {
    return AuthServiceInstance.registerViaEventVerifiedRegistry(
      uuid,
      form_uuid,
      body,
    )
      .then((res) => {
        commit('setAdmin', {
          loaded_registry: {
            registry_uuid: uuid,
            form_id,
          },
        })
        return dispatch('login', res.data)
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  // ---------------------- Payment ---------------------- //
  paymentEvent(_, payload) {
    return AuthServiceInstance.paymentEvent(payload)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
}

// mutations
const mutations = {
  setUser(state, obj) {
    Object.keys(obj).forEach((k) => (state.user[k] = obj[k]))
  },
  loginSuccess(state, payload) {
    state.active = true
    const token = payload.access_token
    state.user = Object.assign({}, payload.user, {
      token: token,
    })
    localStorage.setItem('user', JSON.stringify(state.user))
    localStorage.setItem('token', token)
    localStorage.setItem('node_access_token', payload.node_access_token)
    localStorage.setItem('node_refresh_token', payload.node_refresh_token)
    // console.log('LARA', { token })
    // console.log('NODE', { node_access_token: payload.node_access_token })
    state.token = token
    state.jwt_token = payload.node_access_token

    // this._vm.$socket.emit('checkUser', { email: payload.user.email })
  },
  authenticateCold(state) {
    state.active = true
    const token = window.localStorage.getItem('token')
    const node_access_token = window.localStorage.getItem('node_access_token')
    state.token = token
    state.jwt_token = node_access_token
    state.user = JSON.parse(window.localStorage.getItem('user'))
  },
  setLogoutModal(state, { logoutModal, route = 'auth.login' }) {
    state.logoutModal = logoutModal
    if (!logoutModal) {
      return
    }
    console.log({ route })
    state.logout.route = route
  },
  logoutComplete(state) {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('node_access_token')
    localStorage.removeItem('node_refresh_token')
    state.active = false
    state.jwt_token = ''
    state.token = ''
    state.user = {
      roles: [],
    }
    state.admin = {
      pre_event_modal: false,
      to_route: null,
      can_remember: false,
      previous_route: null,
      loaded_registry: {
        registry_uuid: null,
      },
    }
  },
  setAdmin(state, obj) {
    Object.keys(obj).forEach((k) => (state.admin[k] = obj[k]))
  },
}

export const Session = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
}
