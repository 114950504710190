var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-message"},[_c('div',{staticClass:"tw-flex tw-items-end",class:[_vm.message.user.id === _vm.user.id ? 'tw-justify-end' : '']},[_c('div',{staticClass:"tw-flex tw-max-w-xs tw-flex-col tw-items-start tw-space-y-2 tw-pl-2 tw-pr-2 tw-text-xs",class:[
        _vm.message.user.id === _vm.user.id
          ? 'tw-order-1 tw-items-end'
          : 'tw-order-2 tw-items-start' ]},_vm._l((_vm.message.messages),function(message_row,index){return _c('div',{key:message_row.uuid,staticClass:"tw-group tw-relative tw-flex tw-w-full",class:[
          _vm.message.user.id === _vm.user.id ? 'tw-justify-end' : 'tw-justify-start' ]},[_c('div',{staticClass:"tw-flex tw-gap-x-2",class:[
            _vm.message.user.id === _vm.user.id
              ? 'tw-flex-row-reverse'
              : 'tw-flex-row' ]},[_c('span',{staticClass:"tw-relative tw-inline-block tw-w-auto tw-max-w-full tw-rounded-lg tw-px-4 tw-py-2",class:[
              _vm.message.user.id === _vm.user.id
                ? 'tw-bg-blue-600 tw-text-white'
                : 'tw-bg-gray-300 tw-text-gray-700',
              _vm.message.messages.length - 1 === index &&
              _vm.message.user.id === _vm.user.id
                ? 'tw-rounded-br-none'
                : '',
              _vm.message.messages.length - 1 === index &&
              _vm.message.user.id !== _vm.user.id
                ? 'tw-rounded-bl-none'
                : '' ],staticStyle:{"white-space":"break-spaces","word-wrap":"break-word","text-indent":"0"}},[_vm._v(_vm._s(message_row.body)+" "),_c('div',{staticClass:"tw-flex tw-text-opacity-75",class:[
                _vm.message.user.id === _vm.user.id
                  ? 'tw-justify-end tw-text-gray-200'
                  : 'tw-justify-start tw-text-gray-600' ],staticStyle:{"font-size":"11px","line-height":"15px"}},[_vm._v(" "+_vm._s(message_row.formated_date)+" ")])]),_c('div',{staticClass:"tw-opacity-0 tw-transition focus-within:tw-opacity-100 group-hover:tw-opacity-100"},[(_vm.canDelete)?_c('v-menu',{attrs:{"offset-y":"","transition":"scale-transition","content-class":"tw-py-1 tw-mt-2 tw-bg-white tw-rounded-md tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"tw-inline-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2",attrs:{"type":"button","aria-expanded":"false","aria-haspopup":"true"}},'button',attrs,false),on),[_c('span',{staticClass:"tw-sr-only"},[_vm._v("Open options")]),_c('svg',{staticClass:"tw-h-5 tw-w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"d":"M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"}})])])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.deleteMessage(message_row)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.delete')))])],1)],1)],1):_vm._e()],1)])])}),0),(_vm.canViewProfile)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"tw-h-6 tw-w-6 tw-shrink-0 tw-rounded-full focus:tw-outline-none focus:tw-ring",class:[_vm.message.user.id === _vm.user.id ? 'tw-order-2' : 'tw-order-1'],on:{"click":function($event){return _vm.viewAttendee(_vm.message.user)}}},'button',attrs,false),on),[_c('img',{staticClass:"tw-h-6 tw-w-6 tw-rounded-full tw-object-cover",attrs:{"src":_vm.message.user.avatar,"alt":"My profile"}})])]}}],null,false,1277222461)},[_c('span',[_vm._v(" "+_vm._s(_vm.message.user.first_name)+" "+_vm._s(_vm.message.user.last_name))])]):_c('img',{staticClass:"tw-h-6 tw-w-6 tw-transform tw-rounded-full tw-object-cover tw-transition-all",class:[_vm.message.user.id === _vm.user.id ? 'tw-order-2' : 'tw-order-1'],attrs:{"src":_vm.message.user.avatar,"alt":"My profile"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }