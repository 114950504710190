<template>
  <div>
    <v-dialog
      v-model="open"
      fullscreen
      persistent
      scrollable
      content-class="tw-rounded-none"
    >
      <!-- <v-card class="tw-rounded-none tw-border-0 tw-shadow-md"> -->
        <!-- <ModalHeader class="print:tw-hidden" @close-modal="closeModal">{{
          $t('pages.event.mod_dash.modal.title')
        }}</ModalHeader> -->
        <div class="-my-2 tw-overflow-x-auto tw-relative tw-w-full tw-overflow-hidden print:tw-h-auto print:tw-overflow-auto tw-bg-white">
          <nav class="tw-sticky tw-top-0 tw-z-10 tw-flex-initial tw-bg-primary-500 tw-shadow-md lg:tw-px-8 lg:tw-py-2 print:tw-hidden">
            <div class="tw-container tw-mx-auto tw-flex tw-items-center tw-justify-between tw-space-x-10">
              <h4 class="tw-text-2xl tw-font-bold tw-text-primary-50">
                {{$t('pages.event.mod_dash.modal.title')}}
              </h4>
              <div>
                <AppBtn color="green-light" type="button" icon="download_cloud" @click="print">
                    {{ $t('admin.enums.download') }}
                </AppBtn>
                <v-btn
                  fab
                  x-small
                  class="focus:tw-outline-none focus:tw-ring tw-ml-2"
                  @click="closeModal"
                >
                <v-icon>close</v-icon>
              </v-btn>
              </div>
            </div>
          </nav>
          <div class="tw-h-full tw-w-full tw-p-12 print:tw-overflow-none print:tw-p-0">
            <div class="tw-space-y-8 print:tw-translate-x-0 print:tw-translate-y-0 print:tw-scale-140 print:tw-space-y-0 print:tw-bg-origin-content">
              <section
                class="pageCard tw-relative tw-mx-auto tw-flex tw-bg-white"
                :style="styleCard"
              >
                <CredentialCard
                  :registry="registry"
                  :checkin-user="user"
                  :profile="profile"
                  :qrlink="qrlink"
                  :config="mountedCredential"
                  :user-form="mountedCredential.user_form"
                />
              </section>
            </div>
          </div>
        </div>
        <!-- <v-card-text class="tw-p-0">
          <v-responsive class="tw-bg-gray-50">
                <div id="credentialPrint" cols="12" class="mx-auto" :style="styleCard">
                  <CredentialCard
                    :registry="registry"
                    :checkin-user="user"
                    :profile="profile"
                    :qrlink="qrlink"
                    :config="mountedCredential"
                    :user-form="mountedCredential.user_form"
                  />
                </div>
          </v-responsive>
        </v-card-text>
        <v-card-actions class="print:tw-hidden">
          <v-spacer />
          <AppBtn
            type="button"
            class="print:tw-hidden"
            :disabled="form_loading"
            color="primary"
            @click="print"
          >
            {{ $t('pages.event.mod_dash.modal.button') }}
          </AppBtn>
        </v-card-actions> -->
      <!-- </v-card> -->
    </v-dialog>
  </div>
</template>

<script>
// import ModalHeader from '@/components/ModalHeader'
import AppBtn from '../App/AppBtn.vue'
import CredentialCard from '@/components/Event/CredentialCard.vue'
import { CredentialHydrate } from '@/models/Credential'

export default {
  name: 'PrintCredential',
  components: {
    // ModalHeader,
    AppBtn,
    CredentialCard,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    registry: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    profile: {
      type: Object,
      required: true,
    },
    qrlink: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    form_loading: false,
    mountedCredential: {},
  }),
  head() {
    return {
      style: [
        {
            type: 'text/css',
            inner: this.mountedCredential.custom_css,
        },
      ],
    }
  },
  computed: {
    styleCard() {
        return {
            '--width': `${this.mountedCredential.width}px`,
            '--height': `${this.mountedCredential.height}px`,
        }
    },
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  mounted() {
    this.getCredential()
    this.$emit('updateHead')
  },
  methods: {
    getCredential() {
      this.mountedCredential = CredentialHydrate(
        this.$store.state.event.constants.registry.credential,
        this.registry.forms,
      )
    },
    closeModal() {
      this.open = false
    },
    addElementStylePagePrint() {
      let style = document.createElement('style')
      style.innerHTML = `
          @page {
              size: ${this.mountedCredential.width}px ${this.mountedCredential.height}px;
          }
      `
      document.head.appendChild(style)
    },
    print() {
      // document.body.style.visibility = 'hidden'
      this.addElementStylePagePrint()
      window.print()
      this.open = false
      // document.body.style.visibility = 'visible'
    },
  },
}
</script>

<style type="text/css">
  @page {
    margin: 0 !important;
    padding: 0 !important;
  }
  .pageCard {
    width: var(--width);
    height: var(--height);
  }
  @media print {
    .pageCard, .pageCard * {
        visibility: visible;
        border-radius: 0;
        --tw-shadow: 0 0 transparent;
        -webkit-print-color-adjust: exact !important;
    }
  }
</style>
