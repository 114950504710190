import gql from 'graphql-tag'

export const CORE_MESSAGE_FIELDS = gql`
  fragment CoreMessageFields on Message {
    body
    channel_id
    created_at
    id
    uuid
    user {
      email
      first_name
      last_name
      id
      uuid
      avatar
    }
  }
`
export const CHANNEL_MESSAGES = gql`
  ${CORE_MESSAGE_FIELDS}
  query channelMessages($chanel_id: Int!) {
    channelMessagesPlusOne(id: $chanel_id) {
      ...CoreMessageFields
    }
  }
`

export const SUB_CHANNEL_MESSAGES = gql`
  ${CORE_MESSAGE_FIELDS}
  subscription subChannelMessages($chanel_id: Int!) {
    newChannelMessage(id: $chanel_id) {
      ...CoreMessageFields
    }
  }
`

export const SUB_DELETED_MESSAGES = gql`
  ${CORE_MESSAGE_FIELDS}
  subscription deletedChannelMessage($chanel_id: Int!) {
    deletedChannelMessage(id: $chanel_id) {
      ...CoreMessageFields
    }
  }
`

export const newChannelMessageFromPrivate = gql`
  ${CORE_MESSAGE_FIELDS}
  subscription newChannelMessageFromPrivate($ids: String!) {
    newChannelMessageFromPrivate(ids: $ids) {
      ...CoreMessageFields
    }
  }
`

export const DELETE_CHANNEL_MESSAGE = gql`
  mutation deleteMessage($msg_id: Int!) {
    deleteMessage(id: $msg_id)
  }
`
export const NEW_CHANNEL_MESSAGE = gql`
  mutation newChannelMessage($message: String!, $chanel_id: Int!) {
    createMessage(id: $chanel_id, message: $message)
  }
`
export const storeMessage = gql`
  mutation storeMessage($content: CreateMessageForm!) {
    storeMessage(content: $content)
  }
`

export const newChannelMessageAndParticipant = gql`
  mutation newChannelMessageAndParticipant(
    $message: String!
    $chanel_id: Int!
  ) {
    createMessage(id: $chanel_id, message: $message)

    createNewChannelParticipant(id: $chanel_id)
  }
`
