<template>
  <v-container class="pa-5">
    <v-row v-if="hasToggle">
      <v-col cols="12" class="">
        <h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
          <!-- ¿Tiene horario de apertura y cierre? -->
          <slot name="title"> Habilitar hora de inicio y finalización </slot>
        </h3>
        <div class="tw-mt-2 sm:tw-flex sm:tw-items-start sm:tw-justify-between">
          <div class="tw-max-w-xl tw-text-sm tw-text-gray-500">
            <p class="tw-mb-0">
              <slot name="description">
                Especifique la hora a la que los usuarios serán redirigidos al
                entorno de experiencia virtual después del registro. Aparte de
                eso, los usuarios serán redirigidos a la página de
                agradecimiento.
              </slot>
            </p>
          </div>
          <div
            class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
          >
            <v-switch v-model="form.has_time_bracket" inset large />
          </div>
        </div>
      </v-col>
    </v-row>
    <div v-if="form.has_time_bracket">
      <v-row :class="{ 'tw-mt-4': hasToggle }">
        <v-col v-if="canSpecifyInterval" cols="12" sm="12" class="tw-py-0">
          <v-select
            v-model="form.time_bracket_type"
            :items="time_bracket_types"
            :rules="[rules.required]"
            validate-on-blur
            label="Tipo de intervalo"
            outlined
            rounded
          />
        </v-col>
        <v-col
          v-if="['only_start', 'start_end'].includes(form.time_bracket_type)"
          cols="12"
          sm="12"
          class="tw-py-0"
        >
          <label class="tw-block tw-pb-2 tw-text-gray-600">
            {{ $t('app.start_time') }}
          </label>
          <div class="tw-grid tw-grid-cols-2 tw-gap-4">
            <div>
              <DateInput v-model="form.start_date" />
            </div>
            <div>
              <v-text-field
                v-model="form.start_time"
                :label="$t('app.hour')"
                :rules="[rules.required]"
                outlined
                rounded
                type="time"
              />
            </div>
          </div>
        </v-col>
        <v-col
          v-if="['only_end', 'start_end'].includes(form.time_bracket_type)"
          cols="12"
          sm="12"
          class="tw-py-0"
        >
          <label class="tw-block tw-pb-2 tw-text-gray-600">
            {{ $t('app.end_time') }}
          </label>
          <div class="tw-grid tw-grid-cols-2 tw-gap-4">
            <div>
              <DateInput v-model="form.end_date" :date-min="form.start_date" />
            </div>
            <div>
              <v-text-field
                v-model="form.end_time"
                :label="$t('app.hour')"
                :rules="[rules.required]"
                outlined
                rounded
                type="time"
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="hasTimezones">
        <v-col cols="12" sm="12" class="pb-0">
          <TimezonesAutocomplete
            v-model="form.time_bracket_tz"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import TimezonesAutocomplete from '@/components/TimezonesAutocomplete.vue'
import DateInput from '@/components/FormInputs/DateInput.vue'
import { DurationBase } from '@/models/Duration'
import { rules } from '@/utils/rules'
import { time_bracket_types } from '@/utils/dateHelpers'

export default {
  components: {
    DateInput,
    TimezonesAutocomplete,
  },
  props: {
    duration: {
      type: Object,
      default: () => DurationBase({}),
    },
    hasTimezones: {
      type: Boolean,
      default: true,
    },
    canSpecifyInterval: {
      type: Boolean,
      default: true,
    },
    hasToggle: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
      required: true,
      default: () => DurationBase({}),
    },
  },
  data: () => ({
    date_min: new Date(new Date().setDate(new Date().getDate() - 1))
      .toISOString()
      .substr(0, 10),
    rules,
    time_bracket_types,
  }),
  computed: {
    form: {
      get() {
        return new Proxy(this.value, {
          set: (obj, key, value) => {
            this.form = { ...obj, [key]: value }
            return true
          },
        })
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
  methods: {},
}
</script>

<style></style>
