<template>
  <v-autocomplete
    v-model="input_value"
    :items="rowsFormat"
    :loading="isLoading"
    :search-input.sync="search"
    :label="$t('admin.home.labels.messages')"
    outlined
    item-text="title"
    item-value="id"
    rounded
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          <span
            class="tw-inline-flex tw-items-center tw-whitespace-pre tw-rounded-full tw-border tw-border-gray-300 tw-bg-white tw-px-2 tw-py-0.5 tw-text-xs tw-font-medium tw-leading-5 tw-text-gray-700 tw-shadow-sm hover:tw-bg-gray-50"
          >
            {{ item.id }}
          </span>
          {{ item.subject }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $t('app.msg_count') }}: {{ item.messages_count }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          {{ $t('app.created_at') }}: {{ item.date }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { commonTimeFormat } from '@/utils/dateHelpers'
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    filterName: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Hilos de preguntas',
    },
  },
  data: () => ({
    search: null,
  }),
  computed: {
    ...mapState({
      items: (state) => state.constants.run_time.channels,
      isLoading: (state) => state.constants.is_loading.channels,
    }),
    rows() {
      if (this.filterName) {
        return this.items.filter((row) => {
          return this.filterName == row.channel_type.name
        })
      }
      return this.items
    },
    rowsFormat() {
      return this.rows.map((row) => ({
        ...row,
        title: `${row.id}: ${row.subject}`,
        date: commonTimeFormat(row.created_at),
      }))
    },
    input_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  mounted() {
    if (this.items.length > 0) return
    this.loadRows()
  },
  methods: {
    loadRows() {
      this.$store.dispatch('constants/loadChannels')
    },
  },
}
</script>
