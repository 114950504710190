var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-h-full tw-flex-col tw-bg-white"},[_c('main',{staticClass:"tw-flex-1"},[_c('div',{staticClass:"tw-py-32"},[_c('div',{staticClass:"tw-mx-auto tw-max-w-md tw-px-4 sm:tw-max-w-3xl sm:tw-px-6 lg:tw-max-w-3xl lg:tw-px-8"},[_c('div',{staticClass:"lg:tw-grid lg:tw-grid-cols-1 lg:tw-items-center lg:tw-gap-24"},[_c('div',[_c('v-img',{staticClass:"tw-h-24",attrs:{"src":require('@/assets/logo-bizzdome.svg'),"contain":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0 transparent",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"size":"32","indeterminate":"","color":"primary"}})],1)]},proxy:true}])})],1),_c('div',{staticClass:"tw-mt-12 tw-grid tw-grid-cols-2 tw-gap-0.5 md:tw-grid-cols-3 lg:tw-mt-0 lg:tw-grid-cols-2"},[_c('div',{staticClass:"tw-col-span-2 tw-flex tw-justify-center tw-flex-col tw-items-center tw-bg-gray-50 tw-py-8 tw-px-8 md:tw-col-span-3 lg:tw-col-span-2"},[_c('h2',{staticClass:"tw-text-3xl tw-font-extrabold tw-tracking-tight tw-text-gray-900 sm:tw-text-4xl"},[_vm._v(" "+_vm._s(_vm.$t('pages.registry.index.header'))+" ")]),(_vm.activeAlert)?_c('v-alert',{staticClass:"tw-mt-4 tw-rounded-lg",attrs:{"color":"red","dense":"","text":"","type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('app.registry_inactive_desc'))+" ")]):_vm._e()],1),_vm._l((_vm.registries),function(ref){
var route = ref.route;
var uuid = ref.uuid;
var langs = ref.langs;
var isAvailable = ref.isAvailable;
return _c('div',{key:uuid,class:langs[_vm.current_language_key] && isAvailable ? '' : 'tw-hidden'},[(langs[_vm.current_language_key])?[_c('div',{staticClass:"tw-h-full tw-w-full tw-group focus-within:ring-primary-500 tw-relative tw-flex tw-justify-center tw-bg-gray-50 focus-within:tw-ring-2 focus-within:tw-ring-inset hover:tw-bg-gray-100"},[_c('router-link',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-py-8 tw-px-8 focus:tw-outline-none",attrs:{"to":route}},[_c('img',{staticClass:"tw-max-h-12",attrs:{"src":langs[_vm.current_language_key].logo_url_sm 
                        ? langs[_vm.current_language_key].logo_url_sm 
                        : require('@/assets/icon-bizzdome.svg'),"alt":"Bizz Dome Event"}})])],1)]:_vm._e()],2)}),_c('div',{staticClass:"tw-col-span-2 tw-flex tw-w-full tw-justify-end tw-py-8"},[_c('div',[_c('LocaleSwitcher')],1)])],2)])])])]),_c('footer',{staticClass:"tw-mt-24 tw-bg-primary-900 sm:tw-mt-12"},[_c('div',{staticClass:"tw-mx-auto tw-max-w-md tw-overflow-hidden tw-py-12 tw-px-4 sm:tw-max-w-3xl sm:tw-px-6 lg:tw-max-w-7xl lg:tw-px-8"},[_c('p',{staticClass:"tw-mt-8 tw-text-center tw-text-base tw-text-gray-400"},[_vm._v(" © "+_vm._s(_vm.year)+" BIZZEVENTS, All rights reserved. ")]),_c('p',{staticClass:"tw-mt-8 tw-text-center tw-text-base tw-text-gray-400"},[_c('router-link',{attrs:{"place":"link","to":"/auth/login"}},[_vm._v(" Admin portal ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }