<template>
<div class="tw-flex tw-flex-col">
    <!-- header -->
    <div class="tw-flex tw-items-center tw-justify-between">
        <v-btn small rounded text class="tw-pl-2" @click="onBack()">
            <v-icon left class="tw-text-secondary-400 tw-m-0">chevron_left</v-icon>
            <span class="tw-text-sm tw-normal-case tw-font-bold tw-text-secondary-400">{{
                    $t('pages.event.chat.labels.notes') }}</span>

        </v-btn>
        <div>
            <v-btn small rounded text :loading="loading" :disabled="loading" @click="save()">
                <v-icon left class="tw-text-secondary-400">done</v-icon>
                <span class="tw-text-xs tw-normal-case tw-font-bold tw-text-secondary-400">{{
                        $t('pages.event.chat.notes.save') }}</span>
                <template #loader>
                    <v-progress-circular :size="14" :width="1" color="secondary" indeterminate />
                </template>
            </v-btn>
            <!-- <v-btn icon small>
                <v-icon class="tw-text-secondary-400 tw-text-sm">settings</v-icon>
            </v-btn> -->
        </div>
    </div>
    <!-- body -->
    <div>
        <v-text-field v-model="title" :label="$t('pages.event.chat.notes.untitled')" class="tw-font-bold" solo flat single-line hide-details />
        <v-textarea v-model="description" solo-inverted flat full-width class="tw-bg-[#F3F2F8] tw-text-xs" height="340" hide-details />
    </div>
</div>
</template>

<script>
import {
    mapState,
} from 'vuex'

export default {
    data: () => ({
        title: '',
        description: '',
        highlight: false,
        loading: false,
    }),
    computed: {
        ...mapState({
            current_user: (state) => state.session.user.id,
        }),
    },
    methods: {
        save() {
            this.loading = true
            const payload = {
                user_id: this.current_user,
                title: this.title ? this.title : this.$t('pages.event.chat.notes.untitled'),
                description: this.description,
                highlight: this.highlight,
            }
            this.$store.dispatch('event/quickNotesStore', payload)
                .then(() => {
                    this.title = ''
                    this.description = ''
                    this.highlight = ''
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.loading = false
                    this.onBack()
                })
        },
        onBack() {
            this.$emit('on-back')
        },
    },
}
</script>

<style scoped></style>
