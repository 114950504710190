<template>
    <div class="tw-px-2 lg:tw-px-6 tw-py-10 tw-space-y-2">
        <!-- Item -->
        <div 
            v-for="(rule) in rules" 
            :key="rule.id"
            class="tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-2 tw-rounded-full tw-bg-[#FCFCFC]"
        >
            <div>
                <span class="tw-text-xs tw-font-bold tw-ml-2">{{ rule.label }}</span>
            </div>
            <div>
                <span class="tw-text-xs tw-font-bold">{{ rule.points }} pts.</span>

            </div>
        </div>
        <!-- End Item -->
    </div>
</template>

<script>
export default {
    props: {
        rules: {
            type: [Array, Object],
            default: () => ({}),
        },
    },
}
</script>