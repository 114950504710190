export const CertificateBase = ({
    width = 842,
    height = 595,
    ui_background = '#3565AF',
    url_background = '',
    user_form = {
        // Logos
        logo_pry_is_active: false,
        logo_pry_url: '',
        logo_pry_size: 120,
        logo_sec_is_active: false,
        logo_sec_url: '',
        logo_sec_size: 120,
        // firmas
        firma_pry_is_active: false,
        firma_pry_name: '',
        firma_pry_detail: '',
        firma_pry_url: '',
        firma_pry_color: '#FFFFFF',
        firma_sec_is_active: false,
        firma_sec_name: '',
        firma_sec_detail: '',
        firma_sec_url: '',
        firma_sec_color: '#FFFFFF',
        // Title
        title_text: '',
        title_is_image: false,
        title_url: '',
        title_color: '#FFFFFF',
        title_size: 40,
        // Subtitle
        to_text: '',
        to_color: '#FFFFFF',
        to_size: 14,
        // Fullname
        fullname_color: '#FFFFFF',
        fullname_size: 60,
        // Description
        description_text: '',
        description_color: '#FFFFFF',
        description_size: 14,
    },
}) => ({
    width,
    height,
    ui_background,
    url_background,
    user_form,
})

export const CertificateHydrate = (CertificateDB) => {
    if (CertificateDB) {
        const payload = {...CertificateDB}
        return CertificateBase(payload)
    }
    return CertificateBase({})
}