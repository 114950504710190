import { ThreadsResponsesService } from '@/services/ThreadsResponsesService'
import { ThreadsService } from '@/services/ThreadsService'
import { formErrorsHandler } from '@/utils/formErrorsHandler'

// state
const initState = () => {
  return {
    show: {
      isBeingCopied: false,
      isLoading: true,
      data: {
        channel_type: {},
        participants: [],
      },
    },
  }
}

// getters
const getters = {}

// actions
const actions = {
  index(_, payload) {
    return ThreadsService.index(payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  indexResponses({ state }, payload) {
    const { id } = state.show.data
    return ThreadsResponsesService.index(id, payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  store({ commit }, { body = '' }) {
    commit('setAppIsLoading', true, { root: true })
    return ThreadsService.store(body)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  update({ commit, state }, { payload }) {
    commit('setAppIsLoading', true, { root: true })
    return ThreadsService.update(state.show.data.id, payload)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  show({ commit }, { id, loading = false, appLoading = false }) {
    if (loading) {
      commit('setShow', { isLoading: true })
    }
    if (appLoading) {
      commit('setAppIsLoading', true, { root: true })
    }
    return ThreadsService.show(id)
      .then(({ data: { data } }) => {
        commit('setShow', { data: data })
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setShow', { isLoading: false })
        commit('setAppIsLoading', false, { root: true })
      })
  },
  destroy({ commit }, { id = '' }) {
    commit('setAppIsLoading', true, { root: true })
    return ThreadsService.destroy(id)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
}

// mutations
const mutations = {
  setShow(state, obj) {
    Object.keys(obj).forEach((k) => (state.show[k] = obj[k]))
  },
}

export const Threads = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
}
