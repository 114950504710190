<template>
    <div class="bz-countdown" :style="bgTimer">
        <!-- <div class="bz-login-bg tw-hidden lg:tw-block" :style="bgImg" /> -->
        <div class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-4 tw-mx-auto tw-max-w-7xl tw-w-full">
            <!-- Days -->
            <div class="bz-countdown-box">
                <div class="bz-countdown-number">{{ days }}</div>
                <p class="bz-countdown-text">{{ $t('words.timer.days') }}</p>
            </div>
            <!-- Hours -->
            <div class="bz-countdown-box">
                <div class="bz-countdown-number">{{ hours }}</div>
                <p class="bz-countdown-text">{{ $t('words.timer.hours') }}</p>
            </div>
            <!-- Minutes -->
            <div class="bz-countdown-box">
                <div class="bz-countdown-number">{{ minutes }}</div>
                <p class="bz-countdown-text">{{ $t('words.timer.minutes') }}</p>
            </div>
            <!-- Seconds -->
            <div class="bz-countdown-box">
                <div class="bz-countdown-number">{{ seconds }}</div>
                <p class="bz-countdown-text">{{ $t('words.timer.seconds') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BizzDomeUiRegistryCountdown',
    props: {
        section: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            timerRunning: false,
            timeRemaining: 0,
            interval: null,
        }
    },
    computed: {
        days: function () {
            return Math.floor(this.timeRemaining / (24 * 60 * 60))
        },
        hours: function () {
            return Math.floor((this.timeRemaining % (24 * 60 * 60)) / 3600)
        },
        minutes: function () {
            return Math.floor((this.timeRemaining % 3600) / 60)
        },
        seconds: function () {
            return this.timeRemaining % 60
        },
        bgTimer() {
            if (this.section.data.image_url) {
                return {
                    'background-image': `url(${this.section.data.image_url})`,
                }
            }
            return {
                'background': `${this.section.data.color_hex}`,
            }
        },
    },
    mounted() {
        this.startTimer()
    },
    methods: {
        startTimer: function () {
            if (!this.timerRunning) {
                const targetDate = new Date(this.section.data.time_event)
                this.timerRunning = true

                this.interval = setInterval(() => {
                    const now = new Date()
                    this.timeRemaining = Math.floor((targetDate - now) / 1000)

                    if (this.timeRemaining <= 0) {
                        this.stopTimer()
                    }
                }, 1000)
            }
        },
        stopTimer: function () {
            clearInterval(this.interval)
            this.timerRunning = false
        },
    },
}
</script>
<style>
.bz-countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 360px;
    background-position: center;
    background-size: cover;
}
.bz-countdown-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.bz-countdown-number {
    font-weight: 800;
    width: 5rem;
    height: 5rem;
    background: rgba(255, 255, 255, 0.77);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    font-size: 1.7rem;
}
.bz-countdown-text {
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 0.7rem;
}
</style>
