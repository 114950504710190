<template>
  <div class="tw-bg-landing-primary lg:tw-relative">
    <div
      class="tw-mx-auto tw-max-w-7xl tw-px-4 tw-pt-16 tw-pb-16 sm:tw-px-0 sm:tw-pt-24 lg:tw-grid lg:tw-grid-cols-2 lg:tw-gap-8 lg:tw-pb-24 lg:tw-pt-24"
    >
      <div class="tw-relative lg:tw--my-8">
        <div
          aria-hidden="true"
          class="tw-absolute tw-inset-x-0 tw-top-0 tw-h-1/2 lg:tw-hidden"
        />
        <div
          class="tw-mx-auto tw-max-w-md sm:tw-max-w-3xl lg:tw-h-full lg:tw-p-0"
        >
          <a
            class="tw-aspect-w-9 tw-aspect-h-5 tw-block tw-overflow-hidden tw-rounded-xl tw-shadow focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-500"
            :href="section.data.activities_pdf"
            target="_blank"
          >
            <div class="tw-aspect-w-9 tw-aspect-h-5">
              <img
                class="tw-object-cover lg:tw-h-full lg:tw-w-full"
                :src="section.data.activities_url"
                alt=""
              />
            </div>
            <!-- <div class="">
            </div> -->
          </a>
        </div>
      </div>
      <div
        v-if="registry.start_date_time && section.data.has_calendar"
        class="tw-relative tw-mt-6 lg:tw--my-8"
      >
        <div
          aria-hidden="true"
          class="tw-absolute tw-inset-x-0 tw-top-0 tw-h-1/2 lg:tw-hidden"
        />
        <div
          class="tw-mx-auto tw-max-w-md sm:tw-max-w-3xl lg:tw-h-full lg:tw-p-0"
        >
          <v-menu v-model="showMenu" absolute offset-y style="max-width: 600px">
            <template #activator="{ on, attrs }">
              <div
                v-ripple
                v-bind="attrs"
                class="tw-aspect-w-9 tw-aspect-h-5 tw-overflow-hidden tw-rounded-xl tw-shadow"
                v-on="on"
              >
                <v-img
                  class="tw-object-cover lg:tw-h-full lg:tw-w-full"
                  :src="section.data.calendar_url"
                  alt=""
                />
              </div>
            </template>

            <v-list>
              <v-list-item v-if="canViewCalendar" @click="openSimpleCalendar">
                <v-list-item-title>
                  {{ $t('app.view_events') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-for="(item, index) in calendar_normal"
                :key="index"
                :href="item"
                target="_blank"
              >
                <v-list-item-title>
                  {{ $t('app.add_to') }} {{ index }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-for="(item, index) in calendar_ics"
                :key="index"
                @click="downloadToCal(item)"
              >
                <v-list-item-title>
                  {{ $t('app.add_to') }} {{ index }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <!-- <div class="tw-relative lg:tw--my-8">
        <v-card
          class="tw-max-w-md tw-mx-auto tw-overflow-hidden tw-border-0 sm:tw-max-w-3xl lg:tw-p-0 lg:tw-h-full"
        >
          <v-container
            class="tw-p-0 tw-overflow-hidden tw-shadow-xl tw-rounded-xl tw-aspect-w-9 tw-aspect-h-5"
          >
            <img
              class="tw-object-cover lg:tw-h-full lg:tw-w-full"
              :src="section.data.calendar_url"
              alt=""
            />
          </v-container>
        </v-card>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { eventModel } from '@/utils/eventToCalendar'
import { download } from '@/utils/download'
export default {
  props: {
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    showMenu: false,
    items: [
      { title: 'Click Me' },
      { title: 'Click Me' },
      { title: 'Click Me' },
      { title: 'Click Me 2' },
    ],
  }),
  computed: {
    calendar_urls() {
      return eventModel({
        name: this.registry.label,
        details: this.registry.description,
        startsAt: this.registry.start_date_time,
        endsAt: this.registry.end_date_time,
      })
    },
    calendar_normal() {
      return {
        google: this.calendar_urls.google,
        outlook: this.calendar_urls.outlook,
        yahoo: this.calendar_urls.yahoo,
      }
    },
    calendar_ics() {
      return {
        icloud: this.calendar_urls.ics,
      }
    },
    canViewCalendar() {
      return this.currentCalendar.is_active
    },
    currentCalendar() {
      return this.registry.calendars.find(
        (row) => row.language.name == this.current_language_key,
      )
    },
    ...mapState({
      current_language_key: (state) => state.registry.show.current_language_key,
      // current_language_key: (state) => state.registry.show.current_language_key,
      registry: (state) => state.registry.show.data,
    }),
  },
  methods: {
    openSimpleCalendar() {
      this.$store.commit('registry/setShow', { simple_calendar_modal: true })
    },
    downloadToCal(file) {
      download(file, `${this.registry.label} icloud`)
    },
  },
}
</script>

<style></style>
