<template>
  <v-dialog 
    v-model="open" 
    :persistent="!!canChat" 
    :content-class="computedContentClass"
    @input="onDialogInput">
    <v-card v-if="hasText || hasImage">
      <v-card-text :class="hasText ? 'tw-p-5':'tw-p-1'">
        <template v-if="hasText">
          <div v-html="virtualRoom.autolaunch_text" />
        </template>
        <template v-if="hasImage">
          <img :src="virtualRoom.autolaunch_image"  class="tw-rounded-2xl"/>
        </template>
      </v-card-text>
      <v-divider/>
      <v-card-actions v-if="virtualRoom.clickable">
        <v-spacer/>
        <RenderlessClickableHandle>
          <template slot-scope="{ slotClickActionEvent }">
            <AppBtn type="button" color="primary" @click="slotClickActionEvent(virtualRoom)">
              {{ virtualRoom.has_autolaunch_label_button }}
            </AppBtn>
          </template>
        </RenderlessClickableHandle>
      </v-card-actions>
    </v-card>
    <template v-if="hasVideo">
      <ElementVideoOnly ref="element-video" :video="virtualRoom" :can-close="true"/>
    </template>
  </v-dialog>
</template>
<script>
import RenderlessClickableHandle from '@/components/Event/RenderlessClickableHandle.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import ElementVideoOnly from '@/components/Event/ElementVideoOnly.vue'

export default {
  components: {
    RenderlessClickableHandle,
    AppBtn,
    ElementVideoOnly,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    virtualRoom: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    canChat() {
      return this.virtualRoom.content_elements?.can_chat
    },
    hasText() {
      return this.virtualRoom.autolaunch_type == 'bt_text'
    },
    hasImage() {
      return this.virtualRoom.autolaunch_type == 'bt_image'
    },
    hasVideo() {
      return this.virtualRoom.autolaunch_type == 'bt_video'
    },
    computedContentClass() {
      if (this.canChat) {
        return 'tw-w-full tw-rounded-3xl tw-max-w-7xl 2xl:tw-max-w-screen-2xl fill-height tw-max-h-[640px] tw-bg-white'
      }
      if (this.hasVideo) {
        return 'tw-w-full tw-rounded-3xl tw-max-w-7xl 2xl:tw-max-w-screen-lg tw-h-auto tw-bg-white'
      }
      return 'tw-rounded-3xl tw-max-w-xl tw-max-h-[640px] tw-bg-white bz-scroll'
    },
  },
  mounted() {
    console.log('virtual room',this.virtualRoom)
  },
  methods: {
    onDialogInput(val) {
      if (!val && this.hasVideo) {
        this.handleDialogClose()
      }
    },
    handleDialogClose() {
      this.$refs['element-video'].pauseElement()
    },
  },
}
</script>
<style lang="scss">
.bz-scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
}
</style>
