// const Auth = () => import(/* webpackChunkName: "auth" */ "@/views/auth");

import CommonContainer from '@/components/CommonContainer'
export default [
  {
    path: '/auth',
    component: CommonContainer,
    meta: {
      requiresGuest: true,
    },
    children: [
      {
        path: 'login',
        // component: Login,
        component: () =>
          import(
            /* webpackChunkName: "admin-login" */
            '@/views/Auth/Login.vue'
          ),
        name: 'auth.login',
      },
    ],
  },
]
