<template>
  <v-container class="tw-bg-primary-500" fluid>
    <v-row>
      <v-col cols="12" class="pb-0">
        <div class="tw-flex tw-justify-between">
          <div>
            <QuaternaryTitle>
              <slot />
            </QuaternaryTitle>
            <div
              class="tw-mb-2 tw-h-1 tw-w-16 tw-rounded-full tw-bg-primary-200"
            />
          </div>
          <div>
            <slot name="right">
              <v-btn
                fab
                x-small
                class="focus:tw-outline-none focus:tw-ring"
                @click="closeModal"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </slot>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import QuaternaryTitle from '@/components/Titles/QuaternaryTitle.vue'
export default {
  components: {
    QuaternaryTitle,
  },
  methods: {
    closeModal() {
      this.$emit('close-modal')
    },
  },
}
</script>
