<template>
  <renderless-layout>
    <v-container fluid class="tw-p-0">
      <sticky-header :nav="nav">
        <template #actions>
          <div class="tw-flex tw-items-center tw-space-x-4">
            <LanguageSelect
              v-model="current_config_language_key"
              :label="$t('admin.app.lang_version')"
              dark
            />
            <AppBtn
              color="primary-dark"
              icon="g-next"
              g-next="Eye"
              @click="preview"
            >
              {{ $t('app.preview') }}
            </AppBtn>
            <AppBtn color="white" icon="check" @click="updateForms">
              {{ $t('app.save') }}
            </AppBtn>
          </div>
        </template>
      </sticky-header>
      <v-row class="tw-m-0 tw-p-0">
        <v-col cols="12" sm="12" md="12" lg="11" xl="9" class="mx-auto">
          <v-container fluid class="tw-space-y-6">
            <v-row class="">
              <v-col cols="12" md="3" class="px-5 pt-5 mx-auto">
                <form-description>
                  <template #title>
                    {{ $t('admin.events.background') }}
                  </template>
                  <template #copy>
                    {{ $t('admin.events.logins_description') }}
                  </template>
                </form-description>
              </v-col>

              <v-col cols="12" md="9" class="mx-auto pa-5">
                <v-card class="mx-auto tw-rounded-3xl tw-shadow-md">
                  <v-container class="pa-5">
                    <dropzone-preview
                      v-model="image_url"
                      :width="1920"
                      :bypass="'no-check'"
                      :height="1080"
                      media-group="registry-login-screen"
                    />
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </renderless-layout>
</template>

<script>
import LanguageSelect from '@/components/LanguageSelect'
import AppBtn from '@/components/App/AppBtn.vue'

import DropzonePreview from '@/components/DropzonePreview'
import FormDescription from '@/components/FormDescription'

import StickyHeader from '@/components/StickyHeader'
import { mapState, mapGetters } from 'vuex'
import { mapFields, mapNestedFields } from '@/helpers'

export default {
  components: {
    AppBtn,
    LanguageSelect,
    StickyHeader,
    DropzonePreview,
    FormDescription,
  },

  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    ...mapNestedFields({
      fields: ['image_url'],
      base: ['registries', 'login_screens', 'update', 'data'],
      mutation: 'registries/setLoginScreensUpdateForm',
      nested_key: 'current_config_language_key',
    }),
    nav() {
      return [
        {
          name: this.$t('app.event'),
          route: { name: 'admin.registries.index' },
        },
        {
          name: this.show.label,
          route: {
            name: 'admin.registries.show.home',
            params: { id: this.show.id },
          },
        },
        {
          name: this.$t('admin.events.items.labels.logins'),
          route: {},
        },
      ]
    },
    ...mapFields({
      fields: ['current_config_language_key'],
      base: ['registries', 'landings', 'update', 'data'],
      mutation: 'registries/setLandingsUpdateWrapper',
    }),
    ...mapGetters({
      languages: ['constants/languages'],
    }),
    ...mapState({
      show: (state) => state.registries.show.data,
    }),
  },
  methods: {
    preview() {
      this.$router.push({
        name: 'registry.login',
        params: { slug: this.show.slug },
      })
    },

    updateForms() {
      this.$store.dispatch('registries/updateLoginScreens').then(() =>
        this.$store.dispatch('registries/show', {
          id: this.id,
          appLoading: true,
        }),
      )
    },
  },
}
</script>

<style></style>
