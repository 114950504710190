<template>
  <div class="">
    <div
      class="tw-mx-auto tw-flex tw-max-w-lg tw-justify-center tw-px-6 tw-pt-5 tw-pb-6"
    >
      <div class="tw-space-y-1 tw-text-center">
        <svg
          v-if="!doesNotHaveSize"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="tw-mx-auto tw-h-12 tw-w-12 tw-text-gray-400"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
        <div
          class="tw-flex tw-justify-center tw-text-center tw-text-sm tw-text-gray-600"
        >
          <div class="tw-flex tw-flex-wrap">
            <label
              for="file-upload"
              class="focus-within:outline-none tw-relative tw-mx-auto tw-cursor-pointer tw-rounded-md tw-font-medium tw-text-primary-600 focus-within:tw-ring-2 focus-within:tw-ring-primary-500 focus-within:tw-ring-offset-2 hover:tw-text-primary-500"
            >
              <span>{{ $t('app.upload_a_file') }}</span>
            </label>
            <p class="tw-mb-0 tw-pl-1">{{ $t('app.upload_a_file_drag') }}</p>
          </div>
        </div>
        <p v-if="placeholder" class="tw-mb-0 tw-text-sm tw-text-gray-500">
          {{ placeholder }}
        </p>
        <p
          v-else-if="!doesNotHaveSize"
          class="tw-mb-0 tw-text-xs tw-text-gray-500"
        >
          <i18n path="app.upload_text" tag="div">
            <template #size>
              <span> {{ width }}x{{ height }} </span>
            </template>
            <template #break>
              <br />
            </template>
          </i18n>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { greatestCommonDivisor } from '@/utils/greatestCommonDivisor'
export default {
  props: {
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  computed: {
    doesNotHaveSize() {
      return [this.height, this.width].includes(0)
    },
  },
  mounted() {
    console.log(greatestCommonDivisor(this.width, this.height))
  },
}
</script>

<style></style>
