import gql from 'graphql-tag'

export const getUsersLocation = gql`
  query getUsersLocation {
    getUsersLocation {
      registry_uuid
      user_id
      slug
    }
  }
`

export const setUserLocation = gql`
  mutation setUserLocation($slug: String!) {
    setUserLocation(slug: $slug)
  }
`

export const subUsersLocation = gql`
  subscription subUsersLocation {
    subUsersLocation {
      registry_uuid
      user_id
      slug
    }
  }
`

export const setUserLocationAndActive = gql`
  mutation setUserLocationAndActive($slug: String!) {
    setUserLocation(slug: $slug)
    # setActive
  }
`
