<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    content-class="tw-rounded-3xl tw-max-h-full tw-shadow-none tw-max-w-7xl tw-w-full"
  >
    <!-- max-width="1280px" -->
    <v-card
      class="tw-relative tw-rounded-3xl tw-border-0 tw-bg-transparent tw-shadow-none"
    >
      <modal-header v-if="false">{{ simpleVideoItemUrl }}</modal-header>
      <v-divider v-if="false" />
      <v-card-text class="tw-p-0">
        <v-responsive class="tw-py-0">
          <v-container id="iframe-wrapper" class="tw-bg-gray-100 tw-p-0" fluid>
            <!-- <iframe
              ref="iframe"
              :src="videoUrl"
              frameborder="0"
              class="tw-w-full"
              allow="camera *; geolocation *; microphone *; autoplay *;fullscreen *"
              sandbox="allow-scripts allow-same-origin allow-scripts allow-popups allow-forms allow-pointer-lock"
            /> -->
            <!-- :style="{ display: iframeLoading ? 'none' : 'block' }" -->
            <div style="height: 620px" class="tw-relative">
              <div
                class="tw-absolute tw-inset-x-0 tw-top-0 tw-flex tw-justify-center"
              >
                <div class="tw-w-56 tw-rounded-b-lg tw-bg-primary-50 tw-px-4">
                  <v-container>
                    <v-img :src="require('@/assets/logo-bizzdome.svg')" contain>
                      <template #placeholder>
                        <v-row
                          class="fill-height ma-0 transparent"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            size="32"
                            indeterminate
                            color="primary"
                          />
                        </v-row>
                      </template>
                    </v-img>
                  </v-container>
                </div>
              </div>
              <div class="tw-h-full tw-w-full tw-bg-gray-800">
                <transition name="fade-transition" mode="out-in">
                  <div
                    v-if="VideoChatStateKeys.waiting == status"
                    key="waiting"
                    class="tw-flex tw-h-full tw-items-center tw-justify-center"
                  >
                    <v-progress-circular
                      :size="42"
                      color="primary"
                      indeterminate
                    />
                    <p class="tw-ml-4 tw-mb-0 tw-text-base tw-text-white">
                      llamando...
                    </p>
                  </div>
                  <div
                    v-if="VideoChatStateKeys.ringing == status"
                    key="ringing"
                    class="tw-flex tw-h-full tw-items-center tw-justify-center"
                  >
                    <div
                      class="tw-rounded-lg tw-bg-gray-700 tw-py-10 tw-px-6 tw-text-center xl:tw-px-10 xl:tw-text-left"
                    >
                      <div class="">
                        <img
                          class="tw-mx-auto tw-h-40 tw-w-40 tw-rounded-full xl:tw-h-56 xl:tw-w-56"
                          :src="target_user.avatar"
                          alt=""
                        />
                        <div class="tw-mt-10 tw-w-full">
                          <div
                            class="tw-text-center tw-text-lg tw-font-medium tw-leading-6"
                          >
                            <h3 class="tw-text-white">
                              {{ target_user.first_name }}
                              {{ target_user.last_name }}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tw-ml-4">
                      <div>
                        <p class="tw-text-center tw-text-white">
                          Aceptar llamada
                        </p>
                      </div>
                      <div class="tw-flex tw-flex-col tw-space-y-4">
                        <v-btn
                          color="primary"
                          rounded
                          class="tw-px-12 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-white"
                          @click="acceptCall"
                        >
                          Aceptar
                        </v-btn>
                        <v-btn
                          dark
                          outlined
                          rounded
                          class="tw-px-12 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-white"
                          @click="closeModal"
                        >
                          Colgar</v-btn
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="VideoChatStateKeys.declined == status"
                    key="declined"
                    class="tw-flex tw-h-full tw-items-center tw-justify-center"
                  >
                    <p class="tw-ml-4 tw-mb-0 tw-text-base tw-text-white">
                      Rechazo la llamada
                    </p>
                  </div>
                  <div
                    v-if="VideoChatStateKeys.hung_up == status"
                    key="hung_up"
                    class="tw-flex tw-h-full tw-items-center tw-justify-center"
                  >
                    <p class="tw-ml-4 tw-mb-0 tw-text-base tw-text-white">
                      {{ target_user.first_name }}
                      {{ target_user.last_name }} finalizo la llamada
                    </p>
                  </div>
                  <div
                    v-if="VideoChatStateKeys.busy == status"
                    key="hung_up"
                    class="tw-flex tw-h-full tw-items-center tw-justify-center"
                  >
                    <p class="tw-ml-4 tw-mb-0 tw-text-base tw-text-white">
                      {{ target_user.first_name }}
                      {{ target_user.last_name }} esta en una llamada
                    </p>
                  </div>
                  <AsyncIframe
                    v-if="VideoChatStateKeys.on_going == status"
                    key="on_going"
                    ref="iframeEl"
                    :src="videoUrl"
                    frameborder="0"
                    gesture="media"
                    allow="camera *; geolocation *; microphone *; autoplay *;"
                    sandbox="allow-same-origin allow-scripts"
                    @load="onLoad"
                    @iframe-load="iframeLoad"
                  />
                </transition>
              </div>
            </div>
            <!-- frame-id="iframe"/ -->
            <!-- :height="iframe.height" -->
          </v-container>
        </v-responsive>
      </v-card-text>
      <v-divider class="tw-bg-gray-900" />
      <v-card-actions class="tw-bg-gray-900">
        <v-spacer />
        <v-btn
          dark
          text
          rounded
          class="tw-px-8 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-white"
          @click="closeModal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="tw-mr-2 tw--ml-1 tw-h-6 tw-w-6"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              d="M10.9642 13.0357C9.98758 12.1045 9.41538 11.1011 9.12819 10.494C8.96724 10.1537 9.06042 9.76091 9.32658 9.49474L10.9642 7.85714L8.89275 4.75H6.0954C5.32983 4.75 4.69612 5.32802 4.71678 6.09331C4.77336 8.18879 5.32438 12.3245 8.4999 15.5M13.2968 14.8281C13.4111 14.8897 13.517 14.938 13.6116 14.9758C13.893 15.0882 14.196 14.9825 14.4102 14.7682L16.1428 13.0357L19.2499 15.1071V17.9045C19.2499 18.6701 18.6711 19.3038 17.9059 19.2831C16.324 19.2403 13.5801 18.9157 10.9642 17.3936"
            />
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              d="M18.25 5.75L5.75 18.25"
            />
          </svg>

          Finalizar llamada
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { VideoChatStateKeys } from '@/enums/VideoChatStateKeys'
import ModalHeader from '@/components/ModalHeader'
import AsyncIframe from '@/components/AsyncIframe.vue'
import { mapState } from 'vuex'
import { signalUser } from '@/graphql/mutations/userMutations'
import { AppCoreVitalKeys } from '@/enums/AppCoreVitalKeys'
// https://v3demo.mediasoup.org/
const webrtc_iframe = 'https://meet-ws-next.bizz-dome.com/'
export default {
  components: {
    AsyncIframe,
    ModalHeader,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    uuids: {
      type: String,
      default: 'convo',
    },
  },
  data: () => ({
    VideoChatStateKeys,
    loaded: false,
    myIframe: null,
  }),
  computed: {
    videoUrl() {
      return `${webrtc_iframe}?roomId=${this.uuids}&displayName=${this.user.first_name}`
    },
    ...mapState({
      user: (state) => state.session.user,
      user_uuid: (state) => state.chat.video_chat.user_uuid,
      target_user_uuid: (state) => state.chat.video_chat.target_user_uuid,
      status: (state) => state.chat.video_chat.status,
      target_user: (state) => state.chat.video_chat.target_user,
    }),
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    status: {
      handler(newVal) {
        console.log('VIDEO CHAT: ', newVal)
        const core_vital_type = AppCoreVitalKeys.EventVideoChat
        this.$store.dispatch('general/recordCoreVitalV2', {
          payload: {
            core_vital_type,
            simple_log: newVal,
            second_tracker: this.uuids,
          },
          core_vital_type,
        })
      },
      immediate: true,
    },
  },
  mounted() {
    console.log()
    // setTimeout(() => {
    this.loaded = true
    // console.log('log')
    // if (this.$refs.iframe) {
    //   this.$refs.iframe.style.height = '600px'
    //   this.$refs.iframe.style.opacity = '1'
    //   this.$refs.iframe.parentElement.style.height = 'auto'
    // } else {
    //   console.log(this.$refs.iframe_srcdoc.contentWindow.bodyHeight)
    //   this.$refs.iframe_srcdoc.style.height =
    //     this.$refs.iframe_srcdoc.contentWindow.bodyHeight + 'px'
    //   this.$refs.iframe_srcdoc.style.opacity = '1'
    //   this.$refs.iframe_srcdoc.parentElement.style.height = 'auto'
    // }
    // }, 1000)
  },
  beforeDestroy() {},
  methods: {
    acceptCall() {
      this.$store.dispatch('chat/answerCall').then(() => {
        this.$apollo.mutate({
          mutation: signalUser,
          variables: {
            signal_user: {
              status: this.status,
              user_uuid: this.user_uuid,
              target_user_uuid: this.target_user_uuid,
            },
          },
        })
      })
    },
    iframeLoad(frame) {
      console.log('iframeLoad')
      console.log(frame)
      // this.myIframe = frame.contentWindow
    },
    onLoad(frame) {
      console.log('frame on load')
      console.log(frame)
      console.log(frame.contentWindow.document)
      // this.myIframe = frame.contentWindow
    },
    closeModal() {
      if (this.status == this.VideoChatStateKeys.busy) {
        this.$store.dispatch('chat/closeCall')
        return
      }
      this.$apollo
        .mutate({
          mutation: signalUser,
          variables: {
            signal_user: {
              status: VideoChatStateKeys.declined,
              user_uuid: this.user_uuid,
              target_user_uuid: this.target_user_uuid,
            },
          },
        })
        .then(() => {
          this.$store.dispatch('chat/closeCall')
        })
      // this.open = false
    },
  },
}
</script>

<style></style>
