<template>
  <div>
    <!-- <pre>
      {{formSubmission}}
    </pre> -->
    <div class="bz-credential-box" :style="stylesCard">
      <img v-if="config.url_background" :src="config.url_background" />
      <div class="bz-credential-data">
        <!-- Vertical -->
        <!-- {{profile}} -->
        <div v-if="config.orientation == 1" class="bz-box">
          <img v-if="config.url_logo && config.logo_size > 0" :src="config.url_logo" :style="stylesLogo" class="tw-mb-2"/>
          <span v-if="userForm.fullname__is_active" class="bz-name" :style="stylesName">{{ fullname }}</span>
          <span v-if="userForm.category__is_active" class="bz-category" :style="stylesCategory">{{ formSubmission }}</span>
          <span v-if="userForm.company__is_active" class="bz-company" :style="stylesCompany">{{ profile.company }}</span>
          <div v-if="config.qr_size != 0" class="bz-qr">
            <QRCanvas :options="options"/>
          </div>
          <span v-if="userForm.email__is_active" class="bz-email" :style="stylesEmail">{{ checkinUser.email }}</span>
        </div>
        <!-- Horizontal -->
        <div v-if="config.orientation == 2" class="bz-box">
          <span v-if="userForm.fullname__is_active" class="bz-name" :style="stylesName">{{ fullname }}</span>
          <span v-if="userForm.category__is_active" class="bz-category" :style="stylesCategory">{{ formSubmission }}</span>
          <span v-if="userForm.company__is_active" class="bz-company" :style="stylesCompany">{{ profile.company }}</span>
          <div class="tw-w-full tw-flex tw-items-center tw-justify-evenly pa-1">
            <div v-if="config.qr_size != 0" class="bz-qr">
              <QRCanvas :options="options"/>
            </div>
            <img v-if="config.url_logo" :src="config.url_logo" :style="stylesLogo"/>
          </div>
          <span v-if="userForm.email__is_active" class="bz-email" :style="stylesEmail">{{ checkinUser.email }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { QRCanvas } from 'qrcanvas-vue'

export default {
  name: 'CredentialCard',
  components: {
    QRCanvas,
  },
  props: {
    checkinUser: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type:  [Array, Object],
      default: () => ({}),
    },
    registry: {
      type: Object,
      default: () => ({}),
    },
    registryForm: {
      type: Object,
      default: () => ({}),
    },
    profile: {
      type: Object,
      default: () => ({company:'Nombre de la compañia'}),
    },
    qrlink: {
      type: String,
      default: '',
    },
    userForm: {
      type:  [Array, Object],
      default: () => ({}),
    },
  },
  data: () => ({
  }),
  computed: {
    ...mapState({
      qrlinkGlobal: (state) => state.event.constants.qrlink,
      domain: (state) => state.constants.data.domain,
    }),
    options() {
      return {
        resize: true,
        data: this.qrlinkEvent,
        padding: 2,
        cellSize: this.config.qr_size,
      }
    },
    fullname() {
      return `${this.checkinUser.first_name} ${this.checkinUser.last_name}`
    },
    qrlinkEvent() {
      if (this.qrlink) {
        return this.qrlink
      }
      if (this.qrlinkGlobal) {
        return this.qrlinkGlobal
      }
      if (this.registryForm) {
        return this.domain+'/registry/'+this.registry.slug+'/magic/'+this.registryForm.uuid+'/'+this.checkinUser.uuid
      }
      return 'http://localhost:8080/registry/kmjk/magic/0000000000000000000000/00000000000000000000000'
    },
    formSubmission() {
      if (this.profile.registry_form_submission) {
        const category = this.profile.registry_form_submission.find((row) => row.name == 'category')
        return category ? category.model : ''
      } else {
        return 'Category'
      }
    },
    stylesCard() {
      return {
        background: `${this.config.ui_background}`,
        width: `${this.config.width}px`,
        height: `${this.config.height}px`,
      }
    },
    stylesLogo() {
      return {
        width: `${this.config.logo_size}px`,
        height: `${this.config.logo_size}px`,
      }
    },
    stylesCategory() {
      return  {
        'font-size': `${this.userForm.category__fontsize}px`,
        color: `${this.userForm.category__color}`,
      }
    },
    stylesName() {
      return  {
        'font-size': `${this.userForm.fullname__fontsize}px`,
        color: `${this.userForm.fullname__color}`,
      }
    },
    stylesCompany() {
      return  {
        'font-size': `${this.userForm.company__fontsize}px`,
        color: `${this.userForm.company__color}`,
      }
    },
    stylesEmail() {
      return  {
        'font-size': `${this.userForm.email__fontsize}px`,
        color: `${this.userForm.email__color}`,
      }
    },
  },
}
</script>

<style lang="scss" media="print">
// @page {
//   size: 400px 400px;
//   margin: 0 !important;
//   padding: 0 !important;
// }
// @media print {
//   body * {
//     visibility: hidden;
//   }
//   #credentialPrint,
//   #credentialPrint * {
//     visibility: visible;
//     border: none;
//     border-radius: 0;
//     -webkit-print-color-adjust: exact !important;
//   }
// }
.bz-credential-box {
  margin: auto;
  position: relative;
  border-radius: 0rem;
  overflow: hidden;
}
.bz-credential-data {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
}
.bz-box {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 0.5rem;
  text-align: center;
  .bz-category {
    text-transform: capitalize;
  }
  .bz-name {
    font-weight: bold;
    text-transform: uppercase;
  }
  .bz-email {
    // font-weight: bold;
    margin-top: 0.5rem;
  }
  .bz-company {
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  .bz-qr {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
