<template>
    <div>
        <v-form
            ref="form_forgot_password"
            v-model="valid"
            aria-autocomplete="off"
            @submit.prevent="submitVerification"
        >
            <!-- ------------- Message ------------- -->
            <p class="tw-text-sm tw-text-gray-400 tw-px-4">{{ $t('app.text_forgot_password') }}</p>
            <!-- ------------- Email ------------- -->
            <v-text-field 
                ref="email_verification" 
                v-model="email_verification" 
                append-icon="alternate_email"
                name="email_verification"
                :label="$t('forms.email')"
                type="email"
                outlined
                validate-on-blur
                :rules="[rules.required, rules.email]"
                required
                rounded
                hide-details="auto"
                color="primary"
                class="tw-text-sm"
            />
            <!-- ------------- Button Sign-In ------------- -->
            <v-btn 
                :disabled="loading_forgot" 
                :loading="loading_forgot" 
                color="primary" 
                type="submit" 
                rounded 
                block
                class="tw-py-6 tw-mt-6 tw-text-sm tw-font-semibold"
            >
              {{ $t('app.send_link_reset') }}
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { rules } from '@/utils/rules'
export default {
    data: () => ({
        rules,
        valid: true,
        email_verification: '',
        loading_forgot: false,
        code: '',
    }),
    computed: {
        ...mapState({
            current_language_key: (state) => state.registry.show.current_language_key,
            registry: (state) => state.registry.show.data,
            roomLink: (state) => state.registry.toPath.link,
        }),
        registry_form() {
            return this.registry.forms.find(
                (row) => row.language.name === this.current_language_key,
            )
        },
    },
    methods: {
        submitVerification() {
            // Validate
            if (!this.$refs.form_forgot_password.validate()) {
                return
            }
            this.loading_forgot = true
            // Generate Code
            this.codification(6)
            // Send Data
            this.$store.dispatch('password/forgotPassword', {
                email: this.email_verification,
                registry_form_id: this.registry_form.id,
                lang: this.current_language_key,
                verification: btoa(this.code),
            }).then((res) => {
                if (res.data) {
                    this.loading_forgot = false
                    this.$emit('alert-snackbar',{status:true,color:'success',msg:res.data.status})
                    this.$emit('data-form',{code:this.code,token:res.data.token,email:res.data.email})
                    this.$emit('page-form',{f1:false,f2:false,f3:true,f4:false})
                    return
                }
                if (res.response.status) {
                    if (res.response.status == 422) {
                        this.loading_forgot = false
                        this.$emit('alert-snackbar',{status:true,color:'red',msg:res.response.data.errors.email[0]})
                        return
                    }
                }
            })
        },
        codification(length) {
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
            var pass = ''
            for (let i = 0; i < length; i++) {
                pass += characters.charAt(Math.floor(Math.random()*characters.length))
            }
            this.code = pass
        },
    },
}
</script>

<style>
</style>