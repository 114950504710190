import $axios from '@/axios-instance.js'

export default class EventConfigsNavService {
  baseUrl = '/api/v1/admin/event-configs'
  relationshipDomain = 'nav'
  // Create
  store(event_config, params) {
    return $axios.post(
      `${this.baseUrl}/${event_config}/${this.relationshipDomain}`,
      params,
    )
  }

  // Read all - findAll
  // index(params) {
  //   return $axios.get(this.baseUrl, params);
  // }

  // Read one - show
  // show(id, params) {
  //   return $axios.get(this.baseUrl + `/${id}`, params);
  // }

  // // Update
  // update (id, params) {
  //   return $axios.put(this.baseUrl + `/${id}`, params)
  // }

  // // Delete - delete
  // destroy (id,params) {
  //   return $axios.delete(this.baseUrl + `/${id}`, params)
  // }
}
export const EventConfigsNavServiceInstance = new EventConfigsNavService()
