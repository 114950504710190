export const ModTabKeys = {
  attendees: 'attendees',
  register: 'register',
  aforo: 'aforo',
}

export const ModCustomFormTabKeys = {
  attendees: 'attendees',
  register: 'register',
}
