import gql from 'graphql-tag'

// profiles [forms{}]
export const CORE_ATTENDEE_FIELDS = gql`
  fragment CoreAttendeeFields on Attendee {
    id
    address
    city
    company
    title
    additionals
    country_code
    phone_number
    registry_form_id
    registry_form_submission {
      label
      model
      model_multiple
      name
      uid
      options {
        text
        value
        uid
      }
    }
  }
`
