<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="1280px"
    content-class="tw-rounded-3xl tw-w-full"
  >
    <v-form
      ref="form"
      v-model="valid"
      :disabled="is_loading"
      lazy-validation
      enctype="multipart/form-data"
      @submit.prevent="create"
    >
      <v-card class="tw-rounded-3xl tw-border-0 tw-shadow-md">
        <ModalHeader>
          {{ $t('app.event') }}
          <template #right>
            <div class="tw-flex tw-items-center tw-space-x-3">
              <CommonBadge> {{ langKey }} </CommonBadge>
              <v-fab-transition>
                <v-btn
                  fab
                  x-small
                  class="focus:tw-outline-none focus:tw-ring"
                  @click="closeModal"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-fab-transition>
            </div>
          </template>
        </ModalHeader>
        <v-divider />
        <v-card-text class="tw-p-0">
          <v-responsive class="tw-py-0">
            <v-container class="tw-bg-gray-50" fluid>
              <v-row class="mb-5">
                <v-col cols="12" lg="6" class="mx-auto pa-5">
                  <v-card
                    class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-shadow-md"
                    ><v-container class="pa-5">
                      <div class="tw-grid tw-gap-4">
                        <v-text-field
                          v-model="form.label"
                          :rules="[rules.required]"
                          validate-on-blur
                          type="text"
                          rounded
                          color="primary darken-1"
                          :label="$t('app.title')"
                          outlined
                        />
                        <v-textarea
                          v-model="form.description"
                          :rules="[rules.required]"
                          validate-on-blur
                          type="text"
                          rounded
                          auto-grow
                          color="primary darken-1"
                          :label="$t('app.description')"
                          outlined
                        />
                      </div>
                    </v-container>
                    <v-container class="pa-5">
                      <h3
                        class="tw-text-base tw-font-medium tw-leading-6 tw-text-gray-700"
                      >
                        {{ $t('admin.enums.image_item') }}
                      </h3>
                      <DropzoneOverlayPreview
                        v-model="form.image_url"
                        :bypass="'no-check'"
                        media-group="content-library-download-file"
                      />
                    </v-container>
                  </v-card>
                </v-col>
                <v-col cols="12" lg="6" class="mx-auto pa-5">
                  <v-card
                    class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-shadow-md"
                  >
                    <v-container class="pa-5">
                      <v-row>
                        <v-col cols="12" sm="12" class="tw-py-0">
                          <label class="tw-block tw-pb-2 tw-text-gray-600">
                            {{ $t('app.start_time') }}
                          </label>
                          <div class="tw-grid tw-grid-cols-2 tw-gap-4">
                            <div>
                              <DateInput
                                v-model="form.start_date"
                                :date-min="''"
                              />
                            </div>
                            <div>
                              <v-text-field
                                v-model="form.start_time"
                                :label="$t('app.hour')"
                                :rules="[rules.required]"
                                outlined
                                rounded
                                type="time"
                              />
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="12" class="tw-py-0">
                          <label class="tw-block tw-pb-2 tw-text-gray-600">
                            {{ $t('app.end_time') }}
                          </label>
                          <div class="tw-grid tw-grid-cols-2 tw-gap-4">
                            <div>
                              <DateInput
                                v-model="form.end_date"
                                :date-min="form.start_date"
                              />
                            </div>
                            <div>
                              <v-text-field
                                v-model="form.end_time"
                                :label="$t('app.hour')"
                                :rules="[rules.required]"
                                outlined
                                rounded
                                type="time"
                              />
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" class="pb-0">
                          <TimezonesAutocomplete
                            v-model="form.time_bracket_tz"
                            :rules="[rules.required]"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                    <ClickableModelFormPartial v-model="form.clickable" />
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-responsive>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-alert
            v-model="errorAlert"
            dismissible
            color="red"
            border="left"
            elevation="2"
            colored-border
            icon="error"
            transition="fade-transition"
          >
            {{ errorMsg }}
          </v-alert>
          <v-spacer />
          <AppBtn
            type="button"
            color="transparent"
            :disabled="is_loading"
            @click="closeModal"
          >
            {{ $t('app.cancel') }}
          </AppBtn>
          <AppBtn type="submit" :loading="is_loading" color="primary">
            {{ $t('app.save') }}
          </AppBtn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import AppBtn from '@/components/App/AppBtn.vue'
import ModalHeader from '@/components/ModalHeader'
import { rules } from '@/utils/rules'
import DropzoneOverlayPreview from '../DropzoneOverlayPreview.vue'
import { mapState } from 'vuex'
import CommonBadge from '../CommonBadge.vue'
import { CalendarItemBase } from '@/models/Calendar'
import { simpleObjectCopy } from '@/utils/simpleObjectCopy'
import TimezonesAutocomplete from '../TimezonesAutocomplete.vue'
import DateInput from '../FormInputs/DateInput.vue'
import ClickableModelFormPartial from '../FormPartials/ClickableModelFormPartial.vue'

export default {
  components: {
    ModalHeader,
    AppBtn,
    DropzoneOverlayPreview,
    CommonBadge,
    TimezonesAutocomplete,
    DateInput,
    ClickableModelFormPartial,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    eventItemId: {
      type: [Number, String],
      default: '',
    },
    calendarId: {
      type: [Number, String],
      default: '',
    },
    langKey: {
      type: String,
      default: '',
    },
    eventItem: {
      type: Object,
      default: () => CalendarItemBase({}),
    },
  },
  data: () => ({
    form: CalendarItemBase({}),
    rules,
    errorAlert: false,
    errorMsg: '',
    is_loading: false,
    valid: true,
  }),

  computed: {
    ...mapState({
      registry: (state) => state.registries.show.data,
    }),
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    open: {
      handler(newVal) {
        if (newVal) {
          this.init()
        }
      },
      immediate: true,
    },
  },
  created() {
    console.log('hi')
  },
  methods: {
    init() {
      this.form = simpleObjectCopy(this.eventItem)
    },
    closeModal() {
      this.open = false
    },
    create() {
      this.errorAlert = false
      if (!this.$refs.form.validate()) {
        return
      }
      this.$store
        .dispatch('registries/storeCalendarsItems', {
          payload: this.form,
          calendar: this.calendarId,
        })
        .then((data) => {
          // this.$store.dispatch('registries/show', {
          //   id: this.registry.id,
          //   appLoading: true,
          // }),
          this.$emit('submit-success', data)
        })
        .then(() => {
          this.closeModal()
        })
      // this.is_loading = true
    },
  },
}
</script>

<style></style>
