import { findCommonElements3 } from '@/helpers'

export const formErrorsHandler = (error, form = false) => {
  let errorMessage = ''
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    if (!process.env.NODE_ENV === 'production') {
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
    }
    if (form) {
      return error.response.data.errors
    }
    errorMessage = error.response.status
    if (error.response.data.errors) {
      for (const item in error.response.data.errors) {
        errorMessage += ` | ${error.response.data.errors[item][0]}`
        break
      }
    } else {
      errorMessage += '| ' + error.response.data.message
    }
    return errorMessage
  }
  if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */

    if (!process.env.NODE_ENV === 'production') {
      console.log('request: ', error.request)
    }
    errorMessage = error.message
    return errorMessage
  }
  // Something happened in setting up the request and triggered an Error
  if (!process.env.NODE_ENV === 'production') {
    console.log('message: ', error.message)
  }
  errorMessage = error.message
  console.log('config: ', error.config)
  return errorMessage
}
export const multiStepErrorCheck = (obj, errors) => {
  return Object.keys(obj)
    .map((key) => {
      return {
        [key]: findCommonElements3(Object.keys(errors), Object.keys(obj[key])),
      }
    })
    .reduce((result, item) => {
      const key = Object.keys(item)[0]
      result[key] = item[key]
      return result
    }, {})
}
export const basicErrors = (error) => {
  let errorMessage = ''
  if (error.errors) {
    for (const item in error.errors) {
      errorMessage += ` ${error.errors[item][0]}`
      break
    }
  } else {
    errorMessage += '| ' + error.message
  }
  return errorMessage
}
export default {
  formErrorsHandler,
}
