import $axios from '@/axios-instance.js'
export default class AuthService {
  baseUrl = '/api/v1/auth'

  // authenticate user static
  loginAction(params) {
    return $axios.post(this.baseUrl + '/login', params)
  }
  // create a new user
  registerAction(params) {
    return $axios.post(this.baseUrl + '/register', params)
  }
  // refresh user data and verify if token is still valid
  refreshAction(params) {
    return $axios.get(this.baseUrl + '/refresh-user', params)
  }

  // logout
  logoutAction(params) {
    return $axios.post(this.baseUrl + '/logout', params)
  }

  verifyUserRegistry(registry_uuid, payload, params) {
    return $axios.post(`${this.baseUrl}/user/${registry_uuid}`, payload, params)
  }
  loginViaEventVerifiedRegistry(registry_uuid, data, params) {
    return $axios.post(
      `${this.baseUrl}/user/${registry_uuid}/login`,
      data,
      params,
    )
  }
  registerViaEventVerifiedRegistry(registry_uuid, form_uuid, data, params) {
    return $axios.post(
      `${this.baseUrl}/user/${registry_uuid}/register/${form_uuid}`,
      data,
      params,
    )
  }

  loginViaEventAction(uuid, params) {
    return $axios.post(`${this.baseUrl}/${uuid}/login`, params)
  }
  registerEvent(uuid, params) {
    return $axios.post(`${this.baseUrl}/${uuid}/register`, params)
  }
  paymentEvent(params) {
    return $axios.post(`${this.baseUrl}/payment-event`, params)
  }
}
export const AuthServiceInstance = new AuthService()
// import $axios from "@/axios-instance.js";
// const baseUrl = "/api/v1/auth";

// // authenticate user
// export const loginAction = params => {
//   return $axios.post(baseUrl + "/login", params);
// };
// // create a new user
// export const registerAction = params => {
//   return $axios.post(baseUrl + "/register", params);
// };
// // refresh user data and verify if token is still valid
// export const refreshAction = params => {
//   return $axios.get(baseUrl + `/user`, params);
// };

// // logout
// export const logoutAction = params => {
//   return $axios.delete(baseUrl + "/logout", params);
// };
