<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="1280px"
    content-class="tw-rounded-3xl"
  >
    <v-form
      ref="form"
      v-model="valid"
      :disabled="form_disabled_state"
      lazy-validation
      enctype="multipart/form-data"
      @submit.prevent="onSubmit"
    >
      <v-card class="tw-relative tw-rounded-3xl tw-border-0 tw-shadow-md">
        <ModalHeader @close-modal="closeModal">{{
          $t('admin.app.email_editor')
        }}</ModalHeader>
        <v-divider />
        <v-card-text class="tw-p-0" style="max-height: 700px">
          <v-responsive class="tw-py-0">
            <v-container class="tw-bg-gray-100" fluid>
              <v-row class="mb-5">
                <v-col cols="8" class="px-5 pt-5 mx-auto">
                  <v-card
                    class="
                      mx-auto
                      tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-shadow-md
                    "
                  >
                    <v-container class="pa-5">
                      <div class="tw-grid tw-grid-cols-1 tw-gap-1">
                        <div v-if="hasMounted" class="">
                          <EditorTipTap
                            v-model="form.email.body"
                            label="Email body"
                            :description="
                              $t('admin.app.email_editor_description')
                            "
                          />
                        </div>
                        <div class="tw-mt-3 tw-flex tw-justify-between">
                          <div>
                            <p class="tw-mb-0 tw-max-w-xs">
                              {{ $t('admin.app.email_warning') }}
                            </p>
                          </div>
                          <div class="tw-flex tw-items-center tw-space-x-3">
                            <AppBtn
                              color="transparent"
                              :loading="sending"
                              type="button"
                              @click="dispatchEmail"
                            >
                              {{ $t('app.send') }}
                            </AppBtn>
                            <AppBtn
                              color="white"
                              type="button"
                              icon="g-next"
                              g-next="Eye"
                              :loading="previewLoading"
                              @click="previewEventEmail"
                            >
                              Preview
                            </AppBtn>
                          </div>
                        </div>
                      </div>
                    </v-container>
                    <v-container class="pa-5">
                      <div class="tw-flex tw-justify-between">
                        <div>
                          <h3
                            class="
                              tw-text-lg
                              tw-font-medium
                              tw-leading-6
                              tw-text-gray-900
                            "
                          >
                            {{ $t('app.custom_fields') }}
                          </h3>
                          <p
                            class="tw-mt-1 tw-mb-0 tw-text-sm tw-text-gray-500"
                          >
                            {{ $t('admin.app.fields_email') }}
                          </p>
                        </div>
                        <div>
                          <AppBtn
                            color="white"
                            type="button"
                            icon-right="g-next"
                            :g-next="toggleFields ? 'ChevUp' : 'ChevDown'"
                            @click="toggleFields = !toggleFields"
                          >
                            {{ toggleFields ? $t('app.hide') : $t('app.show') }}
                            {{ $t('app.fields') }}
                          </AppBtn>
                        </div>
                      </div>
                      <dl
                        v-if="toggleFields"
                        class="
                          tw-mt-6 tw-grid tw-grid-cols-1 tw-gap-x-4 tw-gap-y-8
                          sm:tw-grid-cols-3
                        "
                      >
                        <!-- QR Generado -->
                        <div v-if="show.is_hybrid" class="sm:tw-col-span-1">
                          <dt class="tw-text-sm tw-font-medium tw-text-gray-500">
                            {{ $t('bz2.email.qr') }}
                          </dt>
                          <dd class="tw-mt-1 tw-text-sm tw-text-gray-900">:qr-tag</dd>
                        </div>
                        <!-- Boton Generado con credencial de usuario para acceso directo a la plataforma -->
                        <div class="sm:tw-col-span-1">
                          <dt class="tw-text-sm tw-font-medium tw-text-gray-500">
                            {{ $t('bz2.email.btn_link') }}
                          </dt>
                          <dd class="tw-mt-1 tw-text-sm tw-text-gray-900">:btn-access</dd>
                        </div>
                        <div
                          v-for="form_Field in formFields"
                          :key="form_Field.name"
                          class="sm:tw-col-span-1"
                        >
                          <dt
                            class="tw-text-sm tw-font-medium tw-text-gray-500"
                          >
                            {{ form_Field.label }}
                          </dt>
                          <dd class="tw-mt-1 tw-text-sm tw-text-gray-900">
                            {{ form_Field.name }}
                          </dd>
                        </div>
                      </dl>
                    </v-container>
                    <v-container class="pa-5">
                      <div>
                        <div>
                          <h3
                            class="
                              tw-text-lg
                              tw-font-medium
                              tw-leading-6
                              tw-text-gray-900
                            "
                          >
                            {{ $t('admin.app.headers') }}
                          </h3>
                          <p
                            class="tw-mt-1 tw-mb-0 tw-text-sm tw-text-gray-500"
                          >
                            {{ $t('admin.app.headers_description') }}
                          </p>
                        </div>
                        <ul
                          class="tw-mt-2 tw-divide-y tw-divide-gray-200 tw-pl-0"
                        >
                          <li class="tw-py-4">
                            <div>
                              <div
                                class="
                                  tw-flex tw-items-center tw-justify-between
                                "
                              >
                                <div class="tw-flex tw-flex-col">
                                  <p
                                    id="privacy-option-1-label"
                                    class="
                                      tw-mb-0
                                      tw-text-sm
                                      tw-font-medium
                                      tw-text-gray-900
                                    "
                                  >
                                    {{ $t('admin.app.header_image') }}
                                  </p>
                                  <p
                                    id="privacy-option-1-description"
                                    class="tw-mb-0 tw-text-sm tw-text-gray-500"
                                  >
                                    {{
                                      $t('admin.app.header_image_description')
                                    }}
                                  </p>
                                </div>
                                <div
                                  class="
                                    tw-mt-5
                                    sm:tw-mt-0
                                    sm:tw-ml-6
                                    sm:tw-flex
                                    sm:tw-flex-shrink-0
                                    sm:tw-items-center
                                  "
                                >
                                  <v-switch
                                    v-model="form.email.has_header_img"
                                    inset
                                    large
                                  />
                                </div>
                              </div>
                              <div
                                v-if="form.email.has_header_img"
                                class="tw-mt-3"
                              >
                                <div class="tw-grid tw-grid-cols-1 tw-gap-4">
                                  <dropzone-overlay-preview
                                    v-model="form.email.header_img"
                                    :bypass="'no-check'"
                                    :width="600"
                                    :height="300"
                                    media-group="registries_emails"
                                  />
                                  <div>
                                    <v-text-field
                                      v-model="form.email.header_img_click_url"
                                      :rules="[rules.required]"
                                      validate-on-blur
                                      outlined
                                      rounded
                                      label="Url"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="tw-py-4">
                            <div>
                              <div
                                class="
                                  tw-flex tw-items-center tw-justify-between
                                "
                              >
                                <div class="tw-flex tw-flex-col">
                                  <p
                                    class="
                                      tw-mb-0
                                      tw-text-sm
                                      tw-font-medium
                                      tw-text-gray-900
                                    "
                                  >
                                    {{ $t('admin.app.footer_image') }}
                                  </p>
                                  <p
                                    class="tw-mb-0 tw-text-sm tw-text-gray-500"
                                  >
                                    {{
                                      $t('admin.app.footer_image_description')
                                    }}
                                  </p>
                                </div>
                                <div
                                  class="
                                    tw-mt-5
                                    sm:tw-mt-0
                                    sm:tw-ml-6
                                    sm:tw-flex
                                    sm:tw-flex-shrink-0
                                    sm:tw-items-center
                                  "
                                >
                                  <!-- Enabled: "tw-bg-indigo-600", Not Enabled: "tw-bg-gray-200" -->
                                  <v-switch
                                    v-model="form.email.has_footer_img"
                                    inset
                                    large
                                  />
                                </div>
                              </div>
                              <div
                                v-if="form.email.has_footer_img"
                                class="tw-mt-3"
                              >
                                <div class="tw-grid tw-grid-cols-1 tw-gap-4">
                                  <dropzone-overlay-preview
                                    v-model="form.email.footer_img"
                                    :bypass="'no-check'"
                                    :width="600"
                                    :height="300"
                                    media-group="registries_emails"
                                  />
                                  <div>
                                    <v-text-field
                                      v-model="form.email.footer_img_click_url"
                                      :rules="[rules.required]"
                                      validate-on-blur
                                      outlined
                                      rounded
                                      label="Url"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </v-container>
                  </v-card>
                </v-col>
                <v-col cols="4" class="px-5 pt-5 mx-auto">
                  <v-card
                    class="
                      mx-auto
                      tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-shadow-md
                    "
                  >
                    <v-container class="pa-5">
                      <v-row>
                        <v-col cols="12" sm="12" class="pb-0">
                          <v-select
                            v-model="form.registry_email_type"
                            :items="validTypes"
                            :label="$t('app.type')"
                            validate-on-blur
                            :rules="[rules.required]"
                            outlined
                            rounded
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container class="pa-5">
                      <!-- <div class="tw-px-4 sm:tw-px-6"> -->
                      <div>
                        <div>
                          <h3
                            class="
                              tw-text-lg
                              tw-font-medium
                              tw-leading-6
                              tw-text-gray-900
                            "
                          >
                            {{ $t('admin.app.extra_config') }}
                          </h3>
                          <p
                            class="tw-mt-1 tw-mb-0 tw-text-sm tw-text-gray-500"
                          >
                            {{ $t('admin.app.extra_config_description') }}
                          </p>
                        </div>
                        <ul
                          class="tw-mt-2 tw-divide-y tw-divide-gray-200 tw-pl-0"
                        >
                          <li
                            class="
                              tw-flex tw-items-center tw-justify-between tw-py-4
                            "
                          >
                            <v-text-field
                              v-model="form.email.subject"
                              :rules="[rules.required]"
                              validate-on-blur
                              outlined
                              rounded
                              :label="$t('admin.app.subject')"
                            />
                          </li>
                          <li
                            class="
                              tw-flex tw-items-center tw-justify-between tw-py-4
                            "
                          >
                            <v-text-field
                              v-model="form.email.sender_name"
                              validate-on-blur
                              outlined
                              rounded
                              :label="$t('admin.app.sender_name')"
                            />
                          </li>
                          <li
                            class="
                              tw-flex tw-items-center tw-justify-between tw-py-4
                            "
                          >
                            <div class="tw-flex tw-flex-col">
                              <p
                                id="privacy-option-1-label"
                                class="
                                  tw-mb-0
                                  tw-text-sm
                                  tw-font-medium
                                  tw-text-gray-900
                                "
                              >
                                {{ $t('app.active') }}
                              </p>
                              <p
                                id="privacy-option-1-description"
                                class="tw-mb-0 tw-text-sm tw-text-gray-500"
                              >
                                {{ $t('admin.app.enable_email') }}
                              </p>
                            </div>

                            <div
                              class="
                                tw-mt-5
                                sm:tw-mt-0
                                sm:tw-ml-6
                                sm:tw-flex
                                sm:tw-flex-shrink-0
                                sm:tw-items-center
                              "
                            >
                              <v-switch v-model="form.is_active" inset large />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-responsive>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-alert
            v-if="errorAlert"
            v-model="errorAlert"
            dismissible
            color="red"
            border="left"
            elevation="2"
            colored-border
            icon="error"
            transition="fade-transition"
          >
            {{ errorMsg }}
          </v-alert>
          <v-spacer />
          <AppBtn
            color="transparent"
            type="button"
            :disabled="form_loading"
            @click="closeModal"
          >
            {{ $t('app.cancel') }}
          </AppBtn>
          <AppBtn
            color="primary"
            :loading="form_loading"
            type="submit"
            icon="check"
          >
            {{ $t('app.save') }}
          </AppBtn>
        </v-card-actions>
      </v-card>
    </v-form>
    <FlexibleIframeModal v-model="showPreview" :iframe-html="iframeHtml" />
  </v-dialog>
</template>

<script>
import DropzoneOverlayPreview from '@/components/DropzoneOverlayPreview.vue'
import FlexibleIframeModal from '@/components/FlexibleIframeModal.vue'
import EditorTipTap from '@/components/FormInputs/EditorTipTap.vue'
import TurndownService from 'turndown'
import { Marked } from '@ts-stack/markdown'

import { rules } from '@/utils/rules'
import ModalHeader from '@/components/ModalHeader.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import { RegistryEmailBase } from '@/models/RegistryEmail'
import { simpleObjectCopy } from '@/utils/simpleObjectCopy'
import { mapState } from 'vuex'
export default {
  components: {
    AppBtn,
    EditorTipTap,
    ModalHeader,
    DropzoneOverlayPreview,
    FlexibleIframeModal,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: String,
      default: '',
    },
    validTypes: {
      type: Array,
      default: () => [],
    },
    registryEmail: {
      type: Object,
      default: () => RegistryEmailBase({}),
    },
  },
  data: () => ({
    errorAlert: '',
    errorMsg: '',
    sending: false,
    hasMounted: false,
    rules,
    toggleFields: false,
    form_loading: false,
    showPreview: false,
    previewLoading: false,
    iframeHtml: '',
    form_disabled_state: false,
    valid: true,
    form: RegistryEmailBase({}),
  }),
  computed: {
    formFields() {
      // fields - defauilt  fields -> first_name last_name
      return [
        { name: 'first_name', label: 'Nombre' },
        { name: 'last_name', label: 'Apellido' },
        ...this.currentForm.default_fields
          .filter(({ is_active }) => is_active)
          .map(({ name, label }) => ({
            name,
            label,
          })),
        ...this.currentForm.fields
          .filter(({ is_active }) => is_active)
          .map(({ name, label }) => ({
            name,
            label,
          })),
      ].map(({ name, label }) => ({
        name: `:${name.replace('_', '-')}`,
        label,
      }))
    },
    currentForm() {
      return this.forms.find((row) => row.language.name === this.lang)
    },
    ...mapState({
      forms: (state) => state.registries.show.data.forms,
      show: (state) => state.registries.show.data,
    }),
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  beforeMount() {
    console.log('registryEmail ', this.registryEmail)
    this.form = simpleObjectCopy(this.registryEmail)
    if (this.form.email.body) {
      this.form.email.body = Marked.parse(this.form.email.body)
    } else {
      this.form.email.body = ''
    }
    this.hasMounted = true
  },

  methods: {
    previewEventEmail() {
      // this.form_loading = true
      this.form_disabled_state = true
      this.previewLoading = true

      this.$store
        .dispatch('emails/previewEventEmail', {
          body: {
            ...this.form.email,
            preview_registry_form_id: this.currentForm.id,
          },
          // form_id: this.currentForm.id,
        })
        .then((res) => {
          this.iframeHtml = res.data
          this.showPreview = true
        })
        .finally(() => {
          this.form_disabled_state = false
          this.previewLoading = false
          // this.form_loading = false
        })
    },
    dispatchEmail() {
      // this.form_loading = true
      this.form_disabled_state = true
      this.sending = true
      this.$store
        .dispatch('emails/dispatchEmail', {
          body: {
            ...this.form.email,
            preview_registry_form_id: this.currentForm.id,
          },
        })
        .then((res) => {
          // this.iframeHtml = res.data
          // this.showPreview = true
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.sending = false
          this.form_disabled_state = false
          // this.form_loading = false
        })
    },
    toMarkdown() {
      let turndownService = new TurndownService({
        emDelimiter: '_',
        linkStyle: 'inlined',
        headingStyle: 'atx',
      })
      console.log(this.form.email.body)
      let markdown = turndownService.turndown(this.form.email.body)
      console.log(markdown)
      return markdown
    },
    closeModal() {
      this.open = false
    },
    onSubmit() {
      this.errorAlert = false
      if (!this.$refs.form.validate()) {
        return
      }
      this.form_loading = true
      this.form_disabled_state = true

      this.$store
        .dispatch('registries/updateEmails', {
          form: {
            ...this.form,
            lang_key: this.lang,
            email: { ...this.form.email, body: this.toMarkdown() },
          },
        })
        .then((res) => {
          const { data } = res.data
          return this.$store.dispatch('registries/show', {
            id: data.id,
          })
        })
        .then((res) => {
          console.log('after show', { res })
          this.open = false
        })

        .catch((err) => {
          this.errorAlert = true
          this.errorMsg = err
        })
        .finally(() => {
          this.form_disabled_state = false
          this.form_loading = false
        })
    },
  },
}
</script>

<style></style>
