<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="1024px"
    content-class="tw-rounded-3xl tw-w-full"
  >
    <v-card class="tw-relative tw-rounded-3xl tw-border-0 tw-shadow-md">
      <ModalHeader @close-modal="closeModal"> Agenda </ModalHeader>
      <v-divider />
      <v-card-text class="tw-p-0" style="max-height: 700px">
        <v-responsive class="tw-py-0">
          <v-container class="tw-bg-gray-50" fluid>
            <v-col
              cols="12"
              sm="12"
              class="mx-auto tw-container tw-flex tw-max-w-5xl"
            >
              <v-container
                v-if="Object.keys(parsedEvents).length > 0"
                key="events_calendar"
                fluid
                class="tw-space-y-6 tw-py-6"
              >
                <v-card
                  v-for="(row, index) in parsedEvents"
                  :key="index"
                  class="tw-rounded-3xl tw-shadow-md"
                >
                  <v-container class="tw-p-0" fluid>
                    <div class="">
                      <div
                        class="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-px-4 tw-py-5 sm:tw-flex-nowrap sm:tw-px-6"
                      >
                        <div>
                          <h3
                            class="tw-text-3xl tw-font-medium tw-leading-6 tw-text-gray-900"
                          >
                            Agenda {{ index }}
                          </h3>
                        </div>
                      </div>
                      <div
                        v-for="savedEvent in row"
                        :key="savedEvent.uuid"
                        class="tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-p-0"
                      >
                        <dl class="sm:tw-divide-y sm:tw-divide-gray-200">
                          <div
                            class="tw-py-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-py-5 sm:tw-px-6"
                          >
                            <dt class="tw-space-y-1">
                              <p
                                class="tw-mb-0 tw-text-xl tw-font-medium tw-text-gray-900"
                              >
                                {{ savedEvent.label }}
                              </p>
                            </dt>
                            <dd class="tw-mt-1 sm:tw-col-span-2 sm:tw-mt-0">
                              <p
                                class="tw-mb-0 tw-text-sm tw-font-bold tw-text-gray-500"
                              >
                                {{ savedEvent.time_range }}
                              </p>
                              <p class="tw-mt-1 tw-text-sm tw-text-gray-900">
                                {{ savedEvent.description }}
                              </p>
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </v-container>
                </v-card>
              </v-container>
            </v-col>
          </v-container>
        </v-responsive>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ModalHeader from '@/components/ModalHeader'
import { mapState } from 'vuex'
import { groupBy } from '@/helpers'
import { format } from 'date-fns/esm'

export default {
  components: {
    ModalHeader,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    parsedEvents() {
      const events = this.currentCalendar
      //  this.calendars.find(
      //   (row) => row.id == this.currentCalendar.id,
      // )
      if (!events) {
        return
      }
      return groupBy(
        events.events.map((row) => ({
          ...row,
          formated_date_stamp: format(
            new Date(row.start_date_time),
            'yyyy-MM-dd',
          ),
          time_range: `${format(
            new Date(row.start_date_time),
            'hh:mm a',
          )} - ${format(new Date(row.end_date_time), 'hh:mm a')}`,
        })),
        'formated_date_stamp',
      )
    },
    currentCalendar() {
      return this.registry.calendars.find(
        (row) => row.language.name == this.current_language_key,
      )
    },
    ...mapState({
      current_language_key: (state) => state.registry.show.current_language_key,
      // current_language_key: (state) => state.registry.show.current_language_key,
      registry: (state) => state.registry.show.data,
    }),
  },
  mounted() {},
  methods: {
    closeModal() {
      // this.$store.dispatch('registries/initUpdateForm')
      this.open = false
    },
  },
}
</script>
