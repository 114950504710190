<template>
    <div class="tw-grid tw-grid-cols-1 tw-gap-2">
        <v-card class="tw-relative tw-border-0 tw-shadow-sm tw-rounded tw-p-2">
            <!-- ------------------- Options ------------------- -->
            <div class="tw-flex tw-items-center tw-justify-between">
                <div class="tw-flex tw-items-center">
                    <v-btn outlined class="mr-4 tw-capitalize" color="#9e9e9e52" @click="setToday">
                        <span class="tw-text-black tw-font-semibold">
                            {{ $t('bz2.event.book_appointment.type.today') }}
                        </span>
                    </v-btn>
                    <v-btn icon small color="grey darken-2" @click="prev">
                        <v-icon small>
                            navigate_before
                        </v-icon>
                    </v-btn>
                    <v-btn icon small color="grey darken-2" @click="next">
                        <v-icon small>
                            navigate_next
                        </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="$refs.calendar" class="tw-pl-4">
                        {{ $refs.calendar.title }}
                    </v-toolbar-title>
                </div>
                <div class="tw-flex tw-items-center tw-gap-2">
                    <!-- ------------------- Create -------------------  -->
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-btn icon color="grey darken-2" v-bind="attrs" v-on="on" @click="openCreate()">
                                <g-icon
                                    key="calendar"
                                    name="calendar"
                                    class="tw-h-7 tw-w-7"
                                />
                            </v-btn>
                        </template>
                        <span class="tw-text-xs">{{ $t('bz2.event.book_appointment.schedule') }}</span>
                    </v-tooltip>
                    <!-- ------------------- Requets -------------------  -->
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-btn icon color="grey darken-2" v-bind="attrs" v-on="on" @click="openNotifications()">
                                <v-badge
                                    overlap
                                    dot
                                    color="green"
                                    bordered
                                >
                                    <g-icon
                                    key="notifications"
                                    name="notifications"
                                    class="tw-h-5 tw-w-5"
                                    />
                                </v-badge>
                            </v-btn>
                        </template>
                        <span class="tw-text-xs">{{ $t('bz2.event.book_appointment.requests') }}</span>
                    </v-tooltip>
                    <!-- ------------------- Type [ Day, Week, Month, 4day ] ------------------- -->
                    <v-menu bottom right>
                        <template #activator="{ on, attrs }">
                            <v-btn outlined class="mr-4 tw-capitalize" color="#9e9e9e52" v-bind="attrs" v-on="on">
                                <span class="tw-text-black tw-font-semibold tw-flex tw-items-center">
                                    {{ typeToLabel[type] }}
                                    <v-icon right>
                                        arrow_drop_down
                                    </v-icon>
                                </span>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="type = 'day'">
                                <v-list-item-title class="tw-text-sm">{{ $t('bz2.event.book_appointment.type.day') }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'week'">
                                <v-list-item-title class="tw-text-sm">{{ $t('bz2.event.book_appointment.type.week') }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'month'">
                                <v-list-item-title class="tw-text-sm">{{ $t('bz2.event.book_appointment.type.month') }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = '4day'">
                                <v-list-item-title class="tw-text-sm">{{ $t('bz2.event.book_appointment.type.4day') }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </div>
        </v-card>
        <v-card class="tw-relative tw-border-0 tw-shadow-sm tw-rounded tw-p-2" height="600">
            <!-- ------------------- Loading ------------------- -->
            <div v-if="isLoading" class="bz-load">
                <v-progress-linear
                    color="primary accent-4"
                    indeterminate
                    rounded
                    height="6"
                    style="width:30%"
                />
            </div>
            <!-- ------------------- Calendar ------------------- -->
            <v-calendar 
                v-if="!isLoading"
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="calendar"
                :type="type"
                class="tw-rounded"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
            />
            <!-- ------------------- Detail ------------------- -->
            <EventCalendarLiveDetail v-model="selectedOpen" :detail="selectedEvent" :calendar-id="calendarId" @submit-delete="deleteItem"/>
        </v-card>
    </div>
</template>

<script>
import GIcon from '@/components/GIcon.vue'
import EventCalendarLiveDetail from '@/components/Event/EventCalendarLiveDetail.vue'

export default {
    name: 'EventCalendarLive',
    components: {GIcon,EventCalendarLiveDetail},
    props: {
        calendar: {
            type: [Array, Object],
            default: () => ({}),
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        calendarId: {
            type: [Array, Object],
            default: () => ({}),
        },
    },
    data: () => ({
        focus: '',
        type: 'month',
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
    }),
    computed: {
        typeToLabel() {
            return {
                month: this.$t('bz2.event.book_appointment.type.month'),
                week: this.$t('bz2.event.book_appointment.type.week'),
                day: this.$t('bz2.event.book_appointment.type.day'),
                '4day': this.$t('bz2.event.book_appointment.type.4day'),
            }
        },
    },
    mounted() {},
    methods: {
        viewDay({
            date,
        }) {
            this.focus = date
            this.type = 'day'
        },
        setToday() {
            this.focus = ''
        },
        prev() {
            this.$refs.calendar.prev()
        },
        next() {
            this.$refs.calendar.next()
        },
        showEvent({
            nativeEvent,
            event,
        }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }
            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }
            nativeEvent.stopPropagation()
        },
        openCreate() {
            this.$emit('open-create')
        },
        openNotifications() {
            this.$emit('open-notifications')
        },
        deleteItem() {
            this.$emit('delete-item')
        },
    },
}
</script>
<style>
.bz-load {
    position: absolute;
    background: rgba(0,0,0,.1);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    backdrop-filter: blur(3px);
}
</style>