<template>
  <li
    class="tw-flex tw-w-full tw-items-center tw-justify-between tw-bg-white tw-p-4 focus-within:tw-ring focus-within:tw-ring-inset"
  >
    <button
      class="tw-flex tw-min-w-0 tw-flex-1 tw-items-center focus:tw-outline-none"
      type="button"
      @click="talkToPerson(user)"
    >
      <div class="tw-relative">
        <v-img
          :src="user.avatar"
          class="tw-h-9 tw-w-9 tw-rounded-full"
          contain
        >
          <template #placeholder>
            <v-row
              class="fill-height ma-0 transparent"
              align="center"
              justify="center"
            >
              <v-progress-circular size="32" indeterminate color="primary" />
            </v-row>
          </template>
        </v-img>
        <span
          v-if="added"
          class="tw-absolute tw--top-0.5 tw--left-1 tw-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-px-0.5 tw-py-px"
          aria-hidden="true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="tw-h-3.5 tw-w-3.5 tw-rounded-full tw-text-secondary-300"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
        <span
          v-if="loading"
          class="tw-absolute tw--top-0.5 tw--left-1 tw-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-px-0.5 tw-py-px"
          aria-hidden="true"
        >
          <svg
            class="tw-h-4 tw-w-4 tw-animate-spin tw-text-primary-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="tw-opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            />
            <path
              class="tw-opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </span>
        <span
          class="tw-absolute tw--bottom-0.5 tw--right-1 tw-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-px-0.5 tw-py-px"
          aria-hidden="true"
        >
          <span
            class="tw-h-2 tw-w-2 tw-rounded-full"
            :class="[!!user.is_online ? 'tw-bg-green-400' : 'tw-bg-gray-400']"
          />
        </span>
      </div>
      <div class="tw-ml-3 tw-truncate">
        <p
          class="tw-mb-0 tw-truncate tw-text-left tw-text-xs tw-font-semibold tw-text-gray-900"
        >
          {{ user.first_name }} {{ user.last_name }}
        </p>
        <p
          v-if="canViewEmail"
          class="tw-mb-0 tw-truncate tw-text-left tw-text-xs tw-text-gray-400"
        >
          {{ user.email }}
        </p>
      </div>
    </button>
    <slot name="right-col" />

    <div class="tw-flex tw-flex-col tw-items-end">
      <!-- tw-z-50 -->
      <v-menu
        v-if="hasOptions"
        offset-y
        transition="scale-transition"
        content-class=" tw-py-1 tw-mt-2 tw-bg-white tw-rounded-md tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 "
      >
        <template #activator="{ on, attrs }">
          <button
            id="project-options-menu-0-button"
            ref="menuProfileChat"
            type="button"
            class="tw-inline-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2"
            aria-expanded="false"
            aria-haspopup="true"
            v-bind="attrs"
            v-on="on"
          >
            <span class="tw-sr-only">Open options</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="tw-h-5 tw-w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
              />
            </svg>
          </button>
        </template>
        <v-list>
          <v-list-item @click="talkToPerson(user)">
            <v-list-item-title>{{
              $t('pages.event.actions.send_msg')
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!added" @click="addContact(user)">
            <v-list-item-title>{{
              $t('pages.event.actions.add_contact')
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="viewAttendee(user)">
            <v-list-item-title>{{
              $t('pages.event.actions.view_card')
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </li>
</template>

<script>
import {
  computed,
  getCurrentInstance,
  readonly,
  ref,
} from '@vue/composition-api'

export default {
  props: {
    canClick: {
      type: Boolean,
      default: true,
    },
    hasOptions: {
      type: Boolean,
      default: true,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const loading = ref(false)
    const userStore = computed(() => {
      return vm.$store.state.session.user
    })
    const isNotUser = computed(() => {
      if (!userStore.value.roles) {
        return false
      }
      return userStore.value.roles.length > 1
    })
    const forms = computed(() => {
      return vm.$store.state.event.constants.registry.forms
    })
    const current_language_key = computed(() => {
      return vm.$store.state.registry.show.current_language_key
    })
    // user current lang / last form registry access
    // form users => is_public hash|map

    const current_form = computed(() => {
      return forms.value.find(
        (row) => row.language.name === current_language_key.value,
      )
    })
    const default_fields = computed(() => {
      return current_form.value.default_fields
    })
    const email_field = computed(() => {
      const read_default_fields = readonly(default_fields)
      return read_default_fields.value.reduce((acc, curr) => {
        acc[curr.name] = curr
        return acc
      }, {})['email']
    })
    const canViewEmail = computed(() => {
      return email_field.value.is_public || isNotUser.value
    })
    const userContactList = computed(() => {
      return vm.$store.state.event.constants.event_connections.map(
        (row) => row.event_user_id,
      )
    })
    const added = computed(() => {
      return userContactList.value.includes(props.user.id)
    })
    return {
      added,
      loading,
      addContact(user) {
        loading.value = true
        vm.$store
          .dispatch('event/createEventConnection', {
            event_user_id: user.id,
          })
          .then(() => {
            // this.check = true
          })
          .catch((err) => {
            vm.$store.dispatch('notifications/addNotification', {
              title: 'Error añadiendo contacto',
              body: err,
            })
          })
          .finally(() => (loading.value = false))
      },
      canViewEmail,
    }
  },
  methods: {
    viewAttendee(user) {
      this.$store.commit('event/setCard', { attendee: user, modal: true })
    },
    talkToPerson(user) {
      if (!this.canClick) {
        return
      }
      this.$store.commit('chat/setState', { view: 'CHAT', talking_to: user })
    },
  },
}
</script>

<style></style>
