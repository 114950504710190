<template>
  <renderless-layout :full-screen="true">
    <div
      v-if="showLoading"
      :class="{
        'tw-aspect-w-16 tw-aspect-h-7': is_desktop,
        'tw-aspect-w-16 tw-aspect-h-9': !is_desktop,
      }"
    >
      <v-container
        key="canvas_loading_room"
        fluid
        class="tw-pointer-events-none tw-fixed tw-inset-0 tw-z-50 tw-flex tw-p-4 md:tw-items-end md:tw-justify-start md:tw-p-6"
      >
        <v-col class="tw-h-full">
          <div class="tw-flex tw-h-full tw-items-center tw-justify-center">
            <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
            />
          </div>
        </v-col>
      </v-container>
    </div>

    <EventVirtualRoomContent v-else />
  </renderless-layout>
</template>

<script>
import EventVirtualRoomContent from './EventVirtualRoomContent.vue'
// import VueSlickCarousel from 'vue-slick-carousel'
// import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { openWindowTab } from '@/utils/openWindowTab'
import { mapFields } from '@/helpers'
import { mapState } from 'vuex'
// import AppPlyr from '@/components/Video/AppPlyr.vue'
import { setUserLocation } from '@/graphql/queries/vrUserLocationQueries'
import { download } from '@/utils/download'
import { AppCoreVitalKeys } from '@/enums/AppCoreVitalKeys'
import { wsClient } from '@/apollo-client'
import { ClickableTypeKeys } from '@/enums/ClickableTypeKeys'
import { TargetLinkKeys } from '@/enums/TargetLinkKeys'
import { TabKeys } from '@/enums/TabKeys'
import { ContentTypeKeys } from '@/enums/ContentTypeKeys'
export default {
  components: {
    EventVirtualRoomContent,
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    hasMounted: false,
    settings: {
      arrows: false,
      dots: false,
      // dotsClass: 'slick-dots custom-dot-class',
      // variableWidth: true,
      fade: true,
      // adaptiveHeight: true,
      autoplay: true,
      // autoplaySpeed: 2000,
      infinite: true,
      // speed: 500,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
    },
    windowSize: {
      x: 0,
      y: 0,
    },
  }),
  computed: {
    baseItems() {
      return this.virtual_room.elements
    },
    items() {
      return this.baseItems.filter((row) =>
        row.breakpoints.split(',').includes(this.currentBreakPointKey),
      )
    },
    ...mapState({
      license: (state) => state.event.constants.license,
      registry: (state) => state.event.constants.registry,
      user: (state) => state.session.user,
      showLoading: (state) => state.event.show.isLoading,
      is_desktop: (state) => state.event.constants.is_desktop,
      is_preview: (state) => state.event.constants.is_preview,
      virtual_room: (state) => state.event.show.data,
      virtual_rooms: (state) => state.event.constants.virtual_rooms,
      event_configs: (state) => state.event.constants.event_configs.data,
      current_config_language_key: (state) =>
        state.event.constants.event_configs.data.current_config_language_key,
    }),
    eventConfig() {
      if (this.license.can_concurrent_events) {
        console.log('hey lets do multiple events')
        return this.registry.configs.find(
          (row) => row.language.name === this.current_config_language_key,
        )
      }
      return this.event_configs[this.current_config_language_key]
    },
    // ...mapGetters({
    //   eventConfig: ['event/eventConfig'],
    //   // styles: ["event/styles_element"],
    // }),
    currentBreakPointKey() {
      return this.is_desktop ? 'desktop' : 'mobile'
    },
    styles() {
      return this.virtual_room.elements.reduce((row, curr) => {
        const multiplier = this.room_width / 1000
        if (this.is_desktop) {
          row[curr.uuid] = {
            width: parseInt(curr.desktop_width) * multiplier + 'px',
            height: parseInt(curr.desktop_height) * multiplier + 'px',
            top: parseInt(curr.desktop_y) * multiplier + 'px',
            left: parseInt(curr.desktop_x) * multiplier + 'px',
          }
        } else {
          row[curr.uuid] = {
            width: parseInt(curr.responsive_width) * multiplier + 'px',
            height: parseInt(curr.responsive_height) * multiplier + 'px',
            top: parseInt(curr.responsive_y) * multiplier + 'px',
            left: parseInt(curr.responsive_x) * multiplier + 'px',
          }
        }
        return row
      }, {})
    },
    ...mapFields({
      fields: ['room_width', 'room_height', 'nav_count', 'uuid_tracker'],
      base: ['event', 'constants'],
      mutation: 'event/setConstants',
    }),
    ...mapFields({
      fields: [
        'iframeItemModal',
        'iframeItemHtml',
        'iframeItemUrl',
        'iframeItemType',
      ],
      base: ['event', 'iframeItem'],
      mutation: 'event/setIframeItem',
    }),
    ...mapFields({
      fields: ['contentItemModal', 'contentItemData'],
      base: ['event', 'contentItem'],
      mutation: 'event/setContentItem',
    }),
    ...mapFields({
      fields: ['contentWindowModal', 'contentWindowData'],
      base: ['event', 'contentWindow'],
      mutation: 'event/setContentWindow',
    }),
    ...mapFields({
      fields: [
        'simpleVideoItemModal',
        'simpleVideoItemType',
        'simpleVideoItemUrl',
        'simpleVideoItemPreview',
      ],
      base: ['event', 'simpleVideoItem'],
      mutation: 'event/setSimpleVideoItem',
    }),

  },

  mounted() {
    this.$store.commit('chat/setState', { showChat: false })
    console.log('MOUNTED EventVirtualRoom: ', this.slug)
    this.$store.commit('event/setShow', { isLoading: true })
    // this.onResize()
    console.log(this.eventConfig)
    console.log(this.eventConfig.current_config_language_key)
    /**
    Registry
    - RegistryConfig -> Language
    - RegistryTicket 
    -- RegistryTicketLocale -> language
    **/ 
    // TODO: RegistryTicket: User, Room, Registry
    var correct_slug = ''
    var ticket = this.user.ticket ? this.user.ticket.find(ticket => ticket.registry_id == this.registry.id) : ''
    if (this.registry.is_multi_ticket && ticket) {
      correct_slug = this.slug == 'home' 
        ? this.registry.registry_room.find(row => row.registry_ticket_id === ticket.registry_ticket_id).virtual_room.slug
        : this.slug
    } else {
      correct_slug = this.slug == 'home'
        ? this.eventConfig.home_room.slug
        : this.slug
    }
    // const correct_slug =
    //   this.slug == 'home'
    //     ? // ? this.eventConfig[this.eventConfig.current_config_language_key]
    //       //     .home_room.slug
    //       // this.registry.registry_room.find(row => row.registry_ticket_id === this.user.ticket.registry_ticket_id).virtual_room.slug
    //       this.eventConfig.home_room.slug // 1. this.registry.tickets.find(row=> row.locale.language_id == language_id).virtual_room.slug
    //     : this.slug

    this.$nextTick(() => {
      this.$apollo
        .mutate({
          mutation: setUserLocation,
          variables: {
            slug: correct_slug,
          },
        })
        .then(() => {
          console.log('MOUNTED EventVirtualRoom: triggered ')
        })
    })
    // if (this.slug == 'home') {
    //   /**
    //    * From the config get the user selected language home room slug
    //    */
    //   this.$store
    //     .dispatch('event/show', {
    //       id: this.eventConfig[this.eventConfig.current_config_language_key]
    //         .home_room.slug,
    //       // id: this.eventConfig['en'].home_room.slug,
    //     })
    //     .then(() => {
    //       this.$emit('updateHead')
    //       this.recordCoreVital()
    //     })
    // } else {
    //   }

    this.nav_count++
    this.$store.dispatch('event/show', { id: correct_slug }).then(() => {
      this.recordCoreVital()
    })
    // wsClient.
    // this.$apollo.
    wsClient.onReconnecting(() => {
      console.log('onReconnecting')
    })
    wsClient.onReconnected(() => {
      console.log('onReconnected')
      this.$apollo
        .mutate({
          mutation: setUserLocation,
          variables: {
            slug: this.slug,
          },
        })
        .then(() => {
          console.log('MOUNTED EventVirtualRoom: triggered ')
        })
    })
    this.hasMounted = true

    const interval = setInterval(
      () => {
        // check if idle => do not record if idle ?
        this.recordCoreVitalInterval()
      },
      // 30000
      180000,
    )
    this.$once('hook:destroyed', () => {
      console.log('destroyed')
      clearInterval(interval)
    })

    setTimeout(() => {
      this.generateMetaOg()
    }, 4000)
  },
  methods: {
    generateMetaOg() {
      var metas = document.getElementsByTagName('meta')
      for (var i = 0; i < metas.length; i++) {
        if(metas[i].getAttribute('property')=='og:type')
        {
            metas[i].setAttribute('content','article')
        }
        if(metas[i].getAttribute('property')=='og:title')
        {
            metas[i].setAttribute('content',this.virtual_room.label)
        }
        if(metas[i].getAttribute('property')=='og:description')
        {
            metas[i].setAttribute('content',this.virtual_room.description)
        }
        if(metas[i].getAttribute('property')=='og:image')
        {
            metas[i].setAttribute('content',this.virtual_room.bg_desktop)
        }
      }
    },
    recordCoreVital() {
      const core_vital_type =
        this.nav_count > 1
          ? AppCoreVitalKeys.EventNagivation
          : AppCoreVitalKeys.EventInit
      this.dispatchCoreVital(core_vital_type)
    },
    recordCoreVitalInterval() {
      const core_vital_type = AppCoreVitalKeys.EventStillInRoom
      this.dispatchCoreVital(core_vital_type)
    },
    dispatchCoreVital(core_vital_type) {
      const solid = this.$store.getters['event/plainMetricsPayload']
      this.$store.dispatch('general/recordCoreVital', {
        payload: {
          ...solid,
          core_vital_type,
          simple_log: this.nav_count,
          // nav_count: this.nav_count,
          // uuid_tracker: this.uuid_tracker,
          // device_width: window.innerWidth,
          // device_height: window.innerHeight,
          // room_width: this.room_width,
          // room_height: this.room_height,
          // virtual_room_id: this.virtual_room.id,
        },
        core_vital_type,
      })
    },
    contentItemEvent(content_item_element) {
      const {
        target_link_type_id,
        download_item_url,
        doorway_item_room_id,
        link_item_url,
        content_type: { name },
      } = content_item_element

      console.log(name)
      if (ContentTypeKeys.download == name) {
        console.log(target_link_type_id)
        if (TargetLinkKeys.normal.value == target_link_type_id) {
          download(download_item_url, download_item_url.split('/').pop(0))
        }
        if (TargetLinkKeys.target.value == target_link_type_id) {
          this.contentItemModal = true
          this.contentItemData = content_item_element
        }
      }
      if (ContentTypeKeys.doorway == name) {
        const room =
          this.$store.getters['event/getRoomFromConstants'](
            doorway_item_room_id,
          )
        console.log(room)
        if (room) {
          this.toVirtualRoom(room.slug)
        }
      }

      if (ContentTypeKeys.link == name) {
        console.log(link_item_url)
        console.log(target_link_type_id)
        if (TargetLinkKeys.normal.value == target_link_type_id) {
          openWindowTab(link_item_url)
        }
        if (TargetLinkKeys.target.value == target_link_type_id) {
          this.$store.commit('event/setContentItem', {
            contentItemModal: true,
            contentItemData: content_item_element,
          })
        }
      }
      if (ContentTypeKeys.video == name) {
        this.$store.commit('event/setContentVideo', {
          contentVideoModal: true,
          contentVideoData: content_item_element,
        })
      }
    },
    clickActionEvent(item) {
      console.log('click action triggered')
      const {
        clickable,
        clickable: {
          click_action_type: { name },
        },
      } = item
      console.log({ item })
      console.log(item.clickable)
      console.log(item.clickable.click_action_type.name)

      if (ClickableTypeKeys.link_content === name) {
        console.log(item.clickable.content_item_element)
        this.contentItemEvent(item.clickable.content_item_element)
      }
      if (ClickableTypeKeys.link_location === name) {
        const room = this.$store.getters['event/getRoomFromConstants'](
          clickable.link_location_virtual_room_id,
        )
        if (room) {
          this.toVirtualRoom(room.slug)
        }
      }
      if (ClickableTypeKeys.open_favorites === name) {
        this.$store.commit('event/setProfile', {
          modal: true,
          tab: TabKeys.saved,
        })
      }
      if (ClickableTypeKeys.open_calendar === name) {
        console.log('calendar')
      }
      if (ClickableTypeKeys.open_external_url === name) {
        openWindowTab(clickable.external_url_link)
      }
      if (ClickableTypeKeys.open_leaderboard === name) {
        this.$store.commit('event/setProfile', {
          modal: true,
          tab: TabKeys.points,
        })
      }
    },
    contentWindowMethod(item) {
      console.log('Content window')
      console.log({ item })
      const { label, content_item_elements } = item
      this.contentWindowData = { label, content_item_elements }
      this.contentWindowModal = true
      // console.log(item.label item.content_item_elements)
    },
    openSimpleVideo(item) {
      console.log(item)
      this.simpleVideoItemModal = true
      this.simpleVideoItemType = item.video_item_type.name
      this.simpleVideoItemUrl = item.video_item_url
      this.simpleVideoItemPreview = item.video_item_preview
    },
    openIframe(item) {
      console.log(item.iframe_item_url)
      this.iframeItemModal = true
      this.iframeItemType = item.iframe_item_type.name
      this.iframeItemUrl = item.iframe_item_url
      this.iframeItemHtml = item.iframe_item_html
    },
    // toVirtualRoom(item) {
    toVirtualRoom(slug) {
      if (this.slug === slug) {
        return
      }
      this.$router.push({
        name: this.is_preview ? 'event-preview.route' : 'event.route',
        params: {
          slug,
          eventSlug: this.registry.slug,
          // slug: item.doorway_item_room.slug,
        },
      })
    },
    onResize() {
      console.log(
        'resiing elements, while this is showing or not showing',
        this.showLoading,
      )
      if (this.showLoading) {
        return
      }
      console.log(this.$refs.event_room_dekstop.getBoundingClientRect())
      const { width, height } = this.is_desktop
        ? this.$refs.event_room_dekstop.getBoundingClientRect()
        : this.$refs.event_room_dekstop.getBoundingClientRect()
      // : this.$refs.event_room_mobile.getBoundingClientRect()
      this.room_width = width
      this.room_height = height
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
  },
  // head() {
  //   return {
  //     title: {
  //       inner: this.virtual_room.label,
  //     },
  //     style: [
  //       {
  //         type: 'text/css',
  //         inner: this.virtual_room.custom_css,
  //       },
  //     ],
  //   }
  // },
}
</script>

<style>
/* @media (min-width: 1000px) {
  .room-max-width {
    max-width: 1000px;
  }
}
@media (min-width: 1170px) {
  .room-max-width {
    max-width: 1170px;
  }
}

@media (min-width: 1340px) {
  .room-max-width {
    max-width: 1340px;
  }
} */

/* @media (min-width: 1510px) {
  .room-max-width {
    max-width: 1510px;
  }
} */

@media (min-width: 1680px) {
  .room-max-width {
    max-width: 1680px;
  }
}
.g-iframe-full iframe {
  width: 100% !important;
  height: 100% !important;
}
/* 1920 => 1680
=> 1,510
=> 1340
=> 1170
=> 1000 */
</style>
