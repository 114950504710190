<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="1024px"
    content-class="tw-rounded-3xl tw-w-full"
  >
    <v-form
      ref="form"
      v-model="valid"
      :disabled="form_disabled_state"
      lazy-validation
      enctype="multipart/form-data"
      @submit.prevent="create"
    >
      <v-card class="tw-rounded-3xl tw-border-0 tw-shadow-md">
        <ModalHeader @close-modal="closeModal">{{
          $t('admin.app.nav_item')
        }}</ModalHeader>
        <v-divider />
        <v-card-text class="tw-p-0" style="max-height: 700px">
          <v-responsive class="tw-py-0">
            <v-container class="tw-bg-gray-100">
              <v-row class="mb-5">
                <v-col cols="12" md="5" class="px-5 pt-5 mx-auto">
                  <v-card
                    class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-shadow-md"
                  >
                    <v-container class="pa-5">
                      <v-text-field
                        v-model="mountedNavItem.label"
                        :rules="[rules.required]"
                        validate-on-blur
                        outlined
                        rounded
                        :label="$t('admin.app.name')"
                      />
                      <v-text-field
                        v-model="mountedNavItem.helper_text"
                        :rules="[rules.required]"
                        validate-on-blur
                        outlined
                        rounded
                        :label="$t('admin.app.helper_text')"
                      />
                    </v-container>
                  </v-card>
                  <!-- TODO: NavItems: add class to the navitems -->
                  <v-card v-if="show.is_multi_ticket" class="mt-5 mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-shadow-md">
                    <v-container class="pa-5">
                      <TicketAutocomplete 
                        v-model="mountedNavItem.registry_ticket_id"
                        :tickets="registry_tickets"
                      />
                    </v-container>
                  </v-card>
                </v-col>
                <v-col cols="12" md="7" class="mx-auto pa-5">
                  <v-card
                    class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-shadow-md"
                  >
                    <ClickableFormPartial
                      v-if="hasMounted"
                      :clickable="mountedNavItem.clickable"
                      :can-switch-clickable="false"
                      @updated-clickable="updatedClickable"
                    />
                    <v-container class="pa-5">
                      <div
                        class="tw-flex tw-flex-col-reverse lg:tw-flex-row lg:tw-space-x-4"
                      >
                        <div class="tw-w-full">
                          <div v-if="mountedNavItem.image_url" class="tw-mb-6">
                            <label class="tw-block tw-pb-2 tw-text-gray-600">
                              {{ $t('app.preview') }}
                            </label>
                            <div class="tw-flex tw-items-center tw-space-x-4">
                              <img
                                :src="mountedNavItem.image_url"
                                class="overflow-hidden tw-group tw-relative tw-h-18 tw-w-18 tw-rounded-lg tw-shadow-md tw-transition focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300"
                              />
                              <AppBtn
                                type="button"
                                :disabled="form_loading"
                                color="white"
                                @click="toggle_dropzone = !toggle_dropzone"
                              >
                                {{ $t('app.change') }}
                              </AppBtn>
                            </div>
                          </div>
                          <div class="tw-mx-auto">
                            <!-- v-show="toggle_dropzone" -->
                            <v-card
                              v-show="toggle_dropzone"
                              class="mx-auto tw-mb-6 tw-shadow-sm"
                            >
                              <v-container class="tw-p-0">
                                <!--
                                  :width="dimestions[mountedNavItem.size]"
                                  :height="dimestions[mountedNavItem.size]" -->
                                <!-- placeholder="La imagen debe tener un aspect ratio de 1x1(ser cuadrada), y debe ser de formato PNG, JPG/JPEG o GIF" -->
                                <dropzone
                                  :width="1"
                                  :height="1"
                                  :bypass="'ratio-check'"
                                  media-group="nav_item"
                                  @on-success="uploadedFile"
                                />
                              </v-container>
                            </v-card>
                          </div>
                        </div>
                        <div v-if="false" class="tw-w-full">
                          <div class="tw-mb-3 tw-grid tw-grid-cols-1 tw-gap-4">
                            <radio-btn
                              v-for="size in sizes"
                              :key="size.value"
                              v-model="mountedNavItem.size"
                              :label="size.text"
                              :radio-value="size.value"
                            >
                              <p
                                class="tw-mt-1 tw-mb-0 tw-text-sm tw-text-gray-900"
                              >
                                {{ dimestions[size.value] }}x{{
                                  dimestions[size.value]
                                }}
                              </p>
                            </radio-btn>
                          </div>
                        </div>
                      </div>
                      <!-- :bypass="'no-check'" -->
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-responsive>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-alert
            v-model="errorAlert"
            dismissible
            color="red"
            border="left"
            elevation="2"
            colored-border
            icon="error"
            transition="fade-transition"
            >{{ errorMsg }}</v-alert
          >
          <v-spacer />
          <AppBtn
            type="button"
            :disabled="form_loading"
            color="transparent"
            @click="open = false"
          >
            {{ $t('app.cancel') }}
          </AppBtn>
          <AppBtn type="submit" :loading="form_loading" color="primary">
            {{ $t('app.save') }}
          </AppBtn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ModalHeader from '@/components/ModalHeader'
import AppBtn from '@/components/App/AppBtn.vue'
import { mapGetters, mapState } from 'vuex'
import Dropzone from '@/components/Dropzone.vue'
import RadioBtn from '@/components/FormInputs/RadioBtn.vue'
// import VirtualRoomAutocomplete from '@/components/VirtualRoomAutocomplete.vue'
import ClickableFormPartial from '@/components/FormPartials/ClickableFormPartial.vue'
import { rules } from '@/utils/rules'
import { simpleObjectCopy } from '@/utils/simpleObjectCopy'
import TicketAutocomplete from '@/components/TicketAutocomplete.vue'

export default {
  components: {
    AppBtn,
    ClickableFormPartial,
    ModalHeader,
    Dropzone,
    RadioBtn,
    // VirtualRoomAutocomplete,
    TicketAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    navItem: {
      type: Object,
      default: () => ({}),
    },
    lang: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    hasMounted: false,
    toggle_dropzone: false,
    sizes: [
      {
        text: 'Small',
        value: 'sm',
      },
      {
        text: 'Medium',
        value: 'md',
      },
      {
        text: 'Large',
        value: 'lg',
      },
    ],
    dimestions: {
      sm: 24,
      md: 48,
      lg: 72,
    },
    errorAlert: false,
    errorMsg: '',
    exam: '',
    rules,
    // rules: {
    //   numericZero: (v) => !!parseFloat(v) || 'Campo no puede ser 0',
    //   testEmpty: (value) => !!value || 'Campo no puede estar vacio.',
    //   min: (v) => v.length >= 8 || 'Min 8 characters',
    //   emailMatch: () => "The email and password you entered don't match",
    // },
    isLoading: false,
    form_loading: false,
    form_disabled_state: false,
    valid: true,
    mountedNavItem: {},
    isLoadingRooms: false,
  }),
  computed: {
    is_link_location() {
      return this.mountedNavItem.click_action_type == 'link_location'
    },
    is_external_url() {
      return this.mountedNavItem.click_action_type == 'open_external_url'
    },
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    ...mapGetters({
      click_types: ['constants/click_types'],
    }),
    ...mapState({
      show: (state) => state.registries.show.data,
    }),
    registry_tickets() {
      return this.show.registry_tickets
        .map((registry_tickets) => {
            return registry_tickets
        })
    },
  },
  mounted() {
    this.mountedNavItem = simpleObjectCopy(this.navItem)
    if (!this.mountedNavItem.image_url) {
      this.toggle_dropzone = true
    }
    this.hasMounted = true
  },
  methods: {
    closeModal() {
      this.open = false
    },
    updatedClickable(clickable) {
      console.log(clickable)
      this.mountedNavItem.clickable = clickable
    },
    uploadedFile(res) {
      this.mountedNavItem.image_url = res.media_element.link
      this.toggle_dropzone = false
    },
    create() {
      this.errorAlert = false
      if (!this.$refs.form.validate()) {
        return
      }
      if (!this.mountedNavItem.image_url) {
        this.errorAlert = true
        this.errorMsg = 'Indica un icono'
        return
      }

      this.$emit('updated-nav-item', this.mountedNavItem)
      this.open = false
    },
  },
}
</script>

<style></style>
