import $axios from '@/axios-instance.js'

export class HomeAdminDashboardService {
  baseUrl = '/api/v1/admin/dashboard'
  static make() {
    return new HomeAdminDashboardService()
  }
  // Read all - findAll
  execute(params) {
    return $axios.get(this.baseUrl, params)
  }
}
