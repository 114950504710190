/***
 *
 * EXAMPLE USAGE
 *
 * Resource.index('users')
 * Resource.store('users', { email: 'daniel@tighten.co' })
 * Resource.show('users', 1)
 * Resource.update('users', { id: 1, email: 'daniel.coulbourne@gmail.com' })
 * Resource.destroy('users', 1)
 *
 ***/

import $axios from '@/axios-instance.js'

class Resource {
  /**
   * @param {string} endpoint
   */
  constructor(endpoint) {
    this.url = endpoint
  }

  computedUrl() {
    if (this.child) {
      const statePath = `${this.url}/${this.child}`
      this.child = ''
      return statePath
    }
    return this.url
  }

  index(params) {
    return $axios.get(this.computedUrl(), params)
  }

  store(params) {
    return $axios.post(this.computedUrl(), params)
  }

  show(id, params) {
    return $axios.get(this.computedUrl() + `/${id}`, params)
  }

  update(id, params) {
    return $axios.post(this.computedUrl() + `/${id}`, {
      ...params,
      _method: 'PUT',
    })
  }

  destroy(id, params) {
    return $axios.post(this.computedUrl() + `/${id}`, {
      ...params,
      _method: 'DELETE',
    })
  }
}

export default Resource
