import { EventBroadcastOverlayStyleKeys } from '@/enums/EventBroadcastOverlayStyleKeys'
import { dd_mm_yyyy, hh_mm } from '@/utils/dateHelpers'
import { ClickableBase, setClickableFromDB } from './Clickable'
export const EventBroadcastBase = ({
  language = 'es',
  title = '',
  body = '',
  event_broadcast_style_type = 'slim',
  emit_at_date = '',
  emit_at_time = '',
  duration = '',
  has_sound = true,
  emit_at_tz = 'America/Lima',
  has_emit_at = true,
  is_active = true,
  clickable = ClickableBase({}),
  overlay_type = EventBroadcastOverlayStyleKeys.overlay_plain,
  action_label = '',
  overlay_image_url = '',
  has_test = false,
  has_email_user_inactive = false,
}) => ({
  language,
  title,
  body,
  event_broadcast_style_type,
  duration,
  has_sound,
  has_emit_at,
  emit_at_time,
  emit_at_date,
  emit_at_tz,
  is_active,
  clickable,
  overlay_type,
  action_label,
  overlay_image_url,
  has_test,
  has_email_user_inactive,
})
export const EventBroadcastHydrate = ({
  language: { name: language },
  emit_at,
  emit_at_tz,
  event_broadcast_style_type: { name: event_broadcast_style_type },
  clickable,
  overlay_type,
  ...fields
}) =>
  EventBroadcastBase({
    ...fields,
    overlay_type: overlay_type
      ? overlay_type
      : EventBroadcastOverlayStyleKeys.overlay_plain,
    language,
    event_broadcast_style_type,
    clickable: clickable ? setClickableFromDB(clickable) : ClickableBase({}),
    has_emit_at: !!emit_at,
    emit_at_date: dd_mm_yyyy(emit_at, emit_at_tz),
    emit_at_time: hh_mm(emit_at, emit_at_tz),
    emit_at_tz,
  })
