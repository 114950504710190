<template>
  <v-container
    class="fill-height tw-flex tw-items-start tw-overflow-y-scroll tw-bg-gray-100"
    fluid
  >
    <v-row class="mb-5">
      <v-col cols="12" sm="7" class="mx-auto pa-5">
        <v-card
          class="mx-auto tw-divide-y tw-divide-gray-200 tw-overflow-hidden tw-rounded-3xl tw-shadow-md"
        >
          <v-container class="tw-p-0 tw-py-5" fluid>
            <div v-if="usersInMyRoom.length > 0" key="profile.cards.list">
              <div
                class="tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-200 tw-px-4 tw-pb-4"
              >
                <div>
                  <h3
                    class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                  >
                    {{ $t('pages.event.rep_dash.visitors') }}
                  </h3>
                </div>
                <div>
                  <AppBtn
                    icon="refresh"
                    type="button"
                    color="transparent"
                    :disabled="loading"
                    :loading="loading"
                    @click="loadData"
                  >
                    {{ $t('app.refresh') }}
                  </AppBtn>
                </div>
              </div>
              <ul
                class="tw-relative tw-w-full tw-divide-y tw-divide-gray-200 tw-pl-0"
              >
                <UsersListItem
                  v-for="user in usersInMyRoom"
                  :key="user.email"
                  :can-click="false"
                  :has-options="false"
                  :user="user"
                >
                  <template #right-col>
                    <div
                      v-if="user.id !== current_user.id"
                      class="tw-space-x tw-flex tw-divide-x-2 tw-divide-solid tw-divide-gray-200"
                    >
                      <v-btn text @click="viewAttendee(user)">
                        {{ $t('pages.event.profile.labels.profile') }}
                      </v-btn>
                      <v-btn text @click="chatWithUser(user)">
                        {{ $t('pages.event.rep_dash.chat') }}
                      </v-btn>
                    </div>
                  </template>
                </UsersListItem>
              </ul>
            </div>
            <div
              v-else-if="usersInMyRoom.length === 0 && loading"
              key="no_users"
              class="tw-p-16 tw-text-center"
            >
              <h2 class="tw-mb-2 tw-font-semibold tw-text-gray-900">
                {{ $t('app.loading') }}...
              </h2>
              <!-- <p class="tw-text-sm tw-text-gray-700">
            No podemos encontrar nada con ese término en este momento, intente
            buscando algo más.
          </p> -->
            </div>
            <div v-else key="no_users" class="tw-p-16 tw-text-center">
              <h2 class="tw-mb-2 tw-font-semibold tw-text-gray-900">
                {{ $t('pages.event.rep_dash.empty_visitors') }}
              </h2>
              <!-- <p class="tw-text-sm tw-text-gray-700">
            No podemos encontrar nada con ese término en este momento, intente
            buscando algo más.
          </p> -->
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppBtn from '@/components/App/AppBtn.vue'
import UsersListItem from './UsersListItem.vue'
import { mapState } from 'vuex'
export default {
  components: {
    AppBtn,
    UsersListItem,
  },
  props: {
    currentSelectedSlug: {
      require: true,
      type: String,
      default: '',
    },
  },
  data: () => ({ loading: false, user_ids: [] }),
  computed: {
    ...mapState({
      current_user: (state) => state.session.user,
      registry: (state) => state.event.constants.registry,
      users: (state) => state.event.socket.users,
      usersLocation: (state) => state.event.socket.usersLocation,
    }),
    userInThisLocation() {
      return this.usersLocation
        .filter((row) => row.user_id !== this.current_user.id)
        .filter(
          (row) =>
            row.slug === this.currentSelectedSlug &&
            row.registry_uuid === this.registry.uuid,
        )
        .map((row) => row.user_id)
    },
    usersInMyRoom() {
      return this.users.filter((row) => this.user_ids.includes(row.id))
    },
  },
  watch: {
    currentSelectedSlug: {
      handler(newVal) {
        console.log('watcher rep running')
        if (newVal) {
          console.log('watcher rep running actually runs')
          this.loadData()
        }
      },
    },
  },
  mounted() {
    console.log('Created visitors slug: ', this.currentSelectedSlug)
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      this.$store
        .dispatch('event/getRepRoomVisitors')
        .then(({ data }) => {
          console.log(data)
          this.user_ids = data.visitors.map((row) => row.user_id)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    viewAttendee(user) {
      this.$store.commit('event/setCard', { attendee: user, modal: true })
    },
    chatWithUser(user) {
      this.$store.commit('chat/setState', {
        showChat: false,
      })
      this.$nextTick().then(() => {
        this.$store.commit('chat/setState', {
          showChat: true,
          view: 'CHAT',
          talking_to: user,
        })
        this.$store.commit('event/setRepDashboard', {
          modal: false,
        })
      })
    },
  },
}
</script>

<style></style>
