<template>
  <renderless-layout>
    <v-container fluid class="tw-p-0">
      <StickyHeader :nav="nav">
        <template #actions>
          <div class="tw-flex tw-items-center tw-space-x-4">
            <div class="tw-flex tw-items-center tw-space-x-4">
              <LanguageSelect
                v-model="current_config_language_key"
                :label="$t('admin.app.lang_version')"
                dark
              />
              <AppBtn
                color="primary-dark"
                icon="g-next"
                g-next="Eye"
                @click="preview"
              >
                {{ $t('app.preview') }}
              </AppBtn>
              <AppBtn color="white" icon="check" @click="updateForms">
                {{ $t('app.save') }}
              </AppBtn>
            </div>
          </div>
        </template>
      </StickyHeader>
      <v-row class="tw-m-0 tw-p-0">
        <v-col cols="12" sm="12" md="12" lg="11" xl="9" class="mx-auto">
          <v-container fluid class="tw-space-y-6">
            <v-row class="">
              <v-col cols="12" md="3" class="px-5 pt-5 mx-auto">
                <form-description>
                  <template #title>
                    {{ $t('app.title_description') }}
                  </template>
                  <template #copy>
                    {{ $t('app.description_area') }}
                  </template>
                </form-description>
              </v-col>

              <v-col cols="12" md="9" class="mx-auto pa-5">
                <v-card
                  class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-shadow-md"
                >
                  <v-container class="pa-5">
                    <v-row>
                      <v-col cols="7" class="pb-0">
                        <v-text-field
                          v-model="title"
                          validate-on-blur
                          type="text"
                          rounded

                          :label="$t('app.title')"
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="9" class="pb-0">
                        <v-text-field
                          v-model="tag_line"
                          validate-on-blur
                          type="text"
                          rounded

                          :label="$t('app.tagline')"
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-textarea
                          ref="description"
                          v-model="description"
                          validate-on-blur
                          type="text"
                          rounded
                          auto-grow

                          :label="$t('app.description')"
                          outlined
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-container class="pa-5">
                    <v-row>
                      <v-col cols="12" class="tw-pb-0">
                        <DropzonePreview
                          v-model="image_url"
                          :width="1920"
                          :bypass="'no-check'"
                          :height="1080"
                          media-group="register"
                        />
                      </v-col>
                    </v-row>
                  </v-container>

                  <v-container class="pa-5">
                    <draggable
                      v-model="links"
                      tag="div"
                      handle=".handle"
                      class="tw-bg-white tw-shadow sm:tw-rounded-3xl"
                      ghost-class="ghost"
                    >
                      <transition-group
                        type="transition"
                        :name="'flip-list'"
                        tag="ul"
                        class="tw-divide-y tw-divide-gray-200 tw-pl-0"
                      >
                        <li v-for="element in links" :key="element.uid">
                          <button
                            type="button"
                            class="tw-block tw-w-full tw-transition focus:tw-bg-primary-100"
                          >
                            <div
                              class="tw-flex tw-items-center tw-px-4 tw-py-4 sm:tw-px-6"
                            >
                              <div
                                class="tw-min-w-0 tw-flex-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between"
                              >
                                <div
                                  class="tw-grid tw-w-full tw-grid-cols-2 tw-gap-4"
                                >
                                  <v-text-field
                                    ref="title"
                                    v-model="element.label"
                                    validate-on-blur
                                    type="text"
                                    rounded

                                    :label="$t('app.title')"
                                    outlined
                                  />
                                  <v-text-field
                                    ref="Url"
                                    v-model="element.link_url"
                                    validate-on-blur
                                    type="text"
                                    rounded

                                    label="Url*"
                                    outlined
                                  />
                                </div>
                                <div
                                  class="tw-mt-4 tw-flex-shrink-0 sm:tw-mt-0 sm:tw-ml-5"
                                />
                              </div>
                              <div
                                class="tw-flex tw-flex-shrink-0 tw-flex-col tw-items-center tw-space-y-2"
                              >
                                <div class="">
                                  <!-- Heroicon name: solid/chevron-right -->
                                  <v-btn fab small text class="handle">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      class="tw-h-5 tw-w-5"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M8 9l4-4 4 4m0 6l-4 4-4-4"
                                      />
                                    </svg>
                                  </v-btn>
                                </div>
                                <AppBtn
                                  color="transparent"
                                  icon-center="close"
                                  fab
                                  small
                                  type="button"
                                  @click="removeElement(element.uid)"
                                />
                              </div>
                            </div>
                          </button>
                        </li>
                      </transition-group>
                    </draggable>
                    <div class="tw-mt-6 tw-flex tw-justify-end">
                      <AppBtn
                        color="white"
                        icon="plus"
                        type="button"
                        @click="addElement"
                      >
                        {{ $t('admin.app.add_link') }}
                      </AppBtn>
                    </div>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </renderless-layout>
</template>

<script>
import LanguageSelect from '@/components/LanguageSelect'
import AppBtn from '@/components/App/AppBtn.vue'
import draggable from 'vuedraggable'
import StickyHeader from '@/components/StickyHeader'
import { mapState, mapGetters } from 'vuex'
import { mapFields, mapNestedFields } from '@/helpers'
import FormDescription from '@/components/FormDescription'
import DropzonePreview from '@/components/DropzonePreview'
import { LinkModel } from '@/models/LinkModel'
export default {
  components: {
    AppBtn,
    draggable,
    LanguageSelect,
    StickyHeader,
    DropzonePreview,
    FormDescription,
  },
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    ...mapNestedFields({
      fields: ['image_url', 'links', 'title', 'tag_line', 'description'],
      base: ['registries', 'thank_yous', 'update', 'data'],
      mutation: 'registries/setThankYousUpdateForm',
      nested_key: 'current_config_language_key',
    }),
    nav() {
      return [
        {
          name: this.$t('app.event'),
          route: { name: 'admin.registries.index' },
        },
        {
          name: this.show.label,
          route: {
            name: 'admin.registries.show.home',
            params: { id: this.show.id },
          },
        },
        {
          name: 'Thank you page',
          route: {},
        },
      ]
    },
    ...mapFields({
      fields: ['current_config_language_key'],
      base: ['registries', 'landings', 'update', 'data'],
      mutation: 'registries/setLandingsUpdateWrapper',
    }),
    ...mapGetters({
      languages: ['constants/languages'],
    }),
    ...mapState({
      show: (state) => state.registries.show.data,
    }),
  },

  methods: {
    removeElement(uid) {
      this.links = this.links.filter((row) => row.uid !== uid)
    },
    addElement() {
      this.links = [...this.links, LinkModel({})]
    },
    preview() {
      this.$router.push({
        name: 'registry.thank-you',
        params: { slug: this.show.slug },
      })
    },

    updateForms() {
      this.$store.dispatch('registries/updateThankYous').then(() =>
        this.$store.dispatch('registries/show', {
          id: this.id,
          appLoading: true,
        }),
      )
    },
  },
}
</script>

<style></style>
