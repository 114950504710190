import { short_uid } from '@/helpers'
import { arrayToObject } from '@/utils/arrayToObject'

export const FieldTypes = () => ({
    fields: [
      {
        text: 'Texto',
        value: 'input',
        icon: 'text_fields',
      },
      {
        text: 'Texto Largo',
        value: 'textarea',
        icon: 'notes',
      },
      {
        text: 'Radio',
        value: 'radio',
        icon: 'radio_button_checked',
      },
      {
        text: 'Checkbox',
        value: 'checkbox',
        icon: 'check_box',
      },
      {
        text: 'Select',
        value: 'select',
        icon: 'list',
      },
      {
        text: 'Date',
        value: 'date',
        icon: 'calendar_today',
      },
      {
        text: 'Number',
        value: 'number',
        icon: 'dialpad',
      },
      {
        text: 'Upload',
        value: 'upload',
        icon: 'upload',
      },
      {
        text: 'Representatives',
        value: 'domain',
        icon: 'domain',
      },
    ],
  })
  export const LookUpFields = arrayToObject(FieldTypes().fields, 'value')

export const CustomForm = ({
    registry_id = '',
    title = '',
    type_form = '',
    description = '',
    fields = [],
    custom_css = '',
}) => ({
    registry_id,
    title,
    type_form,
    description,
    fields,
    custom_css,
})

export const CustomFormOption = ({ text = '', value = '', uid = short_uid() }) => ({
    text,
    value,
    uid,
})

export const CustomFormInputBase = ({
    label = '',
    model = '',
    model_multiple = [],
    model_correct = '',
    name = '',
    field_type = 'input',
    rules = [],
    cols = 12,
    is_active = false,
    is_required = false,
    is_visible_table = false,
    is_visible_print = false,
    is_multiple = false,
    messages = {},
    options = [],
    uid = short_uid(),
}) => ({
    label,
    model,
    model_multiple,
    model_correct,
    name,
    field_type,
    rules,
    cols,
    messages,
    options,
    uid,
    is_active,
    is_required,
    is_visible_table,
    is_visible_print,
    is_multiple,
})