<template>
  <div
    class="tw-flex tw-items-center tw-rounded-full tw-bg-gray-100 tw-py-1 tw-pl-2 tw-pr-4 tw-text-sm tw-font-medium tw-text-gray-700 tw-shadow-xs sm:tw-text-xs"
  >
    <template v-if="isActive">
      <svg fill="currentColor" class="tw-mr-2 tw-h-5 tw-w-5 tw-text-green-500">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        />
      </svg>
      <div class="tw-whitespace-nowrap">{{ parsedActiveText }}</div>
    </template>
    <template v-else>
      <svg
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        class="tw-mr-2 tw-h-5 tw-w-5 tw-text-gray-500"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <div class="tw-whitespace-nowrap">{{ parsedInactiveText }}</div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: true,
    },
    activeText: {
      type: String,
      default: '',
    },
    inactiveText: {
      type: String,
      default: '',
    },
  },
  computed: {
    parsedActiveText() {
      return this.activeText ? this.activeText : this.$t('admin.app.active')
    },
    parsedInactiveText() {
      return this.inactiveText
        ? this.inactiveText
        : this.$t('admin.app.inactive')
    },
  },
}
</script>

<style></style>
