<template>
  <UserProfileTabWrapper>
    <div>
      <div
        class="tw-sticky tw-top-0 tw-z-10 tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-justify-between tw-bg-gray-50 tw-px-5 tw-py-3 tw-shadow-md"
      >
        <div>
          <v-text-field
            v-model="search"
            :label="$t('app.search')"
            outlined
            rounded
            hide-details=""
            dense
            prepend-inner-icon="search"
          />
        </div>
        <div class="tw-flex tw-items-center tw-w-full md:tw-w-auto tw-px-3 md:tw-px-0 tw-mt-2 sm:tw-mt-0">
          <v-btn
            v-if="canExport"
            rounded
            class="tw-bg-green-700 tw-px-8 tw-font-bold tw-normal-case tw-text-white focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-50 tw-mr-2 tw-w-full sm:tw-w-auto"
            :disabled="isExporting || !userContactList.length > 0"
            :loading="isExporting"
            @click="exportCards"
          >
            <g-icon
              class="tw--ml-1 tw-h-6 tw-w-6"
              name="download_cloud"
            />
            {{ $t('app.export') }}
          </v-btn>
          <!-- Donovann: Add Contact qr -->
          <ModQRScannerModal v-model="qr_model" class="tw-ml-2" @validate-user="addContactQR"/>
          <div
          v-if="false" 
          class="tw-ml-4 tw-flex tw-justify-center">
            <span
              class="tw-inline-flex tw-rounded-md tw-border tw-bg-gray-200 tw-p-1.5"
            >
              <label
                v-if="false"
                class="tw-relative tw-cursor-pointer tw-rounded focus-within:tw-ring-1 focus-within:tw-ring-primary-300 focus-within:tw-ring-offset-2"
              >
                <input
                  v-model="toggle"
                  type="radio"
                  name="server_size"
                  :value="toggleKeys.collection"
                  class="tw-sr-only"
                  aria-labelledby="server-size-0-label"
                  aria-describedby="server-size-0-description-0 server-size-0-description-1"
                />

                <div
                  v-ripple
                  class="tw-rounded tw-px-2.5 tw-py-1.5 tw-transition focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-gray-50"
                  :class="{
                    ' tw-bg-white tw-shadow': isCollection,
                  }"
                >
                  <g-icon
                    name="collection"
                    class="tw-h-6 tw-w-6 tw-text-gray-600"
                  />
                </div>
                <div
                  :class="
                    isCollection
                      ? 'tw-border-gray-200'
                      : 'tw-border-transparent'
                  "
                  class="tw-pointer-events-none tw-absolute tw--inset-px tw-rounded tw-border-2 tw-border-transparent"
                  aria-hidden="true"
                />
              </label>

              <label
                class="tw-relative tw-cursor-pointer tw-rounded focus-within:tw-ring-1 focus-within:tw-ring-primary-300 focus-within:tw-ring-offset-2"
              >
                <input
                  v-model="toggle"
                  type="radio"
                  name="server_size"
                  :value="toggleKeys.list"
                  class="tw-sr-only"
                  aria-labelledby="server-size-0-label"
                  aria-describedby="server-size-0-description-0 server-size-0-description-1"
                />
                <div
                  v-ripple
                  class="tw-rounded tw-px-2.5 tw-py-1.5 tw-transition focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-gray-50"
                  :class="{
                    ' tw-bg-white tw-shadow': isList,
                  }"
                >
                  <g-icon name="list" class="tw-h-6 tw-w-6 tw-text-gray-600" />
                </div>
                <div
                  :class="
                    isList ? 'tw-border-gray-200' : 'tw-border-transparent'
                  "
                  class="tw-pointer-events-none tw-absolute tw--inset-px tw-rounded tw-border-2 tw-border-transparent"
                  aria-hidden="true"
                />
              </label>
            </span>
          </div>
        </div>
      </div>
      <transition name="fade-transition" mode="out-in">
        <div v-if="myContacts.length > 0" key="caca">
          <transition name="fade-transition" mode="out-in">
            <div
              v-if="isCollection"
              key="profile.cards.collections"
              class="tw-grid tw-grid-cols-3 tw-gap-4 tw-p-5 tw-shadow-inner"
            >
              <div v-for="attendee in myContacts" :key="attendee.uuid">
                <v-hover v-slot="{ hover }">
                  <v-card class="tw-rounded-3xl tw-shadow-md">
                    <user-business-card
                      :user="attendee"
                      size="xs"
                      :can-close="false"
                      :can-add-connection="false"
                    />
                    <v-overlay :absolute="true" :value="hover">
                      <div
                        class="tw-space-x tw-flex tw-divide-x-2 tw-divide-solid tw-divide-gray-200"
                      >
                        <v-btn dark text @click="viewAttendee(attendee)">
                          {{ $t('app.view') }}
                        </v-btn>
                        <v-btn dark text @click="deleteAttendee(attendee)">
                          {{ $t('app.delete') }}
                        </v-btn>
                      </div>
                    </v-overlay>
                  </v-card>
                </v-hover>
              </div>
            </div>
            <div v-else key="profile.cards.list">
              <ul
                class="tw-relative tw-w-full tw-divide-y tw-divide-gray-200 tw-pl-0"
              >
                <UsersListItem
                  v-for="user in myContacts"
                  :key="user.email"
                  :can-click="false"
                  :has-options="false"
                  :user="user"
                >
                  <template #right-col>
                    <div
                      class="tw-space-x tw-flex tw-divide-x-2 tw-divide-solid tw-divide-gray-200"
                    >
                      <v-btn text @click="viewAttendee(user)">
                        <!-- {{ $t('app.view') }} -->
                        <v-icon dark color="success">
                          account_box
                        </v-icon>
                      </v-btn>
                      <v-btn text @click="deleteAttendee(user)">
                        <v-icon dark color="red">
                          delete
                        </v-icon>
                        <!-- {{ $t('app.delete') }} -->
                      </v-btn>
                    </div>
                  </template>
                </UsersListItem>
              </ul>
            </div>
          </transition>
        </div>
        <div
          v-else-if="userContactList.length > 0"
          key="users_but_results"
          class="tw-p-16 tw-text-center"
        >
          <h2 class="tw-mb-2 tw-font-semibold tw-text-gray-900">
            {{ $t('pages.event.profile.contacts.no_results') }}
          </h2>
          <p class="tw-text-sm tw-text-gray-700">
            {{ $t('pages.event.profile.contacts.no_result_term') }}
          </p>
        </div>
        <div v-else key="no_users" class="tw-p-16 tw-text-center">
          <h2 class="tw-mb-2 tw-font-semibold tw-text-gray-900">
            {{ $t('pages.event.profile.contacts.no_contacts') }}
          </h2>
          <!-- <p class="tw-text-sm tw-text-gray-700">
            No podemos encontrar nada con ese término en este momento, intente
            buscando algo más.
          </p> -->
        </div>
      </transition>
      <v-dialog
        v-model="confirmDelete"
        persistent
        width="400"
        content-class="tw-self-end tw-rounded-3xl sm:tw-self-auto"
      >
        <v-card dark class="tw-shadow-md">
          <v-card-text class="pt-2">
            <h1 class="text-center mb-2 pt-5 pb-5 tw-text-2xl">
              {{ $t('app.are_you_sure') }}
            </h1>
            <p class="text-center tw-text-lg">
              {{ $t('pages.event.profile.contacts.warning') }}
            </p>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-container class="tw-px-0 tw-py-2">
              <div
                class="tw-flex tw-flex-col tw-justify-center sm:tw-flex-row sm:tw-space-x-3"
              >
                <v-btn text :disabled="loading" @click="cancelDeleteAttendee">
                  {{ $t('app.cancel') }}
                </v-btn>
                <v-btn
                  outlined
                  color="error"
                  :disabled="loading"
                  :loading="loading"
                  class="focus:tw-outline-none focus:tw-ring focus:tw-ring-red-300"
                  @click="deleteAttendeeConfirmed"
                >
                  <v-icon left>close</v-icon>
                  {{ $t('app.delete') }}
                </v-btn>
              </div>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </UserProfileTabWrapper>
</template>

<script>
import GIcon from '@/components/GIcon.vue'
import UserBusinessCard from './UserBusinessCard.vue'
import UsersListItem from './UsersListItem.vue'
import UserProfileTabWrapper from './UserProfileTabWrapper.vue'
import { mapState } from 'vuex'
import { NotificationStatusKeys } from '@/enums/NotificationStatusKeys'
// Donovann
import ModQRScannerModal from './ModQRScannerModal.vue'
const toggleKeys = {
  collection: 'collection',
  list: 'list',
}
export default {
  components: {
    GIcon,
    UsersListItem,
    UserBusinessCard,
    UserProfileTabWrapper,
    ModQRScannerModal,
  },
  data: () => ({
    toggle: 'list',
    toggleKeys,
    search: '',
    loading: false,
    removeAttendeeId: null,
    confirmDelete: false,
    // Donovann
    qr_model: false,
  }),
  computed: {
    ...mapState({
      isExporting: (state) => state.event.userCards.isExporting,
      users: (state) => state.event.socket.users,
      userContactList: (state) =>
        state.event.constants.event_connections.map((row) => row.event_user_id),
      currentRegistry: (state) => state.event.constants.registry,
    }),
    isCollection() {
      return this.toggle === toggleKeys.collection
    },
    isList() {
      return this.toggle === toggleKeys.list
    },
    canExport() {
      return this.currentRegistry.can_export_connections
    },
    myContacts() {
      return this.users
        .filter((row) => this.userContactList.includes(row.id))
        .filter((row) => {
          return (
            row.first_name
              .toLocaleLowerCase()
              .indexOf(this.search.toLocaleLowerCase()) > -1 ||
            row.last_name
              .toLocaleLowerCase()
              .indexOf(this.search.toLocaleLowerCase()) > -1 ||
            row.email
              .toLocaleLowerCase()
              .indexOf(this.search.toLocaleLowerCase()) > -1
          )
        })
    },
  },
  methods: {
    // Donovann: add contact
    addContactQR({ pathname }) {
      const parts = pathname.split('/')
      const user_uuid = parts[parts.length - 1]
      const user_id = this.users.find(row => row.uuid == user_uuid)
      console.log(user_id.id)
      // Add contact
      this.$store
        .dispatch('event/createEventConnection', {
          event_user_id: user_id.id,
        })
        .then(() => {
          // this.check = true
        })
        .catch((err) => {
          this.$store.dispatch('notifications/addNotification', {
            title: 'Error añadiendo contacto',
            body: err,
          })
        })
    },
    exportCards() {
      console.log('hit')
      this.$store.dispatch('event/exportEventConnections').catch((err) => {
        this.$store.dispatch('notifications/addNotification', {
          title: 'Error exportando contactos',
          body: err,
        })
      })
    },
    cancelDeleteAttendee() {
      this.removeAttendeeId = null
      this.confirmDelete = false
    },
    deleteAttendeeConfirmed() {
      this.loading = true
      this.$store
        .dispatch('event/deleteEventConnection', {
          event_user_id: this.removeAttendeeId,
        })
        .then(() => {
          this.removeAttendeeId = null
        })
        .catch((err) => {
          this.$store.dispatch('notifications/addNotification', {
            title: 'Error añadiendo contacto',
            body: err,
            status: NotificationStatusKeys.error,
          })
        })
        .finally(() => {
          this.confirmDelete = false
          this.loading = false
        })
    },
    deleteAttendee(user) {
      this.removeAttendeeId = user.id
      this.confirmDelete = true
    },
    viewAttendee(user) {
      this.$store.commit('event/setCard', { attendee: user, modal: true })
    },
  },
}
</script>

<style></style>
