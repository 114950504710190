import gql from 'graphql-tag'

export const storeQuestionAnswer = gql`
  mutation storeQuestionAnswer($content: CreateQuestionAnswerForm!) {
    storeQuestionAnswer(content: $content)
  }
`

export const updateQuestionAnswer = gql`
  mutation updateQuestionAnswer($content: UpdateQuestionAnswerForm!) {
    updateQuestionAnswer(content: $content)
  }
`

export const deleteQuestionAnswer = gql`
  mutation deleteQuestionAnswer($content: DeleteQuestionAnswerForm!) {
    deleteQuestionAnswer(content: $content)
  }
`
