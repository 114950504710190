<template>
  <v-select
    ref="language_selected"
    v-model="input_value"
    :items="i18Languages"
    :class="{ 'tw-w-48': !block }"
    :label="$t('app.language')"
    outlined
    rounded
    hide-details=""
    dense
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    block: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    input_value: {
      // get() {
      //   return this.value
      // },
      // set(val) {
      //   this.$emit('input', val)
      // },

      get() {
        return this.$store.state.language.locale
      },
      set(v) {
        // this.$i18n.locale = v
        // this.$store.commit('registry/setShow', { current_language_key: v })
        this.$store.dispatch('language/updateLocate', { locale: v })
      },
    },
    inputListeners: function () {
      // let vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          // input: function (event) {
          //   vm.$emit("input", event.target.value);
          // },
        },
      )
    },
    ...mapGetters({
      languages: ['constants/languages'],
    }),
    i18Languages() {
      return this.languages.map((lang) => ({
        ...lang,
        text: this.$i18n.t(`app.${lang.value}`),
      }))
    },
  },
}
</script>

<style></style>
