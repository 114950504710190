<template>
  <div>
    <div class="tw-mb-3 tw-flex tw-items-center tw-justify-between">
      <div>
        <h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
          {{ label }}
        </h3>
        <p class="tw-mt-1 tw-mb-0 tw-text-sm tw-text-gray-500">
          {{ description }}
        </p>
      </div>
      <div class="tw-ml-4 tw-flex tw-justify-center">
        <span
          class="tw-inline-flex tw-rounded-md tw-border tw-bg-gray-200 tw-p-0.5"
        >
          <label
            class="tw-relative tw-cursor-pointer tw-rounded focus-within:tw-ring focus-within:tw-ring-primary-300"
          >
            <input
              v-model="toggle"
              type="radio"
              name="server_size"
              :value="EditorKeys.easy"
              class="tw-sr-only"
              aria-labelledby="server-size-0-label"
              aria-describedby="server-size-0-description-0 server-size-0-description-1"
            />

            <div
              v-ripple
              class="tw-rounded tw-p-1.5 tw-py-1.5 tw-transition focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-50"
              :class="{
                ' tw-bg-white tw-shadow': isEasy,
              }"
            >
              <g-icon name="list" class="tw-h-5 tw-w-5 tw-text-gray-600" />
            </div>
            <div
              :class="isEasy ? 'tw-border-gray-200' : 'tw-border-transparent'"
              class="tw-pointer-events-none tw-absolute tw--inset-px tw-rounded tw-border-2 tw-border-transparent"
              aria-hidden="true"
            />
          </label>
          <!--
              focus-within:tw-ring-1
              focus-within:tw-ring-offset-2 -->
          <label
            class="tw-relative tw-cursor-pointer tw-rounded focus-within:tw-ring focus-within:tw-ring-primary-300"
          >
            <input
              v-model="toggle"
              type="radio"
              name="server_size"
              :value="EditorKeys.code"
              class="tw-sr-only"
              aria-labelledby="server-size-0-label"
              aria-describedby="server-size-0-description-0 server-size-0-description-1"
            />
            <div
              v-ripple
              class="tw-rounded tw-p-1.5 tw-py-1.5 tw-transition focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-50"
              :class="{
                ' tw-bg-white tw-shadow': isCode,
              }"
            >
              <g-icon name="code" class="tw-h-5 tw-w-5 tw-text-gray-600" />
            </div>
            <div
              :class="isCode ? 'tw-border-gray-200' : 'tw-border-transparent'"
              class="tw-pointer-events-none tw-absolute tw--inset-px tw-rounded tw-border-2 tw-border-transparent"
              aria-hidden="true"
            />
          </label>
        </span>
      </div>
    </div>
    <div v-if="isCode" class="tw-w-full">
      <div class="">
        <!-- <editor-base-prism v-if="false" v-model="code" @blur="onBlur" /> -->
        <codemirror
          v-model="code"
          class="tw-h-72 tw-rounded-xl lg:tw-h-80 2xl:tw-h-96"
          :options="cmOptions"
          @blur="onBlur"
        />
      </div>
      <!-- line-numbers -->
    </div>
    <div
      v-if="editor && isEasy"
      class="editor tw-h-72 tw-overflow-hidden lg:tw-h-80 2xl:tw-h-96"
    >
      <TipTapMenuBar class="editor__header" :editor="editor" />
      <EditorContent class="editor__content" :editor="editor" />
      <div
        v-if="hasLimit"
        class="tw-flex tw-justify-end tw-px-4 tw-py-1 tw-text-xs"
      >
        <div class="tw-flex tw-items-center">
          <svg class="character-count__graph tw-h-4 tw-w-4" viewBox="0 0 20 20">
            <circle r="10" cx="10" cy="10" fill="#e9ecef" />
            <circle
              r="5"
              cx="10"
              cy="10"
              fill="transparent"
              stroke="currentColor"
              stroke-width="10"
              :stroke-dasharray="`calc(${percentage} * 31.4 / 100) 31.4`"
              class="tw-text-gray-400"
              transform="rotate(-90) translate(-20)"
            />
            <circle r="6" cx="10" cy="10" fill="white" />
          </svg>
          <div class="tw-ml-1 tw-text-gray-400">
            {{ editor.getCharacterCount() }}/{{ limit }} characters
          </div>
        </div>
      </div>
      <!-- <div class="editor__footer">
        <div :class="`editor__status editor__status--${status}`">
          <template v-if="status === 'connected'">
            {{ users.length }} user{{ users.length === 1 ? '' : 's' }} online in {{ room }}
          </template>
          <template v-else>
            offline
          </template>
        </div>
        <div class="editor__name">
          <button @click="setName">
            {{ currentUser.name }}
          </button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import GIcon from '@/components/GIcon'
import Image from '@tiptap/extension-image'

// import language js
import 'codemirror/mode/htmlmixed/htmlmixed.js'
import 'codemirror/lib/codemirror.css'
// import theme style
// import 'codemirror/theme/base16-dark.css'
import 'codemirror/theme/material.css'
import Codemirror from './EditorCodemirror'
// import Dropcursor from '@tiptap/extension-dropcursor'

// import EditorBasePrism from './EditorBasePrism.vue'
// TipTap
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
import Highlight from '@tiptap/extension-highlight'
import CharacterCount from '@tiptap/extension-character-count'
import TextStyle from '@tiptap/extension-text-style'
import TextAlign from '@tiptap/extension-text-align'
import { FontSize } from '@/utils/tiptap-fontsize'
// import { Color } from '@/utils/tiptap-color'
import { Color } from '@tiptap/extension-color'

import { SpanClass } from '@/utils/tiptap-span-class'
import Typography from '@tiptap/extension-typography'
import Link from '@tiptap/extension-link'
import TipTapMenuBar from './TipTapMenuBar.vue'

const getRandomElement = (list) => {
  return list[Math.floor(Math.random() * list.length)]
}

const getRandomRoom = () => {
  return getRandomElement(['room.4', 'room.5', 'room.6'])
}
import { EditorKeys } from '@/enums/EditorKeys'
export default {
  components: {
    EditorContent,
    TipTapMenuBar,
    // EditorBasePrism,
    GIcon,
    Codemirror,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    characterLimit: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      EditorKeys,
      toggle: 'tiptap',
      provider: null,
      limit: 280,
      indexdb: null,
      editor: null,
      users: [],
      status: 'connecting',
      room: getRandomRoom(),
      code: '',
      cmOptions: {
        tabSize: 4,
        mode: 'text/html',
        theme: 'material',
        lineNumbers: true,
        line: true,
        lineWrapping: true,
        matchBrackets: true,
        // more CodeMirror options...
      },
    }
  },

  computed: {
    percentage() {
      return Math.round((100 / this.limit) * this.editor.getCharacterCount())
    },
    hasLimit() {
      return this.characterLimit > 0
    },
    // code: {
    //   get() {
    //     return this.editor.getHTML()
    //   },
    //   set(val) {
    //     this.editor.commands.setContent(val)
    //   },
    // },
    isEasy() {
      return this.toggle == EditorKeys.easy
    },
    isCode() {
      return this.toggle == EditorKeys.code
    },
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return
      }
      this.editor.commands.setContent(this.value, false)
      this.code = this.editor.getHTML()
      // console.log('hit editor watch')
      // console.log(this.editor.getHTML())
      // console.log(this.code)
      // console.log('end editor watch')
    },
  },
  mounted() {
    console.log('Editor tip tap')
    this.editor = new Editor({
      extensions: [
        StarterKit.configure({
          history: true,
        }),
        Highlight,
        TaskList,
        TaskItem,
        TextStyle,
        Link,
        Typography,
        FontSize,
        // Image,
        // ImageHandler,
        Image.configure({ inline: true }),
        // Dropcursor,
        Color,
        SpanClass,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        CharacterCount.configure({
          limit: 10000,
        }),
      ],
      editorProps: {
        attributes: {
          class:
            'prose tw-prose-sm sm:tw-prose lg:tw-prose-lg xl:tw-prose-2xl tw-mx-auto focus:tw-outline-none',
        },
      },
      content: this.value,
      onUpdate: () => {
        this.code = this.editor.getHTML()
        // HTML
        this.$emit('input', this.editor.getHTML())

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
      // parseOptions: {
      //   preserveWhitespace: 'full',
      // },
    })
    console.log(this.value)
    this.code = this.editor.getHTML()
    console.log(this.code)
  },
  beforeDestroy() {
    this.editor.destroy()
    // this.provider.destroy()
  },
  methods: {
    onBlur() {
      this.editor.commands.setContent(this.code)
      this.$emit('input', this.editor.getHTML())
    },
    getRandomColor() {
      return getRandomElement([
        '#958DF1',
        '#F98181',
        '#FBBC88',
        '#FAF594',
        '#70CFF8',
        '#94FADB',
        '#B9F18D',
      ])
    },
  },
}
</script>

<style lang="scss" scoped>
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #020304;
  color: #ccc;
  padding: 1rem;
  border: 3px solid #0d0d0d;
  border-radius: 0.75rem;
  /* you must provide font-family font-size line-height. Example: */
  /* font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace; */
  font-size: 14px;
  line-height: 1.5;
  /* padding: 5px; */
  outline: none;
  .prism-editor__container:focus-within {
    outline: none;
  }
  textarea:focus-within,
  textarea:focus-visible {
    outline: none;
  }
  .prism-editor__editor:focus {
    outline: none;
  }
  .prism-editor__editor:focus-within {
    outline: none;
  }
  .prism-editor__editor:focus-visible {
    outline: none;
  }
}
.prism-editor__container {
  /* optional class for removing the outline */
  .prism-editor__textarea {
    &:focus {
      outline: none;
    }
    &:focus-visible {
      outline: none;
    }
  }
}

.character-count {
  margin-top: 1rem;
  color: #868e96;
}
.editor {
  display: flex;
  flex-direction: column;
  /* max-height: 288px; */
  color: #0d0d0d;
  background-color: #fffdfe;
  border: 3px solid #0d0d0d;
  border-radius: 0.75rem;

  &__header {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding: 0.25rem;
    border-bottom: 3px solid #0d0d0d;
  }

  &__content {
    padding: 1.25rem 1rem;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__footer {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    white-space: nowrap;
    border-top: 3px solid #0d0d0d;
    font-size: 12px;
    font-weight: 600;
    color: #0d0d0d;
    white-space: nowrap;
    padding: 0.25rem 0.75rem;
  }

  /* Some information about the status */
  &__status {
    display: flex;
    align-items: center;
    border-radius: 5px;

    &::before {
      content: ' ';
      flex: 0 0 auto;
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      background: rgba(#0d0d0d, 0.5);
      border-radius: 50%;
      margin-right: 0.5rem;
    }

    &--connecting::before {
      background: #616161;
    }

    &--connected::before {
      background: #b9f18d;
    }
  }

  &__name {
    button {
      background: none;
      border: none;
      font: inherit;
      font-size: 12px;
      font-weight: 600;
      color: #0d0d0d;
      border-radius: 0.4rem;
      padding: 0.25rem 0.5rem;

      &:hover {
        color: #fff;
        background-color: #0d0d0d;
      }
    }
  }
}
</style>

<style lang="scss">
/* Give a remote user a caret */
.collaboration-cursor__caret {
  position: relative;
  margin-left: -1px;
  margin-right: -1px;
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  word-break: normal;
  pointer-events: none;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  position: absolute;
  top: -1.4em;
  left: -1px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  user-select: none;
  color: #0d0d0d;
  padding: 0.1rem 0.3rem;
  border-radius: 3px 3px 3px 0;
  white-space: nowrap;
}

/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  mark {
    background-color: #faf594;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  hr {
    margin: 1rem 0;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

  ul[data-type='taskList'] {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
      }
    }
  }
}
</style>
