import $axios from '@/axios-instance.js'

class RegistriesActionsUserServiceClass {
  baseUrl = '/api/v1/admin/registries'
  child = 'actions-users'

  // Luego refactrizar las acciones de attendees aqui
  // Delete All Users Selected
  delete(event_config, params) {
    return $axios.post(`${this.baseUrl}/${event_config}/${this.child}`, params)
  }
  checkout(event_config, params) {
    return $axios.post(`${this.baseUrl}/${event_config}/checkout`, params)
  }
}
export const RegistriesActionsUsers = new RegistriesActionsUserServiceClass()
