import Resource from './BaseResource'

class CertificateResource extends Resource {
  /**
   * The constructor for the CertificateResource.
   */
  constructor() {
    super('/api/v1/admin/certificate')
  }
}

export default new CertificateResource()
