<template>
  <div class="tw-flex tw-h-full tw-flex-col">
    <div class="tw-w-full tw-rounded-t-lg tw-bg-primary-500">
      <div class="tw-flex tw-items-center tw-space-x-2 tw-px-4 tw-py-5">
        <div class="">
          <v-btn
            fab
            x-small
            class="text--white tw-normal-case tw-shadow-sm focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-300"
            @click="view = 'HOME'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="tw-h-4 tw-w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </v-btn>
        </div>
        <div class="tw-relative tw-flex tw-flex-1 tw-items-center tw-space-x-3" style="width: 90%">
          <div class="tw-min-w-0 tw-flex-1">
            <!--
                @click="$refs.menuProfileChat.click()" -->
            <!-- <button class="tw-pointer-events-none focus:tw-outline-none"> -->
              <span class="tw-absolute tw-inset-0" aria-hidden="true" />
              <p class="tw-mb-0 tw-text-base tw-font-medium tw-text-gray-50">
                {{ public_channel.subject }}
              </p>
              <p
                v-if="public_channel.description"
                class="tw-mb-0 tw-truncate tw-text-sm tw-text-gray-200"
              >
                {{ public_channel.description }}
              </p>
            <!-- </button> -->
          </div>
          <div>
            <v-menu
              v-if="false"
              offset-y
              transition="scale-transition"
              content-class="tw-py-1 tw-mt-2 tw-bg-white tw-rounded-md tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5"
            >
              <template #activator="{ on, attrs }">
                <button
                  id="project-options-menu-0-button"
                  ref="menuProfileChat"
                  type="button"
                  class="tw-inline-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2"
                  aria-expanded="false"
                  aria-haspopup="true"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span class="tw-sr-only">Open options</span>
                  <svg
                    class="tw-h-5 tw-w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
                    />
                  </svg>
                </button>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title>Ver participantes</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
    <EventRoomContentElementChatChannel
      :parrent-channel-id="public_channel.id"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EventRoomContentElementChatChannel from './EventRoomContentElementChatChannel.vue'
export default {
  components: {
    EventRoomContentElementChatChannel,
  },
  computed: {
    active_channel: {
      get() {
        return this.$store.state.chat.active_channel
      },
      set(value) {
        return this.$store.commit('chat/setState', {
          active_channel: value,
        })
      },
    },
    ...mapState({
      user: (state) => state.session.user,
      public_channel: (state) => state.chat.public_channel,
    }),
    view: {
      get() {
        return this.$store.state.chat.view
      },
      set(val) {
        this.$store.commit('chat/setState', { view: val })
      },
    },
  },
  beforeDestroy() {
    this.active_channel = 0
  },
}
</script>
