import { EventConfigsServiceInstance } from '@/services/EventConfigsService'
import {
  EventConfigForm,
  EventConfigBase,
  EventConfigDB,
} from '@/models/EventConfig'
import { EventNavItemBD } from '@/models/EventNavItem'
import {
  GlobalEventConfigDB,
  GlobalEventConfigForm,
} from '@/models/GlobalEventConfig'
import { formErrorsHandler } from '@/utils/formErrorsHandler'
import { EventConfigsNavServiceInstance } from '@/services/EventConfigsNavService'
import { EventBroadcastBase } from '@/models/EventBroadcast'
import { EventBroadcastsServiceInstance } from '@/services/EventBroadcastsService'

// state
const initState = () => {
  return {
    global_event: {
      update: { data: GlobalEventConfigForm() },
    },
    update: {
      data: {
        current_config_language_key: 'es',
        es: EventConfigForm(),
        en: EventConfigForm(),
      },
    },
    nav_items: {
      update: {
        data: {
          es: [],
          en: [],
        },
      },
    },
    show: {
      data: {
        event_configs: [],
      },
      is_loading: true,
    },
    broadcasts: {
      create: {
        data: EventBroadcastBase({}),
      },
      update: {
        data: EventBroadcastBase({}),
      },
      show: {
        isBeingCopied: false,
        isLoading: true,
        data: {
          event_broadcast_style_type: {},
        },
      },
    },
  }
}

// getters
const getters = {}

// actions
const actions = {
  resetNavItems({ commit, state }) {
    state.show.data.event_configs.forEach(({ config_language, nav_items }) => {
      commit('setNavItems', {
        current_config_language_key: config_language.name,
        nav_items: nav_items.map((item) => EventNavItemBD(item)),
      })
    })
  },
  index({ commit, dispatch }, { loading = false }) {
    if (loading) {
      commit('setShow', { is_loading: true })
    }
    return EventConfigsServiceInstance.index()
      .then(({ data: { event_configs, global_event_config } }) => {
        commit('setShowData', { event_configs })
        dispatch('resetNavItems')
        console.log(GlobalEventConfigDB(global_event_config))
        commit('setGlobalData', GlobalEventConfigDB(global_event_config))
        event_configs.forEach((config_item) => {
          const formated_row = {
            [config_item.config_language.name]: EventConfigDB(config_item),
          }
          commit('setConfigWrapperForm', formated_row)
        })
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setShow', { is_loading: false })
        // commit("setAppIsLoading", false, { root: true });
      })
  },
  mountEventConfigs({ rootState, commit }) {
    const configs = rootState.constants.data.event_configs
    configs.forEach(
      ({
        config_language,
        default_language: default_language_obj,
        virtual_room_start_id,
        header_url,
        label,
      }) => {
        const formated_row = {
          [config_language.name]: EventConfigBase({
            label,
            header_url,
            virtual_room_start_id,
            default_language: default_language_obj.name,
          }),
        }
        commit('setConfigWrapperForm', formated_row)
      },
    )
  },
  updateOrCreate({ state, commit }) {
    const event_confgs_state = state.update.data
    const event_configs = Object.keys(event_confgs_state)
      .filter((row) => !(typeof event_confgs_state[row] === 'string'))
      .map((row) => ({ ...event_confgs_state[row], config_language: row }))
    const form = {
      event_configs,
      global_config: state.global_event.update.data,
    }
    // transform data from keys object to array
    console.log(form)
    commit('setAppIsLoading', true, { root: true })
    // insert service
    EventConfigsServiceInstance.store(form)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  updateOrCreateNav({ state, commit }) {
    // const nav_items = Object.keys(state.nav_items.update.data)
    //   .filter(
    //     (lang) => !(typeof state.nav_items.update.data[lang] === "string")
    //   )
    //   .filter((lang) => state.nav_items.update.data[lang].length > 0)
    //   .map((lang) => ({
    //     ...state.nav_items.update.data[lang],
    //     event_config_id: state.show.data.event_configs.find(
    //       (config) => config.config_language.name == lang
    //     ).id,
    //   }));
    const { current_config_language_key } = state.update.data
    const event_config_id = state.show.data.event_configs.find(
      (config) => config.config_language.name == current_config_language_key,
    ).id

    const form = {
      nav_items: state.nav_items.update.data[current_config_language_key],
    }
    // console.log(event_config_id, form);
    // console.log(state.show.virtualRoom.id);
    commit('setAppIsLoading', true, { root: true })
    return EventConfigsNavServiceInstance.store(event_config_id, form)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  broadcastStore({ commit }, { payload }) {
    // const roles = state.create.data.roles
    const form = {
      ...payload,
    }
    commit('setAppIsLoading', true, { root: true })
    return EventBroadcastsServiceInstance.store(form)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  broadcastUpdate({ state, commit }, { payload }) {
    const form = {
      ...payload,
    }
    const id = state.broadcasts.show.data.id
    commit('setAppIsLoading', true, { root: true })
    return EventBroadcastsServiceInstance.update(id, form)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  setUpdateBroadcastFromDB({ commit }, { event_broadcast }) {
    const { title, body, emit_at } = event_broadcast
    commit(
      'setBroadcastUpdate',
      EventBroadcastBase({ title, body, has_emit_at: !!emit_at }),
    )
  },
  broadcastShow({ commit }, { id, loading = false, appLoading = false }) {
    if (loading) {
      commit('setBroadcastShow', { isLoading: true })
    }
    if (appLoading) {
      commit('setAppIsLoading', true, { root: true })
    }
    return EventBroadcastsServiceInstance.show(id)
      .then(({ data: { event_broadcast } }) => {
        commit('setBroadcastShow', { data: event_broadcast })
        // dispatch('setUpdateBroadcastFromDB', { event_broadcast })
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setBroadcastShow', { isLoading: false })
        commit('setAppIsLoading', false, { root: true })
      })
  },

  broadcastDestroy({ commit }, { id = '' }) {
    commit('setAppIsLoading', true, { root: true })
    return EventBroadcastsServiceInstance.destroy(id)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
}

// mutations
const mutations = {
  setNavItems(state, { nav_items, current_config_language_key }) {
    state.nav_items.update.data[current_config_language_key] = nav_items
  },
  setGlobalData(state, obj) {
    Object.keys(obj).forEach(
      (k) => (state.global_event.update.data[k] = obj[k]),
    )
  },
  setShowData(state, obj) {
    Object.keys(obj).forEach((k) => (state.show.data[k] = obj[k]))
  },
  setShow(state, obj) {
    Object.keys(obj).forEach((k) => (state.show[k] = obj[k]))
  },
  setValue(state, value) {
    state.value = value
  },
  setConfigWrapperForm(state, obj) {
    Object.keys(obj).forEach((k) => (state.update.data[k] = obj[k]))
  },
  setConfigForm(state, obj) {
    console.log(obj)
    const { current_config_language_key } = obj
    delete obj['current_config_language_key']
    Object.keys(obj).forEach(
      (k) => (state.update.data[current_config_language_key][k] = obj[k]),
    )
  },
  cleanBroadcastCreate(state) {
    state.broadcasts.create.data = EventBroadcastBase({})
  },
  setBroadcastShow(state, obj) {
    Object.keys(obj).forEach((k) => (state.broadcasts.show[k] = obj[k]))
  },
  setBroadcastCreate(state, obj) {
    Object.keys(obj).forEach((k) => (state.broadcasts.create.data[k] = obj[k]))
  },
  setBroadcastUpdate(state, obj) {
    Object.keys(obj).forEach((k) => (state.broadcasts.update.data[k] = obj[k]))
  },
}

export const EventConfig = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
}
