<template>
  <transition name="fade-transition" mode="out-in">
    <v-col v-if="showLoading" key="canvas_loading" class="tw-h-full">
      <div class="tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        />
      </div>
    </v-col>
    <template v-else>
      <transition name="fade-transition" mode="out-in">
        <router-view />
      </transition>
    </template>
  </transition>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    slug: {
      type: [Number, String],
      default: 0,
    },
  },
  data: () => ({
    validateEvent: false,
  }),
  head() {
    return {
      title: {
        inner: this.registry.label,
      },
    }
  },
  computed: {
    showLoading() {
      return this.$store.state.registry.show.isLoading
    },

    ...mapState({
      current_language_key: (state) => state.registry.show.current_language_key,
      registry: (state) => state.registry.show.data,
    }),
    hasFavicon() {
      return this.registry.has_custom_fav_icon
    },
  },
  mounted() {
    this.$store
      .dispatch('registry/show', { id: this.slug })
      .then(() => {
        this.$emit('updateHead')
        // Validando si el evento se encuentra inactivo cuando no tiene la hora de inicio y fin
        if (this.registry.start_date_time == null && this.registry.end_date_time == null) {
          this.$router.push({name: 'registry.home.index'})
          this.$store.commit('registry/setAlert',{isActive: true})
          return
        }
        if (this.registry.has_custom_fav_icon) {
          window.document.querySelector('link[rel=icon]').href =
            this.registry.custom_fav_icon_url
        } else {
          window.document.querySelector('link[rel=icon]').href =
            '/bizzdome-fav.svg'
        }
      })
      .catch(() => {
        this.$router.push({ name: 'not_found' })
      })
  },
}
</script>

<style></style>
