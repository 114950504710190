import { LeaderboardService } from '@/services/LeaderboardService'
import { LeaderboardStatsService } from '@/services/LeaderboardStatsService'
import { formErrorsHandler } from '@/utils/formErrorsHandler'

// state
const initState = () => {
  return {
    show: {
      isBeingCopied: false,
      isLoading: false,
      data: {
        boardable: {},
      },
    },
  }
}

// getters
const getters = {}

// actions
const actions = {
  index(_, payload) {
    return LeaderboardService.index(payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  indexStats(_, payload) {
    return LeaderboardStatsService.index(payload)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  show({ commit }, { id, loading = false, appLoading = false }) {
    if (loading) {
      commit('setShow', { isLoading: true })
    }
    if (appLoading) {
      commit('setAppIsLoading', true, { root: true })
    }
    return LeaderboardService.show(id)
      .then(({ data: { data } }) => {
        commit('setShow', { data })
        // dispatch('setUpdateBroadcastFromDB', { event_broadcast })
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setShow', { isLoading: false })
        commit('setAppIsLoading', false, { root: true })
      })
  },
  showUser(_, { id, userId, params }) {
    return LeaderboardService.showUser(id, userId, params)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  store({ commit }, { payload }) {
    commit('setAppIsLoading', true, { root: true })
    return LeaderboardService.store(payload)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  storeAll({ commit }, { id, payload }) {
    commit('setAppIsLoading', true, { root: true })
    return LeaderboardService.storeAll(id, payload)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  update({ commit, state }, { payload }) {
    commit('setAppIsLoading', true, { root: true })
    return LeaderboardService.update(state.show.data.id, payload)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  destroy({ commit }, { id = '' }) {
    commit('setAppIsLoading', true, { root: true })
    return LeaderboardService.destroy(id)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
}

// mutations
const mutations = {
  setShow(state, obj) {
    Object.keys(obj).forEach((k) => (state.show[k] = obj[k]))
  },
}

export const Leaderboards = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
}
