<template>
  <div v-ripple class="tw-rounded-2xl tw-shadow-lg">
    <div
      class="flex tw-relative tw-h-full tw-w-full tw-cursor-pointer tw-items-center tw-rounded-2xl tw-border-2 tw-border-solid tw-bg-white tw-transition-all"
      :class="[borderColor]"
      @click="input_value = radioValue"
    >
      <div class="px-4 pb-0 pt-1 tw-flex tw-h-full tw-w-full tw-flex-col">
        <v-radio-group v-model="input_value">
          <v-radio
            :value="radioValue"
            light
            content-class="tw-items-start"
            class="tw-items-start"
          >
            <template #label>
              <div class="tw-flex tw-flex-col">
                <div
                  class="tw-text-xl tw-font-bold tw-leading-7 tw-text-gray-900"
                >
                  {{ label }}
                  <slot />
                </div>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    radioValue: {
      type: String,
      default: '',
    },
  },
  computed: {
    borderColor() {
      return this.value == this.radioValue
        ? 'tw-border-primary-500'
        : 'tw-border-gray-300'
    },
    input_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>

<style></style>
