/**
 * @returns String
 */
export const tenancy = () => {
  const tenant = process.env.VUE_APP_DOMAIN_OVERRIDE
  if (tenant) {
    return tenant
  }
  let parts = location.hostname.split('.')
  return parts.shift()
}
