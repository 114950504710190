<template>
    <div>
        <v-form
            ref="form_reset_password"
            v-model="valid"
            aria-autocomplete="off"
            @submit.prevent="submitVerification"
        >
            <!-- ------------- Message ------------- -->
            <p class="tw-text-sm tw-text-gray-400 tw-px-4">{{ $t('app.text_reset_password') }}</p>
            <!-- ------------- Password ------------- -->
            <v-text-field
                v-model="password_reset"
                :label="$t('forms.password')"
                :rules="[rules.required]"
                outlined
                :append-icon="show_password_reset ? 'visibility_off' : 'visibility'"
                :type="show_password_reset ? 'text' : 'password'"
                validate-on-blur
                rounded
                @click:append="show_password_reset = !show_password_reset"
            />
            <!-- ------------- Confirmation password ------------- -->
            <v-text-field
                v-model="password_confirmation_reset"
                :label="$t('forms.confirm_password')"
                :append-icon="show_password_confirmation_reset ? 'visibility_off' : 'visibility'"
                :type="show_password_confirmation_reset ? 'text' : 'password'"
                :rules="[rules.required, passwordMatch]"
                outlined
                validate-on-blur
                required
                rounded
                @click:append="show_password_confirmation_reset = !show_password_confirmation_reset"
            />
            <!-- ------------- Button Sign-In ------------- -->
            <v-btn 
              :disabled="loading_reset" :loading="loading_reset" color="primary" type="submit" rounded block
              class="tw-py-6 tw-mt-6 tw-text-sm tw-font-semibold">
              {{ $t('app.change_password') }}
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import { rules } from '@/utils/rules'
export default {
    props: {
        token: {
            type: String,
            default: '',
        },
        email: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        rules,
        valid: true,
        loading_reset: false,
        password_reset: '',
        password_confirmation_reset: '',
        show_password_reset: false,
        show_password_confirmation_reset: false,
    }),
    methods: {
        passwordMatch() {
            return this.password === this.password_confirmation
                ? true
                : this.$t(`app.password_notmatch`)
        },
        submitVerification() {
            if (!this.$refs.form_reset_password.validate()) {
                return
            }
            this.loading_reset = true 
            this.$store.dispatch('password/resetPassword', {
                token: this.token,
                email: this.email,
                password: this.password_reset,
                password_confirmation: this.password_confirmation_reset,
            }).then((res) => {
                if (res.data) {
                    this.$emit('alert-snackbar',{status:true,color:'success',msg:res.data.status})
                    setTimeout(() => {
                        this.loading_reset = false
                        this.$emit('page-form',{f1:true,f2:false,f3:false,f4:false})
                    }, 3000)
                    return
                }
                if (res.response.status) {
                    if (res.response.status == 422) {
                        this.loading_reset = false
                        this.$emit('page-form',{f1:false,f2:false,f3:false,f4:true})
                        this.$emit('alert-snackbar',{status:true,color:'red',msg:res.response.data.errors.password[0]})
                        return
                    }
                }
            })
        },
    },
}
</script>

<style>
</style>