import $axios from '@/axios-silent.js'

export default class CalendarRequestClass {
  baseUrl = '/api/v1/admin/calendars'

  // Create
  requestIndex(params) {
    return $axios.get(`${this.baseUrl}/requests`, params)
  }
  receivedIndex(params) {
    return $axios.get(`${this.baseUrl}/received`, params)
  }
  receivedAccepted(params) {
    return $axios.post(`${this.baseUrl}/accepted`, params)
  }
}
export const CalendarRequestService = new CalendarRequestClass()
