import gql from 'graphql-tag'

// profiles [forms{}]
export const CORE_USER_FIELDS = gql`
  fragment CoreUserFields on User {
    id
    email
    first_name
    last_name
    avatar
    is_online
    uuid
  }
`
export const CorePrivateChannels = gql`
  fragment CorePrivateChannels on Participant {
    message_count
    channel_id
    user {
      uuid
    }
  }
`
