<template>
  <v-col class="tw-h-full">
    <div class="tw-flex tw-h-full tw-items-center tw-justify-center">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      />
    </div>
  </v-col>
</template>

<script>
export default {}
</script>
