export const element_icons = {
  image_item: require('@/assets/editor-icons/ico-01.svg'),
  doorway_item: require('@/assets/editor-icons/ico-02.svg'),
  map_item: require('@/assets/editor-icons/ico-04.svg'),
  info_item: require('@/assets/editor-icons/ico-03.svg'),
  slider_item: require('@/assets/editor-icons/ico-05.svg'),
  video_item: require('@/assets/editor-icons/ico-06.svg'),
  content_item: require('@/assets/editor-icons/ico-09.svg'),
  rrss_item: require('@/assets/editor-icons/ico-07.svg'),
  chat_item: require('@/assets/editor-icons/ico-03.svg'),
  iframe_item: require('@/assets/editor-icons/ico-08.svg'),
  click_item: require('@/assets/editor-icons/ico-10.svg'),
  text_item: require('@/assets/editor-icons/ico-11.svg'),
}
