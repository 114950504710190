import $axios from '@/axios-instance.js'

export class ContentLibraryFoldersService {
  baseUrl = '/api/v1/admin/content-libraries'
  child = 'folders'
  static make() {
    return new ContentLibraryFoldersService()
  }
  // Create
  store(uuid, params) {
    return $axios.post(`${this.baseUrl}/${uuid}/${this.child}`, params)
  }

  // Read all - findAll
  index(uuid, params) {
    return $axios.get(`${this.baseUrl}/${uuid}/${this.child}`, params)
  }

  // Read one - show
  show(id, params) {
    return $axios.get(this.baseUrl + `/${id}`, params)
  }

  // Update
  update(uuid, id, params) {
    return $axios.post(`${this.baseUrl}/${uuid}/${this.child}/${id}`, {
      ...params,
      _method: 'PUT',
    })
  }

  // Delete - delete
  destroy(id, params) {
    return $axios.delete(this.baseUrl + `/${id}`, params)
  }
}
