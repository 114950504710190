<template>
  <v-container
    id="photobooth_gallery"
    class="fill-height tw-flex tw-items-start tw-overflow-y-scroll tw-bg-gray-100"
    fluid
  >
    <v-row class="mb-5">
      <v-col cols="12" sm="12" class="mx-auto pa-5">
        <v-card class="tw-border-transparent tw-bg-transparent tw-shadow-none">
          <v-container class="tw-overflow-hidden tw-p-0" fluid>
            <v-data-iterator
              :footer-props="footer_props"
              :items="formatedRows"
              :options.sync="options"
              :search="search"
              :loading="loading"
              v-bind="$attrs"
            >
              <template #default="props">
                <ul
                  role="list"
                  class="tw-grid tw-grid-cols-2 tw-gap-x-4 tw-gap-y-8 tw-px-4 tw-py-4 sm:tw-grid-cols-3 sm:tw-gap-x-6 sm:tw-px-6 md:tw-grid-cols-4 lg:tw-grid-cols-3 xl:tw-grid-cols-4 xl:tw-gap-x-8"
                >
                  <li
                    v-for="(file, indexFile) in props.items"
                    :key="file.id"
                    class="tw-relative"
                  >
                    <button
                      v-ripple
                      type="button"
                      class="group tw-aspect-w-1 tw-aspect-h-1 tw-block tw-w-full tw-overflow-hidden tw-rounded-xl tw-bg-gray-100 tw-transition focus-within:tw-ring-2 focus-within:tw-ring-indigo-500 focus-within:tw-ring-offset-2 focus-within:tw-ring-offset-gray-100"
                      @click="showMultiple(indexFile)"
                    >
                      <img
                        :src="file.image_url"
                        alt=""
                        :class="[
                          file.current ? '' : 'group-hover:tw-opacity-75',
                          'tw-pointer-events-none tw-object-cover',
                        ]"
                      />
                      <div class="focus:outline-none tw-absolute tw-inset-0">
                        <span class="tw-sr-only">
                          View details for {{ file.image_url }}
                        </span>
                      </div>
                    </button>
                    <p
                      class="tw-pointer-events-none tw-mt-2 tw-mb-0 tw-block tw-truncate tw-text-sm tw-font-medium tw-text-gray-900"
                    >
                      {{ file.user.first_name }}
                      {{ file.user.last_name }}
                    </p>
                    <p
                      class="tw-pointer-events-none tw-mb-0 tw-block tw-text-sm tw-font-medium tw-text-gray-500"
                    >
                      {{ file.time_since_created_at }}
                    </p>
                  </li>
                </ul>
              </template>
              <template #loading>
                <div class="tw-py-20">
                  <div
                    class="tw-flex tw-h-full tw-items-center tw-justify-center"
                  >
                    <v-progress-circular
                      :size="70"
                      :width="7"
                      color="primary"
                      indeterminate
                    />
                  </div>
                </div>
              </template>
              <template #no-data>
                <div class="tw-py-20">
                  <div
                    class="tw-flex tw-h-full tw-items-center tw-justify-center"
                  >
                    {{ $t('$vuetify.noDataText') }}
                  </div>
                </div>
              </template>
            </v-data-iterator>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <vue-easy-lightbox
      esc-disabled
      move-disabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    />
  </v-container>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import { formatBytes } from '@/utils/formatBytes'
import { commonTimeFormat, diffForHumans } from '@/utils/dateHelpers'
import { mapState } from 'vuex'
export default {
  components: {
    VueEasyLightbox,
  },
  data: () => ({
    search: '',
    rows: [],
    loading: false,
    options: {},
    totalRows: 0,
    form: {
      search_enum: 1,
      filter_by: 'first_name',
      filter_type: 'all',
    },
    imgs: '', // Img Url , string or Array of string
    visible: false,
    index: 0,
  }),
  computed: {
    footer_props() {
      const obj = {
        'items-per-page-options': [10, 20, 30, 40, 50],
      }
      return obj
    },
    formatedRows() {
      return this.rows.map((row) => {
        return {
          ...row,
          file_name: row.image_url.split('\\').pop().split('/').pop(),
          file_size: formatBytes(row.media.size),
          created_at: commonTimeFormat(row.created_at),
          time_since_created_at: diffForHumans(row.created_at),
        }
      })
    },
    ...mapState('event', {
      registry: (state) => state.constants.registry,
      photobooth: (state) => state.photobooth,
    }),
  },
  watch: {
    options: {
      handler() {
        this.getRows()
      },
      deep: true,
    },
  },
  methods: {
    showMultiple(index) {
      this.imgs = this.rows.map(({ image_url }) => image_url)
      this.index = index // index of imgList
      this.show()
    },
    show() {
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    getRows() {
      // this.loading = true;
      this.loading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      this.$store
        .dispatch('event/indexPhotoboothConfigGallery', {
          // registry_id: this.registry.id,
          photo_booth_config: this.photobooth.photobooth_config_id,
          body: {
            params: {
              page: page,
              rows_per_page: itemsPerPage,
              sort_by: sortBy[0],
              sort_desc_or_asc: sortDesc[0]
                ? sortDesc[0]
                  ? 'desc'
                  : 'asc'
                : 'desc',
              search: this.search,
              ...this.form,
            },
          },
        })
        .then((res) => {
          const { total, data } = res.data.data
          console.log(data)
          this.rows = data
          this.totalRows = total
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style></style>
