<template>
  <div class="tw-flex tw-h-full tw-flex-col tw-bg-white">
    <main class="tw-flex-1">
      <!-- Logo cloud section -->
      <div class="tw-py-32">
        <div
          class="tw-mx-auto tw-max-w-md tw-px-4 sm:tw-max-w-3xl sm:tw-px-6 lg:tw-max-w-3xl lg:tw-px-8"
        >
          <div
            class="lg:tw-grid lg:tw-grid-cols-1 lg:tw-items-center lg:tw-gap-24"
          >
            <div>
              <v-img
                :src="require('@/assets/logo-bizzdome.svg')"
                contain
                class="tw-h-24"
              >
                <template #placeholder>
                  <v-row
                    class="fill-height ma-0 transparent"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      size="32"
                      indeterminate
                      color="primary"
                    />
                  </v-row>
                </template>
              </v-img>
            </div>
            <div
              class="tw-mt-12 tw-grid tw-grid-cols-2 tw-gap-0.5 md:tw-grid-cols-3 lg:tw-mt-0 lg:tw-grid-cols-2"
            >
              <div
                class="tw-col-span-2 tw-flex tw-justify-center tw-flex-col tw-items-center tw-bg-gray-50 tw-py-8 tw-px-8 md:tw-col-span-3 lg:tw-col-span-2"
              >
                <h2
                  class="tw-text-3xl tw-font-extrabold tw-tracking-tight tw-text-gray-900 sm:tw-text-4xl"
                >
                  {{ $t('pages.registry.index.header') }}
                </h2>
                <v-alert
                  v-if="activeAlert"
                  color="red"
                  dense
                  text
                  type="warning"
                  class="tw-mt-4 tw-rounded-lg"
                >
                  {{ $t('app.registry_inactive_desc') }}
                </v-alert>
              </div>
              <div
                v-for="{ route, uuid, langs, isAvailable } in registries"
                :key="uuid"
                :class="langs[current_language_key] && isAvailable ? '' : 'tw-hidden'"
              >
                <template v-if="langs[current_language_key]">
                  <div class="tw-h-full tw-w-full tw-group focus-within:ring-primary-500 tw-relative tw-flex tw-justify-center tw-bg-gray-50 focus-within:tw-ring-2 focus-within:tw-ring-inset hover:tw-bg-gray-100">  
                    <router-link
                      v-ripple
                      :to="route"
                      class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-py-8 tw-px-8 focus:tw-outline-none"
                    >
                        <img 
                          :src="langs[current_language_key].logo_url_sm 
                          ? langs[current_language_key].logo_url_sm 
                          : require('@/assets/icon-bizzdome.svg')" 
                          class="tw-max-h-12" 
                          alt="Bizz Dome Event"
                        />
                    </router-link>
                  </div>
                </template>
              </div>
              <div class="tw-col-span-2 tw-flex tw-w-full tw-justify-end tw-py-8">
                <div>
                  <LocaleSwitcher />
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </main>

    <!-- Footer section -->
    <footer class="tw-mt-24 tw-bg-primary-900 sm:tw-mt-12">
      <div
        class="tw-mx-auto tw-max-w-md tw-overflow-hidden tw-py-12 tw-px-4 sm:tw-max-w-3xl sm:tw-px-6 lg:tw-max-w-7xl lg:tw-px-8"
      >
        <p class="tw-mt-8 tw-text-center tw-text-base tw-text-gray-400">
          &copy; {{ year }} BIZZEVENTS, All rights reserved.
        </p>
        <p class="tw-mt-8 tw-text-center tw-text-base tw-text-gray-400">
          <router-link place="link" to="/auth/login">
            Admin portal
          </router-link>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
// const testing = [
//   'https://tailwindui.com/img/logos/transistor-logo-gray-400.svg',
//   'https://tailwindui.com/img/logos/mirage-logo-gray-400.svg',
//   'https://tailwindui.com/img/logos/tuple-logo-gray-400.svg',
//   'https://tailwindui.com/img/logos/laravel-logo-gray-400.svg',
//   'https://tailwindui.com/img/logos/statickit-logo-gray-400.svg',
//   'https://tailwindui.com/img/logos/workcation-logo-gray-400.svg',
// ]

import LocaleSwitcher from '@/components/LocaleSwitcher.vue'
import { LanguageValues } from '@/enums/LanguageKeys'
import { mapState } from 'vuex'
import { isHappening } from '@/utils/dateHelpers'
export default {
  components: {
    LocaleSwitcher,
  },
  data: () => ({
    registries: [],
    isLoading: false,
    year: new Date().getFullYear(),
    activeAlert: false,
  }),
  computed: {
    ...mapState({
      current_language_key: (state) => state.registry.show.current_language_key,
      alert_event_inactive: (state) => state.registry.toAlert.isActive,
    }),
  },
  watch: {
    activeAlert(new_val) {
      if (new_val) {
          setTimeout(() => {
            this.$store.commit('registry/setAlert', {isActive:false})
            this.activeAlert = this.alert_event_inactive
          }, 6000)
      }
    },
  },
  mounted() {
    this.activeAlert = this.alert_event_inactive
    this.$store.dispatch('registry/index').then((res) => {
      /**
       * {
       *  slug: '',
       *    lang: {
       *      [key]: { ...landing}
       *    }
       * }
       */
      this.registries = res.registries.map((row) => ({
        ...row,
        route: {
          name: 'registry.home',
          params: { slug: row.slug },
        },
        isAvailable: row.start_date_time
          ? isHappening({
              start_date_time: row.start_date_time,
              end_date_time: row.end_date_time,
            })
          : false,
        langs: row.landings.filter((landing) => {
          if (row.is_only_active_for_default_lang != false) {
            return landing.language_id == row.language_id
          }
          return landing
        }).reduce((acc, curr) => {
          return {
            ...acc,
            [LanguageValues[curr.language_id]]: curr,
          }
        }, {}),
      }))
      // this.registries = res.registries.map((row) => ({
      //   ...row,
      //   route: {
      //     name: 'registry.home',
      //     params: { slug: row.slug },
      //   },
      //   langs: row.landings.reduce((acc, curr) => {
      //     return {
      //       ...acc,
      //       [LanguageValues[curr.language_id]]: curr,
      //     }
      //   }, {}),
      // }))
    })
  },
}
</script>

<style></style>
