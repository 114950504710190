<template>
  <renderless-layout>
    <v-container fluid class="tw-p-0">
      <StickyHeader :nav="nav" />
      <v-row class="tw-m-0">
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="11"
          xl="9"
          class="mx-auto tw-container"
        >
          <v-container fluid class="sm:py-3">
            <RegistriesAccessControlForm
              v-model="form"
              :error-alert.sync="errorAlert"
              :error-msg="errorMsg"
              :is-loading="isLoading"
              @submit-form="submitEvent"
            />
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </renderless-layout>
</template>

<script>
import RegistriesAccessControlForm from './RegistriesAccessControlForm.vue'
import StickyHeader from '@/components/StickyHeader.vue'
// import RowSeparator from '@/components/RowSeparator'
// import FormDescription from '@/components/FormDescription'
import { rules } from '@/utils/rules'
import { mapState } from 'vuex'
import { AccessControlBase } from '@/models/AccessControl'
export default {
  components: {
    StickyHeader,
    // FormDescription,
    // RowSeparator,
    RegistriesAccessControlForm,
  },

  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
  },
  data: () => ({
    // fields
    isLoading: false,
    errorAlert: false,
    errorMsg: '',
    form: AccessControlBase({}),
    rules,
  }),
  computed: {
    nav() {
      return [
        {
          name: this.$t('admin.home.labels.events'),
          route: { name: 'admin.registries.index' },
        },
        {
          name: this.show.label,
          route: {
            name: 'admin.registries.show.home',
            params: { id: this.show.id },
          },
        },
        {
          name: this.$t('admin.events.items.labels.access_control'),
          route: {
            name: 'admin.configs.access-control',
            params: { id: this.show.id },
          },
        },
        {
          name: this.$t('admin.events.items.access_control.new_rule'),
          current: true,
          route: {},
        },
      ]
    },
    ...mapState({
      show: (state) => state.registries.show.data,
    }),
  },

  methods: {
    submitEvent(body) {
      // this.errorAlert = false
      // if (!this.$refs.form.validate()) {
      //   return
      // }
      this.isLoading = true
      this.form_disabled_state = true

      this.$store
        .dispatch('registries/accessControlStore', { payload: { ...body } })
        .then(() => {
          this.$router.push({
            name: 'admin.configs.access-control',
            params: { id: this.show.id },
          })
        })
        .catch((err) => {
          this.errorAlert = true
          this.errorMsg = err
        })
        .finally(() => {
          this.isLoading = false
          this.form_disabled_state = false
        })
    },
  },
}
</script>
