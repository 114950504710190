<template>
  <UserProfileTabWrapper>
    <div>
      <v-container class="tw-divide-y tw-divide-gray-200" fluid>
        <section class="tw-py-6 tw-px-4 sm:tw-p-6 lg:tw-pb-8">
          <div class="tw-mx-auto tw-max-w-3xl tw-divide-y tw-divide-gray-200">
            <div class="tw-space-y-1">
              <h3
                class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
              >
                {{ $t('pages.event.profile.labels.preferences') }}
              </h3>
              <pre class="tw-hidden tw-max-w-2xl tw-text-sm tw-text-gray-500">{{
                JSON.stringify(registry, null, 2)
              }}</pre>
            </div>

            <ul class="tw-mt-2 tw-divide-y tw-divide-gray-200 tw-pl-0">
              <li
                class="tw-items-center tw-py-4 sm:tw-flex sm:tw-justify-between"
              >
                <div class="tw-flex tw-flex-col">
                  <label
                    for="has_rep_badge_present"
                    class="
                      tw-mb-0
                      tw-cursor-pointer
                      tw-select-none
                      tw-text-sm
                      tw-font-medium
                      tw-text-gray-900
                    "
                  >
                    {{ $t('app.preferences_profile.title') }}
                  </label>
                  <p class="tw-mb-0 tw-text-sm tw-text-gray-500">
                    {{ $t('app.preferences_profile.description') }}
                  </p>
                </div>

                <div
                  class="
                    tw-mt-5
                    sm:tw-mt-0
                    sm:tw-ml-6
                    sm:tw-flex
                    sm:tw-flex-shrink-0
                    sm:tw-items-center
                  "
                >
                  <v-switch id="has_rep_badge_present" inset large />
                </div>
              </li>
            </ul>
          </div>
        </section>
      </v-container>
      <!-- Which registry - Language {Stats - Version} debug aid Sound -->
      <!-- <div class="tw-flow-root tw-mt-6">
        <div class="tw-p-16 tw-text-center">
          <h2 class="tw-mb-2 tw-font-semibold tw-text-gray-900">
            {{ $t('pages.event.profile.points.no_content_title') }}
          </h2>

          {{ $t('pages.event.profile.points.no_content_body') }}
        </div>
      </div> -->
    </div>
  </UserProfileTabWrapper>
</template>

<script>
import { mapState } from 'vuex'
import UserProfileTabWrapper from './UserProfileTabWrapper.vue'

export default {
  components: {
    UserProfileTabWrapper,
  },
  data: () => ({}),
  computed: {
    ...mapState({
      registry: (state) => state.event.constants.registry,
    }),
  },
}
</script>

<style></style>
