import Resource from './BaseResource'

class GlobalEventConfigResource extends Resource {
  /**
   * The constructor for the GlobalEventConfigResource.
   */
  constructor() {
    super('/api/v1/admin/global-event-configs')
  }
}

export default new GlobalEventConfigResource()
