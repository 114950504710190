<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="640px"
    content-class="tw-rounded-3xl tw-w-full "
  >
    <v-card class="tw-bottom-0 tw-rounded-3xl tw-border-0 tw-shadow-md">
      <v-card-text class="pt-2">
        <div class="tw-flex tw-flex-col tw-rounded-lg tw-p-8 sm:tw-flex-row">
          <div
            class="tw-mb-4 tw-inline-flex tw-h-16 tw-w-16 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-red-100 tw-text-red-500 sm:tw-mr-8 sm:tw-mb-0"
          >
            <svg viewBox="0 0 20 20" fill="currentColor" class="tw-h-8 tw-w-8">
              <path
                fill-rule="evenodd"
                d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="tw-flex-grow">
            <h2
              class="tw-title-font tw-mb-3 tw-text-lg tw-font-medium tw-text-gray-900"
            >
              {{ $t('app.license_expired_title') }}
            </h2>
            <p class="tw-max-w-xs tw-text-base tw-leading-relaxed">
              {{ $t('app.license_expired_body') }}
            </p>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { isPastDate } from '@/utils/dateHelpers'
import { mapState } from 'vuex'
export default {
  data: () => ({
    open: false,
  }),
  computed: {
    ...mapState('constants', {
      tenant: (state) => state.tenant,
    }),
    has_duration() {
      return this.tenant.has_duration
    },
  },
  watch: {
    has_duration: {
      handler(newValue) {
        if (newValue) {
          this.validateDuration()
        }
      },
      immediate: true,
    },
  },
  methods: {
    validateDuration() {
      let license_expired = isPastDate(this.tenant.end_date)
      if (license_expired) {
        this.open = true
      }
    },
  },
}
</script>

<style></style>
