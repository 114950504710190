<template>
  <v-container class="pa-5">
    <v-row v-if="canSwitchClickable">
      <v-col cols="12" class="pb-0">
        <h3
          id="renew-subscription-label"
          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
        >
          <!-- ¿Tiene horario de apertura y cierre? -->
          {{ $t('app.enable_cta') }}
        </h3>
        <div class="tw-mt-2 sm:tw-flex sm:tw-items-start sm:tw-justify-between">
          <div class="tw-max-w-xl tw-text-sm tw-text-gray-500">
            <p id="renew-description " class="tw-mb-0">
              {{ $t('app.cta_description') }}
            </p>
          </div>
          <div
            class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
          >
            <!-- Enabled: "tw-bg-indigo-600", Not Enabled: "tw-bg-gray-200" -->
            <v-switch
              v-model="form.has_clickable"
              inset
              large
              @change="inputOccurred"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="form.has_clickable">
      <v-col cols="12" sm="6" class="pb-0">
        <v-select
          v-model="form.click_action_type"
          :items="click_types"
          :label="$t('app.type')"
          validate-on-blur
          :rules="[rules.required]"
          outlined
          rounded
          @change="inputOccurred"
        />
      </v-col>
      <v-col cols="12" sm="6" class="pb-0">
        <VirtualRoomAutocomplete
          v-if="form.click_action_type == ClickableTypeKeys.link_location"
          v-model="form.link_location_virtual_room_id"
          :rules="[rules.required]"
          @change="inputOccurred"
        />
        <ContentLibraryElementsAutocomplete
          v-if="form.click_action_type == ClickableTypeKeys.link_content"
          v-model="form.link_content_element_id"
          :rules="[rules.required]"
          @change="inputOccurred"
        />
        <ThreadsAutocomplete
          v-if="form.click_action_type == ClickableTypeKeys.open_public_chat"
          v-model="form.link_thread_id"
          :rules="[rules.required]"
          @change="inputOccurred"
        />
          <!-- v-model="mountedRoomElement.has_channel_id" -->
        <ChannelsAutocomplete
          v-if="form.click_action_type == ClickableTypeKeys.open_channel"
          v-model="form.link_channel_id"
          @change="inputOccurred"
        />
        <v-text-field
          v-if="form.click_action_type == ClickableTypeKeys.open_external_url"
          v-model="form.external_url_link"
          rounded
          outlined
          validate-on-blur
          label="Url"
          :rules="[rules.required]"
          @input="inputOccurred"
        />
        <PhotoboothConfigsAutocomplete
          v-if="form.click_action_type == ClickableTypeKeys.open_photobooth"
          v-model="form.photobooth_config_id"
          :rules="[rules.required]"
          @change="inputOccurred"
        />
        <UsersAutocompleteAll
          v-if="form.click_action_type == ClickableTypeKeys.open_user_chat"
          v-model="form.user_id"
          :multiple="false"
          filter-name="representative"
          :label="$t('admin.app.select_users')"
          @change="inputOccurred"
        />
        <CustomFormsAutocomplete 
          v-if="form.click_action_type == ClickableTypeKeys.custom_form"
          v-model="form.form_id"
          @change="inputOccurred"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VirtualRoomAutocomplete from '@/components/VirtualRoomAutocomplete.vue'
import ContentLibraryElementsAutocomplete from '@/components/ContentLibraryElementsAutocomplete.vue'
import ThreadsAutocomplete from '@/components/ThreadsAutocomplete.vue'
import UsersAutocompleteAll from '@/components/UsersAutocompleteAll.vue'
import ChannelsAutocomplete from '@/components/ChannelsAutocomplete.vue'
import CustomFormsAutocomplete from '@/components/CustomFormsAutocomplete.vue'

import { mapGetters } from 'vuex'
import { rules } from '@/utils/rules'
import { ClickableBase } from '@/models/Clickable'
import { ClickableTypeKeys } from '@/enums/ClickableTypeKeys'
import PhotoboothConfigsAutocomplete from '../PhotoboothConfigsAutocomplete.vue'

export default {
  components: {
    VirtualRoomAutocomplete,
    ContentLibraryElementsAutocomplete,
    ThreadsAutocomplete,
    PhotoboothConfigsAutocomplete,
    UsersAutocompleteAll,
    ChannelsAutocomplete,
    CustomFormsAutocomplete,
  },

  props: {
    clickable: {
      type: Object,
      default: () => ClickableBase({}),
    },
    canSwitchClickable: {
      type: Boolean,
      default: true,
    },
  },
  data: (vm) => ({
    rules,
    hasMounted: false,
    ClickableTypeKeys,
    form: ClickableBase(vm.clickable),
  }),
  computed: {
    ...mapGetters({
      click_types: ['constants/click_types'],
    }),
  },
  mounted() {
    if (this.canSwitchClickable) {
      return
    }
    this.form.has_clickable = true
    this.hasMounted = true
  },
  methods: {
    inputOccurred() {
      console.log('check')
      this.$emit('updated-clickable', this.form)
    },
  },
}
</script>

<style></style>
