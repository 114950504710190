<template>
  <v-overlay :absolute="true" :value="value" :z-index="40" opacity="1">
    <div class="tw-h-screen tw-flex tw-items-center tw-flex-col tw-justify-center">
      <img class="bz-logo-flip" :src="require('@/assets/logo-bizzdome-blanco.svg')" alt="Bizz Dome Event" />
      <svg
      xmlns="http://www.w3.org/2000/svg"
      class="tw-mx-auto tw-h-12 tw-w-12 tw-text-gray-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      >
      <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
      />
    </svg>
    <p class="tw-mt-4 tw-mb-0 tw-text-center tw-text-md">{{ $t('app.flip') }}</p>
    <p class="tw-mt-4 tw-mb-0 tw-text-center tw-w-80 tw-text-gray-400 tw-text-sm">{{ $t('app.flip_alert') }}</p>
    <div class="bz-separate-2"/>
    <p class="tw-text-center tw-text-gray-400 tw-text-md">{{ virtual_room.label }}</p>
    </div>
  </v-overlay>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      virtual_room: (state) => state.event.show.data,
    }),
  },
}
</script>

<style>
.bz-logo-flip {
    height: 3.4rem;
    position: absolute;
    top: 2rem;
}
.bz-separate-2 {
  width: 76%;
    height: 0.5px;
    background: #555555;
    margin: 1rem;
   opacity: 0.4;
}
</style>
