import gql from 'graphql-tag'
export const newQuestionAnswer = gql`
  subscription newQuestionAnswer($thread_id: Int!) {
    newQuestionAnswer(id: $thread_id) {
      body
      is_public
      id
      reply_to_id
      created_at
      uuid
      user {
        id
        first_name
        last_name
        uuid
        avatar
      }
    }
  }
`

export const refreshFullThread = gql`
  subscription refreshFullThread($thread_id: Int!) {
    refreshFullThread(id: $thread_id) {
      uuid
      id
      question_answer {
        id
        is_public
        body
        reply_to_id
        created_at
        uuid
        user {
          id
          first_name
          last_name
          uuid
          avatar
        }
      }
    }
  }
`
