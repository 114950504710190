import Resource from './BaseResource'

class EventRegistryResource extends Resource {
  /**
   * The constructor for the EventRegistryResource.
   */
  constructor() {
    super('/api/v1/event/registries')
  }
  Attendees(registryId) {
    this.child = `${registryId}/users`
    return this
  }
  AttendeesHybrid(registryId) {
    this.child = `${registryId}/users-hybrid`
    return this
  }
  Logins(registryId, userUiid) {
    this.child = `${registryId}/users/${userUiid}/logins`
    return this
  }
  Checkins(registryId, attendeeId) {
    this.child = `${registryId}/attendees/${attendeeId}/check-ins`
    return this
  }
  CheckinsBz(registryId, uuid) {
    this.child = `${registryId}/attendees/${uuid}/checkin-bz`
    return this
  }
  Paying() {
    this.child = `paying`
    return this
  }
}

export default new EventRegistryResource()
