import $axios from '@/axios-instance.js'

class UserLoginsServiceClass {
  baseUrl = '/api/v1/admin/users'
  child = 'logins'

  // Read all - findAll
  index(user, params) {
    return $axios.get(`${this.baseUrl}/${user}/${this.child}`, params)
  }

  // Read one - show
  show(user, login, params) {
    return $axios.get(`${this.baseUrl}/${user}/${this.child}/${login}`, params)
  }
}
export const UserLoginsService = new UserLoginsServiceClass()
