<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="640px"
    content-class="tw-rounded-3xl tw-w-full"
  >
    <v-form
      ref="form"
      v-model="valid"
      :disabled="form_disabled_state"
      lazy-validation
      enctype="multipart/form-data"
      @submit.prevent="update"
    >
      <v-card class="tw-rounded-3xl tw-border-0 tw-shadow-md">
        <ModalHeader @close-modal="closeModal">
          {{ $t('pages.event.profile.profile.reset_password') }}
        </ModalHeader>
        <v-divider />
        <v-card-text
          class="tw-relative tw-overflow-y-scroll tw-p-0"
          style="max-height: 700px"
        >
          <v-responsive class="tw-relative tw-py-0">
            <v-container class="tw-bg-white">
              <v-row class="mb-5">
                <v-col cols="12" class="mx-auto pa-5">
                  <v-card
                    class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-border-0 tw-bg-transparent tw-shadow-none"
                  >
                    <v-container class="pa-5">
                      <v-row>
                        <v-col cols="12" sm="12" class="pb-0">
                          <v-text-field
                            v-model="password"
                            label="Contraseña"
                            :rules="[rules.required]"
                            outlined
                            :append-icon="
                              show_password ? 'visibility_off' : 'visibility'
                            "
                            :type="show_password ? 'text' : 'password'"
                            validate-on-blur
                            rounded
                            @click:append="show_password = !show_password"
                          />
                        </v-col>
                        <v-col cols="12" sm="12" class="pb-0">
                          <v-text-field
                            v-model="password_confirmation"
                            :label="$t('forms.confirm_password')"
                            :append-icon="
                              show_password_confirmation
                                ? 'visibility_off'
                                : 'visibility'
                            "
                            :type="
                              show_password_confirmation ? 'text' : 'password'
                            "
                            :rules="[rules.required, passwordMatch]"
                            outlined
                            validate-on-blur
                            required
                            rounded
                            @click:append="
                              show_password_confirmation =
                                !show_password_confirmation
                            "
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-responsive>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-alert
            v-model="errorAlert"
            dismissible
            color="red"
            border="left"
            elevation="2"
            colored-border
            icon="error"
            transition="fade-transition"
          >
            {{ errorMsg }}
          </v-alert>
          <v-spacer />
          <AppBtn
            type="button"
            :disabled="form_loading"
            color="transparent"
            @click="closeModal"
          >
            {{ $t('app.cancel') }}
          </AppBtn>
          <AppBtn
            type="submit"
            :loading="form_loading"
            color="primary"
            icon="check"
          >
            {{ $t('app.save') }}
          </AppBtn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ModalHeader from '@/components/ModalHeader'
import { rules } from '@/utils/rules'
import AppBtn from '../App/AppBtn.vue'

export default {
  components: {
    ModalHeader,
    AppBtn,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    currentFormUuid: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    password: '',
    password_confirmation: '',
    show_password: false,
    show_password_confirmation: false,
    // fields end
    errorAlert: false,
    errorMsg: '',
    rules,
    isLoading: false,
    form_loading: false,
    form_disabled_state: false,
    valid: true,
    isLoadingRooms: false,
  }),
  computed: {
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },

  methods: {
    passwordMatch() {
      return this.password === this.password_confirmation
        ? true
        : 'Contraseña no es igual'
    },
    closeModal() {
      this.open = false
    },
    update() {
      this.errorAlert = false
      if (!this.$refs.form.validate()) {
        return
      }
      this.form_loading = true
      this.form_disabled_state = true
      const payload = {
        uuid: this.currentFormUuid,
        body: {
          password: this.password,
          password_confirmation: this.password_confirmation,
        },
      }
      this.$store
        .dispatch('event/updatePasswordProfile', payload)
        .then((res) => {
          console.log('after show', { res })
          this.open = false
        })
        .catch((err) => {
          this.errorAlert = true
          this.errorMsg = err
        })
        .finally(() => {
          this.form_disabled_state = false
          this.form_loading = false
        })
    },
  },
}
</script>

<style></style>
