<template>
  <!-- TODO: RegistryTicket: Select field for the classes -->
  <v-autocomplete
    v-model="input_value"
    :items="tickets"
    item-text="ticket_label_key"
    item-value="id"
    :label="$t('admin.ticket.items.labels.label')"
    outlined
    rounded
    clearable
    hide-details
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    tickets: {
      type: [Object, Array],
      default: () => ({}),
    },
  },
  computed: {
    input_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>

<style></style>
