<template>
    <div class="tw-flex tw-flex-col tw-py-3 tw-px-4 bz-action-note" @click="showNote(note)">
        <span class="tw-text-xs tw-font-bold">{{ note.title }}</span>
        <span class="tw-text-[#949493] bz-txt-name">{{ note.date }} {{ note.description }}</span>
    </div>
</template>

<script>
export default {
    props: {
        note: {
            type: [Array, Object],
            default: () => ({}),
        },
    },
    methods: {
        showNote(note) {
            this.$emit('show-note', note)
        },
    },
}
</script>

<style scoped>
.bz-txt-name {
    font-size: 11px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: initial;
}
.bz-action-note {
    cursor: pointer;
}
.bz-action-note:hover {
    background: rgba(148, 148, 147, 0.05);
    transition: all .4s;
}
</style>