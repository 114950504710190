// import { ScheduledEmailBase } from '@/models/ScheduledEmail'
import { ScheduledEmailLogsService } from '@/services/ScheduledEmailLogsService'
import { ScheduledEmailsService } from '@/services/ScheduledEmailsService'
import { formErrorsHandler } from '@/utils/formErrorsHandler'
// state
const initState = () => {
  return {
    show: {
      isBeingCopied: false,
      isLoading: false,
      data: {
        scheduled_email_type: {},
        language: {},
        email: {},
      },
    },
  }
}

// getters
const getters = {}

// actions
const actions = {
  index(_, payload) {
    return ScheduledEmailsService.index(payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  indexLogs({ state }, payload) {
    const scheduled_email = state.show.data.id
    return ScheduledEmailLogsService.index(scheduled_email, payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  show({ commit }, { id, loading = false, appLoading = false }) {
    if (loading) {
      commit('setShow', { isLoading: true })
    }
    if (appLoading) {
      commit('setAppIsLoading', true, { root: true })
    }
    return ScheduledEmailsService.show(id)
      .then(({ data: { scheduled_email } }) => {
        commit('setShow', { data: scheduled_email })
        // dispatch('setUpdateBroadcastFromDB', { event_broadcast })
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setShow', { isLoading: false })
        commit('setAppIsLoading', false, { root: true })
      })
  },
  store({ commit }, { payload }) {
    commit('setAppIsLoading', true, { root: true })
    return ScheduledEmailsService.store(payload)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  update({ commit, state }, { payload }) {
    commit('setAppIsLoading', true, { root: true })
    return ScheduledEmailsService.update(state.show.data.id, payload)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  destroy({ commit }, { id = '' }) {
    commit('setAppIsLoading', true, { root: true })
    return ScheduledEmailsService.destroy(id)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
}

// mutations
const mutations = {
  setShow(state, obj) {
    Object.keys(obj).forEach((k) => (state.show[k] = obj[k]))
  },
}

export const ScheduledEmails = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
}
