import $axios from '@/axios-silent'

export default class quickNotesService {
    baseUrl = '/api/v1/event'

    store(params) {
        return $axios.post(`${this.baseUrl}/quick-note`, params)
    }

    delete(params) {
        return $axios.post(`${this.baseUrl}/quick-note-trash`, params)
    }
    
    highlight(params) {
        return $axios.post(`${this.baseUrl}/quick-note-highlight`, params)
    }

    email(params) {
        return $axios.post(`${this.baseUrl}/quick-note-email`, params)
    }
}

export const quickNotesServiceInstance = new quickNotesService()
