import $axios from '@/axios-instance.js'

class VirtualRoomRegistryRoomServiceClass {
  baseUrl = '/api/v1/admin/virtual-rooms'
  child = 'registry-room'

  // Create
  store(room_id, params) {
    return $axios.post(`${this.baseUrl}/${room_id}/${this.child}`, params)
  }
}
export const VirtualRoomRegistryRoomService = new VirtualRoomRegistryRoomServiceClass()
