<template>
  <div>
    <audio
      ref="audio"
      class="tw-hidden"
      :src="music_track_url"
      preload
      loop
      muted
    />
    <v-btn
      fab
      class="tw-pointer-events-auto tw-m-4 tw-h-14 tw-w-14 md:tw-h-16 md:tw-w-16"
      @click="toggleSound()"
    >
      <v-icon v-if="isPlaying" class="tw-text-4xl">pause</v-icon>
      <v-icon v-else class="tw-text-4xl">play_arrow</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    isPlaying: false,
  }),
  computed: {
    music_track_url() {
      return this.virtual_room.music_track_url
    },
    hasMusic() {
      return this.virtual_room.has_music_track
    },
    ...mapState({
      virtual_room: (state) => state.event.show.data,
    }),
  },
  mounted() {
    this.toggleSound()
  },

  methods: {
    toggleSound() {
      let audio = this.$refs.audio
      if (
        audio.paused
        // document.querySelector('.toggle-sound').classList.contains('paused')
      ) {
        this.isPlaying = true
        console.log('play it')
        audio.play()
        // document.querySelector('.toggle-sound').classList.remove('paused')
      } else {
        this.isPlaying = false
        console.log('pause it')
        audio.pause()
        // document.querySelector('.toggle-sound').classList.add('paused')
      }
    },
  },
  // watch: {
  //   hasMusic: {
  //     handler(val) {
  //       if (val) {
  //         this.toggleSound()
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
}
</script>

<style></style>
