import $axios from '@/axios-instance.js'

class RegistriesCalendarsServiceClass {
  baseUrl = '/api/v1/admin/registries'
  child = 'calendars'

  // index
  index(registry, params) {
    return $axios.get(`${this.baseUrl}/${registry}/${this.child}`, params)
  }
  // Create
  store(registry, params) {
    return $axios.post(`${this.baseUrl}/${registry}/${this.child}`, params)
  }
}
export const RegistriesCalendarsService = new RegistriesCalendarsServiceClass()
