<template>
  <renderless-layout>
    <v-container fluid class="tw-p-0">
      <StickyHeader :nav="nav">
        <template #actions>
          <div class="tw-flex tw-items-center tw-space-x-4">
            <AppBtn
              icon="cog"
              color="transparent-dark"
              @click="openCredentialSettings"
            >
              {{ $t('app.config') }}
            </AppBtn>
            <AppBtn
              color="white"
              type="button"
              icon="pencil_edit"
              @click="css_modal_toggle = true"
            >
              <!-- {{ $t('app.edit') }} -->
              CSS
            </AppBtn>
            <AppBtn icon="check" color="white" type="button" @click="save">
              {{ $t('app.save') }}
            </AppBtn>
          </div>
        </template>
      </StickyHeader>
      <v-row class="tw-m-0 tw-p-0">
        <v-col cols="12" class="mx-auto tw-container tw-max-w-7xl">
          <!-- Clases para css -->
          <v-container class="tw-m-0 tw-py-0">
            <v-chip class="tw-mr-2" color="primary" label text-color="white" small>
              <v-icon class="tw-text-sm" left>label</v-icon>
              .bz-name
            </v-chip>
            <v-chip class="tw-mr-2" color="primary" label text-color="white" small>
              <v-icon class="tw-text-sm" left>label</v-icon>
              .bz-company
            </v-chip>
            <v-chip class="tw-mr-2" color="primary" label text-color="white" small>
              <v-icon class="tw-text-sm" left>label</v-icon>
              .bz-email
            </v-chip>
            <v-chip class="tw-mr-2" color="primary" label text-color="white" small>
              <v-icon class="tw-text-sm" left>label</v-icon>
              .bz-category
            </v-chip>
          </v-container>
          <v-container fluid class="tw-space-y-6">
            <v-row>
              <v-col cols="12" md="9">
                <!-- Potocheck -->
                <v-card class="tw-shadow-md tw-mb-6">
                  <v-container
                    class="tw-h-2 tw-bg-primary-500 tw-px-3 tw-py-0"
                  />
                  <div class="pa-4">
                    <CredentialCard
                      :config="data"
                      :user-form="data.user_form"
                      :checkin-user="user"
                    />
                  </div>
                </v-card>
                <!-- Setting Fields -->
                <v-card class="tw-shadow-md tw-mb-6">
                  <v-container
                    class="tw-h-2 tw-bg-primary-500 tw-px-3 tw-py-0"
                  />
                  <div class="pa-4">
                    <div class="tw-mb-1 tw-font-bold">
                      {{ $t('admin.events.hybrid.items.labels.user_data') }}
                    </div>
                    <v-row>
                      <v-col
                        v-for="field in fields"
                        :key="field"
                        cols="12"
                        lg="4"
                      >
                        <v-switch
                          v-model="data.user_form[`${field}__is_active`]"
                          dense
                          inset
                          :label="`${field}`"
                          hide-details
                          class="pb-2"
                        />
                        <transition name="fade-transition" mode="out-in">
                          <div
                            v-if="data.user_form[`${field}__is_active`]"
                            class="
                              tw-mt-1
                              tw-mb-6
                              tw-rounded-lg
                              tw-bg-gray-100
                              tw-px-4
                              tw-pt-6
                            "
                          >
                            <v-row>
                              <v-col cols="4">
                                <v-text-field
                                  v-model.number="data.user_form[`${field}__fontsize`]"
                                  type="number"
                                  :label="$t('admin.events.hybrid.items.labels.font_size')"
                                  class="tw-mb-2"
                                  hide-details
                                  suffix="px"
                                />
                              </v-col>
                              <v-col cols="8">
                                <color-input
                                  v-model="data.user_form[`${field}__color`]"
                                  :is-outlined="false"
                                  :is-rounded="false"
                                  :is-filled="false"
                                />
                              </v-col>
                            </v-row>
                          </div>
                        </transition>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <!-- Setting Photocheck -->
                <v-card class="tw-shadow-md tw-mb-6">
                  <v-container
                    class="tw-h-2 tw-bg-primary-500 tw-px-3 tw-py-0"
                  />
                  <div class="pa-4">
                    <div class="tw-mb-1 tw-font-bold">
                      {{ $t('admin.events.hybrid.items.labels.photocheck') }}
                    </div>
                    <v-row>
                      <v-col cols="7">
                        <v-select
                          v-model="data.orientation"
                          :items="orientations"
                          :label="
                            $t('admin.events.hybrid.items.labels.orientation')
                          "
                          filled
                          dense
                          hide-details
                        />
                      </v-col>
                      <v-col cols="5">
                        <v-text-field
                          v-model.number="data.qr_size"
                          :label="$t('admin.events.hybrid.items.labels.qr')"
                          type="number"
                          filled
                          dense
                          hide-details
                          suffix="px"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model.number="data.width"
                          :label="$t('admin.events.hybrid.items.labels.width')"
                          type="number"
                          filled
                          dense
                          hide-details
                          suffix="px"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model.number="data.height"
                          :label="$t('admin.events.hybrid.items.labels.height')"
                          type="number"
                          filled
                          dense
                          hide-details
                          suffix="px"
                        />
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
                <!-- Setting Background -->
                <v-card class="tw-shadow-md tw-mb-6">
                  <v-container
                    class="tw-h-2 tw-bg-primary-500 tw-px-3 tw-py-0"
                  />
                  <div class="pa-4">
                    <div class="tw-mb-1 tw-font-bold">
                      {{ $t('admin.events.hybrid.items.labels.background') }}
                    </div>
                    <v-row>
                      <v-col cols="12">
                        <ColorInput
                          v-model="data.ui_background"
                          filled
                          :is-outlined="false"
                          :is-rounded="false"
                          :is-filled="true"
                        />
                        <DropzoneOverlayPreview
                          v-model="data.url_background"
                          bypass="simple-check"
                          media-group="fondo_credential_hybrid"
                          :width="validWidth"
                          :height="validHeight"
                        />
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
                <!-- Setting Logo -->
                <v-card class="tw-shadow-md tw-mb-6">
                  <v-container
                    class="tw-h-2 tw-bg-primary-500 tw-px-3 tw-py-0"
                  />
                  <div class="pa-4">
                    <div class="tw-mb-1 tw-font-bold">
                      {{ $t('admin.events.hybrid.items.labels.logo') }}
                    </div>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model.number="data.logo_size"
                          :label="$t('admin.events.hybrid.items.labels.size')"
                          type="number"
                          filled
                          dense
                          hide-details
                          suffix="px"
                          class="tw-mb-2"
                        />
                        <DropzoneOverlayPreview
                          v-model="data.url_logo"
                          bypass="simple-check"
                          :width="200"
                          :height="200"
                          media-group="logo_credential_hybrid"
                        />
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <CSSEditorModal
        v-model="css_modal_toggle"
        :custom-css.sync="data.custom_css"
      />
      <CredentialSettingsModal v-model="credentialSettingsModal"/>
    </v-container>
  </renderless-layout>
</template>

<script>
import StickyHeader from '@/components/StickyHeader.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import ColorInput from '@/components/FormInputs/ColorInput'
import CredentialCard from '@/components/Event/CredentialCard.vue'
import DropzoneOverlayPreview from '@/components/DropzoneOverlayPreview'
import { mapState } from 'vuex'
import { CredentialHydrate, parseValidKeys } from '@/models/Credential'
import CredentialResource from '@/services/CredentialResource'
import CSSEditorModal from '@/components/CSSEditorModal.vue'
import CredentialSettingsModal from '@/components/CredentialsModals/CredentialSettingsModal.vue'

export default {
  components: {
    StickyHeader,
    AppBtn,
    CredentialCard,
    ColorInput,
    DropzoneOverlayPreview,
    CSSEditorModal,
    CredentialSettingsModal,
  },
  data: () => ({
    fields: [],
    data: {},
    orientations: [
      {
        text: 'Vertical',
        value: 1,
      },
      {
        text: 'Horizontal',
        value: 2,
      },
    ],
    css_modal_toggle: false,
    credentialSettingsModal: false,
  }),
  computed: {
    nav() {
      return [
        {
          name: this.$t('app.event'),
          route: {
            name: 'admin.registries.index',
          },
        },
        {
          name: this.registry.label,
          route: {
            name: 'admin.registries.show.home',
            params: {
              id: this.registry.id,
            },
          },
        },
        {
          name: this.$t('admin.events.items.labels.credentials'),
          route: {},
        },
      ]
    },
    ...mapState({
      registry: (state) => state.registries.show.data,
      user: (state) => state.session.user,
    }),
    validWidth() {
      if (!this.data.width) {
        return 0
      }
      return this.data.width
    },
    validHeight() {
      if (!this.data.height) {
        return 0
      }
      return this.data.height
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.fields = Object.keys(parseValidKeys(this.registry.forms))
      this.data = CredentialHydrate(
        this.registry.credential,
        this.registry.forms,
      )
    },
    save() {
      this.$store.commit('setAppIsLoading', true, { root: true })
      CredentialResource.update(
        this.registry.id,
        CredentialHydrate(this.data, this.registry.forms),
      )
        .then(({ data: { credential } }) => {
          const updatedRegistry = { ...this.registry, credential }
          this.$store.commit('registries/setShow', { data: updatedRegistry })
        })
        .finally(() => {
          this.$store.commit('setAppIsLoading', false, { root: true })
        })
    },
    openCredentialSettings() {
      console.log('Credential Settings')
      this.credentialSettingsModal = true
    },
  },
}
</script>

<style>
</style>
