<template>
  <renderless-layout>
    <v-container fluid class="tw-p-0">
      <StickyHeader :nav="nav">
        <template #actions>
          <div class="tw-flex tw-items-center tw-space-x-3">
            <AppBtn color="transparent-dark" icon="close" @click="deleteEvent">
              {{ $t('app.delete') }}
            </AppBtn>
            <AppBtn
              color="primary-light"
              g-next="DocumentDuplicate"
              icon="g-next"
              @click="copyNotification"
            >
              {{ $t('app.copy') }}
            </AppBtn>
          </div>
        </template>
      </StickyHeader>
      <v-row>
        <v-col cols="12" sm="12" class="mx-auto tw-container tw-max-w-7xl">
          <v-container fluid class="tw-py-6">
            <ConfigEventBroadcastsForm
              v-model="form"
              :error-alert.sync="errorAlert"
              :error-msg="errorMsg"
              :is-loading="isLoading"
              @submit-form="submitEvent"
            />
          </v-container>
        </v-col>
      </v-row>
      <AppConfirmDialog ref="deleteModal" />
    </v-container>
  </renderless-layout>
</template>

<script>
import ConfigEventBroadcastsForm from './ConfigEventBroadcastsForm.vue'
import StickyHeader from '@/components/StickyHeader.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import AppConfirmDialog from '@/components/App/AppConfirmDialog.vue'

import { mapState } from 'vuex'
import {
  EventBroadcastBase,
  EventBroadcastHydrate,
} from '@/models/EventBroadcast'

export default {
  components: {
    AppBtn,
    AppConfirmDialog,
    StickyHeader,
    ConfigEventBroadcastsForm,
  },
  data: () => ({
    // fields
    toggle: true,
    isLoading: false,
    errorAlert: false,
    errorMsg: '',
    form: EventBroadcastBase({ duration: 0 }),
  }),
  computed: {
    nav() {
      return [
        {
          name: this.$t('admin.app.general_config'),
          route: {
            name: 'admin.configs.home',
          },
        },
        {
          name: this.$t('admin.events.items.labels.notifications'),
          route: {
            name: 'admin.configs.event-broadcasts.index',
          },
        },
        {
          name: this.$t('app.edit'),
          route: {},
        },
      ]
    },
    ...mapState({
      broadcast: (state) => state.event_config.broadcasts.show.data,
    }),
  },
  mounted() {
    this.form = EventBroadcastHydrate(this.broadcast)
    console.log({ ...this.form })
  },
  methods: {
    copyNotification() {
      this.$store.commit('event_config/setBroadcastShow', {
        isBeingCopied: true,
      })
      this.$router.push({ name: 'admin.configs.event-broadcasts.create' })
    },
    async deleteEvent() {
      const has_accepted = await this.$refs.deleteModal.open(
        'Eliminar',
        'Notificacion sera eliminada',
        {
          color: 'red',
        },
      )
      if (has_accepted) {
        this.$store
          .dispatch('event_config/broadcastDestroy', { id: this.broadcast.id })
          .then(() => {
            this.$router.push({ name: 'admin.configs.event-broadcasts.index' })
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    submitEvent() {
      this.isLoading = true

      this.$store
        .dispatch('event_config/broadcastUpdate', { payload: { ...this.form } })
        .then(() => {
          this.$router.push({ name: 'admin.configs.event-broadcasts.index' })
        })
        .catch((err) => {
          this.errorAlert = true
          this.errorMsg = err
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style></style>
