<template>
  <UserProfileTabWrapper>
    <div>
      <div class="tw-mt-6 tw-flow-root">
        <ul
          v-if="bookmarked.length > 0"
          class="tw--my-5 tw-divide-y tw-divide-gray-200 tw-pl-0"
        >
          <li
            v-for="favorite in bookmarked"
            :key="favorite.uuid"
            class="tw-py-4 tw-px-5"
          >
            <div class="tw-flex tw-items-center tw-space-x-4">
              <!-- <div class="tw-flex-shrink-0">
                <img
                  class="tw-h-8 tw-w-8 tw-rounded-full"
                  :src="person.imageUrl"
                  alt=""
                />
              </div> -->
              <div class="tw-min-w-0 tw-flex-1">
                <p
                  class="tw-mb-0 tw-truncate tw-text-lg tw-font-medium tw-text-gray-900"
                >
                  {{ favorite.label }}
                </p>
                <p class="tw-mb-0 tw-truncate tw-text-sm tw-text-gray-500">
                  {{ favorite.room_type.label }}
                </p>
              </div>
              <div>
                <div
                  class="tw-space-x tw-flex tw-divide-x-2 tw-divide-solid tw-divide-gray-200"
                >
                  <v-btn text @click="viewItem(favorite)">{{
                    $t('app.view')
                  }}</v-btn>
                  <v-btn text @click="deleteFavorite(favorite)">{{
                    $t('app.delete')
                  }}</v-btn>
                </div>
                <!-- <a
                  href="#"
                  class="
                    tw-inline-flex
                    tw-items-center
                    tw-shadow-sm
                    tw-px-2.5
                    tw-py-0.5
                    tw-border
                    tw-border-gray-300
                    tw-text-sm
                    tw-leading-5
                    tw-font-medium
                    tw-rounded-full
                    tw-text-gray-700
                    tw-bg-white
                    hover:tw-bg-gray-50
                  "
                >
                  View
                </a> -->
              </div>
            </div>
          </li>
        </ul>

        <div v-else class="tw-p-16 tw-text-center">
          <h2 class="tw-mb-2 tw-font-semibold tw-text-gray-900">
            {{ $t('pages.event.profile.saved.no_content_title') }}
          </h2>

          {{ $t('pages.event.profile.saved.no_content_body') }}
        </div>
      </div>

      <v-dialog
        v-model="confirmDelete"
        persistent
        width="400"
        content-class="tw-self-end tw-rounded-3xl sm:tw-self-auto"
      >
        <v-card dark class="taypa-radius tw-shadow-md">
          <v-card-text class="pt-2 taypa-radius">
            <h1 class="text-center mb-2 pt-5 pb-5 tw-text-2xl">
              {{ $t('app.are_you_sure') }}
            </h1>
            <p class="text-center tw-text-lg">
              {{ $t('pages.event.profile.saved.warning') }}
            </p>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-container class="tw-px-0 tw-py-2">
              <div
                class="tw-flex tw-flex-col tw-justify-center sm:tw-flex-row sm:tw-space-x-3"
              >
                <v-btn text :disabled="loading" @click="cancelDeleteAttendee">
                  {{ $t('app.cancel') }}
                </v-btn>
                <v-btn
                  outlined
                  color="error"
                  :disabled="loading"
                  :loading="loading"
                  class="focus:tw-outline-none focus:tw-ring focus:tw-ring-red-300"
                  @click="deleteAttendeeConfirmed"
                >
                  <v-icon left>close</v-icon>{{ $t('app.delete') }}
                </v-btn>
              </div>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </UserProfileTabWrapper>
</template>

<script>
import { mapState } from 'vuex'
import UserProfileTabWrapper from './UserProfileTabWrapper.vue'

export default {
  components: {
    UserProfileTabWrapper,
  },
  data: () => ({
    loading: false,
    removeFavoriteId: null,
    confirmDelete: false,
  }),
  computed: {
    ...mapState({
      favorites: (state) => state.event.constants.favorites,
      virtual_rooms: (state) => state.event.constants.virtual_rooms,
      virtual_room: (state) => state.event.show.data,
    }),

    isRoomFavorite() {
      return this.favorites.map((row) => row.virtual_room_id)
    },
    bookmarked() {
      return this.virtual_rooms.filter((row) =>
        this.isRoomFavorite.includes(row.id),
      )
    },
  },
  methods: {
    viewItem(favorite) {
      if (favorite.slug === this.virtual_room.slug) {
        return
      }
      this.$router.push({
        name: 'event.route',
        params: {
          slug: favorite.slug,
        },
      })
      this.$store.commit('event/setProfile', {
        modal: false,
      })
    },

    deleteAttendeeConfirmed() {
      this.loading = true
      this.$store
        .dispatch('event/delteFavorite', {
          favorite_id: this.removeFavoriteId,
        })
        .then(() => {
          this.removeFavoriteId = null
          // this.$store.dispatch('notifications/addNotification', {
          //   title: 'success eliminando favorito',
          //   body: 'err',
          // })
        })
        .catch((err) => {
          this.$store.dispatch('notifications/addNotification', {
            title: 'Error eliminando favorito',
            body: err,
          })
        })
        .finally(() => {
          this.confirmDelete = false
          this.loading = false
        })
    },
    cancelDeleteAttendee() {
      this.removeFavoriteId = null
      this.confirmDelete = false
    },
    deleteFavorite(favorite) {
      this.removeFavoriteId = this.favorites.find(
        (row) => row.virtual_room_id == favorite.id,
      ).id
      this.confirmDelete = true
    },
  },
}
</script>

<style></style>
