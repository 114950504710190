import i18n from '@/i18n'
import { arrayHasDuplicates } from './arrayHasDuplicates'

export const rules = {
  domain: (v) =>
    /^(?:(?:(?:[a-zA-z-]+):\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?::[0-9]{1,5})?$/.test(
      v,
    ) || 'Dominio no valido',
  url: (v) =>
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
      v,
    ) || 'Url no valido',
  urlSecured: (v) =>
    /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
      v,
    ) || 'Url no valido',
  urlVimeo: (v) =>
    /https:\/\/(www\.)?vimeo.com\/(event\/)?[0-9]{4,}/.test(v) ||
    'Url de vimeo, verifica el formato, https://vimeo.com/ mas el numero del video',

  // email: () => "The email and password you entered don't match",
  email: (v) => /.+@.+/.test(v) || 'E-mail no es valido',
  slug: (v) =>
    /^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(v) ||
    'No puede tener caracteres especiales',
  required: (value) => !!value || i18n.t('rules.required'),
  array_not_empty: (value) => {
    console.log(value)
    return value.length !== 0 || i18n.t('rules.required')
  },
  commaSeparatedEmails: (value) =>
    /^([\w+-.%]+@[\w-.]+\.[A-Za-z]+)(, ?[\w+-.%]+@[\w-.]+\.[A-Za-z]+)*$/.test(
      value,
    ) || 'Ingresa un listado de emails valido',
  uniqueCommaSeparatedItems: (value) => {
    if (!value) {
      return false
    }
    const arr = value.split(',').map((row) => row.trim())
    return (
      !arrayHasDuplicates(arr) || 'Lista de emails no puede tener repetidos'
    )
  },
  number: (value) => Number.isInteger(value) || i18n.t('rules.required'),
  min: (v) => v.length >= 8 || 'Min 8 characters',
  phone: (v) =>
    /^([0|[0-9]{1,5})?([0-9]{10})$/.test(v) || i18n.t('rules.phone'),
}
