import { LicenseService } from '@/services/LicenseService'
import { formErrorsHandler } from '@/utils/formErrorsHandler'
// state
const initState = () => {
  return {
    show: {
      isBeingCopied: false,
      isLoading: false,
      data: {
        participants: [],
        users: {},
        virtual_rooms: {},
        emails_sent: {},
        license: '',
        has_exceeded: false,
        has_video_chat: false,
      },
    },
  }
}

// getters
const getters = {}

// actions
const actions = {
  show({ commit }, { loading = false, appLoading = false }) {
    if (loading) {
      commit('setShow', { isLoading: true })
    }
    if (appLoading) {
      commit('setAppIsLoading', true, { root: true })
    }
    return LicenseService.execute()
      .then(({ data }) => {
        commit('setShow', { data })
        // dispatch('setUpdateBroadcastFromDB', { event_broadcast })
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setShow', { isLoading: false })
        commit('setAppIsLoading', false, { root: true })
      })
  },
}

// mutations
const mutations = {
  setShow(state, obj) {
    Object.keys(obj).forEach((k) => (state.show[k] = obj[k]))
  },
}

export const License = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
}
