export const VideoKeys = {
  uploaded: 'uploaded',
  youtube: 'youtube',
  vimeo: 'vimeo',
  element: 'element',
}
// {
//   1: 'es',
//   2: 'en',
// }

export const VideoValues = Object.keys(VideoKeys).reduce(
  (acc, row, currentIndex) => ({
    ...acc,
    [currentIndex + 1]: row,
  }),
  {},
)
export const VideoValuesInverse = Object.keys(VideoKeys).reduce(
  (acc, row, currentIndex) => ({
    ...acc,
    [row]: currentIndex + 1,
  }),
  {},
)
