import Vue from 'vue'

Vue.filter('m_d_y', function (item) {
  if (!item) {
    return
  }
  let arr = item.split(/[- :]/)
  const monthNames = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Dicienbre',
  ]
  // let dateObj = new Date(item);
  let dateObj = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
  let month = monthNames[dateObj.getMonth()]
  let day = String(dateObj.getDate()).padStart(2, '0')
  let year = dateObj.getFullYear()
  let output = day + '\n' + month + ', ' + year

  return output
})
Vue.filter('time_stamp_m_d_y', function (item) {
  if (!item) {
    return
  }
  const monthNames = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Dicienbre',
  ]
  let dateObj = new Date(item)
  let month = monthNames[dateObj.getMonth()]
  let day = String(dateObj.getDate()).padStart(2, '0')
  let year = dateObj.getFullYear()
  let output = day + '\n' + month + ', ' + year

  return output
})

Vue.filter('hh_mm', function (item) {
  if (!item) {
    return
  }
  let arr = item.split(/[- :]/)
  var date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
  var hours = date.getHours()
  var minutes = date.getMinutes()

  // Check whether AM or PM
  var newformat = hours >= 12 ? 'PM' : 'AM'

  // Find current hour in AM-PM Format
  hours = hours % 12

  // To display "0" as "12"
  hours = hours ? hours : 12
  minutes = minutes < 10 ? '0' + minutes : minutes

  return hours + ':' + minutes + ' ' + newformat
})
Vue.filter('time_stamp_hh_mm', function (item) {
  if (!item) {
    return
  }
  var date = new Date(item)
  var hours = date.getHours()
  var minutes = date.getMinutes()

  // Check whether AM or PM
  var newformat = hours >= 12 ? 'PM' : 'AM'

  // Find current hour in AM-PM Format
  hours = hours % 12

  // To display "0" as "12"
  hours = hours ? hours : 12
  minutes = minutes < 10 ? '0' + minutes : minutes

  return hours + ':' + minutes + ' ' + newformat
})

Vue.filter('in_or_ago', function (time) {
  var datetime = new Date(time).getTime()
  var now = new Date().getTime()

  if (isNaN(datetime)) {
    return ' on ' + time
  }
  let indi
  if (datetime < now) {
    indi = 'En:'
    ;('En: ')
  } else {
    indi = 'Hace:'
  }
  return indi
})
Vue.filter('get_time_diff', function (time) {
  var datetime = new Date(time).getTime()
  var now = new Date().getTime()

  if (isNaN(datetime)) {
    return ' on ' + time
  }
  let milisec_diff
  if (datetime < now) {
    milisec_diff = now - datetime
  } else {
    milisec_diff = datetime - now
  }

  var days = Math.floor(milisec_diff / 1000 / 60 / (60 * 24))
  var hours = Math.floor(milisec_diff / (1000 * 60 * 60) - days * 24)
  var minutes = Math.floor(
    milisec_diff / (1000 * 60) - days * 24 * 60 - hours * 60,
  )

  var respvalue = ''

  if (days > 0) {
    respvalue += days + ' dia(s), '
  }

  if (hours > 0) {
    respvalue += hours + ' hora(s) y '
  }

  respvalue += minutes + ' minuto(s)'

  return respvalue
})
Vue.filter('to_decimal', function (num) {
  return (num / 100).toFixed(2)
})
