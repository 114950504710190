import $axios from '@/axios-instance.js'

export default class VirtualRoomsService {
  baseUrl = '/api/v1/admin/virtual-rooms'

  // Create
  store(params) {
    return $axios.post(this.baseUrl, params)
  }

  /**
   * Read all - findAll.
   *
   * @example
   * ..{
   *   params: {
   *    ID: 12345
   *  }
   * }
   *
   */
  index(params) {
    return $axios.get(this.baseUrl, params)
  }
  searchAsYouType(params) {
    return $axios.get(this.baseUrl + '/search', params)
  }

  // Read one - show
  show(id, params) {
    return $axios.get(this.baseUrl + `/${id}`, params)
  }

  // Update
  update(id, params) {
    return $axios.post(this.baseUrl + `/${id}`, params)
  }

  // Delete - delete
  destroy(id, params) {
    return $axios.post(this.baseUrl + `/${id}`, params)
  }
}
export const VirtualRoomsServiceInstance = new VirtualRoomsService()
