import { CoreVitalService } from '@/services/CoreVitalService'
import { ValidateUrlService } from '@/services/ValidateUrlService'
import { formErrorsHandler } from '@/utils/formErrorsHandler'
import { simpleSound } from '@/utils/simpleSound'
// state
const initState = () => {
  return {
    core_vital: {
      event_container_init: false,
    },
  }
}

// getters
const getters = {}

// actions
const actions = {
  validateUrl(_, { form = null }) {
    return ValidateUrlService.execute(form)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  dispatchBasicSound() {
    simpleSound()
  },
  recordCoreVital(
    { rootGetters },
    {
      payload = {
        /**
     * core_vital_type
      device_width
      device_height
      virtual_room_id
     */
      },
      core_vital_type,
    },
  ) {
    // event_init
    // if(core_vital_type)
    // event_navigation
    CoreVitalService.execute({
      ...rootGetters['event/plainMetricsPayload'],
      ...payload,
    })
      .then((res) => res)
      .catch((err) => console.log(err))
      .finally(() => {
        // some sort of commit that tells the application that we have recorded a core vital
        console.log(`core vital finalized: ${core_vital_type}`)
      })
  },
  recordCoreVitalV2(
    { rootState },
    {
      payload = {
        /**
     * core_vital_type
      device_width
      device_height
      virtual_room_id
     */
      },
      core_vital_type,
    },
  ) {
    console.log(core_vital_type)
    // event_init
    // if(core_vital_type)
    // event_navigation
    const body = {
      ...payload,
      core_vital_type,
      // simple_log: this.nav_count,
      nav_count: rootState.event.constants.nav_count,
      uuid_tracker: rootState.event.constants.uuid_tracker,
      device_width: window.innerWidth,
      device_height: window.innerHeight,
      room_width: rootState.event.constants.room_width,
      room_height: rootState.event.constants.room_height,
      virtual_room_id: rootState.event.show.data.id,
    }

    CoreVitalService.execute(body)
      .then((res) => res)
      .catch((err) => console.log(err))
      .finally(() => {
        // some sort of commit that tells the application that we have recorded a core vital
        console.log('core vital finalized')
      })
  },
}

// mutations
const mutations = {}

export const General = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
}
