import { myTz } from '@/utils/myTz'

import axios from 'axios'
import store from './store'
const $axios = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_LARAVEL_BACKEND_PROD
      : process.env.VUE_APP_LARAVEL_BACKEND_DEV,
})
$axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

$axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      // console.log(store);
      store.dispatch('session/axiosLogout')
    }
    return Promise.reject(error)
  },
)

$axios.interceptors.request.use((config) => {
  const token = store.getters['session/token']
  const jwt_token = store.getters['session/jwt_token']
  config.headers['X-LOCALE'] = store.getters['language/locale']
  config.headers['X-Tenant'] = store.state.tenant
  config.headers['X-TZ'] = myTz()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
    config.headers['X-JWT-ACCESS-TOKEN'] = `Bearer ${jwt_token}`
  }

  return config
})

export default $axios
