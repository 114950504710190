<template>
<v-dialog v-model="open" scrollable width="450px" content-class="bz2-scroll tw-rounded-xl">
    <v-card class="tw-relative tw-rounded-xl tw-border-0 tw-shadow-md">
        <v-card-title class="text-h6 tw-text-white tw-flex tw-justify-between" :style="{'background-color': detail.color}">
            {{ detail.name }}
            <div>
                <v-menu bottom origin="center center" transition="scale-transition">
                    <template #activator="{ on, attrs }">
                        <v-btn icon small color="white" v-bind="attrs" v-on="on" @click="otherCalendar(detail)">
                            <v-icon small>event</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(item, i) in calendar_normal" :key="i" :href="item" target="_blank">
                            <v-list-item-title class="tw-text-xs">
                                {{ $t('app.add_to') }} {{ i }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-for="(item, i) in calendar_ics" :key="i" @click="downloadToCal(item)">
                            <v-list-item-title class="tw-text-xs">
                                {{ $t('app.add_to') }} {{ i }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn v-if="detail.is_appointment" icon small color="white">
                    <v-icon small>edit</v-icon>
                </v-btn>
                <v-btn v-if="detail.is_appointment" icon small color="white" @click="deleteItem(detail)">
                    <v-icon small>delete</v-icon>
                </v-btn>
            </div>
        </v-card-title>
        <v-card-text class="tw-p-4 tw-bg-[#ffffff] tw-relative">
            <div class="tw-grid tw-gap-6">
                <!-- ------------------- Title Event ------------------- -->
                <div class="tw-flex tw-items-center">
                    <v-icon class="tw-text-gray-500" dense>event</v-icon>
                    <div class="tw-block tw-ml-4">
                        <p class="tw-text-xs tw-text-gray-500 tw-font-medium tw-m-0 tw-text-justify">
                            {{detail.start_detail}} - {{ detail.end_detail }}
                        </p>
                    </div>
                </div>
                <!-- ------------------- Details ------------------- -->
                <div class="tw-flex tw-items-start">
                    <v-icon class="tw-text-gray-500" dense>notes</v-icon>
                    <div class="tw-block tw-ml-4">
                        <p class="tw-text-xs tw-text-gray-500 tw-font-medium tw-m-0 tw-text-justify">
                            {{ detail.details }}
                        </p>
                    </div>
                </div>
                <!-- ------------------- Call ------------------- -->
                <div v-if="detail.is_appointment" class="tw-flex tw-items-center">
                    <v-icon class="tw-text-gray-500" dense>videocam</v-icon>
                    <div class="tw-block tw-ml-4">
                        <v-btn class="tw-normal-case tw-bg-primary-500 tw-text-white tw-font-semibold" elevation="0" :disabled="!toUserData.status_claim">
                            Unirse a la llamada
                        </v-btn>
                    </div>
                </div>          
                <!-- ------------------- Guests ------------------- -->
                <div v-if="detail.is_appointment" class="tw-flex tw-items-start">
                    <v-icon class="tw-mt-2 tw-text-gray-500" dense>group</v-icon>
                    <div class="tw-block tw-ml-4">
                        <span class="tw-text-xs tw-font-semibold">2 Invitados</span>
                        <!-- ------------------- Users ------------------- -->
                        <div class="tw-mt-2 tw-grid tw-gap-2">
                            <div class="tw-flex tw-items-center">
                                <v-avatar size="26">
                                    <v-img :src="byUser.avatar" :alt="byUser.first_name" />
                                </v-avatar>
                                <div class="tw-flex tw-flex-col tw-ml-2 ">
                                    <span class="tw-text-md tw-font-semibold">{{ byUser.email }}</span>
                                    <span class="tw-text-xs tw-font-medium tw-text-gray-400">Organizador</span>
                                </div>
                            </div>
                            <div class="tw-flex tw-items-center">
                                <v-avatar size="26">
                                    <v-img :src="toUserData.user.avatar" />
                                </v-avatar>
                                <div class="tw-flex tw-flex-col tw-ml-2 ">
                                    <span class="tw-text-md tw-font-semibold">{{toUserData.user.email}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>      
            </div>
        </v-card-text>
        <AppConfirmDialog ref="deleteModal" />
    </v-card>
</v-dialog>
</template>

<script>
import AppConfirmDialog from '@/components/App/AppConfirmDialog.vue'
import {
    eventModel,
} from '@/utils/eventToCalendar'
import {
    download,
} from '@/utils/download'
export default {
    name: 'EventCalendarLiveDetail',
    components: {AppConfirmDialog},
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        detail: {
            type: [Array, Object],
            default: () => ({}),
        },
        calendarId: {
            type: [Array, Object],
            default: () => ({}),
        },
    },
    data: () => ({
        calendar_normal: [],
        calendar_ics: [],
    }),
    computed: {
        open: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
        byUser() {
            return this.detail.user
        },
        toUserData() {
            return this.detail.registry_calendar_request
        },
    },
    mounted() {},
    methods: {
        closeModal() {
            this.open = false
        },
        otherCalendar(event) {
            const links = eventModel({
                name: event.name,
                details: event.details,
                startsAt: event.start_date_time,
                endsAt: event.end_date_time,
            })
            this.calendar_normal = {
                google: links.google,
                outlook: links.outlook,
                yahoo: links.yahoo,
            }
            this.calendar_ics = {
                icloud: links.ics,
            }
        },
        downloadToCal(file) {
            download(file, `${this.registry.label} icloud`)
        },
        async deleteItem(calendarItem) {
            var detail = `Eliminar ${calendarItem.label}` 
            const has_accepted = await this.$refs.deleteModal.open(
                'Eliminar',
                detail,
                {
                    color: 'red',
                },
            )
            if (has_accepted) {
                this.$store
                .dispatch('event/destroyCalendarItems', {
                    payload: {},
                    calendar: this.calendarId.id,
                    id: calendarItem.id,
                })
                .then(() => {
                    this.closeModal()
                })
                .finally(() => {
                    this.$emit('submit-delete')
                })
            }
        },
    },
}
</script>
