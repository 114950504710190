<template>
  <v-form
    ref="form"
    v-model="valid"
    :disabled="isLoading"
    lazy-validation
    enctype="multipart/form-data"
    @submit.prevent="submitEvent"
  >
    <v-row class="mb-5 tw-pt-6">
      <v-col cols="12" md="3" class="px-5 pt-5 mx-auto">
        <form-description>
          <template #title> {{ $t('admin.app.notification') }} </template>
          <template #copy>
            {{ $t('app.all_fields_required') }}
          </template>
        </form-description>
      </v-col>
      <v-col cols="12" md="9" class="mx-auto pa-5">
        <v-card
          class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-shadow-md"
        >
          <v-container class="pa-5">
            <div>
              <div class="tw-grid sm:tw-grid-cols-3">
                <div class="tw-col-span-2">
                  <v-select
                    v-model="form.language"
                    :items="languages"
                    :label="$t('app.language')"
                    validate-on-blur
                    :rules="[rules.required]"
                    outlined
                    rounded
                  />
                </div>
              </div>
              <ul class="tw-divide-y tw-divide-gray-200 tw-pl-0">
                <li class="tw-flex tw-items-center tw-justify-between">
                  <div class="tw-flex tw-flex-col">
                    <p
                      id="privacy-option-1-label"
                      class="tw-mb-0 tw-text-sm tw-font-medium tw-text-gray-900"
                    >
                      Status
                    </p>
                    <p
                      id="privacy-option-1-description"
                      class="tw-mb-0 tw-text-sm tw-text-gray-500"
                    >
                      {{ $t('admin.app.enable_notifications') }}
                    </p>
                  </div>
                  <div
                    class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
                  >
                    <v-switch v-model="form.is_active" inset large />
                  </div>
                </li>
              </ul>
            </div>
          </v-container>
          <v-container class="pa-5">
            <v-row>
              <v-col cols="12" sm="12" class="pb-0">
                <div class="tw-grid tw-grid-cols-2 tw-gap-4">
                  <v-select
                    v-model="form.event_broadcast_style_type"
                    :items="event_broadcast_style_types"
                    :label="$t('admin.app.style')"
                    validate-on-blur
                    :rules="[rules.required]"
                    outlined
                    rounded
                  />
                  <v-select
                    v-model="form.duration"
                    :items="DurationTypesConsts"
                    :label="$t('app.slide_duration')"
                    validate-on-blur
                    :rules="[rules.number]"
                    outlined
                    rounded
                  />
                </div>
              </v-col>
              <v-col cols="12" class="tw-py-0">
                <ul class="tw-divide-y tw-divide-gray-200 tw-pl-0">
                  <li class="tw-flex tw-items-center tw-justify-between">
                    <div class="tw-flex tw-flex-col">
                      <p
                        id="privacy-option-1-label"
                        class="tw-mb-0 tw-text-sm tw-font-medium tw-text-gray-900"
                      >
                        {{ $t('admin.app.sound') }}
                      </p>
                      <p
                        id="privacy-option-1-description"
                        class="tw-mb-0 tw-text-sm tw-text-gray-500"
                      >
                        <i18n path="admin.app.sound_text" tag="div">
                          <template #sound>
                            <button
                              v-ripple
                              class="tw-lowercase tw-underline focus:tw-outline-none focus:tw-ring"
                              type="button"
                              @click="testSound"
                            >
                              {{ $t('admin.app.sound') }}
                            </button>
                          </template>
                        </i18n>
                      </p>
                    </div>

                    <div
                      class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
                    >
                      <v-switch v-model="form.has_sound" inset large />
                    </div>
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-container>
          <v-container class="pa-5">
            <v-row v-if="isStyleOverlay">
              <v-col cols="12" sm="6" class="pb-0">
                <v-select
                  v-model="form.overlay_type"
                  :items="event_broadcast_overlay_styles_types"
                  :label="$t('admin.app.style')"
                  validate-on-blur
                  :rules="[rules.required]"
                  outlined
                  rounded
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="7" class="pb-0">
                <v-text-field
                  v-model="form.title"
                  :label="$t('app.title')"
                  validate-on-blur
                  :rules="[rules.required]"
                  outlined
                  rounded
                />
              </v-col>
              <v-col cols="12" sm="12" class="pb-0">
                <EditorTipTap
                  v-model="form.body"
                  :rules="[rules.required]"
                  :label="$t('app.description')"
                  outlined
                  rounded
                />
                <!-- <v-textarea
                  v-model="form.body"
                  validate-on-blur
                  :rules="[rules.required]"
                  :label="$t('app.description')"
                  outlined
                  rounded
                /> -->
              </v-col>
            </v-row>
            <v-row v-if="hasImage">
              <v-col cols="12" class="pb-0">
                <DropzoneOverlayPreview
                  v-model="form.overlay_image_url"
                  :width="650"
                  :height="650"
                  :bypass="'ratio-check'"
                  media-group="registries_landings"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-container class="pa-5">
            <v-row>
              <v-col cols="12" class="pb-0">
                <h3
                  class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                >
                  {{ $t('admin.app.dispatched_time') }}
                </h3>
                <div
                  class="tw-mt-2 sm:tw-flex sm:tw-items-start sm:tw-justify-between"
                >
                  <div class="tw-max-w-xl tw-text-sm tw-text-gray-500">
                    <p class="tw-mb-0">
                      {{ $t('admin.app.dispatched_description') }}
                    </p>
                  </div>
                  <div
                    class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
                  >
                    <v-switch v-model="form.has_emit_at" inset large />
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="form.has_emit_at" class="tw-mt-4">
              <v-col cols="12" sm="6" class="pb-0">
                <div class="tw-grid tw-grid-cols-2 tw-gap-4">
                  <div>
                    <DateInput v-model="form.emit_at_date" />
                  </div>
                  <div>
                    <v-text-field
                      v-model="form.emit_at_time"
                      :rules="[rules.required]"
                      :label="$t('app.hour')"
                      outlined
                      rounded
                      type="time"
                    />
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <TimezonesAutocomplete
                  v-model="form.emit_at_tz"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-container>
          <!-- v-model="form.clickable" -->
          <ClickableModelFormPartial v-model="form.clickable">
            <template #description>
              {{ $t('admin.app.action_partial_text') }}
            </template>
            <template #extra-fields>
              <v-row>
                <v-col cols="12" sm="7" class="pb-0">
                  <v-text-field
                    v-model="form.action_label"
                    :label="$t('admin.app.action_name')"
                    validate-on-blur
                    :rules="[rules.required]"
                    outlined
                    rounded
                  />
                </v-col>
              </v-row>
            </template>
          </ClickableModelFormPartial>
          <!-- Donovann -->
          <v-container class="pa-0">
            <ul class="tw-divide-y tw-divide-gray-200 tw-pl-0">
              <!-- Has Send Email Users Inactive -->
              <li class="tw-flex tw-items-center tw-justify-between pa-5">
                <div class="tw-flex tw-flex-col tw-pr-6">
                  <h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                    {{ $t('admin.app.has_send_email_user_inactive') }}
                  </h3>
                  <p class="tw-mb-0 tw-text-sm tw-text-gray-500">
                    {{ $t('admin.app.has_send_email_user_inactive_description') }}
                  </p>
                </div>
                <div class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center">
                  <v-switch v-model="form.has_email_user_inactive" inset large />
                </div>
              </li>
              <!-- Has Test -->
              <li class="tw-flex tw-items-center tw-justify-between pa-5">
                <div class="tw-flex tw-flex-col tw-pr-6">
                  <h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                    {{ $t('admin.app.has_test') }}
                  </h3>
                  <p class="tw-mb-0 tw-text-sm tw-text-gray-500">
                    {{ $t('admin.app.has_test_description') }}
                  </p>
                </div>
                <div class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center">
                  <v-switch v-model="form.has_test" inset large />
                </div>
              </li>
            </ul>
          </v-container>
          <!-- End Donovann -->
        </v-card>
      </v-col>
    </v-row>
    <row-separator />
    <v-row class="mb-5">
      <v-col cols="12" md="9" class="px-0 py-0 ml-auto">
        <v-card
          class="mx-auto pa-5 tw-rounded-3xl tw-border-transparent tw-bg-transparent tw-shadow-none"
        >
          <transition name="fade-transition" mode="out-in">
            <v-row v-if="errorAlertProxy">
              <v-col cols="12">
                <v-alert
                  v-model="errorAlertProxy"
                  dismissible
                  color="red"
                  border="left"
                  elevation="2"
                  colored-border
                  icon="error"
                  transition="fade-transition"
                >
                  {{ errorMsg }}
                </v-alert>
              </v-col>
            </v-row>
          </transition>
          <v-card-actions>
            <v-row>
              <v-col cols="12" class="pa-0">
                <div class="tw-flex tw-justify-end">
                  <AppBtn
                    icon="check"
                    color="primary"
                    :loading="isLoading"
                    type="submit"
                  >
                    {{ $t('app.save') }}
                  </AppBtn>
                </div>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { simpleSound } from '@/utils/simpleSound'

import DropzoneOverlayPreview from '@/components/DropzoneOverlayPreview.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import DateInput from '@/components/FormInputs/DateInput.vue'
import TimezonesAutocomplete from '@/components/TimezonesAutocomplete.vue'
import ClickableModelFormPartial from '@/components/FormPartials/ClickableModelFormPartial.vue'
import { DurationTypesConsts } from '@/constants/DurationTypesConsts'
import { rules } from '@/utils/rules'
import { EventBroadcastStyleKeys } from '@/enums/EventBroadcastStyleKeys'
import { EventBroadcastOverlayStyleKeys } from '@/enums/EventBroadcastOverlayStyleKeys'

import RowSeparator from '@/components/RowSeparator.vue'
import FormDescription from '@/components/FormDescription.vue'
import { mapGetters } from 'vuex'
import EditorTipTap from '@/components/FormInputs/EditorTipTap.vue'
export default {
  components: {
    AppBtn,
    DropzoneOverlayPreview,
    ClickableModelFormPartial,
    TimezonesAutocomplete,
    DateInput,
    FormDescription,
    RowSeparator,
    EditorTipTap,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
    errorAlert: {
      type: Boolean,
      required: false,
    },
    errorMsg: {
      type: String,
      required: '',
      default: '',
    },
  },
  data: () => ({
    EventBroadcastStyleKeys,
    DurationTypesConsts,
    valid: true,
    rules,
  }),
  computed: {
    hasTitleBody() {
      if (!this.isStyleOverlay) {
        return true
      }
      return (
        EventBroadcastOverlayStyleKeys.overlay_plain === this.form.overlay_type
      )
    },
    hasImage() {
      if (!this.isStyleOverlay) {
        return false
      }
      return (
        EventBroadcastOverlayStyleKeys.overlay_image === this.form.overlay_type
      )
    },
    isStyleOverlay() {
      return (
        EventBroadcastStyleKeys.overlay === this.form.event_broadcast_style_type
      )
    },
    ...mapGetters({
      languages: ['constants/languages'],
      event_broadcast_style_types: ['constants/event_broadcast_style_types'],
      event_broadcast_overlay_styles_types: [
        'constants/event_broadcast_overlay_styles_types',
      ],
    }),

    form: {
      get() {
        return new Proxy(this.value, {
          set: (obj, key, value) => {
            this.form = { ...obj, [key]: value }
            return true
          },
        })
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
    errorAlertProxy: {
      get() {
        return this.errorAlert
      },
      set(newValue) {
        this.$emit('update:errorAlert', newValue)
      },
    },
  },
  methods: {
    testSound() {
      simpleSound()
    },
    submitEvent() {
      this.errorAlertProxy = false
      if (!this.$refs.form.validate()) {
        return
      }
      this.$emit('submit-form', this.form)
    },
  },
}
</script>

<style></style>
