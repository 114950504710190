import $axios from '@/axios-instance.js'

class ActionMetricsServiceClass {
  baseUrl = '/api/v1/admin/action-metrics'
  static make() {
    return new ActionMetricsService()
  }
  // Rooms
  rooms(params) {
    return $axios.get(`${this.baseUrl}/rooms`, params)
  }
  contentLibraries(params) {
    return $axios.get(`${this.baseUrl}/content-libraries`, params)
  }
  devices(params) {
    return $axios.get(`${this.baseUrl}/devices`, params)
  }
  countries(params) {
    return $axios.get(`${this.baseUrl}/countries`, params)
  }
  sessions(params) {
    return $axios.get(`${this.baseUrl}/sessions`, params)
  }
  concurrency(params) {
    return $axios.get(`${this.baseUrl}/concurrency`, params)
  }
  // Donovann
  checkins(params) {
    return $axios.get(`${this.baseUrl}/checkins`, params)
  }
  // End Donovann
  logins(params) {
    return $axios.get(`${this.baseUrl}/logins`, params)
  }
  registrations(params) {
    return $axios.get(`${this.baseUrl}/registrations`, params)
  }
  channels(params) {
    return $axios.get(`${this.baseUrl}/channels`, params)
  }
  messages(params) {
    return $axios.get(`${this.baseUrl}/messages`, params)
  }
  realTime(params) {
    return $axios.get(`${this.baseUrl}/real-time`, params)
  }
  window_sizes(params) {
    return $axios.get(`${this.baseUrl}/window-sizes`, params)
  }

  roomsExport(params) {
    return $axios.get(`${this.baseUrl}/rooms/export`, {
      ...params,
      responseType: 'blob',
    })
  }
  dataExport(params) {
    return $axios.get(`${this.baseUrl}/data/export`, {
      ...params,
      responseType: 'blob',
    })
  }
}
export const ActionMetricsService = new ActionMetricsServiceClass()
