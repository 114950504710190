<template>
  <renderless-layout :full-screen="true">
    <!----------------------------------------  Base -------------------------------------- -->
    <div class="bz-login-body bz-vh tw-grid tw-grid-cols-1 lg:tw-grid-cols-2">
      <!-- ------------------- Login ------------------- -->
      <div class="bz-login-form">
        <div class="bz-form">
          <!-- ------------- Logo ------------- -->
          <router-link :to="toHome">
            <img aria-hidden="true" :src="loginLogo" class="bz-login-img-logo" />
          </router-link>
          <!-- ------------- Form Login ------------- -->
          <template v-if="form1">
            <div class="bz-camps">
              <v-form ref="form" v-model="valid" autocomplete="off" @submit.prevent="authenticate">
                <!-- ------------- Email ------------- -->
                <v-text-field 
                  ref="email" v-model="email" append-icon="alternate_email" name="email"
                  :label="$t('forms.email')" type="email" outlined validate-on-blur :rules="[rules.required, rules.email]"
                  required rounded hide-details="auto" color="primary" class="tw-text-sm" />
                <!-- ------------- Password ------------- -->
                <v-text-field
                  v-if="registry.has_password || require_password" ref="password" v-model.lazy="password"
                  :append-icon="show_password ? 'visibility' : 'visibility_off'" name="password"
                  :label="$t('forms.password')" :type="show_password ? 'text' : 'password'" outlined validate-on-blur
                  :rules="[rules.required]" required rounded hide-details="auto" color="primary" class="tw-mt-4 tw-text-sm"
                  @click:append="show_password = !show_password" />
                <!-- ------------- Redirect ForgotPasswrod ------------- -->
                <div v-if="registry.has_password" class="tw-flex tw-items-center tw-justify-end tw-my-2">
                  <a class="tw-text-sm tw-font-semibold" @click="pageForgotPassword">{{ $t('app.forgot_password')}}</a>
                </div>
                <!-- ------------- Button Sign-In ------------- -->
                <v-btn 
                  :disabled="loading" :loading="loading" color="primary" type="submit" rounded block
                  class="tw-py-6 tw-mt-6 tw-text-sm tw-font-semibold" :style="colorBtn">
                  {{ $t('app.login') }}
                </v-btn>
                <!-- ------------- Register ------------- -->
                <div v-if="canRegister" class="tw-flex tw-items-center tw-justify-center tw-mt-4">
                  <span class="tw-text-sm tw-font-semibold">
                    {{ $t('app.register_helper') }} <router-link :to="toRegister" :style="colorText">{{ $t('app.register_here')
                    }}</router-link>
                  </span>
                </div>
                <!-- ------------- Language ------------- -->
                <div v-if="canSwitch">
                  <div class="tw-absolute tw-top-4 tw-right-4 lg:tw-right-0">
                    <LocaleSwitcher />
                  </div>
                </div>
              </v-form>
              <!-- ------------- Support ------------- -->
              <template v-if="registry.has_support">
                <div class="bz-login-support">
                  <RegistrySupportText
                    class="tw-mb-0 tw-pt-4 tw-pb-4 tw-text-center tw-text-sm tw-leading-5 tw-text-gray-700"
                    :modsupport="true"
                  />
                </div>
              </template>
            </div>
          </template>
          <!-- ------------- Form Forgot Password ------------- -->
          <template v-if="form2">
            <div class="bz-camps">
              <FormForgotPassword @alert-snackbar="alertSnackbar" @data-form="dataFormForgot" @page-form="pageForm"/>
            </div>
          </template>
          <!-- ------------- Form Validate Code ------------- -->
          <template v-if="form3">
            <div class="bz-camps">
              <FormCodeVerification :code-form="code_verification" @alert-snackbar="alertSnackbar" @page-form="pageForm"/>
            </div>
          </template>
          <!-- ------------- Form Reset Password ------------- -->
          <template v-if="form4">
            <div class="bz-camps">
              <FormResetPassword :token="token_reset" :email="email_reset" @alert-snackbar="alertSnackbar" @page-form="pageForm"/>
            </div>
          </template>
        </div>
        <!-- ------------- Alert ------------- -->
        <v-snackbar v-model="alert" :color="alertColor">{{ authError }}</v-snackbar>
      </div>
      <!-- ------------------- Background ------------------- -->
      <div class="bz-login-bg tw-hidden lg:tw-block" :style="bgImg" />
    </div>
  </renderless-layout>
</template>

<script>
import LocaleSwitcher from '@/components/LocaleSwitcher.vue'
import RegistrySupportText from './RegistrySupportText.vue'
import FormForgotPassword from './RegistryForgotPassword.vue'
import FormCodeVerification from './RegistryCodeVerification.vue'
import FormResetPassword from './RegistryResetPassword.vue'
import { mapState } from 'vuex'
import { rules } from '@/utils/rules'
import { isPastDate } from '@/utils/dateHelpers'

export default {
  components: {
    LocaleSwitcher,
    RegistrySupportText,
    FormForgotPassword,
    FormCodeVerification,
    FormResetPassword,
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    alert: false,
    alertColor: '',
    authError: '',
    show_password: false,
    show_password_confirmation: false,
    valid: true,
    password: '',
    password_confirmation: '',
    nameRules: [(v) => !!v || 'Campo no puede estar vacio'],
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail no puede estar vacio',
      (v) => /.+@.+/.test(v) || 'E-mail no es valido',
    ],
    rules,
    linkRoom: '',
    // ---------------------- Camps ---------------------- //
    form1: true,
    form2: false,
    form3: false,
    form4: false,
    otp_code: '',
    code_verification: '',
    token_reset: '',
    email_reset: '',
    // ---------------------- Camps ---------------------- //
    require_password: false,
  }),
  computed: {
    canSwitch() {
      return !this.registry.is_only_active_for_default_lang
    },
    loginLogo() {
      return this.current_landing.logo_url_lg
        ? this.current_landing.logo_url_lg
        : require('@/assets/logo-bizzdome.svg')
    },
    toRegister() {
      return {
        name: 'registry.register',
        params: { slug: this.slug },
      }
    },
    toLogin() {
      return {
        name: 'registry.login',
        params: { slug: this.slug },
      }
    },
    toHome() {
      return {
        name: 'registry.home',
        params: { slug: this.slug },
      }
    },
    toForgotPassword() {
      return {
        name: 'registry.forgot-password',
        params: { slug: this.slug },
      }
    },
    canRegister() {
      return !this.registry.is_registration_disabled
    },
    current_landing() {
      return this.registry.landings.find(
        (row) => row.language.name === this.current_language_key,
      )
    },

    current_form() {
      return this.registry.forms.find(
        (row) => row.language.name === this.current_language_key,
      )
    },

    current_login_screen() {
      return this.registry.login_screens.find(
        (row) => row.language.name === this.current_language_key,
      )
    },
    ...mapState({
      current_language_key: (state) => state.registry.show.current_language_key,
      registry: (state) => state.registry.show.data,
      roomLink: (state) => state.registry.toPath.link,
    }),
    loading: {
      get: function () {
        return this.$store.state.isLoading
      },
      set: function (value) {
        this.$store.commit('isLoading', value)
      },
    },
    // bgImg() {
    //   return this.current_login_screen.image_url
    //     ? this.current_login_screen.image_url
    //     : require('@/assets/bg_login_bizzdome.jpg')
    // },
    bgImg() {
      const url = this.current_login_screen.image_url ? this.current_login_screen.image_url : require('@/assets/bg_login_bizzdome.jpg')
      return {
        'background-image': `url(${url})`,
      }
    },
    colorText() {
      const hex = this.current_landing.ui_color ? this.current_landing.ui_color : '#3565af'
      return {
        'color': `${hex}`,
      }
    },
    colorBtn() {
      const bg = this.current_landing.ui_color ? this.current_landing.ui_color : '#3565af'
      const hex = this.current_landing.ui_contrast ? this.current_landing.ui_contrast : '#3565af'
      return {
        'background-color': `${bg} !important`,
        'color': `${hex}`,
      }
    },
  },
  mounted() {
    // console.log(this.registry.end_date_time)
    // console.log()
    if (!this.registry.end_date_time) {
      return
    }
    if (isPastDate(this.registry.end_date_time)) {
      this.$router.push({
        name: 'registry.thank-you',
        params: { slug: this.slug },
      })
    }
    // console.log(this.current_form.uuid)
    console.log('landing', this.current_landing.ui_color)
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    authenticate() {
      this.alert = false
      if (!this.$refs.form.validate()) {
        if (!this.$refs.email.validate()) {
          this.$refs.email.focus()
          return
        }
        if (!this.$refs.password.validate()) {
          this.$refs.password.focus()
          return
        }
      }

      this.loading = true

      const body = {
        uuid: this.current_form.uuid,
        body: { email: this.email, password: this.password },
      }

      return this.$store
        .dispatch('session/loginUserViaEvent', body)
        .then(() => {
          this.email = ''
          this.password = ''
          this.$nextTick(() => {
            const link = this.roomLink ? this.roomLink : '/event/'+this.registry.slug
            this.$router.push(link)
            // this.$router.push(this.roomLink)
            // enviar al redirect
          })
        })
        .catch((err) => {
          if (window.localStorage.getItem('validAdmin')) {
            this.require_password = true
          }
          this.alert = true
          this.alertColor = 'red'
          this.authError = err
        })
        .finally(() => {
          window.localStorage.removeItem('validAdmin')
          this.loading = false
        })
    
    },
    // -------------------------------- Page Forgot Password -------------------------------- //
    pageForgotPassword() {
      this.pageForm({f1:false,f2:true,f3:false,f4:false})
    },
    // -------------------------------- Page Code Verification -------------------------------- //
    pageCodeVerification() {
      console.log('code reset')
    },
    // -------------------------------- Recibied Data Form Forgot Password -------------------------------- // 
    dataFormForgot(data) {
      this.code_verification = data.code
      this.token_reset = data.token
      this.email_reset = data.email
    },
    // -------------------------------- Pages (Forms) -------------------------------- // 
    pageForm(st) {
      this.form1 = st.f1,
      this.form2 = st.f2,
      this.form3 = st.f3,
      this.form4 = st.f4
    },
    // -------------------------------- Alert -------------------------------- // 
    alertSnackbar(data) {
      this.alert = data.status
      this.alertColor = data.color
      this.authError = data.msg
    },
    goToRegister() {
      this.$router.push({
        name: 'registry.register',
        params: { slug: this.slug },
      })
    },
  },
}
</script>

<style lang="scss">
body::-webkit-scrollbar {
    -webkit-appearance: none;
}

body::-webkit-scrollbar:vertical {
    width:10px;
}

body::-webkit-scrollbar-button:increment,body::-webkit-scrollbar-button {
    display: none;
} 

body::-webkit-scrollbar:horizontal {
    height: 10px;
}

body::-webkit-scrollbar-thumb {
    background-color: #bcbcbc;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}
body::-webkit-scrollbar-track {
    border-radius: 10px;  
}

body {
  &::-webkit-scrollbar {
    width: 0px;
  }
}
.bz-vh {
  background: #ffffff;
  height: 100vh;
}
.bz-login-body {
  .bz-login-form {
    position: relative;
    padding: 1rem;
    .bz-form {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .bz-camps {
      width: 50%;
      @media (max-width: 1360px) {width: 60%;}
      @media (max-width: 768px) {width: 60%;}
      @media (max-width: 501px) {width: 90%;}
    }
  }
  .bz-login-bg {
    background-size: cover; // 1074 x 997
    background-position: center;
    background-repeat: no-repeat;
    margin: 1rem;
    border-radius: 1rem;
  }
  .bz-login-img-logo {
    width: 320px;
    margin-bottom: 2.4rem;
    @media (max-width: 768px) {width: 220px;}
  }
}
</style>
