<template>
  <div
    class="tw-pointer-events-none tw-fixed tw-inset-0 tw-flex tw-p-4 md:tw-items-end md:tw-justify-start md:tw-p-6"
    style="z-index: 999"
  >
    <!-- tw-z-50 -->
    <!-- <div class="tw-max-w-sm tw-w-full"> -->
    <!-- <div class="tw-relative"> -->
    <!-- <v-fab-transition>
      <a target="_blank" class="tw-pointer-events-auto" :href="wa_link">
        <v-btn
          v-show="onBoardingComplete"
          style="background-color: #075e54; bottom: 16px"
          fab
          large
          dark
          absolute
          bottom
          right
          class="v-btn--example tw-pointer-events-auto tw-outline-none focus:tw-ring focus:tw-ring-primary-300"
        >
          <img
            src="@/assets/whatsapp_white.svg"
            max-height="24"
            contain
            class="d-flex"
            alt="John"
          />
        </v-btn>
      </a>
    </v-fab-transition> -->
    <!-- </div> -->
    <!-- </div> -->
    <!--
        enter-active-class="tw-transform "
        enter-class="tw-opacity-0 tw--translate-x-full"
        enter-to-class="tw-opacity-100 tw-translate-x-0"
        leave-active-class="tw-absolute tw-transform"
        leave-class="tw-opacity-100 tw-translate-x-0"
        leave-to-class="tw-opacity-0 tw--translate-x-full"
        tag="div"
        class="tw-flex tw-flex-col tw-space-y-4" -->
    <div class="tw-w-full tw-max-w-sm">
      <transition-group
        enter-active-class="tw-transform-gpu"
        enter-class="tw-opacity-0 tw--translate-x-full"
        enter-to-class="tw-opacity-100 tw-translate-x-0"
        leave-active-class="tw-absolute tw-transform-gpu"
        leave-class="tw-opacity-100 tw-translate-x-0"
        leave-to-class="tw-opacity-0 tw--translate-x-full"
        tag="div"
        class="tw-flex tw-flex-col tw-space-y-4"
        @before-leave="beforeLeave"
      >
        <Notifcation
          v-for="notification in sortedNotifications"
          :id="notification.id"
          :key="notification.id"
          :title="notification.title"
          :status="notification.status"
          :type="notification.type"
          :body="notification.body"
          :action-label="notification.action_label"
          :clickable="notification.clickable"
          :msg-meta="notification.msg_meta"
          @remove-item="removeNotification"
        />
        <!-- :class="i" -->
        <!-- :class="i>0?'tw-mt-4':''" -->
      </transition-group>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Notifcation from './NotificationAlert'
export default {
  components: {
    Notifcation,
  },
  computed: {
    ...mapGetters({
      // onBoardingComplete: "onBoardingComplete",
      // userName: "userName",
    }),
    isIn() {
      return this.$store.state.session.active
    },
    wa_link() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 'https://api.whatsapp.com/send?phone=51980702377&text=Hola%20!'
      }
      return 'https://web.whatsapp.com/send?phone=51980702377&text=Hola%20!'
    },
    user() {
      return this.$store.state.session.user
    },
    notifications: {
      get() {
        return this.$store.getters['notifications/notifications']
      },
      set(val) {
        this.$store.commit('notifications/notifications', val)
      },
    },
    sortedNotifications() {
      //   .reverse()
      return [...this.notifications]
      // return [...this.notifications].reverse();
      // return [...this.notifications].slice(0, 4);
    },
  },

  methods: {
    removeNotification(id) {
      this.$store.commit('notifications/notificationsRemove', id)
    },
    beforeLeave(el) {
      const { marginLeft, marginTop, width, height } =
        window.getComputedStyle(el)
      el.style.left = `${el.offsetLeft - parseFloat(marginLeft, 10)}px`
      el.style.top = `${el.offsetTop - parseFloat(marginTop, 10)}px`
      el.style.width = width
      el.style.height = height
    },
  },
}
</script>

<style></style>
