import Resource from './BaseResource'

class LicenseResource extends Resource {
  /**
   * The constructor for the LicenseResource.
   */
  constructor() {
    super('/api/v1/validate-license')
  }
}

export default new LicenseResource()
