import { LanguageValuesInverse } from '@/enums/LanguageKeys'
import i18n from '@/i18n'
// state
const initState = () => {
  return {
    locale: 'es',
  }
}

// getters
const getters = {
  locale({ locale }) {
    return locale
  },

  current({ locale }) {
    return { key: locale, value: LanguageValuesInverse[locale] }
  },
}

// actions
const actions = {
  initLocale({ dispatch }) {
    const user_prefered_locale = window.localStorage.getItem('x_locale')
    if (user_prefered_locale && ['es', 'en'].includes(user_prefered_locale)) {
      dispatch('updateLocate', { locale: user_prefered_locale })
      return
    }
    dispatch('updateLocate', { locale: 'es' })
  },
  updateLocate({ commit }, { locale = 'es' }) {
    i18n.locale = locale
    commit('setLocate', locale)
    commit('registry/setShow', { current_language_key: locale }, { root: true })
    commit(
      'event/setConfig',
      { current_config_language_key: locale },
      { root: true },
    )
    window.localStorage.setItem('x_locale', locale)
    // save to local storage,
    // on boot set locale
  },
}

// mutations
const mutations = {
  setLocate(state, value) {
    state.locale = value
  },
}

export const Language = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
}
