import $axios from '@/axios-instance.js'

export default class EventBroadcastsService {
  baseUrl = '/api/v1/admin/event-broadcasts'

  // Create
  store(params) {
    return $axios.post(this.baseUrl, params)
  }

  // Read all - findAll
  index(params) {
    return $axios.get(this.baseUrl, params)
  }

  // Read one - show
  show(id, params) {
    return $axios.get(this.baseUrl + `/${id}`, params)
  }

  // Update
  update(id, params) {
    return $axios.put(this.baseUrl + `/${id}`, {
      ...params,
      _method: 'PUT',
    })
  }

  // Delete - delete
  destroy(id, params) {
    return $axios.delete(this.baseUrl + `/${id}`, {
      ...params,
      _method: 'DELETE',
    })
  }
}
export const EventBroadcastsServiceInstance = new EventBroadcastsService()
