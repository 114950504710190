<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <nav
    class="scrollbars-hidden tw-flex tw-overflow-hidden tw-overflow-x-auto tw-py-1.5 lg:tw-overflow-visible"
    aria-label="Breadcrumb"
  >
    <ol class="tw-flex tw-items-center tw-space-x-4 tw-pl-2">
      <li v-for="(page, index) in nav" :key="page.name">
        <div class="tw-flex tw-items-center">
          <g-icon
            v-if="index > 0"
            aria-hidden="true"
            name="slash"
            class="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-300"
          />
          <router-link
            v-ripple
            color="white"
            class="tw-block tw-min-w-0 tw-truncate tw-rounded-3xl tw-bg-transparent tw-px-2 tw-text-lg tw-font-medium tw-normal-case tw-tracking-normal tw-text-white tw-transition before:tw-bg-transparent hover:tw-bg-gray-50 hover:tw-bg-opacity-30 focus:tw-bg-gray-50 focus:tw-bg-opacity-30 focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-50"
            :class="{ 'tw-ml-3': index > 0 }"
            :to="page.route"
            :aria-current="page.current ? 'page' : undefined"
          >
            {{ page.name }}
          </router-link>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
import GIcon from '@/components/GIcon'
export default {
  name: 'AppBreadcrumbs',
  components: { GIcon },
  props: {
    nav: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
