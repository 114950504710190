<template>
  <v-col
    cols="12"
    class="tw-flex-initial tw-bg-primary-500 tw-py-3 tw-px-2 tw-shadow-md sm:tw-sticky sm:tw-top-0 sm:tw-z-10 2xl:tw-px-8"
  >
    <div class="tw-container tw-mx-auto">
      <div class="lg:tw-flex lg:tw-items-center lg:tw-justify-between">
        <!-- <div class="tw-flex-1 tw-min-w-0 tw-truncate"> -->
        <div
          class="scrollbars-hidden tw-min-w-0 tw-flex-1 tw-overflow-hidden tw-overflow-x-visible tw-truncate"
        >
          <slot name="title">
            <Breadcrumbs v-if="nav.length > 0" :nav="nav" />
          </slot>
        </div>
        <div v-if="hasActions" class="lg:tw-mt-0 lg:tw-ml-4 lg:tw-flex">
          <div
            class="tw-overflow-hidden tw-overflow-x-auto tw-py-3 lg:tw-overflow-visible lg:tw-py-0"
          >
            <slot name="actions" />
          </div>
          <!-- <span class="tw-hidden sm:tw-block">
            <span class="sm:tw-ml-3"> </span>
            <span class="sm:tw-ml-3"> </span>
          </span> -->
        </div>
      </div>
    </div>
  </v-col>
</template>

<script>
import Breadcrumbs from './AppLayout/Breadcrumbs.vue'
export default {
  components: { Breadcrumbs },
  props: {
    nav: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    hasActions() {
      return this.$slots.actions
    },
  },
}
</script>

<style></style>
