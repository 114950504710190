<template>
    <div class="tw-px-2 lg:tw-px-6 tw-py-10 tw-space-y-2">
        <!-- Item -->
        <div
            v-for="(user, index) in users"
            :key="user.email"
            class="tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-2 tw-rounded-full"
            :class="{
                'tw-bg-[#FEC929] tw-text-white tw-shadow': index == 0,
                'tw-bg-[#00B2FF] tw-text-white tw-shadow': index == 1,
                'tw-bg-[#FFA800] tw-text-white tw-shadow': index == 2,
                'tw-bg-[#FCFCFC] tw-text-black': index != 0 && index != 1 && index != 2,
            }"
        >
            <div>
                <v-avatar color="primary" size="30">
                    <img :src="user.profile_photo_url" alt="" />
                </v-avatar>
                <span class="tw-text-xs tw-font-bold tw-ml-2">{{ user.fullName }}</span>
            </div>
            <div>
                <span class="tw-text-xs tw-font-bold">{{ user.total }} pts.</span>

            </div>
        </div>
        <!-- End Item -->
    </div>
</template>

<script>

export default {
    props: {
        users: {
            type: [Array, Object],
            default: () => ({}),
        },
    },
}
</script>