import $axios from '@/axios-instance.js'

export class ParticipantsService {
  baseUrl = '/api/v1/admin/registries'
  relationshipDomain = 'participants'
  static make() {
    return new ParticipantsService()
  }
  // Create
  store(params) {
    return $axios.post(this.baseUrl, params)
  }

  // Read all - findAll
  index(registry, params) {
    return $axios.get(
      `${this.baseUrl}/${registry}/${this.relationshipDomain}`,
      params,
    )
  }

  // Read one - show
  show(id, params) {
    return $axios.get(this.baseUrl + `/${id}`, params)
  }

  // Update
  update(id, params) {
    return $axios.put(this.baseUrl + `/${id}`, params)
  }

  // Delete - delete
  destroy(id, params) {
    return $axios.delete(this.baseUrl + `/${id}`, params)
  }
}
