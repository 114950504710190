<template>
  <div>
    <div class="tw-flex tw-items-center tw-justify-between">
      <label :for="uuid" class="tw-cursor-pointer">
        <p class="tw-mb-0 tw-text-sm tw-text-gray-500 tw-font-semibold">
          <slot name="title" />
        </p>
        <p class="tw-mb-0 tw-max-w-sm tw-text-sm tw-text-gray-400">
          <slot name="description" />
        </p>
      </label>
      <div
        class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
      >
        <v-switch :id="uuid" v-model="bool" inset large dense/>
      </div>
    </div>
    <transition name="fade-transition" mode="out-in">
      <div v-if="bool && $slots.extras" :class="extrasContainerClasses">
        <!-- tw-grid tw-grid-cols-1 tw-gap-y-4 tw-gap-x-4 sm:tw-grid-cols-6 -->
        <slot name="extras" />
        <!-- <div class="sm:tw-col-span-3">
        <label class="tw-block tw-pb-2 tw-text-gray-600">
          {{ $t('admin.app.footer_colors_input') }}
        </label>
        <div>
          <ColorInput v-model="footer_color" />
        </div>
      </div> -->
      </div>
    </transition>
  </div>
</template>

<script>
import { v4 } from '@/helpers'
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    extraStyle: {
      type: String,
      default: 'gray-bg',
    },
  },
  data: () => ({
    uuid: v4(),
  }),

  computed: {
    bool: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    extrasContainerClasses() {
      return {
        'gray-bg': 'tw-mt-6 tw-rounded-lg tw-bg-gray-100 tw-px-6 tw-py-4',
        'gray-border':
          'tw-mt-4 tw-rounded-lg tw-bg-white tw-border tw-border-solid tw-border-gray-100 tw-px-6 tw-py-4',
      }[this.extraStyle]
    },
  },
}
</script>

<style></style>
