<template>
<v-dialog v-model="open" persistent max-width="768px" content-class="tw-rounded-3xl" scrollable>
    <v-form ref="form" v-model="valid" :disabled="form_disabled_state" lazy-validation enctype="multipart/form-data" @submit.prevent="update">
        <v-card class="tw-rounded-3xl tw-border-0 tw-shadow-md">
            <ModalHeader @close-modal="closeModal">{{$t('bz2.landing.multi_button.title')}}</ModalHeader>
            <v-divider />
            <v-card-text class="tw-p-0" style="max-height: 700px">
                <v-responsive class="tw-py-0">
                    <v-container class="tw-bg-gray-50">
                        <v-row class="mb-5">
                            <v-col cols="12" class="mx-auto pa-5">
                                <v-card class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-bg-transparent tw-shadow-none">
                                    <v-container class="pa-5">
                                        <div class="tw-grid tw-grid-cols-1">
                                            <div class="">
                                                <v-text-field v-model="mountedSection.data.title" :rules="[rules.required]" validate-on-blur outlined rounded :label="$t('app.title')" />
                                            </div>
                                        </div>
                                    </v-container>
                                    <v-container v-if="mountedSection.data.buttons.length > 0" class="pa-5">
                                        <div>
                                            <draggable v-model="mountedSection.data.buttons" tag="div" handle=".handle" class="tw-overflow-hidden tw-bg-gray-100 tw-shadow sm:tw-rounded-3xl" ghost-class="ghost">
                                                <transition-group type="transition" :name="'flip-list'" tag="ul" class="tw-divide-y tw-divide-gray-200 tw-pl-0">
                                                    <li v-for="mbutton in mountedSection.data.buttons" :key="mbutton.uid" class="tw-flex tw-space-x-4 tw-px-4 tw-py-4 sm:tw-px-6">
                                                        <div class="tw-flex-1">
                                                            <DropzoneOverlayPreview v-model="mbutton.mbutton_image_url" :width="450" :height="250" media-group="registries_multibuttons" />
                                                            <div class="tw-mt-2">
                                                                <ToggleGroup v-model="mbutton.has_cta">
                                                                    <template #title>{{ $t('admin.events.multi_buttons') }}</template>
                                                                    <template #extras>
                                                                        <div class="tw-grid tw-grid-cols-1 tw-gap-3 sm:tw-grid-cols-6">
                                                                            <div class="sm:tw-col-span-6">
                                                                                <v-select
                                                                                    v-model="mbutton.cta_link_type"
                                                                                    :items="types_link"
                                                                                    validate-on-blur
                                                                                    label="Type"
                                                                                    outlined
                                                                                    rounded
                                                                                    hide-details
                                                                                />
                                                                            </div>
                                                                            <div class="sm:tw-col-span-6">
                                                                                <v-text-field
                                                                                    v-if="mbutton.cta_link_type == 'external'"
                                                                                    v-model="mbutton.cta_link"
                                                                                    outlined
                                                                                    rounded
                                                                                    label="Link"
                                                                                    hide-details
                                                                                />
                                                                            </div>
                                                                            <div class="sm:tw-col-span-3">
                                                                                <v-select
                                                                                    v-if="mbutton.cta_link_type == 'internal'"
                                                                                    v-model="mbutton.cta_link"
                                                                                    :items="internalPages"
                                                                                    validate-on-blur
                                                                                    label="Type"
                                                                                    outlined
                                                                                    rounded
                                                                                    hide-details
                                                                                />
                                                                            </div>
                                                                            <div class="sm:tw-col-span-3">
                                                                                <CustomFormsAutocomplete 
                                                                                    v-if="mbutton.cta_link_type == 'internal' && mbutton.cta_link == 'register'"
                                                                                    v-model="mbutton.form_id"
                                                                                />
                                                                            </div>
                                                                            <div class="sm:tw-col-span-6">
                                                                                <DropzoneOverlayPreview v-if="mbutton.cta_link_type == 'external'" v-model="mbutton.mbutton_url" media-group="mbutton_pdf" :bypass="'no-check'" />
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                </ToggleGroup>
                                                            </div>
                                                        </div>
                                                        <div class="tw-flex tw-flex-shrink-0 tw-flex-col tw-items-center tw-space-y-2">
                                                            <AppBtn color="transparent" icon-center="g-next" g-next="DragOrder" class="handle tw-cursor-move" fab small type="button" />
                                                            <AppBtn color="transparent-danger" icon-center="close" fab small type="button" @click="deleteItem(mbutton)" />
                                                        </div>
                                                    </li>
                                                </transition-group>
                                            </draggable>
                                        </div>
                                    </v-container>
                                    <v-container>
                                        <div class="tw-flex tw-justify-end">
                                            <AppBtn type="button" icon="plus" color="white" @click="addButton">
                                                {{ $t('app.add') }}
                                            </AppBtn>
                                        </div>
                                    </v-container>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-responsive>
            </v-card-text>
            <v-card-actions>
                <v-alert v-model="errorAlert" dismissible color="red" border="left" elevation="2" colored-border icon="error" transition="fade-transition">
                    {{ errorMsg }}
                </v-alert>
                <v-spacer />
                <AppBtn type="button" :disabled="form_loading" color="transparent" @click="closeModal">
                    {{ $t('app.cancel') }}
                </AppBtn>
                <AppBtn color="primary" :loading="form_loading" icon="check" type="submit">
                    {{ $t('app.save') }}
                </AppBtn>
            </v-card-actions>
        </v-card>
    </v-form>
</v-dialog>
</template>

<script>
import ModalHeader from '@/components/ModalHeader'
// import FormLabel from '@/components/FormInputs/FormLabel.vue'
import {
    Mbutton,
} from '@/models/RegistryLanding'
import draggable from 'vuedraggable'
import AppBtn from '../App/AppBtn.vue'
import DropzoneOverlayPreview from '../DropzoneOverlayPreview.vue'
import ToggleGroup from '@/components/FormInputs/ToggleGroup.vue'
import { internalPages } from '@/models/RegistryLanding'
import CustomFormsAutocomplete from '@/components/CustomFormsAutocomplete.vue'

import {
    rules,
} from '@/utils/rules'
export default {
    name: 'BizzDomeUiRegistriesLandingsMultiButtons',
    components: {
        ModalHeader,
        // FormLabel,
        draggable,
        AppBtn,
        DropzoneOverlayPreview,
        ToggleGroup,
        CustomFormsAutocomplete,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        section: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        valid: true,
        form_disabled_state: false,
        rules,
        isLoading: false,
        form_loading: false,
        mountedSection: {},
        errorAlert: false,
        errorMsg: '',
        internalPages,
    }),
    computed: {
        types_link() {
            return [
                { text: `Internal`, value: 'internal' },
                { text: `External`, value: 'external' },
            ]
        },
        open: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
    created() {
        this.mountedSection = JSON.parse(JSON.stringify(this.section))
    },
    methods: {
        update() {
            this.errorAlert = false
            if (!this.$refs.form.validate()) {
                return
            }

            this.$emit('updated-section', this.mountedSection)
            this.open = false
        },
        deleteItem(logo) {
            this.mountedSection.data.buttons = this.mountedSection.data.buttons.filter(
                (row) => row.uid != logo.uid,
            )
        },
        addButton() {
            this.mountedSection.data.buttons = [
                ...this.mountedSection.data.buttons,
                Mbutton(),
            ]

            console.log('Mbuttons', this.mountedSection)
        },
        closeModal() {
            this.open = false
        },
    },
}
</script>
