<template>
  <v-dialog
    v-model="show"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    content-class="tw-self-end tw-rounded-3xl sm:tw-self-auto"
    @keydown.esc="cancel"
  >
    <v-card dark class="tw-border-0">
      <!-- <v-toolbar :color="options.color" dark dense flat> -->
      <!-- <v-toolbar dark dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
      <v-divider /> -->
      <v-card-text class="pt-2 taypa-radius">
        <h1 class="text-center mb-2 pt-5 pb-5 tw-text-2xl tw-text-white">
          {{ $t('app.are_you_sure') }}
        </h1>
        <p class="text-center tw-text-lg tw-text-gray-400">
          {{ message }}
        </p>
      </v-card-text>

      <v-divider />
      <!-- <v-card-actions class="pt-0">
        <v-container class="tw-px-0 tw-py-2">
          <v-spacer></v-spacer>
          <v-btn @click.native="cancel" color="grey" text>Cancel</v-btn>
          <AppBtn color="transparent-dark" @click="agree"> Confirmar </AppBtn>
        </v-container>
      </v-card-actions> -->
      <v-card-actions>
        <v-container class="tw-px-0 tw-py-2">
          <div
            class="tw-flex tw-flex-col-reverse tw-gap-3 sm:tw-grid sm:tw-grid-cols-2"
          >
            <AppBtn color="transparent-dark" block @click="cancel">
              {{ $t('app.cancel') }}
            </AppBtn>
            <AppBtn color="outlined-danger-dark" block @click="agree">
              {{ $t('app.confirm') }}
            </AppBtn>
          </div>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
import AppBtn from './AppBtn.vue'
export default {
  components: {
    AppBtn,
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 400,
      zIndex: 200,
    },
  }),
  computed: {
    show: {
      get() {
        return this.dialog
      },
      set(value) {
        this.dialog = value
        if (value === false) {
          this.cancel()
        }
      },
    },
  },
  methods: {
    open(title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    },
  },
}
</script>
