<template>
  <transition name="fade-transition" mode="out-in">
    <v-col v-if="showLoading" key="canvas_loading" class="tw-h-full">
      <div class="tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        />
      </div>
    </v-col>
    <template v-else>
      <transition name="fade-transition" mode="out-in">
        <router-view />
      </transition>
    </template>
  </transition>
</template>

<script>
export default {
  computed: {
    showLoading() {
      return this.$store.state.event_config.show.is_loading
    },
  },
  mounted() {
    this.$store.dispatch('event_config/index', { loading: true })
  },
  beforeDestroy() {
    console.log('un mounted')
    this.$store.commit('event_config/setShow', { is_loading: true })
  },
}
</script>

<style></style>
