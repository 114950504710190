<template>
  <span
    class="tw-inline-flex tw-items-center tw-whitespace-pre tw-rounded-full tw-border tw-border-gray-300 tw-bg-white tw-px-2.5 tw-py-0.5 tw-text-xs tw-font-medium tw-leading-5 tw-text-gray-700 tw-shadow-sm hover:tw-bg-gray-50"
  >
    <slot />
  </span>
</template>

<script>
export default {}
</script>

<style></style>
