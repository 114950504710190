<template>
  <!-- <div class="text-xs-center"> -->
  <v-dialog
    v-model="show"
    scrollable
    :width="form_state ? 1024 : 420"
    persistent
    content-class="tw-self-end tw-rounded-3xl sm:tw-self-auto"
  >
    <v-card class="tw-shadow-md">
      <v-container v-if="!form_state" class="tw-p-5" fluid>
        <h1 class="text-center tw-text-2xl">
          {{ $t('pages.registry.index.header') }}
        </h1>
        <div class="tw-mt-3 tw-flex tw-w-full tw-justify-center">
          <div>
            <LocaleSwitcher />
          </div>
        </div>
      </v-container>
      <v-container v-if="form_state" class="tw-p-" fluid>
        <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
          <h1 class="text-center tw-text-2xl">
            {{ currentRegistry.label }}
          </h1>
          <div class="tw-mt-3">
            <span
              class="tw-inline-flex tw-items-center tw-rounded-full tw-bg-gray-100 tw-px-2.5 tw-py-0.5 tw-text-sm tw-font-medium tw-text-gray-800"
            >
              {{ current_language_key }}
            </span>
          </div>
        </div>
      </v-container>
      <v-divider />
      <v-card-text class="pt-2">
        <v-responsive class="tw-py-0">
          <v-container v-if="!form_state" class="tw-p-5" fluid>
            <div class="tw-mt-12 tw-grid tw-grid-cols-1 tw-gap-0.5 lg:tw-mt-0">
              <v-btn
                v-for="{ uuid, langs, label, langs_forms, slug } in registriesRows"
                :key="uuid"
                :disabled="is_loading"
                :loading="is_loading && selected_uuid === uuid"
                text
                class="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-py-6 tw-px-8 focus:tw-outline-none"
                @click="
                  selectedEvent(uuid, langs_forms[current_language_key].id, slug)
                "
              >
                <div class="tw-flex tw-flex-col tw-justify-center">
                  <img
                    class="tw-max-h-12"
                    :src="
                      langs[current_language_key].logo_url_sm
                        ? langs[current_language_key].logo_url_sm
                        : require('@/assets/icon-bizzdome.svg')
                    "
                    alt="Bizz Dome Event"
                  />
                  <h2 class="tw-mt-4 tw-text-base">{{ label }}</h2>
                </div>
              </v-btn>
            </div>
          </v-container>
          <v-container v-else class="tw-p-5">
            <!-- form for selected registry -->
            <PreEventRegister
              :current-form="currentForm"
              :registry="currentRegistry"
              @on-form-submit="processRegister"
            />
          </v-container>
        </v-responsive>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <!-- <v-spacer></v-spacer> -->
        <v-container class="tw-px-0 tw-py-2">
          <div
            class="tw-flex tw-flex-col tw-justify-between sm:tw-flex-row sm:tw-space-x-3"
          >
            <div>
              <v-checkbox
                v-if="can_remember"
                v-model="has_remembered"
                class="tw-mt-0"
                :label="$t('app.remember')"
                hide-details=""
              />
            </div>
            <div
              class="tw-flex tw-flex-col tw-justify-end sm:tw-flex-row sm:tw-space-x-3"
            >
              <AppBtn
                v-if="form_state == true"
                color="transparent"
                icon="g-next"
                g-next="ArrowNarrowLeft"
                :disabled="is_loading"
                @click.stop="form_state = false"
              >
                {{ $t('app.back') }}
              </AppBtn>
              <AppBtn
                color="transparent"
                :disabled="is_loading"
                @click.stop="close"
              >
                {{ $t('app.cancel') }}
              </AppBtn>
            </div>
          </div>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- </div> -->
</template>
<script>
import LocaleSwitcher from '@/components/LocaleSwitcher.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import PreEventRegister from './PreEventRegister.vue'
import { LanguageValues, LanguageValuesInverse } from '@/enums/LanguageKeys'
import { mapState } from 'vuex'
export default {
  components: {
    AppBtn,
    PreEventRegister,
    LocaleSwitcher,
  },
  props: {
    value: Boolean,
  },
  data: () => ({
    has_remembered: false,
    is_loading: false,
    form_state: false,
    selected_uuid: '',
  }),
  computed: {
    ...mapState({
      current_language_key: (state) => state.registry.show.current_language_key,
      registries: (state) => state.constants.data.registries,
      current_registry_uuid: (state) =>
        state.session.admin.loaded_registry.registry_uuid,
      current_form_id: (state) => state.session.admin.loaded_registry.form_id,
      to_route: (state) => state.session.admin.to_route,
      can_remember: (state) => state.session.admin.can_remember,
    }),
    currentForm() {
      if (!this.selected_uuid) {
        return {}
      }
      return this.registries
        .find((row) => row.uuid === this.selected_uuid)
        .forms.find(
          (row) =>
            row.language_id ===
            LanguageValuesInverse[this.current_language_key],
        )
    },
    currentRegistry() {
      if (!this.selected_uuid) {
        return {}
      }
      return this.registries.find((row) => row.uuid === this.selected_uuid)
    },
    registriesRows() {
      return this.registries.map((row) => ({
        ...row,
        route: {
          name: 'registry.home',
          params: { slug: row.slug },
        },
        langs: row.landings.reduce((acc, curr) => {
          return {
            ...acc,
            [LanguageValues[curr.language_id]]: curr,
          }
        }, {}),
        langs_forms: row.forms.reduce((acc, curr) => {
          return {
            ...acc,
            [LanguageValues[curr.language_id]]: curr,
          }
        }, {}),
      }))
    },
    show: {
      get() {
        return this.$store.state.session.admin.pre_event_modal
      },
      set(value) {
        return this.$store.commit('session/setAdmin', {
          pre_event_modal: value,
        })
      },
    },
  },
  watch: {
    show(newVal) {
      if (newVal) {
        if (
          this.can_remember &&
          this.has_remembered &&
          this.current_registry_uuid &&
          this.current_form_id
        ) {
          this.selectedEvent(this.current_registry_uuid, this.current_form_id)
        }
      }
    },
  },
  methods: {
    close() {
      this.show = false
      this.form_state = false
    },
    selectedEvent(registry_uuid, form_id, slug) {
      console.log({ registry_uuid })
      console.log({ form_id })
      console.log({ slug })
      if (
        this.current_registry_uuid === registry_uuid &&
        this.current_form_id === form_id
      ) {
        console.log('matched')
        if (this.to_route) {
          this.$router.push(this.to_route)
        } else {
          this.$router.push('/event/'+slug)
        }
        this.show = false
        return
      }
      this.is_loading = true
      this.selected_uuid = registry_uuid
      // 1. check if current user session already has registry loaded
      //  by checking uuid is the laoded on session admin
      this.$store
        .dispatch('session/verifyUserRegistry', {
          registry_uuid,
          payload: { form_id },
        })
        .then(({ has_attended, is_current, has_profile }) => {
          if (has_profile) {
            return this.$store
              .dispatch('session/loginViaEventVerifiedRegistry', {
                registry_uuid,
                payload: { form_id },
              })
              .then(() => {
                if (this.to_route) {
                  this.$router.push(this.to_route)
                } else {
                  // this.$router.push('/event/home')
                  this.$router.push('/event/'+slug)
                }
                this.show = false
              })
          } else {
            console.log({ has_attended })
            console.log({ is_current })
            this.form_state = true
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.is_loading = false
        })
    },
    processRegister(payload) {
      this.form_state = false

      this.is_loading = true
      // setTimeout(() => {
      //   this.is_loading = false
      // }, 1000)
      return this.$store
        .dispatch('session/registerViaEventVerifiedRegistry', payload)
        .then(() => {
          if (this.to_route) {
            this.$router.push(this.to_route)
          } else {
            this.$router.push('/event/'+payload.slug)
            // this.$router.push('/event/home')
          }
          this.show = false
          // return this.$store.dispatch
        })
        .catch((err) => {
          this.errorAlert = true
          this.errorMsg = err
        })
        .finally(() => {
          this.is_loading = false
        })
      // console.log(payload)
      // 2. check bd if user is partcipating on that event and swap session
      // 3. If not partcipating show form to fill out then send user to event
    },
  },
}
</script>
