<template>
  <div
    class="tw-pointer-events-none tw-fixed tw-inset-0 tw-z-10 tw-hidden tw-p-4 lg:tw-flex lg:tw-items-end lg:tw-justify-start lg:tw-p-6"
  >
    <div class="tw-mb-20 tw-flex tw-w-full tw-max-w-sm tw-items-end">
      <v-fab-transition content-class="">
        <v-speed-dial
          v-model="fab"
          class="tw-pointer-events-auto tw-w-full tw-cursor-pointer"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
        >
          <template #activator>
            <v-btn
              v-model="fab"
              class="tw-pointer-events-auto tw-m-4 tw-h-14 tw-w-14 md:tw-h-16 md:tw-w-16"
              fab
            >
              <v-icon v-if="fab"> close </v-icon>
              <v-icon v-else> build </v-icon>
            </v-btn>
          </template>
          <AppBtn
            color="primary-dark"
            :to="{
              name: 'admin.booths.edit.canvas',
              params: { id: virtual_room.id },
            }"
            icon="pencil_edit"
          >
            {{ $t('app.edit') }}
          </AppBtn>
          <AppBtn
            color="white"
            :to="{
              name: 'admin.booths.index',
            }"
            icon="g-next"
            g-next="ArrowNarrowLeft"
          >
            {{ $t('app.back') }}
          </AppBtn>

          <v-card
            color="white"
            class="trw-w-full tw-mb-3 tw-overflow-hidden tw-rounded-3xl tw-border-transparent tw-shadow-md"
          >
            <v-container class="tw-whitespace-nowrap tw-p-0" fluid>
              <div
                class="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-space-x-3 tw-px-4 tw-py-5 sm:tw-flex-nowrap sm:tw-px-6"
              >
                <div>
                  <h3
                    class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                  >
                    Debug
                  </h3>
                  <p
                    class="tw-mb-0 tw-mt-1 tw-max-w-2xl tw-text-sm tw-text-gray-500"
                  >
                    meta data
                  </p>
                </div>
                <div>
                  <AppBtn
                    color="primary-light"
                    g-next="DocumentDuplicate"
                    icon="g-next"
                    @click="copyMetaData"
                  >
                    {{ $t('app.copy') }}
                  </AppBtn>
                </div>
              </div>
              <div
                class="tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-p-0"
              >
                <dl class="sm:tw-divide-y sm:tw-divide-gray-200">
                  <div
                    class="tw-py-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-py-5 sm:tw-px-6"
                  >
                    <dt class="tw-text-sm tw-font-medium tw-text-gray-500">
                      {{ $t('admin.metrics.device') }}
                    </dt>
                    <dd
                      class="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0"
                    >
                      {{ sessionMetrics.device_width }} x
                      {{ sessionMetrics.device_height }}
                    </dd>
                  </div>
                  <div
                    class="tw-py-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-py-5 sm:tw-px-6"
                  >
                    <dt class="tw-text-sm tw-font-medium tw-text-gray-500">
                      {{ $t('admin.enums.virtual_room') }}
                    </dt>
                    <dd
                      class="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0"
                    >
                      {{ sessionMetrics.room_width }} x
                      {{ sessionMetrics.room_height }}
                    </dd>
                  </div>
                </dl>
              </div>
            </v-container>
          </v-card>
        </v-speed-dial>
      </v-fab-transition>
    </div>
  </div>
</template>

<script>
import AppBtn from '@/components/App/AppBtn.vue'
import { mapState } from 'vuex'
import { copyToClipboard } from '@/utils/copyToClipboard'
export default {
  components: {
    AppBtn,
  },
  data: () => ({
    direction: 'top',
    fab: false,
    hover: true,
    transition: 'slide-y-reverse-transition',
  }),

  computed: {
    isDev() {
      return process.env.NODE_ENV !== 'production'
    },
    sessionMetrics() {
      return this.$store.getters['event/plainMetricsPayload']
    },
    ...mapState({
      virtual_room: (state) => state.event.show.data,
    }),
  },

  methods: {
    copyMetaData() {
      console.log(this.sessionMetrics)
      const result = Object.entries(this.sessionMetrics).reduce(
        (acc, [key, value]) => {
          return `${acc}${key}: ${value}\r\n`
        },
        '',
      )
      console.log(result)
      copyToClipboard(result)
    },
  },
}
</script>

<style lang="postcss">
.v-speed-dial__list {
  @apply tw-items-start !important;
}
</style>
