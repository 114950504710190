<template>
  <div class="tw-flex tw-h-full tw-flex-col">
    <div class="tw-w-full tw-rounded-t-lg tw-bg-primary-500">
      <div class="tw-flex tw-items-center tw-space-x-2 tw-px-4 tw-py-5">
        <div class="">
          <v-btn
            fab
            x-small
            class="text--white tw-normal-case tw-shadow-sm focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-300"
            @click="view = 'HOME'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="tw-h-4 tw-w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </v-btn>
        </div>
        <!-- tw-px-4  -->
        <div class="tw-relative tw-flex tw-flex-1 tw-items-center tw-space-x-3">
          <div class="tw-min-w-0 tw-flex-1">
            <h2 class="tw-text-white">Nuevo chat grupal</h2>
            <!-- <v-text-field
              v-model="search"
              :label="$t('app.search')"
              outlined
              rounded
              hide-details=""
              dark
              dense
              prepend-inner-icon="search"
            /> -->
          </div>
        </div>
      </div>
    </div>
    <div
      id="messages"
      ref="scrollToMe"
      class="tw-scrolling-touch scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 tw-flex tw-flex-col tw-space-y-4 tw-overflow-y-auto"
    >
      <div class="tw-px-2 tw-pt-4 tw-pb-4">
        <v-form @submit.prevent="createGroupChat">
          <v-container>
            <v-row>
              <v-col cols="12" md="12" class="tw-pb-0">
                <v-text-field
                  v-model="form.subject"
                  outlined
                  rounded
                  validate-on-blur
                  :rules="[rules.required]"
                  color="blue-grey lighten-2"
                  label="Nombre del grupo"
                />
              </v-col>
              <v-col cols="12" class="tw-pb-0">
                <v-autocomplete
                  v-model="form.friends"
                  :items="filteredUsers"
                  outlined
                  rounded
                  chips
                  validate-on-blur
                  :rules="[rules.required]"
                  color="blue-grey lighten-2"
                  label="Selecciona a usuarios"
                  item-text="name"
                  item-value="uuid"
                  multiple
                >
                  <template #selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item)"
                    >
                      <v-avatar left>
                        <v-img :src="data.item.avatar" />
                      </v-avatar>
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template #item="data">
                    <v-list-item-avatar>
                      <img :src="data.item.avatar" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name" />
                      <v-list-item-subtitle v-html="data.item.group" />
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <div class="tw-flex tw-w-full tw-justify-end">
                  <app-btn :color="'primary'" :icon="'check'" type="submit">
                    Crear
                  </app-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>
      <!-- <ul class="tw-w-full tw-pl-0 tw-divide-y tw-divide-gray-200">
        <v-virtual-scroll :item-height="72" :items="filteredUsers" height="520">
          <template v-slot:default="{ item }">
            <UsersListItem :user="item" />
          </template>
        </v-virtual-scroll>
      </ul> -->
    </div>
  </div>
</template>

<script>
import { mapFields } from '@/helpers'
import { mapState } from 'vuex'
import { log } from '@/utils/logging'
import { rules } from '@/utils/rules'
import AppBtn from '../App/AppBtn.vue'
const srcs = {
  1: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
  2: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
  3: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
  4: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
  5: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
}

export default {
  components: { AppBtn },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    search: '',
    rules,
    form: {
      subject: '',
      friends: [],
    },
    people: [
      { name: 'Sandra Adams', group: 'Group 1', avatar: srcs[1] },
      { name: 'Ali Connors', group: 'Group 1', avatar: srcs[2] },
      { name: 'Trevor Hansen', group: 'Group 1', avatar: srcs[3] },
      { name: 'Tucker Smith', group: 'Group 1', avatar: srcs[2] },
      { name: 'Britta Holt', group: 'Group 2', avatar: srcs[4] },
      { name: 'Jane Smith ', group: 'Group 2', avatar: srcs[5] },
      { name: 'John Smith', group: 'Group 2', avatar: srcs[1] },
      { name: 'Sandra Williams', group: 'Group 2', avatar: srcs[3] },
    ],
    filter: [],
    debug_items: [{ name: 'tits', id: 1 }],
  }),
  computed: {
    ...mapFields({
      fields: ['users', 'usersLocation', 'onlyActive', 'onlyThisLocation'],
      base: ['event', 'socket'],
      mutation: 'event/setSocket',
    }),
    ...mapState({
      current_user: (state) => state.session.user,
      slug: (state) => state.event.show.data.slug,
      registry: (state) => state.event.constants.registry,
    }),

    userInThisLocation() {
      return this.usersLocation
        .filter((row) => row.slug === this.slug)
        .filter((row) => row.registry_uuid === this.registry.uuid)
        .map((row) => row.user_id)
    },
    filteredUsers() {
      return this.users
        .filter((row) => row.id !== this.current_user.id)
        .map((row) => ({ ...row, name: `${row.first_name} ${row.last_name}` }))
      // .filter((row) => {
      //   // if (!this.onlyActive && !this.onlyThisLocation) {
      //   if (!this.onlyActive) {
      //     return true
      //   }
      //   return row.is_online
      // })
      // .filter((row) => {
      //   if (!this.onlyThisLocation) {
      //     return true
      //   }
      //   return this.userInThisLocation.includes(row.id)
      // })
      // .filter((row) => {
      //   return (
      //     row.first_name
      //       .toLocaleLowerCase()
      //       .indexOf(this.search.toLocaleLowerCase()) > -1 ||
      //     row.last_name
      //       .toLocaleLowerCase()
      //       .indexOf(this.search.toLocaleLowerCase()) > -1 ||
      //     row.email
      //       .toLocaleLowerCase()
      //       .indexOf(this.search.toLocaleLowerCase()) > -1
      //   )
      // })
    },
    view: {
      get() {
        return this.$store.state.chat.view
      },
      set(val) {
        this.$store.commit('chat/setState', { view: val })
      },
    },
  },
  methods: {
    createGroupChat() {
      console.log(this.form)
      // graphql mutation
    },
    remove(item) {
      const index = this.form.friends.indexOf(item.name)
      if (index >= 0) this.form.friends.splice(index, 1)
    },
    toggleActive() {
      this.onlyActive = !this.onlyActive
    },
    toggleOnlyThisLocation() {
      this.onlyThisLocation = !this.onlyThisLocation
    },
    viewAttendee(user) {
      console.log(user)
      this.$store.commit('event/setCard', { attendee: user, modal: true })
    },
    talkToPerson(user) {
      log(user.id)
      this.$store.commit('chat/setState', { view: 'CHAT', talking_to: user })
    },
  },
}
</script>

<style></style>
