<template>
  <span
    class="tw-inline-flex tw-rounded-3xl tw-border tw-bg-gray-100 tw-p-0.5 tw-transition hover:tw-bg-gray-200"
  >
    <label
      v-for="option in options"
      :key="option.value"
      class="tw-relative tw-cursor-pointer tw-rounded-3xl focus-within:tw-ring focus-within:tw-ring-primary-300"
    >
      <!-- name="server_size" -->
      <input
        v-model="input_value"
        type="radio"
        name="server_size"
        :value="option.value"
        class="tw-sr-only"
        aria-labelledby="server-size-0-label"
        aria-describedby="server-size-0-description-0 server-size-0-description-1"
      />

      <div
        v-ripple
        class="tw-rounded-3xl tw-p-1.5 tw-transition focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-50 lg:tw-px-2.5 xl:tw-px-3.5"
        :class="{
          ' tw-bg-white tw-shadow': option.value === input_value,
        }"
      >
        <!-- <g-icon name="list" class="tw-w-5 tw-h-5 tw-text-gray-600" /> -->
        {{ option.text }}
      </div>
      <div
        :class="
          option.value === input_value
            ? 'tw-border-gray-200'
            : 'tw-border-transparent'
        "
        class="tw-pointer-events-none tw-absolute tw--inset-px tw-rounded-3xl tw-border-2 tw-border-transparent"
        aria-hidden="true"
      />
    </label>
  </span>
  <!-- <div
    class="
      group
      tw-p-0.5 tw-rounded-lg tw-flex tw-bg-gray-100
      hover:tw-bg-gray-200
    "
  >
    <button
      x-ref="preview"
      type="button"
      class="
        tw-flex
        focus-visible:tw-ring-2
        focus-visible:ring-teal-500
        focus-visible:tw-ring-offset-2
        tw-rounded-md
        focus:tw-outline-none
        focus-visible:tw-ring-offset-gray-100
      "
      @click="activeTab = 'preview'"
      :tabindex="activeTab === 'preview' ? '0' : '-1'"
      @keydown.arrow-left="activeTab = 'code'"
      @keydown.arrow-right="activeTab = 'code'"
      tabindex="0"
    >
      <span
        x-show="false"
        class="
          tw-p-1.5
          lg:tw-pl-2.5 lg:tw-pr-3.5
          tw-rounded-md
          tw-flex
          tw-items-center
          tw-text-sm
          tw-font-medium
          tw-bg-white
          tw-shadow-sm
          tw-ring-1
          tw-ring-black
          tw-ring-opacity-5
        "
        style="display: none"
      >
        <svg
          aria-hidden="true"
          width="20"
          height="20"
          fill="none"
          class="lg:tw-mr-2 tw-text-teal-500"
        >
          <path
            d="M17.25 10c0 1-1.75 6.25-7.25 6.25S2.75 11 2.75 10 4.5 3.75 10 3.75 17.25 9 17.25 10z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <circle
            cx="10"
            cy="10"
            r="2.25"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></circle>
        </svg>
        <span class="tw-text-gray-900 tw-sr-only lg:tw-not-sr-only"
          >Preview</span
        >
      </span>
      <span
        x-show="true"
        class="
          tw-p-1.5
          lg:tw-pl-2.5 lg:tw-pr-3.5
          tw-rounded-md
          tw-flex
          tw-items-center
          tw-text-sm
          tw-font-medium
          tw-bg-white
          tw-shadow-sm
          tw-ring-1
          tw-ring-black
          tw-ring-opacity-5
        "
        :class="
          activeTab === 'preview'
            ? 'tw-bg-white tw-shadow-sm tw-ring-1 tw-ring-black tw-ring-opacity-5'
            : ''
        "
      >
        <svg
          aria-hidden="true"
          width="20"
          height="20"
          fill="none"
          class="lg:tw-mr-2 tw-text-teal-500"
          :class="
            activeTab === 'preview'
              ? 'tw-text-teal-500'
              : 'tw-text-gray-500 group-hover:tw-text-gray-900'
          "
        >
          <path
            d="M17.25 10c0 1-1.75 6.25-7.25 6.25S2.75 11 2.75 10 4.5 3.75 10 3.75 17.25 9 17.25 10z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <circle
            cx="10"
            cy="10"
            r="2.25"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></circle>
        </svg>
        <span
          class="tw-sr-only lg:tw-not-sr-only tw-text-gray-900"
          :class="
            activeTab === 'preview'
              ? 'tw-text-gray-900'
              : 'tw-text-gray-600 group-hover:tw-text-gray-900'
          "
          >Preview</span
        >
      </span>
    </button>
    <button
      x-ref="code"
      type="button"
      class="
        tw-ml-0.5 tw-p-1.5
        lg:tw-pl-2.5 lg:tw-pr-3.5
        tw-rounded-md
        tw-flex
        tw-items-center
        tw-text-sm
        tw-text-gray-600
        tw-font-medium
        focus-visible:tw-ring-2
        focus-visible:ring-teal-500
        focus-visible:tw-ring-offset-2
        focus:tw-outline-none
        focus-visible:tw-ring-offset-gray-100
      "
      :class="
        activeTab === 'code'
          ? 'tw-bg-white tw-shadow-sm tw-ring-1 tw-ring-black tw-ring-opacity-5'
          : ''
      "
      @click="activeTab = 'code'"
      tabindex="-1"
      :tabindex="activeTab === 'code' ? '0' : '-1'"
      @keydown.arrow-left="activeTab = 'preview'"
      @keydown.arrow-right="activeTab = 'preview'"
    >
      <svg
        aria-hidden="true"
        width="20"
        height="20"
        fill="none"
        class="lg:tw-mr-2 tw-text-gray-500 group-hover:tw-text-gray-900"
        :class="
          activeTab === 'code'
            ? 'tw-text-teal-500'
            : 'tw-text-gray-500 group-hover:tw-text-gray-900'
        "
      >
        <path
          d="M13.75 6.75l3.5 3.25-3.5 3.25M6.25 13.25L2.75 10l3.5-3.25"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
      <span
        class="
          tw-sr-only
          lg:tw-not-sr-only
          tw-text-gray-600
          group-hover:tw-text-gray-900
        "
        :class="
          activeTab === 'code'
            ? 'tw-text-gray-900'
            : 'tw-text-gray-600 group-hover:tw-text-gray-900'
        "
        >Code</span
      >
    </button>
  </div> -->
</template>

<script>
// import GIcon from '@/components/GIcon.vue'
export default {
  components: {
    // GIcon,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    toggle: 'hi',
  }),
  computed: {
    input_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>

<style></style>
