import $axios from '@/axios-instance.js'

class LicenseServiceClass {
  baseUrl = '/api/v1/admin/license'

  execute(params) {
    return $axios.get(this.baseUrl, params)
  }
}
export const LicenseService = new LicenseServiceClass()
