<template>
    <v-dialog v-model="open" persistent scrollable max-width="1024px" content-class="tw-rounded-xl tw-w-full">

        <v-card class="tw-relative tw-rounded-xl tw-border-0 tw-shadow-md">

            <ModalHeader @close-modal="closeModal"> {{ $t('bz2.event.book_appointment.calendar') }} </ModalHeader>

            <v-divider />

            <v-card-text class="tw-px-4 tw-py-6 tw-bg-gray-100 tw-relative">
                <!-- ------------------- Create ------------------- -->
                <EventCalendarCreate v-model="openCreateValue" @submit-create="getRequest" />
                <!-- ------------------- Notifications ------------------- -->
                <EventCalendarLiveNotifications 
                    v-model="openNotificationValue" :rows-request="parsedRequest"
                    :rows-received="parsedReceived" @accepted-success="acceptedAction" />
                <!-- ------------------- Calendar ------------------- -->
                <EventCalendarLive 
                    :is-loading="is_loading" :calendar="parsedEvents" :calendar-id="currentCalendar"
                    @open-create="openCreate" @open-notifications="openNotications" @delete-item="deleteAction" />
            </v-card-text>

        </v-card>

    </v-dialog>
</template>

<script>
import ModalHeader from '@/components/ModalHeader'
import { mapState } from 'vuex'
import { format } from 'date-fns/esm'
// import BookAppointment from '@/components/Event/EventBookAppointmentCard.vue'
import EventCalendarLive from '@/components/Event/EventCalendarLive.vue'
// import UserItemRq from '@/components/Event/EventBookAppointmentUserItemRequest'
import { dateDetailFormat } from '@/enums/CalendarTypeKeys'
import EventCalendarCreate from '@/components/Event/EventBookAppointmentForm.vue'
import EventCalendarLiveNotifications from '@/components/Event/EventCalendarLiveNotifications'
import { diffForHumans } from '@/utils/dateHelpers'
export default {
    components: {
        ModalHeader,
        EventCalendarLive,
        EventCalendarCreate,
        EventCalendarLiveNotifications,
        // UserItemRq,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        // ---------------- Calendar ---------------- //
        is_loading: false,
        calendars: [],
        // ---------------- Notifications ---------------- //
        user_request: [],
        user_received: [],
        // ---------------- Modal: Create - Edit - Notifications---------------- //
        openCreateValue: false,
        openNotificationValue: false,
    }),
    computed: {
        open: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
        ...mapState({
            current_config_language_key: (state) => state.event.constants.event_configs.data.current_config_language_key,
            registry: (state) => state.event.constants.registry,
        }),
        currentCalendar() {
            return this.registry.calendars.find(
                (row) => row.language.name == this.current_config_language_key,
            )
        },
        parsedEvents() {
            // ----------------- 4/07/2023 ----------------- //
            const calendar = this.calendars.find(
                (row) => row.id == this.currentCalendar.id,
            )
            // ------ Valid ------ //
            if (!calendar) {
                return
            }
            // ------ Map calendar ------ //
            return calendar.events.map((row) => ({
                ...row,
                name: row.label,
                details: row.description,
                start: format(new Date(row.start_date_time), 'yyyy-MM-dd hh:mm'),
                end: format(new Date(row.end_date_time), 'yyyy-MM-dd hh:mm'),
                start_detail: dateDetailFormat(row.start_date_time),
                end_detail: dateDetailFormat(row.end_date_time),
                start_date_time: row.start_date_time,
                end_date_time: row.end_date_time,
                color: row.color_appointment ? row.color_appointment : '#3565AF',
                timed: '',
                is_appointment: row.is_appointment,
            })).filter((row) => row.status_appointment)
        },
        parsedRequest() {
            return this.user_request.map((row) => ({
                ...row,
                date_request: dateDetailFormat(row.registry_calendar_item.start_date_time),
                date_laps: diffForHumans(row.registry_calendar_item.created_at),
            }))
        },
        parsedReceived() {
            return this.user_received.map((row) => ({
                ...row,
                date_request: dateDetailFormat(row.registry_calendar_item.start_date_time),
                date_laps: diffForHumans(row.registry_calendar_item.created_at),
            }))
        },
    },
    created() {
        this.init()
    },
    methods: {
        closeModal() {
            this.open = false
        },
        openCreate() {
            this.openCreateValue = true
        },
        openNotications() {
            this.openNotificationValue = true
        },
        init() {
            this.getCalendar().finally(() => {
                this.getReceived()
                this.getRequest()
                this.is_loading = false
            })
        },
        acceptedAction() {
            this.getCalendar().finally(() => {
                this.is_loading = false
                this.getReceived()
            })
        },
        deleteAction() {
            this.getCalendar().finally(() => {
                this.is_loading = false
                this.getRequest()
            })
        },
        getRequest() {
            return this.$store.dispatch('event/getRegistryCalendarRequests', { payload: {} })
                .then((data) => {
                    this.user_request = data.data
                })
        },
        getReceived() {
            return this.$store.dispatch('event/getRegistryCalendarReceived', { payload: {} })
                .then((data) => {
                    this.user_received = data.data
                })
        },
        getCalendar() {
            this.is_loading = true
            return this.$store.dispatch('event/registryCalendarIndex', { payload: {} })
                .then(({ data }) => {
                    console.log('🚀 ~ file: EventCalendarModal.vue:139 ~ .then ~ init')
                    this.calendars = data
                })
                .catch((err) => {
                    console.log('🚀 ~ file: EventCalendarModal.vue:127 ~ getCalendar ~ err:', err)
                })
        },
    },
}
</script>
