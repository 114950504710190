import $axios from '@/axios-instance.js'

class CalendarsItemsServiceClass {
  baseUrl = '/api/v1/admin/calendars'
  child = 'items'
  child_appointment = 'items-appointment'

  // index
  index(calendar, params) {
    return $axios.get(`${this.baseUrl}/${calendar}/${this.child}`, params)
  }
  // Create & Update
  store(calendar, params) {
    return $axios.post(`${this.baseUrl}/${calendar}/${this.child}`, params)
  }
  destroy(calendar, id, params) {
    return $axios.post(`${this.baseUrl}/${calendar}/${this.child}/${id}`, {
      ...params,
      _method: 'DELETE',
    })
  }
  storeAppointment(calendar, params) {
    return $axios.post(`${this.baseUrl}/${calendar}/${this.child_appointment}`, params)
  }
}
export const CalendarsItemsService = new CalendarsItemsServiceClass()
