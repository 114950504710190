import { VirtualRoomElementsServiceInstance } from '@/services/VirtualRoomElementsService'
import { VirtualRoomsServiceInstance } from '@/services/VirtualRoomsService'
import { formErrorsHandler } from '@/utils/formErrorsHandler'
import { DBRoomElementModel } from '@/utils/RoomElement'
import { DurationBase } from '@/models/Duration'
// TODO: VirtualRoom Service
import { VirtualRoomRegistryRoomService } from '@/services/VirtualRoomRegistryTicket'

import {
  dd_mm_yyyy,
  hh_mm,
  // has_time_bracket,
  time_bracket_type,
} from '@/utils/dateHelpers'
import { ClickableBase, setClickableFromDB } from '@/models/Clickable'
// state
const initState = () => {
  return {
    create: {
      hasErrors: false,
      errors: {},
      stepRoutes: {
        step_one: 'admin.booths.create.one',
        step_two: 'admin.booths.create.two',
      },
      data: {
        step_one: {
          room_type: '',
          label: '',
          description: '',
          slug: '',
          language_key: [],
          has_time_bracket: false,
          time_bracket_type: 'start_end',
          time_bracket_tz: '',
          start_date: '',
          start_time: '',
          end_date: '',
          end_time: '',
          is_active: true,
          time_brackets: [],
          has_autolaunch: false,
          // 
          has_auto_open_chat: false,
          click_action_type: {},
          click_action_type_id: '',
          link_thread_id: '',
          link_channel_id: '',
          // 
          has_password_room: false,
          otp_room: '',
        },
        step_two: {
          bg_desktop: '',
          bg_mobile: '',
        },
      },
    },
    update: {
      data: {
        room_type: '',
        label: '',
        description: '',
        slug: '',
        language_key: [],
        has_time_bracket: false,
        time_bracket_type: 'start_end',
        time_bracket_tz: '',
        start_date: '',
        start_time: '',
        end_date: '',
        end_time: '',
        is_active: true,
        time_brackets: [],
        has_autolaunch: false,
        // 
        has_auto_open_chat: false,
        click_action_type: {},
        click_action_type_id: '',
        link_thread_id: '',
        link_channel_id: '',
        // 
        bg_desktop: '',
        bg_mobile: '',
        builders: [],
        reps: [],
        has_rep_badge_present: false,
        has_custom_footer: false,
        footer_color: '#FFFFFF',
        custom_css: '',
        has_music_track: false,
        music_track_url: '',
        clickable: ClickableBase({}),
        autolaunch_type: '',
        autolaunch_image: '',
        autolaunch_video: '',
        autolaunch_text: '',
        can_autolaunch_repeat: false,
        autolaunch_start_time: '',
        autolaunch_end_time: '',
        autolaunch_tz: '',
        has_autolaunch_bracket: false,
        has_autolaunch_label_button: '',
        content_library_element_id: '',
        has_password_room: false,
        otp_room: '',
      },
    },
    show: {
      virtualRoom: {
        room_type: {},
        creator: {},
      },
      room_elements: [],
      loading: false,
    },
  }
}

// getters
const getters = {}

// actions
const actions = {
  cancelUpdate({ commit, state }) {
    commit('setVirtualRoomUpdate', state.show.virtualRoom)
  },
  show({ commit }, { id, load_elements = true }) {
    commit('setShowLoading', true)
    return VirtualRoomsServiceInstance.show(id)
      .then(({ data }) => {
        const { virtual_room } = data
        commit('setVirtualRoom', virtual_room)
        console.log({ virtual_room })
        commit('setVirtualRoomUpdate', virtual_room)

        if (load_elements) {
          // console.log(rootGetters['constants/element_types'])
          commit(
            'setVirtualRoomElements',
            virtual_room.elements.map((row) => {
              return DBRoomElementModel(row)
            }),
          )
        }
        // hidratar formulario de virtual_room update
        return data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setShowLoading', false)
      })
  },
  createOrUpdateElements({ state, commit }) {
    const form = {
      elements: state.show.room_elements,
    }
    // console.log(form);
    // console.log(state.show.virtualRoom.id);
    commit('setAppIsLoading', true, { root: true })
    return VirtualRoomElementsServiceInstance.store(
      state.show.virtualRoom.id,
      form,
    )
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err, true)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  store({ state, commit, dispatch }) {
    const form = {
      ...state.create.data.step_one,
      bg_desktop: state.create.data.step_two.bg_desktop.link,
      bg_mobile: state.create.data.step_two.bg_mobile.link,
    }
    // ...state.create.data.step_two,
    commit('setAppIsLoading', true, { root: true })
    return VirtualRoomsServiceInstance.store(form)
      .then((res) => {
        commit('cleanCreateData')
        dispatch('constants/loadRooms', null, { root: true })
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  update({ state, commit, dispatch }) {
    const form = {
      ...state.update.data,
      _method: 'PUT',
    }
    // ...state.create.data.step_two,
    commit('setAppIsLoading', true, { root: true })
    return VirtualRoomsServiceInstance.update(state.show.virtualRoom.id, form)
      .then((res) => {
        commit('cleanCreateData')
        dispatch('constants/loadRooms', null, { root: true })
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  // TODO: Assign Evemt pr Class to Room
  assignEventOrClass({state},{payload}) {
    const id = state.show.virtualRoom.id
    return VirtualRoomRegistryRoomService.store(id, payload)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
}

// mutations
const mutations = {
  cleanCreateData(state) {
    state.create.data = {
      step_one: {
        room_type: '',
        label: '',
        description: '',
        slug: '',
        language_key: [],
        has_time_bracket: false,
        time_bracket_type: 'start_end',
        time_bracket_tz: '',
        start_date: '',
        start_time: '',
        end_date: '',
        end_time: '',
        is_active: true,
        time_brackets: [],
      },
      step_two: {
        bg_desktop: '',
        bg_mobile: '',
      },
    }
  },
  setShowLoading(state, bool) {
    state.show.loading = bool
  },
  setVirtualRoom(state, obj) {
    Object.keys(obj).forEach((k) => (state.show.virtualRoom[k] = obj[k]))
  },
  setVirtualRoomElements(state, arr) {
    state.show.room_elements = arr
  },
  setVirtualRoomUpdate(state, obj) {
    const tz = obj.time_bracket_tz ? obj.time_bracket_tz : 'UTC'
    state.update.data = {
      ...state.update.data,
      room_type: obj.room_type.name,
      label: obj.label,
      description: obj.description,
      slug: obj.slug,
      language_key: obj.languages.map(({ language }) => language.name),
      has_time_bracket: !!obj.has_time_bracket,
      time_bracket_type: time_bracket_type(
        obj.start_date_time,
        obj.end_date_time,
      ),
      time_bracket_tz: obj.time_bracket_tz,
      start_date: dd_mm_yyyy(obj.start_date_time, tz),
      start_time: hh_mm(obj.start_date_time, tz),
      end_date: dd_mm_yyyy(obj.end_date_time, tz),
      end_time: hh_mm(obj.end_date_time, tz),
      is_active: !!obj.is_active,
      time_brackets: obj.time_brackets.map((row) => ({
        id: row.id,
        ...DurationBase({
          has_time_bracket: true,
          start_date: dd_mm_yyyy(row.start_date_time, obj.time_bracket_tz),
          start_time: hh_mm(row.start_date_time, obj.time_bracket_tz),
          end_date: dd_mm_yyyy(row.end_date_time, obj.time_bracket_tz),
          end_time: hh_mm(row.end_date_time, obj.time_bracket_tz),
        }),
      })),
      bg_desktop: obj.bg_desktop,
      bg_mobile: obj.bg_mobile,
      builders: obj.builders.map((row) => row.id),
      reps: obj.reps.map((row) => row.id),
      has_custom_footer: obj.has_custom_footer,
      has_rep_badge_present: obj.has_rep_badge_present,
      footer_color: obj.footer_color ? obj.footer_color : '#FFFFFF',
      custom_css: obj.custom_css,
      has_music_track: obj.has_music_track,
      music_track_url: obj.music_track_url,
      has_autolaunch: obj.has_autolaunch,
      // 
      has_auto_open_chat: obj.has_auto_open_chat,
      click_action_type: obj.click_action_type,
      click_action_type_id: obj.click_action_type_id,
      link_thread_id: obj.link_thread_id,
      link_channel_id: obj.link_channel_id,
      // 
      clickable: obj.clickable ? setClickableFromDB(obj.clickable) : ClickableBase({}),
      autolaunch_type: obj.autolaunch_type,
      autolaunch_image: obj.autolaunch_image,
      autolaunch_video: obj.autolaunch_video,
      autolaunch_text: obj.autolaunch_text,
      can_autolaunch_repeat: obj.can_autolaunch_repeat,
      has_autolaunch_bracket: obj.has_autolaunch_bracket,
      has_autolaunch_label_button: obj.has_autolaunch_label_button,

      autolaunch_tz: obj.autolaunch_tz,
      autolaunch_start_date: dd_mm_yyyy(obj.autolaunch_start_date_time, obj.autolaunch_tz),
      autolaunch_start_time: hh_mm(obj.autolaunch_start_date_time, obj.autolaunch_tz),
      autolaunch_end_date: dd_mm_yyyy(obj.autolaunch_end_date_time, obj.autolaunch_tz),
      autolaunch_end_time: hh_mm(obj.autolaunch_end_date_time, obj.autolaunch_tz),

      content_library_element_id: obj.content_library_element_id,
      //
      has_password_room: obj.has_password_room,
      otp_room: obj.otp_room,
    }
  },
  setStepOne(state, obj) {
    Object.keys(obj).forEach((k) => (state.create.data.step_one[k] = obj[k]))
  },
  setStepTwo(state, obj) {
    Object.keys(obj).forEach((k) => (state.create.data.step_two[k] = obj[k]))
  },
  setUpdateForm(state, obj) {
    Object.keys(obj).forEach((k) => (state.update.data[k] = obj[k]))
  },
}

export const VirtualRooms = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
}
