<template>
  <v-card
    class="tw-relative tw-rounded-3xl tw-border-0 tw-bg-black tw-bg-opacity-75 tw-shadow-md"
    :class="[video.content_elements.can_chat ? 'fill-height ' : 'tw-aspect-h-9']"
  >
    <v-container
      id="iframe-wrapper"
      class="tw-flex-nowrap tw-p-0"
      :class="[video.content_elements.can_chat ? 'fill-height ' : 'tw-aspect-h-9']"
      fluid
    >
      <v-container
        class="tw-col-span-5 tw-h-full tw-w-full tw-flex-1 tw-flex-grow tw-bg-transparent tw-p-0 tw-shadow-none"
        :class="[video.content_elements.can_chat ? 'all-iframes-w-full ' : '']"
        fluid
      >
        <AppPlyr v-if="simpleVideoItemType == 'uploaded'">
          <video
            ref="uploaded"
            controls
            crossorigin
            playsinline
            :data-poster="simpleVideoItemPreview"
            :autoplay="!!video.content_elements.is_autoplay"
          >
            <source :src="video.content_elements.video_item_url" type="video/mp4" />
          </video>
        </AppPlyr>
        <vimeo-player
          v-if="simpleVideoItemType == 'vimeo'"
          ref="player"
          :class="[
            video.content_elements.can_chat
              ? 'tw-h-full tw-w-full'
              : 'tw-aspect-w-16 tw-aspect-h-9',
          ]"
          :video-url="video.content_elements.video_item_url"
          :options="video.content_elements.is_autoplay ? { autoplay: true } : {}"
        />
        <LazyYoutube
          v-if="simpleVideoItemType == 'youtube'"
          ref="youtube"
          :src="video.content_elements.video_item_url"
          :class="[
            video.content_elements.can_chat
              ? 'tw-h-full tw-w-full'
              : '',
          ]"
          aspect-ratio="16:9"
          :autoplay="!!video.content_elements.is_autoplay"
        />
      </v-container>
      <EventRoomContentElementChat
        v-if="video.content_elements.can_chat && loaded"
        class="tw-col-span-2"
        :parrent-channel-id="channel_id"
        :can-chat="video.content_elements.can_chat"
        :can-close-popup="canClose"
        :can-thread="video.content_elements.can_thread"
        :parrent-thread-id="thread_id"
      />
    </v-container>
  </v-card>
</template>

<script>
import AppPlyr from '@/components/Video/AppPlyr.vue'
import EventRoomContentElementChat from './EventRoomContentElementChat.vue'
import { VideoValues } from '@/enums/VideoKeys'
export default {
  components: {
    AppPlyr,
    EventRoomContentElementChat,
  },
  props: {
    canClose: {
      type: Boolean,
      default: false,
    },
    video: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    channel_id: 0,
    thread_id: 0,
    loaded: false,
  }),
  computed: {
    simpleVideoItemType() {
      return VideoValues[this.video.content_elements.video_item_type_id]
    },
  },
  mounted() {
    if (this.video.link_channel_id) {
      this.channel_id = this.video.link_channel_id
    }
    if (this.video.link_thread_id) {
      this.thread_id = this.video.link_thread_id
    }
    this.$nextTick(() => {
      this.loaded = true
    })
  },
  methods: {
    pauseElement() {
       // Mp4
      if (this.$refs.uploaded) {
        this.$refs.uploaded.plyr.pause()
      }
      // Youtube
      if (this.$refs.youtube) {
        this.$refs.youtube.pauseVideo()
      }
      // Vimeo
      if (this.$refs.player) {
        this.$refs.player.pause()
      }
    },
  },
}
</script>

<style>
.all-iframes-w-full iframe {
  width: 100%;
  height: 100%;
}
</style>