<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="1024px"
    content-class="tw-rounded-3xl"
  >
    <v-form
      ref="form"
      v-model="valid"
      :disabled="form_disabled_state"
      lazy-validation
      enctype="multipart/form-data"
      @submit.prevent="update"
    >
      <v-card class="tw-rounded-3xl tw-border-0 tw-shadow-md">
        <ModalHeader @close-modal="closeModal">{{
          $t('app.field')
        }}</ModalHeader>
        <v-divider />
        <v-card-text class="tw-p-0" style="max-height: 700px">
          <v-responsive class="tw-py-0">
            <v-container class="tw-bg-gray-100">
              <v-row class="mb-5">
                <v-col cols="12" md="4" class="px-5 pt-5 mx-auto">
                  <form-description>
                    <template #title>
                      {{ $t('admin.events.field_settings') }}
                    </template>
                    <template #copy>
                      {{ $t('admin.events.field_settings_description') }}
                    </template>
                  </form-description>
                </v-col>
                <v-col cols="12" md="8" class="mx-auto pa-5">
                  <v-card
                    class="
                      mx-auto
                      tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-shadow-md
                    "
                  >
                    <v-container class="tw-p-5 tw-pb-0">
                      <div class="tw-grid tw-grid-cols-3 tw-gap-4">
                        <div class="tw-col-span-2">
                          <v-text-field
                            ref="new_exam"
                            v-model="mountedFormInput.label"
                            :rules="[rules.testEmpty]"
                            validate-on-blur
                            outlined
                            rounded
                            color="primary darken-1"
                            :label="$t('app.title')"
                          />
                        </div>
                        <div class="tw-col-span-1">
                          <v-select
                            v-model="mountedFormInput.cols"
                            :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                            outlined
                            rounded
                            :label="$t('app.width')"
                          />
                        </div>
                      </div>
                    </v-container>
                    <v-container class="tw-p-5 tw-pb-0">
                      <div>
                        <h3
                          class="
                            tw-text-lg
                            tw-font-medium
                            tw-leading-6
                            tw-text-gray-900
                          "
                        >
                          {{ $t('app.attributes') }}
                        </h3>
                        <p class="tw-mt-1 tw-mb-0 tw-text-sm tw-text-gray-500">
                          {{ $t('admin.app.extra_config_description') }}
                        </p>
                      </div>
                      <div class="tw-grid tw-w-full tw-grid-cols-3">
                        <div>
                          <v-switch
                            v-model="mountedFormInput.is_active"
                            inset
                            :label="$t('app.active')"
                            color="primary"
                          />
                        </div>
                        <div>
                          <v-switch
                            v-model="mountedFormInput.is_required"
                            inset
                            :disabled="!mountedFormInput.is_active"
                            :label="$t('app.required')"
                            color="primary"
                          />
                        </div>
                        <div>
                          <v-switch
                            v-model="mountedFormInput.is_public"
                            inset
                            :disabled="!mountedFormInput.is_active"
                            :label="$t('app.on_card')"
                            color="primary"
                          />
                        </div>
                      </div>
                    </v-container>
                    <v-container class="tw-px-5 tw-pt-8 tw-pb-0">
                      <div class="tw-grid tw-w-full tw-grid-cols-3 tw-gap-4">
                        <div class="tw-col-span-2">
                          <v-select
                            v-model="mountedFormInput.field_type"
                            :items="formatFields"
                            item-text="i18_label"
                            item-value="value"
                            outlined
                            rounded
                            :label="$t('app.type')"
                          />
                        </div>
                        <div
                          v-if="mountedFormInput.field_type == 'number'"
                          class="tw-col-span-1"
                        >
                          <v-text-field
                            v-model="mountedFormInput.characters"
                            :label="$t('app.characters')"
                            type="number"
                            outlined
                            rounded
                          />
                        </div>
                      </div>
                    </v-container>
                    <v-container
                      v-if="hasOptionsField"
                      class="tw-space-y-6 tw-bg-gray-50 tw-px-5"
                    >
                      <div class="tw-space-y-1">
                        <div
                          v-for="option in mountedFormInput.options"
                          :key="option.uid"
                          class="tw-flex tw-items-center tw-space-x-4"
                        >
                          <div class="tw-flex-1">
                            <v-text-field
                              ref="new_exam"
                              v-model="option.text"
                              :rules="[rules.testEmpty]"
                              validate-on-blur
                              filled
                              rounded
                              color="primary darken-1"
                              :label="$t('app.option')"
                              @blur="onBlurOption(option)"
                            />
                          </div>
                          <div class="tw-mb-7">
                            <v-btn
                              text
                              fab
                              small
                              class="
                                tw-bg-transparent tw-font-bold tw-normal-case
                                focus:tw-outline-none
                                focus:tw-ring
                                focus:tw-ring-gray-300
                              "
                              @click="deleteItem(option)"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                class="tw-h-6 tw-w-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <div class="tw-flex tw-justify-start">
                        <v-btn
                          color="white"
                          rounded
                          class="
                            tw-bg-transparent
                            tw-px-8
                            tw-font-bold
                            tw-normal-case
                            tw-shadow
                            focus:tw-outline-none
                            focus:tw-ring
                            focus:tw-ring-gray-300
                          "
                          @click="addOption"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="tw-mr-2 tw--ml-1 tw-h-6 tw-w-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                            />
                          </svg>
                          {{ $t('app.add_option') }}
                        </v-btn>
                      </div>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-responsive>
        </v-card-text>
        <v-container>
          <transition name="fade-transition" mode="out-in">
            <v-row v-if="errorAlert">
              <v-col cols="12">
                <v-alert
                  v-model="errorAlert"
                  dismissible
                  color="red"
                  border="left"
                  elevation="2"
                  colored-border
                  icon="error"
                  transition="fade-transition"
                >
                  {{ errorMsg }}</v-alert
                >
              </v-col>
            </v-row>
          </transition>
        </v-container>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            rounded
            :disabled="form_loading"
            class="
              tw-px-8 tw-normal-case
              focus:tw-outline-none focus:tw-ring focus:tw-ring-white
            "
            @click="closeModal"
          >
            {{ $t('app.cancel') }}</v-btn
          >
          <v-btn
            color="primary darken-1"
            rounded
            type="submit"
            :loading="form_loading"
            class="
              text--white text--lighten-4
              tw-px-8 tw-normal-case
              focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-300
            "
          >
            {{ $t('app.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ModalHeader from '@/components/ModalHeader'
import FormDescription from '@/components/FormDescription'
import { mapState } from 'vuex'

// import RadioBtn from "@/components/FormInputs/RadioBtn";
import { FormOption } from '@/models/RegistryForm'
import { slugify } from '@/helpers'
export default {
  components: {
    ModalHeader,
    // RadioBtn,
    FormDescription,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formInput: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    errorAlert: '',
    errorMsg: '',
    exam: '',
    rules: {
      numericZero: (v) => !!parseFloat(v) || 'Campo no puede ser 0',
      testEmpty: (value) => !!value || 'Campo no puede estar vacio.',
      min: (v) => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => "The email and password you entered don't match",
    },
    isLoading: false,
    form_loading: false,
    form_disabled_state: false,
    valid: true,
    mountedFormInput: {},
    isLoadingRooms: false,
    formatFields: [],
  }),
  computed: {
    hasOptionsField() {
      return ['radio', 'checkbox', 'select'].includes(
        this.mountedFormInput.field_type,
      )
    },
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    ...mapState({
      fields: (state) => state.constants.data.fields,
    }),
  },
  mounted() {
    // this.mountedFormInput = Object.assign({}, this.formInput)
    this.formatFields = this.fields.map((row) => ({
      ...row,
      i18_label: this.$t(`app.field_select.${row.icon}`),
    }))
    this.mountedFormInput = JSON.parse(JSON.stringify(this.formInput))
  },
  methods: {
    deleteItem(row_deleted) {
      console.log(row_deleted)
      this.mountedFormInput.options = this.mountedFormInput.options.filter(
        (row) => row.uid != row_deleted.uid,
      )
    },
    onBlurOption(option) {
      // option
      const updated_option = { ...option, value: slugify(option.text) }
      this.mountedFormInput.options = this.mountedFormInput.options.map((row) =>
        row.uid === option.uid ? updated_option : row,
      )
    },
    addOption() {
      this.mountedFormInput.options = [
        ...this.mountedFormInput.options,
        FormOption({}),
      ]
    },
    closeModal() {
      this.open = false
    },
    update() {
      this.errorAlert = false
      if (!this.$refs.form.validate()) {
        return
      }
      this.mountedFormInput.name = slugify(this.mountedFormInput.label)
      this.$emit('updated-form-input', this.mountedFormInput)
      this.open = false
    },
  },
}
</script>

<style></style>
