<template>
  <v-container fluid class="py-0">
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="11" xl="9" class="tw-mx-auto">
        <div class="container px-5 py-24 flex flex-wrap tw-mx-auto">
          <div class="flex flex-wrap -m-4">
            <div class="tw-mx-auto tw-p-4 md:tw-w-full lg:tw-w-1/2">
              <div
                class="tw-flex tw-flex-col tw-rounded-lg tw-border-2 tw-border-gray-200 tw-bg-white tw-p-8 tw-shadow-md sm:tw-flex-row"
              >
                <div
                  class="tw-mb-4 tw-inline-flex tw-h-16 tw-w-16 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-red-100 tw-text-red-500 sm:tw-mr-8 sm:tw-mb-0"
                >
                  <svg
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="tw-h-8 tw-w-8"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="tw-flex-grow">
                  <h2
                    class="tw-title-font tw-mb-3 tw-text-lg tw-font-medium tw-text-gray-900"
                  >
                    404
                  </h2>
                  <p class="tw-text-base tw-leading-relaxed">
                    {{ $t('app.404_text') }}
                  </p>
                  <v-btn to="/" rounded color="grey lighten-5 " depressed>
                    {{ $t('app.back') }}
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>

<style></style>
