<template>
<!-- Views -->
<div
class="
        tw-z-10
        tw-rounded
        tw-shadow-md
        tw-h-full
    ">
    <v-slide-x-transition>
        <v-card v-if="note_view == UserNoteViews.index" class="tw-shadow-md tw-px-4 tw-py-2 tw-h-full tw-border-none">
            <!-- header -->
            <div class="tw-flex tw-items-center tw-justify-between">
                <span class="tw-text-sm tw-font-bold tw-text-secondary-400">{{ $t('pages.event.chat.notes.title')
                }}</span>
                <v-btn icon @click="onNew">
                    <v-icon class="tw-text-secondary-300">post_add</v-icon>
                </v-btn>
            </div>
            <!-- Search -->
            <div v-if="false" class="tw-py-2">
                <v-text-field outlined :label="$t('app.search')" prepend-inner-icon="search" dense rounded single-line hide-details class="tw-text-sm" />
            </div>
            <!-- Notes -->
            <div class="tw-py-2">
                <div v-if="notesHighlight.length > 0" class="tw-py-2">
                    <span class="tw-ml-4 tw-font-bold tw-text-sm">{{ $t('pages.event.chat.notes.highlights') }}</span>
                    <v-card class="tw-bg-[#F3F2F8] tw-shadow-none tw-rounded-lg">
                        <ListNotes v-for="note in notesHighlight" :key="note.id" :note="note" @show-note="showNote" />
                    </v-card>
                </div>
                <!-- <v-divider /> -->
                <div v-if="notesAll.length > 0">
                    <div class="relative tw-py-2">
                        <!-- <span class="tw-ml-4 tw-font-bold tw-text-sm">Notas</span> -->
                        <v-card class="tw-bg-[#F3F2F8] tw-shadow-none tw-rounded-lg tw-h-full bz-list-notes bz-scroll">
                            <ListNotes v-for="note in notesAll" :key="note.id" :note="note" @show-note="showNote" />
                        </v-card>
                    </div>
                </div>
            </div>
        </v-card>
    </v-slide-x-transition>
    <v-slide-x-transition>
        <v-card v-if="note_view == UserNoteViews.show" class="tw-shadow-md tw-p-4 tw-h-full tw-border-none">
            <NewNote @on-back="onBack" />
        </v-card>
        <v-card v-if="note_view == UserNoteViews.edit" class="tw-shadow-md tw-p-4 tw-h-full tw-border-none">
            <EditNote :only-note="onlyNote" @on-back="onBack" @set-note="setNote" />
        </v-card>
    </v-slide-x-transition>
</div>
</template>

<script>
import ListNotes from '@/components/Notes/ListNotes.vue'
import NewNote from '@/components/Notes/NewNote.vue'
import EditNote from '@/components/Notes/EditNote.vue'
// import {
//     groupBy,
// } from '@/helpers'
import {
    format,
} from 'date-fns'

const UserNoteViews = {
    index: 'index',
    show: 'show',
    edit: 'edit',
}

export default {
    components: {
        ListNotes,
        NewNote,
        EditNote,
    },
    props: {
        notes: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        UserNoteViews,
        note_view: UserNoteViews.index,
        onlyNote: {},
    }),
    computed: {
        formatNotes() {
            return this.notes.map((row) => ({
                ...row,
                date: format(new Date(row.created_at), 'yy/MM/dd hh:mm a'),
            }))
        },
        notesHighlight() {
            return this.formatNotes.filter((note) => note.highlight)
        },
        notesAll() {
            return this.formatNotes.filter((note) => !note.highlight)
        },
        // notesRows() {
        //     const new_group_notes = groupBy(
        //         this.notes.map((row) => ({
        //             ...row,
        //             formated_date: format(
        //                 new Date(row.created_at),
        //                 'yy/MM/dd hh:mm a',
        //             ),
        //             formated_date_stamp: format(
        //                 new Date(row.created_at),
        //                 'yyyy',
        //             ),
        //             // formated_date: new Date(row.created_at).getFullYear() == new Date().getFullYear() 
        //             //     ? format(new Date(row.created_at), 'hh:mm a') 
        //             //     : format(new Date(row.created_at), 'yy/MM/dd'),
        //         })).filter((note) => note.highlight != true),
        //         'formated_date_stamp',
        //     )
        //     return new_group_notes
        // },
    },
    methods: {
        setNote(note) {
            this.onlyNote = note
        },
        showNote(note) {
            this.setNote(note)
            this.note_view = UserNoteViews.edit
        },
        onBack() {
            this.note_view = UserNoteViews.index
        },
        onNew() {
            this.note_view = UserNoteViews.show
        },
    },
}
</script>

<style lang="scss" scoped>
.bz-list-notes {
    overflow-y: scroll;
    max-height: 330px;
}

.bz-scroll {
    &::-webkit-scrollbar {
        width: 2px;
        height: 3px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        /* roundness of the scroll thumb */
        border: 3px solid #ffffff;
        /* creates padding around scroll thumb */
    }
}
</style>
