<template>
  <v-form
    ref="form"
    v-model="valid"
    :disabled="isLoading"
    lazy-validation
    enctype="multipart/form-data"
    @submit.prevent="submitEvent"
  >
    <v-row class="mb-5 tw-pt-6">
      <v-col cols="12" md="3" class="px-5 pt-5 mx-auto">
        <FormDescription>
          <template #title>
            {{ $t('admin.app.details') }}
          </template>
          <template #copy>
            {{ $t('admin.events.items.access_control.form_description') }}
            <!-- Complete los campos y opciones a continuación para crear una regla. -->
          </template>
        </FormDescription>
      </v-col>
      <v-col cols="12" md="9" class="mx-auto pa-5">
        <v-card
          class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-shadow-md"
        >
          <v-container class="pa-5">
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <v-select
                  v-model="form.access_list_type"
                  :items="access_list_types"
                  :label="$t('admin.app.list')"
                  validate-on-blur
                  :rules="[rules.required]"
                  outlined
                  rounded
                />
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-select
                  v-model="form.control_type"
                  :items="control_types"
                  :label="$t('admin.app.type')"
                  validate-on-blur
                  :rules="[rules.required]"
                  outlined
                  rounded
                />
              </v-col>
            </v-row>
          </v-container>
          <v-container class="pa-5">
            <v-row>
              <v-col cols="12" sm="8" class="pb-0">
                <v-text-field
                  v-model="form.target_string"
                  :label="$t('admin.app.target')"
                  validate-on-blur
                  :rules="[rules.required]"
                  outlined
                  rounded
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <row-separator />
    <v-row class="mb-5">
      <v-col cols="12" md="9" class="px-0 py-0 ml-auto">
        <v-card
          class="mx-auto pa-5 tw-rounded-3xl tw-border-transparent tw-bg-transparent tw-shadow-none"
        >
          <transition name="fade-transition" mode="out-in">
            <v-row v-if="errorAlert">
              <v-col cols="12">
                <v-alert
                  v-model="errorAlertProxy"
                  dismissible
                  color="red"
                  border="left"
                  elevation="2"
                  colored-border
                  icon="error"
                  transition="fade-transition"
                >
                  {{ errorMsg }}
                </v-alert>
              </v-col>
            </v-row>
          </transition>
          <v-card-actions>
            <v-row>
              <v-col cols="12" class="pa-0">
                <div class="tw-flex tw-justify-end">
                  <AppBtn
                    color="primary"
                    :loading="isLoading"
                    icon="check"
                    type="submit"
                  >
                    {{ $t('app.save') }}
                  </AppBtn>
                </div>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import AppBtn from '@/components/App/AppBtn.vue'

import { rules } from '@/utils/rules'

import RowSeparator from '@/components/RowSeparator.vue'
import FormDescription from '@/components/FormDescription.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    AppBtn,
    FormDescription,
    RowSeparator,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
    errorAlert: {
      type: Boolean,
      required: false,
    },
    errorMsg: {
      type: String,
      required: '',
      default: '',
    },
  },
  data: () => ({
    valid: true,
    rules,
  }),
  computed: {
    ...mapGetters({
      access_list_types: ['constants/access_list_types'],
      control_types: ['constants/control_types'],
    }),

    form: {
      get() {
        return new Proxy(this.value, {
          set: (obj, key, value) => {
            this.form = { ...obj, [key]: value }
            return true
          },
        })
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
    errorAlertProxy: {
      get() {
        return this.errorAlert
      },
      set(newValue) {
        this.$emit('update:errorAlert', newValue)
      },
    },
  },
  methods: {
    submitEvent() {
      this.errorAlertProxy = false
      if (!this.$refs.form.validate()) {
        return
      }
      this.$emit('submit-form', this.form)
    },
  },
}
</script>

<style></style>
