<template>
  <v-dialog
    v-model="open"
    content-class="tw-rounded-3xl tw-relative fill-height tw-max-h-full tw-flex tw-items-center tw-shadow-none  tw-m-0 tw-p-0 tw-w-full"
  >
    <!-- max-width="1280px" -->
    <v-container
      class="tw-relative tw-max-w-lg lg:tw-max-w-5xl 2xl:tw-max-w-7xl"
      fluid
    >
      <div class="tw-absolute tw--right-6 tw--top-4">
        <v-fab-transition>
          <v-btn
            fab
            x-small
            class="focus:tw-outline-none focus:tw-ring"
            @click="open = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-fab-transition>
      </div>
      <v-card
        class="tw-relative tw-rounded-3xl tw-border-0 tw-bg-transparent tw-shadow-none"
      >
        <modal-header v-if="false">{{ simpleVideoItemUrl }}</modal-header>
        <v-divider v-if="false" />
        <v-card-text
          class="tw-aspect-h-9 tw-bg-transparent tw-p-0 tw-shadow-none"
        >
          <v-responsive class="tw-aspect-h-9">
            <v-container
              class="tw-aspect-h-9 tw-relative tw-bg-transparent tw-p-0 tw-shadow-none"
              fluid
            >
              <v-container
                class="tw-relative tw-overflow-hidden tw-rounded-3xl tw-border-0 tw-bg-transparent tw-p-0 tw-shadow-none"
                fluid
              >
                <AppPlyr v-if="simpleVideoItemType == 'uploaded'">
                  <video
                    controls
                    crossorigin
                    playsinline
                    :data-poster="simpleVideoItemPreview"
                    :autoplay="!!is_autoplay"
                  >
                    <source :src="simpleVideoItemUrl" type="video/mp4" />
                  </video>
                </AppPlyr>
                <!-- v-if="simpleVideoItemType == 'embed'" -->

                <vimeo-player
                  v-if="simpleVideoItemType == 'vimeo'"
                  ref="player"
                  class="tw-aspect-w-16 tw-aspect-h-9"
                  :video-url="simpleVideoItemUrl"
                  :options="is_autoplay ? { autoplay: true } : {}"
                />
                <LazyYoutube
                  v-if="simpleVideoItemType == 'youtube'"
                  ref="youtube"
                  :src="simpleVideoItemUrl"
                  max-width="100%"
                  aspect-ratio="16:9"
                  :autoplay="!!is_autoplay"
                />
              </v-container>
            </v-container>
          </v-responsive>
        </v-card-text>
        <v-divider v-if="false" />
        <v-card-actions v-if="false">
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            rounded
            class="tw-px-8 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-white"
            @click="closeModal"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import ModalHeader from '@/components/ModalHeader'
import { mapFields } from '@/helpers'
import AppPlyr from '@/components/Video/AppPlyr.vue'

export default {
  components: {
    AppPlyr,
    ModalHeader,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    ...mapFields({
      fields: [
        'simpleVideoItemModal',
        'simpleVideoItemType',
        'simpleVideoItemUrl',
        'simpleVideoItemPreview',
        // Recibe variable is_autoplay
        'is_autoplay',
      ],
      base: ['event', 'simpleVideoItem'],
      mutation: 'event/setSimpleVideoItem',
    }),
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  mounted() {},

  methods: {
    closeModal() {
      this.open = false
    },
  },
}
</script>

<style></style>
