import $axios from '@/axios-instance.js'

export default class VirtualRoomElementsService {
  baseUrl = '/api/v1/admin/virtual-rooms'

  // Create
  store(virtual_room, params) {
    return $axios.post(`${this.baseUrl}/${virtual_room}/elements`, params)
  }

  // Read all - findAll
  index(params) {
    return $axios.get(this.baseUrl + '/path', params)
  }

  // Read one - show
  show(id, params) {
    return $axios.get(this.baseUrl + `/path/${id}`, params)
  }

  // Update
  update(params) {
    return $axios.put(this.baseUrl + '/path', params)
  }

  // Delete - delete
  destroy(params) {
    return $axios.delete(this.baseUrl + '/path', params)
  }
}
export const VirtualRoomElementsServiceInstance =
  new VirtualRoomElementsService()
