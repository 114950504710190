import $axios from '@/axios-instance.js'

class RegistriesTicketServiceClass {
  baseUrl = '/api/v1/admin/registries'
  child = 'ticket'

  // Create
  store(event_config, params) {
    return $axios.post(`${this.baseUrl}/${event_config}/${this.child}`, params)
  }
  index(id, params) {
    return $axios.get(`${this.baseUrl}-${this.child}/${id}`, params)
  }
}
export const RegistriesRegistryTicket = new RegistriesTicketServiceClass()
