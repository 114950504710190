// @ts-check
export function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    // @ts-check
    // eslint-disable-next-line no-extra-semi
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

/**
 * @typedef {Object} Field
 * @property {function():*} Field.get - an ID.
 * @property {function({Object}): void} Field.set - an ID.
 */

/**
 * @typedef {Object} FieldState
 * @property {Field} field - an ID.
 */

/**
 * Creates fields mapped to vuex.
 *
 * @param {object} options - The fields, base and mutation
 * @param {string[]} options.fields - An array of properties
 * @param {string[]} options.base - An array of name divided path to the resource
 * @param {string} options.mutation - A string to the mutation
 * @returns {object} Object Implementing a get and set
 * @example
 * ...mapFields({
 *   fields: ['email','name','dob'],
 *   base: ['registration','step_one'],
 *   mutation: "registration/setStepOne"
 * }),
 *
 */
export function mapFields({ fields, base, mutation }) {
  const object = {}
  for (let x = 0; x < fields.length; x++) {
    const field = fields[x]

    /** @type {FieldState} */
    object[field] = {
      get() {
        const state = base.reduce((base, nested) => {
          return base[nested]
        }, this.$store.state)
        return state[field]
      },
      set(value) {
        this.$store.commit(mutation, { [field]: value })
      },
    }
  }
  return object
}

export const mapParent = ({ fields, base, mutation }) => {
  const object = {}
  for (let x = 0; x < fields.length; x++) {
    const field = fields[x]

    /** @type {FieldState} */
    object[field] = {
      get() {
        return this[base][field]
      },
      set(value) {
        this.$emit(mutation, { [field]: value })
      },
    }
  }
  return object
}
export function mapNestedFields({ fields, base, mutation, nested_key }) {
  const object = {}
  for (let x = 0; x < fields.length; x++) {
    // const field = [ fields[x] ];
    const field = fields[x]

    /** @type {FieldState} */
    object[field] = {
      get() {
        const state = base.reduce((base, nested) => {
          return base[nested]
        }, this.$store.state)
        return state[this[nested_key]][field]
      },
      set(value) {
        this.$store.commit(mutation, {
          [field]: value,
          [nested_key]: this[nested_key],
        })
      },
    }
  }

  return object
}
// @ts-ignore
export const v4 = () => {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  )
}
export function short_uid() {
  // I generate the UID from two parts here
  // to ensure the random number provide enough bits.
  // var firstPart = (Math.random() * 46656) | 0;
  // var secondPart = (Math.random() * 46656) | 0;
  // // @ts-ignore
  // firstPart = ("000" + firstPart.toString(36)).slice(-3);
  // // @ts-ignore
  // secondPart = ("000" + secondPart.toString(36)).slice(-3);
  // return firstPart + secondPart;
  return Math.random().toString(36).slice(-6)
}
export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
export async function sleep(fn, ...args) {
  await timeout(3000)
  return fn(...args)
}
export const deviceName = () => {
  const browser = navigator.userAgent.split(/\s*[;)(]\s*/)
  return `${browser[1]} ${browser[2]} ${browser[3]}`
}

/**
 * @param {[]} arr1 shorter array
 * @param {[]} arr2 pool array
 * @returns {Boolean} Determines where or not exists
 */
export const findCommonElements3 = (arr1, arr2) => {
  return arr1.some((item) => arr2.includes(item))
}

export const slugify = (text) => {
  return (
    text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w-]+/g, '') // Remove all non-word chars
      .replace(/--+/g, '-') // Replace multiple - with single -
      // .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      // .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '')
  ) // Trim - from end of text
}
