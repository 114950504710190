<template>
  <!-- <sidebar-layout> -->
  <renderless-layout>
    <v-container id="event_broadcasts_index_page" fluid class="tw-p-0">
      <StickyHeader :nav="nav">
        <template #actions>
          <div class="tw-flex tw-items-center">
            <AppBtn
              icon="plus"
              :to="{ name: 'admin.configs.event-broadcasts.create' }"
              color="white"
            >
              {{ $t('admin.app.new_notification') }}
            </AppBtn>
          </div>
        </template>
      </StickyHeader>
      <v-row class="tw-m-0 tw-p-0">
        <v-col cols="12" sm="12" md="12" lg="10" class="mx-auto tw-container">
          <v-container fluid class="tw-space-y-6 tw-py-6">
            <EventBroadcastsTable @on-row-click="showItem" />
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </renderless-layout>
</template>

<script>
import StickyHeader from '@/components/StickyHeader'
import AppBtn from '@/components/App/AppBtn.vue'

import EventBroadcastsTable from '@/components/EventBroadcasts/EventBroadcastsTable.vue'
export default {
  components: {
    AppBtn,
    StickyHeader,
    EventBroadcastsTable,
    // LanguageBadge,
  },
  computed: {
    nav() {
      return [
        {
          name: this.$t('admin.app.general_config'),
          route: {
            name: 'admin.configs.home',
          },
        },
        {
          name: this.$t('admin.events.items.labels.notifications'),
          route: {},
        },
      ]
    },
  },
  methods: {
    showItem(a) {
      if (a.registry) {
        return this.$router.push({
          name: 'admin.registries.event-broadcasts.edit',
          params: { id: a.registry.id, broadcast: a.id },
        })
      }
      return this.$router.push({
        name: 'admin.configs.event-broadcasts.edit',
        params: { id: a.id },
      })
    },
  },
}
</script>

<style></style>
