<template>
<renderless-layout>
    <v-container fluid class="tw-p-0">
        <StickyHeader :nav="nav">
            <template #actions>
                <div class="tw-flex tw-items-center tw-space-x-4">
                    <AppBtn icon="check" color="white" type="button" @click="saveTicket">
                        {{ $t('app.save') }}
                    </AppBtn>
                </div>
            </template>
        </StickyHeader>
        <v-row class="tw-m-0 tw-p-0">
            <v-col cols="12" class="mx-auto tw-max-w-5xl lg:tw-pt-10">
                <v-container fluid>
                    <v-card
                        flat
                        color="transparent"
                        class="tw-mb-6 tw-overflow-hidden tw-rounded-3xl tw-border-transparent tw-bg-transparent tw-shadow-md"
                    >
                        <v-container
                            class="tw-bg-gradient-to-r tw-from-bizzdome-terciary tw-to-bizzdome-light-brown tw-p-0"
                            fluid
                        >
                            <div class="tw-px-4 tw-py-5 sm:tw-px-6">
                                <h2 class="tw-title-font tw-mb-2 tw-text-xl tw-font-medium tw-text-gray-50 sm:tw-text-2xl">
                                    {{ $t('admin.ticket.items.labels.config') }}
                                </h2>
                                <p class="tw-mt-1 tw-max-w-2xl tw-text-sm tw-text-gray-100">
                                    {{ $t('admin.ticket.items.description.master') }}
                                </p>
                                <v-row>
                                    <v-col cols="12" md="3">
                                        <v-text-field
                                            v-model="registry_tickets.ticket_label_key"
                                            :label="$t('admin.ticket.items.labels.label')" 
                                            append-icon="local_activity"
                                            solo
                                            hide-details 
                                            rounded 
                                            dense 
                                            clearable 
                                        />
                                    </v-col>
                                    <v-col cols="12" md="2" class="tw-flex tw-justify-end lg:tw-justify-start">
                                        <AppBtn color="white" icon="plus" @click="addTicket">
                                            {{ $t('admin.ticket.items.labels.button') }}
                                        </AppBtn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-container>
                    </v-card>
                </v-container>
                <v-container fluid>
                    <v-card class="mx-auto tw-divide-y tw-rounded-3xl tw-shadow-md tw-p-4">
                        <draggable v-model="registry_tickets" tag="div" handle=".handle" class="tw-overflow-hidden tw-bg-white tw-shadow sm:tw-rounded-md" ghost-class="ghost">
                            <transition-group type="transition" :name="'flip-list'" tag="ul" class="tw-divide-y tw-divide-gray-200 tw-pl-0">
                                <li v-for="row in registry_tickets" :key="row.ticket_label_key">
                                    <div class="tw-block hover:tw-bg-gray-50">
                                        <div class="tw-flex tw-items-center tw-px-4 tw-py-3 sm:tw-px-6 tw-justify-between">
                                            <!-- <div class="tw-flex tw-min-w-0 tw-flex-1 tw-items-center">
                                                        {{row.ticket_label_key}}
                                                    </div> -->
                                            <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-items-center">
                                                <div>
                                                    {{row.ticket_label_key}}
                                                </div>
                                                <div>
                                                    <VirtualRoomAutocomplete v-model="row.virtual_room_id" :label="$t('admin.app.initial_room')" dense hide-details />
                                                </div>
                                            </div>
                                            <div class="tw-shrink-0">
                                                <div class="tw-flex tw-items-center tw-space-x-2">
                                                    <div class="md:tw-flex tw-flex-shrink-0 tw-items-center">
                                                        <div class="">
                                                            <v-btn fab small text class="handle tw-cursor-move">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="tw-h-5 tw-w-5">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
                                                                </svg>
                                                            </v-btn>
                                                        </div>
                                                        <AppBtn color="transparent-danger" icon-center="close" fab small type="button" @click="deleteTicket(row)" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </transition-group>
                        </draggable>
                    </v-card>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</renderless-layout>
</template>

<script>
import StickyHeader from '@/components/StickyHeader.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import draggable from 'vuedraggable'
import { RegistryTicketBase, RegistryTicketHydrate } from '@/models/RegistryTicket'
import VirtualRoomAutocomplete from '@/components/VirtualRoomAutocomplete.vue'

import {
    mapGetters,
    mapState,
} from 'vuex'

export default {
    components: {
        StickyHeader,
        AppBtn,
        draggable,
        VirtualRoomAutocomplete,
    },
    data: () => ({
        lang: '',
        registry_tickets: [],
        menu: false,
    }),
    computed: {
        ...mapGetters({
            languages: ['constants/languages'],
        }),
        nav() {
            return [{
                    name: this.$t('app.event'),
                    route: {
                        name: 'admin.registries.index',
                    },
                },
                {
                    name: this.registry.label,
                    route: {
                        name: 'admin.registries.show.home',
                        params: {
                            id: this.registry.id,
                        },
                    },
                },
                {
                    name: this.$t('admin.events.items.labels.platform'),
                    route: {
                        name: 'admin.registries.general',
                    },
                },
                {
                    name: this.$t('admin.ticket.items.labels.ticket'),
                    route: {},
                },
            ]
        },
        ...mapState({
            registry: (state) => state.registries.show.data,
        }),
        label_language() {
            return this.registry_tickets.label_language
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.registry_tickets = this.registry.registry_tickets
                // .filter(({
                //     language,
                // }) => language.name === this.lang)
                .map((registry_ticket) => {
                    return RegistryTicketHydrate({
                        ...registry_ticket,
                        virtual_room_id: registry_ticket.registry_room ? registry_ticket.registry_room.virtual_room_id : null,
                    })
                })
        },
        addTicket() {
            if (this.registry_tickets.ticket_label_key) {
                if (this.registry_tickets.map(row => row.ticket_label_key).includes(this.capitalize(this.registry_tickets.ticket_label_key))) {
                    return this.$store.dispatch('notifications/addNotification', {
                        title: 'Mensaje',
                        body: '¡Clase ya existente!',
                        status: 'error',
                    })
                } else {
                    return this.registry_tickets = [
                            ...this.registry_tickets,
                            RegistryTicketBase({
                                ticket_label_key: this.capitalize(this.registry_tickets.ticket_label_key),
                                registry_id: this.registry.id,
                            }),
                        ]
                }
            }
        },
        capitalize(text) {
            return text[0].toUpperCase() + text.slice(1)
        },
        deleteTicket(row) {
            this.registry_tickets = this.registry_tickets.filter((n) => n.ticket_label_key !== row.ticket_label_key)
        },
        saveTicket() {
            this.$store.dispatch('registries/updateTicket', {
                payload: {
                    registry_tickets: this.registry_tickets,
                },
            })
            .then(() =>
                this.$store.dispatch('registries/show', {
                    id: this.registry.id,
                }),
            )
        },
    },
}
</script>

<style>

</style>
