<template>
  <v-container
    class="fill-height tw-flex tw-items-start tw-overflow-y-scroll tw-bg-gray-100"
    fluid
  >
    <v-row class="mb-5">
      <v-col cols="12" sm="7" class="mx-auto pa-5">
        <v-card
          class="mx-auto tw-divide-y tw-divide-gray-200 tw-overflow-hidden tw-rounded-3xl tw-shadow-md"
        >
          <v-container class="tw-p-0 tw-py-5" fluid>
            <div key="profile.cards.list">
              <ul
                v-if="usersInMyRoom.length > 0"
                class="tw-relative tw-w-full tw-divide-y tw-divide-gray-200 tw-pl-0"
              >
                <UsersListItem
                  v-for="user in usersInMyRoom"
                  :key="user.email"
                  :can-click="false"
                  :has-options="false"
                  :user="user"
                >
                  <template #right-col>
                    <div
                      v-if="user.id !== current_user.id"
                      class="tw-space-x tw-flex tw-divide-x-2 tw-divide-solid tw-divide-gray-200"
                    >
                      <v-btn text @click="viewAttendee(user)">
                        {{ $t('pages.event.profile.labels.profile') }}
                      </v-btn>
                      <v-btn text @click="chatWithUser(user)">
                        {{ $t('pages.event.rep_dash.chat') }}
                      </v-btn>
                    </div>
                  </template>
                </UsersListItem>
              </ul>
              <div v-else key="no_users" class="tw-p-16 tw-text-center">
                <h2 class="tw-mb-2 tw-font-semibold tw-text-gray-900">
                  {{ $t('app.no_users') }}
                  <!-- {{ 'imposible state' }} -->
                </h2>
                <!-- <p class="tw-text-sm tw-text-gray-700">
            No podemos encontrar nada con ese término en este momento, intente
            buscando algo más.
          </p> -->
              </div>
            </div>
          </v-container>
        </v-card>
        <v-card
          class="mx-auto tw-mt-10 tw-divide-y tw-divide-gray-200 tw-overflow-hidden tw-rounded-3xl tw-shadow-md"
        >
          <v-container fluid class="tw-p-0 tw-py-5">
            <div
              class="tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-200 tw-px-4 tw-pb-4"
            >
              <div>
                <h3
                  class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                >
                  {{ $t('pages.event.rep_dash.not_registered_reps') }}
                </h3>
              </div>
              <div>
                <!-- <AppBtn
                    icon="refresh"
                    type="button"
                    color="white"
                    :disabled="loading"
                    :loading="loading"
                    @click="loadData"
                  >
                    Refrescar
                  </AppBtn> -->
              </div>
            </div>

            <ul role="list" class="tw-divide-y tw-divide-gray-200 tw-pl-0">
              <li
                v-for="missingRep in missingReps"
                :key="missingRep.uuid"
                class="tw-flex tw-py-4 tw-px-4"
              >
                <img
                  class="tw-h-10 tw-w-10 tw-rounded-full"
                  :src="missingRep.avatar"
                  alt=""
                />
                <div class="tw-ml-3">
                  <p class="tw-mb-0 tw-text-sm tw-font-medium tw-text-gray-900">
                    {{ missingRep.first_name }} {{ missingRep.last_name }}
                  </p>
                  <p class="tw-mb-0 tw-text-sm tw-text-gray-500">
                    {{ missingRep.email }}
                  </p>
                </div>
              </li>
            </ul>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UsersListItem from './UsersListItem.vue'
import { mapState } from 'vuex'
export default {
  components: {
    UsersListItem,
  },
  props: {
    currentSelectedSlug: {
      require: true,
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      current_user: (state) => state.session.user,
      registry: (state) => state.event.constants.registry,
      rep_rooms: (state) => state.event.constants.rep_rooms,
      users: (state) => state.event.socket.users,
      usersLocation: (state) => state.event.socket.usersLocation,
    }),
    currentRoom() {
      return this.rep_rooms.find((row) => row.slug === this.currentSelectedSlug)
    },
    currentReps() {
      return this.currentRoom.reps.map((row) => row.id)
    },
    userInThisLocation() {
      return this.usersLocation
        .filter((row) => row.user_id !== this.current_user.id)
        .filter(
          (row) =>
            row.slug === this.currentSelectedSlug &&
            row.registry_uuid === this.registry.uuid,
        )
        .map((row) => row.user_id)
    },
    usersInMyRoom() {
      return this.users.filter((row) => this.currentReps.includes(row.id))
    },
    missingReps() {
      const registeredReps = this.usersInMyRoom.map((row) => row.id)
      console.log(registeredReps)
      return this.currentRoom.reps.filter(
        (row) => !registeredReps.includes(row.id),
      )
    },
  },
  methods: {
    viewAttendee(user) {
      this.$store.commit('event/setCard', { attendee: user, modal: true })
    },
    chatWithUser(user) {
      this.$store.commit('event/setRepDashboard', {
        modal: false,
      })
      this.$store.commit('chat/setState', {
        showChat: false,
      })
      this.$nextTick().then(() => {
        this.$store.commit('chat/setState', {
          showChat: true,
          view: 'CHAT',
          talking_to: user,
        })
      })
    },
  },
}
</script>

<style></style>
