<template>
  <UserProfileTabWrapper>
    <div>
      <v-container class="tw-divide-gray-200" fluid>
        <section class="tw-py-6 tw-px-4 sm:tw-p-6 lg:tw-pb-8">
          <div class="tw-mx-auto tw-max-w-3xl tw-divide-y tw-divide-gray-200">
            <!-- <div class="tw-space-y-1">
              <pre class="tw-hidden tw-max-w-2xl tw-text-sm tw-text-gray-500">{{
                JSON.stringify(registry, null, 2)
              }}</pre>
            </div> -->
            <CredentialCard
              v-if="registry.credential"
              :registry="registry"
              :checkin-user="user"
              :profile="profile"
              :qrlink="qrlink"
              :config="mountedCredential"
              :user-form="mountedCredential.user_form"
            />
          </div>
        </section>
      </v-container>
    </div>
  </UserProfileTabWrapper>
</template>

<script>
import { mapState } from 'vuex'
import UserProfileTabWrapper from './UserProfileTabWrapper.vue'
import CredentialCard from '@/components/Event/CredentialCard.vue'
import { CredentialHydrate } from '@/models/Credential'

export default {
  components: {
    UserProfileTabWrapper,
    CredentialCard,
  },
  data: () => ({
    mountedCredential: {},
  }),
  computed: {
    ...mapState({
      registry: (state) => state.event.constants.registry,
      profiles: (state) => state.event.constants.profiles,
      user: (state) => state.session.user,
      current_language_key: (state) =>
        state.event.constants.event_configs.data.current_config_language_key,
      qrlink: (state) => state.event.constants.qrlink,
    }),
    current_form() {
      return this.registry.forms.find(
        (row) => row.language.name === this.current_language_key,
      )
    },
    profile() {
      return this.profiles.find(
        (row) => row.registry_form_id === this.current_form.id,
      )
    },
  },
  mounted() {
    this.getCredential()
  },
  methods: {
    getCredential() {
      this.mountedCredential = CredentialHydrate(
        this.$store.state.event.constants.registry.credential,
        this.registry.forms,
      )
    },
  },
}
</script>