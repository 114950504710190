<template>
<div class="tw-bg-landing-primary lg:tw-relative">
    <div class="tw-mx-auto tw-max-w-7xl tw-py-24 tw-px-6 lg:tw-px-0">
        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4">
            <div 
                v-for="mbtn in section.data.buttons" 
                :key="mbtn.uid" 
                class="tw-relative tw-object-cover lg:tw-h-full lg:tw-w-full"
            >
                <!-- Mostrar la imagen -->
                <img 
                    :src="mbtn.mbutton_image_url" 
                    :alt="mbtn.uid"
                    class="tw-object-cover lg:tw-h-full lg:tw-w-full"
                />

                <!-- Enlace condicional externo o interno -->
                <template v-if="mbtn.cta_link_type === 'external'">
                    <a
                        :href="mbtn.cta_link ? mbtn.cta_link : mbtn.mbutton_url"
                        target="_blank"
                        class="overlay_image_link"
                    />
                </template>
                <template v-if="mbtn.cta_link_type === 'internal'">
                    <router-link
                        :to="resolveInternalLink(mbtn)"
                        class="overlay_image_link"
                    />
                </template>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'BizzDomeUiRegistryMultiButtons',
    props: {
        section: {
            type: Object,
            default: () => ({}),
        },
        slug: {
            type: String,
            default: null,
        },
    },
    mounted() {
        this.detectAndLoadForms()
    },
    methods: {
        resolveInternalLink(key) {
            const { cta_link, form_id } = key

            const params = { slug: this.slug }

            if (form_id) {
                params.form_id = form_id
            }

            const routes = {
                register: { name: 'registry.register', params },
                login: { name: 'registry.login', params },
            }

            return routes[cta_link] || routes.register
        },
        detectAndLoadForms() {
            const buttons = this.section.data.buttons || []

            // Filtrar los botones de tipo 'register' que tengan form_id
            const formIds = buttons
                .filter((button) => button.cta_link === 'register' && button.form_id)
                .map((button) => button.form_id)
            if (formIds.length > 0) {
                this.$store.dispatch('registry/loadForms', formIds)
            }
        },
    },
}
</script>
