<template>
  <renderless-layout>
    <v-container fluid class="tw-p-0">
      <sticky-header :nav="nav">
        <template #actions>
          <div class="tw-flex tw-items-center tw-space-x-4">
            <AppBtn color="white" @click="updateEvent">
              {{ $t('app.save') }}
            </AppBtn>
          </div>
        </template>
      </sticky-header>
      <v-row class="tw-m-0 tw-p-0">
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="11"
          xl="9"
          class="mx-auto mx-auto tw-max-w-5xl lg:tw-pt-10"
        >
          <!-- Actions panel -->
          <v-container fluid class="">
            <v-card
              flat
              color="transparent"
              class="tw-mb-6 tw-overflow-hidden tw-rounded-3xl tw-border-transparent tw-bg-transparent tw-shadow-md"
            >
              <v-container
                class="tw-bg-gradient-to-r tw-from-bizzdome-terciary tw-to-bizzdome-light-brown tw-p-0"
                fluid
              >
                <div class="tw-px-4 tw-py-5 sm:tw-px-6">
                  <h2
                    class="tw-title-font tw-mb-2 tw-text-xl tw-font-medium tw-text-gray-50 sm:tw-text-2xl"
                  >
                    Configuración de Landing
                  </h2>
                  <p class="tw-mt-1 tw-max-w-2xl tw-text-sm tw-text-gray-100">
                    En esta área puede crear muchos tipos de salas virtuales que
                    puede personalizar con plantillas, gráficos y contenido.
                    Estas son las áreas a las que los usuarios navegarán dentro
                    de los espacios más grandes de la experiencia virtual.
                  </p>
                  <div class="tw-flex tw-justify-start tw-space-x-2">
                    <AppBtn color="white" icon="plus" @click="addNavItem">
                      Añadir item de navegación
                    </AppBtn>
                  </div>
                </div>
              </v-container>
            </v-card>
          </v-container>
          <v-container fluid class="">
            <draggable
              v-model="nav_items"
              tag="div"
              handle=".handle"
              class="tw-overflow-hidden tw-bg-white tw-shadow sm:tw-rounded-3xl"
              ghost-class="ghost"
            >
              <transition-group
                type="transition"
                :name="'flip-list'"
                tag="ul"
                class="tw-divide-y tw-divide-gray-200 tw-pl-0"
              >
                <li v-for="row in nav_items" :key="row.uuid">
                  <div class="tw-block hover:tw-bg-gray-50">
                    <div
                      class="tw-flex tw-items-center tw-px-4 tw-py-4 sm:tw-px-6"
                    >
                      <div class="tw-flex tw-min-w-0 tw-flex-1 tw-items-center">
                        <div class="tw-flex-shrink-0">
                          <div
                            class="tw-h-12 tw-w-12 tw-rounded-lg tw-shadow-md"
                          >
                            <img
                              class="tw-h-full tw-w-full tw-object-contain"
                              :src="row.image_url"
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          class="tw-min-w-0 tw-flex-1 tw-px-4 md:tw-grid md:tw-grid-cols-2 md:tw-gap-4"
                        >
                          <div>
                            <p
                              v-if="row.label"
                              class="tw-mb-0 tw-truncate tw-text-sm tw-font-medium tw-text-gray-900"
                            >
                              {{ row.label }}
                            </p>
                            <p
                              v-else
                              class="mb-0 text-sm text-gray-500 tw-italic"
                            >
                              Sin valor establecido.
                            </p>

                            <p
                              class="tw-mt-1 tw-mb-0 tw-flex tw-items-center tw-text-sm tw-uppercase tw-text-gray-500"
                            >
                              <!-- Heroicon name: solid/mail -->
                              <common-badge>
                                {{ row.size }}
                              </common-badge>
                            </p>
                          </div>
                          <div class="tw-hidden md:tw-block">
                            <div>
                              <p class="tw-mb-0 tw-text-sm tw-text-gray-900">
                                {{ row.helper_text }}
                              </p>
                              <div class="tw-mt-1 tw-flex tw-justify-start">
                                <status-badge :is-active="row.is_active" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tw-shrink-0">
                        <div class="tw-flex tw-items-center tw-space-x-2">
                          <AppBtn
                            color="primary"
                            icon="pencil_edit"
                            type="button"
                            @click="updateItem(row)"
                          >
                            Editar
                          </AppBtn>

                          <div
                            class="tw-flex tw-flex-shrink-0 tw-flex-col tw-items-center tw-space-y-2"
                          >
                            <div class="">
                              <v-btn
                                fab
                                small
                                text
                                class="handle tw-cursor-move"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  class="tw-h-5 tw-w-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M8 9l4-4 4 4m0 6l-4 4-4-4"
                                  />
                                </svg>
                              </v-btn>
                            </div>
                            <AppBtn
                              color="transparent-danger"
                              icon-center="close"
                              fab
                              small
                              type="button"
                              @click="deleteItem(row)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </transition-group>
            </draggable>
            <!-- </ul> -->
            <!-- </div> -->
          </v-container>
          <!-- <v-container fluid class="">
            <div class="drag-container" v-drag-and-drop:options="options">
              <ul class="drag-list">
                <li class="drag-column" v-for="group in groups" :key="group.id">
                  <span class="drag-column-header">
                    <h2>{{ group.name }}</h2>
                  </span>
                  <vue-draggable-group
                    v-model="group.items"
                    :groups="groups"
                    :data-id="group.id"
                    @change="onGroupsChange"
                  >
                    <ul class="drag-inner-list" :data-id="group.id">
                      <li
                        class="drag-item"
                        v-for="item in group.items"
                        :key="item.id"
                        :data-id="item.id"
                      >
                        <div class="drag-item-text">{{ item.name }}</div>
                      </li>
                    </ul>
                  </vue-draggable-group>
                </li>
              </ul>
            </div>
          </v-container> -->
          <configs-nav-items-modal
            v-if="configs_nav_items_modal"
            v-model="configs_nav_items_modal"
            :nav-item="selectedNavItem"
            @updated-nav-item="updatedNavItem"
          />
        </v-col>
      </v-row>
    </v-container>
  </renderless-layout>
</template>

<script>
import AppBtn from '@/components/App/AppBtn.vue'

import StickyHeader from '@/components/StickyHeader'
import { mapGetters } from 'vuex'
import { mapFields } from '@/helpers'
import { EventNavItemBase } from '@/models/EventNavItem'
import ConfigsNavItemsModal from '@/components/ConfigsModals/ConfigsNavItemsModal'
import StatusBadge from '@/components/StatusBadge'
import CommonBadge from '@/components/CommonBadge'

import draggable from 'vuedraggable'
export default {
  components: {
    AppBtn,
    ConfigsNavItemsModal,
    CommonBadge,
    StickyHeader,
    StatusBadge,
    draggable,
  },
  data: () => ({
    selectedNavItem: {},
    configs_nav_items_modal: false,
    // nav_items: [
    //   EventNavItemBase({ label: "tits" }),
    //   EventNavItemBase({ label: "papa" }),
    //   EventNavItemBase({ label: "milanesa" }),
    //   EventNavItemBase({ label: "fuck" }),
    // ],
    groups: [
      {
        id: 1,
        name: 'To Do',
        items: [
          { id: 1, name: 'Item 1', groupId: 1 },
          { id: 2, name: 'Item 2', groupId: 1 },
          { id: 3, name: 'Item 3', groupId: 1 },
        ],
      },
      {
        id: 2,
        name: 'In Progress',
        items: [
          { id: 4, name: 'Item 4', groupId: 2 },
          { id: 5, name: 'Item 5', groupId: 2 },
          { id: 6, name: 'Item 6', groupId: 2 },
        ],
      },
      {
        id: 3,
        name: 'Done',
        items: [
          { id: 7, name: 'Item 7', groupId: 3 },
          { id: 8, name: 'Item 8', groupId: 3 },
          { id: 9, name: 'Item 9', groupId: 3 },
          { id: 10, name: 'Item 10', groupId: 3 },
        ],
      },
    ],
    options: {
      dropzoneSelector: '.drag-inner-list',
      draggableSelector: '.drag-item',
    },
  }),
  computed: {
    nav() {
      return [
        {
          name: 'Configuración General',
          route: { name: 'admin.configs.index' },
        },
        {
          name: this.currentLanguage,
          route: {},
        },
        {
          name: 'Navegación',
          route: {},
        },
      ]
    },
    currentLanguage() {
      return this.languages.find(
        (row) => row.value === this.current_config_language_key,
      )['text']
    },
    nav_items: {
      get() {
        return this.$store.state.event_config.nav_items.update.data[
          this.current_config_language_key
        ]
      },
      set(arrs) {
        return this.$store.commit('event_config/setNavItems', {
          current_config_language_key: this.current_config_language_key,
          nav_items: arrs,
        })
      },
    },
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        ghostClass: 'ghost',
      }
    },
    ...mapFields({
      fields: ['current_config_language_key'],
      base: ['event_config', 'update', 'data'],
      mutation: 'event_config/setConfigWrapperForm',
    }),
    ...mapGetters({
      languages: ['constants/languages'],
    }),
    // ...mapState({
    //   show: (state) => state.registries.show.data,
    // }),
  },
  beforeDestroy() {
    console.log('destroyed')
    this.$store.dispatch('event_config/resetNavItems')
  },

  methods: {
    updateItem(nav_item) {
      this.selectedNavItem = nav_item
      this.configs_nav_items_modal = true
    },
    updatedNavItem(navItem) {
      if (this.nav_items.find((r) => r.uuid === navItem.uuid)) {
        this.nav_items = this.nav_items.map((row) =>
          row.uuid === navItem.uuid ? navItem : row,
        )
        return
      }
      this.nav_items = [...this.nav_items, navItem]
    },
    addNavItem() {
      this.selectedNavItem = EventNavItemBase({})
      this.configs_nav_items_modal = true
      // this.nav_items = [...this.nav_items, EventNavItemBase({})];
    },
    deleteItem(row) {
      this.nav_items = this.nav_items.filter((n) => n.uuid !== row.uuid)
    },

    onUpdate: function (event) {
      this.nav_items.splice(
        event.newIndex,
        0,
        this.nav_items.splice(event.oldIndex, 1)[0],
      )
    },
    updateEvent() {
      console.log('hero')
      this.$store
        .dispatch('event_config/updateOrCreateNav')
        .then(() =>
          this.$store.dispatch('event_config/index', { loading: false }),
        )
    },
  },
}
</script>
