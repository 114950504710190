export const RegistryThankYouBase = ({
  title = '',
  image_url = '',
  tag_line = '',
  description = '',
  links,
}) => ({
  title,
  image_url,
  tag_line,
  description,
  links: links ? links : [],
})
