<template>
  <renderless-layout>
    <v-container fluid class="tw-p-0">
      <sticky-header>
        <template #title>
          <primary-title> Configuración General </primary-title>
        </template>
        <template #actions>
          <div class="tw-flex tw-items-center tw-space-x-4">
            <v-select
              v-if="false"
              v-model="default_language"
              :items="languages"
              label="Default Language"
              outlined
              rounded
              hide-details=""
              dense
              prepend-inner-icon="flag"
              class="tw-w-48"
              dark
            />
            <v-select
              v-model="current_config_language_key"
              :items="languages"
              :label="$t('admin.app.lang_version')"
              outlined
              class="tw-w-48"
              dark
              color="white"
              rounded
              hide-details=""
              dense
            />
            <AppBtn color="white" type="button" @click="updateEvent">
              {{ $t('app.save') }}
            </AppBtn>
          </div>
        </template>
      </sticky-header>
      <v-row class="tw-m-0 tw-p-0">
        <v-col cols="12" sm="12" md="12" lg="12" xl="10" class="mx-auto">
          <v-container fluid class="sm:py-3 tw-container tw-mx-auto">
            <!-- <v-row>
              <v-col cols="12" class="tw-pt-0 px-5 sm:tw-pt-5">
                <div class="tw-flex tw-justify-between">
                  <div>
                    <h1
                      class="tw-mb-2 tw-text-2xl tw-font-medium tw-text-gray-900 sm:tw-text-3xl tw-title-font"
                    >
                      Nueva Sala Virtual
                    </h1>
                    <div class="tw-w-20 tw-h-1 tw-rounded tw-bg-primary-500" />
                  </div>
                  <div></div>
                </div>
              </v-col>
            </v-row> -->
            <v-form
              ref="form"
              v-model="valid"
              :disabled="form_disabled_state"
              lazy-validation
              enctype="multipart/form-data"
              @submit.prevent="submitEvent"
            >
              <v-row class="mb-5">
                <v-col cols="12" md="3" class="pt-5 mx-auto tw-px-0 sm:tw-px-5">
                  <form-description>
                    <template #title> General </template>
                    <template #copy>
                      En esta área, puede editar los atributos generales de su
                      sitio virtual. Tenga en cuenta que para los sitios
                      localizados, estos atributos deben ingresarse por separado
                      para cada versión de idioma. Para hacerlo, seleccione una
                      versión de idioma en el menú desplegable a continuación.
                    </template>
                  </form-description>
                </v-col>
                <v-col cols="12" md="9" class="mx-auto tw-px-0 sm:tw-px-5">
                  <v-card class="mx-auto tw-rounded-3xl tw-shadow-md">
                    <v-container v-if="false" class="tw-p-5">
                      <v-row>
                        <v-col cols="12" sm="7" class="pb-0">
                          <v-text-field
                            v-model="label"
                            outlined
                            rounded
                            label="Nombre de la experiencia virtual"
                          />
                        </v-col>
                        <v-col cols="12" sm="5" class="pb-0">
                          <v-switch
                            v-model="is_active"
                            label="Versión de idioma activa"
                            color="primary"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container class="tw-bg-gray-50 tw-p-5">
                      <!-- <v-row> </v-row> -->
                      <v-row>
                        <v-col>
                          <dropzone-preview
                            v-model="header_url"
                            :width="200"
                            :bypass="'no-check'"
                            :height="50"
                            media-group="config"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-if="false" class="mb-5">
                <v-col cols="12" md="3" class="pt-5 mx-auto tw-px-0 sm:tw-px-5">
                  <form-description>
                    <template #title> Duración del evento </template>
                    <template #copy>
                      Ingrese la fecha y hora en que se abrirá su y la fecha y
                      hora en que finalizará su Experiencia Virtual.
                    </template>
                  </form-description>
                </v-col>
                <v-col cols="12" md="9" class="mx-auto tw-px-0 sm:tw-px-5">
                  <v-card class="mx-auto tw-rounded-3xl tw-shadow-md">
                    <v-container class="tw-p-5">
                      <v-row>
                        <v-col cols="12" sm="12" class="pb-0">
                          <v-switch
                            v-model="has_time_bracket"
                            label="¿Tiene horario de apertura y cierre?"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container
                      v-if="has_time_bracket"
                      class="tw-bg-gray-50 tw-p-5"
                    >
                      <v-row>
                        <v-col cols="12" sm="6" class="pb-0">
                          <label class="tw-block tw-pb-2 tw-text-gray-600">
                            Hora de apertura
                          </label>
                          <div class="tw-grid tw-grid-cols-2 tw-gap-4">
                            <div>
                              <date-input v-model="start_date" />
                            </div>
                            <div>
                              <v-text-field
                                v-model="start_time"
                                label="Hora"
                                outlined
                                rounded
                                type="time"
                              />
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6" class="pb-0">
                          <label class="tw-block tw-pb-2 tw-text-gray-600">
                            Hora de cierre
                          </label>
                          <div class="tw-grid tw-grid-cols-2 tw-gap-4">
                            <div>
                              <date-input
                                v-model="end_date"
                                :date-min="start_date"
                              />
                            </div>
                            <div>
                              <v-text-field
                                v-model="end_time"
                                label="Hora"
                                outlined
                                rounded
                                type="time"
                              />
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" class="pb-0">
                          <timezones-autocomplete
                            v-model="time_bracket_tz"
                            :rules="[rules.testEmpty]"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" class="pb-0">
                          <v-text-field
                            v-model="closed_event_url"
                            outlined
                            rounded
                            label="Enlace de redireccionamiento de evento cerrado"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
              <!-- colors -->
              <v-row class="mb-5">
                <v-col cols="12" md="3" class="pt-5 mx-auto tw-px-0 sm:tw-px-5">
                  <form-description>
                    <template #title> Colores principales </template>
                    <template #copy>
                      Esto especificará el color de acento que se transmite a
                      través de títulos, botones y encabezados en la interfaz de
                      usuario de la experiencia virtual. Elija un color del
                      selector o ingrese un código de color hexadecimal.
                    </template>
                  </form-description>
                </v-col>
                <v-col cols="12" md="9" class="mx-auto tw-px-0 sm:tw-px-5">
                  <v-card class="mx-auto tw-rounded-3xl tw-shadow-md">
                    <v-container class="tw-p-5">
                      <v-row>
                        <v-col cols="12" sm="12" class="pb-0">
                          <label class="tw-block tw-pb-2 tw-text-gray-600">
                            Color principal del UI
                          </label>
                          <div>
                            <color-input v-model="ui_color" />
                          </div>
                        </v-col>

                        <v-col cols="12" sm="12" class="pb-0">
                          <label class="tw-block tw-pb-2 tw-text-gray-600">
                            Color del encabezado
                          </label>
                          <div>
                            <color-input v-model="header_color" />
                          </div>
                        </v-col>
                        <v-col cols="12" sm="12" class="pb-0">
                          <label class="tw-block tw-pb-2 tw-text-gray-600">
                            Color del footer
                          </label>
                          <div>
                            <color-input v-model="footer_color" />
                          </div>
                        </v-col>
                        <v-col cols="12" sm="12" class="pb-0">
                          <label class="tw-block tw-pb-2 tw-text-gray-600">
                            Color del texto de encabezados
                          </label>
                          <div>
                            <color-input v-model="titles_color" />
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
              <!-- meta data, Analytics, adicional scripts, -->
              <!-- nav and landing -->
              <v-row class="mb-5">
                <v-col cols="12" md="3" class="pt-5 mx-auto tw-px-0 sm:tw-px-5">
                  <div
                    class="tw-flex tw-flex-col tw-items-center tw-justify-between sm:tw-flex-row sm:tw-py-8"
                  >
                    <div>
                      <h3
                        class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        Punto de partida, personalización y navegación
                      </h3>
                      <div
                        class="tw-mb-2 tw-h-1 tw-w-8 tw-rounded-full tw-bg-primary-200"
                      />
                      <span
                        class="tw-font-medium tw-leading-6 tw-text-gray-500"
                      >
                        Los campos de: Nombre, Tipo y Descripcion son
                        obligatorios.
                      </span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="9" class="mx-auto tw-px-0 sm:tw-px-5">
                  <v-card
                    class="tw-mb-6 tw-overflow-hidden tw-rounded-3xl tw-border-0 tw-shadow-md"
                  >
                    <v-container
                      class="tw-bg-gradient-to-r tw-from-primary-500 tw-to-primary-900 tw-p-0"
                      fluid
                    >
                      <div class="tw-px-4 tw-py-5 sm:tw-px-6">
                        <h2
                          class="tw-title-font tw-mb-2 tw-text-xl tw-font-medium tw-text-gray-50 sm:tw-text-2xl"
                        >
                          Navegación Personalizada
                        </h2>
                        <p
                          class="tw-mt-1 tw-max-w-md tw-text-sm tw-text-gray-100"
                        >
                          Haga clic en los botones a continuación para
                          personalizar los elementos de navegación y el diseño
                          de su interfaz de usuario de experiencia virtual.
                        </p>
                        <div class="tw-flex tw-justify-start tw-space-x-2">
                          <v-btn
                            color="white"
                            rounded
                            class="tw-bg-transparent tw-px-8 tw-font-bold tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300"
                            @click="goToNavigation"
                          >
                            Items de navegación
                          </v-btn>
                          <!-- <v-btn
                            color="white"
                            rounded
                            text
                            dark
                            class="tw-px-8 tw-font-bold tw-normal-case tw-bg-transparent focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-50"
                          >
                            Orden de items
                          </v-btn> -->
                        </div>
                      </div>
                    </v-container>
                  </v-card>
                  <v-card class="mx-auto tw-rounded-3xl tw-shadow-md">
                    <v-container class="tw-p-5">
                      <v-row>
                        <v-col cols="12" sm="7" class="pb-0">
                          <virtual-room-autocomplete
                            v-model="virtual_room_start_id"
                            label="Sala inicial"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="mb-5">
                <v-col cols="12" class="tw-py-0">
                  <div class="tw-h-px tw-border" />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </renderless-layout>
</template>

<script>
import AppBtn from '@/components/App/AppBtn.vue'
import ColorInput from '@/components/FormInputs/ColorInput.vue'
import DateInput from '@/components/FormInputs/DateInput.vue'
import TimezonesAutocomplete from '@/components/TimezonesAutocomplete.vue'
import PrimaryTitle from '@/components/Titles/PrimaryTitle.vue'
import DropzonePreview from '@/components/DropzonePreview.vue'
import FormDescription from '@/components/FormDescription.vue'
import StickyHeader from '@/components/StickyHeader.vue'
import { mapGetters } from 'vuex'
import { mapFields, mapNestedFields } from '@/helpers'
import VirtualRoomAutocomplete from '@/components/VirtualRoomAutocomplete.vue'
export default {
  components: {
    AppBtn,
    PrimaryTitle,
    DropzonePreview,
    FormDescription,
    StickyHeader,
    VirtualRoomAutocomplete,
    TimezonesAutocomplete,
    DateInput,
    ColorInput,
  },
  data: () => ({
    form_disabled_state: false,
    valid: true,
    rules: {
      testEmpty: (value) => !!value || 'Campo no puede estar vacio.',
      min: (v) => v.length >= 8 || 'Min 8 characters',
    },
  }),
  computed: {
    ...mapFields({
      fields: ['default_language'],
      base: ['event_config', 'global_event', 'update', 'data'],
      mutation: 'event_config/setGlobalData',
    }),
    ...mapFields({
      fields: ['current_config_language_key'],
      base: ['event_config', 'update', 'data'],
      mutation: 'event_config/setConfigWrapperForm',
    }),
    ...mapNestedFields({
      fields: [
        'label',
        'header_url',
        'virtual_room_start_id',
        'is_active',
        'has_time_bracket',
        'start_date',
        'start_time',
        'end_date',
        'end_time',
        'time_bracket_tz',
        'closed_event_url',
        'ui_color',
        'header_color',
        'footer_color',
        'titles_color',
      ],
      base: ['event_config', 'update', 'data'],
      mutation: 'event_config/setConfigForm',
      nested_key: 'current_config_language_key',
    }),
    ...mapGetters({
      languages: ['constants/languages'],
    }),
  },
  mounted() {
    // this.$store.dispatch("event_config/mountEventConfigs");
  },
  methods: {
    goToNavigation() {
      console.log()
      this.$router.push({
        name: 'admin.configs.lang.nav-items',
        params: {
          lang: this.current_config_language_key,
        },
      })
    },
    updateEvent() {
      this.$store.dispatch('event_config/updateOrCreate')
    },
  },
}
</script>

<style></style>
