<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="1024px"
    content-class="tw-rounded-xl  fill-height tw-max-h-[640px] tw-w-full"
  >
    <v-card class="fill-height tw-border-0 tw-shadow-md">
      <v-container class="tw-p-0" fluid>
        <div
          class="tw-relative tw-grid tw-grid-cols-6 tw-items-center tw-rounded-t-xl tw-bg-primary-500 tw-pl-5"
        >
          <div class="tw-col-span-5 tw-flex tw-items-end">
            <v-tabs
              v-model="tab"
              dark
              background-color="tw-bg-primary-500"
              show-arrows
            >
              <v-tabs-slider color="primary lighten-3" />
              <v-tab
                v-for="item in items"
                :key="item.name"
                :href="`#${item.name}`"
                class="tw-py-3 tw-px-6"
              >
                {{ item.label }}
              </v-tab>
            </v-tabs>
          </div>
          <div
            class="tw-flex tw-h-full tw-items-center tw-justify-end tw-space-x-3 tw-px-2"
          >
            <AppBtn color="white" @click="takePhoto">
              {{ $t('app.take_photo') }}
            </AppBtn>
            <v-fab-transition>
              <v-btn
                fab
                x-small
                class="focus:tw-outline-none focus:tw-ring"
                @click="open = false"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </v-fab-transition>
          </div>
        </div>
      </v-container>
      <v-divider />

      <v-card-text v-if="open" class="fill-height tw-p-0">
        <v-responsive class="fill-height tw-py-0">
          <v-tabs-items v-model="tab" class="fill-height">
            <v-tab-item
              :key="TabKeys.all_images"
              :value="TabKeys.all_images"
              class="fill-height"
            >
              <PhotoBoothGalleryImagesTab />
            </v-tab-item>
          </v-tabs-items>
        </v-responsive>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import AppBtn from '@/components/App/AppBtn.vue'
import PhotoBoothGalleryImagesTab from '@/components/Event/PhotoBoothGalleryImagesTab.vue'
const TabKeys = {
  all_images: 'all_images',
}
export default {
  components: {
    AppBtn,
    PhotoBoothGalleryImagesTab,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form_loading: false,
    TabKeys,
    tab: TabKeys.all_images,
  }),

  computed: {
    items() {
      return [{ name: TabKeys.all_images, label: this.$t('app.galley') }]
    },

    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    takePhoto() {
      this.open = false
      this.$store.commit('event/setPhotobooth', {
        modal: true,
      })
    },
  },
}
</script>

<style></style>
