import gql from 'graphql-tag'

export const userSignaling = gql`
  subscription userSignaling {
    userSignaling {
      status
      uuid
    }
  }
`
