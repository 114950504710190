<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
  },
  // eslint-disable-next-line no-unused-vars
  render(h) {
    return <div>hi {this.label}</div>
  },
}
</script>

<style></style>
