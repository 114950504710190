<template>
    <renderless-layout>
        <v-container id="forms_index_page" fluid class="tw-p-0">
            <StickyHeader :nav="nav">
                <template #actions>
                    <div class="tw-flex tw-items-center tw-space-x-3">
                        <AppBtn
                            color="primary-dark"
                            type="button"
                            icon="pencil_edit"
                            @click="css_modal_toggle = true"
                        >
                            <!-- {{ $t('app.edit') }} -->
                            CSS
                        </AppBtn>
                        <AppBtn color="transparent-dark" icon="trash_bin" @click="deleteForm">{{ $t('app.delete') }}</AppBtn>
                        <AppBtn color="white" icon="check" @click="updateForm">{{ $t('app.update') }}</AppBtn>
                    </div>
                </template>
            </StickyHeader>
            <transition name="fade-transition" mode="out-in">
                <template v-if="showLoading">
                    <div class="tw-flex tw-h-full tw-items-center tw-justify-center">
                        <v-progress-circular
                            :size="70"
                            :width="7"
                            color="primary"
                            indeterminate
                        />
                    </div>
                </template>
                <template v-else>
                    <FormsBase 
                        ref="customform"
                        v-model="form"
                    />
                </template>
            </transition>
            <AppConfirmDialog ref="deleteModal" />
            <CSSEditorModal
                v-model="css_modal_toggle"
                :custom-css.sync="form.custom_css"
            />
        </v-container>
    </renderless-layout>
</template>
<script>
import StickyHeader from '@/components/StickyHeader'
import AppBtn from '@/components/App/AppBtn.vue'
import { mapState } from 'vuex'
import FormsBase from '@/views/Admin/Forms/FormsBase.vue'
import AppConfirmDialog from '@/components/App/AppConfirmDialog.vue'
import CSSEditorModal from '@/components/CSSEditorModal.vue'

export default {
    name: 'CustomFormsCreate',
    components: {
        AppBtn,
        StickyHeader,
        FormsBase,
        AppConfirmDialog,
        CSSEditorModal,
    },
    props: {
        id: {
            type: [Number, String],
            default: 0,
        },
    },
    data: () => ({
        showData: {},
        css_modal_toggle: false,
    }),
    computed: {
        ...mapState({
            registry: (state) => state.registries.show.data,
            form: (state) => state.forms.show.form,
            showLoading: (state) => state.forms.show.loading,
        }),
        nav() {
            return [
                {
                    name: this.$t('app.event'),
                    route: { name: 'admin.registries.index' },
                },
                {
                    name: this.registry.label,
                    route: {
                        name: 'admin.registries.show.home',
                        params: { id: this.registry.id },
                    },
                },
                {
                    name: this.$t('admin.home.labels.forms'),
                    route: {
                        name: 'admin.registries.custom-forms.index',
                        params: { id: this.registry.id },
                    },
                },
                {
                    name: this.$t('app.edit'),
                    route: {},
                },
            ]
        },
    },
    async mounted() {
        try {
            await this.$store.dispatch('forms/show', { id: this.id })
        } catch (error) {
            console.error('Error loading data', error)
        }
    },
    methods: {
        async deleteForm() {
            const has_accepted = await this.$refs.deleteModal.open(
                'Eliminar',
                'Estas seguro de eliminar este formulario',
                { color: 'red' },
            )

            if (has_accepted) {
                this.$store
                    .dispatch('forms/destroy', { id: this.form.id })
                    .then(() => {
                        this.$router.push({ name: 'admin.registries.custom-forms.index' })
                    })
                    .catch((err) => {
                        console.error(err)
                    })
            }
        },
        updateForm() {
            this.$refs.customform.submitUpdateForm()
        },
    },
}
</script>