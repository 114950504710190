<template>
  <transition name="fade-transition" mode="out-in">
    <event-container-loader v-if="!constantsLoaded" key="canvas_loading" />
    <template v-else>
      <v-container
        ref="g_event_container"
        v-resize="onResize"
        fluid
        class="tw-min-w fill-height tw-h-full tw-p-0 print:tw-hidden"
        :style="colors"
      >
        <v-row class="tw-m-0 tw-flex tw-h-full tw-flex-col tw-p-0">
          <!-- navigation -->
          <!-- TODO: Editar si es que se quiere mostrar el navbar en mobile -->
          <nav
            v-if="is_desktop"
            class="
              tw-sticky
              tw-top-0
              tw-z-10
              tw-h-16
              tw-flex-initial
              tw-bg-event-header-color
              tw-shadow-md
              lg:tw-px-8
            "
          >
            <div class="tw-container tw-mx-auto tw-h-full tw-py-2">
              <div
                class="
                  tw-h-full
                  lg:tw-flex lg:tw-items-center lg:tw-justify-between
                "
              >
                <div class="tw-min-w-0 tw-flex-1">
                  <div
                    class="tw-relative tw-flex tw-h-16 tw-w-48 tw-items-center"
                  >
                    <v-img
                      :src="event_config.header_url"
                      height="50"
                      contain
                      position="left"
                      class="d-flex"
                    >
                      <template #placeholder>
                        <v-row
                          class="fill-height ma-0 transparent"
                          align="center"
                        >
                          <v-progress-circular
                            size="32"
                            indeterminate
                            color="white"
                          />
                        </v-row>
                      </template>
                      <!-- Class -->
                      <div v-if="registry.is_multi_ticket">
                        <div v-if="userTicket != ''">
                          <div class="chip-ticket">
                            <span>{{ userTicket }}</span>
                          </div>
                        </div>
                      </div>
                    </v-img>
                  </div>
                </div>

                <div class="tw-mt-5 tw-flex lg:tw-mt-0 lg:tw-ml-4">
                  <div class="tw-flex tw-items-center tw-space-x-3">
                    <!-- -------------------- Hybrid -------------------- -->
                    <v-tooltip v-if="registry.is_hybrid" bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          rounded
                          color="white"
                          class="
                            tw-bg-transparent
                            tw-pl-2
                            tw-pr-3.5
                            tw-font-bold
                            tw-normal-case
                            tw-text-gray-700
                            tw-shadow
                            focus:tw-outline-none
                            focus:tw-ring
                            focus:tw-ring-gray-50
                          "
                          v-bind="attrs"
                          v-on="on"
                          @click="OpenUsersHybrid"
                        >
                          <g-icon
                            name="hybrid"
                            class="
                              tw-mr-2 tw--ml-1 tw-h-7 tw-w-10 tw-text-gray-400
                            "
                          />
                          {{ activeUsersHybrid.length }}
                        </v-btn>
                      </template>
                      <span>{{ $t('app.hybrid') }}</span>
                    </v-tooltip>
                    <!-- -------------------- Participantes -------------------- -->
                    <template v-if="isRealtimeEnabled">
                      <!-- <div> -->
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn
                              rounded
                              color="white"
                              class="
                                tw-bg-transparent
                                tw-pl-1
                                tw-pr-3.5
                                tw-font-bold
                                tw-normal-case
                                tw-text-gray-700
                                tw-shadow
                                focus:tw-outline-none
                                focus:tw-ring
                                focus:tw-ring-gray-50
                              "
                              v-bind="attrs"
                              v-on="on"
                              @click="OpenAllUsersPanel"
                            >
                              <g-icon
                                name="users"
                                class="
                                  tw-mr-2 tw--ml-1 tw-h-7 tw-w-10 tw-text-gray-400
                                "
                              />
                              {{ users.length }}
                            </v-btn>
                          </template>
                          <span>{{ $t('app.participants') }}</span>
                        </v-tooltip>
                        <!-- -------------------- Activos -------------------- -->
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn
                              rounded
                              color="white"
                              class="
                                tw-bg-transparent
                                tw-pl-4
                                tw-pr-3.5
                                tw-font-bold
                                tw-normal-case
                                tw-shadow
                                focus:tw-outline-none
                                focus:tw-ring
                                focus:tw-ring-gray-50
                              "
                              v-bind="attrs"
                              v-on="on"
                              @click="OpenActiveUsersPanel"
                            >
                              <span
                                class="
                                  tw-mr-1
                                  tw--ml-1
                                  tw-flex
                                  tw-h-6
                                  tw-w-6
                                  tw-items-center
                                "
                              >
                                <span
                                  class="
                                    tw-relative
                                    tw-flex
                                    tw-h-4
                                    tw-w-4
                                    tw-items-center
                                    tw-justify-center
                                  "
                                >
                                  <span
                                    class="
                                      tw-absolute
                                      tw-inline-flex
                                      tw-h-full
                                      tw-w-full
                                      tw-animate-ping
                                      tw-rounded-full
                                      tw-bg-green-400
                                      tw-opacity-75
                                    "
                                  />
                                  <span
                                    class="
                                      tw-relative
                                      tw-inline-flex
                                      tw-h-3.5
                                      tw-w-3.5
                                      tw-rounded-full
                                      tw-bg-green-500
                                    "
                                  />
                                </span>
                              </span>
                              {{ activeUsers }}
                            </v-btn>
                          </template>
                          <span>{{ $t('app.active') }}</span>
                        </v-tooltip>
                        <!-- -------------------- En esta ubicación -------------------- -->
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn
                              rounded
                              color="white"
                              class="
                                tw-bg-transparent
                                tw-pl-2
                                tw-pr-3.5
                                tw-font-bold
                                tw-normal-case
                                tw-shadow
                                focus:tw-outline-none
                                focus:tw-ring
                                focus:tw-ring-gray-50
                              "
                              v-bind="attrs"
                              v-on="on"
                              @click="OpenInThisLocationUsersPanel"
                            >
                              <g-icon
                                class="
                                  tw-mr-2 tw--ml-1 tw-h-7 tw-w-10 tw-text-gray-400
                                "
                                name="v-rooms"
                              />
                              <span>
                                {{ userInThisLocation }}
                              </span>
                            </v-btn>
                          </template>
                          <span>{{
                            $t('pages.event.container.upper_nav.current_location')
                          }}</span>
                        </v-tooltip>
                      <!-- </div> -->
                    </template>
                    <!-- -------------------- Menu Profile -------------------- -->
                    <v-menu
                      offset-y
                      transition="scale-transition"
                      content-class="tw-py-1 tw-mt-2 tw-bg-white tw-rounded-md tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5"
                    >
                      <template #activator="{ on, attrs }">
                        <button
                          v-if="isOnlineParticipant"
                          id="user-menu"
                          v-ripple
                          type="button"
                          class="
                            tw-flex
                            tw-items-center
                            tw-rounded-full
                            tw-bg-white
                            tw-p-1
                            tw-text-sm
                            tw-ring-2
                            tw-ring-white
                            tw-ring-opacity-20
                            focus:tw-outline-none focus:tw-ring-opacity-100
                          "
                          aria-haspopup="true"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span class="tw-sr-only">Open user menu</span>
                          <v-img
                            :src="user.avatar"
                            class="
                              tw-h-10 tw-w-10 tw-rounded-full tw-object-cover
                            "
                            contain
                          >
                            <template #placeholder>
                              <v-row
                                class="fill-height ma-0 transparent"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  size="24"
                                  indeterminate
                                  color="primary"
                                />
                              </v-row>
                            </template>
                          </v-img>
                        </button>
                        <button
                          v-if="[isRep, isModerator, isAdmin].includes(true)"
                          id="user-menu"
                          v-ripple
                          type="button"
                          :class="[
                            isAdmin ? 'tw-bg-green-700' : 'tw-bg-blue-700',
                          ]"
                          class="
                            tw-flex
                            tw-items-center
                            tw-rounded-full
                            tw-p-1
                            tw-pr-3.5
                            tw-text-sm
                            tw-ring-2
                            tw-ring-white
                            tw-ring-opacity-20
                            focus:tw-outline-none focus:tw-ring-opacity-100
                          "
                          aria-haspopup="true"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span class="tw-sr-only">Open user menu</span>
                          <v-img
                            :src="user.avatar"
                            class="
                              tw-h-8 tw-w-8 tw-rounded-full tw-object-cover
                            "
                            contain
                          >
                            <template #placeholder>
                              <v-row
                                class="fill-height ma-0 transparent"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  size="24"
                                  indeterminate
                                  color="primary"
                                />
                              </v-row>
                            </template>
                          </v-img>
                          <span
                            class="
                              tw-ml-2
                              tw-text-base
                              tw-font-semibold
                              tw-text-white
                            "
                          >
                            {{ roleLabel }}
                          </span>
                        </button>
                      </template>
                      <v-list>
                        <v-list-item @click="openUserProfile">
                          <v-list-item-title>{{
                            $t(
                              'pages.event.container.upper_nav.user_dropdown.profile',
                            )
                          }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="isRealtimeEnabled" @click="openContacts">
                          <v-list-item-title>{{
                            $t(
                              'pages.event.container.upper_nav.user_dropdown.contacts',
                            )
                          }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="canOpenModModal" @click="openMod">
                          <v-list-item-title>
                            {{
                              $t(
                                'pages.event.container.upper_nav.user_dropdown.moderate',
                              )
                            }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="canOpenCustomFormModal" @click="openModCustomForm">
                          <v-list-item-title>
                            {{
                              $t(
                                'pages.event.container.upper_nav.user_dropdown.moderate',
                              )
                            }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="isRep && hasRooms"
                          @click="openRepDashboard"
                        >
                          <v-list-item-title>
                            {{
                              $t(
                                'pages.event.container.upper_nav.user_dropdown.rooms',
                              )
                            }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="toggleFullScreen">
                          <v-list-item-title>
                            {{
                              $t(
                                'pages.event.container.upper_nav.user_dropdown.fullscreen',
                              )
                            }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="isAdmin"
                          :to="{ name: 'admin.dashboard.rewrite' }"
                        >
                          <v-list-item-title>Admin Dashboard</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="logout">
                          <v-list-item-title>{{
                            $t('app.logout')
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <!-- -------------------- Notifications -------------------- -->
                    <NotificationsEvent
                      ref="event-notify"
                      :notifications="notificationsForRegistry"
                    />
                    <!-- -------------------- Calendar -------------------- -->
                    <v-tooltip v-if="canViewCalendar" bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          color="white"
                          rounded
                          fab
                          small
                          class="
                            tw-normal-cas
                            tw-bg-transparent
                            tw-font-bold
                            tw-text-gray-800
                            tw-shadow-none
                            focus:tw-outline-none
                            focus:tw-ring
                            focus:tw-ring-gray-50
                          "
                          v-bind="attrs"
                          @click="openSimpleCalendar"
                          v-on="on"
                        >
                          <transition name="fade-transition" mode="out-in">
                            <g-icon
                              key="calendar"
                              name="calendar"
                              class="tw-h-7 tw-w-7"
                            />
                          </transition>
                        </v-btn>
                      </template>
                      <span>{{
                        $t('pages.event.container.upper_nav.activities_helper')
                      }}</span>
                    </v-tooltip>
                    <!-- -------------------- Favorite -------------------- -->
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          color="white"
                          rounded
                          fab
                          small
                          class="
                            tw-bg-transparent
                            tw-font-bold
                            tw-normal-case
                            tw-text-gray-800
                            tw-shadow-none
                            focus:tw-outline-none
                            focus:tw-ring
                            focus:tw-ring-gray-50
                          "
                          :loading="isAddingFavorite"
                          :disabled="isAddingFavorite"
                          v-bind="attrs"
                          @click="createEventFavorite"
                          v-on="on"
                        >
                          <transition name="fade-transition" mode="out-in">
                            <g-icon
                              v-if="isRoomFavorite"
                              key="star_solid"
                              name="star_solid"
                              class="tw-h-7 tw-w-7 tw-text-yellow-400"
                            />
                            <g-icon
                              v-else
                              key="star"
                              name="star"
                              class="tw-h-6 tw-w-6"
                            />
                          </transition>
                        </v-btn>
                      </template>
                      <span v-if="isRoomFavorite">
                        {{
                          $t('pages.event.container.upper_nav.already_in_fav')
                        }}
                      </span>
                      <span v-else>
                        {{ $t('pages.event.container.upper_nav.add_to_fav') }}
                      </span>
                    </v-tooltip>
                    <!-- -------------------- Matchmaking -------------------- -->
                    <v-tooltip v-if="false" bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          color="white"
                          rounded
                          fab
                          small
                          class="
                            tw-normal-cas
                            tw-bg-transparent
                            tw-font-bold
                            tw-text-gray-800
                            tw-shadow-none
                            focus:tw-outline-none
                            focus:tw-ring
                            focus:tw-ring-gray-50
                          "
                          v-bind="attrs"
                          @click="openMatchmaking"
                          v-on="on"
                        >
                          <transition name="fade-transition" mode="out-in">
                            <g-icon name="matchmaking" class="tw-h-6 tw-w-6" />
                          </transition>
                        </v-btn>
                      </template>
                      <span>{{
                        $t('pages.event.profile.labels.matchmaking')
                      }}</span>
                    </v-tooltip>
                    <!-- -------------------- Leaderboard -------------------- -->
                    <v-tooltip v-if="hasLeaderboard" bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          color="white"
                          rounded
                          fab
                          small
                          class="
                            tw-normal-cas
                            tw-bg-transparent
                            tw-font-bold
                            tw-text-gray-800
                            tw-shadow-none
                            focus:tw-outline-none
                            focus:tw-ring
                            focus:tw-ring-gray-50
                          "
                          v-bind="attrs"
                          @click="openLeaderboard"
                          v-on="on"
                        >
                          <transition name="fade-transition" mode="out-in">
                            <g-icon name="award" class="tw-h-6 tw-w-6" />
                          </transition>
                        </v-btn>
                      </template>
                      <span>{{
                        $t('admin.events.items.labels.leaderboards')
                      }}</span>
                    </v-tooltip>
                    <!-- -------------------- Search -------------------- -->
                    <v-btn
                      color="grey darken-2"
                      rounded
                      text
                      fab
                      small
                      dark
                      class="
                        tw-bg-transparent tw-font-bold tw-normal-case
                        focus:tw-outline-none
                        focus:tw-ring
                        focus:tw-ring-gray-50
                      "
                      @click="search_box_modal = true"
                    >
                      <g-icon name="search" class="tw-h-6 tw-w-6" />
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          <!-- virtual room wrapper  -->
          <transition name="fade-transition" mode="out-in">
            <!-- virtual room render -->
            <router-view :key="$route.fullPath" />
          </transition>

          <EventContainerGradient v-if="is_desktop" :colors="colors" />
          <!-- (navItemClickEvent)=> -->
          <EventContainerDesktopNav
            v-if="is_desktop"
            :window-size="screenSize"
            :nav-items="nav_items"
          />
          <EventContainerMobileLayer
            v-if="!is_desktop"
            @on-nav-click="clickActionEvent"
          />

          <RealTimeConnections v-if="isRealtimeEnabled"  :slug="slug" />
        </v-row>
        <!-- v-if="!is_preview" -->
        <div
          class="
            tw-pointer-events-none tw-fixed tw-inset-0 tw-z-10 tw-hidden tw-p-4
            lg:tw-flex lg:tw-items-end lg:tw-justify-start lg:tw-p-6
          "
        >
          <v-btn
            fab
            class="
              tw-pointer-events-auto tw-m-4 tw-h-14 tw-w-14
              md:tw-h-16 md:tw-w-16
            "
            @click="toggleFullScreen"
          >
            <v-icon class="tw-text-4xl">fullscreen</v-icon>
          </v-btn>
          <transition name="fade-transition" mode="out-in">
            <PlayMusicBtn v-if="hasMusic" />
            <!-- <div v-if="hasMusic">
              <audio
                ref="audio"
                class="tw-hidden"
                :src="music_track_url"
                preload
                loop
                muted
              />
              <v-btn
                fab
                class="
                  tw-m-4 tw-w-14 tw-h-14
                  md:tw-w-16 md:tw-h-16
                  tw-pointer-events-auto
                "
                @click="toggleSound()"
              >
                <v-icon class="tw-text-4xl" v-if="isPlaying">pause</v-icon>
                <v-icon class="tw-text-4xl" v-else>play_arrow</v-icon>
              </v-btn>
            </div> -->
          </transition>
        </div>
        <ChatWrapper ref="chatRef" />
        <EventContainerPreview v-if="is_preview" />
        <!-- <VideoChatWrapper v-if="false" /> -->
        <UserEventCardModal v-if="cardModal" v-model="cardModal" />
        <UserProfileModal v-if="profileModal" v-model="profileModal" />
        <RepDashboardModal v-if="isRep" v-model="repDashboardModal" />
        <ModDashboardModal v-if="canOpenModModal" v-model="modDashboardModal" />
        <ModDashboardCustomFormModal v-if="canOpenCustomFormModal" v-model="modDashboardCustomFormModal" />
        <!-- v-if="photoboothModal" -->
        <PhotoBoothModal v-model="photoboothModal" />
        <!-- v-if="photoboothGalleryModal" -->
        <PhotoBoothGalleryModal v-model="photoboothGalleryModal" />
        <SearchBox />
        <EventContainerFlipOverlay :value="shouldFlip" />
        <EventRoomIframeModal
          v-if="iframeItemModal"
          v-model="iframeItemModal"
        />
        <EventRoomSimpleVideoModal
          v-if="simpleVideoItemModal"
          v-model="simpleVideoItemModal"
        />
        <!-- <EventRoomSimpleVideoModal
          v-if="simpleVideoItemModal"
          v-model="simpleVideoItemModal"
        /> -->
        <EventRoomContentElementIframeModal
          v-if="contentItemModal"
          v-model="contentItemModal"
        />
        <EventRoomContentElementVideoModal
          v-if="contentVideoModal"
          v-model="contentVideoModal"
        />
        <EventRoomContentWindowModal
          v-if="contentWindowModal"
          v-model="contentWindowModal"
        />
        <EventCalendarModal v-if="canViewCalendar" v-model="event_calendar_modal" />
        <NotificationModal v-if="notifyModal" v-model="notifyModal" />
        <ChatConversationVideoIframe
          v-if="iframe_modal"
          v-model="iframe_modal"
          :uuids="room_uuids"
        />
        <CustomFormModal v-if="customformModal" v-model="customformModal" />
      </v-container>
    </template>
  </transition>
</template>

<script>
import { mapFields, v4 } from '@/helpers'
import { mapGetters, mapState } from 'vuex'
import ChatWrapper from '@/components/Event/ChatWrapper.vue'
// import VideoChatWrapper from '@/components/Event/VideoChatWrapper'
import PlayMusicBtn from './PlayMusicBtn.vue'
import UserEventCardModal from '@/components/Event/UserEventCardModal'
import UserProfileModal from '@/components/Event/UserProfileModal'
import SearchBox from '@/components/Event/SearchBox'
import EventContainerDesktopNav from './EventContainerDesktopNav'
import EventContainerMobileLayer from './EventContainerMobileLayer'
import EventContainerFlipOverlay from './EventContainerFlipOverlay'
import EventContainerLoader from './EventContainerLoader'
import EventRoomIframeModal from '@/components/Event/EventRoomIframeModal.vue'
import EventRoomSimpleVideoModal from '@/components/Event/EventRoomSimpleVideoModal.vue'
import EventRoomContentElementIframeModal from '@/components/Event/EventRoomContentElementIframeModal.vue'
import EventRoomContentElementVideoModal from '@/components/Event/EventRoomContentElementVideoModal.vue'
import EventRoomContentWindowModal from '@/components/Event/EventRoomContentWindowModal.vue'
import ChatConversationVideoIframe from '@/components/Event/ChatConversationVideoIframe.vue'
import EventContainerPreview from './EventContainerPreview'
import NotificationModal from '@/components/AppLayout/NotificationModal.vue'
import RepDashboardModal from '@/components/Event/RepDashboardModal.vue'
import PhotoBoothModal from '@/components/Event/PhotoBoothModal.vue'
import CustomFormModal from '@/components/Event/CustomFormModal.vue'
// import {
//   SET_ACTIVE,
//   // ONLINE_USERS_UPDATE,
//   GET_ALL_USERS_BY_REGISTRY_QUERY_AND_USERS_IN_DMS,
//   NEW_USER_IN_DMS,
//   onlineUser,
// } from '@/graphql/queries/userQueries'
// import {
//   // setUserLocation,
//   setUserLocationAndActive,
//   subUsersLocation,
// } from '@/graphql/queries/vrUserLocationQueries'
// import { userSignaling } from '@/graphql/subscriptions/userSubscriptions'
import { wsClient } from '@/apollo-client'
import GIcon from '@/components/GIcon.vue'
import EventContainerGradient from './EventContainerGradient.vue'
import { TabKeys } from '@/enums/TabKeys'
import { eventNotification } from '@/graphql/queries/notificationsQueries'
// import { eventUsersHybridGp } from '@/graphql/queries/notificationsQueries'
import { newChannelMessageFromPrivate } from '@/graphql/queries/messageQueries'
import { eventUserNotification } from '@/graphql/queries/notificationsQueries'
// import { VideoChatStateKeys } from '@/enums/VideoChatStateKeys'
// import { signalUser } from '@/graphql/mutations/userMutations'
import { ClickableTypeKeys } from '@/enums/ClickableTypeKeys'
import { openWindowTab } from '@/utils/openWindowTab'
import { ContentTypeKeys } from '@/enums/ContentTypeKeys'
import { TargetLinkKeys } from '@/enums/TargetLinkKeys'
import { download } from '@/utils/download'
import { UserListKeys } from '@/enums/UserListKeys'
// import { pipe, map, toArray } from 'lazy-collections'
import { AppCoreVitalKeys } from '@/enums/AppCoreVitalKeys'
import { BeaconService } from '@/services/BeaconService'
import PhotoBoothGalleryModal from '@/components/Event/PhotoBoothGalleryModal.vue'
import EventCalendarModal from '@/components/Event/EventCalendarModal.vue'
import ModDashboardModal from '@/components/Event/ModDashboardModal.vue'
import ModDashboardCustomFormModal from '@/components/Event/ModDashboardCustomFormModal.vue'
import NotificationsEvent from '@/components/NotificationsEvent.vue'
import { diffForHumans } from '@/utils/dateHelpers'
import RealTimeConnections from './RealTimeConnections.vue'

export default {
  components: {
    PhotoBoothModal,
    NotificationModal,
    EventRoomContentWindowModal,
    EventRoomSimpleVideoModal,
    EventContainerGradient,
    EventContainerPreview,
    EventContainerLoader,
    EventContainerFlipOverlay,
    EventContainerMobileLayer,
    EventRoomIframeModal,
    EventContainerDesktopNav,
    EventRoomContentElementIframeModal,
    PlayMusicBtn,
    EventRoomContentElementVideoModal,
    // VideoChatWrapper,
    ChatConversationVideoIframe,
    ChatWrapper,
    UserEventCardModal,
    UserProfileModal,
    SearchBox,
    GIcon,
    RepDashboardModal,
    PhotoBoothGalleryModal,
    EventCalendarModal,
    ModDashboardModal,
    ModDashboardCustomFormModal,
    NotificationsEvent,
    CustomFormModal,
    RealTimeConnections,
  },
  apollo: {
    $subscribe: {
      eventNotification: {
        query: eventNotification,
        result({ data }) {
          console.log('Notification data: ', data)
          const {
            body,
            title,
            event_broadcast_style_type,
            duration,
            language,
            has_sound,
            clickable,
            action_label,
            registry_id,
          } = data.eventNotification
          // check language
          if (registry_id) {
            if (registry_id != this.registry.id) {
              return
            }
          }
          if (language !== this.$i18n.locale) {
            console.log('not pushing the notification')
            return
          }
          // slim - full page
          if (event_broadcast_style_type == 'slim') {
            this.$store.dispatch('notifications/addNotification', {
              title,
              body,
              duration,
              has_sound,
              clickable,
              action_label,
            })
          }
          if (event_broadcast_style_type == 'overlay') {
            this.notifyModal = true
            this.notifyInfo = data.eventNotification
          }
          // Se envia id del broadcast para validar si el usuario ya tiene esa notificacion
          this.getNotifications()
          // this.validBroadcast(id, title, body)
        },
      },
      mixedNotification: {
        query: newChannelMessageFromPrivate,
        variables() {
          return {
            ids: this.chanel_ids,
          }
        },
        result({ data }) {
          const { body, channel_id } = data.newChannelMessageFromPrivate
          const user_uuid = this.private_channels[channel_id]
          const partner_user = this.users.find((row) => row.uuid === user_uuid)
          const { first_name, last_name } = partner_user
          if (this.active_channel == channel_id && this.showChat) {
            return
          }
          this.$store.dispatch('notifications/addNotification', {
            title: `${first_name} ${last_name}`,
            body: body,
            type: 'msg',
            has_sound: true,
            msg_meta: {
              partner_user,
              channel_id,
            },
          })
          this.getNotifications()
        },
      },
      eventUserNotification: {
        query: eventUserNotification,
        result({ data }) {
          this.getNotifications()
          this.$store.dispatch('notifications/addNotification', {
            title: data.eventUserNotification.data.title,
            body:
              data.eventUserNotification.data.body == ''
                ? this.$t(
                    `app.notify.descriptions.${data.eventUserNotification.data.body_label}`,
                  )
                : data.eventUserNotification.data.body,
          })
        },
      },
    },
  },
  props: {
    slug: {
      type: [Number, String],
      default: 0,
    },
  },

  // sockets: {
  //   myUser(payload) {
  //     console.log('MY USER')
  //     console.log(payload)
  //   },
  //   newUser(payload) {
  //     console.log(payload)
  //   },
  // },
  data: () => ({
    isAddingFavorite: false,
    isPlaying: false,
    landscape: false,
    windowSize: {
      x: 0,
      y: 0,
    },
    screenSize: {
      x: 0,
      y: 0,
    },
  }),
  metaInfo() {
    return {
      title: this.virtual_room.label,
      meta: [
      {
          vmid: 'og:title',
          property: 'og:title',
          content: this.virtual_room.label, // Título del evento para Open Graph
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: this.virtual_room.description, // Descripción del evento
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: this.virtual_room.bg_desktop, // URL del logo del evento para Open Graph
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: window.location.href, // URL del evento
        },
        {
          vmid: 'twitter:title',
          name: 'twitter:title',
          content: this.virtual_room.label, // Título para Twitter
        },
        {
          vmid: 'twitter:description',
          name: 'twitter:description',
          content: this.virtual_room.description, // Descripción para Twitter
        },
        {
          vmid: 'twitter:image',
          name: 'twitter:image',
          content: this.virtual_room.bg_desktop, // Imagen para Twitter
        },
        {
          vmid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image', // Tipo de tarjeta de Twitter
        },
      ],
    }
  },
  computed: {
    ...mapFields({
      fields: ['contentItemModal', 'contentItemData'],
      base: ['event', 'contentItem'],
      mutation: 'event/setContentItem',
    }),
    ...mapFields({
      fields: ['event_calendar_modal'],
      base: ['event', 'flexible'],
      mutation: 'event/setFlexible',
    }),
    hasLeaderboard() {
      return this.event_config.has_leaderboard
    },
    hasRooms() {
      // later but right now just length on reps
      return this.rep_rooms.length > 0
    },
    ...mapFields({
      fields: ['contentVideoModal', 'contentVideoData'],
      base: ['event', 'contentVideo'],
      mutation: 'event/setContentVideo',
    }),
    ...mapFields({
      fields: ['contentWindowModal', 'contentWindowData'],
      base: ['event', 'contentWindow'],
      mutation: 'event/setContentWindow',
    }),
    shouldFlip() {
      if (this.modDashboardModal || this.modDashboardCustomFormModal) {
        return false
      }
      return !this.is_desktop && !this.landscape
    },
    chanel_ids() {
      return Object.keys(this.private_channels).toString()
    },
    iframeItemModal: {
      get() {
        return this.$store.state.event.iframeItem.iframeItemModal
      },
      set(value) {
        return this.$store.commit('event/setIframeItem', {
          iframeItemModal: value,
        })
      },
    },
    photoboothModal: {
      get() {
        return this.$store.state.event.photobooth.modal
      },
      set(value) {
        return this.$store.commit('event/setPhotobooth', {
          modal: value,
        })
      },
    },
    photoboothGalleryModal: {
      get() {
        return this.$store.state.event.photobooth.gallery_modal
      },
      set(value) {
        return this.$store.commit('event/setPhotobooth', {
          gallery_modal: value,
        })
      },
    },
    simpleVideoItemModal: {
      get() {
        return this.$store.state.event.simpleVideoItem.simpleVideoItemModal
      },
      set(value) {
        return this.$store.commit('event/setSimpleVideoItem', {
          simpleVideoItemModal: value,
        })
      },
    },
    activeUsers() {
      // return this.users.filter((user) => user.is_online).length
      // return this.usersLocation.length
      return this.usersLocation.filter(
        (row) => row.registry_uuid === this.registry.uuid,
      ).length
    },
    userInThisLocation() {
      return this.usersLocation.filter(
        (row) =>
          row.slug === this.slug && row.registry_uuid === this.registry.uuid,
      ).length
    },
    activeUsersHybrid() {
      return this.users.map((row) =>
        row.attendees.filter(
          (item) =>
            item.language.name === this.current_config_language_key &&
            item.current_checkin_id !== null &&
            item.current_checkout == null,
        ),
      ).reduce((acc, cur) => {
        return acc.concat(cur)
      }, [])
    },
    search_box_modal: {
      get() {
        return this.$store.state.event.search.modal
      },
      set(value) {
        return this.$store.commit('event/setSearch', {
          modal: value,
        })
      },
    },
    cardModal: {
      get() {
        return this.$store.state.event.card.modal
      },
      set(value) {
        return this.$store.commit('event/setCard', {
          modal: value,
        })
      },
    },
    profileModal: {
      get() {
        return this.$store.state.event.profile.modal
      },
      set(value) {
        return this.$store.commit('event/setProfile', {
          modal: value,
        })
      },
    },

    ...mapFields({
      fields: ['notifyModal', 'notifyInfo'],
      base: ['notifications'],
      mutation: 'notifications/setNotification',
    }),
    repDashboardModal: {
      get() {
        return this.$store.state.event.rep_dashboard.modal
      },
      set(value) {
        return this.$store.commit('event/setRepDashboard', {
          modal: value,
        })
      },
    },
    modDashboardModal: {
      get() {
        return this.$store.state.event.mod_dashboard.modal
      },
      set(value) {
        return this.$store.commit('event/setModDashboard', {
          modal: value,
        })
      },
    },
    modDashboardCustomFormModal: {
      get() {
        return this.$store.state.event.mod_dashboard_custom_form.modal
      },
      set(value) {
        return this.$store.commit('event/setModDashboardCustomForm', {
          modal: value,
        })
      },
    },
    profileTab: {
      get() {
        return this.$store.state.event.profile.tab
      },
      set(value) {
        return this.$store.commit('event/setProfile', {
          tab: value,
        })
      },
    },
    customformModal: {
      get() {
        return this.$store.state.event.customform.modal
      },
      set(value) {
        return this.$store.commit('event/setCustomForm', {
          modal: value,
        })
      },
    },
    ...mapFields({
      fields: [
        'active_chats',
        'videoChatModal',
        'private_channels',
        'active_channel',
        'showChat',
        'home_tab',
      ],
      base: ['chat'],
      mutation: 'chat/setState',
    }),
    ...mapFields({
      fields: ['iframe_modal', 'room_uuids'],
      base: ['chat', 'video_chat'],
      mutation: 'chat/setVideoChat',
    }),
    roleLabel() {
      if (this.isAdmin) {
        return this.$t('app.roles.admin')
      }
      if (this.isModerator) {
        return this.$t('app.roles.moderator')
      }
      if (this.isRep) {
        return this.$t('app.roles.representative')
      }
      return this.$t('app.roles.user')
    },
    isOnlineParticipant() {
      return (
        this.rolesLookUp.includes('user') &&
        [0, 1].includes(this.user.roles.length)
      )
    },
    colors() {
      return {
        '--ui_color': this.event_config.ui_color,
        '--header_color': this.event_config.header_color,
        '--footer_color': this.virtual_room.has_custom_footer
          ? this.virtual_room.footer_color
          : this.event_config.footer_color,
        '--headers_color': this.event_config.headers_color,
      }
    },
    nav_items() {
      if (this.license.can_concurrent_events) {
        // TODO: Validate navitems - class
        // 1. solomente van a ser items en el idioma
        const $nav_items = this.registry.nav_items.filter(
          (row) => row.language.name === this.current_config_language_key,
        )
        // // 2. solamente serian los items de la clase
        // if (this.registry.is_multi_ticket) {
        //   if (this.user.ticket) {
        //     return $nav_items.filter(
        //       (row) =>
        //         row.registry_ticket_id === this.user.ticket.registry_ticket_id,
        //     )
        //   }
        //   // Si el usuario no tiene clase le aparecera todos los nav_items sin clase
        //   return $nav_items.filter((row) => row.registry_ticket_id === null)
        // }
        return $nav_items
      }
      return this.$store.state.event.constants.nav_items.data[
        this.current_config_language_key
      ]
    },
    event_config() {
      if (this.license.can_concurrent_events) {
        return this.registry.configs.find(
          (row) => row.language.name === this.current_config_language_key,
        )
      }
      return this.event_configs[this.current_config_language_key]
    },
    ...mapFields({
      fields: [
        'is_desktop',
        'is_preview',
        'nav_count',
        'uuid_tracker',
        'is_user_in_event',
        'window_width',
        'window_height',
        'navigation_log_virtual_room_ids',
      ],
      base: ['event', 'constants'],
      mutation: 'event/setConstants',
    }),
    ...mapFields({
      fields: ['users', 'usersLocation', 'globalChannels'],
      base: ['event', 'socket'],
      mutation: 'event/setSocket',
    }),
    music_track_url() {
      return this.virtual_room.music_track_url
    },
    hasMusic() {
      return this.virtual_room.has_music_track
    },
    ...mapGetters({
      canOpenModModal: ['event/canOpenModModal'],
      canOpenCustomFormModal: ['event/canOpenCustomFormModal'],
      rolesLookUp: ['session/rolesLookUp'],
      isAdmin: ['session/isAdmin'],
      isRep: ['session/isRep'],
      isModerator: ['session/isModerator'],
      isRealtimeEnabled: ['event/isRealtimeEnabled'],
    }),
    ...mapState({
      user: (state) => state.session.user,
      tenant: (state) => state.tenant,
      license: (state) => state.event.constants.license,
      video_chat_status: (state) => state.chat.video_chat.status,
      virtual_room: (state) => state.event.show.data,
      showLoading: (state) => state.event.show.isLoading,
      favorites: (state) => state.event.constants.favorites,
      registry: (state) => state.event.constants.registry,
      rep_rooms: (state) => state.event.constants.rep_rooms,
      event_configs: (state) => state.event.constants.event_configs.data,
      constantsLoaded: (state) => state.event.constants.constantsLoaded,
      current_config_language_key: (state) =>
        state.event.constants.event_configs.data.current_config_language_key,
      // TODO: New Structure Notifications
      BarNotifications: (state) => state.event.constants.notifications,
      addLink: (state) => state.registry.toPathContact.uuid,
    }),
    formatNotifications() {
      return this.BarNotifications.map((ntf) => {
        return {
          ...ntf,
          date: diffForHumans(ntf.created_at),
          data: JSON.parse(ntf.data),
        }
      })
    },
    notificationsForRegistry() {
      return this.formatNotifications.filter((row) => row.data.registry_id === this.registry.id)
    },
    canViewCalendar() {
      return this.currentCalendar.is_active
    },
    currentCalendar() {
      return this.registry?.calendars.find(
        (row) => row.language.name == this.current_config_language_key,
      )
    },
    isRoomFavorite() {
      return this.favorites
        .map((row) => row.virtual_room_id)
        .includes(this.virtual_room.id)
    },
    userTicket() {
      var ticket = this.user.ticket
      if (ticket) {
        return ticket.find((row) => row.registry_id == this.registry.id)
          ? ticket.find((lbl) => lbl.registry_id == this.registry.id)
              .registry_ticket.ticket_label_key
          : ''
      } else {
        return ''
      }
    },
  },

  beforeCreate: function () {
    console.log('before create')
  },
  mounted() {
    this.is_user_in_event = true
    this.uuid_tracker = v4()
    // console.log(this.uuid_tracker)
    // this.$socket.io.opts.query = {
    //   token: localStorage.getItem('node_access_token'),
    // }
    // this.$socket.disconnect().connect()

    // this.$socket.open()

    // this.$socket.emit('checkUser', { foo: 'bar' })
    // this.$socket.emit('joined', { foo: 'bar' })
    this.resetChatWidget()
    this.onResize()
    this.is_preview = ['event-preview.route'].includes(
      this.$router.currentRoute.name,
    )
    this.$store.dispatch('event/loadConstants').then(() => {
      if (this.registry.has_custom_fav_icon) {
        window.document.querySelector('link[rel=icon]').href =
          this.registry.custom_fav_icon_url
      } else {
        window.document.querySelector('link[rel=icon]').href =
          '/bizzdome-fav.svg'
      }
      // document.documentElement.style.setProperty("--ui_color", `#3200B8`);
    }).finally(() => {
      this.$store.dispatch('event/loadContantsV2').then(() => {
        console.log('🚀 ~ file: EventContainer.vue:1425 ~ constants v2:')
      })
    })
    // console.log('apollog init users ', this.initUsers.users)
    // $apollo.query()
    // this.$apollo
    //   .query({
    //     query: GET_ALL_USERS_BY_REGISTRY_QUERY_AND_USERS_IN_DMS,
    //     fetchPolicy: 'network-only',
    //   })
    //   .then((res) => {
    //     console.log('GET_ALL_USERS_BY_REGISTRY_QUERY_AND_USERS_IN_DMS')
    //     // console.log(res.data)
    //     const {
    //       usersParticipatingInMyChannels,
    //       // getUsersByRegistries,
    //       channelsWhereType,
    //       getUsersLocation,
    //     } = res.data

    //     this.usersLocation = getUsersLocation
    //     const simple_user_location_ids = getUsersLocation.map(
    //       (row) => row.user_id,
    //     )
    //     this.users = this.users.map((row) => ({
    //       ...row,
    //       is_online: simple_user_location_ids.includes(row.id),
    //     }))
    //     this.globalChannels = channelsWhereType
    //     this.active_chats = usersParticipatingInMyChannels
    //       .filter((row) => row.message_count > 0)
    //       .map((row) => row.user.uuid)
    //     this.private_channels = usersParticipatingInMyChannels
    //       .map((row) => [row.channel_id, row.user.uuid])
    //       .reduce((obj, cur) => ({ ...obj, [cur[0]]: cur[1] }), {})
    //     this.$apollo
    //       .mutate({
    //         // Query
    //         mutation: setUserLocationAndActive,
    //         variables: {
    //           slug: this.slug,
    //         },
    //       })
    //       .then((data) => {
    //         console.log('Two mutations ran')
    //         console.log(data)
    //       })
    //     // this.$apollo
    //     //   .mutate({
    //     //     // Query
    //     //     mutation: SET_ACTIVE,
    //     //   })
    //     //   .then(() => {
    //     //     console.log('MOUNTED Set active: triggered ')
    //     //     this.$apollo
    //     //       .mutate({
    //     //         mutation: setUserLocation,
    //     //         variables: {
    //     //           slug: this.slug,
    //     //         },
    //     //       })
    //     //       .then(() => {
    //     //         console.log('MOUNTED EventVirtualRoom: triggered ')
    //     //       })
    //     //   })
    //   })

    // this.$apollo.query({ query: CHECK_PARTICIPANTING_IN }).then((res) => {
    //   console.log('CHECK_PARTICIPANTING_IN', res.data.userParticipatingIn)
    //   const users = res.data.userParticipatingIn.map((row) => {
    //     const [user] = row.channel.participants.filter(
    //       (inner_row) => inner_row.user.email !== this.user.email,
    //     )
    //     return { ...user.user }
    //   })
    //   this.active_chats = users
    // })
    // const observer = this.$apollo.subscribe({
    //   query: NEW_USER_IN_DMS,
    // })

    // observer.subscribe({
    //   next: (res) => {
    //     const users = res.data.newChannelParticipating.map((row) => {
    //       const [user] = row.channel.participants.filter(
    //         (inner_row) => inner_row.user.email !== this.user.email,
    //       )
    //       console.log(user)
    //       return { ...user.user }
    //     })
    //     this.active_chats = users
    //   },
    //   error(error) {
    //     console.error(error)
    //   },
    // })
    // const logData = (event) => {
    const logData = () => {
      if (document.visibilityState === 'hidden') {
        const core_vital_type = AppCoreVitalKeys.EventFinalized
        const solid = this.$store.getters['event/plainMetricsPayload']
        const body = {
          ...solid,
          core_vital_type,
          simple_log: 'beacon',
          _token: this.$store.state.session.token,
        }
        BeaconService(body, this.tenant)
      }
    }
    document.addEventListener('visibilitychange', logData)
    const interval = setInterval(
      () => {
        this.getUsers()
      },
      15000,
      // 30000,
      // 60000,
    )
    this.$once('hook:destroyed', () => {
      console.log('destroyed')

      clearInterval(interval)
      document.removeEventListener('visibilitychange', logData)
    })

    if (this.addLink) {
      setTimeout(() => {
        this.addContact(this.addLink)
      }, 3000)
    }

    // setTimeout(() => {
    //   this.generateMetaOg()
    // }, 4000)

    const intervalHybrid = setInterval(
      () => {
        this.getUsersHybrid()
      },
      15000,
      // 30000,
      // 60000,
    )

    this.$once('hook:destroyed', () => {
      console.log('destroyed')

      clearInterval(intervalHybrid)
      document.removeEventListener('visibilitychange', logData)
    })
  },
  beforeDestroy() {
    // console.log(this.$apollo.subscriptions)
    this.$store.commit('event/setConstants', { constantsLoaded: false })
    this.navigation_log_virtual_room_ids = []
    this.$store.commit('event/setShow', { isLoading: true })
    // const core_vital_type = AppCoreVitalKeys.EventFinalized
    // this.$store.dispatch('general/recordCoreVitalV2', {
    //   payload: {
    //     core_vital_type,
    //     simple_log: 'beforeDestroy',
    //     // second_tracker: this.uuids,
    //   },
    //   core_vital_type,
    // })
    // this.$apolloProvider.defaultClient.stop()
    wsClient.close()
    this.nav_count = 0
    // this.$socket.close()

    this.is_user_in_event = false
    this.$store.commit('session/setAdmin', {
      to_route: null,
      previous_route: null,
    })
    // this.$socket.disconnect()
    // for (let sub in this.$apollo.subscriptions) {
    // this.$apollo.
    // }
    // this.$apollo.subscriptions.forEach(function (sub) {
    //   sub.unsubscribe()
    // })
    // this._apolloSubscriptions.forEach(function (sub) {
    //   sub.unsubscribe()
    // })
  },
  methods: {
    updateUsersLocation(userLocations) {
      const updatedUsers = userLocations.filter(
        (row) => row.registry_uuid === this.registry.uuid,
      )

      // Solo actualiza los usuarios si hay cambios significativos
      if (JSON.stringify(updatedUsers) !== JSON.stringify(this.users)) {
        this.users = updatedUsers
      }
    },
    addContact(uuid) {
        const user = this.users.find((user) => user.uuid == uuid)
        if (this.user.id != user.id) {
          this.$store.dispatch('event/createEventConnection', {
                  event_user_id: user?.id,
                  is_hybrid: true,
              })
              .catch((err) => {
                  this.$store.dispatch('notifications/addNotification', {
                      title: 'Error añadiendo contacto',
                      body: err,
                  })
              }).finally(() => {
                this.$store.dispatch('notifications/addNotification', {
                  title: `${user?.first_name} ${user?.last_name}`,
                  status: 'success',
                  body: this.$t(`app.add_contact_qr.success`),
                })
                this.$store.commit('registry/setPathContact', {uuid: ''})
              })
        }
    },
    // TODO: Get Notifications and Valid Broadcast
    getNotifications() {
      setTimeout(() => {
        this.$store.dispatch('event/getNotifications')
      }, 2000)
    },
    // validBroadcast(id, title, body) {
    //   setTimeout(() => {
    //     const result = this.formatNotifications.find(
    //       ({ data }) => data.broadcast_id == id,
    //     )
    //     // console.log('verify: ',result)
    //     if (result === undefined) {
    //       this.$store
    //         .dispatch('event/broadcastAfterLogin', {
    //           broadcast_id: id,
    //           title: title,
    //           body: body,
    //           registry_id: this.registry.id,
    //         })
    //         .then(() => {
    //           this.getNotifications()
    //         })
    //     }
    //   }, 3500)
    // },
    // Get Notifications
    openSimpleCalendar() {
      this.event_calendar_modal = true
    },
    openLeaderboard() {
      this.openProfile()
      this.profileTab = TabKeys.points
    },
    openMatchmaking() {
      this.openProfile()
      this.profileTab = TabKeys.matchmaking
    },
    toggleSound() {
      let audio = this.$refs.audio
      if (
        audio.paused
        // document.querySelector('.toggle-sound').classList.contains('paused')
      ) {
        this.isPlaying = true
        console.log('play it')
        audio.play()
        // document.querySelector('.toggle-sound').classList.remove('paused')
      } else {
        this.isPlaying = false
        console.log('pause it')
        audio.pause()
        // document.querySelector('.toggle-sound').classList.add('paused')
      }
    },
    getUsers() {
      if (this.$store.state.event.constants.needs_refershing) {
        this.$store.dispatch('event/getEventUsers').catch((err) => {
          console.log(err)
        })
      }
    },
    getUsersHybrid() {
      if (this.$store.state.event.constants.needs_hybrid_refershing) {
        this.$store.dispatch('event/getEventUsersHybrid')
      }
    },
    contentItemEvent(content_item_element) {
      const {
        target_link_type_id,
        download_item_url,
        doorway_item_room_id,
        content_type: { name },
      } = content_item_element
      if (ContentTypeKeys.download == name) {
        // console.log(target_link_type_id)
        if (TargetLinkKeys.normal.value == target_link_type_id) {
          download(download_item_url, download_item_url.split('/').pop(0))
        }
        if (TargetLinkKeys.target.value == target_link_type_id) {
          this.contentItemModal = true
          this.contentItemData = content_item_element
        }
      }
      if (ContentTypeKeys.doorway == name) {
        const room =
          this.$store.getters['event/getRoomFromConstants'](
            doorway_item_room_id,
          )
        if (room) {
          this.toVirtualRoom(room.slug)
        }
      }
    },
    clickActionEvent(item) {
      console.log('click action triggered')
      const {
        clickable,
        clickable: {
          click_action_type: { name },
        },
      } = item
      // console.log({ item })
      // console.log(item.clickable)
      // console.log(item.clickable.click_action_type.name)

      if (ClickableTypeKeys.link_content === name) {
        // console.log(item.clickable.content_item_element)
        this.contentItemEvent(item.clickable.content_item_element)
      }
      if (ClickableTypeKeys.link_location === name) {
        const room = this.$store.getters['event/getRoomFromConstants'](
          clickable.link_location_virtual_room_id,
        )
        if (room) {
          this.toVirtualRoom(room.slug)
        }
      }
      if (ClickableTypeKeys.open_favorites === name) {
        this.$store.commit('event/setProfile', {
          modal: true,
          tab: TabKeys.saved,
        })
      }
      if (ClickableTypeKeys.open_calendar === name) {
        console.log('calendar')
      }
      if (ClickableTypeKeys.open_external_url === name) {
        openWindowTab(clickable.external_url_link)
      }
      if (ClickableTypeKeys.open_leaderboard === name) {
        this.$store.commit('event/setProfile', {
          modal: true,
          tab: TabKeys.points,
        })
      }
    },
    toggleFullScreen() {
      var doc = window.document
      var docEl = doc.documentElement

      var requestFullScreen =
        docEl.requestFullscreen ||
        docEl.mozRequestFullScreen ||
        docEl.webkitRequestFullScreen ||
        docEl.msRequestFullscreen
      var cancelFullScreen =
        doc.exitFullscreen ||
        doc.mozCancelFullScreen ||
        doc.webkitExitFullscreen ||
        doc.msExitFullscreen

      if (
        !doc.fullscreenElement &&
        !doc.mozFullScreenElement &&
        !doc.webkitFullscreenElement &&
        !doc.msFullscreenElement
      ) {
        requestFullScreen.call(docEl)
      } else {
        cancelFullScreen.call(doc)
      }
    },
    openProfile() {
      this.profileModal = true
    },
    openRep() {
      this.repDashboardModal = true
    },
    openMod() {
      this.modDashboardModal = true
    },
    openModCustomForm() {
      this.modDashboardCustomFormModal = true
    },
    openUserProfile() {
      this.openProfile()
      this.profileTab = TabKeys.profile
    },
    openRepDashboard() {
      this.openRep()
      // this.profileTab = TabKeys.profile
    },
    openContacts() {
      this.openProfile()
      this.profileTab = TabKeys.contacts
    },
    createEventFavorite() {
      // ajax request
      if (this.isRoomFavorite) {
        return
      }
      const payload = {
        virtual_room_id: this.virtual_room.id,
      }
      this.isAddingFavorite = true
      this.$store
        .dispatch('event/createFavorite', payload)
        .catch((err) => {
          this.$store.dispatch('notifications/addNotification', {
            title: 'Error añadiendo favorito',
            body: err,
          })
        })
        .finally(() => {
          this.isAddingFavorite = false
        })
    },
    // generateMetaOg() {
    //   var metas = document.getElementsByTagName('meta')
    //   for (var i = 0; i < metas.length; i++) {
    //      if(metas[i].getAttribute('property')=='og:type')
    //     {
    //         metas[i].setAttribute('content','article')
    //     }
    //     if(metas[i].getAttribute('property')=='og:title')
    //     {
    //         metas[i].setAttribute('content',this.virtual_room.label)
    //     }
    //     if(metas[i].getAttribute('property')=='og:description')
    //     {
    //         metas[i].setAttribute('content',this.virtual_room.description)
    //     }
    //     if(metas[i].getAttribute('property')=='og:image')
    //     {
    //         metas[i].setAttribute('content',this.virtual_room.bg_desktop)
    //     }
    //   }
    // },
    OpenUsersPanel() {
      this.$store.commit('chat/setState', {
        showChat: true,
        view: 'ACTIVE_USERS',
      })
    },
    resetChatWidget() {
      this.$store.commit('chat/setState', {
        showChat: false,
        home_tab: this.isRealtimeEnabled ? 'PRIVATE' : 'SUPPORT',
        view: 'HOME',
      })
    },
    OpenAllUsersPanel() {
      this.OpenUsersPanel()
      this.$store.commit('event/setSocket', {
        filters: [],
        // onlyActive: false,
        // onlyThisLocation: false,
      })
    },
    OpenUsersHybrid() {
      this.OpenUsersPanel()
      this.$store.commit('event/setSocket', {
        filters: [UserListKeys.hybrid],
      })
    },
    OpenActiveUsersPanel() {
      this.OpenUsersPanel()
      this.$store.commit('event/setSocket', {
        filters: [UserListKeys.active],
        // onlyActive: true,
        // onlyThisLocation: false,
      })
    },
    OpenInThisLocationUsersPanel() {
      this.OpenUsersPanel()
      this.$store.commit('event/setSocket', {
        filters: [UserListKeys.location],
        // onlyActive: false,
        // onlyThisLocation: true,
      })
    },
    openChat() {
      // chat interface
    },
    toVirtualRoom(slug) {
      if (this.slug === slug) {
        return
      }
      this.$router.push({
        name: this.is_preview ? 'event-preview.route' : 'event.route',
        params: {
          slug,
          eventSlug: this.registry.slug,
        },
      })
    },
    openUrl(url) {
      console.log('being targeted')
      // console.log(url)
      const win = window.open(url, '_blank')
      if (win) {
        //Browser has allowed it to be opened
        win.focus()
      } else {
        //Browser has blocked it
        alert('Please allow popups for this website')
      }
    },
    onResize() {
      // console.log('window w ', window.innerWidth)
      // console.log('window y ', window.innerHeight)
      this.screenSize = { x: window.innerWidth, y: window.innerHeight }
      // console.log('Event  container resize', { window: this.screenSize })
      this.window_width = window.innerWidth
      this.window_height = window.innerHeight
      if (this.$refs.g_event_container) {
        const { width, height } =
          this.$refs.g_event_container.getBoundingClientRect()

        this.windowSize = { x: width, y: height }
      } else {
        this.windowSize = this.screenSize
      }
      // console.log('X', this.windowSize.x)
      // console.log('Y', this.windowSize.y)
      // if ("ontouchstart" in window || navigator.maxTouchPoints > 0) {
      if (this.windowSize.x > 1000) {
        this.is_desktop = true
        return
      }
      this.is_desktop = false
      if (this.windowSize.x < this.windowSize.y) {
        this.landscape = false
      } else {
        this.landscape = true
      }
    },
    logout() {
      this.$store.commit('session/setLogoutModal', {
        logoutModal: true,
        route: 'registry.thank-you',
      })
    },
  },
}
</script>

<style>
.chip-ticket {
  position: absolute;
  top: 0;
  right: 0;
  background: #d7bd99;
  color: #fff;
  padding: 0 0.5rem;
  border-radius: 1rem;
  font-size: 10px;
  height: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
}
.ipOhDr {
  max-width: initial !important;
}
</style>
