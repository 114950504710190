<template>
  <div>
    <transition name="fade-transition" mode="out-in">
      <v-row v-if="errorAlert">
        <v-col cols="12">
          <v-alert
            v-model="errorAlert"
            dismissible
            color="red"
            border="left"
            elevation="2"
            colored-border
            icon="error"
            transition="fade-transition"
          >
            {{ errorMsg }}</v-alert
          >
        </v-col>
      </v-row>
    </transition>
    <!--       @vdropzone-success="uploadedFileDesktop"
      @vdropzone-sending="sendingEventDesktop"  @vdropzone-error="errorEventMobile"-->

    <!-- <vue-dropzone
      v-if="isAws"
      id="dropzone"
      ref="dropzoneDesktop"
      :awss3="awss3"
      :options="{ ...dropzoneOptions, maxFiles: 1, acceptedFiles: mimeType }"
      :use-custom-slot="true"
      class="tw-border-2 tw-border-gray-300 tw-border-dashed"
      @vdropzone-file-added="fileAdded"
      @vdropzone-drop="drop"
      @vdropzone-drag-over="dragover"
      @vdropzone-drag-leave="dragleave"
      @vdropzone-s3-upload-error="s3UploadError"
      @vdropzone-s3-upload-success="s3UploadSuccess"
    >
      <dropzone-placeholder
        :width="width"
        :height="height"
        :placeholder="placeholder"
      />
    </vue-dropzone> -->
    <AwsDropzone
      v-if="isAws"
      :bypass="bypass"
      :height="height"
      :width="width"
      :placeholder="placeholder"
      :media-group="mediaGroup"
      @on-file-added="fileAddedToAwsInput"
      @on-error="showAwsErrors"
      @on-success="uploadedFiletoAws"
    />
    <vue-dropzone
      v-else
      id="dropzone"
      ref="dropzoneDesktop"
      :options="{ ...dropzoneOptions, maxFiles: 1, acceptedFiles: mimeType }"
      :use-custom-slot="true"
      class="tw-border-2 tw-border-dashed tw-border-gray-300"
      @vdropzone-file-added="fileAdded"
      @vdropzone-drop="drop"
      @vdropzone-drag-over="dragover"
      @vdropzone-drag-leave="dragleave"
      @vdropzone-success="uploadedFileDesktop"
      @vdropzone-sending="sendingEventDesktop"
      @vdropzone-error="errorEventMobile"
    >
      <dropzone-placeholder
        :width="width"
        :height="height"
        :placeholder="placeholder"
      />
    </vue-dropzone>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { basicErrors } from '@/utils/formErrorsHandler'
import vue2Dropzone from 'vue2-dropzone'
import DropzonePlaceholder from '@/components/DropzonePlaceholder.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { byPass } from '@/enums/MediaDropzoneBypass'
import AwsDropzone from './AwsDropzone.vue'
export default {
  name: 'AppDropzone',
  components: {
    DropzonePlaceholder,
    vueDropzone: vue2Dropzone,
    AwsDropzone,
  },
  props: {
    width: {
      type: Number,
      default: 0,
    },

    placeholder: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 0,
    },
    bypass: {
      type: String,
      default: 'simple-check',
      validator(v) {
        return Object.keys(byPass).includes(v)
      },
    },
    mediaGroup: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    errorAlert: false,
    errorMsg: '',
  }),
  computed: {
    /**
     * @returns {string}
     */
    mimeType() {
      return byPass[this.bypass]
    },
    isAws() {
      return true
    },
    ...mapGetters({
      dropzoneOptions: ['media_elements/dz_config'],
      awss3: ['media_elements/awss3'],
    }),
  },
  methods: {
    fileAddedToAwsInput() {
      this.errorAlert = false
      this.errorMsg = ''
    },
    showAwsErrors(msg) {
      this.errorAlert = true
      // this.errorMsg = basicErrors(msg)
      this.errorMsg = msg
    },
    s3UploadError(errorMessage) {
      console.log(errorMessage)
    },
    s3UploadSuccess(s3ObjectLocation) {
      console.log(s3ObjectLocation)
    },
    fileAdded() {
      console.log('check')
      this.errorAlert = false
      this.errorMsg = ''
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('tw-bg-green-100')) {
        event.currentTarget.classList.remove('tw-bg-gray-100')
        event.currentTarget.classList.add('tw-bg-green-100')
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('tw-bg-gray-100')
      event.currentTarget.classList.remove('tw-bg-green-100')
    },
    drop(event) {
      event.preventDefault()
      // this.$refs.file.files = event.dataTransfer.files;
      // this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('tw-bg-gray-100')
      event.currentTarget.classList.remove('tw-bg-green-100')
    },
    errorEventMobile(file, message) {
      this.$refs.dropzoneDesktop.removeFile(file)
      if (typeof message === 'string') {
        this.errorAlert = true
        this.errorMsg = message
        return
      }
      // file.previewElement.querySelectorAll(
      //   ".dz-error-message span"
      // )[0].textContent = basicErrors(message);
      this.errorAlert = true
      this.errorMsg = basicErrors(message)
    },

    sendingEventDesktop(file, xhr, formData) {
      formData.append('media_group', this.mediaGroup)
      formData.append('width', this.width)
      formData.append('height', this.height)
      formData.append('bypass', this.bypass)
      // if (this.bypass) {
      // }
    },
    uploadedFileDesktop(file, res) {
      this.$refs.dropzoneDesktop.removeFile(file)
      console.log('onSuccess', res)
      this.$emit('on-success', res)
      // this.bg_desktop = res.media_element;
      // this.toggle_desktop_dropzone = false;
    },
    uploadedFiletoAws(res) {
      console.log('here')
      this.$emit('on-success', res)
      // this.bg_desktop = res.media_element;
      // this.toggle_desktop_dropzone = false;
    },
  },
}
</script>

<style></style>
