<template>
  <div class="tw-flex tw-h-full tw-flex-1 tw-flex-col tw-overflow-y-hidden">
    <transition name="fade-transition" mode="out-in">
      <div
        v-if="!loading"
        id="messages"
        ref="scrollToMe"
        class="tw-scrolling-touch scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 tw-flex tw-flex-1 tw-flex-col tw-overflow-y-scroll"
      >
        <div
          v-for="(group, index) in sortedMessages"
          :key="index"
          class="relative"
        >
          <div
            class="tw-sticky tw-top-0 tw-z-5 tw-border-t tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-px-4 tw-py-1 tw-text-sm tw-font-medium tw-text-gray-500"
          >
            <h3>{{ index }}</h3>
          </div>
          <div class="tw-space-y-4 tw-p-4">
            <ChatConversationMessage
              v-for="message in group"
              :key="message.id"
              :can-view-profile="true"
              :message="message"
              :user="user"
              @delete-message="deleteMessage"
            />
          </div>
        </div>
      </div>
      <div
        v-else
        class="tw-scrolling-touch scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 tw-flex tw-flex-1 tw-flex-col tw-overflow-y-scroll"
      >
        <v-progress-linear indeterminate color="primary darken-2" />
      </div>
    </transition>
    <div
      class="tw-border-t-2 tw-border-gray-200 tw-px-4 tw-pt-2 tw-pb-2 sm:tw-mb-0"
    >
      <form @submit.prevent="submit">
        <div class="tw-relative tw-flex tw-space-x-2">
          <v-textarea
            v-model="body"
            :disabled="loading"
            auto-grow
            dense
            outlined
            hide-details=""
            rounded
            filled
            class="tw-max-h-64 tw-overflow-y-auto"
            :placeholder="$t('app.msg_placeholder')"
            rows="1"
            @keydown.enter.exact.prevent
            @keyup.enter.exact="submit"
          />
          <div class="tw-items-end sm:tw-flex">
            <button
              v-ripple
              type="submit"
              class="tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-blue-500 tw-text-white tw-transition tw-duration-500 tw-ease-in-out hover:tw-bg-blue-400 focus:tw-outline-none focus:tw-ring focus:tw-ring-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="tw-h-5 tw-w-5 tw-rotate-90 tw-transform"
              >
                <path
                  d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"
                />
              </svg>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { groupBy } from '@/helpers'
import { mapState } from 'vuex'
import {
  CHANNEL_MESSAGES,
  DELETE_CHANNEL_MESSAGE,
  newChannelMessageAndParticipant,
  NEW_CHANNEL_MESSAGE,
  SUB_CHANNEL_MESSAGES,
  SUB_DELETED_MESSAGES,
} from '@/graphql/queries/messageQueries'
import ChatConversationMessage from './ChatConversationMessage'
import { format } from 'date-fns'
export default {
  components: {
    ChatConversationMessage,
  },
  props: {
    parrentChannelId: {
      type: Number,
      default: 0,
    },
  },
  apollo: {
    $subscribe: {
      newMessages: {
        // manual:
        query: SUB_CHANNEL_MESSAGES,
        variables() {
          return {
            chanel_id: this.chanel_id,
          }
        },
        result({ data }) {
          this.messages = [...this.messages, data.newChannelMessage]
          this.$nextTick(() => {
            this.scrollToElement()
          })
        },
      },
      deletedMessages: {
        // manual:
        query: SUB_DELETED_MESSAGES,
        variables() {
          return {
            chanel_id: this.chanel_id,
          }
        },
        result({ data }) {
          console.log('sup')
          console.log({ data })
          this.deleted_msg_ids = [
            ...this.deleted_msg_ids,
            data.deletedChannelMessage.id,
          ]
          // this.$nextTick(() => {
          //   this.scrollToElement()
          // })
        },
      },
    },
  },
  data: () => ({
    loading: true,
    chanel_id: 2,
    thread_id: 0,
    body: '',
    messages: [],
    deleted_msg_ids: [],
    hasSpoken: false,
  }),
  computed: {
    active_channel: {
      get() {
        return this.$store.state.chat.active_channel
      },
      set(value) {
        return this.$store.commit('chat/setState', {
          active_channel: value,
        })
      },
    },
    canThread() {
      return this.parrentThreadId
    },
    showChat: {
      get() {
        return this.$store.state.chat.showChat
      },
      set(value) {
        return this.$store.commit('chat/setState', {
          showChat: value,
        })
      },
    },
    sortedMessages() {
      // [{user: ca,messages: [msg]}]
      const new_group_by_user = groupBy(
        this.messages
          .filter((msg) => !this.deleted_msg_ids.includes(msg.id))
          .map((row_message) => ({
            ...row_message,
            formated_date: format(new Date(row_message.created_at), 'hh:mm a'),
            formated_date_stamp: format(
              new Date(row_message.created_at),
              'yyyy-MM-dd',
            ),
          })),
        'formated_date_stamp',
      )
      Object.keys(new_group_by_user).forEach((key) => {
        new_group_by_user[key] = new_group_by_user[key].reduce(
          (previousValue, currentValue) => {
            const currentMessage = {
              body: currentValue.body,
              id: currentValue.id,
              created_at: currentValue.created_at,
              formated_date: currentValue.formated_date,
            }
            const first = {
              ...currentValue,
              messages: [currentMessage],
            }
            // innitial conditions
            if (previousValue.length === 0) {
              return [...previousValue, first]
            }
            const last_message_in_array =
              previousValue[previousValue.length - 1]
            // check if last message
            if (last_message_in_array.user.id !== currentValue.user.id) {
              return [...previousValue, first]
            }

            previousValue[previousValue.length - 1] = {
              ...last_message_in_array,
              messages: [...last_message_in_array.messages, currentMessage],
            }
            return [...previousValue]
          },
          [],
        )
      })

      // return group_by_user
      return new_group_by_user
    },
    ...mapState({
      user: (state) => state.session.user,
    }),
  },
  watch: {
    showChat: {
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.scrollToElement()
          })
        }
      },
    },
  },
  async mounted() {
    this.init()
  },
  methods: {
    deleteMessage(message) {
      console.log({ message })
      this.$apollo
        .mutate({
          mutation: DELETE_CHANNEL_MESSAGE,
          variables: {
            msg_id: message.id,
          },
        })
        .then((res) => {
          console.log({ res })
        })
        .catch((err) => {
          console.log({ err })
        })
    },
    async init() {
      this.loading = true
      this.chanel_id = this.parrentChannelId
      const { data: data_msgs } = await this.$apollo.query({
        query: CHANNEL_MESSAGES,
        fetchPolicy: 'network-only',
        variables: {
          chanel_id: this.parrentChannelId,
        },
      })
      const userPatcipating = data_msgs.channelMessagesPlusOne.find(
        (row) => row.user.uuid === this.user.uuid,
      )
      this.hasSpoken = !!userPatcipating
      console.log({ userPatcipating })
      this.messages = data_msgs.channelMessagesPlusOne.sort(function (a, b) {
        return parseInt(a.id) - parseInt(b.id)
      })

      this.loading = false

      this.$nextTick().then(() => {
        this.scrollToElement()
      })
    },
    closeModal() {
      this.$store.commit('event/setContentVideo', {
        contentVideoModal: false,
      })
    },
    submit() {
      if (this.body === '') {
        return
      }
      if (this.hasSpoken) {
        this.$apollo.mutate({
          mutation: NEW_CHANNEL_MESSAGE,
          variables: {
            message: this.body,
            chanel_id: this.chanel_id,
          },
        })
      } else {
        this.$apollo.mutate({
          mutation: newChannelMessageAndParticipant,
          variables: {
            message: this.body,
            chanel_id: this.chanel_id,
          },
        })
        this.hasSpoken = true
      }

      this.body = ''
    },
    scrollToElement() {
      const el = this.$refs.scrollToMe
      if (el) {
        el.scrollTop = el.scrollHeight
      }
    },
  },
}
</script>

<style></style>
