<template>
<v-dialog v-model="open" persistent max-width="768px" content-class="tw-rounded-3xl" scrollable>
    <v-form ref="form" v-model="valid" :disabled="form_disabled_state" lazy-validation enctype="multipart/form-data" @submit.prevent="update">
        <v-card class="tw-rounded-3xl tw-border-0 tw-shadow-md">
            <ModalHeader @close-modal="closeModal">{{$t('bz2.landing.countdown_timer.title')}}</ModalHeader>
            <v-divider />
            <v-card-text class="tw-p-0" style="max-height: 700px">
                <v-responsive class="tw-py-0">
                    <v-container class="tw-bg-gray-50">
                        <v-row class="mb-5">
                            <v-col cols="12" class="mx-auto pa-5">
                                <v-card class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-bg-transparent tw-shadow-none">
                                    <v-container class="pa-5">
                                        <div class="tw-grid tw-grid-cols-1">
                                            <div class="">
                                                <v-text-field v-model="mountedSection.data.title" :rules="[rules.required]" validate-on-blur outlined rounded :label="$t('app.title')" />
                                            </div>
                                        </div>
                                    </v-container>
                                    <v-container class="pa-5">
                                        <div>
                                            <v-text-field v-model="mountedSection.data.time_event" type="datetime-local" outlined rounded :label="$t('admin.app.date')" />
                                            <ColorInput v-model="mountedSection.data.color_hex" />
                                            <DropzoneOverlayPreview v-model="mountedSection.data.image_url" :width="1920" :height="300" media-group="registries_social_media" />
                                        </div>
                                    </v-container>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-responsive>
            </v-card-text>
            <v-card-actions>
                <v-alert v-model="errorAlert" dismissible color="red" border="left" elevation="2" colored-border icon="error" transition="fade-transition">
                    {{ errorMsg }}
                </v-alert>
                <v-spacer />
                <AppBtn type="button" :disabled="form_loading" color="transparent" @click="closeModal">
                    {{ $t('app.cancel') }}
                </AppBtn>
                <AppBtn color="primary" :loading="form_loading" icon="check" type="submit">
                    {{ $t('app.save') }}
                </AppBtn>
            </v-card-actions>
        </v-card>
    </v-form>
</v-dialog>
</template>
<script>
import ModalHeader from '@/components/ModalHeader'
import AppBtn from '../App/AppBtn.vue'
import DropzoneOverlayPreview from '../DropzoneOverlayPreview.vue'
import ColorInput from '@/components/FormInputs/ColorInput.vue'
import {
    rules,
} from '@/utils/rules'
export default {
    name: 'BizzDomeUiRegistriesLandingsCountdownTimer',
    components: {
        ModalHeader,
        AppBtn,
        DropzoneOverlayPreview,
        ColorInput,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        section: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        valid: true,
        form_disabled_state: false,
        rules,
        isLoading: false,
        form_loading: false,
        mountedSection: {},
        errorAlert: false,
        errorMsg: '',
    }),
    computed: {
        open: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
    created() {
        this.mountedSection = JSON.parse(JSON.stringify(this.section))
    },
    methods: {
        update() {
            this.errorAlert = false
            if (!this.$refs.form.validate()) {
                return
            }

            this.$emit('updated-section', this.mountedSection)
            this.open = false
        },
        closeModal() {
            this.open = false
        },
    },
}
</script>
