<template>
  <section class="tw-relative">
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="pr-5 pl-5"
      autocomplete="off"
      @submit.prevent="register"
    >
      <div
        v-if="hasFields"
        class="tw-mx-auto tw-max-w-lg tw-space-y-6 tw-px-4 sm:tw-px-6 md:tw-max-w-5xl lg:tw-px-8"
      >
        <v-card class="mx-auto sm:overflow-hidden tw-rounded-3xl tw-shadow-md">
          <v-container class="tw-px-4 tw-py-6 sm:tw-p-6">
            <div>
              <h2
                class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
              >
                {{ $t('pages.registry.register.main_form_title') }}
              </h2>
            </div>
            <div class="tw-mt-6 tw-space-y-2">
              <div v-if="hasBasicInfo" class="tw-grid tw-grid-cols-3">
                <div class="tw-col-span-2">
                  <v-text-field
                    ref="email"
                    v-model="form.email"
                    prepend-inner-icon="alternate_email"
                    :label="$t('forms.email')"
                    type="email"
                    outlined
                    validate-on-blur
                    :rules="[rules.required, rules.email]"
                    rounded
                  />
                </div>
              </div>
              <div v-if="hasPassword" class="tw-grid tw-grid-cols-2 tw-gap-6">
                <div class="">
                  <v-text-field
                    v-model="form.password"
                    :label="$t('forms.password')"
                    :rules="[rules.required]"
                    outlined
                    :append-icon="
                      show_password ? 'visibility_off' : 'visibility'
                    "
                    :type="show_password ? 'text' : 'password'"
                    validate-on-blur
                    rounded
                    @click:append="show_password = !show_password"
                  />
                </div>
                <div>
                  <v-text-field
                    v-model="form.password_confirmation"
                    :label="$t('forms.confirm_password')"
                    :append-icon="
                      show_password_confirmation
                        ? 'visibility_off'
                        : 'visibility'
                    "
                    :type="show_password_confirmation ? 'text' : 'password'"
                    :rules="[rules.required, passwordMatch]"
                    outlined
                    validate-on-blur
                    required
                    rounded
                    @click:append="
                      show_password_confirmation = !show_password_confirmation
                    "
                  />
                </div>
              </div>
              <div v-if="hasBasicInfo" class="tw-grid tw-grid-cols-2 tw-gap-6">
                <div class="">
                  <v-text-field
                    v-model="form.first_name"
                    :label="$t('forms.first_name')"
                    :rules="[rules.required]"
                    type="text"
                    outlined
                    validate-on-blur
                    required
                    rounded
                  />
                </div>
                <div>
                  <v-text-field
                    v-model="form.last_name"
                    :label="$t('forms.last_name')"
                    :rules="[rules.required]"
                    type="text"
                    outlined
                    validate-on-blur
                    required
                    rounded
                  />
                </div>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-gap-x-6 tw-gap-y-2">
                <template v-for="default_field in default_fields">
                  <div v-if="default_field.is_active" :key="default_field.name">
                    <select-countries
                      v-if="default_field.name === 'country_code'"
                      v-model="form[default_field.name]"
                      :rules="[rules.required]"
                      outlined
                      rounded
                      validate-on-blur
                    />
                    <v-text-field
                      v-else
                      v-model="form[default_field.name]"
                      :label="default_field.label"
                      :name="default_field.name"
                      :rules="[rules.required]"
                      type="text"
                      outlined
                      validate-on-blur
                      rounded
                    />
                  </div>
                </template>
              </div>
              <div class="tw-grid tw-grid-cols-12 tw-gap-6">
                <div
                  v-for="custom_field in registry_form_submission"
                  :key="custom_field.uid"
                  class="tw-col-span-12"
                  :class="matchCols[custom_field.cols]"
                >
                  <template v-if="custom_field.field_type == 'input'">
                    <v-text-field
                      v-model="custom_field.model"
                      :label="custom_field.label"
                      :name="custom_field.name"
                      type="text"
                      outlined
                      validate-on-blur
                      required
                      rounded
                    />
                  </template>
                  <template v-if="custom_field.field_type == 'textarea'">
                    <v-textarea
                      v-model="custom_field.model"
                      :label="custom_field.label"
                      :name="custom_field.name"
                      outlined
                      validate-on-blur
                      required
                      rounded
                    />
                  </template>
                  <template v-if="custom_field.field_type == 'radio'">
                    <v-radio-group
                      v-model="custom_field.model"
                      class="tw-mt-0"
                      :label="custom_field.label"
                    >
                      <v-radio
                        v-for="option in custom_field.options"
                        :key="option.uid"
                        :label="option.text"
                        :value="option.value"
                      />
                    </v-radio-group>
                  </template>
                  <template v-if="custom_field.field_type == 'checkbox'">
                    <div>
                      <label
                        for="first_name"
                        class="tw-block tw-text-sm tw-font-medium tw-text-slate-900"
                      >
                        {{ custom_field.label }}
                      </label>
                      <v-checkbox
                        v-for="option in custom_field.options"
                        :key="option.uid"
                        v-model="custom_field.model_multiple"
                        class="tw-mt-0"
                        :label="option.text"
                        :value="option.value"
                      />
                    </div>
                  </template>
                  <template 
                    v-if="
                      custom_field.field_type == 'select' 
                      && custom_field.label != 'Departments' 
                      && custom_field.label != 'Provinces'
                      && custom_field.label != 'Districts'
                    "
                  >
                    <v-select
                      v-model="custom_field.model"
                      :label="custom_field.label"
                      outlined
                      rounded
                      :items="custom_field.options"
                    />
                  </template>
                  <template v-if="custom_field.field_type == 'number'">
                    <v-text-field
                      v-model="custom_field.model"
                      :label="custom_field.label"
                      :name="custom_field.name"
                      outlined
                      rounded
                      :maxlength="custom_field.characters"
                      counter
                      @keypress="onlyNumber"
                    />
                  </template>
                  <template v-if="custom_field.field_type == 'date'">
                    <v-text-field
                      v-model="custom_field.model"
                      :label="custom_field.label"
                      :name="custom_field.name"
                      type="date"
                      outlined
                      rounded
                    />
                  </template>
                  <template v-if="custom_field.field_type == 'select' && custom_field.label == 'Departments'">
                    <DepartmentsAutocomplete 
                      v-model="custom_field.model" 
                      :rules="custom_field.is_required ? [rules.required] : []"
                      @change="listProvinceByDepartments"
                    />
                  </template>
                  <template v-if="custom_field.field_type == 'select' && custom_field.label == 'Provinces'">
                    <ProvincesAutocomplete 
                      v-model="custom_field.model" 
                      :search="itemsProvinces" 
                      :rules="custom_field.is_required ? [rules.required] : []"
                      @change="listDistricByProvinces"
                    />
                  </template>
                  <template v-if="custom_field.field_type == 'select' && custom_field.label == 'Districts'">
                    <DistrictsAutocomplete 
                      v-model="custom_field.model" 
                      :search="itemsDistritcs"
                      :rules="custom_field.is_required ? [rules.required] : []"
                    />
                  </template>
                </div>
              </div>
            </div>
          </v-container>
        </v-card>
        <v-card
          v-if="hasTerms"
          class="mx-auto sm:overflow-hidden tw-rounded-3xl tw-shadow-md"
        >
          <v-container class="tw-px-4 tw-py-6 sm:tw-p-6">
            <div>
              <h2
                class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
              >
                Términos y Condiciones
              </h2>
              <p class="tw-mt-1 tw-mb-0 tw-text-sm tw-text-gray-500">
                Los datos personales que proporcione se utilizarán para
                registrarlo en un evento. Al completar este formulario de
                registro, aceptas el Código de Conducta para AEBE y el
                Consentimiento de recopilación de datos para 6Connex.
              </p>
            </div>
            <div class="tw-mt-6 tw-space-y-2">
              <div class="tw-grid tw-grid-cols-3">
                <div class="tw-col-span-2">
                  <v-checkbox
                    v-model="form.terms"
                    class="tw-mt-0"
                    label="Sí, entiendo que mi información será procesada y compartida con las partes antes mencionadas para procesar mi registro."
                  />
                </div>
              </div>
            </div>
          </v-container>
        </v-card>
        <div class="tw-flex tw-justify-end">
          <AppBtn color="primary" type="submit" icon="check">
            {{ $t('app.register') }}
          </AppBtn>
          <!-- <v-btn
            color="primary"
            type="submit"
            rounded
            class="
              tw-px-8 tw-normal-case
              focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-300
              text--white text--lighten-4
            "
          >
            Registrar
          </v-btn> -->
        </div>

        <v-container>
          <transition name="fade-transition" mode="out-in">
            <v-row v-if="errorAlert">
              <v-col cols="12">
                <v-alert
                  v-model="errorAlert"
                  dismissible
                  color="red"
                  border="left"
                  elevation="2"
                  colored-border
                  icon="error"
                  transition="fade-transition"
                >
                  {{ errorMsg }}</v-alert
                >
              </v-col>
            </v-row>
          </transition>
        </v-container>
      </div>
      <div v-else class="tw-text-center">
        <h3 class="tw-mt-2 tw-text-base tw-font-medium tw-text-gray-900">
          {{ $t('admin.app.event_without_fields') }}
        </h3>
        <p class="tw-mt-1 tw-text-sm tw-text-gray-500">
          {{ $t('admin.app.event_without_fields_reminder') }}
        </p>
        <div class="tw-flex tw-justify-center">
          <v-btn type="submit">Ingresar</v-btn>
        </div>
      </div>
    </v-form>
  </section>
</template>

<script>
import SelectCountries from '@/components/FormInputs/SelectCountries.vue'
import { addModelText, onlyNumber } from '@/utils/Settings'
import DepartmentsAutocomplete from '@/components/DepartmentsAutocomplete'
import ProvincesAutocomplete from '@/components/ProvincesAutocomplete'
import DistrictsAutocomplete from '@/components/DistrictsAutocomplete'

import { rules } from '@/utils/rules'
import { matchCols } from '@/utils/formCols'
import AppBtn from '../App/AppBtn.vue'
// import { select_departments, select_provinces, select_disctricts, formatDataFormSubmission } from '@/utils/Settings'
export default {
  components: {
    SelectCountries,
    AppBtn,
    // Components Depa, Prov, Dist
    DepartmentsAutocomplete,
    ProvincesAutocomplete,
    DistrictsAutocomplete,
  },
  props: {
    hasTerms: {
      type: Boolean,
      default: false,
    },
    currentForm: {
      type: Object,
      default: () => ({}),
    },
    registry: {
      type: Object,
      default: () => ({}),
    },
    hasPassword: {
      type: Boolean,
      default: false,
    },
    hasBasicInfo: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    rules,
    matchCols,
    show_password: false,
    show_password_confirmation: false,
    errorAlert: '',
    errorMsg: '',
    valid: true,
    form: {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      password_confirmation: '',
      company: '',
      title: '',
      country_code: '',
      address: '',
      city: '',
      phone_number: '',
      terms: false,
    },
    registry_form_submission: [],
    default_fields: [],
    // Selects Depa - Prov - Dist
    itemsProvinces: null,
    itemsDistritcs: null,
    onlyNumber,
  }),
  computed: {
    hasFields() {
      return (
        this.default_fields.some((row) => row.is_active) ||
        this.registry_form_submission.some((row) => row.is_active)
      )
    },
  },
  mounted() {
    this.initForm()
    this.form.terms = !this.hasTerms
  },
  methods: {
    // TODO: Search
    listProvinceByDepartments(e) {
      this.itemsProvinces = e
    },
    listDistricByProvinces(e) {
      this.itemsDistritcs = e
    },
    // TODO: End Search
    initForm() {
      const current_form = JSON.parse(JSON.stringify(this.currentForm))
      console.log(current_form)
      this.registry_form_submission = current_form.fields
      // .map((item) => {
      //   return { ...item, model: item.field_type === 'checkbox' ? [] : '' }
      // })

      this.default_fields = current_form.default_fields.filter(
        (row) => row.name !== 'email',
      )
    },
    passwordMatch() {
      return this.form.password === this.form.password_confirmation
        ? true
        : 'Contraseña no es igual'
    },
    register() {
      console.log(this.registry_form_submission, this.form)
      if (!this.$refs.form.validate()) {
        return
      }

      this.errorAlert = false
      const payload = {
        uuid: this.registry.uuid,
        slug: this.registry.slug,
        form_uuid: this.currentForm.uuid,
        form_id: this.currentForm.id,
        body: {
          ...this.form,
          registry_form_submission: addModelText(this.registry_form_submission),
        },
      }
      this.$emit('on-form-submit', payload)
      // console.log(payload)
      // return this.$store
      //   .dispatch('session/registerEvent', payload)
      //   .then(() => {
      //     this.$nextTick(() => {
      //       this.$router.push('/event/home')
      //     })
      //     // return this.$store.dispatch
      //   })
      //   .catch((err) => {
      //     this.errorAlert = true
      //     this.errorMsg = err
      //   })
      //   .finally(() => (this.loading = false))
    },
  },
}
</script>

<style></style>
