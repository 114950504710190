<template>
  <v-btn
    rounded
    v-bind="$attrs"
    :fab="fab"
    :type="isLink ? undefined : type"
    :to="isLink ? to : undefined"
    :active-class="isLink ? 'bizzdome-active-unset' : undefined"
    class="tw-font-bold tw-normal-case tw-transition focus:tw-outline-none focus:tw-ring"
    :class="[colorBtn, baseBtn]"
    v-on="$listeners"
  >
    <!-- <g-icon v-if="icon" class="tw-w-6 tw-h-6 tw-mr-2 tw--ml-1" :name="icon" /> -->
    <g-icon
      v-if="icon"
      :class="[iconClasses, 'tw-mr-2 tw--ml-1']"
      :name="icon"
      :g-next="gNext"
      :md-icon="mdIcon"
    />
    <slot>
      <g-icon
        v-if="iconCenter"
        :class="[iconClasses]"
        :name="iconCenter"
        :g-next="gNext"
        :md-icon="mdIcon"
      />
    </slot>
    <g-icon
      v-if="iconRight"
      :class="[iconClasses, 'tw-ml-2 tw--mr-1']"
      :name="iconRight"
      :g-next="gNext"
      :md-icon="mdIcon"
    />
  </v-btn>
</template>

<script>
const colors = {
  'green-download': 'tw-bg-green-700 tw-text-white focus:tw-ring-green-300',
  white: 'tw-bg-white tw-text-gray-700 focus:tw-ring-primary-300',
  'primary-dark': 'tw-bg-primary-900 tw-text-gray-50 focus:tw-ring-primary-300',
  'primary-light':
    'tw-bg-primary-100 tw-text-primary-700 focus:tw-ring-primary-300',
  'green-light': 'tw-bg-green-50 tw-text-green-700 focus:tw-ring-green-300',
  primary: 'tw-bg-primary-500 tw-text-gray-50 focus:tw-ring-primary-300',
  'transparent-border':
    'tw-border-solid tw-border tw-border-gray-500  tw-bg-transparent tw-text-gray-700 tw-shadow-none focus:tw-ring-gray-300',
  transparent: 'tw-bg-transparent tw-text-gray-700 tw-shadow-none',
  'outlined-transparent':
    'tw-border-solid tw-border tw-border-gray-300 tw-bg-transparent tw-text-gray-700 tw-shadow-none focus:tw-ring-gray-300',
  'transparent-primary':
    'tw-bg-transparent hover:tw-bg-primary-100  tw-text-primary-500  tw-shadow-none focus:tw-ring-gray-50',
  'transparent-danger':
    'tw-bg-transparent hover:tw-bg-red-100 hover:tw-text-red-500 tw-text-gray-700 tw-shadow-none focus:tw-ring-red-300',
  'outlined-danger':
    'tw-border-solid tw-border tw-border-red-500 tw-bg-transparent tw-text-red-500 tw-shadow-none focus:tw-ring-red-300',
  'outlined-danger-dark':
    'tw-border-solid tw-border tw-border-red-500 tw-bg-transparent tw-text-red-500 tw-shadow-none focus:tw-ring-red-300',
  'transparent-dark':
    'tw-bg-transparent tw-text-white  tw-shadow-none focus:tw-ring-gray-50',
  secondary: 'focus:tw-ring-secondary-300  tw-text-gray-50 tw-bg-secondary-500',
}
import GIcon from '@/components/GIcon.vue'

import { isObjectEmpty } from '@/utils/isObjectEmpty'
export default {
  components: {
    GIcon,
  },
  props: {
    color: {
      type: String,
      default: '',
      validator(value) {
        return Object.keys(colors).includes(value)
      },
    },
    type: {
      type: String,
      default: 'button',
      validator(value) {
        return ['button', 'submit'].includes(value)
      },
    },
    icon: {
      type: String,
      default: '',
    },
    iconCenter: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    to: {
      type: Object,
      default: () => ({}),
    },
    platform: {
      type: Boolean,
      default: false,
    },
    fab: {
      type: Boolean,
      default: false,
    },

    mdIcon: {
      type: String,
      default: '',
    },
    gNext: {
      type: String,
      default: '',
    },
  },
  computed: {
    colorBtn() {
      return colors[this.color]
    },
    baseBtn() {
      return this.fab ? '' : this.platform ? 'tw-px-4' : 'tw-px-8'
    },
    isLink() {
      return !isObjectEmpty(this.to)
    },
    iconClasses() {
      if (this.$attrs['x-small'] === '') {
        return 'tw-w-4 tw-h-4'
      }
      if (this.$attrs.small === '') {
        return 'tw-w-5 tw-h-5'
      }
      return 'tw-w-6 tw-h-6'
    },
  },
}
</script>
