import $axios from '@/axios-silent'

export default class NewPasswordService {
    baseUrl = '/api/v1/auth'

    forgotPassword(params) {
        return $axios.post(this.baseUrl + '/forgot-password', params)
    }

    resetPassword(params) {
        return $axios.post(this.baseUrl + '/reset-password', params)
    }
}

export const NewPasswordServiceInstance = new NewPasswordService()