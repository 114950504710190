<template>
  <v-autocomplete
    v-model="input_value"
    :items="rows"
    :search-input.sync="search"
    item-text="text"
    item-value="value"
    v-bind="$attrs"
    autocomplete="false"
    :name="Math.random()"
    :label="processedLabel"
  >
    <template #no-data>
      <v-list-item>
        <v-list-item-title> Buscar paises </v-list-item-title>
      </v-list-item>
    </template>
    <!-- <template v-slot:selection="{ attr, on, item, selected }">
                      <v-chip
                        v-bind="attr"
                        :input-value="selected"
                        color="blue-grey"
                        class="white--text"
                        v-on="on"
                      >
                        <span v-text="item.label"></span>
                      </v-chip>
                    </template> -->
    <template #item="{ item }">
      <!-- <v-list-item-avatar
                        color="indigo"
                        class="headline font-weight-light white--text"
                      >
                        {{ item.label.charAt(0) }}
                      </v-list-item-avatar> -->
      <v-list-item-content>
        <v-list-item-title v-text="item.text" />
        <!-- <v-list-item-subtitle
                          v-text="item.uuid"
                        ></v-list-item-subtitle> -->
      </v-list-item-content>
      <v-list-item-action>
        <!-- <v-icon>mdi-bitcoin</v-icon> -->
      </v-list-item-action>
    </template>
  </v-autocomplete>
</template>

<script>
import { select_countries, select_countries_en } from '@/utils/countries'
const countries = {
  es: select_countries,
  en: select_countries_en,
}
export default {
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    items: [],
    search: null,
    // rows: select_countries,
  }),
  computed: {
    locale() {
      return this.$i18n.locale
    },
    processedLabel() {
      if (this.label) {
        return this.label
      }
      return this.$i18n.t('forms.country')
    },
    rows() {
      return countries[this.locale]
    },
    input_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
