<template>
  <v-dialog
    v-model="open"
    width="640"
    content-class="tw-relative tw-rounded-xl sm:tw-self-auto"
  >
    <v-card class="tw-shadow-md">
      <UserBusinessCard :user="attendee" @close-btn="close" />
    </v-card>
  </v-dialog>
</template>
<script>
import UserBusinessCard from './UserBusinessCard'
import { mapState } from 'vuex'
export default {
  components: {
    UserBusinessCard,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      attendee: (state) => state.event.card.attendee,
    }),
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
      // get() {
      //   return this.$store.state.event.card.modal
      // },
      // set(value) {
      //   console.log({ value })
      //   return this.$store.commit('event/setCard', {
      //     modal: value,
      //   })
      // },
    },
  },
  methods: {
    close(bool) {
      this.open = bool
    },
  },
}
</script>

<style></style>
