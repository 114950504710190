<template>
  <!-- TODO: Departmentst: Select field for the classes -->
  <v-autocomplete
    v-model="input_value"
    :items="items"
    item-text="name"
    item-value="id"
    :label="$t('app.field_type.provinces')"
    outlined
    rounded
    clearable
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { provinces } from '@/utils/Provinces'
export default {
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    search: {
      type: [Number, String],
      default: 0,
    },
  },
  data: () => ({
    provinces,
  }),
  computed: {
    input_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    items() {
      if (this.search) {
        return this.provinces.filter((row) => row.department_id == this.search)
      } else {
        return this.provinces
      }
    },
  },
}
</script>

<style></style>
