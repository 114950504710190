<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    :fullscreen="isMobile"
    :hide-overlay="isMobile"
    max-width="1360px"
    :content-class="
      isMobile
        ? 'tw-rounded-none fill-height tw-w-full print:tw-hidden'
        : 'tw-rounded-xl fill-height tw-w-full tw-max-h-[640px] print:tw-hidden'
    "
  >
    <v-card class="fill-height tw-border-0 tw-shadow-md tw-rounded-t-xl ">
      <!-- ------------------- Tabs Dashboard Mod (Attendees, Register, Aforo) ------------------- -->
      <v-container class="tw-p-0" fluid>
        <div
          class="tw-relative tw-grid tw-grid-cols-6 tw-items-center tw-rounded-none tw-bg-primary-500 tw-pl-5 lg:tw-rounded-t-xl print:tw-hidden"
        >
          <div class="tw-col-span-5 tw-flex tw-items-end">
            <v-tabs
              v-model="tab"
              dark
              background-color="tw-bg-primary-500"
              show-arrows
            >
              <v-tabs-slider color="primary lighten-3" />
              <v-tab
                v-for="item in items"
                :key="item.name"
                :href="`#${item.name}`"
                class="tw-py-3 tw-px-6 tw-text-xs"
              >
                {{ item.label }}
              </v-tab>
            </v-tabs>
          </div>
          <div
            class="tw-flex tw-h-full tw-items-center tw-justify-end tw-space-x-3 tw-px-2"
          >
            <v-fab-transition>
              <v-btn
                fab
                x-small
                class="focus:tw-outline-none focus:tw-ring"
                @click="open = false"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </v-fab-transition>
          </div>
        </div>
      </v-container>
      <v-divider />
      <!-- ------------------- Content Dashboard Mod (Attendees, Register, Aforo) ------------------- -->
      <v-card-text class="fill-height tw-p-0">
        <v-responsive class="fill-height tw-py-0">
          <v-tabs-items v-model="tab" class="fill-height">
            <v-tab-item
              :key="ModTabKeys.attendees"
              :value="ModTabKeys.attendees"
              class="fill-height print:tw-hidden"
            >
              <ModDashboardAttendeesTab ref="attendees" />
            </v-tab-item>
            <v-tab-item
              :key="ModTabKeys.register"
              :value="ModTabKeys.register"
              class="fill-height print:tw-hidden"
            >
              <ModRegister ref="register"/>
            </v-tab-item>
            <v-tab-item
              :key="ModTabKeys.aforo"
              :value="ModTabKeys.aforo"
              class="fill-height print:tw-hidden"
            >
              <ModAforo />
            </v-tab-item>
          </v-tabs-items>
        </v-responsive>
      </v-card-text>
      <v-divider />
      <!-- ------------------- Actions Dash Mod (Attendees, Register) ------------------- -->

      <v-card-actions v-if="tab === ModTabKeys.attendees && isAdmin">
        <v-spacer />
        <!-- ----------- Check-out ----------- -->
        <AppBtn
          v-if="tab === ModTabKeys.attendees && isAdmin"
          color="outlined-danger"
          type="button"
          class="tw-px-5 tw-text-xs"
          @click="checkOut"
        >
          <v-icon left>
            restart_alt
          </v-icon>
          {{ $t('admin.home.labels.check_out') }}
        </AppBtn>
      </v-card-actions>
      <v-card-actions v-if="tab === ModTabKeys.register">
        <v-spacer />
        <!-- ----------- Register ----------- -->
        <AppBtn
          v-if="tab === ModTabKeys.register"
          color="green-download"
          type="button"
          icon="check"
          class="tw-px-5 tw-text-xs"
          @click="registerUser"
        >
          {{ $t('pages.event.mod_dash.register') }}
        </AppBtn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapFields } from '@/helpers'
import ModDashboardAttendeesTab from './ModDashboardAttendeesTab.vue'
import { ModTabKeys } from '@/enums/ModTabKeys'
import { mapState } from 'vuex'
import AppBtn from '@/components/App/AppBtn.vue'
import ModRegister from '@/components/Event/ModRegister.vue'
import ModAforo from '@/components/Event/ModAforo.vue'
export default {
  name: 'ModDashboardModal',
  components: {
    ModDashboardAttendeesTab,
    AppBtn,
    ModRegister,
    ModAforo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    qr_model: false,
    notifications: false,
    sound: true,
    widgets: false,
    ModTabKeys,
  }),

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    isAdmin() {
      return this.user.roles.map((row) => row.name).includes('admin')
    },
    ...mapFields({
      fields: ['tab'],
      base: ['event', 'mod_dashboard'],
      mutation: 'event/setModDashboard',
    }),

    items() {
      return Object.keys(ModTabKeys).map((row) => ({
        name: ModTabKeys[row],
        label: this.$i18n.t(`pages.event.mod_dash.${ModTabKeys[row]}`),
      }))
    },
    ...mapState({
      my_rooms: (state) => state.event.constants.rep_rooms,
      user: (state) => state.session.user,
    }),
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    open(value) {
      if (!value) {
        this.$refs.attendees.cleanUp()
      }
    },
  },
  methods: {
    // Donovann
    checkOut() {
      this.$refs.attendees.checkOut()
    },
    registerUser() {
      this.$refs.register.register()
    },
  },
}
</script>

<style></style>
