<template>
  <div class="chat-message">
    <div
      class="tw-flex tw-items-end"
      :class="[message.user.id === user.id ? 'tw-justify-end' : '']"
    >
      <div
        class="tw-flex tw-max-w-xs tw-flex-col tw-items-start tw-space-y-2 tw-pl-2 tw-pr-2 tw-text-xs"
        :class="[
          message.user.id === user.id
            ? 'tw-order-1 tw-items-end'
            : 'tw-order-2 tw-items-start',
        ]"
      >
        <!-- <transition-group
        name="fade-transition"
        tag="div"
        class="
          tw-flex
          tw-flex-col
          tw-items-start
          tw-max-w-xs
          tw-pl-2
          tw-pr-2
          tw-space-y-2
          tw-text-xs
        "
        :class="[
          message.user.id === user.id
            ? 'tw-items-end tw-order-1'
            : 'tw-items-start tw-order-2',
        ]"
      > -->
        <div
          v-for="(message_row, index) in message.messages"
          :key="message_row.uuid"
          class="tw-group tw-relative tw-flex tw-w-full"
          :class="[
            message.user.id === user.id ? 'tw-justify-end' : 'tw-justify-start',
          ]"
        >
          <div
            class="tw-flex tw-gap-x-2"
            :class="[
              message.user.id === user.id
                ? 'tw-flex-row-reverse'
                : 'tw-flex-row',
            ]"
          >
            <span
              class="tw-relative tw-inline-block tw-w-auto tw-max-w-full tw-rounded-lg tw-px-4 tw-py-2"
              style="
                white-space: break-spaces;
                word-wrap: break-word;
                text-indent: 0;
              "
              :class="[
                message.user.id === user.id
                  ? 'tw-bg-blue-600 tw-text-white'
                  : 'tw-bg-gray-300 tw-text-gray-700',
                message.messages.length - 1 === index &&
                message.user.id === user.id
                  ? 'tw-rounded-br-none'
                  : '',
                message.messages.length - 1 === index &&
                message.user.id !== user.id
                  ? 'tw-rounded-bl-none'
                  : '',
              ]"
              >{{ message_row.body }}
              <div
                style="font-size: 11px; line-height: 15px"
                class="tw-flex tw-text-opacity-75"
                :class="[
                  message.user.id === user.id
                    ? 'tw-justify-end tw-text-gray-200'
                    : 'tw-justify-start tw-text-gray-600',
                ]"
              >
                {{ message_row.formated_date }}
              </div>
            </span>
            <div
              class="tw-opacity-0 tw-transition focus-within:tw-opacity-100 group-hover:tw-opacity-100"
            >
              <v-menu
                v-if="canDelete"
                offset-y
                transition="scale-transition"
                content-class="tw-py-1 tw-mt-2 tw-bg-white tw-rounded-md tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5"
              >
                <template #activator="{ on, attrs }">
                  <button
                    type="button"
                    class="tw-inline-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2"
                    aria-expanded="false"
                    aria-haspopup="true"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span class="tw-sr-only">Open options</span>
                    <svg
                      class="tw-h-5 tw-w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
                      />
                    </svg>
                  </button>
                </template>
                <v-list>
                  <v-list-item @click="deleteMessage(message_row)">
                    <v-list-item-title>{{
                      $t('app.delete')
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
        <!-- </transition-group> -->
      </div>
      <v-tooltip v-if="canViewProfile" bottom>
        <template #activator="{ on, attrs }">
          <button
            v-ripple
            class="tw-h-6 tw-w-6 tw-shrink-0 tw-rounded-full focus:tw-outline-none focus:tw-ring"
            :class="[message.user.id === user.id ? 'tw-order-2' : 'tw-order-1']"
            v-bind="attrs"
            @click="viewAttendee(message.user)"
            v-on="on"
          >
            <img
              :src="message.user.avatar"
              alt="My profile"
              class="tw-h-6 tw-w-6 tw-rounded-full tw-object-cover"
            />
          </button>
        </template>
        <span> {{ message.user.first_name }} {{ message.user.last_name }}</span>
      </v-tooltip>
      <img
        v-else
        :src="message.user.avatar"
        alt="My profile"
        class="tw-h-6 tw-w-6 tw-transform tw-rounded-full tw-object-cover tw-transition-all"
        :class="[message.user.id === user.id ? 'tw-order-2' : 'tw-order-1']"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    canViewProfile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    canDelete() {
      return this.user.roles.map((row) => row.name).includes('admin')
    },
  },
  // mounted() {
  //   console.log(this.message)
  // },

  methods: {
    viewAttendee({ uuid }) {
      console.log(uuid)
      if (this.user.uuid === uuid) {
        return
      }
      this.$store.dispatch('event/openBussinessCard', { uuid })
    },
    deleteMessage(message) {
      this.$emit('delete-message', message)
    },
  },
}
</script>

<style></style>
