<template>
  <v-dialog v-model="open" persistent scrollable content-class="tw-rounded-3xl" max-width="784px">
    <v-form 
      ref="form" v-model="valid" :disabled="form_disabled_state" lazy-validation enctype="multipart/form-data"
      @submit.prevent="update">
      <v-card class="tw-rounded-3xl tw-border-0 tw-shadow-md">
        <ModalHeader @close-modal="closeModal">{{
          $t('app.field')
        }}</ModalHeader>
        <v-divider />
        <v-card-text class="tw-p-0" style="max-height: 700px">
          <v-responsive class="tw-p-0">
            <v-container class="tw-bg-gray-100 tw-px-6 tw-py-8">
              <v-card class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-lg tw-shadow-none">
                <!-- === Label and Select Column === -->
                <div class="lg:tw-flex tw-items-center tw-space-y-4 lg:tw-space-y-0 lg:tw-space-x-4 tw-py-4 tw-px-6 ">
                  <div class="tw-w-full">
                    <v-text-field 
                      ref="new_exam" v-model="mountedFormInput.label" :rules="[rules.testEmpty]"
                      validate-on-blur outlined rounded color="primary darken-1" :label="$t('app.title')" hide-details/>
                  </div>
                  <div>
                    <v-select 
                      v-model="mountedFormInput.cols" :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                      outlined rounded :label="$t('app.width')" hide-details/>
                  </div>
                </div>
                <!-- === Atributos === -->
                <div class="tw-py-4 tw-px-6">
                  <div class="tw-mb-2 lg:tw-mb-0">
                    <h3 class="tw-text-lg tw-font-medium tw-leading-4 tw-text-gray-900">
                      {{ $t('app.attributes') }}
                    </h3>
                    <p class="tw-mt-1 tw-mb-3 tw-text-sm tw-text-gray-500">
                      {{ $t('admin.app.extra_config_description') }}
                    </p>
                  </div>
                  <div class="tw-flex tw-space-x-8">
                    <v-switch v-model="mountedFormInput.is_active" inset :label="$t('app.active')" color="primary" class="tw-m-0" dense hide-details/>
                    <v-switch 
                      v-model="mountedFormInput.is_required" inset :disabled="!mountedFormInput.is_active"
                      :label="$t('app.required')" color="primary" class="tw-m-0" dense hide-details/>
                    <v-switch 
                      v-if="mountedFormInput.field_type == 'select'"
                      v-model="mountedFormInput.is_multiple" 
                      inset 
                      :label="$t('app.is_multiple')" 
                      color="primary" 
                      class="tw-m-0" 
                      dense 
                      hide-details
                    />
                    <v-switch v-model="mountedFormInput.is_visible_table" inset :label="$t('app.on_table')" color="primary" class="tw-m-0" dense hide-details/>
                    <v-switch v-model="mountedFormInput.is_visible_print" inset :label="$t('app.on_print')" color="primary" class="tw-m-0" dense hide-details/>
                  </div>
                </div>
                <!-- === Type Input === -->
                <v-container class="tw-px-5 tw-pt-8 tw-pb-0">
                  <div class="tw-grid tw-w-full tw-grid-cols-3 tw-gap-4">
                    <div class="tw-col-span-2">
                      <v-select 
                        v-model="mountedFormInput.field_type" :items="formatFields" item-text="i18_label"
                        item-value="value" outlined rounded :label="$t('app.type')" />
                    </div>
                    <div v-if="mountedFormInput.field_type == 'number'" class="tw-col-span-1">
                      <v-text-field 
                        v-model="mountedFormInput.characters" :label="$t('app.characters')" type="number"
                        outlined rounded />
                    </div>
                  </div>
                </v-container>
                <!-- === Options (Type input -> List, Checkbox, Radio) === -->
                <v-container v-if="hasOptionsField" class="tw-space-y-6 tw-bg-gray-50 tw-px-5 tw-py-6">
                  <div class="tw-space-y-1">
                    <div 
                      v-for="option in mountedFormInput.options" :key="option.uid"
                      class="tw-flex tw-items-center tw-space-x-4">
                      <div class="tw-flex-1">
                        <v-text-field 
                          ref="new_exam" v-model="option.text" :rules="[rules.testEmpty]" validate-on-blur
                          filled rounded color="primary darken-1" :label="$t('app.option')"
                          hide-details
                          @blur="onBlurOption(option)" 
                        />
                      </div>
                      <div class="tw-mb-7">
                        <v-btn 
                          text fab small class="
                                  tw-bg-transparent tw-font-bold tw-normal-case
                                  focus:tw-outline-none
                                  focus:tw-ring
                                  focus:tw-ring-gray-300
                                " @click="deleteItem(option)">
                          <svg 
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                            class="tw-h-6 tw-w-6">
                            <path 
                              stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                  <div class="tw-flex tw-justify-start">
                    <v-btn 
                      color="white" rounded class="
                              tw-bg-transparent
                              tw-px-8
                              tw-font-bold
                              tw-normal-case
                              tw-shadow
                              focus:tw-outline-none
                              focus:tw-ring
                              focus:tw-ring-gray-300
                            " @click="addOption">
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                        class="tw-mr-2 tw--ml-1 tw-h-6 tw-w-6">
                        <path 
                          stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                      </svg>
                      {{ $t('app.add_option') }}
                    </v-btn>
                  </div>
                </v-container>
              </v-card>
            </v-container>
          </v-responsive>
        </v-card-text>
        
        <v-container v-if="errorAlert">
          <transition name="fade-transition" mode="out-in">
            <v-row v-if="errorAlert">
              <v-col cols="12">
                <v-alert 
                  v-model="errorAlert" dismissible color="red" border="left" elevation="2" colored-border
                  icon="error" transition="fade-transition">
                  {{ errorMsg }}</v-alert>
              </v-col>
            </v-row>
          </transition>
        </v-container>

        <v-card-actions class="tw-py-3">
          <v-spacer />
          <v-btn 
            color="blue darken-1" text rounded :disabled="form_loading" class="
                tw-px-8 tw-normal-case
                focus:tw-outline-none focus:tw-ring focus:tw-ring-white
              " @click="closeModal">
            {{ $t('app.cancel') }}</v-btn>
          <v-btn 
            color="primary darken-1" rounded type="submit" :loading="form_loading" class="
                text--white text--lighten-4
                tw-px-8 tw-normal-case
                focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-300
              ">
            {{ $t('app.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ModalHeader from '@/components/ModalHeader'
import { mapState } from 'vuex'
import { CustomFormOption } from '@/models/CustomForm'
import { slugify } from '@/helpers'

export default {
  components: {
    ModalHeader,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formInput: {
      type: Object,
      default: () => ({}),
    },
    typeForm: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    errorAlert: '',
    errorMsg: '',
    exam: '',
    rules: {
      numericZero: (v) => !!parseFloat(v) || 'Campo no puede ser 0',
      testEmpty: (value) => !!value || 'Campo no puede estar vacio.',
      min: (v) => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => "The email and password you entered don't match",
    },
    isLoading: false,
    form_loading: false,
    form_disabled_state: false,
    valid: true,
    mountedFormInput: {},
    isLoadingRooms: false,
    formatFields: [],
  }),
  computed: {
    hasOptionsField() {
      return ['radio', 'checkbox', 'select'].includes(
        this.mountedFormInput.field_type,
      )
    },
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    ...mapState({
      fields: (state) => state.forms.data.fields,
    }),
  },
  mounted() {
    this.formatFields = this.fields.map((row) => ({
      ...row,
      i18_label: this.$t(`app.field_select.${row.icon}`),
    }))
    this.mountedFormInput = JSON.parse(JSON.stringify(this.formInput))
  },
  methods: {
    deleteItem(row_deleted) {
      console.log(row_deleted)
      this.mountedFormInput.options = this.mountedFormInput.options.filter(
        (row) => row.uid != row_deleted.uid,
      )
    },
    onBlurOption(option) {
      // option
      const updated_option = { ...option, value: slugify(option.text) }
      this.mountedFormInput.options = this.mountedFormInput.options.map((row) =>
        row.uid === option.uid ? updated_option : row,
      )
    },
    addOption() {
      this.mountedFormInput.options = [
        ...this.mountedFormInput.options,
        CustomFormOption({}),
      ]
    },
    closeModal() {
      this.open = false
    },
    update() {
      this.errorAlert = false
      if (!this.$refs.form.validate()) {
        return
      }
      this.mountedFormInput.name = slugify(this.mountedFormInput.label)
      this.$emit('updated-form-input', this.mountedFormInput)
      this.open = false
    },
  },
}
</script>

<style></style>