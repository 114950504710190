<template>
  <div
    class="tw-flex tw-w-full tw-items-center tw-justify-center tw-text-center"
  >
    <!-- class="tw-p-12 tw-bg-gray-100 tw-border tw-border-gray-300" -->
    <div
      class="tw-w-full tw-border-2 tw-border-dashed tw-border-gray-300 tw-transition focus-within:tw-ring hover:tw-bg-gray-200"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        :id="uuid"
        ref="file"
        type="file"
        name="field"
        class="tw-absolute tw-h-px tw-w-px tw-overflow-hidden tw-opacity-0"
        :accept="mimeType"
        @change="onChange"
      />
      <!-- accept=".pdf,.jpg,.jpeg,.png" -->
      <transition name="fade-transition" mode="out-in">
        <label
          v-if="!filelist.length"
          ref="aws-dropzone-placeholder"
          :for="uuid"
          class="tw-block tw-cursor-pointer"
        >
          <!-- <label for="assetsFieldHandle" class="tw-block tw-cursor-pointer">
          <div>
            Explain to our users they can drop files in here or
            <span class="tw-underline">click here</span> to upload their files
          </div>
         -->
          <span class="tw-pointer-events-none">
            <DropzonePlaceholder
              :width="width"
              :height="height"
              :placeholder="placeholder"
            />
          </span>
        </label>
        <ul v-if="filelist.length" ref="aws-dropzone-ul" class="tw-p-4">
          <li
            v-for="(file, index) in filelist"
            :key="index"
            class="tw-flex tw-justify-center tw-p-1 tw-text-sm"
          >
            <div
              class="tw-relative tw-flex tw-h-48 tw-w-48 tw-items-center tw-overflow-hidden tw-rounded-3xl tw-bg-white tw-shadow"
            >
              <div
                class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center"
              >
                <div
                  class="tw-relative tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-px-4"
                >
                  <div
                    v-if="isLoadingToAws"
                    class="tw-w-full tw-overflow-hidden tw-rounded-full tw-bg-gray-200"
                  >
                    <div
                      class="tw-h-2 tw-rounded-full tw-bg-primary-600 tw-transition-all"
                      :style="{ width: uploadProgress + '%' }"
                    />
                  </div>
                  <v-progress-circular
                    v-else
                    :size="70"
                    :width="7"
                    color="primary"
                    indeterminate
                  />
                </div>
              </div>
              <img
                v-if="isImage(file)"
                :src="image_display"
                class="tw-w-h-full tw-w-full tw-object-contain"
              />
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                class="tw-h-full tw-w-full tw-text-gray-100"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <!-- {{ file.name }}
            <button
              class="ml-2"
              type="button"
              title="Remove file"
              @click="remove(filelist.indexOf(file))"
            >
              remove
            </button> -->
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
import { byPass } from '@/enums/MediaDropzoneBypass'
import { slugify } from '@/utils/slugify'
import Vapor from '@/utils/vapor'
import { breakFileIntoParts } from '@/utils/breakFileIntoParts'
import DropzonePlaceholder from '@/components/DropzonePlaceholder.vue'
import { v4 } from '@/helpers'
const isImage = (file) => file['type'].includes('image')
import { greatestCommonDivisor } from '@/utils/greatestCommonDivisor'
const validateRule = (bool, msg) => {
  return {
    isValid: !bool,
    message: bool ? msg : '',
  }
}
export default {
  components: {
    DropzonePlaceholder,
  },
  props: {
    width: {
      type: Number,
      default: 0,
    },

    placeholder: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 0,
    },
    bypass: {
      type: String,
      default: 'simple-check',
      validator(v) {
        return Object.keys(byPass).includes(v)
      },
    },
    mediaGroup: {
      type: String,
      default: 'demo',
    },
  },
  data: () => ({
    uuid: v4(),
    filelist: [], // Store our uploaded files
    image_display: '',
    uploadProgress: 0,
    isLoadingToAws: true,
  }),
  computed: {
    mimeType() {
      return byPass[this.bypass]
    },
  },
  methods: {
    isImage,
    onChange() {
      this.filelist = [...this.$refs.file.files]
      this.$emit('on-file-added')
      const reader = new FileReader()
      const current_file = this.$refs.file.files[0]
      const size = current_file.size
      const filename_parts = breakFileIntoParts(current_file.name)
      if (isImage(current_file)) {
        // this.processImage

        reader.readAsDataURL(current_file)
        reader.onload = (e) => {
          //Initiate the JavaScript Image object.
          const image = new Image()

          //Set the Base64 string return from FileReader as source.
          image.src = e.target.result
          this.image_display = e.target.result
          //Validate the File Height and Width.
          image.onload = () => {
            const { naturalWidth: width, naturalHeight: height } = image

            const { isValid, message } = this.validateImage(width, height)
            if (!isValid) {
              this.throwError(message)

              return
            }

            const filename = `${slugify(
              filename_parts.name,
            )}_n-${width}x${height}.${filename_parts.ext}`

            this.uploadtoAws(current_file, filename, size, filename_parts.ext)
          }
        }
        return
      }
      // this.processFile
      const filename = `${slugify(filename_parts.name)}.${filename_parts.ext}`
      // this.image_display = require('')
      this.uploadtoAws(current_file, filename, size, filename_parts.ext)
    },
    throwError(msg) {
      this.$emit('on-error', msg)
      this.resetInput()
    },
    resetInput() {
      this.uploadProgress = 0
      this.filelist = []
      this.$refs.file.value = ''
    },
    uploadtoAws(file, filename, size, file_ext) {
      this.isLoadingToAws = true
      Vapor.store(file, {
        data: {
          filename,
        },
        headers: {
          Authorization: `Bearer ${this.$store.state.session.token}`,
          'X-Tenant': this.$store.state.tenant,
        },
        progress: (progress) => {
          this.uploadProgress = Math.round(progress * 100)
        },
      })
        .then((response) => {
          console.log(response)
          this.isLoadingToAws = false
          this.$store
            .dispatch('media_elements/commitStorage', {
              key: response.key,
              file_ext,
              media_group: this.mediaGroup,
              filename: response.uuid,
              size,
            })
            .then((res) => {
              this.isLoadingToAws = true
              console.log(res)
              this.$emit('on-success', res)
              this.uploadProgress = 0
              this.filelist = []
              this.$refs.file.value = ''
            })
            .catch((error) => {
              console.log(error)
            })
          // reset steps
        })
        .catch((error) => {
          console.log(error)
          console.log(error.response.data.errors)
        })
    },
    // use some state for the base64 image -> Missing solution for anything else not image related -> Done
    // use some percentage as indicador -> Done
    // at some point parse the width && height to make sure is correct -> Parsing yet not validating yet -> inserting the logic
    // emit back same object -> getting here -> Done
    validateImage(width, height) {
      // if (this.bypass == 'simple-check') {
      //   return validateRule(
      //     this.width !== width || this.height !== height,
      //     'Las dimesiones de la imagen son incorrectas',
      //   )
      // }
      // if (this.bypass == 'ratio-check') {
      //   return validateRule(
      //     greatestCommonDivisor(this.width, this.height).join('x') !==
      //       greatestCommonDivisor(width, height).join('x'),
      //     'Las proporsiones de la imagen son incorrectas',
      //   )
      // }
      // return { isValid: true, message: '' }
      return (
        {
          'simple-check': validateRule(
            this.width !== width || this.height !== height,
            'Las dimesiones de la imagen son incorrectas',
          ),
          'ratio-check': validateRule(
            greatestCommonDivisor(this.width, this.height).join('x') !==
              greatestCommonDivisor(width, height).join('x'),
            'Las proporsiones de la imagen son incorrectas',
          ),
        }[this.bypass] || { isValid: true, message: '' }
      )
    },
    remove(i) {
      this.filelist.splice(i, 1)
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('tw-bg-green-300')) {
        event.currentTarget.classList.remove('tw-bg-gray-100')
        event.currentTarget.classList.add('tw-bg-green-300')
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('tw-bg-gray-100')
      event.currentTarget.classList.remove('tw-bg-green-300')
    },
    drop(event) {
      event.preventDefault()
      if (this.filelist.length) {
        return
      }
      this.$refs.file.files = event.dataTransfer.files
      this.onChange() // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('tw-bg-gray-100')
      event.currentTarget.classList.remove('tw-bg-green-300')
    },
  },
}
</script>

<style></style>
