<template>
  <!-- <transition
    mode="out-in"
    enter-active-class="tw-ease-out tw-duration-150"
    enter-class="tw-opacity-0 "
    enter-to-class="tw-opacity-100 "
    leave-active-class="tw-ease-in tw-duration-100"
    leave-class="tw-opacity-100 "
    leave-to-class="tw-opacity-0 "
  >
    <div
      v-if="global_loading"
      class="tw-fixed tw-bottom-0 tw-inset-x-0 sm:tw-px-4 sm:tw-pb-4 sm:tw-inset-0 sm:tw-flex sm:tw-items-center sm:tw-justify-center tw-z-50"
      key="backdrop"
    >
      <transition
        enter-class="tw-opacity-0 "
        enter-active-class="tw-ease-out tw-duration-300"
        enter-to-class="tw-opacity-100 "
        leave-class="tw-opacity-100 "
        leave-active-class="tw-ease-in tw-duration-200"
        leave-to-class="tw-opacity-0 "
      >
        <div
          v-if="global_loading"
          class="tw-fixed tw-inset-0 tw-transition-opacity tw-bg-filter"
          key="opacity"
        >
          <div
            class="tw-absolute tw-inset-0 tw-bg-gray-800 tw-opacity-75 tw-bg-filter"
          ></div>
        </div>
      </transition>
      <transition
        enter-class="tw-opacity-0 "
        enter-active-class="tw-ease-out tw-duration-300"
        enter-to-class="tw-opacity-100 "
        leave-class="tw-opacity-100 "
        leave-active-class="tw-ease-in tw-duration-200"
        leave-to-class="tw-opacity-0 "
      >
        <div
          class="tw-fixed tw-top-0 tw-right-0 tw-h-screen tw-w-screen tw-z-50 tw-flex tw-justify-center tw-items-center"
          v-if="global_loading"
        > 
          <div class="parent-loader">
            <div class="loader">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition> -->

  <!-- class="tw-test"
    opacity=".75"
    color="#374151" -->
  <!-- <div
    class="tw-fixed tw-inset-0 tw-flex md:tw-items-end md:tw-justify-start tw-p-4 md:tw-p-6 tw-pointer-events-none tw-z-50"
  > -->
  <v-overlay :value="global_loading" :z-index="500">
    <v-progress-circular indeterminate size="64" />
  </v-overlay>
  <!-- </div> -->
</template>

<script>
export default {
  computed: {
    global_loading() {
      return this.$store.state.appIsLoading
    },
  },
}
</script>

<style></style>
