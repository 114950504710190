import { HelperService } from '@/services/HelperService'
import { formErrorsHandler } from '@/utils/formErrorsHandler'

// state
const initState = () => {
  return {
    value: '',
  }
}

// getters
const getters = {
  value: (state) => {
    return state.value
  },
}

// actions
const actions = {
  previewEventEmail(_, { body = {} }) {
    return HelperService.previewEventEmail(body)
      .then((res) => {
        console.log(res)
        return res
      })
      .catch((err) => {
        return formErrorsHandler(err)
      })
  },
  dispatchEmail(_, { body = {} }) {
    return HelperService.dispatchEmail(body)
      .then((res) => {
        console.log(res)
        return res
      })
      .catch((err) => {
        return formErrorsHandler(err)
      })
  },
}

// mutations
const mutations = {
  setValue(state, value) {
    state.value = value
  },
}

export const Emails = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
}
