<template>
  <renderless-layout :full-screen="true">
    <div
      class="
        -my-2
        tw-overflow-x-auto tw-relative tw-h-full tw-overflow-hidden
        print:tw-h-auto print:tw-overflow-auto
      "
    >
      <nav
        class="
          tw-fixed
          tw-w-full
          tw-top-0
          tw-z-10
          tw-flex-initial
          tw-bg-primary-500
          tw-shadow-md
          lg:tw-px-8 lg:tw-py-2
          print:tw-hidden
        "
      >
        <div
          class="
            tw-container
            tw-mx-auto
            tw-flex
            tw-items-center
            tw-justify-between
            tw-space-x-10
            tw-overflow-x-auto
            tw-p-2
            lg:tw-p-0
            bz-scroll
          "
        >
          <AppBtn
            :to="{ name: 'admin.registries.participants' }"
            color="white"
            icon="g-next"
            g-next="ArrowNarrowLeft"
          >
            {{ $t('app.back') }}
          </AppBtn>
          <div class="tw-flex tw-items">
            <v-select
              v-model="rowsPerPage"
              dense
              hide-details=""
              :label="$t('admin.app.certificates.per_page')"
              :items="itemsPerPage"
              rounded
              outlined
              dark
              class="tw-mr-2"
              style="width: 160px"
            />
            <v-select
              v-model="lang_key"
              dense
              hide-details=""
              :label="$t('app.language')"
              :items="languages"
              rounded
              outlined
              dark
              class="tw-mr-2"
              style="width: 200px"
            />
            <AppBtn
              color="primary-light"
              type="button"
              icon="collection"
              class="tw-mr-2"
              @click="getUsers"
            >
              <!-- {{ $t('admin.enums.download') }} -->
              Filter
            </AppBtn>
            <AppBtn
              color="green-light"
              type="button"
              icon="download_cloud"
              @click="print"
            >
              {{ $t('admin.enums.download') }}
            </AppBtn>
          </div>
        </div>
      </nav>
      <!-- Certificate Card -->
      <div
        class="
          tw-h-full
          tw-w-full
          tw-overflow-x-auto
          tw-overflow-y-scroll
          tw-pt-20
          tw-pb-12
          print:tw-overflow-auto print:tw-p-0
          tw-space-y-2
          print:tw-space-y-0
          bz-scroll
        "
      >
        <!-- Pagination -->
        <v-pagination
          v-if="users.length !== 0"
          v-model="pagination.current_page"
          :length="pagination.last_page"
          :total-visible="7"
          class="print:tw-hidden"
          @input="onPageChange"
        />
        <!-- End Pagination -->
        <!-- Certificate -->
        <template v-if="loadingCertificate">
          <section
            class="
              pageCertificate
              tw-relative
              tw-mx-auto
              tw-flex
              tw-items-center
              tw-justify-center
              tw-flex-col
              tw-bg-white
              tw-text-sm
              tw-text-primary-500
            "
            :style="styleCertificate"
          >
            {{ $t('admin.app.certificates.loading') }}
            <v-progress-linear
              color="primary"
              indeterminate
              rounded
              class="tw-mt-2"
              height="4"
              style="width: 50%"
            />
          </section>
        </template>
        <template v-else>
          <template v-if="users.length == 0">
            <section
              class="
                pageCertificate
                tw-relative
                tw-mx-auto
                tw-flex
                tw-items-center
                tw-justify-center
                tw-flex-col
                tw-bg-white
                tw-text-sm
                tw-text-primary-500
              "
              :style="styleCertificate"
            >
              {{ $t('admin.app.certificates.filter') }}
            </section>
          </template>
          <template v-else>
            <section
              v-for="user in usersAttendees"
              :key="user.id"
              class="
                pageCertificate
                tw-relative tw-mx-auto tw-flex tw-flex-col tw-bg-white
              "
              :style="styleCertificate"
            >
              <CertificateCard
                :config="config"
                :user-form="userForm"
                :checkin-user="user"
              />
            </section>
          </template>
        </template>
        <!-- End Certificate -->
      </div>
    </div>
  </renderless-layout>
</template>

<script>
import AppBtn from '@/components/App/AppBtn.vue'
import { mapGetters, mapState } from 'vuex'
import { CertificateHydrate } from '@/models/Certificates'
import CertificateCard from '@/components/Event/CertificateCard.vue'

export default {
  components: {
    AppBtn,
    CertificateCard,
  },
  data: () => ({
    config: {},
    lang_key: 'es',
    loadingCertificate: false,
    users: [],
    userForm: {},
    pagination: {},
    rowsPerPage: 15,
    itemsPerPage: [15, 30, 60, 80, 100, 200],
  }),
  head() {
    return {
      style: [
        {
          type: 'text/css',
          inner: this.config.custom_css,
        },
      ],
    }
  },
  computed: {
    ...mapState({
      registry: (state) => state.registries.show.data,
    }),
    ...mapGetters('constants', ['languages']),
    styleCertificate() {
      return {
        '--width': `${this.config.width}px`,
        '--height': `${this.config.height}px`,
      }
    },
    usersAttendees() {
      return this.users.map((row) => ({
        ...row,
        attendees: row.attendees.find(
          (row) => row.language.bame === this.lang_key,
        ),
      }))
    },
  },
  mounted() {
    this.init()
    this.$emit('updateHead')
  },
  methods: {
    init() {
      this.config = CertificateHydrate(this.registry.certificate)
      this.userForm = JSON.parse(
        JSON.stringify(
          this.registry.certificate ? this.registry.certificate.user_form : {},
        ),
      )
    },
    getUsers() {
      this.loadingCertificate = true
      // New Query
      this.$store
        .dispatch('registries/certificates', {
          params: {
            page: this.pagination.current_page,
            rowsPerPage: this.rowsPerPage,
            registry_id: this.registry.id,
            filter_type: this.lang_key,
          },
        })
        .then(({ data: { users } }) => {
          this.users = users.data
          this.pagination = users
        })
        .finally(() => {
          this.loadingCertificate = false
        })
    },
    onPageChange() {
      this.getUsers()
    },
    addElementStylePagePrint() {
      var style = document.createElement('style')
      style.innerHTML = `
                @page {
                    size: ${this.config.width}px ${this.config.height}px;
                }
            `
      document.head.appendChild(style)
    },
    print() {
      this.addElementStylePagePrint()
      window.print()
    },
  },
}
</script>

<style scoped lang="scss">
.bz-scroll {
  &::-webkit-scrollbar {
    width: 2px;
    height: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid #ffffff; /* creates padding around scroll thumb */
  }
}
.pageCertificate {
  width: var(--width);
  height: var(--height);
}
@media print {
  .pageCertificate {
    border-radius: 0;
    --tw-shadow: 0 0 transparent;
    -webkit-print-color-adjust: exact !important;
  }
}
</style>