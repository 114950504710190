<template>
<div class="tw-my-1">
    <div class="tw-relative bz-hover">
        <v-list-item 
            class="tw-px-3 tw-py-1 bz-cursor " 
            :class="[ntf.read_at == null ? 'bz-read' : '']" 
            @click="action(ntf)"
        >
            <!-- icon -->
            <v-list-item-avatar>
                <template v-if="ntf.data.body_label == NotificationsTypeKeys.event_broadcast">
                    <v-icon class="tw-bg-bizzdome-light-brown tw-text-white" dark>
                        {{ ntf.data.icon }}
                    </v-icon>
                </template>
                <template v-else>
                    <img :src="ntf.avatar" />
                </template>
            </v-list-item-avatar>
            <!-- Body -->
            <v-list-item-content>
                <div class="bz-ntf-card-content">
                    <div class="bz-title-time">
                        <span class="bz-title">
                            {{ntf.data.title}}
                        </span>
                    </div>
                    <span class="bz-description" v-html="ntf.data.body == '' ? $t(`app.notify.descriptions.${ntf.data.body_label}`) : ntf.data.body"/>
                </div>
                <span class="bz-time">
                    {{ntf.date}}
                </span>
            </v-list-item-content>
        </v-list-item>
        <div class="bz-actions">
            <v-btn 
                v-if="!added && ntf.data.body_label != NotificationsTypeKeys.event_broadcast"
                fab small class="mr-2 tw-bg-white tw-shadow-lg" 
                @click="addContact(ntf)"
            >
                <v-icon class="tw-text-green-600 bz-icon">person_add</v-icon>
            </v-btn>
            <v-btn 
                fab small class="tw-bg-white tw-shadow-lg" 
                @click="deleteNtf(ntf.notification_id)"
            >
                <v-icon class="tw-text-red-600 bz-icon">delete_outline</v-icon>
            </v-btn>
        </div>
    </div>
    <v-divider />
    <!-- <pre class="tw-text-xs">
        {{notification}}
    </pre> -->
</div>
</template>

<script>
import { ref, computed, getCurrentInstance } from '@vue/composition-api'
import { NotificationsTypeKeys } from '@/enums/NotificationsType'
export default {
    props: {
        ntf: {
            type: Object,
            default: () => ({}),
        },
    },
    setup(props, { emit }) {
        const vm = getCurrentInstance().proxy
        const loading = ref(false)
        // Actions
        function action(ntf) {
            if (ntf.data.action_type == 'chat') {
                this.openChat(ntf)
                return
            }
            if (ntf.data.action_type == 'contact') {
                this.viewAttendee(ntf)
                return
            }
            if (ntf.data.action_type == 'broadcast') {
                this.readNtf(ntf.notification_id)
                emit('active-broadcast', ntf)
                return
            }
        }
        // Open Chat
        function openChat(ntf) {
            this.$store.commit('chat/setState', {
                showChat: true,
                view: 'HOME',
            })
            this.$nextTick().then(() => {
                this.$store.commit('chat/setState', {
                    showChat: true,
                    view: 'CHAT',
                    talking_to: ntf,
                })
            })
            this.deleteNtf(ntf.notification_id)
        }
        // View Contact
        function viewAttendee(ntf) {
            this.$store.commit('event/setCard', {
                attendee: ntf,
                modal: true,
            })
            this.readNtf(ntf.notification_id)
        }
        // Marcar como leido
        function readNtf(id) {
            loading.value = true
            vm.$store.dispatch('event/updateMark', {
                id: id,
            }).finally(() => {
                loading.value = false
            })
        }
        // Eliminar Notificacion
        function deleteNtf(id) {
            loading.value = true
            vm.$store.dispatch('event/deleteNotification', {
                id: id,
            }).finally(() => {
                loading.value = false
            })
        }
        // Verificar lista de contactos
        const userContactList = computed(() => {
            return vm.$store.state.event.constants.event_connections.map(
                (row) => row.event_user_id,
            )
        })
        // Comprobar si el usuario esta en la lista de contactos
        const added = computed(() => {
            return userContactList.value.includes(props.ntf.data.user_id)
        })
        // Añadir Contacto
        function addContact(ntf) {
            loading.value = true
            vm.$store.dispatch('event/createEventConnection', {
                    event_user_id: ntf.data.user_id,
                })
                .catch((err) => {
                    vm.$store.dispatch('notifications/addNotification', {
                        title: 'Error añadiendo contacto',
                        body: err,
                    })
                })
                .finally(() => (loading.value = false))
        }

        return {
            action,
            openChat,
            viewAttendee,
            readNtf,
            added,
            loading,
            NotificationsTypeKeys,
            deleteNtf,
            addContact,
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
