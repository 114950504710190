import { ContentLibraryBase } from '@/models/ContentLibrary'
import { ContentLibraryElementBase } from '@/models/ContentLibraryElement'
import { ContentLibrariesService } from '@/services/ContentLibrariesService'
import { ContentLibraryElementsService } from '@/services/ContentLibraryElementsService'
import { ContentLibraryFoldersService } from '@/services/ContentLibraryFoldersService'
import { ContentLibraryTagsService } from '@/services/ContentLibraryTagsService'
import { SearchAutocompleteService } from '@/services/SearchAutocompleteService'
import { formErrorsHandler } from '@/utils/formErrorsHandler'

// state
const initState = () => {
  return {
    create: {
      hasErrors: false,
      errors: {},
      data: ContentLibraryBase({}),
    },
    show: {
      isLoading: true,
      data: { ...ContentLibraryBase({}), tags: [], folders: [] },
    },
    update: {
      data: ContentLibraryBase({}),
    },
    element: {
      create: {
        data: ContentLibraryElementBase({}),
      },
      update: {
        data: ContentLibraryElementBase({}),
      },
      show: {
        isLoading: true,
        isBeingCopied: false,

        data: {},
      },
    },
  }
}

// getters
const getters = {}

// actions
const actions = {
  index(_, payload) {
    return ContentLibrariesService.make()
      .index(payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  store({ state, commit }) {
    // const roles = state.create.data.roles
    const form = {
      ...state.create.data,
    }
    commit('setAppIsLoading', true, { root: true })
    return ContentLibrariesService.make()
      .store(form)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },

  show({ commit }, { uuid, loading = false, appLoading = false }) {
    if (loading) {
      commit('setShow', { isLoading: true })
    }
    if (appLoading) {
      commit('setAppIsLoading', true, { root: true })
    }
    return ContentLibrariesService.make()
      .show(uuid)
      .then((res) => {
        commit('setShow', { data: res.data.data })
        // dispatch('initUpdateForm')
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setShow', { isLoading: false })
        commit('setAppIsLoading', false, { root: true })
      })
  },
  elementShow(
    { commit },
    { uuid, element, loading = false, appLoading = false },
  ) {
    if (loading) {
      commit('setElementShow', { isLoading: true })
    }
    if (appLoading) {
      commit('setAppIsLoading', true, { root: true })
    }
    return ContentLibraryElementsService.make()
      .show(uuid, element)
      .then(({ data }) => {
        return commit('setElementShow', { data: data.element })
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setElementShow', { isLoading: false })
        if (appLoading) {
          commit('setAppIsLoading', false, { root: true })
        }
      })
  },
  updateOrCreateTag({ commit, state }, { uuid, body = {} }) {
    if (!body.id) {
      return ContentLibraryTagsService.make()
        .store(uuid, body)
        .then(({ data }) => {
          console.log(data)
          commit('setShow', { data: { ...state.show.data, tags: data.tags } })
          return data
        })
        .catch((err) => {
          throw formErrorsHandler(err)
        })
    }
    return ContentLibraryTagsService.make()
      .update(uuid, body.id, body)
      .then(({ data }) => {
        console.log(data)

        commit('setShow', { data: { ...state.show.data, tags: data.tags } })
        return data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  updateOrCreateFolder({ commit, state }, { uuid, body = {} }) {
    if (!body.id) {
      return ContentLibraryFoldersService.make()
        .store(uuid, body)
        .then(({ data }) => {
          console.log(data)
          commit('setShow', {
            data: { ...state.show.data, folders: data.folders },
          })
          return data
        })
        .catch((err) => {
          throw formErrorsHandler(err)
        })
    }
    return ContentLibraryFoldersService.make()
      .update(uuid, body.id, body)
      .then(({ data }) => {
        console.log(data)

        commit('setShow', {
          data: { ...state.show.data, folders: data.folders },
        })
        return data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },

  loadContentLibrariesTags({ commit, state }, { uuid }) {
    // commit('setConstantLoading', { tags: true })
    return SearchAutocompleteService.contentLibrariesTags(uuid)
      .then(({ data: { tags } }) => {
        // commit('setRunTimeConstant', { tags: tags })

        commit('setShow', { data: { ...state.show.data, tags } })
        return tags
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
    // .finally(() => {
    //   commit('setConstantLoading', { tags: false })
    // })
  },
  storeElement({ commit, dispatch }, { uuid, body = {} }) {
    commit('setAppIsLoading', true, { root: true })
    return ContentLibraryElementsService.make()
      .store(uuid, body)
      .then((res) => {
        dispatch('loadContentLibrariesTags', { uuid })
        dispatch('constants/loadContentElements', null, { root: true })
        return res
      })
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  updateElement({ commit, dispatch }, { uuid, element, body = {} }) {
    commit('setAppIsLoading', true, { root: true })
    return ContentLibraryElementsService.make()
      .update(uuid, element, body)
      .then((res) => {
        dispatch('loadContentLibrariesTags', { uuid })
        dispatch('constants/loadContentElements', null, { root: true })
        return res
      })
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
}

// mutations
const mutations = {
  cleanCreate(state) {
    state.create.data = ContentLibraryBase({})
  },
  setShow(state, obj) {
    Object.keys(obj).forEach((k) => (state.show[k] = obj[k]))
  },
  setCreate(state, obj) {
    Object.keys(obj).forEach((k) => (state.create.data[k] = obj[k]))
  },
  setUpdate(state, obj) {
    Object.keys(obj).forEach((k) => (state.update.data[k] = obj[k]))
  },
  // element - content item

  cleanElementCreate(state) {
    state.element.create.data = ContentLibraryElementBase({})
  },
  setElementShow(state, obj) {
    Object.keys(obj).forEach((k) => (state.element.show[k] = obj[k]))
  },
  setElementCreate(state, obj) {
    Object.keys(obj).forEach((k) => (state.element.create.data[k] = obj[k]))
  },
  setElementUpdate(state, obj) {
    Object.keys(obj).forEach((k) => (state.element.update.data[k] = obj[k]))
  },
}

export const ContentLibraries = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
}
