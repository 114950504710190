<template>
  <renderless-layout>
    <v-container fluid class="tw-p-0">
      <StickyHeader :nav="nav">
        <template #actions>
          <div class="tw-flex tw-items-center tw-space-x-4">
            <!-- TODO: Added RegistryTicket -->
            <!-- <AppBtn
              v-if="registry.is_multi_ticket"
              icon="cog"
              color="transparent-dark"
              :to="{
                name: 'admin.registries.tickets',
                params: { id: registry.id },
              }"
            >
              {{ $t('admin.ticket.items.labels.config') }}
            </AppBtn> -->
            <LanguageSelect
              v-model="form.lang_key"
              :label="$t('admin.app.lang_version')"
              dark
            />
            <AppBtn
              icon="check"
              color="white"
              type="button"
              @click="updateEvent"
            >
              {{ $t('app.save') }}
            </AppBtn>
          </div>
        </template>
      </StickyHeader>
      <v-row class="tw-m-0 tw-p-0">
        <v-col cols="12" sm="12" md="12" lg="12" xl="10" class="mx-auto">
          <v-container fluid class="sm:py-3 tw-container tw-mx-auto">
            <v-form
              ref="form"
              v-model="valid"
              :disabled="form_disabled_state"
              lazy-validation
              enctype="multipart/form-data"
              @submit.prevent="submitEvent"
            >
              <!-- colors -->
              <v-row class="mb-5">
                <v-col cols="12" md="3" class="pt-5 mx-auto tw-px-0 sm:tw-px-5">
                  <form-description>
                    <template #title>
                      {{ $t('admin.events.platform_branding_title') }}
                    </template>
                    <template #copy>
                      {{ $t('admin.events.platform_branding_description') }}
                    </template>
                  </form-description>
                </v-col>
                <v-col cols="12" md="9" class="mx-auto tw-px-0 sm:tw-px-5">
                  <v-card
                    class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-shadow-md"
                  >
                    <v-container class="tw-p-5">
                      <v-row>
                        <v-col>
                          <DropzoneOverlayPreview
                            v-model="form[form.lang_key].header_url"
                            :width="200"
                            :bypass="'no-check'"
                            :height="50"
                            media-group="config"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container class="tw-p-5">
                      <v-row>
                        <v-col cols="12" sm="12" class="pb-0">
                          <label class="tw-block tw-pb-2 tw-text-gray-600">
                            {{ $t('admin.events.ui_primary_color') }}
                          </label>
                          <div>
                            <ColorInput
                              v-model="form[form.lang_key].ui_color"
                            />
                          </div>
                        </v-col>

                        <v-col cols="12" sm="12" class="pb-0">
                          <label class="tw-block tw-pb-2 tw-text-gray-600">
                            {{ $t('admin.events.header_color') }}
                          </label>
                          <div>
                            <ColorInput
                              v-model="form[form.lang_key].header_color"
                            />
                          </div>
                        </v-col>
                        <v-col cols="12" sm="12" class="pb-0">
                          <label class="tw-block tw-pb-2 tw-text-gray-600">
                            {{ $t('admin.events.footer_color') }}
                          </label>
                          <div>
                            <ColorInput
                              v-model="form[form.lang_key].footer_color"
                            />
                          </div>
                        </v-col>
                        <!-- <v-col cols="12" sm="12" class="pb-0">
                          <label class="tw-block tw-pb-2 tw-text-gray-600">
                            {{ $t('admin.events.ui_contrast_color') }}
                          </label>
                          <div>
                            <ColorInput
                              v-model="form[form.lang_key].titles_color"
                            />
                          </div>
                        </v-col> -->
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
              <!-- meta data, Analytics, adicional scripts, -->
              <!-- nav and landing -->
              <v-row class="mb-5">
                <v-col cols="12" md="3" class="pt-5 mx-auto tw-px-0 sm:tw-px-5">
                  <div
                    class="tw-flex tw-flex-col tw-items-center tw-justify-between sm:tw-flex-row sm:tw-py-8"
                  >
                    <div>
                      <h3
                        class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        {{ $t('admin.app.navigation') }}
                      </h3>
                      <div
                        class="tw-mb-2 tw-h-1 tw-w-8 tw-rounded-full tw-bg-primary-200"
                      />
                      <span
                        class="tw-font-medium tw-leading-6 tw-text-gray-500"
                      >
                        {{ $t('admin.app.navigation_config') }}
                      </span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="9" class="mx-auto tw-px-0 sm:tw-px-5">
                  <v-card
                    class="tw-mb-6 tw-overflow-hidden tw-rounded-3xl tw-border-0 tw-shadow-md"
                  >
                    <v-container
                      class="tw-bg-gradient-to-r tw-from-primary-500 tw-to-primary-900 tw-p-0"
                      fluid
                    >
                      <div class="tw-px-4 tw-py-5 sm:tw-px-6">
                        <h2
                          class="tw-title-font tw-mb-2 tw-text-xl tw-font-medium tw-text-gray-50 sm:tw-text-2xl"
                        >
                          {{ $t('admin.app.nav_tittle') }}
                        </h2>
                        <p
                          class="tw-mt-1 tw-max-w-md tw-text-sm tw-text-gray-100"
                        >
                          {{ $t('admin.app.nav_description') }}
                        </p>
                        <div class="tw-flex tw-justify-start tw-space-x-3">
                          <AppBtn
                            color="white"
                            :to="{
                              name: 'admin.registries.navs.lang',
                              params: {
                                lang: form.lang_key,
                                id: id,
                              },
                            }"
                          >
                            {{ $t('admin.app.edit_nav') }}
                          </AppBtn>
                        </div>
                      </div>
                    </v-container>
                  </v-card>
                  <v-card class="mx-auto tw-rounded-3xl tw-shadow-md">
                    <v-container class="tw-p-5">
                      <v-row>
                        <v-col cols="12" sm="7" class="pb-0">
                          <VirtualRoomAutocomplete
                            v-model="form[form.lang_key].virtual_room_start_id"
                            :label="$t('admin.app.initial_room')"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="mb-5">
                <v-col cols="12" class="tw-py-0">
                  <div class="tw-h-px tw-border" />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </renderless-layout>
</template>

<script>
import AppBtn from '@/components/App/AppBtn.vue'
import ColorInput from '@/components/FormInputs/ColorInput.vue'
import DropzoneOverlayPreview from '@/components/DropzoneOverlayPreview.vue'
import FormDescription from '@/components/FormDescription.vue'
import StickyHeader from '@/components/StickyHeader.vue'
import { mapState } from 'vuex'

import VirtualRoomAutocomplete from '@/components/VirtualRoomAutocomplete.vue'
import { rules } from '@/utils/rules'
import { EventConfigDB, EventConfigForm } from '@/models/EventConfig'
import LanguageSelect from '@/components/LanguageSelect.vue'

export default {
  components: {
    AppBtn,
    DropzoneOverlayPreview,
    FormDescription,
    StickyHeader,
    VirtualRoomAutocomplete,
    ColorInput,
    LanguageSelect,
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data: () => ({
    form_disabled_state: false,
    valid: true,
    rules,
    form: {
      lang_key: 'es',
      es: EventConfigForm(),
      en: EventConfigForm(),
    },
  }),
  computed: {
    nav() {
      return [
        {
          name: this.$t('app.event'),
          route: { name: 'admin.registries.index' },
        },
        {
          name: this.registry.label,
          route: {
            name: 'admin.registries.show.home',
            params: { id: this.registry.id },
          },
        },
        {
          name: this.$t('admin.events.items.labels.platform'),
          route: {},
        },
      ]
    },
    ...mapState({
      registry: (state) => state.registries.show.data,
    }),
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.form = this.registry.configs.reduce(
        (acc, { language, ...obj }) => {
          return {
            ...acc,
            [language.name]: EventConfigDB(obj),
          }
        },
        { ...this.form },
      )
    },
    updateEvent() {
      this.$store
        .dispatch('registries/updateConfigs', { payload: this.form })
        .then(() =>
          this.$store.dispatch('registries/show', {
            id: this.registry.id,
            appLoading: true,
          }),
        )
        .then(() => {
          this.init()
        })
    },
  },
}
</script>

<style></style>
