<template>
    <div class="tw-py-2">
        <!-- Avatars -->
        <div class="tw-flex tw-items-center tw-justify-center tw-space-x-4 lg:tw-space-x-6 tw-pt-12">
            <!-- Second -->
            <div v-if="users[1]">
                <div class="bz-size-st-bg tw-bg-[#00B2FF]">
                    <v-avatar color="primary" class="bz-size-st">
                        <img :src="users[1].profile_photo_url"/>
                    </v-avatar>
                    <!-- Number -->
                    <div class="bz-position bz-postion-size-st tw-bg-[#00B2FF]">2</div>
                </div>
                <div class="tw-py-6 tw-flex tw-flex-col tw-items-center">
                    <span class="tw-text-xs tw-font-bold bz-txt-name">{{ users[1].fullName }}</span>
                    <span class="tw-text-xs tw-text-gray-300">{{ users[1].total }} pts.</span>
                </div>
            </div>
            <!-- First -->
            <div v-if="users[0]">
                <div class="bz-size-f-bg tw-bg-[#FEC929]">
                    <img src="@/assets/win-c.png" class="bz-win-cr"/>
                    <v-avatar color="primary" class="bz-size-f">
                        <img :src="users[0].profile_photo_url"/>
                    </v-avatar>
                    <!-- Number -->
                    <div class="bz-position bz-postion-size-f tw-bg-[#FEC929]">1</div>
                </div>
                <div class="tw-py-6 tw-flex tw-flex-col tw-items-center">
                    <span class="tw-text-xs tw-font-bold bz-txt-name">{{ users[0].fullName }}</span>
                    <span class="tw-text-xs tw-text-gray-300">{{ users[0].total }} pts.</span>
                </div>
            </div>
            <!-- Third -->
            <div v-if="users[2]">
                <div class="bz-size-st-bg tw-bg-[#FFA800]">
                    <v-avatar color="primary" class="bz-size-st">
                        <img :src="users[2].profile_photo_url"/>
                    </v-avatar>
                    <!-- Number -->
                    <div class="bz-position bz-postion-size-st tw-bg-[#FFA800]">3</div>
                </div>
                <div class="tw-py-6 tw-flex tw-flex-col tw-items-center">
                    <span class="tw-text-xs tw-font-bold bz-txt-name">{{ users[2].fullName }}</span>
                    <span class="tw-text-xs tw-text-gray-300">{{ users[2].total }} pts.</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        users: {
            type: [Array, Object],
            default: () => ({}),
        },
    },
}
</script>

<style>
.bz-txt-name {
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
}
.bz-size-f-bg {
    width: 110px !important;
    height: 110px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    position: relative;
}

.bz-size-f {
    width: 100px !important;
    height: 100px !important;
}

.bz-size-st-bg {
    width: 80px !important;
    height: 80px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    position: relative;
    margin: auto;
}

.bz-size-st {
    width: 70px !important;
    height: 70px !important;
}

.bz-position {
    position: absolute;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid #ffffff;
    color: #ffffff;
    font-weight: bold;
}

.bz-postion-size-st {
    width: 25px;
    height: 25px;
    bottom: -10px;
    font-size: 10px;
}

.bz-postion-size-f {
    width: 35px;
    height: 35px;
    bottom: -15px;
    font-size: 14px;
}

.bz-win-cr {
    position: absolute;
    z-index: 1;
    width: 90px;
    top: -40px;
    right: -10px;
    transform: rotate(16deg);
}
</style>