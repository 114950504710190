<template>
  <renderless-layout>
    <v-container fluid class="tw-p-0">
      <sticky-header :nav="nav">
        <template #actions>
          <div class="tw-flex tw-items-center tw-space-x-3">
            <LanguageSelect
              v-model="current_config_language_key"
              :label="$t('admin.app.lang_version')"
              dark
            />
          </div>
        </template>
      </sticky-header>
      <v-row class="tw-m-0 tw-p-0">
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="11"
          xl="9"
          class="mx-auto tw-max-w-5xl"
        >
          <v-container fluid class="tw-space-y-6">
            <v-row class="">
              <v-col
                v-if="emails.length > 0"
                cols="12"
                class="px-5 pt-5 mx-auto"
              >
                <div
                  class="tw-overflow-hidden tw-bg-white tw-shadow sm:tw-rounded-3xl"
                >
                  <ul
                    role="list"
                    class="tw-divide-y tw-divide-gray-200 tw-pl-0"
                  >
                    <li v-for="email in emails" :key="email.id">
                      <button
                        v-ripple
                        class="tw-block tw-w-full hover:tw-bg-gray-50"
                        @click="updateEmail(email)"
                      >
                        <div
                          class="tw-flex tw-items-center tw-px-4 tw-py-4 sm:tw-px-6"
                        >
                          <div
                            class="tw-min-w-0 tw-flex-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between"
                          >
                            <div class="tw-truncate">
                              <div class="tw-flex tw-text-sm">
                                <p
                                  class="tw-mb-0 tw-truncate tw-font-medium tw-text-indigo-600"
                                >
                                  {{ email.email.subject }}
                                </p>
                                <p
                                  class="tw-ml-1 tw-mb-0 tw-flex-shrink-0 tw-font-normal tw-text-gray-500"
                                >
                                  {{ $t('app.type') }}
                                  {{
                                    registryEmailMap[email.registry_email_type]
                                  }}
                                </p>
                              </div>
                              <div class="tw-mt-2 tw-flex">
                                <div
                                  class="tw-flex tw-items-center tw-text-sm tw-text-gray-500"
                                >
                                  <!-- Heroicon name: solid/calendar -->
                                  <svg
                                    class="tw-mr-1.5 tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  <p class="tw-mb-0">
                                    {{ $t('app.updated_at') }}
                                    <time :datetime="email.datetime">
                                      {{ email.date }}
                                    </time>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="tw-ml-5 tw-flex-shrink-0">
                            <!-- Heroicon name: solid/chevron-right -->
                            <svg
                              class="tw-h-5 tw-w-5 tw-text-gray-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </div>
                        </div>
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="tw-mt-6 tw-flex tw-justify-end">
                  <AppBtn
                    color="white"
                    icon="plus"
                    type="button"
                    :disabled="hasMissingConfigs"
                    @click="addEmailConfig"
                  >
                    {{ $t('admin.app.create_email') }}
                  </AppBtn>
                </div>
              </v-col>
              <v-col v-else>
                <div class="container px-5 py-24 flex flex-wrap tw-mx-auto">
                  <div class="flex flex-wrap -m-4">
                    <div class="tw-mx-auto tw-p-4 md:tw-w-full lg:tw-w-1/2">
                      <div
                        class="tw-rounded-lg tw-bg-white tw-p-8 tw-text-center tw-shadow-md"
                      >
                        <GIcon
                          name="email"
                          class="tw-mx-auto tw-h-12 tw-w-12 tw-text-gray-400"
                        />
                        <h3
                          class="tw-mt-2 tw-text-sm tw-font-medium tw-text-gray-900"
                        >
                          {{ $t('admin.app.no_emails_config') }}
                        </h3>
                        <p class="tw-mt-1 tw-text-sm tw-text-gray-500">
                          {{ $t('admin.app.no_emails_config_description') }}
                        </p>
                        <div class="tw-mt-6">
                          <AppBtn
                            color="primary-dark"
                            icon="plus"
                            type="button"
                            @click="addEmailConfig"
                          >
                            {{ $t('admin.app.create_email') }}
                          </AppBtn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <RegistriesEmailModal
        v-if="email_modal"
        v-model="email_modal"
        :registry-email="selectedEmail"
        :lang="current_config_language_key"
        :valid-types="validTypes"
      />
    </v-container>
  </renderless-layout>
</template>

<script>
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import RegistriesEmailModal from '@/components/RegistriesModals/RegistriesCreateOrUpdateEmailModal.vue'
import LanguageSelect from '@/components/LanguageSelect'
import AppBtn from '@/components/App/AppBtn.vue'
import StickyHeader from '@/components/StickyHeader'
import { mapState, mapGetters } from 'vuex'
import { mapFields } from '@/helpers'
import GIcon from '@/components/GIcon.vue'
import { RegistryEmailBase } from '@/models/RegistryEmail'
const registryEmailTypes = [
  {
    text: 'Confirmación de registro',
    value: 'confirm_event',
  },
  {
    text: 'Otra Confirmación',
    value: 'confirm_other_form',
  },
  // {
  //   text: 'Confirmación de registro',
  //   value: 'confirm_other',
  // },
]
export default {
  components: {
    RegistriesEmailModal,
    AppBtn,
    LanguageSelect,
    StickyHeader,
    GIcon,
  },
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
  },
  data: () => ({
    email_modal: false,
    selectedEmail: {},
    validTypes: [],
  }),
  computed: {
    registryEmailMap() {
      return registryEmailTypes.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.value]: this.$t(`admin.app.${cur.value}`),
        }),
        {},
      )
    },
    hasMissingConfigs() {
      const types_in_use = this.emails.map((row) => row.registry_email_type)
      return !(
        registryEmailTypes.filter((row) => !types_in_use.includes(row.value))
          .length > 0
      )
    },
    nav() {
      return [
        {
          name: this.$t('app.event'),
          route: { name: 'admin.registries.index' },
        },
        {
          name: this.show.label,
          route: {
            name: 'admin.registries.show.home',
            params: { id: this.show.id },
          },
        },
        {
          name: 'Emails',
          route: {},
        },
      ]
    },
    ...mapFields({
      fields: ['current_config_language_key'],
      base: ['registries', 'landings', 'update', 'data'],
      mutation: 'registries/setLandingsUpdateWrapper',
    }),
    ...mapGetters({
      languages: ['constants/languages'],
    }),
    emails() {
      return this.currentEmails
        .filter((row) => row.language.name == this.current_config_language_key)
        .map((row) => ({
          ...row,

          date: format(new Date(row.updated_at), "hh:ii, do 'de' MMMM yyyy", {
            locale: es,
          }),
          datetime: format(new Date(row.updated_at), 'yyyy-MM-dd'),
        }))
    },
    ...mapState({
      show: (state) => state.registries.show.data,
      currentEmails: (state) => state.registries.show.data.emails,
    }),
  },
  methods: {
    addEmailConfig() {
      const types_in_use = this.emails.map((row) => row.registry_email_type)
      this.validTypes = registryEmailTypes.filter(
        (row) => !types_in_use.includes(row.value),
      )
      this.selectedEmail = RegistryEmailBase({})
      this.email_modal = true
    },
    updateEmail(email) {
      const types_in_use = email.registry_email_type
      this.validTypes = registryEmailTypes.filter(
        (row) => types_in_use == row.value,
      )
      this.selectedEmail = RegistryEmailBase(email)
      this.email_modal = true
    },
    updateForms() {
      this.$store.dispatch('registries/updateLoginScreens').then(() =>
        this.$store.dispatch('registries/show', {
          id: this.id,
          appLoading: true,
        }),
      )
    },
  },
}
</script>

<style></style>
