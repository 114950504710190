import { render, staticRenderFns } from "./RegistriesCertificatePrint.vue?vue&type=template&id=0602941a&scoped=true&"
import script from "./RegistriesCertificatePrint.vue?vue&type=script&lang=js&"
export * from "./RegistriesCertificatePrint.vue?vue&type=script&lang=js&"
import style0 from "./RegistriesCertificatePrint.vue?vue&type=style&index=0&id=0602941a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0602941a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VPagination,VProgressLinear,VSelect})
