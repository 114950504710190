<template>
  <v-autocomplete
    ref="tz_selected"
    v-model="input_value"
    v-bind="$attrs"
    validate-on-blur
    type="text"
    rounded
    :items="tz_availables"
    :label="$t('admin.app.tz')"
    outlined
    v-on="inputListeners"
  />
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    value: {
      type: String,
      default: 'America/Lima',
    },
  },
  computed: {
    input_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },

    inputListeners: function () {
      // let vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          // input: function (event) {
          //   vm.$emit("input", event.target.value);
          // },
        },
      )
    },
    ...mapGetters({
      tz_availables: ['constants/tz'],
    }),
  },
}
</script>

<style></style>
