import $axios from '@/axios-instance.js'

class HelperServiceClass {
  baseUrl = '/api/v1/admin/helpers'

  previewEventEmail(body, params) {
    return $axios.post(`${this.baseUrl}/preview-email`, body, params)
  }
  dispatchEmail(body, params) {
    return $axios.post(`${this.baseUrl}/dispatch-email`, body, params)
  }
  exportRegistryAttendees(registry, body, params) {
    return $axios.post(
      `${this.baseUrl}/export-registry-attendees/${registry}`,
      body,
      { ...params, responseType: 'blob' },
    )
  }
  importRegistryAttendees(registry, body, params) {
    return $axios.post(
      `${this.baseUrl}/import-registry-attendees/${registry}`,
      body,
      params,
    )
  }
  exportRegistryAttendeesTemplate(registry, body, params) {
    return $axios.post(
      `${this.baseUrl}/export-registry-attendees-template/${registry}`,
      body,
      { ...params, responseType: 'blob' },
    )
  }
  exportRegistryLeaderboard(registry, body, params) {
    return $axios.post(
      `${this.baseUrl}/export-registry-leaderboard/${registry}`,
      body,
      { ...params, responseType: 'blob' },
    )
  }
  exportRegistryCheckins(registry, body, params) {
    return $axios.post(
      `${this.baseUrl}/export-registry-checkins/${registry}`,
      body,
      { ...params, responseType: 'blob' },
    )
  }
  exportRegistryCheckinsRoom(registry, body, params) {
    return $axios.post(
      `${this.baseUrl}/export-registry-checkins-room/${registry}`,
      body,
      { ...params, responseType: 'blob' },
    )
  }
  metricsDashboard(params) {
    return $axios.get(`${this.baseUrl}/core-vitals-metrics`, params)
  }
}
export const HelperService = new HelperServiceClass()
