import { HelperService } from '@/services/HelperService'
import { MetricsService } from '@/services/MetricsService'
import { ActionMetricsService } from '@/services/ActionMetricsService'
import { myTz } from '@/utils/myTz'
import { ExportableForm } from '@/models/ExportableForm'

// state
const initState = () => {
  return {
    show: {
      hasDataLoaded: false,
      isLoading: true,
      data: { user: {} },
    },
    form: {
      tz: myTz(),
      filter_registry: 'all',
      filter_lang: 'all',
      filter_range: [
        new Date(new Date().setDate(new Date().getDate() - 0))
          .toISOString()
          .substr(0, 10),
        new Date().toISOString().substr(0, 10),
      ],
      filter_date: new Date(new Date().setDate(new Date().getDate() - 0))
        .toISOString()
        .substr(0, 10),
    },
    content_elements: {
      form: {
        direction: 'bar',
        per: 'hour',
      },
    },
    concurrent: {
      form: {
        direction: 'bar',
        per: 'hour',
      },
    },
    // Donovann: Checkins
    checkins: {
      form: {
        direction: 'bar',
        per: 'hour',
      },
    },
    logins: {
      form: {
        direction: 'bar',
        per: 'hour',
      },
    },
    registrations: {
      form: {
        direction: 'bar',
        per: 'hour',
      },
    },
    channels: {
      form: {
        direction: 'bar',
        per: 'hour',
      },
    },
    messages: {
      form: {
        direction: 'bar',
        per: 'hour',
      },
    },
    data: {
      active_current: 0,
      participant_count: 0,
      logins_count: 0,
      // room
      room_report: [],
      room_report_total: 0,
      // devices
      devices: {
        desktop: { label: 'Desktop', browsers: {}, total: 0 },
        tablet: { label: 'Tablet', browsers: {}, total: 0 },
        phone: { label: 'Movil', browsers: {}, total: 0 },
      },
      devices_total: 0,
      // sessions
      avg_sessions: {
        600: { label: 'menos de 10 minutos', count: 0 },
        1200: { label: '10 minutos a 20 minutos', count: 0 },
        1800: { label: '20 minutos a 30 minutos', count: 0 },
        2400: { label: '30 minutos a 40 minutos', count: 0 },
        3000: { label: '40 minutos a 50 minutos', count: 0 },
        3600: { label: '50 minutos a una hora', count: 0 },
        4200: { label: 'Una hora a 1 hora y 10 minutos', count: 0 },
        4800: { label: '1 hora y 10 minutos a 1 hora y 20 minutos', count: 0 },
        5400: { label: '1 hora y 20 minutos a 1 hora y 30 minutos', count: 0 },
        6000: { label: '1 hora y 30 minutos a 1 hora y 40 minutos', count: 0 },
        6600: { label: '1 hora y 40 minutos a 1 hora y 50 minutos', count: 0 },
        7200: { label: '1 hora y 50 minutos a 2 horas', count: 0 },
        7201: { label: 'mas de 2 horas', count: 0 },
      },
      sessions_total: 0,
      // contires
      countries: [],
      countries_total: 0,
      // libraries
      content_libraries: [],
      content_libraries_total: 0,
      // concurrent
      concurrent: [],
      // Donovann: Checkins
      checkins: [],
      checkins_report_total: [],
      // logins
      logins: [],
      // registrations
      registrations: [],
      // channels
      channels: [],
      // messages
      messages: [],
      // screens
      screens: [],
      screens_total: 0,
    },
    widget_state: {
      dashboard_numbers_loading: false,
      window_sizes_loading: false,
      registrations_loading: false,
      // Donovann: Checkins
      checkins_loading: false,
      logins_loading: false,
      concurrency_loading: false,
      sessions_loading: false,
      devices_loading: false,
      countries_loading: false,
      rooms_loading: false,
      channels_loading: false,
      content_libraries_loading: false,
      messages_loading: false,
    },
    exportable: {
      modal: false,
      form: ExportableForm({
        // rooms,devices,sessions, countries, messages, screens
        type: 'rooms',
        virtual_room: '',
        per: '',
        concurrency_range: '',
      }),
    },
  }
}

// getters
const getters = {}

// actions
const actions = {
  getMetrics({ commit, dispatch }) {
    commit('setShow', { hasDataLoaded: true })
    return Promise.all([
      dispatch('dashboard'),
      dispatch('getRooms'),
      dispatch('getDevices'),
      dispatch('getSessions'),
      dispatch('getCountries'),
      dispatch('getContentLibraries'),
      dispatch('getConcurrency'),
      dispatch('getCheckins'),
      dispatch('getLogins'),
      dispatch('getRegistrations'),
      dispatch('getChannels'),
      dispatch('getMessages'),
      dispatch('getWindowSizes'),
    ]).finally(() => {
      commit('setShow', { hasDataLoaded: true })
    })
  },
  index(_, payload) {
    return MetricsService.index(payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  getRealTime(_, payload) {
    return ActionMetricsService.realTime(payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  dashboard({ commit, state }, payload = {}) {
    commit('setWidgetState', { dashboard_numbers_loading: true })
    return HelperService.metricsDashboard({
      params: {
        ...state.form,
        ...payload,
      },
    })
      .then(({ data }) => {
        commit('setData', {
          active_current: data.active_users,
          participant_count: data.participant_count,
          logins_count: data.logins_count,
        })
        return data
      })
      .catch((err) => {
        throw err
      })
  },
  getRooms({ commit, state }, payload = {}) {
    commit('setWidgetState', { rooms_loading: true })
    return ActionMetricsService.rooms({
      params: {
        ...state.form,
        ...payload,
      },
    })
      .then(({ data }) => {
        commit('setData', {
          room_report: data.visits,
          room_report_total: data.report_total,
        })
        return data
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        commit('setWidgetState', { rooms_loading: false })
      })
  },
  getDevices({ commit, state }, payload = {}) {
    commit('setWidgetState', { devices_loading: true })
    return ActionMetricsService.devices({
      params: {
        ...state.form,
        ...payload,
      },
    })
      .then(({ data }) => {
        let devices = {
          desktop: { label: 'Desktop', browsers: {}, total: 0 },
          tablet: { label: 'Tablet', browsers: {}, total: 0 },
          phone: { label: 'Movil', browsers: {}, total: 0 },
        }
        data.agents.forEach((row) => {
          devices[row.agent.device_type].total =
            devices[row.agent.device_type].total + row.count
          if (devices[row.agent.device_type].browsers[row.agent.browser]) {
            devices[row.agent.device_type].browsers[row.agent.browser] =
              devices[row.agent.device_type].browsers[row.agent.browser] +
              row.count
          } else {
            devices[row.agent.device_type].browsers[row.agent.browser] =
              row.count
          }
        })
        commit('setData', {
          devices,
          devices_total: data.devices_total,
        })
        return data
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        commit('setWidgetState', { devices_loading: false })
      })
  },
  getSessions({ commit, state }, payload = {}) {
    commit('setWidgetState', { sessions_loading: true })
    return ActionMetricsService.sessions({
      params: {
        ...state.form,
        ...payload,
      },
    })
      .then(({ data }) => {
        let avg_sessions = {
          600: { label: 'menos de 10 minutos', count: 0 },
          1200: { label: '10 minutos a 20 minutos', count: 0 },
          1800: { label: '20 minutos a 30 minutos', count: 0 },
          2400: { label: '30 minutos a 40 minutos', count: 0 },
          3000: { label: '40 minutos a 50 minutos', count: 0 },
          3600: { label: '50 minutos a una hora', count: 0 },
          4200: { label: 'Una hora a 1 hora y 10 minutos', count: 0 },
          4800: {
            label: '1 hora y 10 minutos a 1 hora y 20 minutos',
            count: 0,
          },
          5400: {
            label: '1 hora y 20 minutos a 1 hora y 30 minutos',
            count: 0,
          },
          6000: {
            label: '1 hora y 30 minutos a 1 hora y 40 minutos',
            count: 0,
          },
          6600: {
            label: '1 hora y 40 minutos a 1 hora y 50 minutos',
            count: 0,
          },
          7200: { label: '1 hora y 50 minutos a 2 horas', count: 0 },
          7201: { label: 'mas de 2 horas', count: 0 },
        }
        data.avg_sessions.forEach((row) => {
          // Array(12)
          if (row.total < 600) {
            avg_sessions['600'].count++
            return
          }
          if (row.total < 1200) {
            avg_sessions['1200'].count++
            return
          }
          if (row.total < 1800) {
            avg_sessions['1800'].count++
            return
          }
          if (row.total < 2400) {
            avg_sessions['2400'].count++
            return
          }
          if (row.total < 3000) {
            avg_sessions['3000'].count++
            return
          }
          if (row.total < 3000) {
            avg_sessions['3000'].count++
            return
          }
          if (row.total < 3600) {
            avg_sessions['3600'].count++
            return
          }
          if (row.total < 4200) {
            avg_sessions['4200'].count++
            return
          }
          if (row.total < 4800) {
            avg_sessions['4800'].count++
            return
          }
          if (row.total < 5400) {
            avg_sessions['5400'].count++
            return
          }
          if (row.total < 6000) {
            avg_sessions['6000'].count++
            return
          }
          if (row.total < 6600) {
            avg_sessions['6600'].count++
            return
          }
          if (row.total < 7200) {
            avg_sessions['7200'].count++
            return
          }
          avg_sessions['7201'].count++
        })

        const sessions_total = Object.keys(avg_sessions).reduce(
          (acc, cur) => acc + avg_sessions[cur].count,
          0,
        )
        commit('setData', {
          avg_sessions,
          sessions_total,
        })
        return data
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        commit('setWidgetState', { sessions_loading: false })
      })
  },
  getCountries({ commit, state }, payload = {}) {
    commit('setWidgetState', { countries_loading: true })
    return ActionMetricsService.countries({
      params: {
        ...state.form,
        ...payload,
      },
    })
      .then(({ data: { countries, countries_total }, ...data }) => {
        commit('setData', {
          countries,
          countries_total,
        })
        return data
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        commit('setWidgetState', { countries_loading: false })
      })
  },

  getContentLibraries({ commit, state }, payload = {}) {
    commit('setWidgetState', { content_libraries_loading: true })
    return ActionMetricsService.contentLibraries({
      params: {
        ...state.form,
        ...payload,
      },
    })
      .then(
        ({ data: { content_libraries, content_libraries_total }, ...data }) => {
          commit('setData', {
            content_libraries,
            content_libraries_total,
          })
          return data
        },
      )
      .catch((err) => {
        throw err
      })
      .finally(() => {
        commit('setWidgetState', { content_libraries_loading: false })
      })
  },
  getConcurrency({ commit, state }, payload = {}) {
    commit('setWidgetState', { concurrency_loading: true })
    return ActionMetricsService.concurrency({
      params: {
        ...state.form,
        ...state.concurrent.form,
        ...payload,
      },
    })
      .then(({ data: { concurrent }, ...data }) => {
        commit('setData', {
          concurrent,
        })
        return data
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        commit('setWidgetState', { concurrency_loading: false })
      })
  },
  // Donovann
  getCheckins({ commit, state }, payload = {}) {
    commit('setWidgetState', { checkins_loading: true })
    return ActionMetricsService.checkins({
      params: {
        ...state.form,
        ...state.checkins.form,
        ...payload,
      },
    })
      .then(({ data }) => {
        // commit('setData', {
        //   checkins,
        // })
        commit('setData', {
          checkins: data.checkins,
          checkins_report_total: data.report_total,
        })
        return data
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        commit('setWidgetState', { checkins_loading: false })
      })
  },
  // End Donovann
  getLogins({ commit, state }, payload = {}) {
    commit('setWidgetState', { logins_loading: true })
    return ActionMetricsService.logins({
      params: {
        ...state.form,
        ...state.logins.form,
        ...payload,
      },
    })
      .then(({ data: { logins }, ...data }) => {
        commit('setData', {
          logins,
        })
        return data
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        commit('setWidgetState', { logins_loading: false })
      })
  },
  getRegistrations({ commit, state }, payload = {}) {
    commit('setWidgetState', { registrations_loading: true })
    return ActionMetricsService.registrations({
      params: {
        ...state.form,
        ...state.registrations.form,
        ...payload,
      },
    })
      .then(({ data: { registrations }, ...data }) => {
        commit('setData', {
          registrations,
        })
        return data
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        commit('setWidgetState', { registrations_loading: false })
      })
  },
  getChannels({ commit, state }, payload = {}) {
    commit('setWidgetState', { channels_loading: true })
    return ActionMetricsService.channels({
      params: {
        ...state.form,
        ...state.channels.form,
        ...payload,
      },
    })
      .then(({ data: { channels }, ...data }) => {
        commit('setData', {
          channels: channels.reverse(),
        })
        return data
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        commit('setWidgetState', { channels_loading: false })
      })
  },
  getMessages({ commit, state }, payload = {}) {
    commit('setWidgetState', { messages_loading: true })
    return ActionMetricsService.messages({
      params: {
        ...state.form,
        ...state.messages.form,
        ...payload,
      },
    })
      .then(({ data: { messages }, ...data }) => {
        commit('setData', {
          messages: messages.reverse(),
        })
        return data
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        commit('setWidgetState', { messages_loading: false })
      })
  },
  getWindowSizes({ commit, state }, payload = {}) {
    commit('setWidgetState', { window_sizes_loading: true })
    return ActionMetricsService.window_sizes({
      params: {
        ...state.form,
        ...payload,
      },
    })
      .then(({ data: { window_sizes, window_sizes_total }, ...data }) => {
        commit('setData', {
          screens: window_sizes,
          screens_total: window_sizes_total,
          // screens: Object.keys(window_sizes).reduce((acc, cur) => {
          //   return { ...acc, [cur]: window_sizes[cur].length }
          // }, {}),
        })
        return data
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        commit('setWidgetState', { window_sizes_loading: false })
      })
  },
  exportRoomUsers(_, payload) {
    return ActionMetricsService.roomsExport(payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  exportData(_, payload) {
    return ActionMetricsService.dataExport(payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  initExportForm({ commit }, payload) {
    commit('setExportable', { modal: true, form: { ...payload } })
  },
}

// mutations
const mutations = {
  setShow(state, obj) {
    Object.keys(obj).forEach((k) => (state.show[k] = obj[k]))
  },
  setWidgetState(state, obj) {
    Object.keys(obj).forEach((k) => (state.widget_state[k] = obj[k]))
  },
  setData(state, obj) {
    Object.keys(obj).forEach((k) => (state.data[k] = obj[k]))
  },
  setForm(state, obj) {
    Object.keys(obj).forEach((k) => (state.form[k] = obj[k]))
  },
  setContentElementsForm(state, obj) {
    Object.keys(obj).forEach((k) => (state.content_elements.form[k] = obj[k]))
  },
  setConcurrencyForm(state, obj) {
    Object.keys(obj).forEach((k) => (state.concurrent.form[k] = obj[k]))
  },
  setCheckinsForm(state, obj) {
    Object.keys(obj).forEach((k) => (state.checkins.form[k] = obj[k]))
  },
  setLoginsForm(state, obj) {
    Object.keys(obj).forEach((k) => (state.logins.form[k] = obj[k]))
  },
  setRegistrationsForm(state, obj) {
    Object.keys(obj).forEach((k) => (state.registrations.form[k] = obj[k]))
  },
  setChannelsForm(state, obj) {
    Object.keys(obj).forEach((k) => (state.channels.form[k] = obj[k]))
  },
  setMessagesForm(state, obj) {
    Object.keys(obj).forEach((k) => (state.messages.form[k] = obj[k]))
  },
  setExportable(state, obj) {
    Object.keys(obj).forEach((k) => (state.exportable[k] = obj[k]))
  },
  setExportableForm(state, obj) {
    Object.keys(obj).forEach((k) => (state.exportable.form[k] = obj[k]))
  },
}

export const Metrics = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
}
