export const ClickableTypeKeys = {
  link_content: 'link_content',
  link_location: 'link_location',
  open_favorites: 'open_favorites',
  open_calendar: 'open_calendar',
  open_external_url: 'open_external_url',
  open_leaderboard: 'open_leaderboard',
  open_public_chat: 'open_public_chat',
  open_channel: 'open_channel',
  open_photobooth: 'open_photobooth',
  open_support_pane: 'open_support_pane',
  open_reps_pane: 'open_reps_pane',
  open_user_chat: 'open_user_chat',
  reload_page: 'reload_page',
  custom_form: 'custom_form',
}
