import $axios from '@/axios-instance.js'

class ChannelsService {
  baseUrl = '/api/v1/admin/channels'

  // Create
  store(params) {
    return $axios.post(this.baseUrl, params)
  }
  // Read all - findAll
  index(params) {
    return $axios.get(this.baseUrl, params)
  }
  // Read one - show
  show(id, params) {
    return $axios.get(this.baseUrl + `/${id}`, params)
  }
  // Update
  update(id, params) {
    return $axios.post(this.baseUrl + `/${id}`, { ...params, _method: 'PUT' })
  }
  // Delete - delete
  destroy(id) {
    return $axios.post(this.baseUrl + `/${id}`, { _method: 'DELETE' })
  }
}
export default new ChannelsService()
