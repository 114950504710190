<template>
  <renderless-layout>
    <v-container
      id="event_access_index"
      fluid
      class="tw-flex tw-h-full tw-flex-col tw-p-0"
    >
      <StickyHeader :nav="nav">
        <template #actions>
          <div class="tw-flex tw-items-center tw-space-x-3">
            <AppBtn
              color="transparent-dark"
              icon="cog"
              @click="strategy_modal = true"
            >
              {{ $t('admin.home.labels.config') }}
            </AppBtn>
            <AppBtn
              color="green-light"
              type="button"
              icon="g-next"
              g-next="CloudUpload"
              @click="import_modal = true"
            >
              {{ $t('admin.app.import') }}
            </AppBtn>
            <AppBtn
              :to="{ name: 'admin.configs.access-control.create' }"
              rounded
              icon="plus"
              color="white"
              class="tw-px-8 tw-font-bold tw-normal-case tw-text-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300"
            >
              {{ $t('admin.events.items.access_control.new_rule') }}
            </AppBtn>
          </div>
        </template>
      </StickyHeader>
      <v-row class="tw-m-0 tw-flex-1 tw-p-0">
        <v-col cols="12" sm="12" md="12" lg="10" class="mx-auto tw-container">
          <v-container fluid class="tw-space-y-6 tw-py-6">
            <v-card class="tw-rounded-3xl tw-shadow-md" white>
              <v-container class="tw-p-0" fluid>
                <div
                  class="tw-flex tw-grid-cols-5 tw-flex-col-reverse tw-border-b tw-pt-3 xl:tw-grid"
                >
                  <div class="tw-col-span-3 tw-flex tw-items-end">
                    <v-tabs v-model="filter_type" show-arrows>
                      <v-tab
                        v-for="filter_row_type in filter_types"
                        :key="filter_row_type.text"
                        :href="'#' + filter_row_type.value"
                      >
                        {{ filter_row_type.text }}
                      </v-tab>
                    </v-tabs>
                  </div>
                  <div
                    class="tw-col-span-2 tw-grid tw-grid-cols-2 tw-gap-6 tw-px-4 tw-pb-3"
                  >
                    <v-select
                      v-model="filter_selected"
                      :items="filters"
                      :label="$t('admin.app.filter_by')"
                      prepend-inner-icon="sort"
                      hide-details
                      outlined
                      rounded
                    />
                    <v-text-field
                      v-model="search"
                      outlined
                      rounded
                      append-icon="search"
                      :label="$t('app.search')"
                      hide-details
                    />
                  </div>
                </div>
              </v-container>
              <v-container class="tw-overflow-hidden tw-p-0" fluid>
                <v-data-table
                  ref="datatable"
                  v-model="selected"
                  :search="search"
                  :headers="headers"
                  :items="formatedRows"
                  show-select
                  :single-select="loading"
                  :options.sync="options"
                  :server-items-length="totalRows"
                  :loading="loading"
                  class="elevation-1"
                  :footer-props="footer_props"
                  :class="{ mobile: $vuetify.breakpoint.mdAndDown }"
                >
                  <!-- selectable-key="id" -->
                  <template #body="{ items, isSelected, select }">
                    <transition mode="out-in" name="fade">
                      <tbody v-if="items.length" key="main-table">
                        <tr
                          v-for="item in items"
                          :key="item.id"
                          class="tw-cursor-pointer tw-transition sm:tw-py-4"
                          :class="{ 'tw-opacity-75 ': loading }"
                        >
                          <!-- @click="showItem(item)" -->
                          <td>
                            <!-- {{ isSelected(item) }} -->
                            <!-- <v-checkbox v-model="selected" :value="item" /> -->
                            <v-checkbox
                              :value="isSelected(item)"
                              @click="select(item, !isSelected(item))"
                            />
                          </td>
                          <td>
                            <button @click="showItem(item)">
                              <div class="py-4 tw-flex">
                                <div class="tw-flex tw-items-center">
                                  <div>
                                    <quinary-title :dark="false">
                                      {{ item.target_string }}
                                    </quinary-title>
                                  </div>
                                </div>
                              </div>
                            </button>
                          </td>
                          <td>
                            <div class="tw-flex tw-justify-start">
                              <CommonBadge>
                                {{ item.control_type.label }}
                              </CommonBadge>
                            </div>
                          </td>

                          <td>
                            <div class="tw-flex tw-justify-start tw-space-x-3">
                              <CommonBadge>
                                {{ item.access_list_type.label }}
                              </CommonBadge>
                              <StatusBadge
                                :is-active="
                                  item.access_list_type_id ==
                                  accessControlStrategyId
                                "
                              />
                            </div>
                          </td>
                          <td>
                            <div class="tw-flex tw-justify-end">
                              <span>
                                {{ item.date }}
                              </span>
                            </div>
                          </td>
                          <td>
                            <v-btn
                              fab
                              text
                              small
                              class="tw-text-gray-300 focus:tw-text-gray-600 focus:tw-outline-none focus:tw-ring-primary-500"
                              @click="showItem(item)"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                class="tw-h-6 tw-w-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M9 5l7 7-7 7"
                                />
                              </svg>
                            </v-btn>
                          </td>
                        </tr>
                        <!-- mobile list view -->
                      </tbody>
                      <tbody v-if="!items.length && !loading" key="zero_table">
                        <tr>
                          <td colspan="8">
                            <v-alert
                              class="mx-8 mt-4"
                              color="primary"
                              border="left"
                              type="info"
                              elevation="2"
                              colored-border
                            >
                              <strong>{{ $t('$vuetify.noDataText') }}</strong>
                            </v-alert>
                          </td>
                        </tr>
                      </tbody>
                      <tbody
                        v-if="!items.length && loading"
                        key="loading_table"
                      >
                        <tr>
                          <td colspan="8">
                            <v-alert
                              class="mx-8 mt-4"
                              color="primary darken-1"
                              border="left"
                              type="success"
                              elevation="2"
                              colored-border
                            >
                              <strong>{{ $t('app.loading') }}</strong>
                            </v-alert>
                          </td>
                        </tr>
                      </tbody>
                    </transition>
                  </template>
                </v-data-table>
              </v-container>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
      <!-- <div
        class="
          tw-bottom-0
          tw-sticky
          tw-z-10
          tw-flex-initial
          tw-px-2
          tw-shadow-md
          tw-bg-white
          xl:tw-px-8 xl:tw-py-4
          tw-transform tw-transition-all tw-duration-200 tw-ease-in-out
        "
      >
        <div class="tw-container tw-mx-auto">
          <div class="tw-flex tw-items-center tw-justify-between">
            <div class="tw-flex tw-space-x-3 tw-items-center">
              <div>{{ selected.length }} seleccionados</div>
              <AppBtn color="white" @click="selectAll">
                Selecionar todos: {{ totalRows }}
              </AppBtn>
              <AppBtn color="white" @click="selected = []">
                Deseleccionar
              </AppBtn>
            </div>
            <div class="tw-flex tw-space-x-3 tw-items-center">
              <AppBtn color="outlined-danger">Eliminar</AppBtn>
            </div>
          </div>
        </div>
      </div> -->
      <transition name="fade-transition" mode="out-in">
        <FixedFooter v-if="selected.length > 0">
          <div class="tw-container tw-mx-auto">
            <div class="tw-flex tw-items-center tw-justify-between">
              <div class="tw-flex tw-items-center tw-space-x-3">
                <div>{{ selected.length }} seleccionados</div>
                <AppBtn color="white" @click="selectAll">
                  Selecionar todos: {{ totalRows }}
                </AppBtn>
                <AppBtn color="white" @click="selected = []">
                  Deseleccionar
                </AppBtn>
              </div>
              <div class="tw-flex tw-items-center tw-space-x-3">
                <AppBtn color="outlined-danger" @click="deleteMultiple"
                  >Eliminar</AppBtn
                >
              </div>
            </div>
          </div>
        </FixedFooter>
      </transition>
      <RegistriesAccessControlImportModal
        v-model="import_modal"
        @import-success="getLeads"
      />
      <RegistriesAccessControlStrategyModal
        v-if="strategy_modal"
        v-model="strategy_modal"
        :strategy="show.access_control_strategy"
      />
    </v-container>
  </renderless-layout>
</template>

<script>
import RegistriesAccessControlImportModal from '@/components/RegistriesModals/RegistriesAccessControlImportModal.vue'

import RegistriesAccessControlStrategyModal from '@/components/RegistriesModals/RegistriesAccessControlStrategyModal.vue'
import QuinaryTitle from '@/components/Titles/QuinaryTitle.vue'
import StickyHeader from '@/components/StickyHeader.vue'
import FixedFooter from '@/components/FixedFooter.vue'

import CommonBadge from '@/components/CommonBadge.vue'
import StatusBadge from '@/components/StatusBadge.vue'
// import LanguageBadge from '@/components/LanguageBadge'
import { AccessControlsServiceInstance } from '@/services/AccessControlsService'
// import { VirtualRoomsServiceInstance } from '@/services/VirtualRoomsService'
import { mapGetters, mapState } from 'vuex'

// import { formatterArray } from '@/utils/formatterArray'
import AppBtn from '@/components/App/AppBtn.vue'
import { commonTimeFormat } from '@/utils/dateHelpers'

export default {
  components: {
    RegistriesAccessControlImportModal,
    CommonBadge,
    AppBtn,
    RegistriesAccessControlStrategyModal,
    QuinaryTitle,
    StickyHeader,
    StatusBadge,
    FixedFooter,
    // LanguageBadge,
  },
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
  },
  data: () => ({
    filter_type: 'all',
    search_enums: [
      { value: 1, text: 'Enviados' },
      { value: 0, text: 'Pendientes' },
    ],
    import_modal: false,
    search_enum: 1,
    strategy_modal: false,
    menu2: false,
    filter_status: '',
    filter_speciality: '',
    filter_selected: 'domain',
    search: '',
    filters: [
      {
        text: 'Dominio',
        value: 'domain',
      },
      {
        text: 'Email',
        value: 'email',
      },
    ],
    selected: [],
    totalRows: 0,
    rows: [],
    loading: false,
    awaitingSearch: false,
    options: {},
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t('admin.app.target'),
          align: 'start',
          sortable: true,
          value: 'target_string',
        },
        {
          text: this.$t('admin.app.type'),
          align: 'start',
          sortable: false,
          value: 'language',
        },

        // { text: 'Creador', value: 'protein', align: 'start', sortable: false },
        {
          text: this.$t('admin.app.list'),
          sortable: true,
          value: 'sent_at',
        },
        {
          text: this.$t('admin.app.updated_at'),
          value: 'updated_at',
          sortable: true,
          align: 'end',
        },
        { text: '', value: 'go', sortable: false },
      ]
    },
    accessControlStrategy() {
      return this.show.access_control_strategy.access_list_type.label
    },
    accessControlStrategyId() {
      return this.show.access_control_strategy.access_list_type_id
    },

    nav() {
      return [
        {
          name: this.$t('admin.home.labels.events'),
          route: { name: 'admin.registries.index' },
        },
        {
          name: this.show.label,
          route: {
            name: 'admin.registries.show.home',
            params: { id: this.show.id },
          },
        },
        {
          name: this.$t('admin.events.items.labels.access_control'),
          route: {},
        },
      ]
    },
    ...mapState({
      show: (state) => state.registries.show.data,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    filter_types() {
      return [
        { text: this.$t('admin.app.all_key'), value: 'all' },
        ...this.access_list_types,
        // { text: 'Lista Blanca', value: 'white_list' },
        // { text: 'Lista Negra', value: 'black_list' },
      ]
    },
    ...mapGetters({
      access_list_types: ['constants/access_list_types'],
      control_types: ['constants/control_types'],
    }),
    formatedRows() {
      return this.rows.map((row) => ({
        ...row,
        // formatedRoles: formatterArray().format(
        //   row.roles.map((role) => role.label),
        // ),
        // date: format(new Date(row.updated_at), "hh:mm aaa, do 'de' MMMM yyyy", {
        //   locale: es,
        // }),
        date: commonTimeFormat(row.updated_at),
        // fullName: `${row.first_name} ${row.last_name}`,
      }))
    },
    footer_props() {
      const obj = {
        'items-per-page-options': [
          10, 20, 30, 40, 50,
          // { text: 'Todos', value: this.totalRows },
        ],
      }
      return obj
    },
  },
  watch: {
    options: {
      handler() {
        this.getLeads()
      },
      deep: true,
    },
    filter_status: {
      handler() {
        this.getLeads()
      },
      deep: true,
    },
    filter_speciality: {
      handler() {
        this.getLeads()
      },
      deep: true,
    },
    filter_type: {
      handler() {
        this.getLeads()
      },
      deep: true,
    },
    filter_selected: {
      handler() {
        this.getLeads()
      },
      deep: true,
    },
    search_enum: {
      handler() {
        this.getLeads()
      },
      deep: true,
    },
    search: {
      handler() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getLeads()
            this.awaitingSearch = false
          }, 1000) // 1 sec delay
        }
        this.awaitingSearch = true
      },
      deep: true,
    },
  },
  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    selectAll() {
      console.log(this.$refs.datatable)
      this.$refs.datatable.toggleSelectAll(true)
    },
    showItem(a) {
      console.log(this.$vuetify.application.left)
      return this.$router.push({
        name: 'admin.configs.access-control.edit',
        params: { accessControl: a.id, id: this.id },
      })
    },
    deleteMultiple() {
      console.log(this.selected)
    },
    getLeads() {
      this.$refs.datatable.toggleSelectAll(false)
      // this.loading = true;
      this.loading = true

      // this.$vuetify.goTo(`#event_access_index`, {
      //   duration: 300,
      //   offset: 0,
      //   easing: 'easeInOutCubic',
      // })
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      //  let page_number = page.page || this.state.pagination.page;
      // let rowsPerPage = page.rowsPerPage || this.state.pagination.rowsPerPage;
      AccessControlsServiceInstance.index(this.id, {
        params: {
          page: page,
          rowsPerPage: itemsPerPage,
          sortBy: sortBy[0],
          sortDesc: sortDesc[0],
          filterBy: this.filter_selected,
          search: this.search,
          filter_type: this.filter_type,
          search_enum: this.search_enum,
        },
      })
        .then((response) => {
          const { total, data } = response.data.data
          this.rows = data
          this.totalRows = total
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style></style>
