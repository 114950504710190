<template>
<v-dialog v-model="open" scrollable width="450px" content-class="bz2-scroll tw-rounded-xl">
    <v-card class="tw-relative tw-rounded-xl tw-border-0 tw-shadow-md">
        <v-card-text class="tw-p-2 tw-bg-[#ffffff] tw-relative">
            <v-tabs v-model="tab" fixed-tabs background-color="transparent">
                <v-tab v-for="tr in typeRequest" :key="tr.tab" :href="`#${tr.tab}`" class="tw-capitalize tw-text-xs tw-font-semibold">
                    {{ tr.label }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" style="height: 270px;" class="tw-overflow-y-scroll bz-scroll tw-bg-white tw-px-2">
                <v-tab-item :key="tabKeys.received" :value="tabKeys.received">
                    <div class="tw-py-4">
                        <ItemList v-for="ra in rowsReceived" :key="ra.uuid" :user="ra.by_user" :calendar-item="ra" :is-received="true" @accepted-success="acceptedAction" />
                    </div>
                </v-tab-item>
                <v-tab-item :key="tabKeys.sent" :value="tabKeys.sent">
                    <div class="tw-py-4">
                        <ItemList v-for="ra in rowsRequest" :key="ra.uuid" :user="ra.user" :calendar-item="ra" />
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
    </v-card>
</v-dialog>
</template>

<script>
import ItemList from '@/components/Event/EventCalendarLiveNotificationsItem'
export default {
    name: 'EventCalendarLiveNotifications',
    components: {
        ItemList,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        rowsRequest: {
            type: [Array, Object],
            default: () => ({}),
        },
        rowsReceived: {
            type: [Array, Object],
            default: () => ({}),
        },
    },
    data: () => ({
        tab: null,
        typeRequest: [
            { tab: 'received', label: 'Recibidos' },
            { tab: 'sent', label: 'Enviados' },
        ],
        tabKeys: {
            sent: 'sent',
            received: 'received',
        },
    }),
    computed: {
        open: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
    mounted() {},
    methods: {
        closeModal() {
            this.open = false
        },
        acceptedAction() {
            this.closeModal()
            this.$emit('accepted-success')
        },
    },
}
</script>
