<script>
import {
  SET_ACTIVE,
  GET_ALL_USERS_BY_REGISTRY_QUERY_AND_USERS_IN_DMS,
  NEW_USER_IN_DMS,
  onlineUser,
} from '@/graphql/queries/userQueries'
import {
  setUserLocationAndActive,
  subUsersLocation,
} from '@/graphql/queries/vrUserLocationQueries'
import { userSignaling } from '@/graphql/subscriptions/userSubscriptions'
import { eventUsersHybridGp } from '@/graphql/queries/notificationsQueries'
import { VideoChatStateKeys } from '@/enums/VideoChatStateKeys'
import { pipe, map, toArray } from 'lazy-collections'
import { signalUser } from '@/graphql/mutations/userMutations'
import { mapFields } from '@/helpers'
import { mapState } from 'vuex'

export default {
  props: {
    slug: {
      type: [Number, String],
      default: 0,
    },
  },
  apollo: {
    $subscribe: {
      onlineUsers: {
        query: onlineUser,
        result({ data }) {
          if (
            data.onlineUser.id == this.user.id &&
            !data.onlineUser.is_online
          ) {
            this.$apollo
              .mutate({
                mutation: SET_ACTIVE,
              })
              .then(() => {
                console.log('Two mutations ran')
              })
          }
          if (this.users.length > 0) {
            console.log('users list about to refresh')
            let program = pipe(
              map((row) => row.id),
              toArray(),
            )
            const plainMap = program(this.users)
            if (!plainMap.includes(data.onlineUser.id)) {
              this.$store.commit('event/setConstants', {
                needs_refershing: true,
              })
            }
          }
        },
      },
      usersLocation: {
        query: subUsersLocation,
        result({ data }) {
          this.usersLocation = data.subUsersLocation
          let program = pipe(
            map((row) => row.user_id),
            toArray(),
          )
          const simple_user_location_ids = program(data.subUsersLocation)

          let program2 = pipe(
            map((row) => ({
              ...row,
              is_online: simple_user_location_ids.includes(row.id),
            })),
            toArray(),
          )
          this.users = program2(this.users)
        },
      },
      updatePrivateChats: {
        query: NEW_USER_IN_DMS,
        result({ data }) {
          this.active_chats = data.newUserParticipatingInMyChannels
            .filter((row) => row.message_count > 0)
            .map((row) => row.user.uuid)

          this.private_channels = data.newUserParticipatingInMyChannels
            .map((row) => [row.channel_id, row.user.uuid])
            .reduce((obj, cur) => ({ ...obj, [cur[0]]: cur[1] }), {})
        },
      },
      eventUsersHybrid: {
        query: eventUsersHybridGp,
        result({ data }) {
          console.log('🚀 ~ result ~ status:', data)
          this.$store.commit('event/setConstants', {
            needs_hybrid_refershing: true,
          })
        },
      },
      userSignaling: {
        query: userSignaling,
        result({ data }) {
          console.log('userSignaling')
          if (data.userSignaling.status == VideoChatStateKeys.waiting) {
            if (
              [
                VideoChatStateKeys.on_going,
                VideoChatStateKeys.waiting,
                VideoChatStateKeys.ringing,
              ].includes(this.video_chat_status)
            ) {
              this.$apollo.mutate({
                mutation: signalUser,
                variables: {
                  signal_user: {
                    status: VideoChatStateKeys.busy,
                    user_uuid: this.user.uuid,
                    target_user_uuid: data.userSignaling.uuid,
                  },
                },
              })
              return
            }
            this.$store.dispatch('chat/isRinging', {
              body: {
                user_uuid: this.user.uuid,
                target_user_uuid: data.userSignaling.uuid,
              },
            })
          }
          if (data.userSignaling.status == VideoChatStateKeys.on_going) {
            this.$store.dispatch('chat/answerCall')
          }
          if (data.userSignaling.status == VideoChatStateKeys.busy) {
            this.$store.dispatch('chat/targetBusy')
          }
          if (data.userSignaling.status == VideoChatStateKeys.declined) {
            if (this.video_chat_status == VideoChatStateKeys.on_going) {
              // hunn up
              this.$store.dispatch('chat/hungUp')
            }
            if (this.video_chat_status == VideoChatStateKeys.waiting) {
              // declined
              this.$store.dispatch('chat/declined')
            }
          }
        },
      },
    },
  },
  computed: {
    ...mapFields({
      fields: ['users', 'usersLocation', 'globalChannels'],
      base: ['event', 'socket'],
      mutation: 'event/setSocket',
    }),

    ...mapFields({
      fields: [
        'active_chats',
        'private_channels',
      ],
      base: ['chat'],
      mutation: 'chat/setState',
    }),
    ...mapState({
      user: (state) => state.session.user,
      video_chat_status: (state) => state.chat.video_chat.status,
      showLoading: (state) => state.event.show.isLoading,
    }),
  },
  mounted() {
    this.$apollo
      .query({
        query: GET_ALL_USERS_BY_REGISTRY_QUERY_AND_USERS_IN_DMS,
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        console.log('GET_ALL_USERS_BY_REGISTRY_QUERY_AND_USERS_IN_DMS')
        const {
          usersParticipatingInMyChannels,
          channelsWhereType,
          getUsersLocation,
        } = res.data

        this.usersLocation = getUsersLocation
        const simple_user_location_ids = getUsersLocation.map(
          (row) => row.user_id,
        )
        this.users = this.users.map((row) => ({
          ...row,
          is_online: simple_user_location_ids.includes(row.id),
        }))
        this.globalChannels = channelsWhereType
        this.active_chats = usersParticipatingInMyChannels
          .filter((row) => row.message_count > 0)
          .map((row) => row.user.uuid)
        this.private_channels = usersParticipatingInMyChannels
          .map((row) => [row.channel_id, row.user.uuid])
          .reduce((obj, cur) => ({ ...obj, [cur[0]]: cur[1] }), {})

        this.$apollo
          .mutate({
            mutation: setUserLocationAndActive,
            variables: {
              slug: this.slug,
            },
          })
          .then((data) => {
            console.log('Two mutations ran')
            console.log(data)
          })
      })
  },
  render() {
    console.log('real time connected')
    return this.$slots.default
  },
}
</script>
