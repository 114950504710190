import $axios from '@/axios-instance.js'

export default class ConstantsService {
  baseUrl = '/api/v1/constants'

  // Read all - findAll
  init(params) {
    return $axios.get(this.baseUrl, params)
  }

  // Read one - show
  adminConstants(params) {
    return $axios.get(this.baseUrl + '/admin', params)
  }
}
export const ConstantsServiceInstance = new ConstantsService()
