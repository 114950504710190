<template>
  <div class="tw-bg-white">
    <div class="tw-mx-auto tw-max-w-7xl tw-px-4 tw-py-12 sm:tw-px-6 lg:tw-px-8">
      <div class="tw-flex tw-flex-wrap">
        <div
          v-for="logo in section.data.logos"
          :key="logo.uid"
          class="tw-mx-auto tw-flex tw-w-1/2 tw-justify-center tw-px-4 tw-py-6 tw-relative"
          :class="[cols]"
        >
          <!-- :src="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg" -->
          <img
            class="tw-h-12 sm:tw-h-36"
            :src="logo.logo_url"
            :alt="logo.uid"
          />
          <template v-if="logo.url_link">
            <a :href="logo.url_link" target="_blank" class="overlay_image_link"/>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    cols() {
      return {
        3: 'md:tw-w-1/3',
        4: 'md:tw-w-1/4',
        5: 'md:tw-w-1/5',
        6: 'md:tw-w-1/6',
      }[this.section.data.grid]
    },
  },
}
</script>

<style></style>
