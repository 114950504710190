<template>
  <div class="tw-flex tw-h-full tw-flex-1 tw-flex-col tw-overflow-y-hidden">
    <!-- Donovann: Se añadir Q&A al chat -->
    <div 
      v-if="parrentThreadChat"
      class="tw-w-full tw-rounded-t-lg tw-bg-secondary-400">
      <div class="tw-flex tw-items-center tw-space-x-2 tw-px-4 tw-py-5">
        <div class="">
          <v-btn
            fab
            x-small
            class="text--white tw-normal-case tw-shadow-sm focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-300"
            @click="view = 'HOME'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="tw-h-4 tw-w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </v-btn>
        </div>
        <!-- tw-px-4  -->
        <div class="tw-relative tw-flex tw-flex-1 tw-items-center tw-space-x-3">
          <div class="tw-min-w-0 tw-flex-1">
            <p class="tw-mb-0 tw-text-base tw-font-medium tw-text-gray-50">
              {{ parrentThread.subject }}
            </p>
            <p
              v-if="parrentThread.description"
              class="tw-mb-0 tw-truncate tw-text-sm tw-text-gray-200"
            >
              {{ parrentThread.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- End Donovann -->
    <transition name="fade-transition" mode="out-in">
      <div
        v-if="loading"
        key="thread_items_loading"
        class="tw-scrolling-touch scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 tw-flex tw-flex-1 tw-flex-col tw-overflow-y-scroll"
      >
        <v-progress-linear indeterminate color="primary darken-2" />
      </div>
      <div
        v-if="!loading"
        id="messages"
        key="thread_items"
        ref="scrollToMe"
        class="tw-scrolling-touch scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 tw-flex tw-flex-1 tw-flex-col tw-overflow-y-scroll tw-bg-white"
      >
        <!-- <div > -->
        <transition-group
          name="fade-transition"
          mode="out-in"
          tag="div"
          class="tw-divide-y tw-divide-gray-200"
        >
          <EventRoomContentElementChatThreadItem
            v-for="item in parsedThreadItems"
            :key="item.uuid"
            :item="item"
            :thread-id="parrentThreadId"
          />
        </transition-group>
        <!-- </div> -->
      </div>
    </transition>
    <div
      class="tw-border-t-2 tw-border-gray-200 tw-px-4 tw-pt-2 tw-pb-2 sm:tw-mb-0"
    >
      <form @submit.prevent="submit">
        <div class="tw-relative tw-flex tw-space-x-2">
          <v-textarea
            v-model="body"
            :disabled="loading"
            auto-grow
            dense
            outlined
            hide-details=""
            rounded
            filled
            class="tw-max-h-64 tw-overflow-y-auto"
            :placeholder="$t('app.msg_placeholder')"
            rows="1"
            @keydown.enter.exact.prevent
            @keyup.enter.exact="submit"
          />
          <div class="tw-items-end sm:tw-flex">
            <button
              v-ripple
              type="submit"
              class="tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-blue-500 tw-text-white tw-transition tw-duration-500 tw-ease-in-out hover:tw-bg-blue-400 focus:tw-outline-none focus:tw-ring focus:tw-ring-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="tw-h-5 tw-w-5 tw-rotate-90 tw-transform"
              >
                <path
                  d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"
                />
              </svg>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { threadWhereId } from '@/graphql/queries/threadQueries'
import { v4 } from '@/helpers'
import { commonDistanceSince } from '@/utils/dateHelpers'
import { storeQuestionAnswer } from '@/graphql/mutations/threadMutations'
// import AppBtn from '../App/AppBtn.vue'
import EventRoomContentElementChatThreadItem from './EventRoomContentElementChatThreadItem.vue'
import { mapState } from 'vuex'
import {
  newQuestionAnswer,
  refreshFullThread,
} from '@/graphql/subscriptions/threadSubscriptions'
import { isObjectEmpty } from '@/utils/isObjectEmpty'
export default {
  components: {
    // AppBtn,
    EventRoomContentElementChatThreadItem,
  },
  props: {
    parrentThreadId: {
      type: Number,
      default: 0,
    },
    // Donovann: Data para llenar el 
    // subject - description
    parrentThread: {
      type: Object,
      default: () => ({}),
    },
    // Validar si ha sido abierto en una sala o desde el chat 
    parrentThreadChat: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    $subscribe: {
      newQuestionAnswer: {
        // manual:
        query: newQuestionAnswer,
        variables() {
          return {
            thread_id: this.thread_id,
          }
        },
        result({ data }) {
          console.log({ data })
          let list_ids = this.thread_items.map((row) => row.id)
          if (list_ids.includes(data.newQuestionAnswer.id)) {
            this.thread_items = this.thread_items.map((row) => {
              return row.id === data.newQuestionAnswer.id
                ? data.newQuestionAnswer
                : row
            })
            return
          } else {
            this.thread_items = [...this.thread_items, data.newQuestionAnswer]
          }

          if (data.newQuestionAnswer.reply_to_id) {
            return
          }
          this.$nextTick(() => {
            this.scrollToElement()
          })
        },
      },
      refreshAllQuestionAnswers: {
        // manual:
        query: refreshFullThread,
        variables() {
          return {
            thread_id: this.thread_id,
          }
        },
        result({ data }) {
          this.thread_items = data.refreshFullThread.question_answer
          this.$nextTick(() => {
            this.scrollToElement()
          })
        },
      },
    },
  },
  data: () => ({
    loading: true,
    body: '',
    thread_id: 0,
    thread_items: [],
  }),
  computed: {
    // Donovann: (view) si esta en el chat te permitira retroceder a Home
    view: {
      get() {
        return this.$store.state.chat.view
      },
      set(val) {
        this.$store.commit('chat/setState', { view: val })
      },
    },
    parsedThreadItems() {
      let parsedAll = this.thread_items.map((row) => ({
        ...row,
        parsedCreatedAt: commonDistanceSince(row.created_at),
      }))
      let children = parsedAll
        .filter((row) => row.reply_to_id)
        .reduce((acc, curr) => {
          return {
            ...acc,
            [curr.reply_to_id]: curr,
          }
        }, {})
      let hasChildren = !isObjectEmpty(children)
      return parsedAll
        .filter((row) => !row.reply_to_id)
        .filter((row) => {
          if (this.isAdmin) {
            return true
          }
          if (this.user.id == row.user.id) {
            return true
          }

          return row.is_public
        })
        .map((row) => {
          let reply = {}
          if (hasChildren) {
            if (children[row.id]) {
              reply = children[row.id]
            }
          }
          return { ...row, reply }
        })
    },
    isAdmin() {
      return this.user.roles.map((row) => row.name).includes('admin')
    },
    ...mapState({
      user: (state) => state.session.user,
    }),
  },
  async mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      this.thread_id = this.parrentThreadId
      const { data } = await this.$apollo.query({
        query: threadWhereId,
        fetchPolicy: 'network-only',
        variables: {
          thread_id: this.parrentThreadId,
        },
      })
      this.thread_items = data.threadWhereId.question_answer

      this.loading = false

      console.log(data)
    },
    submit() {
      if (this.body === '') {
        return
      }
      // uuid get established on the frontend -> adds little clock icon -> once emited overide message and take away icon
      const uuid = v4()
      this.$apollo.mutate({
        mutation: storeQuestionAnswer,
        variables: {
          content: {
            body: this.body,
            thread_id: this.thread_id,
            uuid,
          },
        },
      })
      // .then(async () => {
      //   console.log('here')
      //   const { data } = await this.$apollo.query({
      //     query: threadWhereId,
      //     fetchPolicy: 'network-only',
      //     variables: {
      //       thread_id: this.parrentThreadId,
      //     },
      //   })
      //   this.thread_items = data.threadWhereId.question_answer
      // })
      this.body = ''
    },
    scrollToElement() {
      const el = this.$refs.scrollToMe
      window.thread_component = this
      console.log(el)
      if (el) {
        el.scrollTop = el.scrollHeight
      }
    },
  },
}
</script>
