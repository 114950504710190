<template>
    <div class="tw-grid tw-gap-6 tw-grid-cols-12">
        <div 
            v-for="field in fields" 
            :key="field.uid" 
            class="tw-col-span-12" 
            :class="matchCols[field.cols]"
        >
            <!-- ====== Text Field ====== -->
            <template v-if="field.field_type == 'input'">
                <v-text-field 
                    v-model="field.model" 
                    :label="field.label" 
                    :name="field.name"
                    :rules="field.is_required ? [rules.required] : []" 
                    type="text"
                    v-bind="commonProps()"
                />
            </template>
            <!-- ====== Text Area ====== -->
            <template v-if="field.field_type == 'textarea'">
                <v-textarea 
                    v-model="field.model" 
                    :label="field.label" 
                    :rules="field.is_required ? [rules.required] : []"
                    :name="field.name" 
                    v-bind="commonProps()"
                />
            </template>
            <!-- ====== Radio Buttons ====== -->
            <template v-if="field.field_type == 'radio'">
                <v-radio-group 
                    v-model="field.model" 
                    class="tw-mt-0" 
                    :label="field.label" 
                    :rules="field.is_required ? [rules.required] : []"
                >
                    <v-radio 
                        v-for="option in field.options" 
                        :key="option.uid" 
                        :label="option.text"
                        :value="option.value"
                    />
                </v-radio-group>
            </template>
            <!-- ====== Checkbox ====== -->
            <template v-if="field.field_type == 'checkbox'">
                <div>
                    <label for="first_name" class="tw-block tw-text-sm tw-font-medium tw-text-slate-600">
                        {{ field.label }}
                    </label>
                    <v-checkbox 
                        v-for="option in field.options" 
                        :key="option.uid"
                        v-model="field.model_multiple" 
                        :rules="field.is_required ? [rules.array_not_empty] : []" 
                        :label="option.text" 
                        :value="option.value"
                        hide-details 
                    />
                </div>
            </template>
            <!-- ====== Select ====== -->
            <template
                v-if="field.field_type == 'select' && field.is_multiple
                && field.label != 'Departments'
                && field.label != 'Provinces'
                && field.label != 'Districts'"
            >
            <!-- :rules="field.is_required ? [rules.required] : []"  -->
                <v-select
                    v-model="field.model_multiple" 
                    :label="field.label" 
                    :rules="[field.is_required ? v => !!field.model_multiple.length || 'This field is required' : []]"
                    :items="field.options"
                    clearable
                    multiple
                    v-bind="commonProps()"
                >
                    <template #item="data">
                        <div v-html="formatText(data.item.text)" />
                    </template>
                    <template #selection="{ item, index }">
                        <div v-if="index === 0" v-html="formatText(item.text)" />
                        <span v-if="index === 1" class="grey--text text-caption tw-ml-1">
                            ({{ field.model_multiple.length - 1 }} {{ field.model_multiple.length - 1 === 1 ? $t('app.more_selection') : $t('app.more_selections') }})
                        </span>
                    </template>
                </v-select>
            </template>
            <template
                v-if="field.field_type == 'select' 
                && !field.is_multiple
                && field.label != 'Departments'
                && field.label != 'Provinces'
                && field.label != 'Districts'"
            >
                <v-select
                    v-model="field.model" 
                    :label="field.label" 
                    :rules="field.is_required ? [rules.required] : []" 
                    :items="field.options"
                    clearable
                    v-bind="commonProps()"
                >
                    <template #item="data">
                        <div v-html="formatText(data.item.text)" />
                    </template>
                    <template #selection="data">
                        <div v-html="formatText(data.item.text)" />
                    </template>
                </v-select>
            </template>
            <!-- ====== Number ====== -->
            <template v-if="field.field_type == 'number'">
                <v-text-field 
                    v-model="field.model" 
                    :label="field.label" 
                    :name="field.name"
                    :rules="field.is_required ? [rules.required] : []"
                    :counter="!!field.characters" 
                    :maxlength="field.characters"
                    v-bind="commonProps({ hideDetails: field.characters ? false : true })"
                    @keypress="onlyNumber"
                />
            </template>
            <!-- ====== Date ====== -->
            <template v-if="field.field_type == 'date'">
                <v-text-field 
                    v-model="field.model" 
                    :label="field.label" 
                    :name="field.name"
                    :rules="field.is_required ? [rules.required] : []" 
                    type="date" 
                    v-bind="commonProps()"
                />
            </template>
            <!-- ====== Departments ====== -->
            <template v-if="field.field_type == 'select' && field.label == 'Departments'">
                <DepartmentsAutocomplete 
                    v-model="field.model"
                    :rules="field.is_required ? [rules.required] : []"
                    hide-details
                    @change="listProvinceByDepartments" 
                />
            </template>
            <!-- ====== Provinces ====== -->
            <template v-if="field.field_type == 'select' && field.label == 'Provinces'">
                <ProvincesAutocomplete 
                    v-model="field.model" 
                    :search="itemsProvinces"
                    :rules="field.is_required ? [rules.required] : []"
                    hide-details
                    @change="listDistricByProvinces"
                />
            </template>
            <!-- ====== Districts ====== -->
            <template v-if="field.field_type == 'select' && field.label == 'Districts'">
                <DistrictsAutocomplete 
                    v-model="field.model" 
                    :search="itemsDistritcs"
                    :rules="field.is_required ? [rules.required] : []" 
                    hide-details
                />
            </template>
            <!-- ====== Upload ====== -->
            <template v-if="field.field_type == 'upload'">
                <v-file-input
                    v-model="field.model" 
                    :label="field.label" 
                    :name="field.name"
                    :rules="field.is_required ? [rules.required] : []"
                    show-size
                    truncate-length="15"
                    v-bind="commonProps()"
                />
            </template>
            <!-- ====== Representatives ====== -->
            <!-- <template v-if="field.field_type == 'domain'">
                <UsersAutocomplete
                    v-model="field.model"
                    :label="field.label"
                    :name="field.name"
                    :rules="field.is_required ? [rules.required] : []"
                    :multiple="false"
                    :use-notes="true"
                    filter-name="representative"
                    hide-details
                />
            </template> -->
        </div>
    </div>
</template>

<script>
import { matchCols } from '@/utils/formCols'
import { rules } from '@/utils/rules'
import { onlyNumber } from '@/utils/Settings'
import DepartmentsAutocomplete from '@/components/DepartmentsAutocomplete'
import ProvincesAutocomplete from '@/components/ProvincesAutocomplete'
import DistrictsAutocomplete from '@/components/DistrictsAutocomplete'

export default {
    name: 'DynamicFields',
    components: {
        DepartmentsAutocomplete,
        ProvincesAutocomplete,
        DistrictsAutocomplete,
    },
    props: {
        fields: {
            type: [Array, Object],
            required: true,
        },
    },
    data: () => ({
        matchCols,
        rules,
        onlyNumber,
        itemsProvinces: null,
        itemsDistritcs: null,
    }),
    computed: {
        commonProps() {
            return (overrides = {}) => {
                return {
                    outlined: true,
                    validateOnBlur: true,
                    required: true,
                    rounded: true,
                    hideDetails: overrides.hideDetails !== undefined ? overrides.hideDetails : true,
                }
            }
        },
    },
    methods: {
        listProvinceByDepartments(e) {
            this.itemsProvinces = e
        },
        listDistricByProvinces(e) {
            this.itemsDistritcs = e
        },
        formatText(text) {
            const regex = /\*(.*?)\*/g
            return text.replace(regex, '<strong>$1</strong>')
        },
    },
}
</script>