<template>
  <div class="tw-relative tw-overflow-hidden tw-rounded-2xl tw-bg-gray-50 tw-shadow-xl tw-py-10 tw-px-0 sm:tw-px-6 lg:tw-px-10">
    <!-- Slice Card -->
    <div aria-hidden="true" class="tw-absolute tw-inset-0 tw-flex tw-justify-end">
      <svg
class="tw-inset-y-0 tw-right-0 tw-block tw-h-full tw-w-48 tw-translate-x-1/2 tw-transform tw-text-gray-200"
        fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
        <polygon points="50,0 100,0 50,100 0,100" />
      </svg>
      <div class="tw-inset-y-0 tw-block tw-h-full tw-w-5/12 tw-bg-gray-200" />
    </div>
    <!-- End Slice Card -->
    <!-- Close -->
    <div v-if="true" class="tw-absolute tw-inset-0 tw-flex tw-justify-end tw-p-4">
      <v-btn v-if="canClose" icon class="tw-transition focus:tw-outline-none focus:tw-ring" @click="closeBtn">
        <v-icon>close</v-icon>
      </v-btn>
    </div>
    <!-- End Close -->
    <!-- Content -->
    <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 tw-relative tw-gap-4">
      <!-- Profile -->
      <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <div
class="tw-p-1 tw-rounded-full tw-border-2"
          :class="[!!user.is_online ? 'tw-border-green-400' : 'tw-border-gray-400']">
          <v-avatar size="100">
            <img alt="user" :src="userCard.avatar"/>
          </v-avatar>
        </div>
        <div class="tw-mt-2 tw-flex tw-flex-col tw-text-center">
          <!-- Fullname -->
          <span class="tw-text-sm md:tw-text-lg tw-font-bold">{{ userCard.full_name }}</span>
          <!-- Email -->
          <a
v-if="default_fields_look_up.email.is_public" :href="'mailto:' + userCard.email"
            class="tw-text-xs md:tw-text-sm tw-text-opacity-50 tw-text-gray-600 tw-mb-1">
            <v-icon style="font-size: 16px;" class="tw-text-gray-600 tw-text-opacity-50">alternate_email</v-icon>
            {{ userCard.email }}
          </a>
          <!-- Phone Number -->
          <a
v-if="default_fields_look_up.phone_number.is_public" :href="'tel:' + current_attendee.phone_number"
            class="tw-text-xs md:tw-text-sm tw-text-gray-600 tw-text-opacity-50 tw-mb-1">
            <v-icon style="font-size: 16px;" class="tw-text-gray-600 tw-text-opacity-50">call</v-icon>
            {{ current_attendee.phone_number }}
          </a>
        </div>
        <!-- Options -->
        <div class="tw-w-full md:tw-w-auto tw-flex tw-justify-center tw-pt-6">
          <!-- Add conctact -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
              <v-btn v-if="canAddConnection" class="mx-2" fab small color="success" :loading="loading" :disabled="added" @click="addContact(user)">
                  <v-icon dark>{{ added? 'how_to_reg': 'person_add' }}</v-icon>
                </v-btn>
              </span>
            </template>
            <span>{{ $t('pages.event.actions.add_contact') }}</span>
          </v-tooltip>
          <!-- Block user -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-btn v-if="canAddConnection && isAdmin" class="mx-2" fab small color="error" :loading="loading_blocked" :disabled="blocked" @click="blockContact(user)">
                  <v-icon dark>person_off</v-icon>
                </v-btn>
              </span>
            </template>
            <span>{{ $t('pages.event.actions.block_user') }}</span>
          </v-tooltip>
          <!-- Open chat direct -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-btn class="mx-2" fab dark small color="secondary" @click="openChat(user)">
                  <v-icon dark>
                    chat
                  </v-icon>
                </v-btn>
              </span>
            </template>
            <span>{{ $t('pages.event.actions.send_msg') }}</span>
          </v-tooltip>
          
        </div>
      </div>
      <!-- Personal Data -->
      <div class="tw-col-span-2 tw-px-6 tw-flex tw-items-center">
        <ul>
          <li v-if="default_fields_look_up.company.is_public" class="libzd">
            <div class="pt_bzd tw-bg-primary-500" />
            <strong class="tw-text-sm tw-ml-3 tw-mr-2">{{ default_fields_look_up.company.label }}:
            </strong>
            <span class="tw-text-opacity-60 tw-text-sm">{{ current_attendee.company }}</span>
          </li>
          <li v-if="default_fields_look_up.title.is_public" class="libzd">
            <div class="pt_bzd tw-bg-primary-500" />
            <strong class="tw-text-sm tw-ml-3 tw-mr-2">{{ default_fields_look_up.title.label }}:
            </strong>
            <span class="tw-text-opacity-60 tw-text-sm">{{ current_attendee.title }}</span>
          </li>
          <li v-if="default_fields_look_up.country_code.is_public" class="libzd">
            <div class="pt_bzd tw-bg-primary-500" />
            <strong class="tw-text-sm tw-ml-3 tw-mr-2">{{
              default_fields_look_up.country_code.label
            }}:
            </strong>
            <span class="tw-text-opacity-60 tw-text-sm">{{ current_attendee.country_name }}</span>
          </li>
          <li v-if="default_fields_look_up.city.is_public" class="libzd">
            <div class="pt_bzd tw-bg-primary-500" />
            <strong class="tw-text-sm tw-ml-3 tw-mr-2">{{ default_fields_look_up.city.label }}:
            </strong>
            <span class=" tw-text-opacity-60 tw-text-sm">{{ current_attendee.city }}</span>
          </li>
          <li v-if="default_fields_look_up.address.is_public" class="libzd">
            <div class="pt_bzd tw-bg-primary-500" />
            <strong class="tw-text-sm tw-ml-3 tw-mr-2">{{ default_fields_look_up.address.label }}:
            </strong>
            <span class="tw-text-opacity-60 tw-text-sm">{{ current_attendee.address }}</span>
          </li>
          <!-- <li v-for="(rfs, index) in current_attendee.registry_form_submission" :key="index" class="libzd">
            <div class="pt_bzd tw-bg-primary-500" />
            <strong class="tw-text-sm tw-ml-3 tw-mr-1 ">{{ rfs.label }}: </strong>
            <span class=" tw-text-opacity-60 tw-text-sm">{{ rfs.model }}</span>
          </li> -->
        </ul>
      </div>
    </div>
    <!--  End Content -->
    <div v-if="false" class="tw-relative">
      <div class="tw-flex tw-items-center tw-space-x-4">
        <div class="tw-relative">
          <img class="tw-h-40 tw-w-40 tw-rounded-full" :class="[imgClasses]" :src="userCard.avatar" alt="" />
          <span
            class="tw-absolute tw--bottom-0.5 tw--right-1 tw-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-px-0.5 tw-py-px"
            aria-hidden="true">
            <span
class="tw-h-2 tw-w-2 tw-rounded-full"
              :class="[!!user.is_online ? 'tw-bg-green-400' : 'tw-bg-gray-400']" />
          </span>
        </div>
        <div class="tw-min-w-0 tw-space-y-2">
          <div class="tw-space-y-1 tw-text-lg tw-font-medium tw-leading-6">
            <h3 :class="[fullNameTitle]">{{ userCard.full_name }}</h3>
            <p v-if="default_fields_look_up['title'].is_viewable" class="tw-mb-0 tw-text-gray-400" :class="[jobTitle]">
              {{ current_attendee.title }}
            </p>
            <!-- <div class="tw-max-w-max"> -->
            <p
v-if="default_fields_look_up['company'].is_viewable"
              class="tw-mb-0 tw-truncate tw-leading-none tw-text-primary-500" :class="[companyTitle]">
              {{ current_attendee.company }}
            </p>
            <!-- </div> -->
          </div>
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-justify-between" :class="[infoSpacerClasses]">
        <div>
          <ul class="tw-pl-0" :class="[defaultFieldsSpacerClasses]">
            <li v-if="default_fields_look_up['phone_number'].is_viewable">
              <a href="#" class="tw-flex tw-items-center tw-text-gray-400 hover:tw-text-gray-500">
                <span class="tw-sr-only">phone</span>
                <g-icon :class="[defaultFieldsIconClasses]" class="tw-flex-shrink-0 tw-text-primary-400" name="phone" />
                <p :class="[defaultFieldsTextClasses]" class="tw-mb-0 tw-ml-2">
                  {{ current_attendee.phone_number }}
                </p>
              </a>
            </li>
            <li v-if="default_fields_look_up['email'].is_viewable">
              <a href="#" class="tw-flex tw-items-center tw-text-gray-400 hover:tw-text-gray-500">
                <span class="tw-sr-only">Email</span>
                <g-icon :class="[defaultFieldsIconClasses]" class="tw-flex-shrink-0 tw-text-primary-400" name="email" />
                <p :class="[defaultFieldsTextClasses]" class="tw-mb-0 tw-ml-2">
                  {{ userCard.email }}
                </p>
              </a>
            </li>
          </ul>
        </div>
        <div class="tw-flex">
          <v-btn
v-if="canAddConnection && !blocked && isAdmin" :loading="loading" :disabled="loading"
            class="tw-font-bold tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-50 tw-mr-2"
            :class="[size == 'xs' ? 'tw-px-2' : 'tw-px-8']" rounded @click="blockContact(user)">
            <v-icon>person_off</v-icon>
            {{ $t('pages.event.actions.block_user') }}
          </v-btn>
          <v-btn
v-if="canAddConnection && !added" :loading="loading" :disabled="loading"
            class="tw-font-bold tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-50"
            :class="[size == 'xs' ? 'tw-px-2' : 'tw-px-8']" rounded @click="addContact(user)">
            <v-icon v-if="size == 'xs'">add</v-icon>
            <template v-else>
              <g-icon name="add_user" class="tw-mr-2 tw--ml-1 tw-h-6 tw-w-6" />
              {{ $t('pages.event.actions.add_contact') }}
            </template>
          </v-btn>
          <status-badge v-if="canAddConnection && blocked" class="tw-mr-2 tw-ml-2" :active-text="$t('app.blocked')" />
          <status-badge v-if="canAddConnection && added" :active-text="$t('app.added')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GIcon from '@/components/GIcon'
import {
  computed,
  getCurrentInstance,
  readonly,
  ref,
} from '@vue/composition-api'
import StatusBadge from '@/components/StatusBadge'

const keyClasses = {
  wraperClasses: {
    lg: 'tw-px-6 tw-py-10 sm:tw-px-12 sm:tw-pt-14 sm:tw-pb-10',
    xs: 'tw-px-4 tw-py-6 sm:tw-px-6 sm:tw-pt-10 sm:tw-pb-8',
  },
  imgClasses: {
    lg: 'xl:tw-w-36 xl:tw-h-36',
    xs: 'xl:tw-w-16 xl:tw-h-16',
  },
  fullNameTitle: {
    lg: 'tw-text-3xl',
    xs: 'tw-text-lg',
  },
  jobTitle: {
    lg: 'tw-text-xl',
    xs: 'tw-text-sm',
  },
  companyTitle: {
    lg: 'tw-text-xl',
    xs: 'tw-text-sm',
  },
  infoSpacerClasses: { lg: 'tw-mt-16', xs: 'tw-mt-8' },
  defaultFieldsSpacerClasses: {
    lg: 'tw-space-y-5',
    xs: 'tw-space-y-2',
  },
  defaultFieldsIconClasses: {
    lg: 'tw-w-8 tw-h-8',
    xs: 'tw-w-5 tw-h-5',
  },
  defaultFieldsTextClasses: {
    lg: 'tw-text-lg',
    xs: 'tw-text-sm',
  },
}

import { select_countries, select_countries_en } from '@/utils/countries'
const countries = {
  es: select_countries,
  en: select_countries_en,
}
export default {
  components: {
    GIcon,
    StatusBadge,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    size: {
      type: String,
      default: 'lg',
      validator(value) {
        return ['lg', 'xs'].includes(value)
      },
    },
    canAddConnection: {
      type: Boolean,
      default: true,
    },
    canClose: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const loading = ref(false)
    const loading_blocked = ref(false)

    const wraperClasses = keyClasses.wraperClasses[props.size]
    const imgClasses = keyClasses.imgClasses[props.size]
    const fullNameTitle = keyClasses.fullNameTitle[props.size]
    const jobTitle = keyClasses.jobTitle[props.size]
    const companyTitle = keyClasses.companyTitle[props.size]
    const infoSpacerClasses = keyClasses.infoSpacerClasses[props.size]
    const defaultFieldsSpacerClasses =
      keyClasses.defaultFieldsSpacerClasses[props.size]
    const defaultFieldsIconClasses =
      keyClasses.defaultFieldsIconClasses[props.size]
    const defaultFieldsTextClasses =
      keyClasses.defaultFieldsTextClasses[props.size]

    const userCard = computed(() => ({
      ...props.user,
      full_name: `${props.user.first_name} ${props.user.last_name}`,
    }))
    const user = computed(() => {
      return vm.$store.state.session.user
    })
    const isNotUser = computed(() => {
      return user.value.roles.length > 1
    })
    const forms = computed(() => {
      return vm.$store.state.event.constants.registry.forms
    })
    const current_language_key = computed(() => {
      return vm.$store.state.registry.show.current_language_key
    })
    // user current lang / last form registry access
    // form users => is_public hash|map

    const current_form = computed(() => {
      return forms.value.find(
        (row) => row.language.name === current_language_key.value,
      )
    })

    // Country Format
    const countries_rows = countries[current_language_key.value]

    const current_attendee = computed(() => {
      // Es
      const found = userCard.value.attendees.find(
        (row) => row.registry_form_id === current_form.value.id,
      )
      if (found) {
        // return found
        return {
          ...found,
          country_name: countries_rows.find((row) => row.value === found.country_code)?.text,
        }
      }
      // En
      const enFound = userCard.value.attendees.map((row) => {
        return {
          ...row,
          country_name: countries_rows.find((ct) => ct.value === row.country_code)?.text,
        }
      })

      return enFound[0]
    })

    const default_fields = computed(() => {
      return current_form.value.default_fields
    })
    const fields = computed(() => {
      return current_form.value.fields
    })
    const default_fields_look_up = computed(() => {
      const read_default_fields = readonly(default_fields)
      return read_default_fields.value.reduce((acc, curr) => {
        const is_viewable = isNotUser.value && curr.is_active
        acc[curr.name] = { ...curr, is_viewable: curr.is_public || is_viewable }
        return acc
      }, {})
    })

    const userContactList = computed(() => {
      return vm.$store.state.event.constants.event_connections.map(
        (row) => row.event_user_id,
      )
    })

    const added = computed(() => {
      return userContactList.value.includes(props.user.id)
    })
    // console.log(forms.value)
    // console.log(current_language_key.value)
    // console.log(current_form.value)

    // Verify blocked user
    const usersBlockRegistry = computed(() => {
      return vm.$store.state.event.constants.event_users_block.map(
        (row) => row.target_string,
      )
    })
    const blocked = computed(() => {
      return usersBlockRegistry.value.includes(props.user.email)
    })

    const isAdmin = computed(() => {
      return vm.$store.state.session.user.roles.map((row) => row.name).includes('admin')
    })

    function openChat(user) {
      this.$store.commit('chat/setState', {
        showChat: true,
        view: 'HOME',
      })
      this.$nextTick().then(() => {
        this.$store.commit('chat/setState', {
          showChat: true,
          view: 'CHAT',
          talking_to: user,
        })
      })
      this.$store.commit('event/setCard', {
        modal: false,
      })
      this.$store.commit('event/setProfile', {
        modal: false,
      })
    }

    return {
      isAdmin,
      added,
      blocked,
      wraperClasses,
      imgClasses,
      fullNameTitle,
      jobTitle,
      companyTitle,
      infoSpacerClasses,
      defaultFieldsSpacerClasses,
      defaultFieldsIconClasses,
      defaultFieldsTextClasses,
      userCard,
      loading,
      loading_blocked,
      isNotUser,
      closeBtn() {
        emit('close-btn', false)
      },

      addContact(user) {
        loading.value = true
        vm.$store
          .dispatch('event/createEventConnection', {
            event_user_id: user.id,
          })
          .then(() => {
            // this.check = true
          })
          .catch((err) => {
            vm.$store.dispatch('notifications/addNotification', {
              title: 'Error añadiendo contacto',
              body: err,
            })
          })
          .finally(() => (loading.value = false))
      },
      current_attendee,
      default_fields,
      default_fields_look_up,
      fields: fields ? fields : [],
      // TODO: Block Contact
      blockContact(user) {
        loading_blocked.value = true
        vm.$store
          .dispatch('event/accessControlStore', {
            payload: {
              access_list_type: 'black_list',
              control_type: 'email',
              target_string: user.email,
            },
          })
          .finally(() => (loading_blocked.value = false))
      },
      openChat,
    }
  },
}
</script>

<style>
.fcb {
  align-items: center;
  padding-bottom: 1rem !important;
}

.fcb p {
  margin: 0;
  color: #b4b4b4;
}

.libzd {
  display: flex;
  align-items: center;
  padding-bottom: .5rem;
}

.pt_bzd {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
</style>
