<template>
  <v-app-bar v-if="isIn" app color="white">
    <v-app-bar-nav-icon @click.stop="leftDrawer = !leftDrawer" />
    <!-- <v-avatar
        :color="$vuetify.breakpoint.smAndDown ? 'grey darken-1' : 'transparent'"
        :color="$vuetify.breakpoint.mdAndDown ? 'grey darken-1' : 'transparent'"
        size="32" logo-on-small hidden-sm-and-up
      ></v-avatar> -->
    <!-- <div
      class="tw-flex tw-items-center tw-justify-between tw-w-full lg:tw-inline-flex lg:tw-justify-items-auto"
    > -->
    <!-- <div class="tw-mr-auto">
      <div class="tw-flex">
        <v-btn @click.prevent="drawer = !drawer" rounded class="accent" dark>
          <v-icon class="tw-block">menu</v-icon></v-btn
        >
      </div>
      <div class="tw-flex">
        <v-btn
          @click.prevent="leftDrawer = !leftDrawer"
          rounded
          class="accent"
          dark
        >
          <v-icon class="tw-block">menu</v-icon></v-btn
        >
      </div>
    </div> -->
    <v-spacer />
    <!-- sm:tw-mr-8 -->
    <div class="tw-flex tw-py-4 sm:tw-hidden">
      <v-img
        :src="require('@/assets/logo-bizzdome.svg')"
        max-height="50"
        max-width="150"
        contain
        :position="$vuetify.breakpoint.mdAndDown ? 'center' : 'center'"
        class="tw-mx-auto"
      >
        <template #placeholder>
          <v-row
            class="fill-height ma-0 transparent"
            align="center"
            :justify="$vuetify.breakpoint.mdAndDown ? 'start' : 'center'"
          >
            <v-progress-circular size="32" indeterminate color="primary" />
          </v-row>
        </template>
      </v-img>
      <!-- </div> -->
    </div>

    <v-spacer />
    <!-- <div class="tw-hidden lg:tw-flex"> -->
    <!-- </div> -->
    <!-- <div class="tw-ml-auto">
      <div class="tw-flex">
        <v-btn @click.prevent="drawer = !drawer" rounded class="accent" dark>
          <v-icon class="tw-block">menu</v-icon></v-btn
        >
      </div>
      <div class="tw-flex">
        <v-btn
          @click.prevent="leftDrawer = !leftDrawer"
          rounded
          class="accent"
          dark
        >
          <v-icon class="tw-block">menu</v-icon></v-btn
        >
      </div>
    </div> -->

    <!-- <v-btn icon>
      <v-icon>help</v-icon>
    </v-btn> -->
    <v-menu
      offset-y
      transition="scale-transition"
      content-class="tw-py-1 tw-mt-2 tw-bg-white tw-rounded-md tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-z-10"
    >
      <template #activator="{ on, attrs }">
        <button
          v-ripple
          type="button"
          class="tw-flex tw-items-center tw-rounded-full tw-bg-green-700 tw-p-1 tw-text-sm tw-ring-2 tw-ring-white tw-ring-opacity-20 focus:tw-outline-none focus:tw-ring-opacity-100 sm:tw-pr-3.5"
          aria-haspopup="true"
          v-bind="attrs"
          v-on="on"
        >
          <span class="tw-sr-only">Open user menu</span>
          <v-img
            :src="user.avatar"
            class="tw-h-8 tw-w-8 tw-rounded-full tw-object-cover"
            contain
          >
            <template #placeholder>
              <v-row
                class="fill-height ma-0 transparent"
                align="center"
                justify="center"
              >
                <v-progress-circular size="24" indeterminate color="primary" />
              </v-row>
            </template>
          </v-img>
          <span
            class="tw-ml-2 tw-hidden tw-text-base tw-font-semibold tw-text-white sm:tw-flex"
          >
            {{ $t('app.roles.admin') }}
          </span>
        </button>
      </template>
      <v-list class="tw-z-10">
        <v-list-item
          :to="{ name: 'admin.users.show', params: { uuid: user.uuid } }"
        >
          <v-list-item-title>
            {{ $t('pages.event.container.upper_nav.user_dropdown.profile') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title>{{ $t('app.logout') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
    <!-- </div> -->
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    links: [
      { label: 'Inicio', route: '/' },
      { label: 'Jugar', route: '/games' },
      { label: 'Actividades', route: '/activities' },
      { label: 'Artistas', route: '/artists' },
    ],
  }),
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        return this.$store.commit('drawer', { bool: val })
      },
    },
    leftDrawer: {
      get() {
        return this.$store.state.leftDrawer
      },
      set(val) {
        return this.$store.commit('setLeftDrawer', { bool: val })
      },
    },
    user() {
      return this.$store.state.session.user
    },
    ...mapGetters({
      // onBoardingComplete: "onBoardingComplete",
    }),
    isIn() {
      return this.$store.state.session.active
    },
  },
  methods: {
    addNotification() {
      this.$store.dispatch('notifications/addNotification', {
        title: 'Prueba',
        body: 'Lorem ipsum',
      })
    },
    logout() {
      this.$store.commit('session/setLogoutModal', {
        logoutModal: true,
      })
    },
    // logout() {
    //   // console.log("logout");

    //   this.$store.dispatch('logout')
    // },
  },
}
</script>

<style></style>
