import $axios from '@/axios-instance.js'

class ScheduledEmailLogsServiceClass {
  baseUrl = '/api/v1/admin/scheduled-emails'
  child = 'logs'

  // Read all - findAll
  index(scheduled_email, params) {
    return $axios.get(
      `${this.baseUrl}/${scheduled_email}/${this.child}`,
      params,
    )
  }

  // Read one - show
  show(scheduled_email, id, params) {
    return $axios.get(
      `${this.baseUrl}/${scheduled_email}/${this.child}/${id}`,
      params,
    )
  }
}
export const ScheduledEmailLogsService = new ScheduledEmailLogsServiceClass()
