<template>
  <div>
    <v-dialog
      v-model="modal_body"
      persistent
      scrollable
      max-width="1024px"
      content-class="tw-rounded-3xl"
    >
      <v-card class="tw-rounded-3xl tw-border-0 tw-shadow-md">
        <ModalHeader @close-modal="closeModal">{{ $t('app.title_slider_element') }}</ModalHeader>
        <v-divider />
        <v-card-text class="tw-p-0" style="max-height: 700px">
          <v-responsive class="tw-bg-gray-50">
            <v-container class="tw-bg-gray-50">
              <v-row class="mb-2">
                <!-- Image Desktop - Mobile -->
                <v-col cols="12" class="mx-auto pa-5">
                  <!-- Desktop -->
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="5" class="px-5 pt-5 mx-auto">
                        <div
                          class="
                            tw-flex
                            tw-flex-col
                            tw-items-center
                            tw-justify-between
                            sm:tw-flex-row sm:tw-py-8
                          "
                        >
                          <div>
                            <h4>{{ $t('app.title_slider_desktop') }}</h4>
                            <div
                              class="
                                tw-mb-2
                                tw-h-1
                                tw-w-8
                                tw-rounded-full
                                tw-bg-primary-200
                              "
                            />
                            <span
                              class="
                                tw-font-medium tw-leading-6 tw-text-gray-500
                              "
                            >
                              {{ SectionTypeKeys.main_slider === section ? $t('app.bg_slider_desktop') : $t('app.bg_slider_desktop_two') }}
                            </span>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12" md="7" class="mx-auto pa-5">
                        <v-card
                          class="
                            mx-auto
                            tw-mb-6
                            tw-overflow-hidden
                            tw-rounded-3xl
                            tw-shadow-md
                          "
                        >
                          <v-container class="tw-bg-gray-50 tw-p-5">
                            <v-row>
                              <v-col>
                                <DropzoneOverlayPreview
                                  v-model="form.image_url"
                                  :width="1920"
                                  :height="SectionTypeKeys.main_slider === section ? 840 : 840"
                                  bypass="simple-check"
                                  media-group="registries_landings_slider_slide"
                                />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                  <!-- Mobile -->
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="5" class="px-5 pt-5 mx-auto">
                        <div
                          class="
                            tw-flex
                            tw-flex-col
                            tw-items-center
                            tw-justify-between
                            sm:tw-flex-row sm:tw-py-8
                          "
                        >
                          <div>
                            <h4>{{ $t('app.title_slider_mobile') }}</h4>
                            <div
                              class="
                                tw-mb-2
                                tw-h-1
                                tw-w-8
                                tw-rounded-full
                                tw-bg-primary-200
                              "
                            />
                            <span
                              class="
                                tw-font-medium tw-leading-6 tw-text-gray-500
                              "
                            >
                              {{ $t('app.bg_slider_mobile') }}
                            </span>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12" md="7" class="mx-auto pa-5">
                        <v-card
                          class="
                            mx-auto
                            tw-mb-6
                            tw-overflow-hidden
                            tw-rounded-3xl
                            tw-shadow-md
                          "
                        >
                          <v-container class="tw-bg-gray-50 tw-p-5">
                            <v-row>
                              <v-col>
                                <DropzoneOverlayPreview
                                  v-model="form.mobile_image_url"
                                  :width="1080"
                                  :height="1800"
                                  bypass="simple-check"
                                  media-group="registries_landings_slider_slide"
                                />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <!-- Description -->
                <v-col cols="12" class="mx-auto pa-5">
                  <v-container class="pa-5">
                    <EditorTipTap
                      v-model="form.body"
                      :label="$t('app.description')"
                      :description="$t('admin.events.slider_copy_description')"
                    />
                  </v-container>
                </v-col>
                <!-- Active Call to action -->
                <v-col cols="12" class="mx-auto pa-5">
                  <v-container class="pa-5">
                    <!-- Active Call to action -->
                    <ToggleGroup v-model="form.has_cta">
                      <template #title>{{ $t('app.enable_cta') }}</template>
                      <template #description>
                        {{ $t('app.cta_description_slider') }}
                      </template>
                      <template #extras>
                        <div
                          class="
                            tw-grid tw-grid-cols-1 tw-gap-y-4 tw-gap-x-4
                            sm:tw-grid-cols-6
                          "
                        >
                          <div class="sm:tw-col-span-3">
                            <v-text-field
                              v-model="form.cta_label"
                              outlined
                              rounded
                              :label="$t('admin.app.name')"
                            />
                          </div>
                          <div class="sm:tw-col-span-3">
                            <v-select
                              v-model="form.cta_link_type"
                              :items="types_link"
                              validate-on-blur
                              label="Type"
                              outlined
                              rounded
                            />
                          </div>
                          <div class="sm:tw-col-span-6">
                            <v-text-field
                              v-if="form.cta_link_type == 'external'"
                              v-model="form.cta_link"
                              outlined
                              rounded
                              label="Link"
                            />
                            <v-select
                              v-if="form.cta_link_type == 'internal'"
                              v-model="form.cta_link"
                              :items="internalPages"
                              validate-on-blur
                              label="Type"
                              outlined
                              rounded
                            />
                          </div>
                        </div>
                      </template>
                    </ToggleGroup>
                    <!-- Active Call to action type btn -->
                    <ToggleGroup v-model="form.is_btn_cta">
                      <template #title>{{ $t('app.enable_cta_btn') }}</template>
                      <template #description>
                        {{ $t('app.cta_description_slider_btn') }}
                      </template>
                    </ToggleGroup>
                    <!-- Active Two Buttons Login and Register -->
                    <ToggleGroup v-model="form.has_two_login_and_register">
                      <template #title>{{ $t('app.tw_buttons_login_and_register') }}</template>
                      <template #description>
                        {{ $t('app.tw_buttons_login_and_register_desc') }}
                      </template>
                    </ToggleGroup>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-responsive>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <AppBtn
            type="button"
            :disabled="form_loading"
            color="primary"
            @click="closeModal"
          >
            {{ $t('app.close') }}
          </AppBtn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Active modal -->
    <AppBtn
      color="transparent"
      icon-center="setting"
      fab
      small
      type="button"
      class="tw-text-primary-400"
      @click="modal_body = true"
    />
  </div>
</template>

<script>
import ModalHeader from '@/components/ModalHeader'
import AppBtn from '../App/AppBtn.vue'
import EditorTipTap from '@/components/FormInputs/EditorTipTap.vue'
import ToggleGroup from '../FormInputs/ToggleGroup.vue'
import { internalPages } from '@/models/RegistryLanding'

import DropzoneOverlayPreview from '../DropzoneOverlayPreview.vue'
import { SectionTypeKeys } from '@/enums/SectionTypeKeys'

export default {
  components: {
    ModalHeader,
    AppBtn,
    EditorTipTap,
    ToggleGroup,
    DropzoneOverlayPreview,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    section: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    form_loading: false,
    form_disabled_state: false,
    valid: true,
    modal_body: false,
    internalPages,
    SectionTypeKeys,
  }),
  computed: {
    types_link() {
      return [
        { text: `Internal`, value: 'internal' },
        { text: `External`, value: 'external' },
      ]
    },
    form: {
      get() {
        return new Proxy(this.value, {
          set: (obj, key, value) => {
            this.form = { ...obj, [key]: value }
            return true
          },
        })
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
  methods: {
    closeModal() {
      this.modal_body = false
    },
  },
}
</script>

<style></style>
