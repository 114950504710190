<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="1366px"
    content-class="tw-rounded-3xl tw-w-full"
  >
    <v-card
      id="EventRoomContentElementIframeModal"
      class="tw-relative tw-rounded-3xl tw-border-0 tw-shadow-md"
    >
      <!-- <modal-header>{{ iframeItemUrl }}</modal-header> -->
      <!-- <v-divider /> -->
      <!-- style="max-height: 768px" -->
      <v-card-text class="tw-p-0">
        <v-responsive class="tw-py-0">
          <v-container id="iframe-wrapper" class="tw-bg-gray-100 tw-p-0" fluid>
            <!-- v-if="iframeItemType == 'link'" -->
            <!-- <iframe
              ref="iframe"
              :src="contentItemData.download_item_url"
              frameborder="1"
              class="tw-w-full"
              allow="camera *; geolocation *; microphone *; autoplay *;fullscreen *"
              sandbox="allow-scripts allow-same-origin allow-scripts allow-popups allow-forms allow-pointer-lock"
            /> -->
            <!-- <object
              class="tw-w-full"
              :data="contentItemData.download_item_url"
              type="application/pdf"
            >
              <embed
                :src="contentItemData.download_item_url"
                type="application/pdf"
              />
            </object> -->
            <!-- id="fullScreenWindow" -->
            <iframe
              ref="iframe"
              :src="iframeLink"
              class="iframe"
              allowfullscreen="allowfullscreen"
              webkitallowfullscreen="webkitAllowFullScreen"
              mozallowfullscreen="mozallowfullscreen"
              frameborder="none"
              height="100%"
              width="100%"
              allow="camera; microphone"
              allowusermedia="allowusermedia"
            />
            <!-- <iframe
              v-else
              ref="iframe_srcdoc"
              :srcdoc="iframeItemHtml"
              frameborder="0"
              class="tw-w-full"
            /> -->
            <!-- :height="iframe.height" -->
          </v-container>
        </v-responsive>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          rounded
          :disabled="form_loading"
          class="tw-px-8 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-white"
          @click="closeModal"
        >
          {{ $t('app.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ContentTypeKeys } from '@/enums/ContentTypeKeys'
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loaded: false,
    iframe: {
      height: '800px',
      style: null,
      wrapperStyle: null,
    },
    form_loading: false,
    form_disabled_state: false,
    valid: true,
  }),
  computed: {
    ...mapState({
      contentItemData: (state) => state.event.contentItem.contentItemData,
    }),
    iframeLink() {
      if (ContentTypeKeys.download == this.contentItemData.content_type.name) {
        return this.contentItemData.download_item_url
      }
      return this.contentItemData.link_item_url
    },
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  mounted() {
    // console.log('settings ', this.settings)
    // let editor = this.$refs.editor
    // this.iframe.style = {
    //   position: 'absolute',
    //   width: window.innerWidth,
    //   height: window.innerHeight,
    //   top: -editor.offsetTop + 'px',
    //   left: -editor.offsetLeft + 'px',
    // }
    // this.iframe.wrapperStyle = {
    //   overflow: 'hidden',
    //   height: editor.clientHeight + 'px',
    //   width: editor.clientWidth + 'px',
    // }
    // this.loaded = true
    // var e = this;
    setTimeout(() => {
      console.log('log')
      // console.log(this.$refs.iframe.contentWindow.bodyHeight)
      // this.$refs.iframe.style.height = ''.concat(
      //   this.$refs.iframe.contentWindow.bodyHeight,
      //   'px',
      // )
      if (this.$refs.iframe) {
        this.$refs.iframe.style.height = '800px'
        this.$refs.iframe.style.opacity = '1'
        this.$refs.iframe.parentElement.style.height = 'auto'
      } else {
        console.log(this.$refs.iframe_srcdoc.contentWindow.bodyHeight)
        this.$refs.iframe_srcdoc.style.height =
          this.$refs.iframe_srcdoc.contentWindow.bodyHeight + 'px'
        this.$refs.iframe_srcdoc.style.opacity = '1'
        this.$refs.iframe_srcdoc.parentElement.style.height = 'auto'
      }
    }, 1000)
    // window.addEventListener("message", (function(t) {
    //     var n = t.data;
    //     "BODY_HEIGHT" === n.type && n.name === e.$refs.iframe.name && (e.$refs.iframe.style.height = "".concat(n.height, "px"),
    //     e.$refs.iframe.style.opacity = "1",
    //     e.$refs.iframe.parentElement.style.height = "auto")
    // }
    // )),
    // this.onResize = this.onResize.bind(this),
    // this.onResizeEnd = this.onResizeEnd.bind(this)
  },

  methods: {
    closeModal() {
      this.open = false
    },
  },
}
</script>

<style></style>
