import { UserBase } from '@/models/User'
import { UserAttendeesService } from '@/services/UserAttendeesService'
import { UserLoginsService } from '@/services/UserLoginsService'
import { UsersServiceInstance } from '@/services/UsersService'
import { formErrorsHandler } from '@/utils/formErrorsHandler'

// state
const initState = () => {
  return {
    create: {
      hasErrors: false,
      errors: {},
      data: UserBase({}),
    },
    show: {
      isLoading: true,
      data: UserBase({}),
    },
    update: {
      data: UserBase({}),
    },
    attendees: {
      show: {
        isLoading: true,
        data: {},
      },
    },
  }
}

// getters
const getters = {
  value: (state) => {
    return state.value
  },
}

// actions
const actions = {
  indexAttedees({ state }, payload) {
    const { uuid } = state.show.data
    return UserAttendeesService.index(uuid, payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  indexLogins(_, { payload, user }) {
    const { uuid } = user
    return UserLoginsService.index(uuid, payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  store({ commit, dispatch }, { payload }) {
    // const roles = state.create.data.roles
    commit('setAppIsLoading', true, { root: true })
    return UsersServiceInstance.store(payload)
      .then((res) => {
        dispatch('constants/loadUsers', false, { root: true })
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },

  storeAttedees({ state, commit }, { payload }) {
    const { uuid } = state.show.data
    commit('setAppIsLoading', true, { root: true })
    return UserAttendeesService.store(uuid, payload)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  updateAttedees({ commit, state }, { payload }) {
    const { uuid } = state.show.data
    const { id } = state.attendees.show.data
    commit('setAppIsLoading', true, { root: true })
    return UserAttendeesService.update(uuid, id, payload)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  update({ state, commit, dispatch }, { payload = {} }) {
    // const form = {
    //   ...state.update.data,
    //   ...body,
    // }
    const { uuid } = state.show.data
    commit('setAppIsLoading', true, { root: true })
    return UsersServiceInstance.update(uuid, payload)
      .then((res) => {
        dispatch('constants/loadUsers', false, { root: true })
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  setUpdateFormFromDB({ commit }, { user }) {
    const { first_name, email, last_name, notes, roles: user_roles } = user
    const roles = user_roles.map((row) => row.name)
    commit(
      'setUpdate',
      UserBase({ email, first_name, last_name, notes, roles }),
    )
  },
  show({ commit, dispatch }, { uuid, loading = false, appLoading = false }) {
    if (loading) {
      commit('setShow', { isLoading: true })
    }
    if (appLoading) {
      commit('setAppIsLoading', true, { root: true })
    }
    return UsersServiceInstance.show(uuid)
      .then(({ data: { user } }) => {
        commit('setShow', { data: user })
        dispatch('setUpdateFormFromDB', { user })
      })
      .catch((err) => {
        console.log(formErrorsHandler(err))
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setShow', { isLoading: false })
        commit('setAppIsLoading', false, { root: true })
      })
  },
  showAttendee(
    { commit },
    { uuid, attendee, loading = false, appLoading = false },
  ) {
    if (loading) {
      commit('setAttendeesShow', { isLoading: true })
    }
    if (appLoading) {
      commit('setAppIsLoading', true, { root: true })
    }
    return UserAttendeesService.show(uuid, attendee)
      .then(({ data: { data } }) => {
        commit('setAttendeesShow', { data: data })
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAttendeesShow', { isLoading: false })
        commit('setAppIsLoading', false, { root: true })
      })
  },
  updatePassword({ commit }, { uuid = '', body = {} }) {
    commit('setAppIsLoading', true, { root: true })
    return UsersServiceInstance.storeNewPassword(uuid, body)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  destroy({ commit }, { uuid = '' }) {
    commit('setAppIsLoading', true, { root: true })
    return UsersServiceInstance.destroy(uuid)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
}

// mutations
const mutations = {
  cleanCreate(state) {
    state.create.data = UserBase({})
  },
  setShow(state, obj) {
    Object.keys(obj).forEach((k) => (state.show[k] = obj[k]))
  },
  setAttendeesShow(state, obj) {
    Object.keys(obj).forEach((k) => (state.attendees.show[k] = obj[k]))
  },
  setCreate(state, obj) {
    Object.keys(obj).forEach((k) => (state.create.data[k] = obj[k]))
  },
  setUpdate(state, obj) {
    Object.keys(obj).forEach((k) => (state.update.data[k] = obj[k]))
  },
}

export const Users = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
}
