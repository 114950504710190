<template>
  <EventRoomContentElementChat
    class="tw-overflow-hidden tw-rounded-3xl"
    block
    :parrent-channel-id="chatElement.channel_id"
    :can-chat="chatElement.can_chat"
    :can-thread="chatElement.can_thread"
    :parrent-thread-id="chatElement.thread_id"
  />
</template>

<script>
import EventRoomContentElementChat from './EventRoomContentElementChat.vue'
export default {
  components: {
    EventRoomContentElementChat,
  },
  props: {
    element: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    chatElement() {
      const channel_id =
        this.element.channels.length > 0 ? this.element.channels[0].id : 0
      const thread_id =
        this.element.threads.length > 0 ? this.element.threads[0].id : 0
      return {
        channel_id,
        can_chat: !!channel_id,
        can_thread: !!thread_id,
        thread_id,
      }
    },
  },
}
</script>
