<template>
  <div class="tw-relative">
    <img
      :key="validSlide.uid + '-image-desktop'"
      aria-hidden="true"
      class="tw-hidden tw-h-full tw-w-full tw-object-cover sm:tw-block slide-img-dsk"
      :src="validSlide.image_url"
    />
    <img
      :key="validSlide.uid + '-image-mobile'"
      aria-hidden="true"
      class="tw-w-full tw-object-cover sm:tw-hidden slide-img-mbl"
      :src="validSlide.mobile_image_url"
    />

    <!-- Contenido del Slide -->
    <div v-if="validSlide.body" class="slide-ot-detail">
      <div class="tw-mx-auto tw-max-w-7xl">
        <div class="tw-mx-auto tw-mt-6 tw-max-w-lg tw-text-center tw-text-lg tw-text-white md:tw-max-w-3xl" v-html="validSlide.body" />
      </div>
    </div>

    <!-- Sección CTA -->
    <div v-if="validSlide.has_two_login_and_register" class="bz-section-two-btn">
      <div class="tw-flex tw-justify-center tw-flex-col lg:tw-flex-row tw-space-y-4 lg:tw-space-y-0 lg:tw-space-x-6 bz-buttons-two">
        <!-- Login -->
        <v-btn
          rounded
          class="bz-btn-login"
          :to="resolveInternalLink('login')"
        >
          {{ $t('app.login') }}
        </v-btn>
        <!-- Register -->
        <v-btn
          rounded
          class="bz-btn-register"
          :to="resolveInternalLink('register')"
        >
          {{ $t('app.register') }}
        </v-btn>
      </div>
    </div>
    <div v-if="validSlide.has_cta" class="bz-buttons tw-absolute tw-top-0 tw-w-full tw-h-full">
      <template v-if="validSlide.is_btn_cta">
            <!-- Botón CTA -->
            <v-btn
              v-if="validSlide.cta_link_type === 'external'"
              rounded
              :href="validSlide.cta_link"
              :class="`bz-btn-${validSlide.cta_link_type}`"
              target="_blank"
            >
              {{ validSlide.cta_label }}
            </v-btn>
            <v-btn
              v-else
              rounded
              :class="`bz-btn-${validSlide.cta_link_type}`"
              :to="resolveInternalLink(validSlide.cta_link)"
            >
              {{ validSlide.cta_label }}
            </v-btn>
      </template>
      <template v-else>
        <!-- Enlace que cubre toda la imagen -->
        <a v-if="validSlide.cta_link_type === 'external'" :href="validSlide.cta_link" target="_blank" class="overlay_image_link" />
        <router-link v-else :to="resolveInternalLink(validSlide.cta_link)" class="overlay_image_link" />
      </template>
    </div>
      
  </div>
</template>

<script>
import { validateSectionSliderSlide } from '@/models/RegistryLanding'

export default {
  props: {
    slug: {
      type: String,
      default: '',
    },
    slide: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    validSlide() {
      return validateSectionSliderSlide(this.slide)
    },
  },
  methods: {
    resolveInternalLink(key) {
      const params = { slug: this.slug }
      return {
        register: { name: 'registry.register', params },
        login: { name: 'registry.login', params },
      }[key] || { name: 'registry.register', params }
    },
  },
}
</script>