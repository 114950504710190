<template>
  <!-- tw-scale-100 group-hover:tw-scale-105 tw-transform -->
  <div
    v-if="type == 'simple'"
    class="tw-group tw-pointer-events-auto tw-transform tw-cursor-pointer tw-rounded-lg tw-bg-white tw-shadow-md tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-transition-all tw-duration-300"
  >
    <div class="tw-shadow-xs tw-overflow-hidden tw-rounded-lg">
      <div class="tw-p-4">
        <div class="tw-flex tw-items-start">
          <div class="tw-flex-shrink-0">
            <svg
              v-if="status === NotificationStatusKeys.success"
              class="tw-h-6 tw-w-6 tw-text-green-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <svg
              v-if="status === NotificationStatusKeys.error"
              xmlns="http://www.w3.org/2000/svg"
              class="tw-h-6 tw-w-6 tw-text-red-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div class="tw-ml-3 tw-w-0 tw-flex-1 tw-pt-0.5">
            <p
              class="tw-mb-0 tw-text-sm tw-font-medium tw-leading-5 tw-text-slate-900"
            >
              {{ title }}
            </p>
            <p
              class="tw-mt-1 tw-mb-0 tw-text-sm tw-leading-5 tw-text-slate-500"
            >
              <span class="tw-font-semibold tw-text-slate-600" v-html="body" />
            </p>
            <div v-if="hasClickable" class="tw-mt-3 tw-flex tw-space-x-7">
              <RenderlessClickableHandle>
                <template slot-scope="{ slotClickActionEvent }">
                  <AppBtn
                    color="primary"
                    @click="
                      () => {
                        $emit('remove-item', id)
                        slotClickActionEvent({ clickable })
                      }
                    "
                  >
                    {{ actionLabel }}
                  </AppBtn>
                </template>
              </RenderlessClickableHandle>
            </div>
          </div>
          <div class="tw-ml-4 tw-flex tw-flex-shrink-0">
            <span
              class="tw-inline-flex tw-text-slate-400 tw-transition tw-duration-150 tw-ease-in-out focus:tw-text-slate-500 focus:tw-outline-none"
              @click="$emit('remove-item', id)"
            >
              <svg
                class="tw-h-5 tw-w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else-if="type == 'msg'"
    class="tw-group tw-divide-x-1 tw-pointer-events-auto tw-flex tw-w-full tw-max-w-md tw-transform tw-divide-gray-200 tw-rounded-lg tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-transition-all tw-duration-300"
  >
    <div class="tw-w-0 tw-flex-1 tw-p-4">
      <div class="tw-flex tw-items-start">
        <div class="tw-flex-shrink-0 tw-pt-0.5">
          <img
            class="tw-h-10 tw-w-10 tw-rounded-full"
            :src="msgMeta.partner_user.avatar"
            alt=""
          />
        </div>
        <div class="tw-ml-3 tw-w-0 tw-flex-1">
          <p class="tw-mb-0 tw-text-sm tw-font-medium tw-text-gray-900">
            {{ title }}
          </p>
          <p class="tw-mt-1 tw-mb-0 tw-text-sm tw-text-gray-500" v-html="body" />
        </div>
      </div>
    </div>
    <div class="tw-flex tw-border-l tw-border-solid tw-border-gray-200">
      <button
        class="tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded-none tw-rounded-r-lg tw-border tw-border-transparent tw-p-4 tw-text-sm tw-font-medium tw-text-primary-600 hover:tw-text-primary-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500"
        @click="answerMsg"
      >
        Responder
      </button>
    </div>
  </div>
</template>

<script>
import AppBtn from '@/components/App/AppBtn.vue'
import { isObjectEmpty } from '@/utils/isObjectEmpty'
import { ClickableTypeKeys } from '@/enums/ClickableTypeKeys'
import RenderlessClickableHandle from '@/components/Event/RenderlessClickableHandle.vue'
import { NotificationStatusKeys } from '@/enums/NotificationStatusKeys'
export default {
  components: {
    AppBtn,
    RenderlessClickableHandle,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    actionLabel: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    msgMeta: {
      type: Object,
      default: () => ({
        partner_user: { avatar: '' },
        channel_id: 0,
      }),
    },
    clickable: {
      type: Object,
      default: () => ({}),
    },
    body: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    ClickableTypeKeys,
    NotificationStatusKeys,
  }),
  computed: {
    hasClickable() {
      return !isObjectEmpty(this.clickable) && this.clickable
    },
  },
  methods: {
    answerMsg() {
      this.$store.commit('chat/setState', {
        showChat: true,
        view: 'HOME',
      })
      this.$nextTick().then(() => {
        this.$store.commit('chat/setState', {
          showChat: true,
          view: 'CHAT',
          talking_to: this.msgMeta.partner_user,
        })
      })
      this.$store.commit('notifications/notificationsRemove', this.id)
    },
  },
}
</script>

<style></style>
