import gql from 'graphql-tag'
import {
  CorePrivateChannels,
  CORE_USER_FIELDS,
} from '@/graphql/fragments/CoreUserFields'
import { CORE_ATTENDEE_FIELDS } from '../fragments/CoreAttendeeFields'

export const SET_ACTIVE = gql`
  mutation SET_ACTIVE {
    setActive
  }
`

// export const GET_ALL_USERS_QUERY = gql`
//   ${CORE_USER_FIELDS}
//   query getAllUsers {
//     users {
//       ...CoreUserFields
//     }
//   }
// `

// export const GET_ALL_USERS_BY_REGISTRY_QUERY = gql`
//   ${CORE_USER_FIELDS}
//   ${CORE_ATTENDEE_FIELDS}
//   query getUsersByRegistries {
//     getUsersByRegistries {
//       ...CoreUserFields
//       attendees {
//         ...CoreAttendeeFields
//       }
//     }
//     usersParticipatingInMyChannels {
//       user {
//         uuid
//       }
//     }
//   }
// `
// # ${CORE_USER_FIELDS}
// # ${CORE_ATTENDEE_FIELDS}
// # getUsersByRegistries {
// #   ...CoreUserFields
// #   attendees {
// #     ...CoreAttendeeFields
// #   }
// # }
// channelsWhereType(channelType: 3) {
export const GET_ALL_USERS_BY_REGISTRY_QUERY_AND_USERS_IN_DMS = gql`
  ${CorePrivateChannels}
  query getUsersByRegistriesAndInDMS {
    channelsWhereType(channelType: 3, isPrivate: 0) {
      subject
      id
      description
      languages {
        name
      }
      channel_type_id
      is_private
    }
    usersParticipatingInMyChannels {
      ...CorePrivateChannels
    }
    getUsersLocation {
      registry_uuid
      user_id
      slug
    }
  }
`

export const ONLINE_USERS_UPDATE = gql`
  ${CORE_USER_FIELDS}
  ${CORE_ATTENDEE_FIELDS}
  subscription activeUsers {
    activeUsers {
      ...CoreUserFields
      attendees {
        ...CoreAttendeeFields
      }
    }
  }
`
export const onlineUser = gql`
  ${CORE_USER_FIELDS}
  subscription onlineUser {
    onlineUser {
      ...CoreUserFields
    }
  }
`

export const CHECK_CHANNEL = gql`
  mutation createUser($partner_id: Int!) {
    checkChannel(partner_id: $partner_id) {
      id
    }
  }
`
export const firstMsgOnChannel = gql`
  mutation firstMsgOnChannel($partner_id: Int!) {
    firstMsgOnChannel(partner_id: $partner_id)
  }
`

// export const CHECK_PARTICIPANTING_IN = gql`
//   query userParticipatingIn {
//     userParticipatingIn {
//       channel {
//         participants {
//           user {
//             email
//             first_name
//             last_name
//             id
//             uuid
//             avatar
//           }
//         }
//       }
//     }
//   }
// `

export const NEW_USER_IN_DMS = gql`
  subscription newUserParticipatingInMyChannels {
    newUserParticipatingInMyChannels {
      message_count
      channel_id
      user {
        uuid
      }
    }
  }
`
// export const CHECK_PARTICIPANTING_IN_SUB = gql`
//   subscription newChannelParticipating($user_id: Int!) {
//     newChannelParticipating(user_id: $user_id) {
//       channel {
//         participants {
//           user {
//             email
//             first_name
//             last_name
//             id
//             uuid
//             avatar
//           }
//         }
//       }
//     }
//   }
// `
