export const ElementTypeKeys = {
  image: 'image_item',
  doorway: 'doorway_item',
  info: 'info_item',
  video: 'video_item',
  slider: 'slider_item',
  content: 'content_item',
  rrss: 'rrss_item',
  chat: 'chat_item',
  iframe: 'iframe_item',
  click: 'click_item',
  map: 'map_item',
  text: 'text_item',
}
