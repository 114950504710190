import { ChannelMessagesService } from '@/services/ChannelMessagesService'
import ChannelsService from '@/services/ChannelsService'
import { formErrorsHandler } from '@/utils/formErrorsHandler'

// state
const initState = () => {
  return {
    show: {
      isBeingCopied: false,
      isLoading: true,
      data: {
        channel_type: {},
        participants: [],
      },
    },
  }
}

// getters
const getters = {}

// actions
const actions = {
  index(_, payload) {
    return ChannelsService.index(payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  indexMessages({ state }, payload) {
    const channel = state.show.data.id
    return ChannelMessagesService.index(channel, payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  store({ commit }, { body = '' }) {
    commit('setAppIsLoading', true, { root: true })
    return ChannelsService.store(body)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  update({ commit, state }, { payload }) {
    commit('setAppIsLoading', true, { root: true })
    return ChannelsService.update(state.show.data.id, payload)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  show({ commit }, { id, loading = false, appLoading = false }) {
    if (loading) {
      commit('setShow', { isLoading: true })
    }
    if (appLoading) {
      commit('setAppIsLoading', true, { root: true })
    }
    return ChannelsService.show(id)
      .then(({ data: { data } }) => {
        commit('setShow', { data: data })
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setShow', { isLoading: false })
        commit('setAppIsLoading', false, { root: true })
      })
  },
  destroy({ commit }, { id = '' }) {
    commit('setAppIsLoading', true, { root: true })
    return ChannelsService.destroy(id)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
}

// mutations
const mutations = {
  setShow(state, obj) {
    Object.keys(obj).forEach((k) => (state.show[k] = obj[k]))
  },
}

export const Channels = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
}
