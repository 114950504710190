<template>
  <!-- TODO: Departmentst: Select field for the classes -->
  <v-autocomplete
    v-model="input_value"
    :items="items"
    item-text="name"
    item-value="id"
    :label="$t('app.field_type.districts')"
    outlined
    rounded
    clearable
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { districts } from '@/utils/Districts'
export default {
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    search: {
      type: [Number, String],
      default: 0,
    },
  },
  data: () => ({
    districts,
  }),
  computed: {
    input_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    items() {
      if (this.search) {
        return this.districts.filter((row) => row.province_id == this.search)
      } else {
        return this.districts
      }
    },
  },
}
</script>

<style></style>
