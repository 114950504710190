<template>
  <v-container class="pa-5">
    <v-row v-if="canSwitchClickable">
      <v-col cols="12" class="pb-0">
        <h3
          id="renew-subscription-label"
          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
        >
          <!-- ¿Tiene horario de apertura y cierre? -->
          {{ $t('app.enable_cta') }}
        </h3>
        <div class="tw-mt-2 sm:tw-flex sm:tw-items-start sm:tw-justify-between">
          <div class="tw-max-w-xl tw-text-sm tw-text-gray-500">
            <p id="renew-description " class="tw-mb-0">
              <slot name="description">
                {{ $t('app.cta_description') }}
              </slot>
            </p>
          </div>
          <div
            class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
          >
            <!-- Enabled: "tw-bg-indigo-600", Not Enabled: "tw-bg-gray-200" -->
            <v-switch v-model="form.has_clickable" inset large />
            <!-- <v-switch
              :value="form.has_clickable"
              inset
              large
              @change="
                ($event) => updateValue('has_clickable', $event.target.checked)
              "
            /> -->
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="form.has_clickable">
      <v-col cols="12" sm="6" class="pb-0">
        <v-select
          v-model="form.click_action_type"
          :items="click_types"
          :label="$t('app.type')"
          validate-on-blur
          :rules="[rules.required]"
          outlined
          rounded
        />
      </v-col>
      <v-col cols="12" sm="6" class="pb-0">
        <VirtualRoomAutocomplete
          v-if="form.click_action_type == ClickableTypeKeys.link_location"
          v-model="form.link_location_virtual_room_id"
          :rules="[rules.required]"
        />
        <ContentLibraryElementsAutocomplete
          v-if="form.click_action_type == ClickableTypeKeys.link_content"
          v-model="form.link_content_element_id"
          :rules="[rules.required]"
        />
        <ThreadsAutocomplete
          v-if="form.click_action_type == ClickableTypeKeys.open_public_chat"
          v-model="form.link_thread_id"
          :rules="[rules.required]"
        />
        <ChannelsAutocomplete
          v-if="form.click_action_type == ClickableTypeKeys.open_channel"
          v-model="form.link_channel_id"
        />
        <v-text-field
          v-if="form.click_action_type == ClickableTypeKeys.open_external_url"
          v-model="form.external_url_link"
          rounded
          outlined
          validate-on-blur
          label="Url"
          :rules="[rules.required]"
        />
        <PhotoboothConfigsAutocomplete
          v-if="form.click_action_type == ClickableTypeKeys.open_photobooth"
          v-model="form.photobooth_config_id"
          :rules="[rules.required]"
          @change="inputOccurred"
        />
      </v-col>
    </v-row>
    <slot v-if="form.has_clickable" name="extra-fields" />
  </v-container>
</template>

<script>
import VirtualRoomAutocomplete from '@/components/VirtualRoomAutocomplete.vue'
import ContentLibraryElementsAutocomplete from '@/components/ContentLibraryElementsAutocomplete.vue'
import ThreadsAutocomplete from '@/components/ThreadsAutocomplete.vue'
import ChannelsAutocomplete from '../ChannelsAutocomplete.vue'

import { mapGetters } from 'vuex'
import { rules } from '@/utils/rules'
import { ClickableTypeKeys } from '@/enums/ClickableTypeKeys'
import PhotoboothConfigsAutocomplete from '../PhotoboothConfigsAutocomplete.vue'

export default {
  components: {
    VirtualRoomAutocomplete,
    ContentLibraryElementsAutocomplete,
    ThreadsAutocomplete,
    PhotoboothConfigsAutocomplete,
    ChannelsAutocomplete,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
    canSwitchClickable: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    rules,
    hasMounted: false,
    ClickableTypeKeys,
    // form: ClickableBase(vm.clickable),
  }),
  computed: {
    ...mapGetters({
      click_types: ['constants/click_types'],
    }),
    form: {
      // get() {
      //   return this.clickable
      // },
      // set(val) {
      //   console.log({ val })
      //   this.$emit('updated-clickable', { val })
      // },
      get() {
        return new Proxy(this.value, {
          set: (obj, key, value) => {
            this.form = { ...obj, [key]: value }
            // this.$emit('input', { ...obj, [key]: value })
            return true
          },
        })
      },
      set(newValue) {
        // emit('update:modelValue', newValue)

        this.$emit('input', newValue)
      },
    },
    // ...mapParent({
    //   fields: Object.keys(ClickableBase({})),
    //   base: 'clickable',
    //   mutation: 'updated-clickable',
    // }),
    // has_clickable: {
    //   get() {
    //     return this.clickable.has_clickable
    //   },
    //   set(val) {
    //     this.$emit('updated-clickable', { has_clickable: val })
    //   },
    // },
  },
  mounted() {
    if (this.canSwitchClickable) {
      return
    }
    this.form.has_clickable = true
    this.hasMounted = true
  },
  methods: {
    updateValue(key, value) {
      console.log(value)
      this.$emit('input', { ...this.value, [key]: value })
    },
  },
}
</script>

<style></style>
