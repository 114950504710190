<template>
  <!-- TODO: Departmentst: Select  -->
  <v-autocomplete
    v-model="input_value"
    :items="departments"
    item-text="name"
    item-value="id"
    :label="$t('app.field_type.departments')"
    outlined
    rounded
    clearable
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { departments } from '@/utils/Departments'
export default {
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    search: {
      type: [Number, String],
      default: 0,
    },
  },
  data: () => ({
    departments,
  }),
  computed: {
    input_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>

<style></style>
