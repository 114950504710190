import $axios from '@/axios-instance.js'

class RegistriesConfigsServiceClass {
  baseUrl = '/api/v1/admin/registries'
  child = 'configs'

  // Create
  store(event_config, params) {
    return $axios.post(`${this.baseUrl}/${event_config}/${this.child}`, params)
  }
}
export const RegistriesConfigsService = new RegistriesConfigsServiceClass()
