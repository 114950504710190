<template>
  <renderless-layout>
    <v-container fluid class="tw-p-0">
      <StickyHeader :nav="nav">
        <template #actions>
          <div class="tw-flex tw-items-center tw-space-x-4">
            <AppBtn icon="check" color="white" type="button" @click="save">
              {{ $t('app.save') }}
            </AppBtn>
          </div>
        </template>
      </StickyHeader>
      <v-row class="tw-m-0 tw-p-0">
        <v-col cols="12" class="tw-mx-auto tw-container tw-max-w-7xl">
          <v-container fluid class="tw-space-y-6">
            <v-row>
              <v-col cols="12" md="9">
                <div
                  class="
                    tw-p-4
                    tw-mb-4
                    tw-bg-transparent
                    tw-flex
                    tw-items-center
                    tw-justify-center
                  "
                >
                  <CertificateCard
                    :config="data"
                    :user-form="userForm"
                    :checkin-user="user"
                  />
                </div>
                <v-card>
                  <div class="tw-h-2 tw-bg-primary-500 tw-px-3 tw-py-0" />
                  <div class="tw-px-2 tw-py-2 tw-font-bold">
                    {{
                      $t('admin.events.certificates.items.labels.info.label')
                    }}
                  </div>
                  <div class="tw-p-4">
                    <v-row>
                      <!-- Title -->
                      <v-col cols="4">
                        <v-textarea
                            v-model="userForm.title_text"
                            class="tw-text-xs"
                            filled
                            dense
                            :label="$t('admin.events.certificates.items.labels.info.title')"
                            rows="1"
                        />
                        <ColorInput
                            v-model="userForm.title_color"
                            :is-outlined="false"
                            :is-rounded="false"
                            :is-filled="true"
                        />
                        <v-slider
                            v-model="userForm.title_size"
                            color="primary"
                            :label="$t('admin.events.certificates.items.labels.size')"
                            min="1"
                            max="100"
                            thumb-label
                        />
                      </v-col>
                      <!-- Subtitle -->
                      <v-col cols="4">
                        <v-textarea
                            v-model="userForm.to_text"
                            class="tw-text-xs"
                            filled
                            dense
                            :label="$t('admin.events.certificates.items.labels.info.subtitle')"
                            rows="1"
                        />
                        <ColorInput
                            v-model="userForm.to_color"
                            :is-outlined="false"
                            :is-rounded="false"
                            :is-filled="true"
                        />
                        <v-slider
                            v-model="userForm.to_size"
                            color="primary"
                            :label="$t('admin.events.certificates.items.labels.size')"
                            min="1"
                            max="100"
                            thumb-label
                        />
                      </v-col>
                      <!-- Description -->
                      <v-col cols="4">
                        <v-textarea
                            v-model="userForm.description_text"
                            class="tw-text-xs"
                            filled
                            dense
                            :label="$t('admin.events.certificates.items.labels.info.description')"
                            rows="1"
                        />
                        <ColorInput
                            v-model="userForm.description_color"
                            :is-outlined="false"
                            :is-rounded="false"
                            :is-filled="true"
                        />
                        <v-slider
                            v-model="userForm.description_size"
                            color="primary"
                            :label="$t('admin.events.certificates.items.labels.size')"
                            min="1"
                            max="100"
                            thumb-label
                        />
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <!-- TODO: Background -->
                <v-card class="tw-shadow-md tw-mb-4">
                  <div class="tw-h-2 tw-bg-primary-500 tw-px-3 tw-py-0" />
                  <div class="tw-px-2 tw-py-2 tw-font-bold">
                    {{
                      $t('admin.events.certificates.items.labels.background')
                    }}
                  </div>
                  <ColorInput
                    v-model="data.ui_background"
                    :is-outlined="false"
                    :is-rounded="false"
                    :is-filled="false"
                    class="tw-px-4 tw-py-2"
                  />
                </v-card>
                <v-card class="tw-shadow-md tw-mb-4 tw-p-4">
                  <DropzoneOverlayPreview
                    v-model="data.url_background"
                    bypass="simple-check"
                    :width="842"
                    :height="595"
                    media-group="background_certificate"
                  />
                </v-card>
                <!-- Fullname -->
                <v-card class="tw-shadow-md tw-mb-4">
                    <div class="tw-h-2 tw-bg-primary-500 tw-px-3 tw-py-0" />
                    <div class="tw-px-2 tw-py-2 tw-font-bold">
                        {{
                        $t('admin.events.certificates.items.labels.fullname')
                        }}
                    </div>
                    <div class="tw-p-4">
                        <ColorInput
                            v-model="userForm.fullname_color"
                            :is-outlined="false"
                            :is-rounded="false"
                            :is-filled="true"
                        />
                        <v-slider
                            v-model="userForm.fullname_size"
                            color="primary"
                            :label="$t('admin.events.certificates.items.labels.size')"
                            min="1"
                            max="100"
                            thumb-label
                        />
                    </div>
                </v-card>
                <!-- TODO: Logo -->
                <v-card class="tw-shadow-md tw-mb-4">
                  <div class="tw-h-2 tw-bg-primary-500 tw-px-3 tw-py-0" />
                  <div class="tw-px-2 tw-py-2 tw-font-bold">
                    {{
                      $t('admin.events.certificates.items.labels.logos.label')
                    }}
                  </div>
                  <v-sheet class="tw-px-4 tw-py-2">
                    <!-- TODO: Logo 1 -->
                    <ToggleGroup v-model="userForm.logo_pry_is_active">
                      <template #title>
                        {{
                          $t(
                            'admin.events.certificates.items.labels.logos.logo_primary',
                          )
                        }}
                      </template>
                    </ToggleGroup>
                    <transition name="fade-transition" mode="out-in">
                      <div
                        v-if="userForm.logo_pry_is_active"
                        class="tw-bg-gray-100 tw-px-2 tw-py-4"
                      >
                        <v-slider
                            v-model="userForm.logo_pry_size"
                            color="primary"
                            :label="$t('admin.events.certificates.items.labels.size')"
                            min="1"
                            max="600"
                            thumb-label
                        />
                        <DropzoneOverlayPreview
                          v-model="userForm.logo_pry_url"
                          bypass="simple-check"
                          :width="200"
                          :height="200"
                          media-group="logo_certificate"
                        />
                      </div>
                    </transition>
                    <!-- TODO: Logo 2 -->
                    <ToggleGroup v-model="userForm.logo_sec_is_active">
                      <template #title>
                        {{
                          $t(
                            'admin.events.certificates.items.labels.logos.logo_second',
                          )
                        }}
                      </template>
                    </ToggleGroup>
                    <transition name="fade-transition" mode="out-in">
                      <div
                        v-if="userForm.logo_sec_is_active"
                        class="tw-bg-gray-100 tw-px-2 tw-py-4"
                      >
                        <v-slider
                            v-model="userForm.logo_sec_size"
                            color="primary"
                            :label="$t('admin.events.certificates.items.labels.size')"
                            min="1"
                            max="600"
                            thumb-label
                        />
                        <DropzoneOverlayPreview
                          v-model="userForm.logo_sec_url"
                          bypass="simple-check"
                          :width="200"
                          :height="200"
                          media-group="logo_certificate"
                        />
                      </div>
                    </transition>
                  </v-sheet>
                </v-card>
                <!-- TODO: Firmas -->
                <v-card class="tw-shadow-md">
                  <div class="tw-h-2 tw-bg-primary-500 tw-px-3 tw-py-0" />
                  <div class="tw-px-2 tw-py-2 tw-font-bold">
                    {{
                      $t(
                        'admin.events.certificates.items.labels.signatures.label',
                      )
                    }}
                  </div>
                  <v-sheet class="tw-px-4 tw-py-2">
                    <!-- TODO: Signature 1 -->
                    <ToggleGroup v-model="userForm.firma_pry_is_active">
                      <template #title>
                        {{
                          $t(
                            'admin.events.certificates.items.labels.signatures.signature_primary',
                          )
                        }}
                      </template>
                    </ToggleGroup>
                    <transition name="fade-transition" mode="out-in">
                      <div
                        v-if="userForm.firma_pry_is_active"
                        class="tw-bg-gray-100 tw-px-2 tw-py-4"
                      >
                        <v-text-field
                          v-model="userForm.firma_pry_name"
                          :label="$t('admin.events.certificates.items.labels.signatures.name')"
                          dense
                          class="tw-text-xs"
                        />
                        <v-text-field
                          v-model="userForm.firma_pry_detail"
                          :label="$t('admin.events.certificates.items.labels.signatures.charge')"
                          dense
                          class="tw-text-xs"
                        />
                        <ColorInput
                            v-model="userForm.firma_pry_color"
                            :is-outlined="false"
                            :is-rounded="false"
                            :is-filled="false"
                        />
                        <DropzoneOverlayPreview
                          v-model="userForm.firma_pry_url"
                          bypass="simple-check"
                          :width="180"
                          :height="80"
                          media-group="logo_certificate"
                        />
                      </div>
                    </transition>
                    <!-- TODO: Signature 2 -->
                    <ToggleGroup v-model="userForm.firma_sec_is_active">
                      <template #title>
                        {{
                          $t(
                            'admin.events.certificates.items.labels.signatures.signature_second',
                          )
                        }}
                      </template>
                    </ToggleGroup>
                    <transition name="fade-transition" mode="out-in">
                      <div
                        v-if="userForm.firma_sec_is_active"
                        class="tw-bg-gray-100 tw-px-2 tw-py-4"
                      >
                        <v-text-field
                          v-model="userForm.firma_sec_name"
                          :label="$t('admin.events.certificates.items.labels.signatures.name')"
                          dense
                          class="tw-text-xs"
                        />
                        <v-text-field
                          v-model="userForm.firma_sec_detail"
                          :label="$t('admin.events.certificates.items.labels.signatures.charge')"
                          dense
                          class="tw-text-xs"
                        />
                        <ColorInput
                            v-model="userForm.firma_sec_color"
                            :is-outlined="false"
                            :is-rounded="false"
                            :is-filled="false"
                        />
                        <DropzoneOverlayPreview
                          v-model="userForm.firma_sec_url"
                          bypass="simple-check"
                          :width="180"
                          :height="80"
                          media-group="logo_certificate"
                        />
                      </div>
                    </transition>
                  </v-sheet>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </renderless-layout>
</template>

<script>
import StickyHeader from '@/components/StickyHeader.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import ColorInput from '@/components/FormInputs/ColorInput'
import DropzoneOverlayPreview from '@/components/DropzoneOverlayPreview'
import ToggleGroup from '@/components/FormInputs/ToggleGroup.vue'
import CertificateCard from '@/components/Event/CertificateCard.vue'
import { CertificateHydrate } from '@/models/Certificates'
import CertificateResource from '@/services/CertificateResource'

import { mapState } from 'vuex'

export default {
  components: {
    StickyHeader,
    AppBtn,
    ColorInput,
    DropzoneOverlayPreview,
    CertificateCard,
    ToggleGroup,
  },
  data() {
    return {
      data: {},
      userForm: {},
    }
  },
  computed: {
    nav() {
      return [
        {
          name: this.$t('app.event'),
          route: {
            name: 'admin.registries.index',
          },
        },
        {
          name: this.registry.label,
          route: {
            name: 'admin.registries.show.home',
            params: {
              id: this.registry.id,
            },
          },
        },
        {
          name: this.$t('admin.events.items.labels.certificates'),
          route: {},
        },
      ]
    },
    ...mapState({
      registry: (state) => state.registries.show.data,
      user: (state) => state.session.user,
    }),
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.data = CertificateHydrate(this.registry.certificate)
      this.userForm = JSON.parse(JSON.stringify(this.registry.certificate ? this.registry.certificate.user_form : {} ))
    },
    save() {
        this.$store.commit('setAppIsLoading', true, { root: true })
        const payload = {
          ...this.data,
          user_form: this.userForm,
        }
        CertificateResource.update(
            this.registry.id,
            CertificateHydrate(payload),
        )
        .then(({ data: { certificate }}) => {
            const updatedRegistry = { ...this.registry, certificate }
            this.$store.commit('registries/setShow', { data: updatedRegistry })
        })
        .finally(() => {
            this.$store.commit('setAppIsLoading', false, { root: true })
        })
    },
  },
}
</script>

<style>
</style>
