import { v4 } from '@/helpers'
import { ClickableBase } from './Clickable'
import { DurationBase } from './Duration'
export const VirtualRoomElementSlideBase = ({
  uuid = v4(),
  label = '',
  image_url = '',
  image_cropped = '',
  image_original = '',
  duration = DurationBase({}),
  clickable = ClickableBase({}),
}) => ({
  uuid,
  duration,
  clickable,
  label,
  image_url,
  image_cropped,
  image_original,
})
