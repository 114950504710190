<template>
  <v-dialog
    v-model="open"
    persistent
    max-width="768px"
    content-class="tw-rounded-3xl"
    scrollable
  >
    <v-form
      ref="form"
      v-model="valid"
      :disabled="form_disabled_state"
      lazy-validation
      enctype="multipart/form-data"
      @submit.prevent="update"
    >
      <v-card class="tw-rounded-3xl tw-border-0">
        <ModalHeader @close-modal="closeModal">{{
          $t('admin.events.items.sections.main_banner')
        }}</ModalHeader>
        <v-divider />
        <v-card-text class="tw-p-0" style="max-height: 700px">
          <v-responsive v-if="hasMounted" class="tw-py-0">
            <v-container class="tw-bg-gray-50 tw-p-0" fluid>
              <v-row class="mb-5 tw-m-0">
                <v-col cols="12" md="12" class="mx-auto tw-p-0">
                  <div class="tw-px-6 tw-py-2">
                    <ul class="tw-divide-y tw-divide-gray-200 tw-px-5">
                      <li
                        class="tw-items-center tw-py-4 sm:tw-flex sm:tw-justify-between"
                      >
                        <div class="tw-flex tw-flex-col">
                          <h3
                            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                          >
                            {{ $t('admin.events.type_of_banner') }}
                          </h3>
                          <p
                            id="privacy-option-1-description"
                            class="tw-mb-0 tw-text-sm tw-text-gray-500"
                          >
                            {{ $t('admin.events.type_of_banner_description') }}
                          </p>
                        </div>
                        <div
                          class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
                        >
                          <v-select
                            v-model="banner_type"
                            :items="banner_types"
                            :label="$t('app.type')"
                            outlined
                            rounded
                            hide-details=""
                            dense
                            class="tw-w-64"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <v-card
                    v-if="banner_type === BannerTypeKeys.banner_with_text"
                    class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-none tw-bg-gray-50 tw-py-2 tw-px-6 tw-shadow-none"
                  >
                    <v-container class="pa-5">
                      <div class="tw-grid tw-grid-cols-1 tw-gap-1">
                        <dropzone-preview
                          v-model="
                            mountedBannerSectionsMap[
                              BannerTypeKeys.banner_with_text
                            ].data.hero_image_url
                          "
                          :width="1920"
                          :height="840"
                          media-group="registries_landings"
                        />
                      </div>
                    </v-container>
                    <v-container class="pa-5">
                      <div class="tw-grid tw-grid-cols-1 tw-gap-1">
                        <div class="">
                          <EditorTipTap
                            v-model="
                              mountedBannerSectionsMap[
                                BannerTypeKeys.banner_with_text
                              ].data.hero_title
                            "
                            :label="$t('app.title')"
                            :description="
                              $t('admin.events.banner_title_description')
                            "
                          />
                        </div>
                      </div>
                    </v-container>
                    <v-container class="pa-5">
                      <div class="tw-grid tw-grid-cols-1 tw-gap-1">
                        <div class="">
                          <EditorTipTap
                            v-model="
                              mountedBannerSectionsMap[
                                BannerTypeKeys.banner_with_text
                              ].data.hero_description
                            "
                            :label="$t('app.description')"
                            :description="
                              $t('admin.events.banner_copy_description')
                            "
                          />
                        </div>
                      </div>
                    </v-container>
                  </v-card>
                  <v-card
                    v-if="banner_type === BannerTypeKeys.simple_image"
                    class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-none tw-bg-gray-50 tw-py-2 tw-px-6 tw-shadow-none"
                  >
                    <v-container class="pa-5">
                      <div class="tw-grid tw-grid-cols-1 tw-gap-1">
                        <DropzonePreview
                          v-model="
                            mountedBannerSectionsMap[
                              BannerTypeKeys.simple_image
                            ].data.image_desktop
                          "
                          :width="1920"
                          :height="840"
                          media-group="registries_landings"
                        />
                      </div>
                    </v-container>
                    <v-container class="pa-5">
                      <div class="tw-grid tw-grid-cols-1 tw-gap-1">
                        <dropzone-preview
                          v-model="
                            mountedBannerSectionsMap[
                              BannerTypeKeys.simple_image
                            ].data.image_mobile
                          "
                          :width="1080"
                          :height="1800"
                          media-group="registries_landings"
                        />
                      </div>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>

              <transition name="fade-transition" mode="out-in">
                <v-row v-if="errorAlert">
                  <v-col cols="12">
                    <v-alert
                      v-model="errorAlert"
                      dismissible
                      color="red"
                      border="left"
                      elevation="2"
                      colored-border
                      icon="error"
                      transition="fade-transition"
                    >
                      {{ errorMsg }}</v-alert
                    >
                  </v-col>
                </v-row>
              </transition>
            </v-container>
          </v-responsive>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            rounded
            :disabled="form_loading"
            class="tw-px-8 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-white"
            @click="closeModal"
          >
            {{ $t('app.cancel') }}</v-btn
          >
          <v-btn
            color="primary darken-1"
            rounded
            type="submit"
            :loading="form_loading"
            class="text--white text--lighten-4 tw-px-8 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-300"
          >
            {{ $t('app.save') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ModalHeader from '@/components/ModalHeader'
import DropzonePreview from '@/components/DropzonePreview.vue'
import EditorTipTap from '@/components/FormInputs/EditorTipTap.vue'
import { BannerTypeKeys } from '@/enums/SectionTypeKeys'
import { arrayToObject } from '@/utils/arrayToObject'
export default {
  components: {
    ModalHeader,
    EditorTipTap,
    // RadioBtn,
    DropzonePreview,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bannerSections: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    banner_types: [
      { text: 'Imagen con texto', value: BannerTypeKeys.banner_with_text },
      { text: 'Solo imagen', value: BannerTypeKeys.simple_image },
    ],
    banner_type: BannerTypeKeys.banner_with_text,
    BannerTypeKeys,
    errorAlert: '',
    errorMsg: '',
    exam: '',
    rules: {
      numericZero: (v) => !!parseFloat(v) || 'Campo no puede ser 0',
      testEmpty: (value) => !!value || 'Campo no puede estar vacio.',
      min: (v) => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => "The email and password you entered don't match",
    },
    isLoading: false,
    form_loading: false,
    form_disabled_state: false,
    valid: true,
    mountedIntroHero: {},
    mountedBannerSectionsMap: {
      [BannerTypeKeys.simple_image]: { data: {} },
      [BannerTypeKeys.banner_with_text]: { data: {} },
    },
    mountedBannerSections: [],
    isLoadingRooms: false,
    hasMounted: false,
  }),
  computed: {
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    // hero_title: {
    //   get() {
    //     return this.mountedBannerSectionData(BannerTypeKeys.banner_with_text)
    //       .hero_title
    //   },
    //   set(val) {
    //     this.setKeyValue({ hero_title: val })
    //   },
    // },
  },
  watch: {
    banner_type(newVal) {
      Object.keys(this.mountedBannerSectionsMap).forEach((key) => {
        this.mountedBannerSectionsMap[key].is_active = false
      })
      this.mountedBannerSectionsMap[newVal].is_active = true
    },
  },
  created() {
    // banner_sections  | array
    // separate blocks
    // set which is active  banner_type
    this.mountedBannerSections = JSON.parse(JSON.stringify(this.bannerSections))
    const activeKey = this.mountedBannerSections.find(
      (row) => row.is_active,
    ).name
    this.banner_type = activeKey
    this.mountedBannerSectionsMap = arrayToObject(
      this.mountedBannerSections,
      'name',
    )
    this.hasMounted = true
    // console.log(this.mountedBannerSectionsMap)

    // console.log(
    //   Object.keys(this.mountedBannerSectionsMap).map(
    //     (row) => this.mountedBannerSectionsMap[row],
    //   ),
    // )
  },
  methods: {
    mountedBannerSectionData(name) {
      return this.mountedBannerSections.find((row) => row.name === name).data
    },
    setKeyValue(obj) {
      Object.keys(obj).forEach(
        (k) => (this.mountedBannerSections[0].data[k] = obj[k]),
      )
    },
    emitFormat() {
      return Object.keys(this.mountedBannerSectionsMap).map(
        (row) => this.mountedBannerSectionsMap[row],
      )
    },
    closeModal() {
      this.open = false
    },
    update() {
      this.errorAlert = false
      if (!this.$refs.form.validate()) {
        return
      }

      this.$emit('updated-banner-sections', this.emitFormat())
      this.open = false
    },
  },
}
</script>

<style></style>
