<template>
  <div>
    <v-img
      :src="section.data.image_desktop"
      class="tw-hidden sm:tw-flex"
      :class="[desktopClasses]"
    />
    <v-img
      :src="section.data.image_mobile"
      class="sm:tw-hidden"
      :class="[mobileClasses]"
    />
  </div>
</template>

<script>
const ratios = {
  width: [
    'tw-aspect-w-1',
    'tw-aspect-w-2',
    'tw-aspect-w-3',
    'tw-aspect-w-4',
    'tw-aspect-w-5',
    'tw-aspect-w-6',
    'tw-aspect-w-7',
    'tw-aspect-w-8',
    'tw-aspect-w-9',
    'tw-aspect-w-10',
    'tw-aspect-w-11',
    'tw-aspect-w-12',
    'tw-aspect-w-13',
    'tw-aspect-w-14',
    'tw-aspect-w-15',
    'tw-aspect-w-16',
  ],

  height: [
    'tw-aspect-h-1',
    'tw-aspect-h-2',
    'tw-aspect-h-3',
    'tw-aspect-h-4',
    'tw-aspect-h-5',
    'tw-aspect-h-6',
    'tw-aspect-h-7',
    'tw-aspect-h-8',
    'tw-aspect-h-9',
    'tw-aspect-h-10',
    'tw-aspect-h-11',
    'tw-aspect-h-12',
    'tw-aspect-h-13',
    'tw-aspect-h-14',
    'tw-aspect-h-15',
    'tw-aspect-h-16',
  ],
}
export default {
  props: {
    section: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    desktopClasses() {
      return `${ratios.width[this.section.data.ratio_desktop_x - 1]} ${
        ratios.height[this.section.data.ratio_desktop_y - 1]
      }`
    },
    mobileClasses() {
      return `${ratios.width[this.section.data.ratio_mobile_x - 1]} ${
        ratios.height[this.section.data.ratio_mobile_y - 1]
      }`
    },
  },
}
</script>

<style></style>
