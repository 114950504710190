<template>
  <v-autocomplete
    v-model="input_value"
    :items="rows"
    :loading="isLoading"
    :search-input.sync="search"
    item-text="subject"
    item-value="id"
    :label="$t('admin.app.threads')"
    outlined
    rounded
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-text="item.subject" />
        <v-list-item-subtitle v-text="item.thread_type.label" />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    filterName: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Hilos de preguntas',
    },
  },
  data: () => ({
    search: null,
  }),
  computed: {
    ...mapState({
      items: (state) => state.constants.run_time.threads,
      isLoading: (state) => state.constants.is_loading.threads,
    }),
    rows() {
      if (this.filterName) {
        return this.items.filter((row) => {
          return this.filterName == row.thread_type.name
        })
      }
      return this.items
    },
    input_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  // watch: {
  //   search() {
  //     if (this.items.length > 0) return
  //     this.loadRows()
  //   },
  // },
  mounted() {
    if (this.items.length > 0) return
    this.loadRows()
  },
  methods: {
    loadRows() {
      this.$store.dispatch('constants/loadThreads')
    },
  },
}
</script>

<style></style>
