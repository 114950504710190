export const TargetLinkKeys = {
  normal: { name: 'normal', value: 1 },
  target: { name: 'target', value: 2 },
}
// {
//   1: 'es',
//   2: 'en',
// }

// export const LanguageValues = Object.keys(LanguageKeys).reduce(
//   (acc, row, currentIndex) => ({
//     ...acc,
//     [currentIndex + 1]: row,
//   }),
//   {},
// )
