<template>
  <v-card
    class="tw-relative tw-rounded-3xl tw-border-0 tw-bg-black tw-bg-opacity-75 tw-shadow-md"
    :class="[video.can_chat ? 'fill-height ' : 'tw-aspect-h-9']"
  >
    <!--         tw-backdrop-filter
        tw-backdrop-blur-3xl
        tw-backdrop-saturate-150
         -->
    <!-- <modal-header>{{ iframeItemUrl }}</modal-header> -->
    <!-- <v-divider /> -->
    <!-- style="max-height: 768px" -->
    <!-- <v-card-text
        class="tw-p-0"
        :class="[video.can_chat ? 'fill-height' : 'tw-aspect-h-9']"
      >
        <v-responsive
          class="tw-py-0"
          :class="[video.can_chat ? 'fill-height' : 'tw-aspect-h-9']"
        > -->
    <v-container
      id="iframe-wrapper"
      class="tw-flex-nowrap tw-p-0"
      :class="[video.can_chat ? 'fill-height ' : 'tw-aspect-h-9']"
      fluid
    >
      <!-- tw-grid tw-grid-cols-7  -->
      <!--
                tw-aspect-w-16
                tw-w-full
                tw-aspect-h-auto
                tw-h-full -->
      <!-- tw-aspect-h-9 -->
      <v-container
        class="tw-col-span-5 tw-h-full tw-w-full tw-flex-1 tw-flex-grow tw-bg-transparent tw-p-0 tw-shadow-none"
        :class="[video.can_chat ? 'all-iframes-w-full ' : '']"
        fluid
      >
        <AppPlyr v-if="simpleVideoItemType == 'uploaded'">
          <video
            ref="uploaded"
            controls
            crossorigin
            playsinline
            :data-poster="simpleVideoItemPreview"
            :autoplay="!!video.is_autoplay"
          >
            <source :src="video.video_item_url" type="video/mp4" />
          </video>
        </AppPlyr>

        <vimeo-player
          v-if="simpleVideoItemType == 'vimeo'"
          ref="player"
          :class="[
            video.can_chat
              ? 'tw-h-full tw-w-full'
              : 'tw-aspect-w-16 tw-aspect-h-9',
          ]"
          :video-url="video.video_item_url"
          :options="video.is_autoplay ? { autoplay: true } : {}"
        />
        <!-- class="tw-aspect-w-16 tw-aspect-h-9" -->
        <LazyYoutube
          v-if="simpleVideoItemType == 'youtube'"
          ref="youtube"
          :src="video.video_item_url"
          :class="[
            video.can_chat
              ? 'tw-h-full tw-w-full'
              : '',
          ]"
          aspect-ratio="16:9"
          :autoplay="!!video.is_autoplay"
        />
      </v-container>
      <!-- class="tw-aspect-w-16 tw-aspect-h-9" -->
      <EventRoomContentElementChat
        v-if="video.can_chat && loaded"
        class="tw-col-span-2"
        :parrent-channel-id="channel_id"
        :can-chat="video.can_chat"
        :can-close="canClose"
        :can-thread="video.can_thread"
        :parrent-thread-id="thread_id"
      />
    </v-container>
    <!-- </v-responsive>
      </v-card-text> -->
  </v-card>
</template>

<script>
import AppPlyr from '@/components/Video/AppPlyr.vue'
import EventRoomContentElementChat from './EventRoomContentElementChat.vue'
import { VideoValues } from '@/enums/VideoKeys'
export default {
  components: {
    AppPlyr,
    EventRoomContentElementChat,
  },
  props: {
    canClose: {
      type: Boolean,
      default: false,
    },
    video: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    channel_id: 0,
    thread_id: 0,
    loaded: false,
  }),
  computed: {
    simpleVideoItemType() {
      return VideoValues[this.video.video_item_type_id]
    },
  },
  mounted() {
    console.log(this.video.video_item_url)
    console.log(this.video.can_chat)
    console.log(this.video.can_thread)
    console.log(this.video.video_item_url)
    if (this.video.channels[0]) {
      this.channel_id = this.video.channels[0].id
    }
    if (this.video.threads[0]) {
      this.thread_id = this.video.threads[0].id
    }
    this.$nextTick(() => {
      this.loaded = true
    })
    // console.log(this.video.channels[0].id)
  },
  methods: {
    pauseElement() {
      console.log('llegue')
       // Mp4
      if (this.$refs.uploaded) {
        this.$refs.uploaded.plyr.pause()
      }
      // Youtube
      if (this.$refs.youtube) {
        this.$refs.youtube.pauseVideo()
      }
      // Vimeo
      if (this.$refs.player) {
        this.$refs.player.pause()
      }
    },
  },
}
</script>

<style>
.all-iframes-w-full iframe {
  width: 100%;
  height: 100%;
}
</style>
