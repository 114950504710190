<template>
  <v-dialog
    v-model="open"
    persistent
    content-class="tw-rounded-3xl tw-relative fill-height tw-max-h-full  tw-items-center tw-shadow-none  tw-m-0 tw-p-0 tw-w-full"
  >
    <!-- tw-flex -->
    <v-card class="fill-height tw-border-0 tw-bg-transparent tw-shadow-none">
      <v-card-text class="fill-height tw-p-0">
        <v-container
          class="fill-height tw-relative tw-flex tw-items-center tw-justify-center tw-py-0"
        >
          <div class="tw-relative tw-py-3 sm:tw-mx-auto sm:tw-max-w-xl">
            <div class="tw-absolute tw--right-10 tw-top-4">
              <v-fab-transition>
                <v-btn
                  fab
                  x-small
                  class="focus:tw-outline-none focus:tw-ring"
                  @click="closeModal"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-fab-transition>
            </div>
            <transition name="fade-transition" mode="out-in">
              <v-card
                v-if="mode === 'accepting'"
                key="photobooth__accepting"
                color="white"
                class="tw-mx-auto tw-mb-3 tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-border-transparent tw-shadow-md"
              >
                <v-container
                  class="tw-relative tw-rounded-3xl tw-px-10 tw-py-12"
                  fluid
                >
                  <div class="tw-text-center">
                    <div class="tw-flex tw-justify-center">
                      <v-img
                        :src="require('@/assets/logo-bizzdome.svg')"
                        max-width="200"
                        contain
                      >
                        <template #placeholder>
                          <v-row
                            class="fill-height ma-0 transparent"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              size="32"
                              indeterminate
                              color="primary"
                            />
                          </v-row>
                        </template>
                      </v-img>
                    </div>

                    <!-- <h3
                      class="tw-mt-6 tw-text-lg tw-font-medium tw-text-gray-900"
                    >
                      Photobooth
                    </h3>
                    <p class="tw-mt-1 tw-text-sm tw-text-gray-500">
                      Todas las imágenes tomadas llenarán una galería.<br />
                      Puedes ver la galería aquí.
                    </p> -->
                    <div class="tw-mt-6">
                      <AppBtn
                        color="primary"
                        icon-right="g-next"
                        g-next="ArrowNarrowRight"
                        @click="toggleCaptureMode"
                      >
                        {{ $t('app.take_photo') }}
                      </AppBtn>
                    </div>
                  </div>
                </v-container>
                <v-container class="tw-py-12">
                  <div class="tw-flex tw-justify-center">
                    <AppBtn
                      color="outlined-transparent"
                      icon="g-next"
                      g-next="Photo"
                      @click="openPhotoboothGallery"
                    >
                      {{ $t('admin.app.view_gallery') }}
                    </AppBtn>
                  </div>
                </v-container>
              </v-card>

              <v-card
                v-if="mode === 'capture'"
                key="photobooth__capture"
                color="white"
                class="tw-mx-auto tw-mb-3 tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-border-transparent tw-shadow-md"
              >
                <v-container class="tw-relative tw-rounded-3xl tw-p-1" fluid>
                  <video
                    id="camera"
                    ref="camera"
                    :width="camera.width"
                    :height="camera.height"
                  />
                  <canvas
                    id="source-canvas"
                    ref="sourceCanvas"
                    class="camera__canvas tw-rounded-3xl"
                    :width="canvas.width"
                    :height="canvas.height"
                  />
                  <canvas
                    id="target-canvas"
                    ref="targetCanvas"
                    class="camera__canvas"
                    :width="canvas.width"
                    :height="canvas.height"
                  />
                  <!-- small -->
                  <AppBtn
                    fab
                    icon-center="g-next"
                    g-next="Camara"
                    color="primary"
                    class="tw-absolute tw-top-0 tw-bottom-0 tw-my-auto tw-right-4 md:tw-top-auto md:tw-bottom-4 tw-opacity-90"
                    @click="toggleConfirmImage"
                  />
                  <!-- <v-btn
                    class="tw-absolute tw-top-0 tw-bottom-0 tw-my-auto tw-right-4 md:tw-top-auto md:tw-bottom-4 tw-opacity-90"
                    fab
                    @click="toggleConfirmImage"
                  >
                    <div class="tw-border-2 btn-camera-capture" />
                  </v-btn> -->
                </v-container>
                <v-container class="tw-flex tw-flex-col tw-items-center tw-p-5">
                  <ul class="tw-grid tw-w-full tw-grid-cols-4 tw-gap-4 tw-pl-0">
                    <li
                      v-for="background in backgrounds"
                      :key="background.uuid"
                    >
                      <label
                        class="tw-relative tw-cursor-pointer tw-rounded"
                      >
                        <input
                          v-model="selected_background"
                          type="radio"
                          name="server_size"
                          :value="background.uuid"
                          class="tw-sr-only"
                        />
                        <!-- tw-sr-only  tw-fixed tw-h-0 tw-w-0 tw-opacity-0 checked:tw-invisible-->
                        <div
                          v-ripple
                          class="tw-aspect-w-1 tw-aspect-h-1 tw-overflow-hidden tw-rounded-xl tw-shadow tw-transition focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-50"
                          :class="{
                            ' tw-bg-white  tw-ring tw-ring-primary-300':
                              selected_background == background.uuid,
                          }"
                        >
                          <img
                            :src="background.background_url"
                            class="tw-h-full tw-w-full"
                            crossorigin="anonymous"
                          />
                        </div>
                      </label>
                    </li>
                  </ul>
                </v-container>
                <!-- <v-container class="tw-flex tw-flex-col tw-items-center tw-p-5">
                  <div>
                    <p class="tw-mb-0 tw-text-lg tw-text-gray-500">
                      {{ $t('admin.app.click_to_capture') }}
                    </p>
                  </div>

                  <div class="tw-mt-6">
                    <AppBtn
                      fab
                      icon-center="g-next"
                      g-next="Camara"
                      color="primary"
                      @click="toggleConfirmImage"
                    />
                  </div>
                </v-container> -->
                <v-container class="tw-py-4">
                  <div class="tw-flex tw-justify-center">
                    <AppBtn
                      color="outlined-transparent"
                      icon="g-next"
                      g-next="Photo"
                      @click="openPhotoboothGallery"
                    >
                      {{ $t('admin.app.view_gallery') }}
                    </AppBtn>
                  </div>
                </v-container>
              </v-card>

              <v-card
                v-if="mode === 'confirm'"
                key="photobooth__confirm"
                color="white"
                class="tw-mx-auto tw-mb-3 tw-rounded-3xl tw-border-transparent tw-shadow-md"
              >
                <v-container class="tw-rounded-3xl tw-p-1" fluid>
                  <v-img
                    :src="imageRaw"
                    class="camera__canvas tw-rounded-3xl"
                    :width="canvas.width"
                    :height="canvas.height"
                  />
                </v-container>
                <v-container class="tw-flex tw-flex-col tw-items-center tw-p-4">
                  <!-- <div>
                    <p class="tw-mb-0 tw-text-lg tw-text-gray-500">
                      {{ $t('app.confirm_photo') }}
                    </p>
                  </div> -->
                  <div class="tw-mt-0 tw-flex tw-space-x-3">
                    <AppBtn
                      :disabled="is_loading"
                      icon="g-next"
                      g-next="ArrowNarrowLeft"
                      color="outlined-danger"
                      @click="toggleCaptureMode"
                    >
                      {{ $t('app.back') }}
                    </AppBtn>
                    <AppBtn
                      :loading="is_loading"
                      icon="check"
                      color="primary"
                      @click="saveCapture"
                    >
                      {{ $t('app.save') }}
                    </AppBtn>
                  </div>
                </v-container>
              </v-card>
              <v-card
                v-if="mode === 'share'"
                key="photobooth__confirm"
                color="white"
                class="tw-mx-auto tw-mb-3 tw-rounded-3xl tw-border-transparent tw-shadow-md"
              >
                <v-container class="tw-rounded-3xl tw-p-1" fluid>
                  <v-img
                    :src="imageRaw"
                    class="camera__canvas tw-rounded-3xl"
                    :width="canvas.width"
                    :height="canvas.height"
                  />
                </v-container>
                <v-container class="tw-flex tw-flex-col tw-items-center tw-p-2">
                  <!-- <div>
                    <p class="tw-mb-0 tw-text-lg tw-text-gray-500">
                      {{ $t('admin.app.share_photo') }}
                    </p>
                  </div> -->

                  <div class="tw-mt-0 tw-grid tw-grid-cols-3 tw-gap-2">
                    <!-- <AppBtn
                      icon="g-next"
                      g-next="Photo"
                      color="primary-dark"
                      @click="openPhotoboothGallery"
                    >
                      {{ $t('admin.app.view_gallery') }}
                    </AppBtn> -->
                    <v-btn
                      class="ma-2 tw-text-xs tw-bg-primary-900 tw-text-gray-50 focus:tw-ring-primary-300 tw-font-bold tw-normal-case tw-transition focus:tw-outline-none focus:tw-ring"
                      rounded
                      @click="openPhotoboothGallery"
                    >
                      <v-icon class="tw-mr-2">
                        collections
                      </v-icon>
                      {{ $t('admin.app.view_gallery') }}
                    </v-btn>
                    <v-btn
                      class="ma-2 tw-text-xs tw-bg-green-50 tw-text-green-700 focus:tw-ring-green-300 tw-font-bold tw-normal-case tw-transition focus:tw-outline-none focus:tw-ring"
                      rounded
                      @click="downlaodImage"
                    >
                      <v-icon class="tw-mr-2">
                        download
                      </v-icon>
                      {{ $t('admin.enums.download') }}
                    </v-btn>
                    <v-btn
                      class="ma-2 tw-text-xs tw-bg-white tw-text-gray-700 focus:tw-ring-primary-300 tw-font-bold tw-normal-case tw-transition focus:tw-outline-none focus:tw-ring"
                      rounded
                      @click="toggleCaptureMode"
                    >
                      <v-icon class="tw-mr-2">
                        photo_camera
                      </v-icon>
                      {{ $t('app.take_photo') }}
                    </v-btn>
                    <!-- <v-menu v-if="false" rounded offset-y>
                      <template #activator="{ attrs, on }">
                        <AppBtn
                          icon="g-next"
                          g-next="Share"
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          @click="toggleShareMode"
                        >
                          Compartir
                        </AppBtn>
                      </template>

                      <v-list>
                        <v-list-item
                          v-for="item in ['Facebook', 'Twitter', 'LinkedIn']"
                          :key="item"
                          link
                        >
                          <v-list-item-title v-text="item" />
                        </v-list-item>
                      </v-list>
                    </v-menu> -->
                    <!-- <AppBtn
                      icon="download_cloud"
                      color="green-light"
                      @click="downlaodImage"
                    >
                      {{ $t('admin.enums.download') }}
                    </AppBtn>
                    <AppBtn
                      icon="refresh"
                      color="white"
                      @click="toggleCaptureMode"
                    >
                      {{ $t('app.take_photo') }}
                    </AppBtn> -->
                  </div>
                </v-container>
              </v-card>
            </transition>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
import AppBtn from '@/components/App/AppBtn.vue'
import { mapState } from 'vuex'
const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}
// const AsyncCanvasView = (canvas, img) => {
//   return new Promise(function (resolve, reject) {
//     const base_image = new Image()
//     base_image.crossOrigin = 'anonymous'
//     // let replacing = img.replace('https://jdoutfit.com/', 'https://www.guillermocava.com/');
//     // replacing = replacing.replace('https://www.jdoutfit.com/', 'https://www.guillermocava.com/');
//     // let replacing = img.replace('https://www.guillermocava.com/', 'http://localhost/');
//     // let replacing = img.replace('https://jdoutfit.com/', 'http://localhost:8000/');
//     console.log(img)
//     // replacing = img.replace('https://www.jdoutfit.com/', 'http://localhost/');
//     base_image.src = img
//     try {
//       base_image.onload = function () {
//         resolve(canvas.drawImage(base_image, 0, 0))
//       }
//     } catch (error) {
//       console.log(error)
//       reject(error)
//     }
//   })
// }
export default {
  components: {
    AppBtn,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    is_loading: false,
    mode: 'accepting',
    // selected_background: 'empty-frame',
    selected_background: '',
    animationReq: undefined,
    sourceCamera: null,
    sourceVideo: null,
    localStream: undefined,
    backgroundImage: null,
    dpi: null,
    sourceCanvas: null,
    sourceCanvasCtx: null,
    targetCanvas: null,
    imageRaw: '',
    targetCanvasCtx: null,
    savedData: null,
    camera: {
      width: 1280,
      height: 720,
    },
  }),
  computed: {
    backgrounds() {
      return [
        // {
        //   uuid: 'empty-frame',
        //   background_url: require('@/assets/empty_frame.png'),
        // },
        ...this.photobooth.photo_booth_config.backgrounds,
      ]
    },
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    ...mapState('event', {
      photobooth: (state) => state.photobooth,
    }),
    canvas() {
      return {
        width: 500,
        height: 500,
        aspect: this.camera.width / this.camera.height,
      }
    },
    maxDimension() {
      if (this.camera.width > this.camera.height) {
        return this.camera.height
      }
      return this.camera.width
    },
    orientation() {
      return this.camera.width > this.camera.height ? 'landscape' : 'portrait'
    },
    crop() {
      if (this.orientation == 'portrait') {
        return {
          orientation: 'portrait',
          sx: 0,
          sy: (this.camera.height - this.camera.width) / 2,
          sWidth: this.camera.width,
          sHeight: this.camera.width,
          dx: 0,
          dy: 0,
          dWidth: this.canvas.width,
          dHeight: this.canvas.height,
        }
      }
      return {
        orientation: 'landscape',
        sx: (this.camera.width - this.camera.height) / 2,
        sy: 0,
        sWidth: this.camera.height,
        sHeight: this.camera.height,
        dx: 0,
        dy: 0,
        dWidth: this.canvas.width,
        dHeight: this.canvas.height,
      }
    },
    deviceID() {
      if (this.cameraID != undefined) {
        return this.cameraID
      }
      return 'camera'
    },
    currentForm() {
      return this.$store.getters['event/currentForm']
    },
    videoConstraints() {
      return {
        audio: false,
        video: {
          deviceId: this.deviceID,
          width: this.camera.width,
          height: this.camera.height,
        },
      }
    },
  },

  watch: {
    selected_background: {
      handler() {
        this.toggleCaptureMode()
      },
    },
    open: {
      handler(newVal) {
        if (newVal) {
          //init evente
          this.toggleCaptureMode()
          this.photoboothConfigInit()
        } else {
          if (this.localStream) {
            this.localStream.getTracks().forEach((track) => track.stop())
          }
        }
      },
    },
  },
  mounted() {
    // window.cancelAnimationFrame(this.animationReq)
    // this.initAll()
  },

  beforeDestroy() {
    //  window.localstream.getTracks().forEach(function (track) {
    //     if (track.readyState == 'live') {
    //       track.stop()
    //     }
    //   })
    // this.sourceVideo.getTracks().forEach(function (track) {
    //   if (track.readyState == 'live') {
    //     track.stop()
    //   }
    // })
    // this.localStream.getVideoTracks().forEach(function (track) {
    //   // if (track.readyState == 'live') {
    //   track.stop()
    //   // }
    // })
    // window.localstream.getTracks().forEach(function (track) {
    //     if (track.readyState == 'live') {
    //       track.stop()
    //     }
    //   })
    // this.localStream.stop()
    // this.sourceVideo.onloadedmetadata = () => {
    //   this.sourceVideo.stop()
    // }
  },
  methods: {
    photoboothConfigInit() {
      this.$store.dispatch('event/showPhotoboothConfig', {
        photo_booth_config: this.photobooth.photobooth_config_id,
      }).then(() => {
        this.selected_background = this.backgrounds ? this.backgrounds[0].uuid : ''
      })
    },
    closeModal() {
      this.open = false
    },
    openPhotoboothGallery() {
      this.open = false
      this.$store.commit('event/setPhotobooth', {
        gallery_modal: true,
      })
    },
    saveCapture() {
      const form = new FormData()
      form.append('file', dataURItoBlob(this.imageRaw))
      form.append('media_group', 'photobooth_image__cropped')
      form.append('bypass', 'no-check')

      this.is_loading = true
      return this.$store
        .dispatch('media_elements/storeDrop', form)
        .then(({ media_element: { link } }) => {
          const payload = {
            uuid: this.currentForm.uuid,
            body: {
              image_url: link,
              photobooth_config_id: this.photobooth.photobooth_config_id,
            },
          }
          return this.$store.dispatch('event/storePhotoboothPhoto', payload)
        })
        .then(() => {
          this.mode = 'share'
          // this.$emit('croped-image', res)
          // this.open = false
        })
        .catch((err) => {
          console.warning('---Could not store image---', err)
          // this.errorAlert = true
          // this.errorMsg = err
        })
        .finally(() => {
          this.is_loading = false
          // this.form_loading = false
        })
      // const payload = {
      //   uuid: this.current_form.uuid,
      //   body: {
      //     ...this.form,
      //     registry_form_submission: this.registry_form_submission,
      //   },
      // }
      // this.$store.dispatch('event/updateProfile', payload).catch((err) => {
      //   this.errorAlert = true
      //   this.errorMsg = err
      // })
    },
    // openGallery() {},
    toggleShareMode() {
      this.mode = 'share'
    },
    downlaodImage() {
      // this.savedData = this.$refs.sourceCanvas.toDataURL('image/png')
      var link = document.createElement('a')
      link.download = 'bizz-dome-photobooth.png'
      // link.href = this.$refs.sourceCanvas.toDataURL('image/png')
      link.href = this.imageRaw
      link.click()
    },
    toggleConfirmImage() {
      this.imageRaw = this.$refs.sourceCanvas.toDataURL('image/png')
      this.mode = 'confirm'
    },
    initAll() {
      // ==|== Vars - Camera =====================================================================
      // eslint-disable-next-line no-undef
      this.sourceVideo = this.$refs.camera

      // this.sourceVideo.width = this.camera.width
      // this.sourceVideo.height = this.camera.height
      // this.sourceVideo.width = 1080
      // this.sourceVideo.height = 720
      this.sourceVideo.width = 500
      this.sourceVideo.height = 500
      this.dpi = window.devicePixelRatio
      this.sourceCanvas = this.$refs.sourceCanvas
      this.sourceCanvasCtx = this.sourceCanvas.getContext('2d')
      this.targetCanvas = this.$refs.targetCanvas
      this.targetCanvasCtx = this.targetCanvas.getContext('2d')
      this.resetCamera()
      this.startAnimation()
      console.info('---camara booth initialized---')
    },
    startAnimation() {
      // more documentation https://stackoverflow.com/questions/17533888/s3-access-control-allow-origin-header
      let base_image = null
      if (this.selected_background) {
        const bg = this.photobooth.photo_booth_config.backgrounds.find(
          (row) => row.uuid == this.selected_background,
        )
        if (bg) {
          base_image = new Image()
          base_image.crossOrigin = 'anonymous'
          base_image.src = bg.background_url
        }
      }

      // try {
      //   base_image.onload = function () {
      //     resolve(canvas.drawImage(base_image, 0, 0))
      //   }
      // } catch (error) {
      //   console.warning(error)
      //   reject(error)
      // }
      const animationStep = () => {
        if (this.sourceVideo) {
          this.sourceCanvasCtx.save()
          this.sourceCanvasCtx.translate(this.canvas.width, 0)
          this.sourceCanvasCtx.scale(-1, 1)
          this.sourceCanvasCtx.drawImage(
            this.sourceVideo,

            this.crop.sx,
            this.crop.sy,
            this.crop.sWidth,
            this.crop.sHeight,
            this.crop.dx,
            this.crop.dy,
            this.crop.dWidth,
            this.crop.dHeight,
          )
          this.sourceCanvasCtx.restore()
          // AsyncCanvasView(
          //   this.sourceCanvasCtx,
          //   'https://bizz-dome-uploads--demo.s3.amazonaws.com/media/2022/03/8b02b904-9171-4451-b875-995abc67fa57_m-kmjk-frame-1_n-500x500.png',
          // )
          if (base_image) {
            this.sourceCanvasCtx.drawImage(base_image, 0, 0, 500, 500)
          }
        }
        // Target Canvas
        // this.targetCanvasCtx.fillStyle = "white";
        // this.targetCanvasCtx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        // if (this.sourceCanvas) {
        //     this.targetCanvasCtx.save();
        //     this.targetCanvasCtx.drawImage(this.sourceCanvas, this.crop.sx, this.crop.sy, this.crop.sWidth, this.crop.sHeight, this.crop.dx, this.crop.dy, this.crop.dWidth, this.crop.dHeight);
        //     this.targetCanvasCtx.restore();
        // }
        this.animationReq = requestAnimationFrame(animationStep)
      }
      this.animationReq = requestAnimationFrame(animationStep)
    },
    resetCamera() {
      if (typeof this.localStream !== 'undefined') {
        // this.stopMediaTracks(this.localStream)
        this.localStream.getTracks().forEach((track) => track.stop())
      }
      navigator.mediaDevices
        .getUserMedia(this.videoConstraints)
        .then(this.streamMedia)
        .catch(this.handleError)
    },
    toggleCaptureMode() {
      this.mode = 'capture'
      navigator.mediaDevices
        .getUserMedia(this.videoConstraints)
        .then(() => {
          this.$nextTick().then(() => {
            setTimeout(() => {
              window.cancelAnimationFrame(this.animationReq)

              this.initAll()
            }, 750)
          })
        })
        .catch((err) => {
          if (err.name == 'NotAllowedError') {
            console.warning('---User has denied accessed---')
          }
        })
    },
    streamMedia(stream) {
      this.localStream = stream
      let track = this.localStream.getVideoTracks()[0]
      this.camera.width = track.getSettings().width
      this.camera.height = track.getSettings().height
      this.sourceVideo.srcObject = this.localStream
      this.sourceVideo.playsInline = true
      this.sourceVideo.onloadedmetadata = () => {
        this.sourceVideo.play()
      }
    },
  },
}
</script>

<style>
#camera,
#target-canvas {
  display: none;
}
.btn-camera-capture {
  width: 45px;
  height: 45px;
  border: 1px solid #aaaaaa;
  border-radius: 50%;
}
</style>
