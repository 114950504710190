import { cloneDeep } from '@/utils/cloneDeep'

export const AttendeeBase = ({
  email = '',
  first_name = '',
  last_name = '',
  password = '',
  password_confirmation = '',
  company = '',
  title = '',
  country_code = '',
  address = '',
  city = '',
  phone_number = '',
  registry_form_id = '',
  registry_form_submission = [],
  default_fields = [],
  terms = false,
  has_payment = false,
}) => ({
  email,
  first_name,
  last_name,
  password,
  password_confirmation,
  company,
  title,
  country_code,
  address,
  city,
  phone_number,
  registry_form_id,
  registry_form_submission,
  default_fields,
  terms,
  has_payment,
})

export const formSubmissionModel = (registry_form_submission) => {
  if (!registry_form_submission) {
    return ''
  }
  return registry_form_submission.model
}

export const formSubmissionModelMultiple = (registry_form_submission) => {
  // if (registry_form_submission.input_type !== 'checkbox') {
  //   return []
  // }
  if (!registry_form_submission) {
    return ''
  }
  return registry_form_submission.model_multiple
}

export const formatAttendee = (attendee) => {
  const {
    company,
    title,
    country_code,
    address,
    city,
    phone_number,
    registry_form_id,
    registry_form_submission: prev_registry_form_submission,
    has_payment,
  } = attendee
  const current_form = cloneDeep(attendee.registry_form)

  let registry_form_submission = current_form.fields.map((row) => {
    const updated_registry_form_submission = prev_registry_form_submission.find(
      (user_row) => user_row.name == row.name,
    )
    const model = formSubmissionModel(updated_registry_form_submission)
    const model_multiple = formSubmissionModelMultiple(
      updated_registry_form_submission,
    )
    return { ...row, model, model_multiple }
  })
  return AttendeeBase({
    registry_form_id,
    company,
    title,
    country_code,
    address,
    city,
    registry_form_submission,
    default_fields: current_form.default_fields.filter(
      (row) => row.name !== 'email',
    ),
    phone_number,
    has_payment,
  })
}
