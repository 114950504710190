<template>
  <div class="tw-py-6 tw-bg-white tw-rounded-3xl tw-shadow-md">
    <div class="tw-mx-auto tw-max-w-3xl tw-px-4 sm:tw-px-6 md:tw-flex md:tw-items-center md:tw-justify-between md:tw-space-x-5 lg:tw-max-w-7xl lg:tw-px-8 print:tw-hidden">
      <!-- ----------------- Profile - Button Back ----------------- -->
      <div class="tw-flex tw-items-center tw-space-x-5">
        <div class="">
          <v-btn fab x-small class="text--white tw-normal-case tw-shadow-sm focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-300" @click="$emit('on-back')">
            <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
          </v-btn>
        </div>
        <div class="tw-flex-shrink-0">
          <div class="tw-relative">
            <img class="tw-h-14 tw-w-14 tw-rounded-full" :src="user.avatar" alt="" />
          </div>
        </div>
        <div>
          <h1 class="tw-text-xl tw-font-bold tw-text-gray-900">
            {{ fullName }}
          </h1>
          <span class="tw-text-sm tw-text-gray-400">
            {{ user.email }}
          </span>
        </div>
      </div>
      <div class="tw-mt-6 tw-flex tw-space-x-3 lg:tw-mt-0">
        <div>
          <v-select v-model="language_id" dense outlined rounded :items="validLanguages" hide-details class="tw-text-xs" label="Language" />
        </div>
      </div>
    </div>
    <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-4 tw-px-6 tw-mx-auto tw-mt-8">
      <!-- ----------------- Information Attendee ----------------- -->
      <div class="tw-col-span-2">
        <v-card class="tw-rounded-3xl tw-shadow tw-space-y-2" aria-labelledby="applicant-information-title">
          <v-app-bar dark color="primary" class="bz-app-bar tw-shadow">
            <v-toolbar-title class="tw-text-sm">{{ $t('pages.event.mod_dash.personal_information') }}</v-toolbar-title>
            <v-spacer/>
          </v-app-bar>
          <div class="tw-px-6 tw-py-6">
            <dl class="tw-grid tw-grid-cols-1 tw-gap-x-2 tw-gap-y-8 sm:tw-grid-cols-3">
              <div v-for="default_field in default_fields" :key="default_field.name" class="sm:tw-col-span-1">
                <dt class="tw-text-sm tw-font-bold tw-text-gray-900">
                  {{ default_field.label }}
                </dt>
                <dd class="tw-mt-1 tw-text-xs tw-text-gray-400">
                  {{ form[default_field.name] }}
                </dd>
              </div>
              <div v-for="custom_field in custom_fields" :key="custom_field.uid">
                <dt class="tw-text-xs tw-font-bold tw-text-gray-900">
                  {{ custom_field.label }}
                </dt>
                <dd class="tw-mt-1 tw-text-xs tw-text-gray-400">
                  {{ custom_field.model }}
                </dd>
              </div>
            </dl>
          </div>
        </v-card>
      </div>
      <!-- ----------------- Actions Buttons ----------------- -->
      <div>
        <v-card class="tw-rounded-3xl tw-shadow">
          <v-app-bar dark color="primary" class="bz-app-bar tw-shadow">
            <v-toolbar-title class="tw-text-sm">{{ $t('pages.event.mod_dash.actions.title') }}</v-toolbar-title>
            <v-spacer/>
          </v-app-bar>
          <div class="tw-p-4 tw-space-y-2">
            <!-- ----------------- Checkin Button ----------------- -->
            <ButtonIconCustom icon="how_to_reg" label="Check-ins" color="tw-bg-orange-400" :mode="true" :loading="loadings.check_in" @click="checkIn" />
            <v-divider />
            <!-- ----------------- Print Credential Button ----------------- -->
            <ButtonIconCustom icon="print" :label="$t('pages.event.mod_dash.modal.button')" color="tw-bg-sky-500" :mode="true" @click="printCredentialModal = true" />
            <v-divider v-if="registry.has_payment" />
            <!-- ----------------- Payment Button ----------------- -->
            <div v-if="attendee && registry.has_payment" class="tw-rounded-3xl tw-p-2 bz-btn-custom" :class="attendee.has_payment ? 'tw-bg-red-500' : 'tw-bg-green-500'">
              <v-checkbox v-model="attendee.has_payment" hide-details  class="tw-m-0 tw-text-xs bz-checkbox-paid tw-text-white tw-w-full" @change="hasPayment">
                <template #label>
                  <div class="tw-w-full tw-text-center">
                    <v-icon left class="tw-text-white">
                      {{ attendee.has_payment ? 'price_check' : 'attach_money' }}
                    </v-icon>
                    <span class=" tw-text-white">
                      {{ attendee.has_payment ? $t('pages.event.mod_dash.paid.no_paid') : $t('pages.event.mod_dash.paid.paid') }}
                    </span>
                  </div>
                </template>
              </v-checkbox>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <!-- ----------------- Checkin List ----------------- -->
    <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-gap-4 tw-px-6 tw-mx-auto tw-mt-4">
      <UsersShowCheckinsList ref="checkin-list" :attendee="attendee" :registry="registry" class="tw-col-span-2" />
    </div>
    <!-- ----------------- Login List ----------------- -->
    <div
      v-if="false" 
      class="tw-mx-auto tw-mt-8 tw-grid tw-max-w-3xl tw-grid-cols-1 tw-gap-6 sm:tw-px-6 lg:tw-max-w-7xl lg:tw-grid-flow-col-dense lg:tw-grid-cols-3 print:tw-hidden">
      <div class="tw-space-y-6 lg:tw-col-span-2 lg:tw-col-start-1">
        <!-- Description list-->
        <UsersShowLoginsList :user="user" :registry="registry" is-mod />
      </div>
    </div>
    <!-- ----------------- Print Credential ----------------- -->
    <template v-if="attendee">
      <PrintCredentialCardModal v-if="printCredentialModal" v-model="printCredentialModal" :registry="registry" :user="user" :profile="attendee" :qrlink="qrlink" />
    </template>
    <!-- ----------------- Notification Snackbar ----------------- -->
    <NotificationSnackbar v-model="sActive" :status="sStatus" :msg="sMsg"/>
  </div>
</template>

<script>
import { AttendeeBase, formatAttendee } from '@/models/Attendee'
import { mapState } from 'vuex'
import UsersShowLoginsList from '../Users/UsersShowLoginsList.vue'
import UsersShowCheckinsList from '../Users/UsersShowCheckinsList.vue'
import EventRegistryResource from '@/services/EventRegistryResource'
import ButtonIconCustom from '../ButtonIconCustom.vue'
import PrintCredentialCardModal from './PrintCredentialCardModal.vue'
// import { select_departments, select_provinces, select_disctricts } from '@/utils/Settings'
import { departments } from '@/utils/Departments'
import { provinces } from '@/utils/Provinces'
import { districts } from '@/utils/Districts'

import NotificationSnackbar from '@/components/Event/NotificationSnackbar.vue'

export default {
  name: 'RegistriesParticipantsActions',
  components: {
    UsersShowLoginsList,
    UsersShowCheckinsList,
    PrintCredentialCardModal,
    ButtonIconCustom,
    NotificationSnackbar,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    qrLinks: {
      type: Array,
      default: () => ([]),
    },
    registry: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    departments,
    provinces,
    districts,
    language_id: 0,
    loadings: {
      check_in: false,
      load_payment: false,
      print_photocheck: false,
    },
    form: AttendeeBase({}),
    printCredentialModal: false,
    // ----------- Data Notify Snackbar ----------- //
    sActive: false,
    sStatus: '',
    sMsg: '',
  }),
  computed: {
    has_checkin() {
      return this.user.attendees.some((attendee) => attendee.current_checkin_id)
    },
    default_fields() {
      return this.form.default_fields.filter((row) => row.is_active)
    },
    custom_fields() {
      return this.form.registry_form_submission.filter((row) => row.is_active)
        .map((row) => ({
          ...row,
          model: row.name == 'departments' ? this.departments.find((dep) => dep.id == row.model).name : row.model &&
            row.name == 'provinces' ? this.provinces.find((prov) => prov.id == row.model).name : row.model &&
              row.name == 'districts' ? this.districts.find((dist) => dist.id == row.model).name : row.model
          ,
          label: row.name == 'departments' ? this.$t(`app.field_type.${row.name}`) : row.label &&
            row.name == 'provinces' ? this.$t(`app.field_type.${row.name}`) : row.label &&
              row.name == 'districts' ? this.$t(`app.field_type.${row.name}`) : row.label
          ,
        }))
    },
    validLanguages() {
      return this.languages
        .filter((row) => this.attendanceLanguageIds.includes(row.id))
        .map((row) => ({ text: this.$t(`app.${row.name}`), value: row.id }))
    },
    ...mapState('constants', {
      languages: (state) => state.data.languages,
    }),
    ...mapState({
      virtual_room: (state) => state.event.show.data,
    }),
    qrlink() {
      if (this.registry.credential) {
        if (this.registry.credential.has_customlink) {
          return this.registry.credential.qr_custom_link ? this.registry.credential.qr_custom_link : this.qrLinks.find(row => row.registry_form_id == this.attendee.registry_form_id).qr_link
        }
      }
      return this.qrLinks.find(row => row.registry_form_id == this.attendee.registry_form_id).qr_link
    },
    fullName() {
      return `${this.user.first_name} ${this.user.last_name}`
    },
    userattendance() {
      return this.user.attendees
    },
    attendanceLanguageIds() {
      return this.userattendance.map((row) => row.language.id)
    },
    attendee() {
      return this.userattendance.find(
        (row) => row.language.id == this.language_id,
      )
    },
  },
  watch: {
    language_id: {
      handler(value) {
        if (value) {
          this.formatForm()
        }
      },
    },
  },
  mounted() {
    this.initLanguages()
  },
  methods: {
    setSnackbar(active, status, msg) {
      this.sActive = active
      this.sStatus = status
      this.sMsg = msg
    },
    // -------------- Check-in with Mod -------------- //
    checkIn() {
      this.loadings.check_in = true
      EventRegistryResource.Checkins(this.registry.id, this.attendee.id)
        .store(
          {
            virtual_room_id: this.virtual_room.id,
          },
        )
        .then(() => {
          this.setSnackbar(
            true,
            'success',
            `${this.fullName} ` + this.$t('pages.event.mod_dash.msg.checked'),
          )
        })
        .finally(() => {
          this.$refs['checkin-list'].getRows()
          this.loadings.check_in = false
        })
    },
    // -------------- Payment with Mod -------------- //
    hasPayment() {
      this.loadings.load_payment = true
      EventRegistryResource.Paying()
        .update(this.attendee.id, { has_payment: this.attendee.has_payment })
        .then(() => {
          const msg = `${this.fullName} ` + (this.attendee.has_payment ? this.$t('pages.event.mod_dash.msg.paid') : this.$t('pages.event.mod_dash.msg.cancel_paid'))
          this.setSnackbar(
            true,
            'success',
            msg,
          )
        })
        .finally(() => {
          this.loadings.load_payment = false
        })
    },
    initLanguages() {
      this.language_id = this.validLanguages[0].value
    },
    formatForm() {
      this.form = formatAttendee(this.attendee)
    },
  },
}
</script>

<style>
.bz-icon-size {
  font-size: 22px !important;
}

.bz-btn-custom {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bz-checkbox-paid .v-input--selection-controls__input {
  display: none;
}
.bz-app-bar{
  height: 42px !important;
  padding: 0 .5rem;
}
.bz-app-bar .v-toolbar__content {
  height: 42px !important;
}
</style>
