<script>
import { ClickableTypeKeys } from '@/enums/ClickableTypeKeys'
import { TabKeys } from '@/enums/TabKeys'
import { openWindowTab } from '@/utils/openWindowTab'
import { ContentTypeKeys } from '@/enums/ContentTypeKeys'
import { TargetLinkKeys } from '@/enums/TargetLinkKeys'
import { download } from '@/utils/download'
import { mapState } from 'vuex'
import { UserListKeys } from '@/enums/UserListKeys'
import { AppCoreVitalKeys } from '@/enums/AppCoreVitalKeys'
export default {
  computed: {
    ...mapState({
      virtual_room: (state) => state.event.show.data,
      registry: (state) => state.event.constants.registry,
      users: (state) => state.event.socket.users,
      channels: (state) => state.event.constants.channels,
      threads: (state) => state.event.constants.threads,
    }),
    hasReps() {
      return this.virtual_room.reps.length > 0
    },
  },
  methods: {
    clickActionEvent(item) {
      console.log('click action triggered')
      const {
        clickable,
        clickable: {
          click_action_type: { name },
        },
      } = item
      console.log({ item })
      console.log(item.clickable)
      console.log(item.clickable.click_action_type.name)

      if (ClickableTypeKeys.link_content === name) {
        console.log(item.clickable.content_item_element)
        this.contentItemEvent(item.clickable.content_item_element)
      }
      if (ClickableTypeKeys.link_location === name) {
        const room = this.$store.getters['event/getRoomFromConstants'](
          clickable.link_location_virtual_room_id,
        )
        if (room) {
          this.toVirtualRoom(room.slug)
        }
        if (this.$store.state.event.flexible.event_calendar_modal) {
          this.$store.commit('event/setFlexible', {
            event_calendar_modal: false,
          })
        }
      }
      if (ClickableTypeKeys.open_favorites === name) {
        this.$store.commit('event/setProfile', {
          modal: true,
          tab: TabKeys.saved,
        })
      }
      if (ClickableTypeKeys.open_calendar === name) {
        // console.log('calendar')
        this.$store.commit('event/setFlexible', {
          event_calendar_modal: true,
        })
      }
      if (ClickableTypeKeys.open_external_url === name) {
        openWindowTab(clickable.external_url_link)
      }
      if (ClickableTypeKeys.open_leaderboard === name) {
        this.$store.commit('event/setProfile', {
          modal: true,
          tab: TabKeys.points,
        })
      }
      // Donovann: Edit Open Channel
      if (ClickableTypeKeys.open_channel === name) {
        const Channel = this.channels.find((row) => row.id === item.clickable.link_channel_id)
        this.$store.commit('chat/setState', {
          showChat: true,
          view: 'HOME',
          home_tab: 'PRIVATE',
        })
        this.$nextTick().then(() => {
          this.$store.commit('chat/setState', {
            showChat: true,
            view: 'ACTIVE_PUBLIC_CHANNEL',
            public_channel: Channel,
          })
        })
      }
      if (ClickableTypeKeys.open_photobooth === name) {
        this.$store.commit('event/setPhotobooth', {
          modal: true,
          photobooth_config_id: clickable.photobooth_config_id,
        })
      }
      if (ClickableTypeKeys.open_support_pane === name) {
        // check if has support
        if (!this.registry.has_support) {
          return
        }
        this.$store.commit('chat/setState', {
          showChat: true,
          view: 'HOME',
          home_tab: 'SUPPORT',
        })
      }
      if (ClickableTypeKeys.open_reps_pane === name) {
        // check if has support
        if (!this.hasReps) {
          return
        }
        this.$store.commit('chat/setState', {
          showChat: true,
          view: 'ACTIVE_USERS',
        })
        this.$store.commit('event/setSocket', {
          filters: [UserListKeys.reps],
        })
      }
      // Donovann: Add Open User Chat - Open Public Chat
      if (ClickableTypeKeys.open_user_chat === name) {
        const partner_user = this.users.find((row) => row.id === item.clickable.user_id)
        this.$store.commit('chat/setState', {
          showChat: true,
          view: 'HOME',
        })
        this.$nextTick().then(() => {
          this.$store.commit('chat/setState', {
            showChat: true,
            view: 'CHAT',
            talking_to: partner_user,
          })
        })
      }
      if (ClickableTypeKeys.open_public_chat === name) {
        const Threads = this.threads.find((row) => row.id === item.clickable.link_thread_id)
        this.$store.commit('chat/setState', {
          showChat: true,
          view: 'HOME',
          home_tab: 'PRIVATE',
        })
        this.$nextTick().then(() => {
          this.$store.commit('chat/setState', {
            showChat: true,
            view: 'ACTIVE_QA_CHANNEL',
            parrent_thread: Threads,
            thread_has_chat: true,
          })
        })
      }
      // Donovann: Reload Page
      if (ClickableTypeKeys.reload_page === name) {
        window.location.reload()
      }

      if (ClickableTypeKeys.custom_form === name) {
        this.$store.commit('event/setCustomForm', {
          modal: true,
          form: item.clickable.custom_form,
        })
      }
    },
    toVirtualRoom(slug) {
      // check if slug
      // check from router
      if (this.$route.path.split('/').includes(slug)) {
        return
      }
      this.$router.push({
        name: this.is_preview ? 'event-preview.route' : 'event.route',
        params: {
          slug,
          eventSlug: this.registry.slug,
        },
      })
    },
    contentItemEvent(content_item_element) {
      const {
        target_link_type_id,
        download_item_url,
        doorway_item_room_id,
        link_item_url,
        id,
        content_type: { name },
      } = content_item_element
      console.log({ id })
      if (ContentTypeKeys.download == name) {
        console.log(target_link_type_id)
        if (TargetLinkKeys.normal.value == target_link_type_id) {
          download(download_item_url, download_item_url.split('/').pop(0))
        }
        if (TargetLinkKeys.target.value == target_link_type_id) {
          // this.contentItemModal = true
          // this.contentItemData = content_item_element
          this.$store.commit('event/setContentItem', {
            contentItemModal: true,
            contentItemData: content_item_element,
          })
        }
      }
      if (ContentTypeKeys.doorway == name) {
        const room =
          this.$store.getters['event/getRoomFromConstants'](
            doorway_item_room_id,
          )
        if (room) {
          this.toVirtualRoom(room.slug)
        }
      }
      if (ContentTypeKeys.link == name) {
        console.log(link_item_url)
        console.log(target_link_type_id)
        if (TargetLinkKeys.normal.value == target_link_type_id) {
          openWindowTab(link_item_url)
        }
        if (TargetLinkKeys.target.value == target_link_type_id) {
          this.$store.commit('event/setContentItem', {
            contentItemModal: true,
            contentItemData: content_item_element,
          })
        }
      }
      if (ContentTypeKeys.video == name) {
        this.$store.commit('event/setContentVideo', {
          contentVideoModal: true,
          contentVideoData: content_item_element,
        })
      }
      const core_vital_type = AppCoreVitalKeys.EventContent
      const solid = this.$store.getters['event/plainMetricsPayload']
      this.$store.dispatch('general/recordCoreVital', {
        payload: {
          core_vital_type,
          simple_log: name,
          ...solid,
          content_library_element_id: id,
        },
        core_vital_type,
      })
    },
  },
  render() {
    return this.$scopedSlots.default({
      slotClickActionEvent: (item) => this.clickActionEvent(item),
    })
  },
}
</script>
