<template>
  <v-autocomplete
    v-model="input_value"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    cache-items
    item-text="name"
    item-value="id"
    :label="label"
    outlined
    rounded
    hide-no-data
    hide-details
    v-bind="$attrs"
    :multiple="multiple"
    v-on="$listeners"
  >
    <template #selection="data">
      <v-chip
        v-if="multiple"
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
      >
        <v-avatar left>
          <v-img :src="data.item.avatar" />
        </v-avatar>
        {{ data.item.name }}
      </v-chip>
      <v-chip
        v-else
        v-bind="data.attrs"
        :input-value="data.selected"
        @click="data.select"
      >
        <v-avatar left>
          <v-img :src="data.item.avatar" />
        </v-avatar>
        {{ data.item.name }}
      </v-chip>
    </template>
    <template #item="data">
      <v-list-item-avatar>
        <img :src="data.item.avatar" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="data.item.name" />
        <v-list-item-subtitle v-html="data.item.email" />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    value: {
      type: [Array, Number],
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    filterName: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Usuarios',
    },
  },
  data: () => ({
    loading: false,
    items: [],
    search: null,
    awaitingSearch: false,
  }),
  computed: {
    ...mapState({
      users: (state) => state.constants.run_time.users_all,
      isLoading: (state) => state.constants.is_loading.users,
    }),
    rows() {
      return this.users.data.map((row) => ({
        ...row,
        name: `${row.first_name} ${row.last_name}`,
      }))
    },
    input_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    search: {
      handler() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getUsersSearch()
            this.awaitingSearch = false
          }, 1000) // 1 sec delay
          this.awaitingSearch = true
        }
      },
    },
  },
  mounted() {
    this.getUsersSearch()
  },
  methods: {
    getUsersSearch() {
      this.loading = true
      this.$store.dispatch('constants/searchUsers', {
        search: this.search,
        id: this.input_value,
      }).then(() => {
        this.items.push(this.rows.find(row => row.id == this.input_value))
      })
      setTimeout(() => {
        this.items = this.rows
        this.loading = false
      }, 1000)
    },
  },
}
</script>

<style></style>
