<template>
    <renderless-layout>
        <v-container id="forms_index_page" fluid class="tw-p-0">
            <StickyHeader :nav="nav">
                <template #actions>
                    <div class="tw-space-x-3">
                        <AppBtn
                            color="green-light"
                            type="button"
                            icon="download_cloud"
                            class="tw-text-xs"
                            :disabled="exportEntriesLoading"
                            :loading="exportEntriesLoading"
                            @click="exportEntries"
                        >
                            {{ $t('app.export') }}
                        </AppBtn>
                        <AppBtn icon="plus" :to="{ name: 'admin.registries.custom-forms.create' }" color="white">
                            {{ $t('admin.app.new_form') }}
                        </AppBtn>
                    </div>
                </template>
            </StickyHeader>
            <v-row class="tw-m-0 tw-p-0 mx-auto tw-max-w-7xl">
                <v-col cols="12">
                    <v-container fluid class="tw-space-y-6 tw-py-6">
                        <v-card class="tw-rounded-3xl tw-shadow-md" white>
                            <v-container class="tw-p-0" fluid>
                                <div class="tw-grid tw-border-b tw-pt-3 lg:tw-grid-cols-5">
                                    <div class="tw-gap-6 tw-px-4 tw-pb-3 lg:tw-col-span-2">
                                        <v-text-field 
                                            v-model="search" 
                                            outlined 
                                            rounded 
                                            prepend-inner-icon="search"
                                            dense 
                                            :label="$t('app.search')" 
                                            hide-details 
                                        />
                                    </div>
                                </div>
                            </v-container>
                            <v-container class="tw-overflow-hidden tw-p-0" fluid>
                                <v-data-table 
                                    v-model="selected" 
                                    :search="search" 
                                    :headers="headers" 
                                    :items="parsedRows"
                                    :options.sync="options" 
                                    :server-items-length="totalRows" 
                                    :loading="loading"
                                    class="elevation-1" 
                                    :footer-props="footer_props"
                                    :class="{ mobile: $vuetify.breakpoint.mdAndDown }"
                                >
                                    <template #body="{ items }">
                                        <transition mode="out-in" name="fade">
                                            <tbody v-if="items.length" key="main-table">
                                                <tr 
                                                    v-for="item in items" 
                                                    :key="item.id"
                                                    class="tw-cursor-pointer sm:tw-py-4" 
                                                    @click="showForm(item.id)"
                                                >
                                                    <td class="tw-whitespace-nowrap">
                                                        {{ item.title }}
                                                    </td>
                                                    <td class="tw-whitespace-nowrap">
                                                        {{ item.parsedTypeForm }}
                                                    </td>
                                                    <td class="tw-whitespace-nowrap">
                                                        {{ item.parsedUpdatedAt }}
                                                    </td>
                                                    <td>
                                                        <v-btn 
                                                            fab text small
                                                            class="tw-text-gray-300 focus:tw-text-gray-600 focus:tw-outline-none focus:tw-ring-primary-500">
                                                            <svg 
                                                                xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24" stroke="currentColor"
                                                                class="tw-h-6 tw-w-6">
                                                                <path 
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    stroke-width="2" d="M9 5l7 7-7 7" />
                                                            </svg>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </transition>
                                    </template>
                                </v-data-table>
                            </v-container>
                        </v-card>
                    </v-container>
                </v-col>
            </v-row>
            <CustomFormEntriesExportModal v-model="toggle_export" />
        </v-container>
    </renderless-layout>
</template>
<script>
import StickyHeader from '@/components/StickyHeader'
import AppBtn from '@/components/App/AppBtn.vue'
import { mapState } from 'vuex'
import { commonTimeFormat } from '@/utils/dateHelpers'
import CustomFormEntriesExportModal from '@/components/Forms/CustomFormEntriesExportModal.vue'

export default {
    name: 'CustomForms',
    components: {
        AppBtn,
        StickyHeader,
        CustomFormEntriesExportModal,
    },
    data: () => ({
        search: '',
        selected: undefined,
        totalRows: 0,
        rows: [],
        loading: false,
        options: {},
        awaitingSearch: false,
        exportEntriesLoading: false,
        toggle_export: false,
    }),
    computed: {
        ...mapState({
            registry: (state) => state.registries.show.data,
        }),
        nav() {
            return [
                {
                    name: this.$t('app.event'),
                    route: { name: 'admin.registries.index' },
                },
                {
                    name: this.registry.label,
                    route: {
                        name: 'admin.registries.show.home',
                        params: { id: this.registry.id },
                    },
                },
                {
                    name: this.$t('admin.home.labels.forms'),
                    route: {},
                },
            ]
        },
        headers() {
            return [
                {
                    text: this.$t('admin.app.name'),
                    align: 'start',
                    sortable: false,
                    value: 'title',
                },
                {
                    text: this.$t('app.type'),
                    align: 'start',
                    sortable: false,
                    value: 'type_form',
                },
                {
                    text: this.$t('admin.app.updated_at'),
                    align: 'start',
                    sortable: false,
                    value: 'updated_at',
                },
                {
                    text: '',
                    value: 'actions',
                    sortable: false,
                },
            ]
        },
        parsedRows() {
            return this.rows.map((row) => ({
                ...row,
                parsedTypeForm: this.$t(`app.type_form.${row.type_form}`),
                parsedCreatedAt: commonTimeFormat(row.created_at),
                parsedUpdatedAt: commonTimeFormat(row.updated_at),
            }))
        },
        footer_props() {
            const obj = {
                'items-per-page-options': [
                    10, 20, 30, 40, 50,
                ],
            }
            return obj
        },
        isMobile() {
            return this.$vuetify.breakpoint.mdAndDown
        },
    },
    watch: {
        options: {
            handler() {
                this.getRows()
            },
            deep: true,
        },
        search: {
            handler() {
                if (!this.awaitingSearch) {
                    setTimeout(() => {
                        this.getRows()
                        this.awaitingSearch = false
                    }, 1000)
                }
                this.awaitingSearch = true
            },
            deep: true,
        },
    },
    methods: {
        showForm(id) {
            return this.$router.push({
                name: 'admin.registries.custom-forms.edit',
                params: { id: id },
            })
        },
        getRows() {
            this.loading = true
            const { sortBy, sortDesc, page, itemsPerPage } = this.options

            this.$store
                .dispatch('forms/index', {
                    params: {
                        page: page,
                        rowsPerPage: itemsPerPage,
                        sortBy: sortBy,
                        sortDesc: sortDesc,
                        search: this.search,
                        registry_id: this.registry.id,
                    },
                })
                .then((res) => {
                    const { total, data } = res.data.data
                    this.rows = data
                    this.totalRows = total
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        exportEntries() {
            this.toggle_export = true
        },
    },
}
</script>