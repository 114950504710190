<script>
import { mapState } from 'vuex'

export default {
    data: () => ({
        contact_loading: false,
    }),
    computed: {
        ...mapState({
            contactList: (state) => state.event.constants.event_connections.map((row) => row.event_user_id),
        }),
    },
    methods: {       
        // --------------------- View Attendee --------------------- //
        viewAttendee(item) {
            this.$store.commit('event/setCard', {
                attendee: item,
                modal: true,
            })
        },
        // --------------------- Add Contact --------------------- //
        addContact(item) {
            this.contact_loading = true
            this.$store.dispatch('event/createEventConnection', {
                    event_user_id: item.id,
                })
                .then(() => {
                    this.$store.dispatch('notifications/addNotification', {
                        title: this.$t('bz2.event.bussines_card.title'),
                        body: this.$t('bz2.event.bussines_card.success'),
                        status: 'success',
                    })
                })
                .catch(() => {
                    this.$store.dispatch('notifications/addNotification', {
                        title: this.$t('bz2.event.bussines_card.title'),
                        body: this.$t('bz2.event.bussines_card.error'),
                        status: 'error',
                    })
                })
                .finally(() => {
                    this.contact_loading = false
                }) 
        },
        // --------------------- Open Chat --------------------- //
        openChat(item) {
            this.$store.commit('chat/setState', {
                showChat: true,
                view: 'HOME',
            })
            this.$nextTick().then(() => {
                this.$store.commit('chat/setState', {
                    showChat: true,
                    view: 'CHAT',
                    talking_to: item,
                })
            })
            this.$store.commit('event/setCard', {
                modal: false,
            })
            this.$store.commit('event/setProfile', {
                modal: false,
            })
        },
        // --------------------- Contact List  --------------------- //
        added(item) {
            return this.contactList.includes(item)
        },
    },
    render() {
        return this.$scopedSlots.default({
            contact_loading: this.contact_loading,
            slotViewAttendee: (item) => this.viewAttendee(item),
            slotAddContact: (item) => this.addContact(item),
            slotOpenChat: (item) => this.openChat(item),
            slotAdded: (item) => this.added(item),
        })
    },
}
</script>