import { NotificationStatusKeys } from '@/enums/NotificationStatusKeys'
import { formatterArray } from '@/utils/formatterArray'
import { simpleSound } from '@/utils/simpleSound'
export const Notifications = {
  namespaced: true,
  state: {
    count: 0,
    notifications: [],
    notifyModal: false,
    notifyInfo: {},
    notifyModalQueue: [],
  },
  mutations: {
    count(state, payload) {
      state.count = payload
    },
    notifications(state, payload) {
      state.notifications.push({
        id: payload.id,
        title: payload.title,
        body: payload.body,
        type: payload.type ? payload.type : 'simple',
        clickable: payload.clickable,
        status: payload.status,
        action_label: payload.action_label,
        msg_meta:
          payload.type == 'msg'
            ? { ...payload.msg_meta }
            : {
                partner_user: {},
                channel_id: 0,
              },
      })
    },
    notificationsRemove(state, payload) {
      state.notifications = state.notifications.filter((n) => n.id !== payload)
    },
    setNotification(state, obj) {
      Object.keys(obj).forEach((k) => (state[k] = obj[k]))
    },
  },
  getters: {
    notifications(state) {
      return state.notifications
    },
    count(state) {
      return state.count
    },
  },

  actions: {
    addNotification({ commit, state }, payload) {
      const currentId = state.count
      console.log('Notification', { payload })
      const duration = Object.hasOwn(payload, 'duration')
        ? payload.duration
        : 6000
      const status = Object.hasOwn(payload, 'status')
        ? payload.status
        : 'success'
      if (!NotificationStatusKeys[status]) {
        const values = Object.keys(NotificationStatusKeys)
        console.error(
          'Wrong value for the `status` prop.' +
            `Given ${
              payload.status
            }, expected one of: "${formatterArray().format(values)}".`,
        )
        return
      }

      if (payload.has_sound) {
        simpleSound()
      }
      commit('notifications', { id: currentId, ...payload, status })
      // check if preferences sound on

      if (duration != 0) {
        setTimeout(() => {
          console.log('Removing: ' + currentId)
          commit('notificationsRemove', currentId)
        }, duration)
      }

      // let next;
      commit('count', state.count + 1)
    },
  },
}
