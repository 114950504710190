import { Extension } from '@tiptap/core'
import '@tiptap/extension-text-style'
export const SpanClass = Extension.create({
  name: 'spanClass',
  addOptions: {
    types: ['textStyle'],
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          spanClass: {
            default: 'none',
            renderHTML: (attributes) => {
              if (!attributes.spanClass) {
                return {}
              }
              return {
                class: attributes.spanClass,
              }
            },
            parseHTML: (element) => element.classList.value,
          },
        },
      },
    ]
  },
  addCommands() {
    return {
      setSpanClass:
        (spanClass) =>
        ({ chain }) => {
          return chain().setMark('textStyle', { spanClass }).run()
        },
      unsetSpanClass:
        () =>
        ({ chain }) => {
          return chain()
            .setMark('textStyle', { spanClass: '' })
            .removeEmptyTextStyle()
            .run()
        },
    }
  },
})
