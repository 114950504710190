<template>
  <v-dialog
    v-model="open"
    persistent
    max-width="768px"
    content-class="tw-rounded-3xl"
    scrollable
  >
    <v-form
      ref="form"
      v-model="valid"
      :disabled="form_disabled_state"
      lazy-validation
      enctype="multipart/form-data"
      @submit.prevent="update"
    >
      <v-card class="tw-rounded-3xl tw-border-0">
        <ModalHeader @close-modal="closeModal">{{
          $t('admin.events.items.sections.Actividades y calendario')
        }}</ModalHeader>
        <v-divider />
        <v-card-text class="tw-p-0" style="max-height: 700px">
          <v-responsive class="tw-py-0">
            <v-container class="tw-bg-gray-100">
              <v-row class="mb-5">
                <!-- <v-col cols="12" md="4" class="px-5 pt-5 mx-auto">
                  <form-description>
                    <template #title> {{ $t('app.config') }} </template>
                    <template #copy>
                      {{ $t('admin.events.cal_act_descriptions') }}
                    </template>
                  </form-description>
                </v-col> -->
                <v-col cols="12" class="mx-auto pa-5 tw-space-y-4">
                  <v-card class="mx-auto tw-rounded-3xl tw-shadow-md">
                    <v-container class="pa-5">
                      <ToggleGroup
                        v-model="mountedSection.data.has_activities"
                        extra-style="gray-border"
                      >
                        <template #title>
                          {{ $t('admin.events.activity_btn') }}
                        </template>
                        <template #extras>
                          <div>
                            <form-label>
                              {{ $t('admin.events.image_btn_activities') }}
                            </form-label>
                            <!-- <DropzonePreview

                                :height="250"
                                :width="450"
                                media-group="registries"
                              /> -->

                            <DropzoneOverlayPreview
                              v-model="mountedSection.data.activities_url"
                              :height="250"
                              :width="450"
                              :bypass="'no-check'"
                              media-group="registries"
                            />
                          </div>
                          <div class="tw-mt-6">
                            <div
                              class="tw-flex tw-w-full tw-items-end tw-justify-between"
                            >
                              <FormLabel>
                                {{ $t('admin.events.pdf_activites') }}
                              </FormLabel>
                              <div>
                                <v-switch
                                  v-if="!mountedSection.data.activities_pdf"
                                  v-model="is_custom_link"
                                  inset
                                  label="Custom link"
                                />
                              </div>
                            </div>
                            <DropzoneOverlayPreview
                              v-if="!is_custom_link"
                              v-model="mountedSection.data.activities_pdf"
                              media-group="activities_pdf"
                              :bypass="'no-check'"
                            />
                            <div
                              v-if="
                                is_custom_link &&
                                !mountedSection.data.activities_pdf
                              "
                              class="tw-mt-4 tw-grid tw-grid-cols-3 tw-gap-6"
                            >
                              <div class="tw-col-span-2">
                                <v-text-field
                                  v-model="custom_link"
                                  :rules="[rules.url]"
                                  :label="$t('admin.app.link')"
                                  outlined
                                  validate-on-blur
                                  dense
                                  rounded
                                />
                              </div>
                              <div class="tw-pt-0.5">
                                <AppBtn
                                  type="button"
                                  color="primary-dark"
                                  icon="check"
                                  @click="persistLink"
                                >
                                  {{ $t('app.save') }}
                                </AppBtn>
                              </div>
                            </div>
                            <!-- <div class="tw-flex tw-justify-end">

                            </div> -->
                            <!-- <div
                                v-if="mountedSection.data.activities_pdf"
                                class="tw-mb-6"
                              >
                                <div class="tw-flex tw-items-center tw-space-x-4">
                                  <a
                                    :href="mountedSection.data.activities_pdf"
                                    target="_blank"
                                  >
                                    <v-btn
                                      rounded
                                      class="tw-px-8 tw-normal-case tw-shadow-md focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-200"
                                    >
                                      {{ $t('app.view') }}
                                    </v-btn>
                                  </a>
                                  <v-btn
                                    rounded
                                    class="tw-px-8 tw-normal-case tw-shadow-md focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-200"
                                    @click="toggle_dropzone = !toggle_dropzone"
                                  >
                                    {{ $t('app.change') }}
                                  </v-btn>
                                </div>
                              </div>
                              <v-card
                                v-show="toggle_dropzone"
                                class="mx-auto tw-mb-6 tw-rounded-3xl tw-shadow-md"
                              >
                                <v-container>
                                  <dropzone
                                    v-model="mountedSection.data.activities_pdf"
                                    :bypass="'no-check'"
                                    media-group="activities_pdf"
                                    @on-success="uploadedFile"
                                  />
                                </v-container>
                              </v-card> -->
                          </div>
                        </template>
                      </ToggleGroup>
                    </v-container>
                  </v-card>
                  <v-card class="mx-auto tw-rounded-3xl tw-shadow-md">
                    <v-container class="pa-5">
                      <ToggleGroup
                        v-model="mountedSection.data.has_calendar"
                        extra-style="gray-border"
                      >
                        <template #title>
                          {{ $t('admin.events.booth_calendar') }}
                        </template>
                        <template #extras>
                          <form-label>
                            {{ $t('admin.events.image_btn_activities_image') }}
                          </form-label>
                          <DropzoneOverlayPreview
                            v-model="mountedSection.data.calendar_url"
                            :height="250"
                            :width="450"
                            :bypass="'no-check'"
                            media-group="registries"
                          />
                        </template>
                      </ToggleGroup>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-responsive>
        </v-card-text>
        <v-divider />

        <v-card-actions>
          <v-alert
            v-model="errorAlert"
            dismissible
            color="red"
            border="left"
            elevation="2"
            colored-border
            icon="error"
            transition="fade-transition"
          >
            {{ errorMsg }}</v-alert
          >
          <v-spacer />
          <AppBtn
            type="button"
            :disabled="form_loading"
            color="transparent"
            @click="closeModal"
          >
            {{ $t('app.cancel') }}
          </AppBtn>
          <AppBtn
            type="submit"
            :loading="form_loading"
            color="primary"
            icon="check"
          >
            {{ $t('app.save') }}
          </AppBtn>
          <!-- <v-btn
            color="blue darken-1"
            text
            rounded
            :disabled="form_loading"
            class="tw-px-8 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-white"
            @click="closeModal"
          >
            {{ $t('app.cancel') }}</v-btn
          > -->
          <!-- <v-btn
            color="primary darken-1"
            rounded
            type="submit"
            :loading="form_loading"
            class="text--white text--lighten-4 tw-px-8 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-300"
          >
            {{ $t('app.save') }}</v-btn
          > -->
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import FormLabel from '@/components/FormInputs/FormLabel.vue'
import ModalHeader from '@/components/ModalHeader'
// import FormDescription from '@/components/FormDescription'
import ToggleGroup from '../FormInputs/ToggleGroup.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import DropzoneOverlayPreview from '../DropzoneOverlayPreview.vue'
import { rules } from '@/utils/rules'

export default {
  components: {
    FormLabel,
    ModalHeader,
    // RadioBtn,
    // FormDescription,

    // Dropzone,
    ToggleGroup,
    AppBtn,
    DropzoneOverlayPreview,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    rules,
    custom_link: '',
    is_custom_link: false,
    toggle_dropzone: false,
    errorAlert: false,
    errorMsg: '',
    exam: '',
    isLoading: false,
    form_loading: false,
    form_disabled_state: false,
    valid: true,
    mountedSection: {},
    isLoadingRooms: false,
  }),
  computed: {
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  created() {
    this.mountedSection = JSON.parse(JSON.stringify(this.section))
    if (!this.mountedSection.data.activities_pdf) {
      this.toggle_dropzone = true
    }
  },
  methods: {
    persistLink() {
      this.mountedSection.data.activities_pdf = this.custom_link
      this.is_custom_link = false
    },
    uploadedFile(res) {
      this.mountedSection.data.activities_pdf = res.media_element.link
      this.toggle_dropzone = false
    },
    closeModal() {
      this.open = false
    },
    update() {
      this.errorAlert = false
      if (!this.$refs.form.validate()) {
        return
      }

      this.$emit('updated-section', this.mountedSection)
      this.open = false
    },
  },
}
</script>

<style></style>
