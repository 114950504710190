<template>
  <v-dialog
    v-model="open"
    persistent
    max-width="1024px"
    scrollable
    content-class="tw-rounded-3xl fill-height tw-max-h-[700px] "
  >
    <v-form
      ref="form_dooway"
      v-model="valid"
      :disabled="form_loading"
      lazy-validation
      enctype="multipart/form-data"
      @submit.prevent="onSubmit"
    >
      <v-card class="fill-height tw-rounded-3xl tw-border-0 tw-shadow-md">
        <ModalHeader @close-modal="closeModal">HTML Personalizado</ModalHeader>
        <v-card-text class="fill-height tw-p-0">
          <v-responsive class="fill-height tw-py-0">
            <v-container class="fill-height tw-bg-gray-100">
              <v-row class="mb-3 fill-height">
                <v-col cols="12" class="mx-auto pa-5 fill-height">
                  <EditorCodemirror
                    v-if="open"
                    v-model="custom_html"
                    class="fill-height tw-rounded-xl"
                    :options="cmOptions"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-responsive>
        </v-card-text>
        <v-card-actions>
          <v-alert
            v-model="alert"
            dismissible
            color="red"
            border="left"
            elevation="2"
            colored-border
            icon="error"
            transition="fade-transition"
          >
            {{ errorMsg }}
          </v-alert>

          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            rounded
            :disabled="form_loading"
            class="tw-px-8 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-white"
            @click="open = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary darken-1"
            rounded
            type="submit"
            :loading="form_loading"
            class="text--white text--lighten-4 tw-px-8 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-300"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ModalHeader from '@/components/ModalHeader'

import 'codemirror/mode/htmlmixed/htmlmixed.js'
// import 'codemirror/mode/css/css'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import EditorCodemirror from '@/components/FormInputs/EditorCodemirror'
import { rules } from '@/utils/rules'
export default {
  components: {
    EditorCodemirror,
    ModalHeader,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    customHtml: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    has_mounted: false,
    cmOptions: {
      tabSize: 4,
      mode: 'text/html',
      theme: 'material',
      lineNumbers: true,
      line: true,
      lineWrapping: true,
      autoRefresh: true,
      matchBrackets: true,
    },
    errorMsg: '',
    exam: '',
    rules,
    alert: false,
    form_loading: false,
    form_disabled_state: false,
    valid: true,
    mountedRoomElement: {},
  }),
  computed: {
    custom_html: {
      get() {
        return this.customHtml
      },
      set(newValue) {
        this.$emit('update:customHtml', newValue)
      },
    },
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  // mounted() {
  //   setTimeout(() => {
  //     this.has_mounted = true
  //   }, 1000)
  // },
  // watch: {
  //   open: {
  //     handler(newVal) {
  //       if (newVal) {
  //         // setTimeout(() => {
  //         this.has_mounted = true
  //         // }, 1000)
  //       } else {
  //         this.has_mounted = false
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    onSubmit() {
      this.open = false
    },
    closeModal() {
      this.open = false
    },
  },
}
</script>

<style></style>
