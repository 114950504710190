<template>
  <v-container class="fill-height tw-flex tw-items-start tw-overflow-y-scroll tw-bg-gray-100 tw-px-6 lg:tw-px-10" fluid>
    <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-8 tw-gap-4 tw-w-full">
      <!-- ------------------- QR Scan Camera - Lector QR ------------------- -->
      <div class="tw-py-5 lg:tw-col-span-2 tw-relative">
        <div v-if="loadingMob" class="bz-loading-checkinmob tw-rounded-3xl">
          <v-progress-circular
            :size="50"
            :width="3"
            color="primary"
            indeterminate
          />
        </div>
        <v-card class="tw-rounded-3xl tw-shadow-md tw-mb-4 tw-relative">
          <div v-if="true" class="bz-active-camera tw-flex tw-items-center tw-justify-center tw-overflow-hidden">
            <ModQRScanner ref="ModQR" @validate-user="validateQR"/>
          </div>
          <v-divider />
          <div v-if="true" class="tw-py-4 tw-px-2">
            <v-text-field
              ref="lector"
              v-model="valuelink"
              label="Lector QR"
              outlined
              rounded
              hide-details
              dense
              class="tw-text-sm"
              autofocus
            />
          </div>
        </v-card>
      </div>
      <!-- ------------------- Attendees (Table - Actions) ------------------- -->
      <div v-if="!isMobile" class="lg:tw-col-span-6">
        <transition name="fade-transition" mode="out-in">
          <v-col v-if="showLoading" key="canvas_loading" class="tw-h-full">
            <div class="tw-flex tw-h-full tw-items-center tw-justify-center">
              <v-progress-circular :size="70" :width="7" color="primary" indeterminate />
            </div>
          </v-col>
          <template v-else>
            <transition name="fade-transition" mode="out-in">
              <v-col v-if="view == ModAttendeesViews.index" key="registries-participants-table" cols="12" class="mx-auto tw-p-0 lg:tw-py-5 lg:tw-px-5">
                <RegistriesParticipantsTable ref="participants-table" is-mod :registry="registry" @show-item="showItem" />
              </v-col>
              <v-col v-if="view == ModAttendeesViews.show" key="registries-participant-show" cols="12" class="mx-auto tw-p-0 lg:tw-py-5 lg:tw-px-5">
                <RegistriesParticipantActions ref="participantActions" :registry="registry" :user="user" :qr-links="qr_links" @on-back="onBack" @set-user="setUser" />
              </v-col>
            </transition>
          </template>
        </transition>
      </div>
      <!-- ------------------- Confirm Check-out ------------------- -->
      <AppConfirmDialog ref="checkoutModal" />
      <!-- ----------------- Notification Snackbar ----------------- -->
      <NotificationSnackbar v-model="sActive" :status="sStatus" :msg="sMsg"/>
    </div>
  </v-container>
</template>

<script>
import EventRegistryResource from '@/services/EventRegistryResource'
import { mapState } from 'vuex'
import RegistriesParticipantsTable from '../Registries/RegistriesParticipantsTable.vue'
import RegistriesParticipantActions from './RegistriesParticipantActions.vue'
import { NotificationStatusKeys } from '@/enums/NotificationStatusKeys'
import ModQRScanner from './ModQRScanner.vue'
import AppConfirmDialog from '@/components/App/AppConfirmDialog.vue'
import NotificationSnackbar from '@/components/Event/NotificationSnackbar.vue'
import { simpleSoundCheckin } from '@/utils/simpleSoundCheckin'
const ModAttendeesViews = {
  index: 'index',
  show: 'show',
}
export default {
  components: { RegistriesParticipantsTable, RegistriesParticipantActions, ModQRScanner, AppConfirmDialog, NotificationSnackbar },
  data: () => ({
    showLoading: false,
    ModAttendeesViews,
    view: ModAttendeesViews.index,
    user: {},
    qr_links: [],
    valuelink: '',
    awaitingLink: false,
    userMobile: {},
    // ----------- Data Notify Snackbar ----------- //
    sActive: false,
    sStatus: '',
    sMsg: '',
    loadingMob: false,
  }),
  computed: {
    ...mapState({
      current_user: (state) => state.session.user,
      registry: (state) => state.event.constants.registry,
      users: (state) => state.event.socket.users,
      usersLocation: (state) => state.event.socket.usersLocation,
      current_config_language_key: (state) =>
        state.event.constants.event_configs.data.current_config_language_key,
      virtual_room: (state) => state.event.show.data,
    }),
    current_form() {
        return this.registry.forms.find(
            (row) => row.language.name === this.current_config_language_key,
        )
    },
    userInThisLocation() {
      return this.usersLocation
        .filter((row) => row.user_id !== this.current_user.id)
        .filter(
          (row) =>
            row.slug === this.currentSelectedSlug &&
            row.registry_uuid === this.registry.uuid,
        )
        .map((row) => row.user_id)
    },
    usersInMyRoom() {
      return this.users.filter((row) =>
        this.userInThisLocation.includes(row.id),
      )
    },
    fullName() {
      return `${this.user.first_name} ${this.user.last_name}`
    },
    userattendance() {
      return this.userMobile.attendees
    },
    attendee() {
      return this.userattendance.find(
        (row) => row.language.name == this.current_config_language_key,
      )
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown
    },
  },
  watch: {
    // http://localhost:8080/registry/demo/magic/c75983a8-5ea6-4740-9f9a-a503eae27eb1/a45b11e0-8eed-4025-9365-d8995b0d1a20?tenant=dov
    // http://localhost:8080/registry/demo/magic/c75983a8-5ea6-4740-9f9a-a503eae27eb1/7e84d7d8-76dc-4317-afa2-fab2231101b3s?tenant=dov
    // http://localhost:8080/registry/demo/magic/c75983a8-5ea6-4740-9f9a-a503eae27eb1/7e84d7d8-76dfab223f?tenant=dov
    valuelink: {
      handler() {
        if (!this.awaitingLink) {
          setTimeout(() => {
            this.filterQR()
            this.awaitingLink = false
          }, 2200)
        }
        this.awaitingLink = true
      },
      deep: true,
    },
  },
  mounted() {
    this.focusLector()
  },
  methods: {
    setSnackbar(active, status, msg) {
      this.sActive = active
      this.sStatus = status
      this.sMsg = msg
    },
    // ------------------- QR Scann ------------------- //
    validateQR({ pathname }) {
      const parts = pathname.split('/')
      const user_uuid = parts[parts.length - 1]
      if (user_uuid) {
        if (user_uuid.length >= 36) {
          if (this.isMobile) {
            this.checkInMob(user_uuid)
          } else {
            this.getUser(user_uuid).then(() => {
                this.$nextTick().then(() => {
                  this.checkInDesk()
                }).finally(() => {
                  this.valuelink = ''
                  this.hasCheckedFalse()
                })
              })
            return 
          }
        }
        this.valuelink = ''
        return
      }
    },
    // ------------------- Lector QR ------------------- //
    filterQR() {
      const pathname = this.valuelink
      this.validateQR({pathname})
    },
    // ------------------- Check-in Desktop ------------------- //
    checkInDesk() {
      this.$refs.participantActions.checkIn()
    },
    // ------------------- Check-in Mobile ------------------- //
    checkInMob(uuid) {
      simpleSoundCheckin()
      this.loadingMob = true
      EventRegistryResource.CheckinsBz(this.registry.id, uuid)
        .store(
          {
            virtual_room_id: this.virtual_room.id,
            registry_form_id: this.current_form.id,
          },
        )
        .then(({data: {data, status}}) => {
          this.valuelink = ''
          this.hasCheckedFalse()
          const msg = status === 'red' ? this.$t('bz2.event.mod.user_not_exists') : `${data.first_name} ${data.last_name} ` + this.$t('pages.event.mod_dash.msg.checked')
          this.setSnackbar(
            true,
            status,
            msg,
          )
        })
        .finally(() => {
          this.loadingMob = false
        })
    },
    // ------------------- Other ------------------- //
    focusLector() {
      this.$refs.lector.focus()
    },
    cleanUp() {
      this.$refs.ModQR.cleanUp()
    },
    hasCheckedFalse() {
      setTimeout(() => {
        this.$refs.ModQR.updateHasChecked()
      }, 3000)
    },
    // ------------------- Button Back ------------------- //
    onBack() {
      this.view = ModAttendeesViews.index
    },
    // -------------------  Check-in mobile ------------------- //
    async checkOut() {
      const has_accepted = await this.$refs.checkoutModal.open(
        this.$t('bz2.event.mod.checkout.title'),
        this.$t('bz2.event.mod.checkout.description'),
        {
          color: 'red',
        },
      )
      if (has_accepted) {
        // Filtrar datos
        this.$store.dispatch('event/getEventUsers')
        .then(() => {
          const $attendees = this.users.map(
            (row) => row.attendees.filter((item) => item.language.name === this.current_config_language_key),
          )
          // new Array
          const users = []
          // Recorrer y almacenar al nuevo array segun las condiciones
          $attendees.forEach(ele => {
            ele.forEach(e => {
              if (e.current_checkin !== null) {
                if (e.current_checkin.finalized_at == null) {
                  users.push(e)
                }
              }
            })
          })
          this.$store.dispatch('registries/checkout', {
            payload: {
              registry_id: this.registry.id,
              attendees: users,
            },
          })
            .then(() => {
              this.$refs['participants-table'].getLeads()
            })
        })
        .finally(() => {
          
        })
      }
    },
    // -------------------  Set User ------------------- //
    setUser(user) {
      this.user = user
    },
    // -------------------  Set QR Links ------------------- //
    setQrLinks(qr_links) {
      this.qr_links = qr_links
    },
    // ------------------- Get User ------------------- //
    getUser(uuid) {
      this.showLoading = true
      return EventRegistryResource.Attendees(this.registry.id)
        .show(uuid)
        .then(({ data: { user, qrs } }) => {
          this.setUser(user)
          this.setQrLinks(qrs)
          this.view = ModAttendeesViews.show
        })
        .catch(() => {
          this.$store.dispatch('notifications/addNotification', {
            title: 'Error',
            body: 'The user does not exist',
            status: NotificationStatusKeys.error,
          })
        })
        .finally(() => {
          this.showLoading = false
        })
    },
    showItem({ uuid }) {
      this.getUser(uuid)
    },
    viewAttendee(user) {
      this.$store.commit('event/setCard', { attendee: user, modal: true })
    },
    chatWithUser(user) {
      this.$store.commit('event/setRepDashboard', {
        modal: false,
      })
      this.$store.commit('chat/setState', {
        showChat: false,
      })
      this.$nextTick().then(() => {
        this.$store.commit('chat/setState', {
          showChat: true,
          view: 'CHAT',
          talking_to: user,
        })
      })
    },
  },
}
</script>

<style>
.bz-active-camera {
  width: 100%;
  /* height: 175.5px; */
}
.tw-pst-ac {
  top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}
.bz-loading-checkinmob {
    background: #0000006e;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    backdrop-filter: blur(10px);
}
</style>
