// export type TCalendarEvent = {
//   name: string;
//   details: string | null;
//   location: string | null;
//   startsAt: string;
//   endsAt: string;
// }

import { format } from 'date-fns'

/**
 * Initial: https://github.com/vborodulin/add-event-to-calendar/blob/master/src/index.tsx
 * Update: https://github.com/AnandChowdhary/calendar-link/blob/b0e54744d6a1f47b051fd0df090e16a53d458d60/src/index.ts
 *
 */
const makeDuration = (event) => {
  const minutes = Math.floor(
    (+new Date(event.endsAt) - +new Date(event.startsAt)) / 60 / 1000,
  )
  return `${Math.floor(minutes / 60)}:${`0${minutes % 60}`.slice(-2)}`
}

const makeTime = (time) =>
  new Date(time).toISOString().replace(/[-:]|\.\d{3}/g, '')

// type Query = { [key: string]: null | boolean | number | string }

const makeUrl = (base, query) =>
  Object.keys(query).reduce((accum, key, index) => {
    const value = query[key]

    if (value !== null) {
      return `${accum}${index === 0 ? '?' : '&'}${key}=${encodeURIComponent(
        value,
      )}`
    }
    return accum
  }, base)

const makeGoogleCalendarUrl = (event) =>
  makeUrl('https://calendar.google.com/calendar/render', {
    action: 'TEMPLATE',
    dates: `${makeTime(event.startsAt)}/${makeTime(event.endsAt)}`,
    location: event.location,
    text: event.name,
    details: event.details,
  })

// const makeOutlookCalendarUrl = (event) =>
//   makeUrl('https://outlook.live.com/owa', {
//     rru: 'addevent',
//     startdt: makeTime(event.startsAt),
//     enddt: makeTime(event.endsAt),
//     subject: event.name,
//     location: event.location,
//     body: event.details,
//     allday: false,
//     uid: new Date().getTime().toString(),
//     path: '/calendar/view/Month',})

const makeOutlookCalendarUrl = (event) => {
  const details = {
    path: '/calendar/action/compose',
    rru: 'addevent',
    startdt: format(new Date(event.startsAt), "yyyy-MM-dd'T'HH:mm"),
    enddt: format(new Date(event.endsAt), "yyyy-MM-dd'T'HH:mm"),
    subject: event.name,
    body: event.details,
    location: event.location,
    allday: false,
  }
  return makeUrl(
    'https://outlook.live.com/calendar/0/deeplink/compose',
    details,
  )
}

const makeYahooCalendarUrl = (event) =>
  makeUrl('https://calendar.yahoo.com', {
    v: 60,
    view: 'd',
    type: 20,
    title: event.name,
    st: makeTime(event.startsAt),
    dur: makeDuration(event),
    desc: event.details,
    in_loc: event.location,
  })

const makeICSCalendarUrl = (event) => {
  const components = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    `URL:${document.URL}`,
    `DTSTART:${makeTime(event.startsAt)}`,
    `DTEND:${makeTime(event.endsAt)}`,
    `SUMMARY:${event.name}`,
    `DESCRIPTION:${event.details}`,
    `LOCATION:${event.location}`,
    'END:VEVENT',
    'END:VCALENDAR',
  ]

  return encodeURI(`data:text/calendar;charset=utf8,${components.join('\n')}`)
}
export const eventModel = ({
  name = '',
  details = null,
  location = null,
  startsAt = '',
  endsAt = '',
}) =>
  makeUrls({
    name,
    details,
    location,
    startsAt,
    endsAt,
  })
export const makeUrls = (event) => ({
  google: makeGoogleCalendarUrl(event),
  outlook: makeOutlookCalendarUrl(event),
  yahoo: makeYahooCalendarUrl(event),
  ics: makeICSCalendarUrl(event),
})

// const format =makeUrls( eventModel(event))
// export default makeUrls
