<template>
  <div
    class="tw-sticky tw-bottom-0 tw-z-10 tw-flex-initial tw-transform tw-bg-white tw-px-2 tw-shadow-md tw-transition-all tw-duration-200 tw-ease-in-out xl:tw-px-8 xl:tw-py-4"
  >
    <!-- tw-fixed
    :style="{
      right: `${$vuetify.application.right}px`,
      left: `${$vuetify.application.left}px`,
    }" -->
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style></style>
