<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="640px"
    content-class="tw-rounded-3xl tw-w-full"
  >
    <v-form
      ref="form"
      v-model="valid"
      :disabled="is_loading"
      lazy-validation
      enctype="multipart/form-data"
      @submit.prevent="create"
    >
      <v-card class="tw-rounded-3xl tw-border-0 tw-shadow-md">
        <ModalHeader>
          {{ $t('app.config') }}
          <template #right>
            <div class="tw-flex tw-items-center tw-space-x-3">
              <LanguageSelect
                v-model="lang_key"
                :label="$t('admin.app.lang_version')"
                dark
              />
              <v-fab-transition>
                <v-btn
                  fab
                  x-small
                  class="focus:tw-outline-none focus:tw-ring"
                  @click="closeModal"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-fab-transition>
            </div>
          </template>
        </ModalHeader>
        <v-divider />
        <v-card-text class="tw-p-0">
          <v-responsive class="tw-py-0">
            <v-container class="tw-bg-white" fluid>
              <v-row class="mb-5">
                <v-col cols="12" class="mx-auto pa-5">
                  <div class="tw-divide-y tw-divide-gray-200">
                    <div class="tw-space-y-1">
                      <h3
                        class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        {{ $t('admin.app.extra_config') }}
                      </h3>
                      <p class="tw-max-w-2xl tw-text-sm tw-text-gray-500">
                        {{ $t('admin.app.extra_config_description') }}
                      </p>
                    </div>

                    <ul class="tw-mt-2 tw-divide-y tw-divide-gray-200 tw-pl-0">
                      <li
                        class="tw-items-center tw-py-4 sm:tw-flex sm:tw-justify-between"
                      >
                        <div class="tw-flex tw-flex-col">
                          <p
                            id="privacy-option-1-label"
                            class="tw-mb-0 tw-text-sm tw-font-medium tw-text-gray-900"
                          >
                            {{ $t('admin.app.enable_calendar') }}
                          </p>
                          <p
                            id="privacy-option-1-description"
                            class="tw-mb-0 tw-text-sm tw-text-gray-500"
                          >
                            {{ $t('admin.app.enable_calendar_description') }}
                          </p>
                        </div>

                        <div
                          class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
                        >
                          <v-switch
                            v-model="form[lang_key].is_active"
                            inset
                            large
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-responsive>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-alert
            v-model="errorAlert"
            dismissible
            color="red"
            border="left"
            elevation="2"
            colored-border
            icon="error"
            transition="fade-transition"
          >
            {{ errorMsg }}
          </v-alert>
          <v-spacer />
          <AppBtn
            type="button"
            color="transparent"
            :disabled="is_loading"
            @click="closeModal"
          >
            {{ $t('app.cancel') }}
          </AppBtn>
          <AppBtn type="submit" :loading="is_loading" color="primary">
            {{ $t('app.save') }}
          </AppBtn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import AppBtn from '@/components/App/AppBtn.vue'
import ModalHeader from '@/components/ModalHeader'
import { rules } from '@/utils/rules'
import LanguageSelect from '../LanguageSelect.vue'
import { CalendarBase } from '@/models/Calendar'
import { mapState } from 'vuex'

export default {
  components: {
    ModalHeader,
    AppBtn,
    LanguageSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    lang_key: 'es',
    form: {
      es: CalendarBase({}),
      en: CalendarBase({}),
    },
    rules,
    errorAlert: false,
    errorMsg: '',
    is_loading: false,
    valid: true,
  }),

  computed: {
    ...mapState({
      registry: (state) => state.registries.show.data,
    }),
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    open: {
      handler(newVal) {
        if (newVal) {
          this.init()
        }
      },
      immediate: true,
    },
  },
  methods: {
    init() {
      console.log(this.registry.calendars)
      this.form = this.registry.calendars.reduce(
        (acc, { language, ...obj }) => {
          return {
            ...acc,
            [language.name]: CalendarBase(obj),
          }
        },
        { ...this.form },
      )
    },
    closeModal() {
      this.open = false
    },
    create() {
      this.errorAlert = false
      if (!this.$refs.form.validate()) {
        return
      }

      const calendars = Object.keys(this.form).map((row) => ({
        ...this.form[row],
      }))
      this.$store
        .dispatch('registries/updateCalendars', {
          payload: { calendars },
        })
        .then(() =>
          this.$store.dispatch('registries/show', {
            id: this.registry.id,
            appLoading: true,
          }),
        )
        .then(() => {
          this.init()
          this.closeModal()
        })
      // this.is_loading = true
    },
  },
}
</script>

<style></style>
