<template>
  <renderless-layout>
    <v-container id="users_index_page" fluid class="tw-p-0">
      <StickyHeader :nav="nav">
        <template #actions>
          <div class="tw-flex tw-items-center tw-space-x-3">
            <AppBtn
              v-if="show.is_hybrid"
              color="green-light"
              type="button"
              icon="download_cloud"
              class="tw-text-xs"
              :disabled="exporCheckinstLoading"
              :loading="exporCheckinstLoading"
              @click="exportAttendeesCheckins"
            >
              {{ $t('admin.metrics.check_ins') }}
            </AppBtn>
            <AppBtn
              color="green-light"
              type="button"
              icon="download_cloud"
              class="tw-text-xs"
              :disabled="exportLoading"
              :loading="exportLoading"
              @click="exportAttendees"
            >
              {{ $t('app.export') }}
            </AppBtn>

            <AppBtn
              color="green-light"
              type="button"
              icon="g-next"
              g-next="CloudUpload"
              class="tw-text-xs"
              @click="toggle_import = true"
            >
              {{ $t('app.import') }}
            </AppBtn>
            <AppBtn
              color="white"
              icon="plus"
              class="tw-text-xs"
              :to="{ name: 'admin.users.create' }"
            >
              {{ $t('app.create') }}
            </AppBtn>
          </div>
        </template>
      </StickyHeader>
      <v-row class="tw-m-0 tw-p-0">
        <v-col cols="12" class="mx-auto tw-max-w-7xl">
          <v-container fluid class="tw-p-0 tw-py-6">
            <RegistriesParticipantsTable
              ref="participants-table"
              :registry="show"
              @show-item="showItem"
            />
          </v-container>
        </v-col>
      </v-row>
      <RegistriesParticipantsImportModal
        v-model="toggle_import"
        @refresh-index="refreshTable"
      />
      <RegistriesParticipantsExportModal v-model="toggle_export" />
    </v-container>
  </renderless-layout>
</template>

<script>
import AppBtn from '@/components/App/AppBtn.vue'
import RegistriesParticipantsImportModal from '@/components/RegistriesModals/RegistriesParticipantsImportModal.vue'
import StickyHeader from '@/components/StickyHeader.vue'
import RegistriesParticipantsExportModal from '@/components/RegistriesModals/RegistriesParticipantsExportModal.vue'

import { mapState } from 'vuex'
import RegistriesParticipantsTable from '@/components/Registries/RegistriesParticipantsTable.vue'
import { axiosDownloadable } from '@/utils/axiosDownloadable'

export default {
  components: {
    AppBtn,
    RegistriesParticipantsImportModal,
    StickyHeader,
    RegistriesParticipantsExportModal,
    RegistriesParticipantsTable,
  },
  data: () => ({
    toggle_export: false,
    exportLoading: false,
    exporCheckinstLoading: false,
    toggle_import: false,
  }),
  computed: {
    ...mapState({
      show: (state) => state.registries.show.data,
    }),
    nav() {
      return [
        {
          name: this.$t('app.event'),
          route: { name: 'admin.registries.index' },
        },
        {
          name: this.show.label,
          route: {
            name: 'admin.registries.show.home',
            params: { id: this.show.id },
          },
        },
        {
          name: this.$t('admin.events.items.labels.attendees'),
          route: {},
        },
      ]
    },
  },
  methods: {
    refreshTable() {
      this.$refs['participants-table'].getLeads()
    },
    exportAttendees() {
      this.toggle_export = true
    },
    exportAttendeesCheckins() {
      this.exporCheckinstLoading = true
      this.$store
        .dispatch('registries/exportCheckins', {
          payload: {
            registry_id: this.show.id,
          },
        })
        .then((res) => {
          console.log(res)
          axiosDownloadable(res, 'check-ins_')
        })
        .catch((err) => {
          this.$store.dispatch('notifications/addNotification', {
            title: 'Error exportando',
            body: err,
          })
        })
        .finally(() => {
          this.exporCheckinstLoading = false
        })
    },
    showItem(a) {
      return this.$router.push({
        name: 'admin.users.show',
        params: { uuid: a.uuid },
      })
    },
  },
}
</script>

<style></style>
