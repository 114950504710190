export const matchCols = {
  1: 'sm:tw-col-span-1',
  2: 'sm:tw-col-span-2',
  3: 'sm:tw-col-span-3',
  4: 'sm:tw-col-span-4',
  5: 'sm:tw-col-span-5',
  6: 'sm:tw-col-span-6',
  7: 'sm:tw-col-span-7',
  8: 'sm:tw-col-span-8',
  9: 'sm:tw-col-span-9',
  10: 'sm:tw-col-span-10',
  11: 'sm:tw-col-span-11',
  12: 'sm:tw-col-span-12',
}
