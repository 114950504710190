<template>
  <div class="vue-friendly-iframe" />
</template>

<script>
import { v4 } from '@/helpers'
// import debounce from 'lodash.debounce'
// import utils from 'src/utils/utils'
function debounce(func, wait, immediate) {
  let timeout

  return function () {
    const context = this

    const args = arguments

    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    const callNow = immediate && !timeout

    clearTimeout(timeout)

    timeout = setTimeout(later, wait)

    if (callNow) func.apply(context, args)
  }
}

function generateGuid() {
  return v4()
}
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    crossorigin: {
      type: String,
      required: false,
      default: 'anonymous',
    },
    target: {
      type: String,
      required: false,
      default: '_parent',
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    allow: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    sandbox: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      iframeEl: null,
      iframeLoadedMessage: `IFRAME_LOADED_${generateGuid()}`,
      iframeOnReadyStateChangeMessage: `IFRAME_ON_READ_STATE_CHANGE_${generateGuid()}`,
    }
  },
  computed: {},
  watch: {
    src() {
      this.reinitIframe(this)
    },
  },

  mounted() {
    this.listenForEvents()
    this.initIframe()
  },
  methods: {
    removeIframe() {
      while (this.$el.firstChild) {
        this.$el.removeChild(this.$el.firstChild)
      }
    },
    setIframeUrl() {
      if (this.iframeEl.contentWindow === null) {
        setTimeout(this.setIframeUrl)
        return
      }
      const iframeDoc = this.iframeEl.contentWindow.document
      console.log(iframeDoc)
      iframeDoc.open().write(
        `
          <body onload="window.location.replace('${this.src}'); parent.postMessage('${this.iframeLoadedMessage}', '*')"></body>
          `,
      )
      iframeDoc.close() //iframe iframe-load event happens
      const vm = this
      // eslint-disable-next-line no-unused-vars
      this.iframeEl.addEventListener('load', (e) => {
        vm.$emit('load', this.iframeEl)
      })
    },
    reinitIframe: debounce((vm) => {
      vm.removeIframe()
      vm.initIframe()
    }, 200),
    initIframe() {
      this.iframeEl = document.createElement('iframe')
      this.iframeEl.setAttribute(
        'style',
        'visibility: hidden; position: absolute; top: -99999px; border: none;',
      )
      if (this.src) this.iframeEl.setAttribute('iframe-src', this.src)
      if (this.className) this.iframeEl.setAttribute('class', this.className)
      if (this.class) this.iframeEl.setAttribute('class', this.class)
      if (this.crossorigin)
        this.iframeEl.setAttribute('crossorigin', this.crossorigin)
      if (this.target) this.iframeEl.setAttribute('target', this.target)
      if (this.allow) this.iframeEl.setAttribute('allow', this.allow)
      if (this.name) this.iframeEl.setAttribute('name', this.name)
      if (this.title) this.iframeEl.setAttribute('title', this.title)
      if (this.sandbox) this.iframeEl.setAttribute('sandbox', this.sandbox)
      this.$el.appendChild(this.iframeEl)
      this.setIframeUrl()
    },
    listenForEvents() {
      // Create IE + others compatible event handler
      const eventMethod = window.addEventListener
        ? 'addEventListener'
        : 'attachEvent'
      const eventer = window[eventMethod]
      const messageEvent =
        eventMethod === 'attachEvent' ? 'onmessage' : 'message'
      // Listen to message from child window
      eventer(
        messageEvent,
        (event) => {
          if (event.data === this.iframeLoadedMessage) {
            this.$emit('iframe-load')
            this.iframeEl.setAttribute(
              'style',
              'visibility: visible; border: none;',
            )
          }
          // if (event.data === this.iframeOnReadyStateChangeMessage) {
          //   this.$emit('load')
          // }
        },
        false,
      )
    },
  },
}
</script>
<style>
.vue-friendly-iframe {
  height: 100%;
  width: 100%;
}
.vue-friendly-iframe iframe {
  height: 100%;
  width: 100%;
}
</style>
