<template>
<div class="tw-flex tw-flex-col">
    <!-- header -->
    <div class="tw-flex tw-items-center tw-justify-between">
        <v-btn small rounded text class="tw-pl-2" @click="onBack()">
            <v-icon left class="tw-text-secondary-400 tw-m-0">chevron_left</v-icon>
            <span class="tw-text-sm tw-normal-case tw-font-bold tw-text-secondary-400">{{
                        $t('pages.event.chat.labels.notes') }}</span>

        </v-btn>
        <div>
            <template v-if="!ov_value">
                <v-btn small rounded text :loading="loading" :disabled="loading" @click="edit()">
                    <v-icon left class="tw-text-secondary-400">done</v-icon>
                    <span class="tw-text-xs tw-normal-case tw-font-bold tw-text-secondary-400">{{
                            $t('app.update') }}</span>
                    <template #loader>
                        <v-progress-circular :size="14" :width="1" color="secondary" indeterminate />
                    </template>
                </v-btn>
                <v-menu bottom left class="tw-z-50">
                    <template #activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                            <v-icon class="tw-text-secondary-400 tw-text-sm">more_vert</v-icon>
                        </v-btn>
                    </template>
                    <v-list class="bz-menu-opt tw-p-0">
                        <v-list-item link @click="activeEmail()">
                            <div class="tw-w-full tw-flex tw-items-center tw-justify-between">
                                <div class="tw-text-xs">{{ $t('pages.event.chat.notes.sent_post') }}</div>
                                <div>
                                    <v-icon class="tw-text-sm tw-ml-8">forward_to_inbox</v-icon>
                                </div>
                            </div>
                        </v-list-item>
                        <v-divider />
                        <!-- <v-list-item link>
                        <div class="tw-w-full tw-flex tw-items-center tw-justify-between">
                            <div class="tw-text-xs">Descargar</div>
                            <div><v-icon class="tw-text-sm tw-ml-8">download</v-icon></div>
                        </div>
                    </v-list-item>
                    <v-divider />-->
                        <v-list-item link @click="pin()">
                            <div class="tw-w-full tw-flex tw-items-center tw-justify-between">
                                <div class="tw-text-xs">{{ highlight ? $t('pages.event.chat.notes.no_highlight') : $t('pages.event.chat.notes.highlight') }}</div>
                                <div>
                                    <v-icon class="tw-text-sm tw-ml-8">push_pin</v-icon>
                                </div>
                            </div>
                        </v-list-item>
                        <v-divider />
                        <v-list-item link class="tw-text-red-500" @click="trash()">
                            <div class="tw-w-full tw-flex tw-items-center tw-justify-between">
                                <div class="tw-text-xs">{{ $t('pages.event.chat.notes.trash') }}</div>
                                <div>
                                    <v-icon class="tw-text-sm tw-ml-8 tw-text-red-500">delete</v-icon>
                                </div>
                            </div>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <template v-if="ov_value">
                <v-btn class="tw-text-secondary-400" :ripple="false" icon @click="ov_value = false">
                    <v-icon class="tw-text-md">close</v-icon>
                </v-btn>
            </template>
        </div>
    </div>
    <!-- body -->
    <template v-if="!ov_value">
        <div class="tw-relative">
            <v-text-field v-model="title" :label="$t('pages.event.chat.notes.untitled')" class="tw-font-bold" solo flat single-line hide-details />
            <v-textarea v-model="description" solo-inverted flat full-width class="tw-bg-[#F3F2F8] tw-text-xs" height="340" hide-details />
        </div>
    </template>
    <template v-if="ov_value">
        <div class="tw-p-4">
            <v-text-field v-model="email" :label="$t('forms.email')" rounded dense filled flat single-line hide-details />
            <v-btn block rounded :loading="email_loading" color="primary" class="tw-mt-2" @click="sendEmail()">{{ $t('app.send') }}</v-btn>
        </div>
    </template>
</div>
</template>

<script>
import {
    mapState,
} from 'vuex'

export default {
    props: {
        onlyNote: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        showMenu: false,
        id: '',
        title: '',
        description: '',
        highlight: false,
        loading: false,
        ov_value: false,
        email: '',
        email_loading: false,
    }),

    computed: {
        ...mapState({
            current_user: (state) => state.session.user.id,
        }),
    },
    mounted() {
        this.id = this.onlyNote.id
        this.title = this.onlyNote.title
        this.description = this.onlyNote.description
        this.highlight = this.onlyNote.highlight
    },
    methods: {
        edit() {
            this.loading = true
            const payload = {
                id: this.id,
                user_id: this.current_user,
                title: this.title,
                description: this.description,
                highlight: this.highlight,
            }
            console.log('🚀 ~ file: EditNote.vue:111 ~ edit ~ payload:', payload)
            this.$store.dispatch('event/quickNotesStore', payload)
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.loading = false
                    // this.onBack()
                })
        },
        // Send to email
        activeEmail() {
            this.ov_value = true
        },
        sendEmail() {
            this.email_loading = true
            const payload = {
                title: this.title,
                description: this.description,
                email: this.email,
            }
            this.$store.dispatch('event/quickNoteSendEmail', payload)
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.email_loading = false
                    this.email = ''
                    this.ov_value = false
                })
        },
        // Highlight
        pin() {
            const payload = {
                id: this.id,
                user_id: this.current_user,
                highlight: this.highlight ? false : true,
            }
            this.$store.dispatch('event/quickNotesHighlight', payload)
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.onBack()
                })
        },
        // Delete
        trash() {
            this.$store.dispatch('event/quickNotesDelete', {
                    id: this.id,
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.loading = false
                    this.onBack()
                })
        },
        onBack() {
            this.$emit('on-back')
        },
    },
}
</script>

<style scoped>
.bz-menu-opt .v-list-item {
    min-height: 30px !important;
}
</style>
