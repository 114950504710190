import i18n from '@/i18n'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
// import es from 'vuetify/lib/locale/es'
// import en from 'vuetify/lib/locale/en'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // primary: "#5268ff",
        // primary: '#3200b8',
        primary: '#3565AF',
        // secondary: '#97ffc2',
        secondary: '#B2914F',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
  // lang: {
  //   locales: { es },
  //   current: 'es',
  // },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  icons: {
    iconfont: 'md',
  },
})
