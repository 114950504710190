<template>
  <div class="tw-relative tw-bg-white" :style="colors">
    <slot />
    <slot name="footer">
      <RegistryFooter :section="sectionFooter"/>
    </slot>
  </div>
</template>

<script>
import RegistryFooter from './RegistryFooter.vue'
import { hexToRgbComma } from '@/utils/hexToRgb'
import { mapState } from 'vuex'
import { SectionTypeKeys } from '@/enums/SectionTypeKeys'
export default {
  components: {
    RegistryFooter,
  },
  computed: {
    colors() {
      return {
        '--landing_ui_color': hexToRgbComma(this.current_landing.ui_color),
        '--landing_ui_contrast': hexToRgbComma(
          this.current_landing.ui_contrast,
        ),
      }
    },
    current_landing() {
      return this.registry.landings.find(
        (row) => row.language.name === this.current_language_key,
      )
    },
    sectionFooter() {
      if (!this.current_landing) { return [] }
      if (!this.current_landing.sections) { return [] }
      return this.current_landing.sections.find((row) => row.name === SectionTypeKeys.social_media && row.is_active)?.data
    },
    ...mapState({
      current_language_key: (state) => state.registry.show.current_language_key,
      registry: (state) => state.registry.show.data,
    }),
  },
}
</script>

<style></style>
