<template>
  <div>
    <template v-for="(item, index) in items">
      <div
        v-if="item.type === 'divider'"
        :key="index"
        class="divider tw-mr-3 tw-ml-2"
      />
      <v-menu
        v-else-if="item.type === 'menu'"
        :key="index"
        transition="scale-transition"
        content-class="tw-py-1  tw-bg-white tw-rounded-md tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5"
      >
        <template #activator="{ on, attrs }">
          <TipTapMenuItem
            :title="item.title"
            :icon="item.icon"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-list class="tw-p-0">
          <template v-for="row in item.items">
            <v-list-item
              v-if="row.color"
              :key="row.title"
              class="tw-py-0 tw-px-1"
              dense
              @click="row.action"
            >
              <div
                class="tw-h-6 tw-w-6 tw-rounded-full"
                :style="{ backgroundColor: row.color }"
              />
            </v-list-item>
            <v-list-item
              v-else-if="row.text"
              :key="row.title"
              class="tw-py-0 tw-px-1"
              dense
              @click="row.action"
            >
              <v-list-item-title>{{ row.title }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-else :key="row.title" class="tw-py-0 tw-px-1" dense>
              <TipTapMenuItem v-bind="row" @click="row.action" />
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <TipTapMenuItem
        v-else
        :key="index"
        v-bind="item"
        class="tw-mr-1"
        @click="item.action"
      />
    </template>
  </div>
</template>

<script>
import TipTapMenuItem from './TipTapMenuItem.vue'
export default {
  components: {
    TipTapMenuItem,
  },
  props: {
    editor: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      items: [
        {
          icon: 'bold',
          title: 'Bold',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold'),
        },
        {
          icon: 'italic',
          title: 'Italic',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic'),
        },
        {
          icon: 'strikethrough',
          title: 'Strike',
          action: () => this.editor.chain().focus().toggleStrike().run(),
          isActive: () => this.editor.isActive('strike'),
        },
        // {
        //   icon: 'code-view',
        //   title: 'Code',
        //   action: () => this.editor.chain().focus().toggleCode().run(),
        //   isActive: () => this.editor.isActive('code'),
        // },
        // {
        //   icon: 'mark-pen-line',
        //   title: 'Highlight',
        //   action: () => this.editor.chain().focus().toggleHighlight().run(),
        //   isActive: () => this.editor.isActive('highlight'),
        // },
        {
          icon: 'link',
          title: 'Link',
          action: () => {
            const url = window.prompt('URL')

            this.editor
              .chain()
              .focus()
              .extendMarkRange('link')
              .setLink({ href: url })
              .run()
          },
          isActive: () => this.editor.isActive('link'),
        },

        {
          type: 'menu',
          title: 'Text align',
          icon: 'align-left',
          items: [
            {
              icon: 'align-left',
              title: 'Align Left',
              action: () =>
                this.editor.chain().focus().setTextAlign('left').run(),
              isActive: () => this.editor.isActive({ textAlign: 'left' }),
            },
            {
              icon: 'align-center',
              title: 'Align Center',
              action: () =>
                this.editor.chain().focus().setTextAlign('center').run(),
              isActive: () => this.editor.isActive({ textAlign: 'center' }),
            },
            {
              icon: 'align-right',
              title: 'Align Right',
              action: () =>
                this.editor.chain().focus().setTextAlign('right').run(),
              isActive: () => this.editor.isActive({ textAlign: 'right' }),
            },
            {
              icon: 'align-justify',
              title: 'Align Justify',
              action: () =>
                this.editor.chain().focus().setTextAlign('justify').run(),
              isActive: () => this.editor.isActive({ textAlign: 'justify' }),
            },
          ],
        },
        {
          type: 'divider',
        },
        {
          icon: 'command-fill',
          title: 'Class',
          action: () => {
            const inlineClass = window.prompt('Class para el elemento')

            this.editor.chain().focus().setSpanClass(inlineClass).run()
          },
        },
        {
          type: 'menu',
          title: 'Text heading',
          icon: 'text',
          items: [
            {
              icon: 'h-1',
              title: 'Heading 1',
              action: () =>
                this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
              isActive: () => this.editor.isActive('heading', { level: 1 }),
            },
            {
              icon: 'h-2',
              title: 'Heading 2',
              action: () =>
                this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
              isActive: () => this.editor.isActive('heading', { level: 2 }),
            },
            {
              icon: 'h-3',
              title: 'Heading 3',
              action: () =>
                this.editor.chain().focus().toggleHeading({ level: 3 }).run(),
              isActive: () => this.editor.isActive('heading', { level: 3 }),
            },
            {
              icon: 'h-4',
              title: 'Heading 4',
              action: () =>
                this.editor.chain().focus().setFontSize('14px').run(),
              // isActive: () => this.editor.isActive('heading', { level: 3 }),
            },
            {
              icon: 'h-5',
              title: 'Heading 5',
              action: () =>
                this.editor.chain().focus().toggleHeading({ level: 5 }).run(),
              isActive: () => this.editor.isActive('heading', { level: 5 }),
            },

            {
              icon: 'paragraph',
              title: 'Paragraph',
              action: () => this.editor.chain().focus().setParagraph().run(),
              isActive: () => this.editor.isActive('paragraph'),
            },
          ],
        },
        {
          type: 'menu',
          title: 'Text color',
          icon: 'font-color',
          items: [
            {
              color: 'blue',
              title: 'Blue',
              action: () => this.editor.chain().focus().setColor('blue').run(),
            },
            {
              color: 'red',
              title: 'Red',
              action: () => this.editor.chain().focus().setColor('red').run(),
            },
            {
              color: 'green',
              title: 'Green',
              action: () => this.editor.chain().focus().setColor('green').run(),
            },
            {
              color: 'yellow',
              title: 'Yellow',
              action: () =>
                this.editor.chain().focus().setColor('yellow').run(),
            },
            {
              color: 'black',
              title: 'Black',
              action: () => this.editor.chain().focus().setColor('black').run(),
            },
          ],
        },
        {
          type: 'menu',
          title: 'Font size',
          icon: 'font-size',
          items: [
            {
              text: true,
              title: '64px',
              action: () =>
                this.editor.chain().focus().setFontSize('64px').run(),
            },
            {
              text: true,
              title: '48px',
              action: () =>
                this.editor.chain().focus().setFontSize('48px').run(),
            },
            {
              text: true,
              title: '28px',
              action: () =>
                this.editor.chain().focus().setFontSize('28px').run(),
            },
            {
              text: true,
              title: '16px',
              action: () =>
                this.editor.chain().focus().setFontSize('16px').run(),
              // isActive: () => this.editor.isActive('heading', { level: 3 }),
            },
            {
              text: true,
              title: '8px',
              action: () =>
                this.editor.chain().focus().setFontSize('8px').run(),
            },
          ],
        },

        // {
        //   icon: 'list-check-2',
        //   title: 'Task List',
        //   action: () => this.editor.chain().focus().toggleTaskList().run(),
        //   isActive: () => this.editor.isActive('taskList'),
        // },
        // {
        //   icon: 'code-box-line',
        //   title: 'Code Block',
        //   action: () => this.editor.chain().focus().toggleCodeBlock().run(),
        //   isActive: () => this.editor.isActive('codeBlock'),
        // },
        {
          type: 'divider',
        },
        {
          icon: 'list-unordered',
          title: 'Bullet List',
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive('bulletList'),
        },
        {
          icon: 'list-ordered',
          title: 'Ordered List',
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive('orderedList'),
        },
        {
          icon: 'double-quotes-l',
          title: 'Blockquote',
          action: () => this.editor.chain().focus().toggleBlockquote().run(),
          isActive: () => this.editor.isActive('blockquote'),
        },
        {
          icon: 'separator',
          title: 'Horizontal Rule',
          action: () => this.editor.chain().focus().setHorizontalRule().run(),
        },
        {
          type: 'divider',
        },
        {
          icon: 'text-wrap',
          title: 'Hard Break',
          action: () => this.editor.chain().focus().setHardBreak().run(),
        },
        // {
        //   icon: 'format-clear',
        //   title: 'Clear Format',
        //   action: () =>
        //     this.editor.chain().focus().clearNodes().unsetAllMarks().run(),
        // },
        {
          type: 'divider',
        },
        {
          icon: 'arrow-go-back-line',
          title: 'Undo',
          action: () => this.editor.chain().focus().undo().run(),
        },
        {
          icon: 'arrow-go-forward-line',
          title: 'Redo',
          action: () => this.editor.chain().focus().redo().run(),
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.divider {
  width: 2px;
  height: 1.25rem;
  background-color: rgba(13, 13, 13, 0.1);
  /* margin-left: 0.5rem; */
  /* margin-right: 0.75rem; */
}
.menu-item {
  width: 1.75rem;
  height: 1.75rem;
  color: #0d0d0d;
  border: none;
  background-color: transparent;
  border-radius: 0.4rem;
  padding: 0.25rem;
  /* margin-right: 0.25rem; */

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }

  &.is-active,
  &:hover {
    color: #fff;
    background-color: #0d0d0d;
  }
}
</style>
