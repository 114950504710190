<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="640px"
    content-class="tw-rounded-3xl"
  >
    <v-card class="tw-relative tw-rounded-3xl tw-border-0 tw-shadow-md">
      <ModalHeader @close-modal="closeModal">
        {{ $t('admin.app.extra_config') }}
      </ModalHeader>
      <v-divider />
      <v-card-text class="tw-p-0" style="max-height: 700px">
        <v-responsive class="tw-py-0">
          <v-container class="tw-bg-white" fluid>
            <v-row class="mb-5">
              <v-col cols="12" class="mx-auto pa-5">
                <!-- Description list with tw-inline editing -->
                <div class="tw-divide-y tw-divide-gray-200">
                  <div class="tw-space-y-1">
                    <h3
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                    >
                      {{
                        $t('admin.events.items.access_control.strategy_label')
                      }}
                    </h3>
                    <p class="tw-mb-0 tw-max-w-2xl tw-text-sm tw-text-gray-500">
                      {{
                        $t(
                          'admin.events.items.access_control.strategy_description',
                        )
                      }}
                    </p>
                  </div>
                  <div class="tw-mt-6">
                    <dl class="tw-divide-y tw-divide-gray-200">
                      <div
                        class="tw-py-4 sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-4 sm:tw-py-5"
                      >
                        <dt
                          class="tw-sr-only tw-text-sm tw-font-medium tw-text-gray-500"
                        >
                          {{ $t('admin.app.strategy') }}
                        </dt>
                        <dd
                          class="tw-mt-1 tw-items-center tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0 sm:tw-flex sm:tw-justify-between"
                        >
                          <span class="tw-flex-grow">
                            <v-select
                              v-model="access_list_type"
                              :items="access_list_types"
                              :label="$t('admin.app.strategy')"
                              outlined
                              :disabled="form_strategy_loading"
                              rounded
                              hide-details=""
                              dense
                              class="tw-w-64"
                            />
                          </span>
                          <span
                            class="tw-mt-3 tw-flex tw-flex-shrink-0 sm:tw-ml-4 sm:tw-mt-0"
                          >
                            <AppBtn
                              :loading="form_strategy_loading"
                              :disabled="saveDisabled"
                              type="button"
                              color="primary"
                              @click="updateStrategy"
                            >
                              {{ $t('app.save') }}
                            </AppBtn>
                          </span>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>

                <div class="tw-mt-10 tw-divide-y tw-divide-gray-200">
                  <div class="tw-space-y-1">
                    <h3
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                    >
                      {{ $t('admin.app.actions') }}
                    </h3>
                    <p class="tw-max-w-2xl tw-text-sm tw-text-gray-500">
                      {{
                        $t(
                          'admin.events.items.access_control.ac_settings_actions_label',
                        )
                      }}
                    </p>
                  </div>

                  <ul class="tw-mt-2 tw-divide-y tw-divide-gray-200 tw-pl-0">
                    <li
                      class="tw-items-center tw-py-4 sm:tw-flex sm:tw-justify-between"
                    >
                      <div class="tw-flex tw-flex-col">
                        <p
                          id="privacy-option-1-label"
                          class="tw-mb-0 tw-text-sm tw-font-medium tw-text-gray-900"
                        >
                          {{
                            $t(
                              'admin.events.items.access_control.ac_reset_all_label',
                            )
                          }}
                        </p>
                        <p
                          id="privacy-option-1-description"
                          class="tw-mb-0 tw-text-sm tw-text-gray-500"
                        >
                          {{
                            $t(
                              'admin.events.items.access_control.ac_reset_all_description',
                            )
                          }}
                        </p>
                      </div>

                      <div
                        class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
                      >
                        <AppBtn type="button" color="transparent-border">
                          {{ $t('admin.app.reset') }}
                        </AppBtn>
                      </div>
                    </li>
                    <li
                      class="tw-items-center tw-py-4 sm:tw-flex sm:tw-justify-between"
                    >
                      <div class="tw-flex tw-flex-col">
                        <p
                          id="privacy-option-2-label"
                          class="tw-mb-0 tw-text-sm tw-font-medium tw-text-gray-900"
                        >
                          {{
                            $t(
                              'admin.events.items.access_control.ac_reset_white_label',
                            )
                          }}
                        </p>
                        <p
                          id="privacy-option-2-description"
                          class="tw-mb-0 tw-text-sm tw-text-gray-500"
                        >
                          {{
                            $t(
                              'admin.events.items.access_control.ac_reset_white_description',
                            )
                          }}
                        </p>
                      </div>
                      <div
                        class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
                      >
                        <AppBtn type="button" color="transparent-border">
                          {{
                            $t(
                              'admin.events.items.access_control.ac_reset_white_btn',
                            )
                          }}
                        </AppBtn>
                      </div>
                    </li>
                    <li
                      class="tw-items-center tw-py-4 sm:tw-flex sm:tw-justify-between"
                    >
                      <div class="tw-flex tw-flex-col">
                        <p
                          id="privacy-option-2-label"
                          class="tw-mb-0 tw-text-sm tw-font-medium tw-text-gray-900"
                        >
                          {{
                            $t(
                              'admin.events.items.access_control.ac_reset_black_label',
                            )
                          }}
                        </p>
                        <p
                          id="privacy-option-2-description"
                          class="tw-mb-0 tw-text-sm tw-text-gray-500"
                        >
                          {{
                            $t(
                              'admin.events.items.access_control.ac_reset_black_description',
                            )
                          }}
                        </p>
                      </div>
                      <div
                        class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
                      >
                        <AppBtn type="button" color="transparent-border">
                          {{
                            $t(
                              'admin.events.items.access_control.ac_reset_black_btn',
                            )
                          }}
                        </AppBtn>
                      </div>
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>

            <transition name="fade-transition" mode="out-in">
              <v-row v-if="errorAlert">
                <v-col cols="12">
                  <v-alert
                    v-model="errorAlert"
                    dismissible
                    color="red"
                    border="left"
                    elevation="2"
                    colored-border
                    icon="error"
                    transition="fade-transition"
                  >
                    {{ errorMsg }}
                  </v-alert>
                </v-col>
              </v-row>
            </transition>
          </v-container>
        </v-responsive>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <AppBtn
          color="transparent"
          :disabled="form_loading"
          @click="closeModal"
        >
          {{ $t('app.cancel') }}
        </AppBtn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { rules } from '@/utils/rules'

import ModalHeader from '@/components/ModalHeader'
import { mapGetters, mapState } from 'vuex'
import AppBtn from '../App/AppBtn.vue'
import { simpleObjectCopy } from '@/utils/simpleObjectCopy'

export default {
  components: {
    ModalHeader,
    AppBtn,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    strategy: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    errorAlert: '',
    errorMsg: '',
    exam: '',
    rules,
    isLoading: false,
    form_loading: false,
    form_strategy_loading: false,
    valid: true,
    mountedStrategy: {
      access_list_type: { name: '' },
    },
    access_list_type: '',
    isLoadingRooms: false,
  }),
  computed: {
    saveDisabled() {
      if (this.form_strategy_loading) {
        return true
      }
      return this.access_list_type == this.mountedStrategy.access_list_type.name
    },
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    ...mapGetters({
      access_list_types: ['constants/access_list_types'],
    }),

    ...mapState({
      show: (state) => state.registries.show.data,
    }),
  },
  mounted() {
    console.log('strategy ', this.strategy)
    this.mountedStrategy = simpleObjectCopy(this.strategy)
    this.access_list_type = this.mountedStrategy.access_list_type.name
  },
  methods: {
    closeModal() {
      // this.$store.dispatch('registries/initUpdateForm')
      this.open = false
    },
    updateStrategy() {
      this.errorAlert = false
      this.form_loading = true
      this.form_strategy_loading = true
      this.$store
        .dispatch('registries/accessControlStrategyUpdate', {
          body: { access_list_type: this.access_list_type },
        })
        .then(({ data }) => {
          console.log(data)
          // const { data } = res.data
          return this.$store.dispatch('registries/show', {
            id: this.show.id,
          })
        })
        .then((res) => {
          console.log('after show', { res })
          this.open = false
        })
        .catch((err) => {
          this.errorAlert = true
          this.errorMsg = err
        })
        .finally(() => {
          this.form_strategy_loading = false
          this.form_loading = false
        })
    },
  },
}
</script>
