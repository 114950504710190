<template>
  <label class="tw-block tw-pb-2 tw-text-gray-600">
    <slot />
  </label>
</template>

<script>
export default {}
</script>

<style></style>
