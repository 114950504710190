import { tenancy } from '@/utils/tenancy'
import { ApolloClient } from 'apollo-client'
// import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
// import { setContext } from 'apollo-link-context'
// import { onError } from 'apollo-link-error'
// import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
// import { getMainDefinition } from 'apollo-utilities'
// import { NODE_SERVER, WS_SERVER } from './config'
import { WS_SERVER } from './config'
import { SubscriptionClient } from 'subscriptions-transport-ws'

// const GRAPHQL_ENDPOINT = 'ws://localhost:3000/graphql';

// const client = new SubscriptionClient(GRAPHQL_ENDPOINT, {
//   reconnect: true,
// });

// HTTP connection to the API
// const httpLink = createHttpLink({
//   // You should use an absolute URL here
//   uri: `${NODE_SERVER}/graphql`,
//   headers: {
//     authorization: `bearer ${localStorage.getItem('node_access_token')}`,
//     'x-refresh-token': localStorage.getItem('node_refresh_token'),
//   },
//   credentials: 'include',
// })

// const authMiddleware = setContext(async (_, { headers }) => {
//   const token = localStorage.getItem('node_access_token')
//   const refreshToken = localStorage.getItem('node_refresh_token')
//   // await store.dispatch('auth/getToken')
//   //     .then((accessToken) => {
//   //         token = accessToken;
//   //     })
//   //     .catch((error) => {
//   //         console.error('error getting token from vuex store');
//   //     });

//   return {
//     headers: {
//       ...headers,

//       authorization: `bearer ${token}`,
//       'x-refresh-token': refreshToken,
//       // authorization: token ? `Bearer ${token}` : '',
//       // 'X-APP-VERSION': global.APP_VERSION,
//       // 'X-APP-TOKEN': global.VUE_APP_X_TOKEN,
//     },
//   }
// })

// const errorMiddleware = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors) {
//     if (graphQLErrors[0].message === 'in maintenance') {
//       console.error('application in maintenance mode')
//     }
//   } else if (networkError) {
//     console.error('network error detected')
//   }
// })

export const wsClient = new SubscriptionClient(`${WS_SERVER}/graphql`, {
  reconnect: true,
  lazy: true,
  timeout: 180000,
  connectionParams: () => {
    const token = localStorage.getItem('node_access_token')
    const refreshToken = localStorage.getItem('node_refresh_token')
    // let parts = location.hostname.split('.')
    // var subdomain = parts.shift();
    const tenant = tenancy()
    console.log('check WebSocketLink TOKEN', token)
    return {
      token: `bearer ${token}`,
      refreshToken,
      tenant,
    }
  },

  connectionCallback(error, result) {
    console.log('connection call back')
    console.log(error)
    console.log(result)
  },
})
// export const wsClient = new SubscriptionClient(`${WS_SERVER}/subscriptions`, {
//   reconnect: true,
//   lazy: true,
//   timeout: 180000,
//   connectionParams: () => {
//     const token = localStorage.getItem('node_access_token')
//     const refreshToken = localStorage.getItem('node_refresh_token')
//     console.log('check WebSocketLink TOKEN', token)
//     return {
//       token: `bearer ${token}`,
//       refreshToken,
//     }
//   },

//   connectionCallback(error, result) {
//     console.log('connection call back')
//     console.log(error)
//     console.log(result)
//   },
// })
// Create the subscription websocket link
const wsLink = new WebSocketLink(wsClient)
// const wsLink = new WebSocketLink({
//   uri: `${WS_SERVER}/subscriptions`,
//   options: {
//     reconnect: true,
//     lazy: true,
//     connectionParams: () => {
//       const token = localStorage.getItem('node_access_token')
//       const refreshToken = localStorage.getItem('node_refresh_token')
//       console.log('check WebSocketLink TOKEN', token)
//       return {
//         token: `bearer ${token}`,
//         refreshToken,
//       }
//     },
//     connectionCallback(error, result) {
//       console.log(error)
//       console.log(result)
//     },
//   },
// })

// // Cache implementation
const cache = new InMemoryCache()

// // Create the apollo client
// export const apolloClient = new ApolloClient({
//   link: httpLink,
//   cache,
// })

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
// eslint-disable-next-line no-unused-vars
// const link = split(
//   // split based on operation type
//   ({ query }) => {
//     const definition = getMainDefinition(query)
//     return (
//       definition.kind === 'OperationDefinition' &&
//       definition.operation === 'subscription'
//     )
//   },
//   wsLink,

//   errorMiddleware.concat(authMiddleware.concat(httpLink)),
// )

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: wsLink,
  networkInterface: wsClient,
  cache,
  connectToDevTools: true,
})

// const apolloClient = new ApolloClient({
//   networkInterface: client,
// });
