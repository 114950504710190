export const SectionTypeKeys = {
  main_slider: 'main_slider',
  countdown_timer: 'countdown_timer',
  social_media: 'social_media',
  multi_buttons: 'multi_buttons',
  activities: 'activities_and_calendar',
  cta: 'body_hero_cta',
  sponsor: 'sponsor_logos',
  image: 'image_ratio',
  image_two: 'image_ratio_two',
  slider: 'slider',
  slider_two: 'slider_two',
}

export const BannerTypeKeys = {
  banner_with_text: 'banner_with_text',
  simple_image: 'simple_image',
}
