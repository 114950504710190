<template>
    <v-snackbar v-model="open" :timeout="timeout" :color="status">
        {{ msg }} 
    </v-snackbar>
</template>

<script>
export default {
    name: 'NotificationSnackbar',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: '',
        },
        msg: {
            type: String,
            default: '',
        },
        status: {
            type: String,
            default: '',
        },
    },
    data: () => ({
      timeout: 3000,
    }),
    computed: {
        open: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
}
</script>