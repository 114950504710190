import $axios from '@/axios-instance.js'

export default class MediaService {
  baseUrl = '/api/v1/actions/media-elements'

  // Create
  store(params) {
    return $axios.post(this.baseUrl, params)
  }
  // store
  storeDrop(params) {
    return $axios.post(this.baseUrl + '/dz', params)
  }

  // store
  commitStorage(params) {
    return $axios.post(this.baseUrl + '/commit-storage', params)
  }

  // Read all - findAll
  index(params) {
    return $axios.get(this.baseUrl, params)
  }

  // Read one - show
  show(id, params) {
    return $axios.get(this.baseUrl + `/${id}`, params)
  }

  // Update
  update(id, params) {
    return $axios.post(this.baseUrl + `/${id}`, params)
  }

  // Delete - delete
  destroy(id) {
    return $axios.post(this.baseUrl + `/${id}`)
  }
}
export const MediaServiceInstance = new MediaService()
