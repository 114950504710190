<template>
  <v-container
    class="fill-height tw-flex tw-items-start tw-overflow-y-scroll tw-bg-gray-100 tw-p-0 bz-scroll"
    fluid
  >
    <!-- <v-card flat class="tw-aspect-h-9 tw-aspect-w-16 tw-border-0 tw-rounded-none"> -->
    <slot name="top" />
    <!-- <v-card-text class="tw-overflow-y-scroll tw-p-0"> -->
    <v-container class="tw-h-full tw-w-full tw-p-0" fluid>
      <slot />
    </v-container>
    <!-- </v-card-text> -->
    <slot name="bottom" />
  </v-container>
  <!-- </v-card> -->
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.bz-scroll {
  &::-webkit-scrollbar {
    width: 2px;
    height: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid #ffffff; /* creates padding around scroll thumb */
  }
}
</style>