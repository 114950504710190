<template>
  <div>
    <template v-if="modsupport">
      <v-speed-dial 
        v-model="fab_icon" bottom right direction="top" transition="slide-y-reverse-transition"
        class="tw-absolute">
        <template #activator>
          <v-btn v-model="fab_icon" color="primary" dark fab small elevation="2">
            <v-icon v-if="fab_icon">close</v-icon>
            <v-icon v-else>support_agent</v-icon>
          </v-btn>
        </template>
        <v-btn :href="wa_link" target="_blank" fab dark x-small color="green" ><v-icon>chat</v-icon></v-btn>
        <v-btn :href="emailFomart" fab dark x-small color="secondary"><v-icon>alternate_email</v-icon></v-btn>
      </v-speed-dial>
    </template>
    <template v-else>
      <i18n path="support.registry" tag="p">
        <template #email>
          <a :href="emailFomart">
            {{ registry.support_email }}
          </a>
        </template>
        <template #phone>
          <a :href="wa_link" target="_blank">
            +{{ registry.support_phone_number }}
          </a>
        </template>
      </i18n>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    modsupport: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    fab_icon: false,
  }),
  computed: {
    emailFomart() {
      return `mailto:${this.registry.support_email}`
    },
    wa_msg() {
      return this.$i18n.t('support.registry-msg', {
        event: this.registry.label,
      })
    },
    wa_link() {
      return `https://${this.$vuetify.breakpoint.smAndDown ? 'api' : 'web'}.whatsapp.com/send?phone=${this.registry.support_phone_number}&text=${this.wa_msg}`
    },
    ...mapState({
      registry: (state) => state.registry.show.data,
    }),
  },
}
</script>

<style></style>
