<template>
  <v-select
    ref="language_selected"
    v-model="input_value"
    :items="languagesEnums"
    class="tw-w-48"
    :label="label"
    outlined
    rounded
    hide-details=""
    dense
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    value: {
      type: String,
      default: 'es',
    },
    label: {
      type: String,
      default: 'Idioma',
    },
  },
  computed: {
    input_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    inputListeners: function () {
      // let vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          // input: function (event) {
          //   vm.$emit("input", event.target.value);
          // },
        },
      )
    },
    ...mapGetters({
      languages: ['constants/languages'],
    }),
    languagesEnums() {
      return this.languages.map((row) => ({
        text: this.$t(`admin.enums.${row.value}`),
        value: row.value,
      }))
    },
  },
}
</script>

<style></style>
