import $axios from '@/axios-instance.js'

class UsersCertificateServiceClass {
    baseUrl = '/api/v1/admin/certificates'

    index(params) {
        return $axios.get(this.baseUrl, params)
    }
}

export const UsersCertificateService = new UsersCertificateServiceClass()
