import i18n from '@/i18n'
import { format, isFuture, isPast } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { myTz } from '@/utils/myTz'
const resolveTimezone = (tz) => (tz ? tz : myTz())

export const commonDatabaseFormat = (iso) => {
  return format(new Date(iso), 'yyyy-MM-dd HH:mm')
}

// import dayjs from 'dayjs'
export const dd_mm_yyyy = (date, tz) => {
  // return date ? dayjs(date).format('YYYY-MM-DD') : ''
  return date
    ? format(utcToZonedTime(new Date(date), resolveTimezone(tz)), 'yyyy-MM-dd')
    : ''
}
export const hh_mm = (time, tz) => {
  // return time ? dayjs(time).format('HH:mm') : ''
  return time
    ? format(utcToZonedTime(new Date(time), resolveTimezone(tz)), 'HH:mm')
    : ''
}
export const has_time_bracket = (start, end) => {
  return start || end ? true : false
}
export const time_bracket_types = () => [
  { text: i18n.t('app.start_end'), value: 'start_end' },
  { text: i18n.t('app.only_start'), value: 'only_start' },
  { text: i18n.t('app.only_end'), value: 'only_end' },
]
export const time_bracket_type = (start, end) => {
  if (start && end) {
    return 'start_end'
  }
  if (start) {
    return 'only_start'
  }
  if (end) {
    return 'only_end'
  }
  return 'start_end'
}

export const isFutureDate = (isoDateTz) => {
  return isFuture(new Date(isoDateTz))
}
export const isPastDate = (isoDateTz) => {
  return isPast(new Date(isoDateTz))
}
export const isHappening = (duration) => {
  return (
    isPastDate(duration.start_date_time) && isFutureDate(duration.end_date_time)
  )
}
import { es, enUS } from 'date-fns/locale'
import { formatDistance } from 'date-fns'

export const diffForHumans = (isoDateTz) => {
  return formatDistance(new Date(isoDateTz), new Date(), {
    addSuffix: true,
    locale: { es, en: enUS }[i18n.locale],
  })
}

export const commonTimeFormat = (isoDateTz) => {
  // console.log(i18n.locale)
  if (i18n.locale == 'es') {
    return format(new Date(isoDateTz), "hh:mm aaaa '-' dd MMMM, yyyy", {
      locale: { es }[i18n.locale],
    })
  } else if(i18n.locale == 'en') {
    return format(new Date(isoDateTz), "hh:mm aaaa '-' MMMM dd, yyyy", {
      locale: { en: enUS }[i18n.locale],
    })
  }
}

export const commonDateFormat = (isoDateTz) => {
  if (i18n.locale == 'es') {
    return format(new Date(isoDateTz), 'dd MMMM, yyyy', {
      locale: { es }[i18n.locale],
    })
  } else if(i18n.locale == 'en') {
    return format(new Date(isoDateTz), 'MMMM dd, yyyy', {
      locale: { en: enUS }[i18n.locale],
    })
  }
}

export const commonPreciseTimeFormat = (isoDateTz) => {
  return format(new Date(isoDateTz), "hh:mm:ss aaaa '-' do MMMM yyyy", {
    locale: { es, en: enUS }[i18n.locale],
  })
}
export const commonDistanceSince = (isoDateTz) => {
  return formatDistance(new Date(isoDateTz), new Date(), {
    addSuffix: true,
    locale: { es, en: enUS }[i18n.locale],
  })
}
