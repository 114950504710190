<template>
  <div class="">
    <v-hover v-if="img" v-slot="{ hover }">
      <v-card
        class="mx-auto tw-min-h-[50px] tw-overflow-hidden tw-rounded-3xl tw-shadow-md"
      >
        <v-container class="pa-8">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-img contain class="tw-w-full" :src="img" />
            </v-col>
          </v-row>
        </v-container>
        <v-fade-transition>
          <v-overlay v-if="hover" absolute color="grey lighten-1">
            <v-btn
              rounded
              class="tw-px-8 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-200"
              @click="toggle_dropzone = true"
            >
              {{ $t('app.change') }}
            </v-btn>
          </v-overlay>
        </v-fade-transition>
      </v-card>
    </v-hover>
    <div class="tw-space-y-6 tw-py-6">
      <v-card
        v-show="toggle_dropzone"
        class="mx-auto tw-rounded-3xl tw-shadow-md"
      >
        <v-container>
          <dropzone
            :media-group="mediaGroup"
            :width="width"
            :height="height"
            :bypass="bypass"
            @on-success="uploadedFileDesktop"
          />
        </v-container>
      </v-card>
      <div v-if="toggle_dropzone" class="tw-flex tw-justify-end">
        <v-btn
          v-show="img"
          class="primary--text text--darken-4 tw-px-8 tw-font-black tw-normal-case focus:tw-outline-none"
          color="primary lighten-5"
          text
          rounded
          type="button"
          dark
          @click="toggle_dropzone = false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="tw-mr-2 tw--ml-1 tw-h-6 tw-w-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
          {{ $t('app.cancel') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Dropzone from '@/components/Dropzone.vue'
export default {
  components: { Dropzone },
  props: {
    value: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },

    bypass: {
      type: String,
      default: 'simple-check',
      validator(v) {
        return ['no-check', 'simple-check', 'ratio-check'].includes(v)
      },
    },
    mediaGroup: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    toggle_dropzone: true,
  }),
  computed: {
    img: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    img: {
      handler(new_val) {
        if (!new_val) {
          this.toggle_dropzone = true
        } else {
          this.toggle_dropzone = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    uploadedFileDesktop(res) {
      this.img = res.media_element.link
      this.toggle_dropzone = false
    },
  },
}
</script>

<style></style>
