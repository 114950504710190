export const DurationTypesConsts = [
  { text: 'Hasta que lo cierre el usuario', value: 0 },
  { text: '3 segundos', value: 3000 },
  { text: '5 segundos', value: 5000 },
  { text: '10 segundos', value: 10000 },
  { text: '15 segundos', value: 15000 },
  { text: '30 segundos', value: 30000 },
  { text: '1 minuto', value: 60000 },
  { text: '2 minuto', value: 120000 },
  { text: '3 minuto', value: 180000 },
  { text: '5 minuto', value: 300000 },
]
