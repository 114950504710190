<template>
  <v-dialog
    v-model="open"
    persistent
    max-width="640px"
    scrollable
    content-class="tw-rounded-3xl tw-w-full"
  >
    <v-form
      ref="form_dooway"
      v-model="valid"
      :disabled="form_loading"
      lazy-validation
      enctype="multipart/form-data"
      @submit.prevent="onSubmit"
    >
      <v-card class="tw-rounded-3xl tw-border-0 tw-shadow-md">
        <ModalHeader @close-modal="closeModal">CSS</ModalHeader>
        <v-card-text class="tw-p-0" style="max-height: 700px">
          <v-responsive class="tw-py-0">
            <v-container class="tw-bg-gray-100">
              <v-row class="mb-3">
                <v-col
                  cols="12"
                  class="mx-auto pa-5 tw-h-72 lg:tw-h-80 2xl:tw-h-96"
                >
                  <EditorCodemirror
                    v-if="open"
                    v-model="custom_css"
                    class="tw-h-72 tw-rounded-xl lg:tw-h-80 2xl:tw-h-96"
                    :options="cmOptions"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-responsive>
        </v-card-text>
        <v-card-actions>
          <v-alert
            v-model="alert"
            dismissible
            color="red"
            border="left"
            elevation="2"
            colored-border
            icon="error"
            transition="fade-transition"
          >
            {{ errorMsg }}
          </v-alert>

          <v-spacer />
          <AppBtn
            type="button"
            color="transparent"
            :disabled="form_loading"
            @click="closeModal"
          >
            {{ $t('app.cancel') }}
          </AppBtn>
          <AppBtn
            type="submit"
            :loading="form_loading"
            color="primary"
            icon="check"
          >
            {{ $t('app.save') }}
          </AppBtn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ModalHeader from '@/components/ModalHeader'

// import 'codemirror/mode/htmlmixed/htmlmixed.js'
import 'codemirror/mode/css/css'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import EditorCodemirror from '@/components/FormInputs/EditorCodemirror'
// import EditorMonaco from '@/components/FormInputs/EditorMonaco'
import { rules } from '@/utils/rules'
import AppBtn from './App/AppBtn.vue'
// import MonacoEditor from 'vue-monaco'

// import { VueMonacoEditor } from '@xxyy/vue-monaco-editor'
export default {
  components: {
    EditorCodemirror,
    // EditorMonaco,
    // MonacoEditor,
    ModalHeader,
    AppBtn,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    customCss: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    has_mounted: false,
    cmOptions: {
      tabSize: 4,
      mode: 'text/css',
      theme: 'material',
      lineNumbers: true,
      line: true,
      lineWrapping: true,
      autoRefresh: true,
      matchBrackets: true,
    },
    errorMsg: '',
    exam: '',
    rules,
    alert: false,
    form_loading: false,
    form_disabled_state: false,
    valid: true,
    mountedRoomElement: {},
  }),
  computed: {
    custom_css: {
      get() {
        return this.customCss
      },
      set(newValue) {
        this.$emit('update:customCss', newValue)
      },
    },
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    onSubmit() {
      this.open = false
    },
    closeModal() {
      this.open = false
    },
  },
}
</script>

<style></style>
