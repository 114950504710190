<template>
    <v-dialog
      v-model="open"
      persistent
      scrollable
      :fullscreen="isMobile"
      :hide-overlay="isMobile"
      max-width="1360px"
      :content-class="
        isMobile
          ? 'tw-rounded-none fill-height tw-w-full print:tw-hidden'
          : 'tw-rounded-xl fill-height tw-w-full tw-max-h-[640px] print:tw-hidden'
      "
    >
      <v-card class="fill-height tw-border-0 tw-shadow-md tw-rounded-t-xl ">
        <!-- ------------------- Tabs Dashboard Mod (Attendees, Register, Aforo) ------------------- -->
        <v-container class="tw-p-0" fluid>
          <div
            class="tw-relative tw-grid tw-grid-cols-6 tw-items-center tw-rounded-none tw-bg-primary-500 tw-pl-5 lg:tw-rounded-t-xl print:tw-hidden"
          >
            <div class="tw-col-span-5 tw-flex tw-items-end">
              <v-tabs
                v-model="tab"
                dark
                background-color="tw-bg-primary-500"
                show-arrows
              >
                <v-tabs-slider color="primary lighten-3" />
                <v-tab
                  v-for="item in items"
                  :key="item.name"
                  :href="`#${item.name}`"
                  class="tw-py-3 tw-px-6 tw-text-xs"
                >
                  {{ item.label }}
                </v-tab>
              </v-tabs>
            </div>
            <div
              class="tw-flex tw-h-full tw-items-center tw-justify-end tw-space-x-3 tw-px-2"
            >
              <v-fab-transition>
                <v-btn
                  fab
                  x-small
                  class="focus:tw-outline-none focus:tw-ring"
                  @click="open = false"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-fab-transition>
            </div>
          </div>
        </v-container>
        <v-divider />
        <!-- ------------------- Content Dashboard Mod (Attendees, Register, Aforo) ------------------- -->
        <v-card-text class="fill-height tw-p-0">
          <v-responsive class="fill-height tw-py-0">
            <v-tabs-items v-model="tab" class="fill-height">
              <v-tab-item
                :key="ModCustomFormTabKeys.attendees"
                :value="ModCustomFormTabKeys.attendees"
                class="fill-height print:tw-hidden"
              >
                <UserListEntries ref="entries" />
              </v-tab-item>
              <v-tab-item
                :key="ModCustomFormTabKeys.register"
                :value="ModCustomFormTabKeys.register"
                class="fill-height print:tw-hidden"
              >
                <ModRegisterCustomForm ref="register" :custom-form="customForms"  />
              </v-tab-item>
            </v-tabs-items>
          </v-responsive>
        </v-card-text>
        <v-divider />
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { mapFields } from '@/helpers'
  // import ModDashboardAttendeesTab from './ModDashboardAttendeesTab.vue'
  import { ModCustomFormTabKeys } from '@/enums/ModTabKeys'
  import { mapState } from 'vuex'
  import UserListEntries from '@/components/Event/UserListEntries.vue'
  
  // import AppBtn from '@/components/App/AppBtn.vue'
  import ModRegisterCustomForm from './ModRegisterCustomForm.vue'

  export default {
    name: 'ModDashboardModal',
    components: {
      UserListEntries,
      // ModDashboardAttendeesTab,
      // AppBtn,
      ModRegisterCustomForm,
    },
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      ModCustomFormTabKeys,
      customForms: [],
    }),  
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.mdAndDown
      },
      isAdmin() {
        return this.user.roles.map((row) => row.name).includes('admin')
      },
      ...mapFields({
        fields: ['tab'],
        base: ['event', 'mod_dashboard_custom_form'],
        mutation: 'event/setModDashboardCustomForm',
      }),
      items() {
        return Object.keys(ModCustomFormTabKeys).map((row) => ({
          name: ModCustomFormTabKeys[row],
          label: this.$i18n.t(`pages.event.mod_dash.${ModCustomFormTabKeys[row]}`),
        }))
      },
      ...mapState({
        user: (state) => state.session.user,
        registry: (state) => state.event.constants.registry.id,
      }),
      open: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        },
      },
    },
    mounted() {
      this.loadCustomForms()
    },
    methods: {
      // Methods
      loadCustomForms() {
        this.$store.dispatch('forms/loadCustomForms', {
          params: {
            registry_id: this.registry,
          },
        })
        .then((res) => {
          this.customForms = res.data.forms
        })
      },
    },
  }
  </script>
  
  <style></style>
  