<template>
  <renderless-layout>
    <v-container fluid class="tw-p-0">
      <sticky-header>
        <template #title>
          <primary-title> {{ $t('admin.app.general_config') }} </primary-title>
        </template>
      </sticky-header>
      <v-row class="tw-m-0 tw-p-0">
        <v-col cols="12" sm="12" md="12" lg="7" class="mx-auto tw-container">
          <!-- Actions panel -->
          <div class="tw-py-10">
            <section aria-labelledby="quick-links-title ">
              <div
                class="tw-divide-y tw-divide-gray-200 tw-overflow-hidden tw-rounded-3xl tw-bg-gray-200 tw-shadow sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-px sm:tw-divide-y-0"
              >
                <div class="tw-sr-only">
                  <primary-title>Quick links</primary-title>
                </div>
                <HomeQuickLink
                  v-for="(link, index) in quick_links"
                  :key="link.name"
                  :index-item="index"
                  :length-items="quick_links.length"
                  :name="link.name"
                  :details="link.details"
                  :icon="link.icon"
                  :route-to="link.route"
                  :marker-type="link.marketType"
                  item-type="router-link"
                />
                <!-- @click="push(link.route)" -->
              </div>
            </section>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </renderless-layout>
</template>

<script>
import PrimaryTitle from '@/components/Titles/PrimaryTitle'
import HomeQuickLink from '@/components/HomeQuickLink.vue'
import StickyHeader from '@/components/StickyHeader'
import { mapState } from 'vuex'
const links = [
  {
    route: 'general',
    name: 'Administración del Evento',
    details:
      'Modifica los colores,  items de navegacion y mas sobre tu experiencia virtual.',
    marketType: 'svg',
    icon: 'cog',
  },
  {
    route: 'additionals',
    name: 'Limites y Opciones adicionales',
    details:
      'Limites de licencia y opciones adicionales para restablecer la plataforma a valores iniciales.',
    marketType: 'svg',
    icon: 'adjust_alt',
  },
  {
    route: 'event-broadcasts',
    name: 'Notificaciones',
    details: 'Enviar y programa notificaciones.',
    marketType: 'svg',
    icon: 'bell',
  },
  // {
  //   route: 'leaderboards',
  //   name: 'Leadboard',
  //   marketType: 'svg',
  //   details:
  //     'Genera las reglas para la acumulacion de puntos y destaca los premios del evento.',
  //   icon: 'award',
  // },
].map((row) => ({
  ...row,
  name: row.name,
  route_name: row.route,
  route: `/admin/configs/${row.route}`,
}))
export default {
  components: {
    PrimaryTitle,
    StickyHeader,
    HomeQuickLink,
  },
  data: () => ({}),
  computed: {
    quick_links() {
      return links
        .filter((link) => {
          return link.route_name === 'general'
            ? !this.license.can_concurrent_events
            : true
        })
        .map((row) => ({
          ...row,
          name: this.$t(`admin.settings.${row.route_name}`),
          details: this.$t(`admin.settings.details.${row.route_name}`),
        }))
    },
    ...mapState({
      license: (state) => state.constants.data.license,
      user: (state) => state.session.user,
    }),
  },
}
</script>

<style></style>
