var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"user_list",staticClass:"tw-flex tw-h-full tw-flex-col"},[(_vm.searchTab)?_c('div',{staticClass:"tw-w-full tw-rounded-t-lg"},[_c('div',{staticClass:"tw-flex tw-items-center tw-space-x-2 tw-px-4 tw-py-5"},[_c('div',{staticClass:"tw-relative tw-flex tw-flex-1 tw-items-center tw-space-x-3"},[_c('div',{staticClass:"tw-min-w-0 tw-flex-1"},[_c('v-text-field',{attrs:{"label":_vm.$t('app.search'),"outlined":"","rounded":"","hide-details":"","dense":"","prepend-inner-icon":"search"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"tw--mr-4 focus:tw-outline-none focus:tw-ring focus:tw-ring-white tw-bg-primary-500",attrs:{"x-small":"","rounded":"","fab":"","height":"24"}},'v-btn',attrs,false),on),[_c('g-icon',{staticClass:"tw-h-3.5 tw-w-3.5 tw-text-white",attrs:{"name":"filter_solid"}})],1)]}}],null,false,656701438)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary","multiple":""},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}},[_vm._l((_vm.filterAbles),function(item,i){return [_c('v-list-item',{key:("item-" + i),attrs:{"value":item.value,"active-class":"primary--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary accent-4"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)]}}],null,true)})]})],2)],1)],1)]},proxy:true}],null,false,882872956),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])])]):_vm._e(),(!_vm.searchTab)?_c('div',{staticClass:"tw-w-full tw-rounded-t-lg tw-bg-primary-500"},[_c('div',{staticClass:"tw-flex tw-items-center tw-space-x-2 tw-px-4 tw-py-5"},[_c('div',{},[_c('v-btn',{staticClass:"text--white tw-normal-case tw-shadow-sm focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-300",attrs:{"fab":"","x-small":""},on:{"click":function($event){_vm.view = 'HOME'}}},[_c('svg',{staticClass:"tw-h-4 tw-w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 19l-7-7 7-7"}})])])],1),_c('div',{staticClass:"tw-relative tw-flex tw-flex-1 tw-items-center tw-space-x-3"},[_c('div',{staticClass:"tw-min-w-0 tw-flex-1"},[_c('v-text-field',{attrs:{"label":_vm.$t('app.search'),"outlined":"","rounded":"","hide-details":"","dark":"","dense":"","prepend-inner-icon":"search"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"tw--mr-4 focus:tw-outline-none focus:tw-ring focus:tw-ring-white",attrs:{"x-small":"","rounded":"","fab":"","height":"24"}},'v-btn',attrs,false),on),[_c('g-icon',{staticClass:"tw-h-3.5 tw-w-3.5 tw-text-white",attrs:{"name":"filter_solid"}})],1)]}}],null,false,2803373356)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary","multiple":""},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}},[_vm._l((_vm.filterAbles),function(item,i){return [_c('v-list-item',{key:("item-" + i),attrs:{"value":item.value,"active-class":"primary--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary accent-4"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)]}}],null,true)})]})],2)],1)],1)]},proxy:true}],null,false,583382044),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])])]):_vm._e(),_c('div',{ref:"scrollToMe",staticClass:"tw-scrolling-touch scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 tw-flex tw-flex-col tw-space-y-4 tw-overflow-y-auto",attrs:{"id":"messages"}},[(_vm.filteredUsers.length > 0)?_c('ul',{staticClass:"tw-w-full tw-divide-y tw-divide-gray-200 tw-pl-0"},[_c('v-virtual-scroll',{staticClass:"bz-scroll",attrs:{"item-height":72,"items":_vm.filteredUsers,"height":_vm.height},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('UsersListItem',{attrs:{"user":item}})]}}],null,false,144103521)})],1):_c('div',[_c('div',{staticClass:"tw-px-4 sm:tw-px-6 lg:tw-px-16"},[_c('div',{staticClass:"tw-pt-10 tw-pb-16 tw-text-center sm:tw-pt-24 sm:tw-pb-36 lg:tw-pt-40 lg:tw-pb-56"},[_c('svg',{staticClass:"tw-mx-auto tw-mb-6 tw-text-gray-900",attrs:{"width":"96","height":"96","fill":"none"}},[_c('path',{attrs:{"d":"M36 28.024A18.05 18.05 0 0025.022 39M59.999 28.024A18.05 18.05 0 0170.975 39","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('ellipse',{attrs:{"cx":"37.5","cy":"43.5","rx":"4.5","ry":"7.5","fill":"currentColor"}}),_c('ellipse',{attrs:{"cx":"58.5","cy":"43.5","rx":"4.5","ry":"7.5","fill":"currentColor"}}),_c('path',{attrs:{"d":"M24.673 75.42a9.003 9.003 0 008.879 5.563m-8.88-5.562A8.973 8.973 0 0124 72c0-7.97 9-18 9-18s9 10.03 9 18a9 9 0 01-8.448 8.983m-8.88-5.562C16.919 68.817 12 58.983 12 48c0-19.882 16.118-36 36-36s36 16.118 36 36-16.118 36-36 36a35.877 35.877 0 01-14.448-3.017","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M41.997 71.75A14.94 14.94 0 0148 70.5c2.399 0 4.658.56 6.661 1.556a3 3 0 003.999-4.066 12 12 0 00-10.662-6.49 11.955 11.955 0 00-7.974 3.032c1.11 2.37 1.917 4.876 1.972 7.217z","fill":"currentColor"}})]),(_vm.search)?_c('i18n',{staticClass:"tw-mb-3 tw-text-lg tw-font-medium tw-leading-5 tw-text-gray-900",attrs:{"path":"app.sorry_no_results","tag":"p"},scopedSlots:_vm._u([{key:"term",fn:function(){return [_vm._v("“"+_vm._s(_vm.search)+"\". ")]},proxy:true}],null,false,2879389404)}):_c('p',{staticClass:"tw-mb-3 tw-text-lg tw-font-medium tw-leading-5 tw-text-gray-900"},[_vm._v(" "+_vm._s(_vm.$t('app.no_users'))+" ")])],1)])])]),_c('div',{staticClass:"tw-hidden tw-border-t-2 tw-border-gray-200 tw-px-4 tw-pt-2 tw-pb-2 sm:tw-mb-0"})])}
var staticRenderFns = []

export { render, staticRenderFns }