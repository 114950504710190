<template>
  <div class="">
    <!-- open overlay -->
    <v-overlay
      :value="toggle_dropzone"
      :class="{
        'tw-backdrop-filter-blur tw-z-50 tw-rounded-none tw-backdrop-blur tw-backdrop-brightness-50 tw-backdrop-filter':
          toggle_dropzone,
      }"
    >
      <div>
        <div class="tw-w-full">
          <v-card
            class="mx-auto tw-mb-6 tw-rounded-3xl tw-bg-white tw-shadow-md"
          >
            <v-container class="tw-bg-white">
              <dropzone
                :media-group="mediaGroup"
                :width="width"
                :height="height"
                :bypass="bypass"
                :placeholder="placeholder"
                @on-success="uploadedFileDesktop"
              />
            </v-container>
          </v-card>
          <div class="tw-flex tw-justify-end">
            <AppBtn
              icon="close"
              color="transparent-dark"
              @click="toggle_dropzone = false"
            >
              {{ $t('app.cancel') }}
            </AppBtn>
          </div>
        </div>
      </div>
    </v-overlay>
    <!-- image change -->
    <div v-if="img">
      <div
        v-if="isImg"
        class="tw-relative tw-block tw-min-h-[64px] tw-overflow-hidden tw-rounded-3xl"
      >
        <!-- <v-img contain class="tw-w-full tw-relative" :src="img">
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0 transparent"
              align="center"
              justify="center"
            >
              <v-progress-circular size="32" indeterminate color="primary" />
            </v-row>
          </template>
        </v-img> -->
        <!-- loading="lazy" -->
        <img class="tw-relative tw-w-full" :src="img" />
        <div
          class="tw-pointer-events-none tw-absolute tw-inset-0 tw-flex tw-h-full tw-w-full tw-items-start tw-justify-end tw-p-4 tw-text-sm tw-font-medium tw-text-white"
        >
          <div class="tw-pointer-events-auto tw-flex tw-flex-col tw-space-y-2">
            <v-btn
              dark
              rounded
              class="tw-px-8 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-200"
              @click="toggle_dropzone = true"
            >
              {{ $t('app.change') }}
            </v-btn>
          </div>
        </div>
      </div>
      <div
        v-else-if="isVideo"
        class="tw-relative tw-hidden tw-overflow-hidden tw-rounded-3xl lg:tw-block"
      >
        <AppPlyr class="tw-relative">
          <video controls crossorigin playsinline>
            <source :src="img" type="video/mp4" />
          </video>
        </AppPlyr>
        <div
          class="tw-pointer-events-none tw-absolute tw-inset-0 tw-flex tw-h-full tw-w-full tw-items-start tw-justify-end tw-p-4 tw-text-sm tw-font-medium tw-text-white"
        >
          <v-btn
            rounded
            class="tw-pointer-events-auto tw-bg-gray-50 tw-normal-case tw-text-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-200"
            dark
            @click="toggle_dropzone = true"
          >
            {{ $t('app.change') }}
          </v-btn>
        </div>
      </div>
      <div v-else class="tw-py-4 sm:tw-grid sm:tw-gap-4 sm:tw-py-5">
        <!-- <dt class="tw-text-sm tw-font-medium tw-text-gray-500">Attachments</dt> -->
        <dd class="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-mt-0">
          <ul
            class="tw-divide-y tw-divide-gray-200 tw-rounded-md tw-border tw-border-gray-200 tw-pl-0"
          >
            <li
              class="tw-flex tw-items-center tw-justify-between tw-py-3 tw-pl-3 tw-pr-4 tw-text-sm"
            >
              <div class="tw-flex tw-w-0 tw-flex-1 tw-items-center">
                <!-- Heroicon name: solid/paper-clip -->
                <a
                  :href="img"
                  target="_blank"
                  class="tw-group tw-flex tw-w-0 tw-flex-1 tw-items-center tw-truncate tw-text-gray-700 tw-transition hover:tw-text-primary-700"
                >
                  <svg
                    class="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-400 tw-transition group-hover:tw-text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span class="tw-ml-2 tw-w-0 tw-flex-1 tw-truncate">
                    {{ fileName }}
                  </span>
                </a>
              </div>
              <div class="tw-ml-4 tw-flex tw-flex-shrink-0 tw-space-x-4">
                <button
                  type="button"
                  class="tw-rounded-md tw-bg-white tw-font-medium tw-text-primary-600 hover:tw-text-primary-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2"
                  @click="toggle_dropzone = true"
                >
                  {{ $t('app.change') }}
                </button>
                <span class="tw-text-gray-300" aria-hidden="true">|</span>
                <button
                  type="button"
                  class="tw-rounded-md tw-bg-white tw-font-medium tw-text-primary-600 hover:tw-text-primary-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2"
                  @click="img = ''"
                >
                  {{ $t('app.delete') }}
                </button>
              </div>
            </li>
          </ul>
        </dd>
      </div>
    </div>
    <!-- <v-card v-else class="tw-shadow-md mx-auto tw-rounded-3xl"> -->
    <v-card v-else class="tw-shadow-none">
      <v-container fluid class="tw-p-0">
        <dropzone
          v-if="!placeholderAlt"
          :media-group="mediaGroup"
          :width="width"
          :height="height"
          :placeholder="placeholder"
          :bypass="bypass"
          @on-success="uploadedFileDesktop"
        />
        <div
          v-else
          class="tw-mt-1 tw-flex tw-justify-center tw-rounded-md tw-border-2 tw-border-dashed tw-border-gray-300 tw-px-6 tw-pt-5 tw-pb-6"
        >
          <div class="tw-space-y-1 tw-text-center">
            <v-btn
              rounded
              class="tw-pointer-events-auto tw-bg-gray-50 tw-normal-case tw-text-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-200"
              dark
              @click="toggle_dropzone = true"
            >
              {{ placeholderAlt }}
            </v-btn>
            <!-- <p class="tw-text-xs tw-text-gray-500">
              PNG, JPG, GIF up to 10MB
            </p> -->
          </div>
        </div>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import AppPlyr from '@/components/Video/AppPlyr.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import Dropzone from '@/components/Dropzone.vue'
import { byPass } from '@/enums/MediaDropzoneBypass'
export default {
  components: { Dropzone, AppBtn, AppPlyr },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    placeholderAlt: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    bypass: {
      type: String,
      default: 'simple-check',
      validator(v) {
        return Object.keys(byPass).includes(v)
      },
    },
    mediaGroup: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    toggle_dropzone: false,
  }),
  computed: {
    img: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    isImg() {
      return /.(ico|jpg|jpeg|png|svg|webp|tif|tiff|gif)$/i.test(this.img)
    },
    isVideo() {
      return /.(mp4|webm)$/i.test(this.img)
    },
    fileName() {
      return this.img.split('/').pop()
    },
  },
  // watch: {
  //   img: {
  //     handler(new_val) {
  //       if (!new_val) {
  //         this.toggle_dropzone = true
  //       } else {
  //         this.toggle_dropzone = false
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
  methods: {
    uploadedFileDesktop(res) {
      this.img = res.media_element.link
      this.toggle_dropzone = false
      this.$emit('on-success', res)
    },
  },
}
</script>

<style></style>
