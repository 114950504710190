<template>
  <v-dialog
    v-model="open"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template #activator="{ on, attrs }">
      <AppBtn
        color="primary"
        type="button"
        v-bind="attrs"
        class="tw-px-5 tw-text-xs"
        @click="openQrScanner"
        v-on="on"
      >
        <v-icon left>
          qr_code_scanner
        </v-icon>
        {{ $t('admin.home.labels.scann') }}
      </AppBtn>
    </template>
    <v-card class="tw-gray-100">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t('admin.home.labels.scann') }} QR</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            fab
            x-small
            text
            dark
            class="focus:tw-outline-none focus:tw-ring"
            @click="open = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container>
        <v-card class="tw-rounded-3xl tw-shadow-md" white>
          <v-container class="tw-overflow-hidden tw-p-0">
            <div
              class="tw-aspect-h-16 tw-aspect-w-9 lg:tw-aspect-h-9 lg:tw-aspect-w-16"
            >
              <video ref="qr-video" class="tw-h-full tw-w-full" />
            </div>
          </v-container>
          <v-container>
            <div class="tw-flex tw-justify-end">
              <div>
                <v-select
                  v-model="camara"
                  label="Scaner"
                  :items="camaras"
                  outlined
                  rounded
                  @change="onUpdate"
                />
              </div>
            </div>
          </v-container>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import QrScanner from 'qr-scanner'
import AppBtn from '../App/AppBtn.vue'
import throttle from 'lodash/throttle'
export default {
  components: { AppBtn },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    camaras: [],
    camara: '',
    has_checked: false,
    qrScanner: null,
  }),
  computed: {
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    open(value) {
      if (value) {
        this.openQrScanner()
      } else {
        this.cleanUp()
      }
    },
  },
  methods: {
    onUpdate(val) {
      if (!this.qrScanner) {
        return
      }
      this.qrScanner.setCamera(val)
    },
    cleanUp() {
      if (!this.qrScanner) {
        return
      }
      this.qrScanner.destroy()
      this.qrScanner = null
    },
    openQrScanner() {
      this.has_checked = false
      this.$nextTick(function () {
        QrScanner.listCameras(true).then((camaras) => {
          const videoElem = this.$refs['qr-video']
          this.camaras = camaras.map((row) => ({
            text: row.label,
            value: row.id,
          }))
          this.camara = this.camaras[0].value
          this.qrScanner = new QrScanner(
            videoElem,
            throttle((result) => {
              if (this.has_checked) {
                return
              }
              if (!result.data.includes(window.location.origin)) {
                return
              }
              const url = new URL(result.data)
              const pathname = url.pathname
              this.has_checked = true
              this.$emit('validate-user', { pathname })
              this.open = false
            }),
            {
              highlightScanRegion: true,
              preferredCamera: this.camaras[0].value,
            },
          )
          this.qrScanner.start()
        })
      })
    },
  },
}
</script>

<style></style>
