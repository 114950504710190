<script>
export default {
  props: {
    on: { type: Boolean, default: false },
    fullScreen: { type: Boolean, default: false },
  },
  mounted() {
    console.log('Full screen layout:', this.on)
    this.$store.commit('setFullScreenLayout', { bool: this.on })
    this.$store.commit('setGlobalState', {
      fullScreenForce: this.fullScreen,
    })
  },
  // eslint-disable-next-line no-unused-vars
  render(h) {
    return this.$slots.default
  },
}
</script>
