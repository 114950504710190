<template>
  <!-- class="tw-aspect-w-16 tw-aspect-h-9" -->
  <div v-if="section.data.slides.length > 0">
    <VueSlickCarousel
      key="landing-slider"
      v-bind="{
        ...settings,
        autoplaySpeed: section.data.duration,
      }"
      class="tw-h-full tw-w-full"
    >
      <div
        v-for="slide in section.data.slides"
        :key="slide.uid"
        class="tw-pointer-events-auto focus:tw-outline-none"
      >
        <RegistrySliderSlide :slug="slug" :slide="slide" :section="section" />
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import RegistrySliderSlide from './RegistrySliderSlide.vue'

export default {
  components: {
    VueSlickCarousel,
    RegistrySliderSlide,
  },
  props: {
    slug: {
      type: String,
      default: '',
    }, 
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    settings: {
      arrows: true,
      dots: false,
      centerPadding: '0px',
      // dotsClass: 'slick-dots custom-dot-class',
      // variableWidth: true,
      fade: true,
      // adaptiveHeight: true,
      autoplay: true,
      // autoplaySpeed: 2000,
      infinite: true,
      // speed: 500,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            dots: true,
          },
        },
      ],
    },
  }),
}
</script>