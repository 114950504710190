<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="1024px"
    content-class="tw-rounded-3xl tw-w-full"
  >
    <v-card class="tw-relative tw-rounded-3xl tw-border-0 tw-shadow-md">
      <ModalHeader @close-modal="closeModal">{{
        content_window.label
      }}</ModalHeader>
      <v-divider />
      <!-- style="max-height: 768px" -->
      <v-card-text class="tw-p-0">
        <v-responsive class="tw-py-0">
          <v-container id="iframe-wrapper" class="tw-bg-white tw-p-0" fluid>
            <div>
              <ul
                v-for="element in formatedRows"
                :key="element.uuid"
                role="list"
                class="tw-divide-y tw-divide-gray-200 tw-rounded-md tw-border tw-border-gray-200 tw-pl-0"
              >
                <li
                  class="tw-flex tw-items-center tw-justify-between tw-py-3 tw-pl-3 tw-pr-4 tw-text-sm"
                >
                  <div class="tw-flex tw-w-0 tw-flex-1 tw-items-center">
                    <!-- <svg

                      x-description="Heroicon name: solid/paper-clip"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                        clip-rule="evenodd"
                      />
                    </svg> -->
                    <GIcon
                      name="g-next"
                      g-next="Attachment"
                      class="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-400"
                    />
                    <span
                      class="tw-ml-2 tw-w-0 tw-flex-1 tw-truncate tw-text-lg"
                    >
                      {{ element.content_library_element.label }}
                    </span>
                  </div>
                  <div class="tw-ml-4 tw-flex-shrink-0">
                    <AppBtn
                      color="transparent-border"
                      @click="contentItemEvent(element.content_library_element)"
                    >
                      {{ element.labelBtn }}
                    </AppBtn>
                  </div>
                </li>
              </ul>
            </div>
          </v-container>
        </v-responsive>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          rounded
          class="tw-px-8 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-white"
          @click="closeModal"
        >
          {{ $t('app.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GIcon from '@/components/GIcon.vue'
import ModalHeader from '@/components/ModalHeader'
import { mapState } from 'vuex'
import { ContentTypeKeys } from '@/enums/ContentTypeKeys'
import { download } from '@/utils/download'
import AppBtn from '@/components/App/AppBtn.vue'
import { TargetLinkKeys } from '@/enums/TargetLinkKeys'
import { openWindowTab } from '@/utils/openWindowTab'
import { AppCoreVitalKeys } from '@/enums/AppCoreVitalKeys'
export default {
  components: {
    ModalHeader,
    AppBtn,
    GIcon,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    ContentTypeKeys,
  }),
  computed: {
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    ...mapState({
      content_window: (state) => state.event.contentWindow.contentWindowData,
      registry: (state) => state.event.constants.registry,
    }),
    formatedRows() {
      return this.content_window.content_item_elements.map((row) => {
        return { ...row, labelBtn: this.labelBtn(row.content_library_element) }
      })
    },
  },
  mounted() {},

  methods: {
    closeModal() {
      this.open = false
    },
    labelBtn(content_item_element) {
      const {
        target_link_type_id,
        content_type: { name },
      } = content_item_element
      if ([ContentTypeKeys.download, ContentTypeKeys.link].includes(name)) {
        if (TargetLinkKeys.normal.value == target_link_type_id) {
          return this.$t('app.go_to_link')
        }
        if (TargetLinkKeys.target.value == target_link_type_id) {
          return this.$t('app.open')
        }
      }
      return (
        {
          [ContentTypeKeys.doorway]: this.$t('app.go_to_room'),
          [ContentTypeKeys.audio]: this.$t('app.listen'),
          [ContentTypeKeys.video]: this.$t('app.listen'),
        }[name] || this.$t('app.view')
      )
    },

    contentItemEvent(content_item_element) {
      const {
        target_link_type_id,
        download_item_url,
        doorway_item_room_id,
        link_item_url,
        id,
        content_type: { name },
      } = content_item_element
      console.log({ id })
      if (ContentTypeKeys.download == name) {
        console.log(target_link_type_id)
        if (TargetLinkKeys.normal.value == target_link_type_id) {
          download(download_item_url, download_item_url.split('/').pop(0))
        }
        if (TargetLinkKeys.target.value == target_link_type_id) {
          this.$store.commit('event/setContentItem', {
            contentItemModal: true,
            contentItemData: content_item_element,
          })
        }
      }
      if (ContentTypeKeys.doorway == name) {
        const room =
          this.$store.getters['event/getRoomFromConstants'](
            doorway_item_room_id,
          )
        console.log(room)
        if (room) {
          this.toVirtualRoom(room.slug)
        }
      }
      if (ContentTypeKeys.link == name) {
        console.log(link_item_url)
        console.log(target_link_type_id)
        if (TargetLinkKeys.normal.value == target_link_type_id) {
          openWindowTab(link_item_url)
        }
        if (TargetLinkKeys.target.value == target_link_type_id) {
          this.$store.commit('event/setContentItem', {
            contentItemModal: true,
            contentItemData: content_item_element,
          })
        }
      }
      if (ContentTypeKeys.video == name) {
        this.$store.commit('event/setContentVideo', {
          contentVideoModal: true,
          contentVideoData: content_item_element,
        })
      }
      const core_vital_type = AppCoreVitalKeys.EventContent
      const solid = this.$store.getters['event/plainMetricsPayload']
      this.$store.dispatch('general/recordCoreVital', {
        payload: {
          core_vital_type,
          simple_log: name,
          ...solid,
          content_library_element_id: id,
        },
        core_vital_type,
      })
    },

    toVirtualRoom(slug) {
      if (this.slug === slug) {
        return
      }
      this.$router.push({
        name: this.is_preview ? 'event-preview.route' : 'event.route',
        params: {
          slug,
          eventSlug: this.registry.slug,
          // slug: item.doorway_item_room.slug,
        },
      })
    },
  },
}
</script>

<style></style>
