<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="1280px"
    content-class="tw-rounded-3xl tw-w-full"
  >
    <v-form
      ref="form"
      v-model="valid"
      :disabled="form_disabled_state"
      lazy-validation
      enctype="multipart/form-data"
      @submit.prevent="update"
    >
      <v-card class="tw-relative tw-rounded-3xl tw-border-0 tw-shadow-md">
        <ModalHeader @close-modal="closeModal">{{
          $t('app.settings')
        }}</ModalHeader>
        <v-divider />
        <v-card-text class="tw-p-0" style="max-height: 700px">
          <v-responsive class="tw-py-0">
            <v-container class="tw-bg-gray-100" fluid>
              <v-row class="mb-5">
                <!----------------------------------------  Settings Event -------------------------------------- -->
                <v-col cols="12" lg="6" class="mx-auto pa-5">
                  <v-card class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-shadow-md">
                    <v-container class="pa-5">
                      <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-4">
                        <!-- ------------------- Name Event  ------------------- -->
                        <v-text-field
                          v-model="label"
                          :rules="[rules.required]"
                          validate-on-blur
                          outlined
                          rounded
                          :label="$t('admin.app.name')"
                          hide-details="auto"
                          class="tw-col-span-2"
                        />
                        <!-- ------------------- Slug  ------------------- -->
                        <v-text-field
                          v-model="slug"
                          validate-on-blur
                          :rules="[rules.slug]"
                          :disabled="isDefault"
                          :label="$t('app.slug')"
                          outlined
                          rounded
                          hide-details="auto"
                          class="tw-col-span-2 lg:tw-col-span-1"
                        />
                        <!-- ------------------- Language  ------------------- -->
                        <v-select
                          v-model="language_key"
                          :items="languages"
                          :label="$t('app.default_language')"
                          validate-on-blur
                          :rules="[rules.required]"
                          outlined
                          rounded
                          hide-details="auto"
                          class="tw-col-span-2 lg:tw-col-span-1"
                        />
                        <!-- ------------------- Description  ------------------- -->
                        <v-textarea
                          v-model="description"
                          :label="$t('admin.app.description')"
                          outlined
                          rounded
                          hide-details="auto"
                          class="tw-col-span-2"
                        />
                      </div>
                    </v-container>
                    <!-- ------------------- Event opening  ------------------- -->
                    <v-container class="pa-5">
                      <ToggleGroup v-model="has_time_bracket">
                        <template #title>
                          {{ $t('admin.events.settings_range_title') }}
                        </template>
                        <template #description>
                          {{ $t('admin.events.settings_range_description') }}
                        </template>
                        <template #extras>
                          <DurationModelFormPartial
                            v-model="duration_event"
                            :has-toggle="false"
                            :can-specify-interval="false"
                          />
                        </template>
                      </ToggleGroup>
                    </v-container>
                  </v-card>
                </v-col>
                <!----------------------------------------  Additional settings -------------------------------------- -->
                <v-col cols="12" lg="6" class="mx-auto pa-5">
                  <v-card class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-shadow-md">
                    <v-container class="pa-5">
                      <div>
                        <div>
                          <h3 class="tw-text-lg tw-leading-6 tw-text-gray-900 tw-font-bold">
                            {{ $t('admin.events.additional_config') }}
                          </h3>
                          <p class="tw-mt-0 tw-mb-0 tw-text-sm tw-text-gray-500 tw-font-semibold">
                            {{ $t('admin.events.additional_config_booleans') }}
                          </p>
                        </div>
                        <ul
                          class="tw-mt-2 tw-divide-y tw-divide-gray-200 tw-pl-0"
                        >
                          <!-- ------------------- Password Active Event  ------------------- -->
                          <li class="tw-py-2">
                            <ToggleGroup v-model="has_password">
                              <template #title>
                                {{ $t('admin.events.passwords') }}
                              </template>
                              <template #description>
                                {{ $t('admin.events.passwords_description') }}
                              </template>
                            </ToggleGroup>
                          </li>
                          <!-- ------------------- Exports Contacts ------------------- -->
                          <li class="tw-py-2">
                            <ToggleGroup v-model="can_export_connections">
                              <template #title>
                                {{ $t('admin.events.export_contacts') }}
                              </template>
                              <template #description>
                                {{ $t('admin.events.export_contacts_description') }}
                              </template>
                            </ToggleGroup>
                          </li>
                          <!-- ------------------- Send Certificate ------------------- -->
                          <li class="tw-py-2">
                            <ToggleGroup v-model="is_send_certificate">
                              <template #title>
                                {{ $t('admin.app.send_certificate') }}
                              </template>
                              <template #description>
                                {{ $t('admin.app.send_certificate_desc') }}
                              </template>
                            </ToggleGroup>
                          </li>
                          <!-- ------------------- Send Confirmation when importing excel participants  ------------------- -->
                          <li class="tw-py-2">
                            <ToggleGroup v-model="is_sending_email">
                              <template #title>
                                {{ $t('admin.ticket.items.labels.send_emnail_confirm') }}
                              </template>
                              <template #description>
                                {{ $t('admin.ticket.items.description.send_emnail_confirm') }}
                              </template>
                            </ToggleGroup>
                          </li>
                          <!-- ------------------- Disable Registration  ------------------- -->
                          <li class="tw-py-2">
                            <ToggleGroup v-model="is_registration_disabled">
                              <template #title>
                                {{ $t('admin.events.disable_registration') }}
                              </template>
                              <template #description>
                                {{ $t('admin.events.disable_registration_description') }}
                              </template>
                            </ToggleGroup>
                          </li>
                          <!-- ------------------- Only use default language  ------------------- -->
                          <li class="tw-py-2">
                            <ToggleGroup v-model="is_only_active_for_default_lang">
                              <template #title>
                                {{ $t('admin.events.only_default_lang') }}
                              </template>
                              <template #description>
                                {{ $t('admin.events.only_default_lang_description') }}
                              </template>
                            </ToggleGroup>
                          </li>
                          <!-- ------------------- Add favicon  ------------------- -->
                          <li class="tw-py-2">
                            <ToggleGroup v-model="has_custom_fav_icon">
                              <template #title>
                                {{ $t('admin.events.add_favicon') }}
                              </template>
                              <template #description>
                                {{ $t('admin.events.add_favicon_description') }}
                              </template>
                              <template #extras>
                                <DropzoneOverlayPreview
                                  v-model="custom_fav_icon_url"
                                  bypass="ratio-check"
                                  :width="1"
                                  :height="1"
                                  media-group="registry-favicon"
                                />
                              </template>
                            </ToggleGroup>
                          </li>
                          <!-- ------------------- Enable support  ------------------- -->
                          <li class="tw-py-2">
                            <ToggleGroup v-model="has_support">
                              <template #title>
                                {{ $t('admin.events.enable_support') }}
                              </template>
                              <template #description>
                                {{ $t('admin.events.enable_support_description') }}
                              </template>
                              <template #extras>
                                <UsersAutocomplete
                                  v-model="support_user_id"
                                  :multiple="false"
                                  filter-name="representative"
                                  :label="$t('admin.app.select_reps')"
                                />
                                <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-4">
                                  <v-text-field
                                    v-model="support_email"
                                    :rules="[rules.required, rules.email]"
                                    validate-on-blur
                                    outlined
                                    rounded
                                    label="Email"
                                    hide-details="auto"
                                  />
                                  <v-text-field
                                    v-model="support_phone_number"
                                    :rules="[rules.required, rules.phone]"
                                    validate-on-blur
                                    outlined
                                    rounded
                                    hide-details="auto"
                                    :label="$t('forms.phone')"
                                  />
                                </div>
                              </template>
                            </ToggleGroup>
                          </li>
                          <!-- ------------------- Payment Event  ------------------- -->
                          <li class="tw-py-2">
                            <ToggleGroup v-model="has_payment">
                              <template #title>
                                {{ $t('bz2.registry.setting.payment.title') }}
                              </template>
                              <template #description>
                                {{ $t('bz2.registry.setting.payment.description') }}
                              </template>
                              <template #extras>
                                <div class="tw-grid tw-grid-cols-1 tw-gap-4">
                                  <v-text-field
                                    v-model="publishable_key"
                                    type="text"
                                    outlined
                                    rounded
                                    :label="$t('bz2.registry.setting.payment.public_key')"
                                    class="tw-text-sm"
                                    dense
                                    hide-details="auto"
                                  />
                                  <v-text-field
                                    v-model="private_key"
                                    type="text"
                                    outlined
                                    rounded
                                    :label="$t('bz2.registry.setting.payment.private_key')"
                                    :append-icon="show_private_key ? 'visibility' : 'visibility_off'"
                                    :class="show_private_key ? '' : 'bz-pass-private-key'"
                                    hide-details="auto"
                                    class="tw-text-sm"
                                    dense
                                    @click:append="show_private_key = !show_private_key"
                                  />
                                  <div v-if="!is_multi_ticket" class="tw-block tw-items-center lg:tw-flex">
                                    <v-text-field
                                      v-model="price_event"
                                      outlined
                                      rounded
                                      :label="$t('bz2.registry.setting.payment.price_event')"
                                      prefix="$"
                                      class="tw-text-sm"
                                      dense
                                      hide-details="auto"
                                    />
                                    <EditorTipTapModal 
                                      v-model="price_event_description"
                                    />
                                  </div>
                                </div>
                              </template>
                            </ToggleGroup>
                          </li>
                          <!-- ------------------- Multiclass event  ------------------- -->
                          <li class="tw-py-2">
                            <ToggleGroup v-model="is_multi_ticket">
                              <template #title>
                                {{ $t('admin.ticket.items.labels.multi_ticket') }}
                              </template>
                              <template #description>
                                {{ $t('admin.ticket.items.description.multi_ticket') }}
                              </template>
                              <template #extras>
                                <div class="tw-flex tw-items-center tw-justify-between tw-flex-col lg:tw-flex-row">
                                  <span class="tw-font-semibold">{{ $t('admin.ticket.items.labels.config') }}</span>
                                  <AppBtn
                                    type="button"
                                    color="secondary"
                                    small
                                    fab
                                    @click="registries_ticket_modal = true"
                                  >
                                    <v-icon>local_activity</v-icon>
                                  </AppBtn>
                                </div>
                                <RegistriesMultiTicketModal v-model="registries_ticket_modal"/>
                              </template>
                            </ToggleGroup>
                          </li>
                          <!-- ------------------- Hybrid event  ------------------- -->
                          <li class="tw-py-2">
                            <ToggleGroup v-model="is_hybrid">
                              <template #title>
                                {{ $t('admin.events.hybrid.items.labels.hybrid') }}
                              </template>
                              <template #description>
                                {{ $t('admin.events.hybrid.items.description.hybrid') }}
                              </template>
                              <template #extras>
                                <DurationModelFormPartial
                                  v-model="duration_hybrid"
                                  :has-toggle="false"
                                  :can-specify-interval="false"
                                />
                              </template>
                            </ToggleGroup>
                          </li>
                          <!-- ------------------- QR function  ------------------- -->
                          <li class="tw-py-2">
                            <ToggleGroup v-model="is_entry_or_add">
                              <template #title>
                                {{ $t('bz2.registry.setting.qr.title')}}
                              </template>
                              <template #description>
                                {{ $t('bz2.registry.setting.qr.description')}}
                              </template>
                              <template #extras>
                                <div class="tw-flex tw-items-center tw-justify-between">
                                  <label class="tw-cursor-pointer">
                                    <p class="tw-mb-0 tw-text-sm tw-text-gray-500 tw-font-semibold">
                                      {{ $t('bz2.registry.setting.qr.actions.autologin')}}
                                    </p>
                                  </label>
                                  <div class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center">
                                    <v-switch v-model="is_autlogin_qr" inset large dense @change="adco()"/>
                                  </div>
                                </div>
                                <div class="tw-flex tw-items-center tw-justify-between">
                                  <label class="tw-cursor-pointer">
                                    <p class="tw-mb-0 tw-text-sm tw-text-gray-500 tw-font-semibold">
                                      {{ $t('bz2.registry.setting.qr.actions.add_contact')}}
                                    </p>
                                  </label>
                                  <div class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center">
                                    <v-switch v-model="is_add_contact_qr" inset large dense @change="aulo()"/>
                                  </div>
                                </div>
                              </template>
                            </ToggleGroup>
                          </li>
                          <!-- ------------------- Show List Entries Form  ------------------- -->
                          <li class="tw-py-2">
                            <ToggleGroup v-model="show_list_entries">
                              <template #title>
                                {{ $t('admin.events.show_list_entries') }}
                              </template>
                              <template #description>
                                {{ $t('admin.events.show_list_entries_description') }}
                              </template>
                            </ToggleGroup>
                          </li>
                          <!-- ------------------- Disable real-time ------------------- -->
                          <li class="tw-py-2">
                            <ToggleGroup v-model="disable_real_time">
                              <template #title>
                                {{ $t('admin.events.disable_real_time') }}
                              </template>
                              <template #description>
                                {{ $t('admin.events.disable_real_time_description') }}
                              </template>
                            </ToggleGroup>
                          </li>
                        </ul>
                      </div>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-responsive>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-alert
            v-model="errorAlert"
            dismissible
            color="red"
            border="left"
            elevation="2"
            colored-border
            icon="error"
            transition="fade-transition"
          >
            {{ errorMsg }}
          </v-alert>
          <v-spacer />
          <AppBtn
            type="button"
            :disabled="form_loading"
            color="transparent"
            @click="closeModal"
          >
            {{ $t('app.cancel') }}
          </AppBtn>
          <AppBtn
            type="submit"
            :loading="form_loading"
            color="primary"
            icon="check"
          >
            {{ $t('app.save') }}
          </AppBtn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import DropzoneOverlayPreview from '@/components/DropzoneOverlayPreview.vue'
import { rules } from '@/utils/rules'
import AppBtn from '@/components/App/AppBtn.vue'
import ModalHeader from '@/components/ModalHeader.vue'
import { mapGetters, mapState } from 'vuex'
import { mapFields } from '@/helpers'
// Hybrid
import ToggleGroup from '../FormInputs/ToggleGroup.vue'
import { DurationBase } from '@/models/Duration'
import DurationModelFormPartial from '../FormPartials/DurationModelFormPartial.vue'

import UsersAutocomplete from '@/components/UsersAutocomplete.vue'

import RegistriesMultiTicketModal from '@/components/RegistriesModals/RegistriesMultiTicketModal.vue'
import EditorTipTapModal from '@/components/EditorTipTapModal.vue'

export default {
  components: {
    AppBtn,
    ModalHeader,
    DropzoneOverlayPreview,
    ToggleGroup,
    DurationModelFormPartial,
    UsersAutocomplete,
    RegistriesMultiTicketModal,
    EditorTipTapModal,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    errorAlert: false,
    errorMsg: '',
    exam: '',
    rules,
    isLoading: false,
    form_loading: false,
    form_disabled_state: false,
    valid: true,
    mountedSettings: {},
    isLoadingRooms: false,
    configPhotocheck: false,
    credential_loading: false,
    show_private_key: false,
    registries_ticket_modal: false,
  }),
  computed: {
    ...mapFields({
      fields: [
        'has_support',
        'support_email',
        'support_phone_number',
        'has_time_bracket',
        'label',
        'language_key',
        'slug',
        'description',
        'start_date',
        'start_time',
        'end_date',
        'end_time',
        'time_bracket_tz',
        'is_registration_disabled',
        'has_password',
        'can_export_connections',
        'is_only_active_for_default_lang',
        'has_custom_fav_icon',
        'custom_fav_icon_url',
        // Hybrid
        'is_hybrid',
        'hybrid_start_time',
        'hybrid_start_date',
        'hybrid_end_time',
        'hybrid_end_date',
        'hybrid_tz',
        'hybrid_credential_image_url',
        // Chat Support
        'support_user_id',
        // Multi Ticket
        'is_multi_ticket',
        // Sending email with import excel
        'is_sending_email',
        'is_send_certificate',
        'is_entry_or_add',
        'is_add_contact_qr',
        'is_autlogin_qr',
        // Has payment (Stripe)
        'has_payment',
        'publishable_key',
        'private_key',
        'pass_private_key',
        'price_event',
        'price_event_description',
        'show_list_entries',
        'disable_real_time',
      ],
      base: ['registries', 'update', 'data'],
      mutation: 'registries/setUpdate',
    }),

    isVirtualRoomActive() {
      return (
        this.mountedSettings.doorway_item_room_type &&
        this.mountedSettings.doorway_item_type == 'virtual_room'
      )
    },
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    duration_hybrid: {
      get() {
        // datos del mapfield
        return DurationBase({
          has_time_bracket: true,
          time_bracket_tz: this.hybrid_tz,
          start_date: this.hybrid_start_date,
          start_time: this.hybrid_start_time,
          end_date: this.hybrid_end_date,
          end_time: this.hybrid_end_time,
        })
      },
      set({ start_date, start_time, end_date, end_time, time_bracket_tz }) {
        //todo: a datos del mapfields

        this.hybrid_start_time = start_time
        this.hybrid_start_date = start_date
        this.hybrid_end_time = end_time
        this.hybrid_end_date = end_date

        this.hybrid_tz = time_bracket_tz
      },
    },
    duration_event: {
      get() {
        // datos del mapfield
        return DurationBase({
          has_time_bracket: true,
          time_bracket_tz: this.time_bracket_tz,
          start_date: this.start_date,
          start_time: this.start_time,
          end_date: this.end_date,
          end_time: this.end_time,
        })
      },
      set({ start_date, start_time, end_date, end_time, time_bracket_tz }) {
        //todo: a datos del mapfields

        this.start_time = start_time
        this.start_date = start_date
        this.end_time = end_time
        this.end_date = end_date

        this.time_bracket_tz = time_bracket_tz
      },
    },
    doorway_room_types() {
      return this.room_types.filter((row) => {
        return !(
          this.mountedSettings.doorway_item_type === 'map' &&
          row.value === 'lobby'
        )
      })
    },
    ...mapGetters({
      room_types: ['constants/room_types'],
      languages: ['constants/languages'],

      doorway_types: ['constants/doorway_types'],
    }),
    ...mapState({
      registry: (state) => state.registries.show.data,
    }),
    isDefault() {
      return this.mountedSettings.slug == 'home'
    },
  },
  mounted() {
    console.log('settings ', this.settings)
    this.mountedSettings = this.settings
  },

  methods: {
    aulo() {
      if(this.is_autlogin_qr) {
        this.is_autlogin_qr = false
      }
    },
    adco() {
      if(this.is_add_contact_qr) {
        this.is_add_contact_qr = false
      }
    },
    closeModal() {
      this.$store.dispatch('registries/initUpdateForm')
      this.open = false
    },
    update() {
      this.errorAlert = false
      if (!this.$refs.form.validate()) {
        return
      }
      this.form_loading = true
      this.form_disabled_state = true
      this.$store
        .dispatch('registries/update')
        .then((res) => {
          const { data } = res.data
          return this.$store.dispatch('registries/show', {
            id: data.id,
          })
        })
        .then((res) => {
          console.log('after show', { res })
          this.open = false
        })

        .catch((err) => {
          this.errorAlert = true
          this.errorMsg = err
        })
        .finally(() => {
          this.form_disabled_state = false
          this.form_loading = false
        })
    },
  },
}
</script>

<style>
.bz-pass-private-key input{
  filter: blur(2.3px)
}
</style>
