var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","width":_vm.form_state ? 1024 : 420,"persistent":"","content-class":"tw-self-end tw-rounded-3xl sm:tw-self-auto"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"tw-shadow-md"},[(!_vm.form_state)?_c('v-container',{staticClass:"tw-p-5",attrs:{"fluid":""}},[_c('h1',{staticClass:"text-center tw-text-2xl"},[_vm._v(" "+_vm._s(_vm.$t('pages.registry.index.header'))+" ")]),_c('div',{staticClass:"tw-mt-3 tw-flex tw-w-full tw-justify-center"},[_c('div',[_c('LocaleSwitcher')],1)])]):_vm._e(),(_vm.form_state)?_c('v-container',{staticClass:"tw-p-",attrs:{"fluid":""}},[_c('div',{staticClass:"tw-flex tw-flex-col tw-items-center tw-justify-center"},[_c('h1',{staticClass:"text-center tw-text-2xl"},[_vm._v(" "+_vm._s(_vm.currentRegistry.label)+" ")]),_c('div',{staticClass:"tw-mt-3"},[_c('span',{staticClass:"tw-inline-flex tw-items-center tw-rounded-full tw-bg-gray-100 tw-px-2.5 tw-py-0.5 tw-text-sm tw-font-medium tw-text-gray-800"},[_vm._v(" "+_vm._s(_vm.current_language_key)+" ")])])])]):_vm._e(),_c('v-divider'),_c('v-card-text',{staticClass:"pt-2"},[_c('v-responsive',{staticClass:"tw-py-0"},[(!_vm.form_state)?_c('v-container',{staticClass:"tw-p-5",attrs:{"fluid":""}},[_c('div',{staticClass:"tw-mt-12 tw-grid tw-grid-cols-1 tw-gap-0.5 lg:tw-mt-0"},_vm._l((_vm.registriesRows),function(ref){
var uuid = ref.uuid;
var langs = ref.langs;
var label = ref.label;
var langs_forms = ref.langs_forms;
var slug = ref.slug;
return _c('v-btn',{key:uuid,staticClass:"tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-py-6 tw-px-8 focus:tw-outline-none",attrs:{"disabled":_vm.is_loading,"loading":_vm.is_loading && _vm.selected_uuid === uuid,"text":""},on:{"click":function($event){return _vm.selectedEvent(uuid, langs_forms[_vm.current_language_key].id, slug)}}},[_c('div',{staticClass:"tw-flex tw-flex-col tw-justify-center"},[_c('img',{staticClass:"tw-max-h-12",attrs:{"src":langs[_vm.current_language_key].logo_url_sm
                      ? langs[_vm.current_language_key].logo_url_sm
                      : require('@/assets/icon-bizzdome.svg'),"alt":"Bizz Dome Event"}}),_c('h2',{staticClass:"tw-mt-4 tw-text-base"},[_vm._v(_vm._s(label))])])])}),1)]):_c('v-container',{staticClass:"tw-p-5"},[_c('PreEventRegister',{attrs:{"current-form":_vm.currentForm,"registry":_vm.currentRegistry},on:{"on-form-submit":_vm.processRegister}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-container',{staticClass:"tw-px-0 tw-py-2"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-justify-between sm:tw-flex-row sm:tw-space-x-3"},[_c('div',[(_vm.can_remember)?_c('v-checkbox',{staticClass:"tw-mt-0",attrs:{"label":_vm.$t('app.remember'),"hide-details":""},model:{value:(_vm.has_remembered),callback:function ($$v) {_vm.has_remembered=$$v},expression:"has_remembered"}}):_vm._e()],1),_c('div',{staticClass:"tw-flex tw-flex-col tw-justify-end sm:tw-flex-row sm:tw-space-x-3"},[(_vm.form_state == true)?_c('AppBtn',{attrs:{"color":"transparent","icon":"g-next","g-next":"ArrowNarrowLeft","disabled":_vm.is_loading},on:{"click":function($event){$event.stopPropagation();_vm.form_state = false}}},[_vm._v(" "+_vm._s(_vm.$t('app.back'))+" ")]):_vm._e(),_c('AppBtn',{attrs:{"color":"transparent","disabled":_vm.is_loading},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('app.cancel'))+" ")])],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }