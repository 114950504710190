export const UserListKeys = {
  active: 'active',
  location: 'location',
  reps: 'reps',
  hybrid: 'hybrid',
}

export const UserListLabels = [
  // { text: 'Solo activos' },
  // { text: 'En esta ubicación' },
  // { text: 'Representantes' },
  { text: 'active' },
  { text: 'location' },
  { text: 'reps' },
  { text: 'hybrid' },
]
