<template>
  <div class="fill-height">
    <div
      class="fill-height"
      :class="[block ? 'tw-w-full' : 'lg:tw-w-[340px] lg:tw-max-w-md']"
    >
      <!-- tw-overflow-y-scroll lg:tw-w-screen lg:tw-max-w-md-->
      <div class="tw-flex tw-h-full tw-flex-col tw-bg-white tw-shadow-xl">
        <!-- <div class="tw-p-6">
          <div class="tw-flex tw-items-start tw-justify-between">
          </div>
        </div> -->
        <div
          class="tw-flex tw-items-end tw-justify-between tw-border-b tw-border-gray-200"
        >
          <div class="tw-px-6">
            <nav class="tw--mb-px tw-flex tw-space-x-6">
              <!-- Current: "tw-border-indigo-500 tw-text-indigo-600", Default: "tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300" -->
              <button
                v-if="canChat"
                v-ripple
                type="button"
                :class="[
                  tabs.channel_view === currentTab
                    ? 'tw-border-indigo-500 tw-text-indigo-600'
                    : 'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700',
                ]"
                class="tw-whitespace-nowrap tw-border-b-2 tw-border-solid tw-px-1 tw-py-4 tw-text-sm tw-font-medium tw-transition"
                @click="toggleTab(tabs.channel_view)"
              >
                Chat
              </button>

              <button
                v-if="canThread"
                v-ripple
                type="button"
                :class="[
                  tabs.thread_view === currentTab
                    ? 'tw-border-indigo-500 tw-text-indigo-600'
                    : 'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-300 hover:tw-text-gray-700',
                ]"
                class="tw-whitespace-nowrap tw-border-b-2 tw-border-solid tw-px-1 tw-py-4 tw-text-sm tw-font-medium tw-transition"
                @click="toggleTab(tabs.thread_view)"
              >
                Q&A
              </button>
            </nav>
          </div>
          <div class="tw-ml-3 tw-flex tw-items-center tw-p-4">
            <v-btn
              v-if="canClose"
              fab
              x-small
              class="focus:tw-outline-none focus:tw-ring"
              @click="closeModal"
            >
              <v-icon>close</v-icon>
            </v-btn>
            <v-btn
              v-if="canClosePopup"
              fab
              x-small
              class="focus:tw-outline-none focus:tw-ring"
              @click="closeModalPopUp"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </div>
        <transition name="fade-transition" mode="out-in">
          <EventRoomContentElementChatChannel
            v-if="canChatShow"
            key="channels"
            :parrent-channel-id="parrentChannelId"
          />
          <EventRoomContentElementChatThread
            v-if="canThreadShow"
            key="threads"
            :parrent-thread-id="parrentThreadId"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EventRoomContentElementChatChannel from './EventRoomContentElementChatChannel.vue'
import EventRoomContentElementChatThread from './EventRoomContentElementChatThread.vue'
const tabs = {
  channel_view: 'channel_view',
  thread_view: 'thread_view',
}
export default {
  components: {
    EventRoomContentElementChatThread,
    EventRoomContentElementChatChannel,
  },
  props: {
    canThread: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    canClose: {
      type: Boolean,
      default: false,
    },
    canClosePopup: {
      type: Boolean,
      default: false,
    },
    canChat: {
      type: Boolean,
      default: false,
    },
    parrentChannelId: {
      type: Number,
      default: 0,
    },
    parrentThreadId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    tabs,
    currentTab: '',
    chanel_id: 2,
    thread_id: 0,
  }),
  computed: {
    canChatShow() {
      return this.currentTab == tabs.channel_view && this.canChat
    },
    canThreadShow() {
      return this.currentTab == tabs.thread_view && this.canThread
    },
    ...mapState({
      user: (state) => state.session.user,
    }),
  },
  created() {
    this.initTabs()
  },
  methods: {
    initTabs() {
      if (this.canChat) {
        this.currentTab = tabs.channel_view
        return
      }
      if (this.canThread) {
        this.currentTab = tabs.thread_view
        return
      }
    },
    toggleTab(tab) {
      this.currentTab = tab
    },
    closeModal() {
      this.$store.commit('event/setContentVideo', {
        contentVideoModal: false,
      })
    },
    closeModalPopUp() {
      this.$store.commit('event/setActiveInmediatePopUp', {
        contentVideoModal: false,
      })
    },
  },
}
</script>

<style></style>
