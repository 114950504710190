<template>
  <div class="tw-flex tw-h-full tw-flex-col">
    <!-- hover:tw-bg-gray-50 focus-within:tw-ring-2 focus-within:tw-ring-inset focus-within:tw-ring-indigo-500 -->

    <div class="tw-w-full tw-rounded-t-lg tw-bg-primary-500">
      <div class="tw-flex tw-items-center tw-space-x-2 tw-px-4 tw-py-5">
        <div class="">
          <v-btn
            fab
            x-small
            class="
              text--white
              tw-normal-case tw-shadow-sm
              focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-300
            "
            @click="view = 'HOME'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="tw-h-4 tw-w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </v-btn>
        </div>
        <!-- tw-px-4  -->
        <div class="tw-relative tw-flex tw-flex-1 tw-items-center tw-space-x-3">
          <div class="tw-flex-shrink-0">
            <img
              class="tw-h-10 tw-w-10 tw-rounded-full"
              :src="talking_to.avatar"
              alt=""
            />
          </div>
          <div class="tw-min-w-0 tw-flex-1">
            <a href="#" class="focus:tw-outline-none">
              <span
                class="tw-absolute tw-inset-0"
                aria-hidden="true"
                @click="$refs.menuProfileChat.click()"
              />
              <p class="tw-mb-0 tw-text-sm tw-font-medium tw-text-gray-50">
                {{ talking_to.first_name }} {{ talking_to.last_name }}
              </p>
              <!-- <p class="tw-mb-0 tw-text-sm tw-text-gray-200 tw-truncate">
                {{ talking_to.email }}
              </p> -->
            </a>
          </div>
          <div>
            <v-menu
              offset-y
              transition="scale-transition"
              content-class="tw-py-1 tw-mt-2 tw-bg-white tw-rounded-md tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5"
            >
              <template #activator="{ on, attrs }">
                <button
                  id="project-options-menu-0-button"
                  ref="menuProfileChat"
                  type="button"
                  class="
                    tw-inline-flex
                    tw-h-8
                    tw-w-8
                    tw-items-center
                    tw-justify-center
                    tw-rounded-full
                    tw-bg-white
                    tw-text-gray-400
                    hover:tw-text-gray-500
                    focus:tw-outline-none
                    focus:tw-ring-2
                    focus:tw-ring-primary-500
                    focus:tw-ring-offset-2
                  "
                  aria-expanded="false"
                  aria-haspopup="true"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span class="tw-sr-only">Open options</span>
                  <svg
                    class="tw-h-5 tw-w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
                    />
                  </svg>
                </button>
              </template>
              <v-list>
                <v-list-item @click="viewAttendee">
                  <v-list-item-title>{{
                    $t('pages.event.actions.view_card')
                  }}</v-list-item-title>
                </v-list-item>
                <!-- <v-list-item @click="callUser">
                  <v-list-item-title>Iniciar Videollamada</v-list-item-title>
                </v-list-item> -->
                <v-list-item
                  v-if="talking_to.is_online && has_video_chat"
                  @click="startVideoCall"
                >
                  <v-list-item-title>{{
                    $t('pages.event.actions.start_vchat')
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="false">
                  <v-list-item-title>Silenciar</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="false">
                  <v-list-item-title>Bloquear</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
    <!-- tw-overflow-x-hidden -->
    <transition name="fade-transition" mode="out-in">
      <div
        v-if="!loading"
        id="messages"
        ref="scrollToMe"
        class="
          tw-scrolling-touch
          scrollbar-thumb-blue
          scrollbar-thumb-rounded
          scrollbar-track-blue-lighter
          scrollbar-w-2
          tw-flex tw-flex-1 tw-flex-col tw-overflow-y-scroll
        "
      >
        <div
          v-for="(group, index) in sortedMessages"
          :key="index"
          class="relative"
        >
          <div
            class="
              tw-sticky
              tw-top-0
              tw-z-10
              tw-border-t
              tw-border-b
              tw-border-gray-200
              tw-bg-gray-50
              tw-px-4
              tw-py-1
              tw-text-sm
              tw-font-medium
              tw-text-gray-500
            "
          >
            <h3>{{ index }}</h3>
          </div>
          <div class="tw-space-y-4 tw-p-4">
            <!-- <transition-group name="fade-transition" class="tw-space-y-4 tw-p-4"> -->
            <ChatConversationMessage
              v-for="message in group"
              :key="message.id"
              :message="message"
              :user="user"
            />
            <!-- </transition-group> -->
          </div>
        </div>
      </div>
      <div
        v-else
        class="
          tw-scrolling-touch
          scrollbar-thumb-blue
          scrollbar-thumb-rounded
          scrollbar-track-blue-lighter
          scrollbar-w-2
          tw-flex tw-flex-1 tw-flex-col tw-overflow-y-scroll
        "
      >
        <v-progress-linear indeterminate color="primary darken-2" />
      </div>
    </transition>
    <div
      class="
        tw-border-t-2 tw-border-gray-200 tw-px-4 tw-pt-2 tw-pb-2
        sm:tw-mb-0
      "
    >
      <form @submit.prevent="submit">
        <div class="tw-relative tw-flex tw-space-x-2">
          <!-- <span class="tw-absolute tw-inset-y-0 tw-flex tw-items-center">
            <button
              type="button"
              class="tw-inline-flex tw-items-center tw-justify-center tw-w-12 tw-h-12 tw-text-gray-500 tw-transition tw-duration-500 tw-ease-in-out tw-rounded-full hover:tw-bg-gray-300 focus:tw-outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="tw-w-6 tw-h-6 tw-text-gray-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                />
              </svg>
            </button>
          </span> -->
          <!--  tw-pl-12 -->
          <!-- <textarea
            v-model="body"
            rows="1"
            type="text"
            placeholder="Escribe un mensaje"
            class="
              tw-w-full
              tw-py-3
              tw-pl-5
              tw-text-gray-600
              tw-flex-1
              tw-placeholder-gray-600
              tw-bg-gray-200
              tw-rounded-full
              tw-overflow-hidden
              tw-resize-none
              focus:tw-outline-none focus:tw-placeholder-gray-400
            "
            @keydown.enter.exact.prevent
            @keyup.enter.exact="submit"
          /> -->
          <v-textarea
            v-model="body"
            :disabled="loading"
            auto-grow
            dense
            outlined
            hide-details=""
            rounded
            class="tw-max-h-64 tw-overflow-y-auto"
            :placeholder="$t('app.msg_placeholder')"
            rows="1"
            @keydown.enter.exact.prevent
            @keyup.enter.exact="submit"
          />
          <!-- </form> -->
          <div class="tw-items-end sm:tw-flex">
            <!-- <div
            class="
              tw-absolute tw-inset-y-0 tw-right-0 tw-items-center tw-hidden
              sm:tw-flex
            "
          > -->
            <!-- <button
              type="button"
              class="
                tw-inline-flex
                tw-items-center
                tw-justify-center
                tw-w-10
                tw-h-10
                tw-text-gray-500
                tw-transition
                tw-duration-500
                tw-ease-in-out
                tw-rounded-full
                hover:tw-bg-gray-300
                focus:tw-outline-none
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="tw-w-6 tw-h-6 tw-text-gray-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                />
              </svg>
            </button>
            <button
              type="button"
              class="
                tw-inline-flex
                tw-items-center
                tw-justify-center
                tw-w-10
                tw-h-10
                tw-text-gray-500
                tw-transition
                tw-duration-500
                tw-ease-in-out
                tw-rounded-full
                hover:tw-bg-gray-300
                focus:tw-outline-none
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="tw-w-6 tw-h-6 tw-text-gray-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </button>
            <button
              type="button"
              class="
                tw-inline-flex
                tw-items-center
                tw-justify-center
                tw-w-10
                tw-h-10
                tw-text-gray-500
                tw-transition
                tw-duration-500
                tw-ease-in-out
                tw-rounded-full
                hover:tw-bg-gray-300
                focus:tw-outline-none
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="tw-w-6 tw-h-6 tw-text-gray-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button> -->
            <button
              v-ripple
              type="submit"
              class="
                tw-inline-flex
                tw-h-10
                tw-w-10
                tw-items-center
                tw-justify-center
                tw-rounded-full
                tw-bg-blue-500
                tw-text-white
                tw-transition
                tw-duration-500
                tw-ease-in-out
                hover:tw-bg-blue-400
                focus:tw-outline-none focus:tw-ring focus:tw-ring-white
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="tw-h-5 tw-w-5 tw-rotate-90 tw-transform"
              >
                <path
                  d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"
                />
              </svg>
            </button>
          </div>
        </div>
      </form>
    </div>

    <!-- <VideoChatWrapper
      v-if="videoChatModal"
      v-model="videoChatModal"
      :room="room_id"
      :to="talking_to.email"
      :video-answer="videoAnswer"
      @closeVideo="video(false)"
    /> -->
    <!-- <ChatConversationVideoIframe
      v-if="videoIframeModal"
      v-model="videoIframeModal"
      :uuids="uuids"
    /> -->
  </div>
</template>

<script>
// import VideoChatWrapper from '@/components/Event/VideoChatWrapper'
// import ChatConversationVideoIframe from './ChatConversationVideoIframe.vue'
import { groupBy, v4 } from '@/helpers'
import { mapState } from 'vuex'
import { CHECK_CHANNEL, firstMsgOnChannel } from '@/graphql/queries/userQueries'
import {
  CHANNEL_MESSAGES,
  // NEW_CHANNEL_MESSAGE,
  storeMessage,
  // NEW_CHANNEL_MESSAGE,
  SUB_CHANNEL_MESSAGES,
} from '@/graphql/queries/messageQueries'
import ChatConversationMessage from './ChatConversationMessage'
// import { DESCRIPTION_TYPE } from '@/utils/config'
import { log } from '@/utils/logging'
import { format } from 'date-fns'
import { VideoChatStateKeys } from '@/enums/VideoChatStateKeys'
import { signalUser } from '@/graphql/mutations/userMutations'
export default {
  apollo: {
    $subscribe: {
      newMessages: {
        // manual:
        query: SUB_CHANNEL_MESSAGES,
        variables() {
          return {
            chanel_id: this.chanel_id,
          }
        },
        result({ data }) {
          // const { body, user } = data.newChannelMessage
          // this.$store.dispatch('notifications/addNotification', {
          //   title: body,
          //   body: `${user.first_name} ${user.last_name}`,
          // })
          const uuids = this.messages.map((row) => row.uuid)
          if (uuids.includes(data.newChannelMessage.uuid)) {
            this.messages = [...this.messages].map((row) => {
              if (row.uuid === data.newChannelMessage.uuid) {
                return data.newChannelMessage
              }
              return row
            })
          } else {
            this.messages = [...this.messages, data.newChannelMessage]
          }
          this.$nextTick(() => {
            this.scrollToElement()
          })
        },
      },
    },
  },
  components: {
    // VideoChatWrapper,
    // ChatConversationVideoIframe,
    ChatConversationMessage,
  },
  // sockets: {
  //   privateMessagePCSignaling: function ({ desc, from, to, candidate }) {
  //     log('SOCKET SIGNAL')
  //     if (from === this.user.email) return
  //     if (to !== this.user.email) return
  //     if (desc) {
  //       if (desc.type === DESCRIPTION_TYPE.offer) {
  //         // If we have an income call
  //         log('hits offer')
  //         this.openChat(desc, from)
  //       } else if (desc.type === DESCRIPTION_TYPE.answer) {
  //         // If we have a response
  //         this.videoAnswer = { ...this.videoAnswer, remoteDesc: desc }
  //       } else {
  //         log('Unsupported SDP type')
  //       }
  //       // Candidate
  //     } else if (candidate) {
  //       this.videoAnswer = { ...this.videoAnswer, candidate }
  //       // Other peer has closed the video
  //     } else {
  //       this.videoCall = false
  //       this.$store.commit('chat/setState', {
  //         videoChatModal: false,
  //       })
  //     }
  //   },
  // },
  data: () => ({
    loading: true,
    chanel_id: 0,
    body: '',
    messages: [],
    videoIframeModal: false,
    videoCall: false,
    videoAnswer: {
      video: undefined,
      remoteDesc: undefined,
      candidate: undefined,
      close: false,
    },
  }),
  computed: {
    uuids() {
      // this.user.uuid + this.talking_to.uuid
      // return [ 1:this.user.uuid,2: ];
      return this.user.id > this.talking_to.id
        ? this.user.uuid + '-link-' + this.talking_to.uuid
        : this.talking_to.uuid + '-link-' + this.user.uuid
    },
    room_id() {
      const sum = this.user.id + this.talking_to.id
      return '_' + sum
    },
    videoChatModal: {
      get() {
        return this.$store.state.chat.videoChatModal
      },
      set(value) {
        return this.$store.commit('chat/setState', {
          videoChatModal: value,
        })
      },
    },
    active_channel: {
      get() {
        return this.$store.state.chat.active_channel
      },
      set(value) {
        return this.$store.commit('chat/setState', {
          active_channel: value,
        })
      },
    },
    showChat: {
      get() {
        return this.$store.state.chat.showChat
      },
      set(value) {
        return this.$store.commit('chat/setState', {
          showChat: value,
        })
      },
    },
    sortedMessages() {
      // [{user: ca,messages: [msg]}]
      console.time('Function sortedMessages#1')
      const new_group_by_user = groupBy(
        this.messages.map((row_message) => ({
          ...row_message,
          formated_date: format(
            new Date(row_message.created_at),
            'hh:mm a',
            // 'hh:ii a yyyy-MM-dd',
          ),
          formated_date_stamp: format(
            new Date(row_message.created_at),
            'yyyy-MM-dd',
          ),
        })),
        'formated_date_stamp',
      )
      Object.keys(new_group_by_user).forEach((key) => {
        new_group_by_user[key] = new_group_by_user[key].reduce(
          (previousValue, currentValue, currentIndex) => {
            // console.log('previous ', previousValue)
            log('previous ', currentIndex)
            // console.log('currentValue ', currentValue)
            // console.log('new ', currentValue.user.id)

            const currentMessage = {
              body: currentValue.body,
              id: currentValue.id,
              created_at: currentValue.created_at,
              formated_date: currentValue.formated_date,
              uuid: currentValue.uuid,
            }
            const first = {
              ...currentValue,
              messages: [currentMessage],
            }
            // innitial conditions
            if (previousValue.length === 0) {
              return [...previousValue, first]
            }
            const last_message_in_array =
              previousValue[previousValue.length - 1]
            // check if last message
            if (last_message_in_array.user.id !== currentValue.user.id) {
              return [...previousValue, first]
            }

            previousValue[previousValue.length - 1] = {
              ...last_message_in_array,
              messages: [...last_message_in_array.messages, currentMessage],
            }
            return [...previousValue]
          },
          [],
        )
      })

      console.log(new_group_by_user)

      console.timeEnd('Function sortedMessages#1')

      // return group_by_user
      return new_group_by_user
    },
    ...mapState({
      user: (state) => state.session.user,
      talking_to: (state) => state.chat.talking_to,
      has_video_chat: (state) => state.event.constants.license.has_video_chat,
      registry: (state) => state.event.constants.registry,
    }),
    view: {
      get() {
        return this.$store.state.chat.view
      },
      set(val) {
        this.$store.commit('chat/setState', { view: val })
      },
    },
  },
  watch: {
    talking_to: {
      handler() {
        this.init()
      },
      deep: true,
    },
    showChat: {
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.scrollToElement()
          })
        }
      },
    },
  },
  async mounted() {
    this.init()
  },
  beforeDestroy() {
    this.active_channel = 0
  },
  methods: {
    async init() {
      this.loading = true
      console.log('mounted chat convo', this.talking_to)
      this.talking_to.id
      const { data } = await this.$apollo.mutate({
        // Query
        mutation: CHECK_CHANNEL,
        variables: {
          partner_id: this.talking_to.id,
        },
      })
      log(data.checkChannel.id)
      this.chanel_id = data.checkChannel.id
      this.active_channel = this.chanel_id
      const { data: data_msgs } = await this.$apollo.query({
        query: CHANNEL_MESSAGES,
        fetchPolicy: 'network-only',
        variables: {
          chanel_id: data.checkChannel.id,
        },
      })
      this.messages = data_msgs.channelMessagesPlusOne.sort(function (a, b) {
        return parseInt(a.id) - parseInt(b.id)
      })
      this.loading = false
      this.$nextTick().then(() => {
        this.scrollToElement()
      })
    },
    startVideoCall() {
      console.log(this.uuids)
      this.$store
        .dispatch('chat/callUser', {
          body: {
            uuids: this.uuids,
            user_uuid: this.user.uuid,
            target_user: this.talking_to,
            target_user_uuid: this.talking_to.uuid,
          },
        })
        .then(() => {
          this.$apollo
            .mutate({
              mutation: signalUser,
              variables: {
                signal_user: {
                  status: VideoChatStateKeys.waiting,
                  user_uuid: this.user.uuid,
                  target_user_uuid: this.talking_to.uuid,
                },
              },
            })
            .then(() => {
              console.log('MOUNTED EventVirtualRoom: triggered ')
            })
        })
    },
    viewAttendee() {
      console.log('talking_to')
      console.log(this.talking_to)
      this.$store.commit('event/setCard', {
        attendee: this.talking_to,
        modal: true,
      })
    },
    video(value) {
      this.videoCall = value
      if (value) {
        this.videoAnswer = { ...this.videoAnswer, video: !value }
      }
    },
    openChat(description, from) {
      this.videoAnswer = {
        ...this.videoAnser,
        video: true,
        remoteDesc: description,
        from,
      }
      this.videoCall = true
      this.$store.commit('chat/setState', {
        videoChatModal: true,
      })
    },
    callUser() {
      this.$store.commit('chat/setState', {
        videoChatModal: true,
      })

      this.videoCall = true
      this.videoAnswer = { ...this.videoAnswer, video: false }
    },
    submit() {
      if (this.body === '') {
        return
      }
      const wasFirstMessage = this.messages.length
      // uuid get established on the frontend -> adds little clock icon -> once emited overide message and take away icon
      const uuid = v4()
      this.messages = [
        ...this.messages,
        {
          body: this.body,
          created_at: new Date(),
          uuid,
          user: {
            id: this.user.id,
            avatar: this.user.avatar,
            first_name: this.user.first_name,
            last_name: this.user.last_name,
          },
        },
      ]

      this.$nextTick().then(() => {
        this.scrollToElement()
      })
      this.$apollo
        .mutate({
          mutation: storeMessage,
          variables: {
            content: {
              message: this.body,
              chanel_id: this.chanel_id,
              uuid,
            },
          },
        })
        .then(() => {
          console.log('here')
          if (wasFirstMessage === 0) {
            console.log('was first')
            this.$apollo.mutate({
              // Query
              mutation: firstMsgOnChannel,
              variables: {
                partner_id: this.talking_to.id,
              },
            })
          }
        })
      // Donovann
      this.$store.dispatch('event/newNotificationMessage', {
        event_user_id: this.talking_to.id,
        message: this.body,
        registry_id: this.registry.id,
      })
      this.body = ''
    },
    scrollToElement() {
      const el = this.$refs.scrollToMe
      el.scrollTop = el.scrollHeight
    },
  },
}
</script>

<style></style>
