import { NewPasswordServiceInstance } from '@/services/NewPasswordService'
const actions = {
    forgotPassword(_, params) {
        // console.table(params)
        return NewPasswordServiceInstance.forgotPassword(params)
            .then((res) => {
                return res
            })
            .catch(error => {
                return error
            })
    },
    
    resetPassword(_, params) {
        return NewPasswordServiceInstance.resetPassword(params)
            .then((res) => {
                return res
            })
            .catch(error => {
                return error
            })
    },
}

export const Password = {
    namespaced: true,
    actions,
}