<template>
  <v-navigation-drawer
    v-model="drawer"
    floating
    right
    temporary
    app
    color="white"
  >
    <v-toolbar class="tw-bg-event-header-color">
      <v-img
        :src="event_config.header_url"
        height="48"
        contain
        class="d-flex mb-1"
      >
        <template #placeholder>
          <v-row
            class="fill-height ma-0 transparent"
            align="center"
            justify="center"
          >
            <v-progress-circular size="32" indeterminate color="white" />
          </v-row>
        </template>
      </v-img>
    </v-toolbar>

    <v-divider />
    <v-list>
      <v-list-item two-line @click="openProfile">
        <v-list-item-avatar
          class="tw-border-3 tw-border-solid tw-border-primary-200"
        >
          <v-img :src="user.avatar" position="top" class="d-flex">
            <template #placeholder>
              <v-row
                class="fill-height ma-0 transparent"
                align="center"
                justify="center"
              >
                <v-progress-circular size="32" indeterminate color="white" />
              </v-row>
            </template>
          </v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ user.first_name }} {{ user.last_name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-list nav class="py-2">
      <v-list-item @click="fullScreenMode">
        <v-list-item-icon>
          <v-icon>fullscreen</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Full Screen</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="canOpenModModal" @click="openMod">
        <v-list-item-icon>
          <v-icon>dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('pages.event.container.upper_nav.user_dropdown.moderate') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isAdmin" :to="{ name: 'admin.dashboard.rewrite' }">
        <v-list-item-icon>
          <v-icon>dashboard</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Admin Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-list nav class="py-4">
      <RenderlessClickableHandle
        v-for="nav_item in navItems"
        :key="nav_item.uuid"
      >
        <template slot-scope="{ slotClickActionEvent }">
          <v-list-item link @click="slotClickActionEvent(nav_item)">
            <v-list-item-icon>
              <!-- <v-icon>{{ item.icon }}</v-icon> -->
              <!--  :class="{
              'tw-w-6 tw-h-6': nav_item.size == 'lg',
              'tw-w-6 tw-h-6': nav_item.size == 'md',
              'tw-w-6 tw-h-6': nav_item.size == 'sm',
            }" -->
              <v-img class="tw-h-6 tw-w-6" :src="nav_item.image_url" />
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ nav_item.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </RenderlessClickableHandle>
    </v-list>
    <template #append>
      <div class="pa-2 tw-space-y-4">
        <v-btn
          block
          text
          depressed
          color="grey lighten-4"
          x-small
          disabled
          @click.stop="() => null"
        >
          <v-icon left>verified_user</v-icon>
          Version: {{ version_build }}
        </v-btn>
        <AppBtn color="white" block @click="logout">
          <v-icon left>power_settings_new</v-icon>
          {{ $t('app.logout') }}
        </AppBtn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { TabKeys } from '@/enums/TabKeys'
// import { ClickableTypeKeys } from '@/enums/ClickableTypeKeys'
import { mapGetters, mapState } from 'vuex'
import RenderlessClickableHandle from './RenderlessClickableHandle.vue'
import AppBtn from '../App/AppBtn.vue'
// import { openWindowTab } from '@/utils/openWindowTab'
// import { TabKeys } from '@/enums/TabKeys'
// import { TargetLinkKeys } from '@/enums/TargetLinkKeys'
// import { download } from '@/utils/download'
// import { ContentTypeKeys } from '@/enums/ContentTypeKeys'
export default {
  components: { RenderlessClickableHandle, AppBtn },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isAdmin() {
      return this.user.roles.map((row) => row.name).includes('admin')
    },
    ...mapState({
      user: (state) => state.session.user,
      showLoading: (state) => state.event.show.isLoading,
      constantsLoaded: (state) => state.event.constants.constantsLoaded,
      current_config_language_key: (state) =>
        state.event.constants.event_configs.data.current_config_language_key,
      registry: (state) => state.event.constants.registry,
      event_configs: (state) => state.event.constants.event_configs.data,
      version: (state) => state.constants.data.version,
    }),
    ...mapGetters({
      canOpenModModal: ['event/canOpenModModal'],
    }),
    version_build() {
      try {
        return this.version
      } catch (error) {
        return process.env.VUE_APP_VERSION
      }
    },
    event_config() {
      return this.registry.configs.find(
        (row) => row.language.name === this.current_config_language_key,
      )
      // return this.event_configs[this.current_config_language_key]
    },
    navItems() {
      // return this.$store.state.event.constants.nav_items.data[
      //   this.current_config_language_key
      // ]
      return this.registry.nav_items.filter(
        (row) => row.language.name === this.current_config_language_key,
      )
    },
    drawer: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    openMod() {
      this.$store.commit('event/setModDashboard', {
        modal: true,
      })
    },
    openProfile() {
      this.$store.commit('event/setProfile', {
        modal: true,
        tab: TabKeys.profile,
      })
    },
    fullScreenMode() {
      var doc = window.document
      var docEl = doc.documentElement

      var requestFullScreen =
        docEl.requestFullscreen ||
        docEl.mozRequestFullScreen ||
        docEl.webkitRequestFullScreen ||
        docEl.msRequestFullscreen
      var cancelFullScreen =
        doc.exitFullscreen ||
        doc.mozCancelFullScreen ||
        doc.webkitExitFullscreen ||
        doc.msExitFullscreen

      if (
        !doc.fullscreenElement &&
        !doc.mozFullScreenElement &&
        !doc.webkitFullscreenElement &&
        !doc.msFullscreenElement
      ) {
        requestFullScreen.call(docEl)
      } else {
        cancelFullScreen.call(doc)
      }
    },
    logout() {
      this.$store.commit('session/setLogoutModal', {
        logoutModal: true,

        route: 'registry.thank-you',
      })
    },
  },
}
</script>

<style></style>
