<template>
  <v-card
    ref="event_broadcasts_table"
    class="tw-rounded-3xl tw-shadow-md"
    white
  >
    <v-container class="tw-p-0" fluid>
      <div class="tw-grid tw-grid-cols-5 tw-border-b tw-pt-3 lg:tw-grid-cols-2">
        <div class="tw-col-span-3 tw-flex tw-items-end lg:tw-col-span-1">
          <v-tabs v-model="filter_type" show-arrows>
            <v-tab
              v-for="filter_row_type in filter_types"
              :key="filter_row_type.text"
              :href="'#' + filter_row_type.value"
            >
              {{ filter_row_type.text }}
            </v-tab>
          </v-tabs>
        </div>
        <div
          class="tw-col-span-2 tw-grid tw-grid-cols-2 tw-gap-6 tw-px-4 tw-pb-3 lg:tw-col-span-1"
        >
          <v-select
            v-model="filter_selected"
            :items="filters"
            :label="$t('admin.app.filter_by')"
            prepend-inner-icon="sort"
            hide-details
            outlined
            rounded
          />

          <v-select
            v-if="filter_selected == 'sent_at'"
            v-model="search_enum"
            :items="search_enums"
            :label="$t('admin.app.filter_by')"
            prepend-inner-icon="sort"
            hide-details
            outlined
            rounded
          />
          <v-text-field
            v-else
            v-model="search"
            outlined
            rounded
            append-icon="search"
            :label="$t('app.search')"
            hide-details
          />
          <!-- </v-col> -->
        </div>
      </div>
      <!-- </v-row> -->
    </v-container>
    <v-container class="tw-overflow-hidden tw-p-0" fluid>
      <v-data-table
        v-model="selected"
        :search="search"
        :headers="headers"
        :items="formatedRows"
        :options.sync="options"
        :server-items-length="totalRows"
        :loading="loading"
        class="elevation-1"
        :footer-props="footer_props"
        :class="{ mobile: $vuetify.breakpoint.mdAndDown }"
      >
        <template #body="{ items }">
          <transition mode="out-in" name="fade">
            <tbody v-if="items.length" key="main-table">
              <tr
                v-for="item in items"
                :key="item.id"
                class="tw-cursor-pointer tw-transition sm:tw-py-4"
                :class="{ 'tw-opacity-75 ': loading }"
                @click="showItem(item)"
              >
                <td>
                  <div class="py-4 tw-flex">
                    <div class="tw-flex tw-items-center">
                      <div>
                        <QuinaryTitle :dark="false">
                          {{ item.title }}
                        </QuinaryTitle>
                        <p class="tw-mb-0 tw-text-sm tw-text-gray-500 tw-line-clamp-2" v-html="item.body" />
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="tw-flex tw-justify-end">
                    <p v-if="item.registry" class="mb-0 text-sm text-gray-500">
                      {{ item.registry.label }}
                    </p>
                    <p v-else class="mb-0 text-sm text-gray-500 tw-italic">
                      {{ $t('admin.app.general_notification') }}
                    </p>
                  </div>
                </td>
                <td>
                  <div class="tw-flex tw-justify-start">
                    <common-badge>
                      {{ $t(`admin.enums.${item.language.name}`) }}
                    </common-badge>
                  </div>
                </td>

                <td>
                  <div class="tw-flex tw-justify-start">
                    <StatusBadge
                      :is-active="!!item.sent_at"
                      :active-text="$t('admin.app.processed')"
                      :inactive-text="$t('admin.app.pending')"
                    />
                  </div>
                </td>
                <td>
                  <div class="tw-flex tw-justify-end">
                    <span v-if="item.parsed_emit_at">
                      {{ item.parsed_emit_at }}
                    </span>
                    <p v-else class="mb-0 text-sm text-gray-500 tw-italic">
                      Enviado imediatamente - {{ item.date }}
                    </p>
                  </div>
                </td>
                <td>
                  <div class="tw-flex tw-justify-end">
                    <span>
                      {{ item.date }}
                    </span>
                  </div>
                </td>
                <td>
                  <v-btn
                    fab
                    text
                    small
                    class="tw-text-gray-300 focus:tw-text-gray-600 focus:tw-outline-none focus:tw-ring-primary-500"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="tw-h-6 tw-w-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </v-btn>
                </td>
              </tr>
              <!-- mobile list view -->
            </tbody>
            <tbody v-if="!items.length && !loading" key="zero_table">
              <tr>
                <td colspan="8">
                  <v-alert
                    class="mx-8 mt-4"
                    color="primary"
                    border="left"
                    type="info"
                    elevation="2"
                    colored-border
                  >
                    <strong>{{ $t('$vuetify.noDataText') }}</strong>
                  </v-alert>
                </td>
              </tr>
            </tbody>
            <tbody v-if="!items.length && loading" key="loading_table">
              <tr>
                <td colspan="8">
                  <v-alert
                    class="mx-8 mt-4"
                    color="primary darken-1"
                    border="left"
                    type="success"
                    elevation="2"
                    colored-border
                  >
                    <strong>{{ $t('$vuetify.noDataText') }}</strong>
                  </v-alert>
                </td>
              </tr>
            </tbody>
          </transition>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>

<script>
import QuinaryTitle from '@/components/Titles/QuinaryTitle'

import CommonBadge from '@/components/CommonBadge'
import StatusBadge from '@/components/StatusBadge'
import { EventBroadcastsServiceInstance } from '@/services/EventBroadcastsService'
import { mapGetters, mapState } from 'vuex'

import { commonTimeFormat } from '@/utils/dateHelpers'
// import { formatterArray } from '@/utils/formatterArray'
export default {
  components: {
    CommonBadge,
    QuinaryTitle,
    StatusBadge,
    // LanguageBadge,
  },
  props: {
    registryId: {
      type: [Number, String],
      default: '',
    },
  },
  data: () => ({
    filter_type: 'all',
    search_enums: [
      { value: 1, text: 'Enviados' },
      { value: 0, text: 'Pendientes' },
    ],
    search_enum: 1,
    filter_status: '',
    filter_speciality: '',
    filter_selected: 'email',
    search: '',
    filters: [
      {
        text: 'Titulo',
        value: 'title',
      },
      {
        text: 'Contenido',
        value: 'body',
      },
      {
        text: 'Enviado',
        value: 'sent_at',
      },
    ],

    selected: undefined,
    totalRows: 0,
    rows: [],
    loading: false,
    awaitingSearch: false,
    options: {},
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t('admin.app.name'),
          align: 'start',
          sortable: true,
          value: 'last_name',
        },
        {
          text: this.$t('app.event'),
          align: 'start',
          sortable: false,
          value: 'registry_id',
        },
        {
          text: this.$t('app.language'),

          align: 'start',
          sortable: false,
          value: 'language',
        },

        {
          text: 'Status',
          sortable: true,
          value: 'sent_at',
        },
        {
          text: this.$t('admin.app.send_date'),
          value: 'emit_at',
          sortable: true,
          align: 'end',
        },
        {
          text: this.$t('app.updated_at'),
          value: 'updated_at',
          sortable: true,
          align: 'end',
        },
        { text: '', value: 'go', sortable: false },
      ]
    },
    nav() {
      return [
        {
          name: this.$t('app.event'),
          route: { name: 'admin.registries.index' },
        },
        {
          name: this.show.label,
          route: {
            name: 'admin.registries.show.home',
            params: { id: this.show.id },
          },
        },
        {
          name: 'Notificaciones',
          route: {},
        },
      ]
    },
    ...mapState({
      show: (state) => state.registries.show.data,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    filter_types() {
      return [
        { text: 'Todos', value: 'all' },
        { text: 'Español', value: 'es' },
        { text: 'Ingles', value: 'en' },
      ].map((row) => ({
        ...row,
        text: this.$t(`admin.enums.${row.value}`),
      }))
    },
    ...mapGetters({
      room_types: ['constants/room_types'],
      rolesSelect: ['constants/rolesSelect'],
    }),
    formatedRows() {
      return this.rows.map((row) => ({
        ...row,
        // formatedRoles: formatterArray().format(
        //   row.roles.map((role) => role.label),
        // ),
        parsed_emit_at: row.emit_at ? commonTimeFormat(row.emit_at) : null,
        date: commonTimeFormat(row.updated_at),
        datetime: row.updated_at,
        // fullName: `${row.first_name} ${row.last_name}`,
      }))
    },
    footer_props() {
      const obj = {
        'items-per-page-options': [
          10, 20, 30, 40, 50,
          // { text: 'Todos', value: this.totalRows },
        ],
      }
      return obj
    },
    filter_statuses() {
      return this.$store.getters.constants.statuses.map((item) => {
        const new_item = {
          text: item.label,
          value: item.id,
        }
        return new_item
      })
    },
    filter_specialities() {
      return this.$store.getters.constants.specialities.map((item) => {
        const new_item = {
          text: item.label,
          value: item.id,
        }
        return new_item
      })
    },
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
  },
  watch: {
    options: {
      handler() {
        this.getLeads()
      },
      deep: true,
    },
    filter_status: {
      handler() {
        this.getLeads()
      },
      deep: true,
    },
    filter_speciality: {
      handler() {
        this.getLeads()
      },
      deep: true,
    },
    filter_type: {
      handler() {
        this.getLeads()
      },
      deep: true,
    },
    filter_selected: {
      handler() {
        this.getLeads()
      },
      deep: true,
    },
    search_enum: {
      handler() {
        this.getLeads()
      },
      deep: true,
    },
    search: {
      handler() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getLeads()
            this.awaitingSearch = false
          }, 1000) // 1 sec delay
        }
        this.awaitingSearch = true
      },
      deep: true,
    },
  },
  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.onResize)
  },
  mounted() {
    const interval = setInterval(
      () => {
        this.getLeads()
      },
      // 30000
      120000,
    )
    this.$once('hook:destroyed', () => {
      console.log('destroyed')
      clearInterval(interval)
    })
  },
  methods: {
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    showItem(item) {
      this.$emit('on-row-click', item)
      // return this.$router.push({
      //   name: 'admin.configs.event-broadcasts.edit',
      //   params: { id: a.id },
      // })
    },
    getLeads() {
      // this.loading = true;
      this.loading = true

      this.$vuetify.goTo(this.$refs.event_broadcasts_table, {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic',
      })
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      //  let page_number = page.page || this.state.pagination.page;
      // let rowsPerPage = page.rowsPerPage || this.state.pagination.rowsPerPage;
      return EventBroadcastsServiceInstance.index({
        params: {
          page: page,
          rowsPerPage: itemsPerPage,
          sortBy: sortBy[0],
          sortDesc: sortDesc[0],
          filterBy: this.filter_selected,
          search: this.search,
          filter_type: this.filter_type,
          search_enum: this.search_enum,
          registry_id: this.registryId,
        },
      })
        .then((res) => {
          const { total, data } = res.data.data
          this.rows = data
          this.totalRows = total
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style></style>
