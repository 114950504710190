import $axios from '@/axios-silent.js'

export default class EventNotifications {
  baseUrl = '/api/v1/event'

  // Create
  notifications(params) {
    return $axios.post(`${this.baseUrl}/notifications`, {
      ...params,
      _method: 'POST',
    })
  }
  deleteNotification(id, params) {
    return $axios.delete(`${this.baseUrl}/notifications/${id}`, {
      ...params,
      _method: 'DELETE',
    })
  }
  deleteAll(params) {
    return $axios.post(`${this.baseUrl}/notifications/delete-all`, {
      ...params,
      _method: 'POST',
    })
  }
  updateMark(id, params) {
    return $axios.put(`${this.baseUrl}/notifications/${id}`, {
      ...params,
      _method: 'PUT',
    })
  }
  newMessage(params) {
    return $axios.post(`${this.baseUrl}/notifications-chat`, {
      ...params,
      _method: 'POST',
    })
  }
  broadcastAfter(params) {
    return $axios.post(`${this.baseUrl}/notifications-broadcast`, {
      ...params,
      _method: 'POST',
    })
  }
}
export const EventNotificationsInstance = new EventNotifications()
