<template>
  <v-dialog
    v-model="open"
    persistent
    max-width="768px"
    content-class="tw-rounded-3xl"
    scrollable
  >
    <v-form
      ref="form"
      v-model="valid"
      :disabled="form_disabled_state"
      lazy-validation
      enctype="multipart/form-data"
      @submit.prevent="update"
    >
      <v-card class="tw-rounded-3xl tw-border-0">
        <ModalHeader @close-modal="closeModal">Imagen</ModalHeader>
        <v-divider />
        <v-card-text class="tw-p-0" style="max-height: 700px">
          <v-responsive class="tw-py-0">
            <v-container class="tw-bg-gray-50 tw-p-0" fluid>
              <v-row class="mb-5 tw-m-0">
                <v-col cols="12" md="12" class="mx-auto tw-p-0">
                  <v-card
                    class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-none tw-bg-gray-50 tw-py-2 tw-px-6 tw-shadow-none"
                  >
                    <v-container class="pa-5">
                      <div class="tw-px-4 tw-py-2">
                        <div
                          class="tw-items-center sm:tw-flex sm:tw-justify-between"
                        >
                          <div class="tw-flex tw-flex-col">
                            <h3
                              class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            >
                              {{ $t('admin.app.desktop_image') }}
                            </h3>
                            <p
                              id="privacy-option-1-description"
                              class="tw-mb-0 tw-text-sm tw-text-gray-500"
                            >
                              {{ $t('admin.app.select_image') }}
                            </p>
                          </div>
                          <div
                            class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
                          >
                            <v-select
                              v-model="desktop_ratio"
                              :items="aspect_ratios"
                              :label="$t('admin.app.image_ratios')"
                              outlined
                              rounded
                              hide-details=""
                              dense
                              class="tw-w-64"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="tw-mt-3 tw-grid tw-grid-cols-1 tw-gap-1">
                        <DropzoneOverlayPreview
                          v-model="mountedSection.data.image_desktop"
                          :width="desktopDimentions[0]"
                          :height="desktopDimentions[1]"
                          :bypass="'ratio-check'"
                          media-group="registries_landings"
                        />
                      </div>
                    </v-container>
                    <v-container class="pa-5">
                      <div class="tw-px-4 tw-py-2">
                        <div
                          class="tw-items-center sm:tw-flex sm:tw-justify-between"
                        >
                          <div class="tw-flex tw-flex-col">
                            <h3
                              class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                            >
                              {{ $t('admin.app.mobile_image') }}
                            </h3>
                            <p
                              id="privacy-option-1-description"
                              class="tw-mb-0 tw-text-sm tw-text-gray-500"
                            >
                              {{ $t('admin.app.select_image') }}
                            </p>
                          </div>
                          <div
                            class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
                          >
                            <v-select
                              v-model="mobile_ratio"
                              :items="aspect_ratios"
                              :label="$t('admin.app.image_ratios')"
                              outlined
                              rounded
                              hide-details=""
                              dense
                              class="tw-w-64"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="tw-mt-3 tw-grid tw-grid-cols-1 tw-gap-1">
                        <DropzoneOverlayPreview
                          v-model="mountedSection.data.image_mobile"
                          :width="mobileDimentions[0]"
                          :height="mobileDimentions[1]"
                          :bypass="'ratio-check'"
                          media-group="registries_landings"
                        />
                      </div>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>

              <transition name="fade-transition" mode="out-in">
                <v-row v-if="errorAlert">
                  <v-col cols="12">
                    <v-alert
                      v-model="errorAlert"
                      dismissible
                      color="red"
                      border="left"
                      elevation="2"
                      colored-border
                      icon="error"
                      transition="fade-transition"
                    >
                      {{ errorMsg }}</v-alert
                    >
                  </v-col>
                </v-row>
              </transition>
            </v-container>
          </v-responsive>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            rounded
            :disabled="form_loading"
            class="tw-px-8 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-white"
            @click="closeModal"
          >
            {{ $t('app.cancel') }}</v-btn
          >
          <v-btn
            color="primary darken-1"
            rounded
            type="submit"
            :loading="form_loading"
            class="text--white text--lighten-4 tw-px-8 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-300"
          >
            {{ $t('app.save') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ModalHeader from '@/components/ModalHeader'
import DropzoneOverlayPreview from '@/components/DropzoneOverlayPreview.vue'
import { BannerTypeKeys } from '@/enums/SectionTypeKeys'
import { rules } from '@/utils/rules'
import { greatestCommonDivisor } from '@/utils/greatestCommonDivisor'

export default {
  components: {
    ModalHeader,
    DropzoneOverlayPreview,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    rules,
    aspect_ratios: [
      { text: '1920x1080 (16x9)', value: '1920x1080' },
      { text: '1920x1440 (4x3)', value: '1920x1440' },
      { text: '2100x900 (7x3)', value: '2100x900' },
      { text: '1080x1920 (9x16)', value: '1080x1920' },
      { text: '1280x1600 (4x5)', value: '1280x1600' },
      { text: '1280x1920 (2x3)', value: '1280x1920' },
      { text: '1600x1600 (1x1)', value: '1600x1600' },
    ],
    desktop_ratio: '1920x1080',
    mobile_ratio: '1920x1080',
    BannerTypeKeys,
    errorAlert: '',
    errorMsg: '',
    isLoading: false,
    form_loading: false,
    form_disabled_state: false,
    valid: true,
    mountedSection: {},
    isLoadingRooms: false,
  }),
  computed: {
    // tuple [W,H]
    /**
     * @returns {array}
     */
    desktopDimentions() {
      return this.desktop_ratio.split('x').map((row) => parseInt(row))
    },
    // tuple [W,H]
    /**
     * @returns {array}
     */
    mobileDimentions() {
      return this.mobile_ratio.split('x').map((row) => parseInt(row))
    },
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  created() {
    this.mountedSection = JSON.parse(JSON.stringify(this.section))
    this.initForm()
  },
  methods: {
    initForm() {
      const {
        ratio_desktop_x,
        ratio_desktop_y,
        ratio_mobile_x,
        ratio_mobile_y,
      } = this.mountedSection.data
      const desktopRatio = `${ratio_desktop_x}x${ratio_desktop_y}`
      const mobileRatio = `${ratio_mobile_x}x${ratio_mobile_y}`
      const aspectRatioMap = this.aspectRatioMap()
      this.desktop_ratio = aspectRatioMap[desktopRatio]
      this.mobile_ratio = aspectRatioMap[mobileRatio]
    },
    aspectRatioMap() {
      return this.aspect_ratios
        .map((row) => row.value.split('x'))
        .map((row) => {
          return [
            greatestCommonDivisor(row[0], row[1]).join('x'),
            row.join('x'),
          ]
        })
        .reduce(
          (obj, item) => ({
            ...obj,
            [item[0]]: item[1],
          }),
          {},
        )
    },
    closeModal() {
      this.open = false
    },
    update() {
      this.errorAlert = false
      if (!this.$refs.form.validate()) {
        return
      }
      const [ratio_desktop_x, ratio_desktop_y] = greatestCommonDivisor(
        this.desktopDimentions[0],
        this.desktopDimentions[1],
      )
      const [ratio_mobile_x, ratio_mobile_y] = greatestCommonDivisor(
        this.mobileDimentions[0],
        this.mobileDimentions[1],
      )
      this.mountedSection.data = {
        ...this.mountedSection.data,
        ratio_desktop_x,
        ratio_desktop_y,
        ratio_mobile_x,
        ratio_mobile_y,
      }
      this.$emit('updated-section', this.mountedSection)
      this.open = false
    },
  },
}
</script>

<style></style>
