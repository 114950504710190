import $axios from '@/axios-silent.js'

class CoreVitalServiceClass {
  baseUrl = '/api/v1/actions/app-core-vital'
  // Create
  execute(payload, params) {
    return $axios.post(this.baseUrl, payload, params)
  }
}
export const CoreVitalService = new CoreVitalServiceClass()
