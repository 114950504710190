import { VideoChatStateKeys } from '@/enums/VideoChatStateKeys'
// state
const initState = () => {
  return {
    view: 'HOME',
    videoChatModal: false,
    showChat: false,
    home_tab: 'PRIVATE',
    active_chats: [],
    private_channels: {},
    active_channel: null,
    public_channel: null,
    // Donovann
    parrent_thread: null,
    thread_has_chat: false,
    // End Donovann
    talking_to: {},
    video_chat: {
      user_uuid: '',
      target_user: {},
      target_user_uuid: '',
      room_uuids: null,
      iframe_modal: false,
      status: VideoChatStateKeys.not_started, //not_started | on_going | ringing | waiting | declined
    },
  }
}

// getters
const getters = {}

// actions
const actions = {
  closeCall({ commit }) {
    commit('setVideoChat', {
      user_uuid: '',
      target_user: {},
      target_user_uuid: '',
      room_uuids: null,
      iframe_modal: false,
      status: VideoChatStateKeys.not_started,
    })
  },
  answerCall({ commit }) {
    commit('setVideoChat', {
      status: VideoChatStateKeys.on_going,
    })
  },
  hungUp({ commit }) {
    commit('setVideoChat', {
      status: VideoChatStateKeys.hung_up,
    })
  },
  targetBusy({ commit }) {
    commit('setVideoChat', {
      status: VideoChatStateKeys.busy,
    })
  },
  declined({ commit }) {
    commit('setVideoChat', {
      status: VideoChatStateKeys.declined,
    })
  },
  isRinging({ commit, rootState }, { body }) {
    const user = rootState.event.socket.users.find(
      (row) => row.uuid === body.target_user_uuid,
    )
    const room_uuids =
      rootState.session.user.id > user.id
        ? body.user_uuid + '-link-' + user.uuid
        : user.uuid + '-link-' + body.user_uuid
    commit('setVideoChat', {
      target_user: user,
      target_user_uuid: body.target_user_uuid,
      user_uuid: body.user_uuid,
      room_uuids,
      status: VideoChatStateKeys.ringing,
      iframe_modal: true,
    })
  },
  callUser({ commit }, { body }) {
    commit('setVideoChat', {
      target_user: body.target_user,
      target_user_uuid: body.target_user_uuid,
      user_uuid: body.user_uuid,
      room_uuids: body.uuids,
      status: VideoChatStateKeys.waiting,
      iframe_modal: true,
    })
  },
}

// mutations
const mutations = {
  setVideoChat(state, obj) {
    Object.keys(obj).forEach((k) => (state.video_chat[k] = obj[k]))
  },
  setState(state, obj) {
    Object.keys(obj).forEach((k) => (state[k] = obj[k]))
  },
}

export const Chat = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
}
