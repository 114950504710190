<script>
import { mapGetters, mapState } from 'vuex'
import UserProfileTabWrapper from '@/components/Event/UserProfileTabWrapper.vue'
import { commonTimeFormat } from '@/utils/dateHelpers'

export default {
    name: 'UserListEntries',
    components: { UserProfileTabWrapper },
    data: () => ({
        search: '',
        entries: [],  // Almacena los formularios
        loading: false,  // Indica si los datos están cargando
        activeTab: 0,  // Controla la pestaña activa
        loadingStates: {},
        formToPrint: null,
    }),
    computed: {
        groupedForms() {
            return this.entries.reduce((g, e) => {
                const title = e.custom_form.title
                if (!g[title]) {
                    g[title] = []
                }
                g[title].push(e)
                return g
            }, {})
        },
        visibleFieldsByForm() {
            const visibleFields = {}
            for (const title in this.groupedForms) {
                const forms = this.groupedForms[title]
                if (forms.length > 0) {
                    visibleFields[title] = forms[0].custom_form.fields.filter(field => field.is_visible_table)
                }
            }
            return visibleFields
        },
        fieldsToPrint() {
            if (!this.formToPrint) return []

            // Tomar los campos visibles desde custom_form.fields
            const visibleFields = this.formToPrint.custom_form.fields.filter(field => field.is_visible_print)

            // Para cada campo visible, obtener el valor correspondiente en form_submission
            return visibleFields.map(field => {
                // Encontrar el valor correspondiente en form_submission
                const formSubmission = this.formToPrint.form_submission.find(submission => submission.name === field.name)

                return {
                    ...field,
                    model: formSubmission ? formSubmission.model : '', // Asignar el valor de form_submission o vacío si no existe
                }
            })
        },
        groupedFieldsToPrint() {
            if (!this.formToPrint) return []

            // Tomar los campos visibles desde custom_form.fields
            const visibleFields = this.formToPrint.custom_form.fields.filter(field => field.is_visible_print)

            // Combinar los campos relacionados con nombre y apellido
            let combinedFields = []
            let nameField = '' // Para almacenar el valor del nombre
            let lastNameField = '' // Para almacenar el valor del apellido

            visibleFields.forEach(field => {
                // Encontrar el valor correspondiente en form_submission
                const formSubmission = this.formToPrint.form_submission.find(submission => submission.name === field.name)
                const fieldValue = formSubmission ? formSubmission.model : ''

                // Verificar si es un campo de nombre o apellido
                if (['nombre', 'name', 'first_name', 'nombres', 'names'].includes(field.name)) {
                    nameField = fieldValue // Asignar el nombre
                } else if (['last_name', 'apellidos', 'apellido'].includes(field.name)) {
                    lastNameField = fieldValue // Asignar el apellido
                } else {
                    // Si no es un campo de nombre o apellido, agregarlo directamente al arreglo
                    combinedFields.push({
                        label: field.label,
                        value: fieldValue,
                        name: field.name,
                        isNameGroup: false,
                    })
                }
            })

            // Si encontramos campos de nombre y apellido, los agrupamos
            if (nameField || lastNameField) {
                combinedFields.unshift({
                    label: 'fullname',
                    value: `${nameField} ${lastNameField}`, // Combina nombre y apellido
                    isNameGroup: true,
                })
            }

            return combinedFields
        },
        ...mapGetters({
            isRep: 'session/isRep',
            isAdmin: 'session/isAdmin',
        }),
        ...mapState({
            registry: (state) => state.event.constants.registry.id,
        }),
    },
    watch: {
        search: {
            handler() {
                clearTimeout(this.awaitingSearch)
                this.awaitingSearch = setTimeout(() => {
                    this.getRows()
                }, 600)
            },
            immediate: false,
        },
    },
    mounted() {
        this.getRows()
    },
    methods: {
        formatDate(date) {
            return commonTimeFormat(date)
        },
        getFormSubmissionValue(formSubmission, fieldName) {
            const field = formSubmission.find((item) => item.name === fieldName)
            return field ? field.model : '' // Retorna el valor o una cadena vacía si no existe
        },
        // Método para cargar los datos desde la API
        getRows() {
            this.loading = true

            this.$store
                .dispatch('forms/listEntries', {
                    params: {
                        rowsPerPage: 10,  // Cargar solo 40 resultados
                        search: this.search,
                        registry_id: this.registry,
                    },
                })
                .then((res) => {
                    const { data } = res.data.data
                    this.entries = data  // Actualiza los resultados
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        submitAssistence(id, bool) {
            this.$set(this.loadingStates, id, { checkLoading: bool, closeLoading: !bool })

            const previousState = this.entries.find(e => e.id === id).assistance

            this.entries.find(e => e.id === id).assistance = bool

            this.$store
                .dispatch('forms/updateAssistence', {
                    id: id,
                    assistance: bool,
                })
                .catch(() => {
                    this.entries.find(e => e.id === id).assistance = previousState
                })
                .finally(() => {
                    this.$set(this.loadingStates, id, { checkLoading: false, closeLoading: false })
                })
        },
        printData(form) {
            this.formToPrint = form
            this.$nextTick(() => {
                this.printUsingIframe()
            })
        },
        printUsingIframe() {
            const customCSS = this.formToPrint.custom_form.custom_css || ''
            // Crear un iframe temporal para imprimir
            const iframe = document.createElement('iframe')
            iframe.style.position = 'absolute'
            iframe.style.width = '0'
            iframe.style.height = '0'
            iframe.style.border = 'none'
            document.body.appendChild(iframe)

            // Obtener el documento dentro del iframe
            const doc = iframe.contentWindow.document
            const printContents = document.getElementById('printArea').innerHTML

            // Escribir el contenido dentro del iframe
            doc.open()
            doc.write(`
                <html>
                    <head>
                        <title>Imprimir formulario</title>
                        <style>
                            .print-container {
                                text-align: center;
                            }
                            ${customCSS}
                        </style>
                    </head>
                    <body>
                        ${printContents}
                    </body>
                </html>
            `)
            doc.close()

            // Esperar un momento antes de abrir la ventana de impresión
            setTimeout(() => {
                iframe.contentWindow.focus()
                iframe.contentWindow.print()

                // Eliminar el iframe después de la impresión
                document.body.removeChild(iframe)
            }, 100)
        },
        // deleteEntrie(id) {
        //     this.$set(this.loadingStates, id, { deleteLoading: true })
        //     this.$store
        //         .dispatch('forms/deleteEntrie', { id: id })
        //         .then(() => {
        //             this.getRows()
        //         })
        //         .finally(() => {
        //             this.$set(this.loadingStates, id, { deleteLoading: false })
        //         })
        // },
    },
}
</script>

<template>
    <UserProfileTabWrapper>
        <v-container fluid>
            <section class="tw-py-6 tw-px-4 sm:tw-p-6 lg:tw-pb-8">
                <div class="tw-mx-auto tw-max-w-7xl">
                    <div class="tw-mt-4">
                        <!-- Campo de búsqueda -->
                        <v-text-field
                            v-model="search"
                            label="Buscar formularios"
                            clearable
                            rounded
                            filled
                            hide-details
                        />
                        <!-- Tarjeta con pestañas de formularios -->
                        <v-card class="tw-rounded-xl tw-shadow-md tw-mt-4">
                            <v-tabs v-model="activeTab" grow>
                                <v-tab v-for="(forms, title) in groupedForms" :key="title" class="tw-font-semibold">
                                    {{ title }}
                                </v-tab>
                                <v-tab-item v-for="(forms, title) in groupedForms" :key="title" class="tw-p-4">
                                    <v-divider />
                                    <v-simple-table>
                                        <thead>
                                            <tr>
                                                <!-- Mostrar solo los campos visibles -->
                                                <th v-for="field in visibleFieldsByForm[title]" :key="field.uid">
                                                    {{ field.label }}
                                                </th>
                                                <th>{{ $t('app.created_at') }}</th>
                                                <th>{{ $t('bz2.event.rep.forms.labels.assitence') }}</th>
                                                <th>{{ $t('app.actions') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="form in forms" :key="form.id">
                                                <td v-for="field in visibleFieldsByForm[form.custom_form.title]" :key="field.uid" class="tw-whitespace-nowrap">
                                                    {{ getFormSubmissionValue(form.form_submission, field.name) }}
                                                </td>
                                                <td class="tw-whitespace-nowrap">{{ formatDate(form.created_at) }}</td>
                                                <td class="tw-whitespace-nowrap">
                                                    <v-chip small :color="form.assistance ? 'green' : 'red'" outlined class="tw-ml-1">
                                                        {{ form.assistance ? $t('bz2.event.rep.forms.labels.attended') : $t('bz2.event.rep.forms.labels.no_attended') }}
                                                    </v-chip>
                                                </td>
                                                <td class="tw-whitespace-nowrap">
                                                    <v-btn 
                                                        fab text small
                                                        class="tw-text-green-500 focus:tw-outline-none"
                                                        :disabled="form.assistance"
                                                        :loading="loadingStates[form.id] && loadingStates[form.id].checkLoading"
                                                        @click="submitAssistence(form.id, true)"
                                                    >
                                                        <v-icon>check</v-icon>
                                                    </v-btn>
                                                    <v-btn 
                                                        v-if="isAdmin"
                                                        fab text small
                                                        class="tw-text-red-500 focus:tw-outline-none"
                                                        :disabled="!form.assistance"
                                                        :loading="loadingStates[form.id] && loadingStates[form.id].closeLoading"
                                                        @click="submitAssistence(form.id, false)"
                                                    >
                                                        <v-icon>close</v-icon>
                                                    </v-btn>
                                                    <v-btn 
                                                        fab text small
                                                        class="tw-text-blue-900 focus:tw-outline-none"
                                                        @click="printData(form)"
                                                    >
                                                        <v-icon>print</v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-tab-item>
                            </v-tabs>
                        </v-card>
                    </div>
                </div>
            </section>
            <!-- Area de impresion -->
            <div id="printArea" style="display:none;">
                <div v-if="formToPrint" class="print-container">
                    <!-- Renderizar directamente el contenido -->
                    <template v-for="(group, index) in groupedFieldsToPrint">
                        <p v-if="group.isNameGroup" :key="`fullname-${index}`" class="bzcf-fullname">{{ group.value }}</p>
                        <p v-else :key="`group-${group.name}-${index}`" :class="`bzcf-${group.name}`">{{ group.value }}</p>
                    </template>
                </div>
            </div>
        </v-container>
    </UserProfileTabWrapper>
</template>