import gql from 'graphql-tag'

export const threadWhereId = gql`
  query threadWhereId($thread_id: Int!) {
    threadWhereId(id: $thread_id) {
      uuid
      id
      question_answer {
        id
        is_public
        body
        reply_to_id
        created_at
        uuid
        user {
          id
          first_name
          last_name
          uuid
          avatar
        }
      }
    }
  }
`
