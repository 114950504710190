import $axios from '@/axios-instance.js'

class RegistriesTicketUserServiceClass {
  baseUrl = '/api/v1/admin/registries'
  child = 'ticket-user'

  // Create
  store(event_config, params) {
    return $axios.post(`${this.baseUrl}/${event_config}/${this.child}`, params)
  }
  // Delete
  delete(event_config, params) {
    return $axios.post(`${this.baseUrl}/${event_config}/delete-ticketu`, params)
  }
}
export const RegistriesTicketUser = new RegistriesTicketUserServiceClass()
