<template>
  <div
    :class="{
      'tw-aspect-w-16 tw-aspect-h-7': is_desktop,
      'tw-aspect-w-16 tw-aspect-h-9': !is_desktop,
    }"
  >
    <div
      ref="event_room_dekstop"
      key="room_dekstop"
      v-resize="onResize"
      class="tw-relative"
      :class="{
        'tw-aspect-w-16 tw-aspect-h-7': is_desktop,
        'tw-aspect-w-16 tw-aspect-h-9': !is_desktop,
        'tw-pointer-events-none': popUpNotAuthorized,
      }"
    >
      <v-img
        v-if="is_desktop"
        :src="virtual_room.bg_desktop"
        class="tw-aspect-w-16 tw-aspect-h-7"
      />
      <v-img
        v-if="!is_desktop"
        :src="virtual_room.bg_mobile"
        class="tw-aspect-w-16 tw-aspect-h-9"
      />
      <!-- class="tw-aspect-w-16 tw-aspect-h-10" tw-top-4 -->
      <div class="tw-absolute tw-inset-0">
        <div class="tw-relative tw-h-full tw-w-full">
          <div
            v-if="has_rep_badge_present"
            class="tw-absolute tw-inset-x-0 tw-top-4"
          >
            <div class="tw-container tw-mx-auto tw-flex tw-justify-end">
              <RenderlessFilterRepsHandle>
                <template slot-scope="{ slotFilterRepsActionEvent, hasReps }">
                  <transition name="fade-transition" mode="out-in">
                    <v-btn
                      v-if="hasReps"
                      rounded
                      color="white"
                      class="
                        tw-bg-transparent
                        tw-pr-4
                        tw-pl-3.5
                        tw-font-bold
                        tw-normal-case
                        tw-shadow
                        focus:tw-outline-none
                        focus:tw-ring
                        focus:tw-ring-gray-50
                      "
                      @click="slotFilterRepsActionEvent"
                    >
                      {{ $t('pages.event.chat.home.reps_cta') }}
                      <svg
                        class="
                          tw-ml-2
                          tw--mr-1
                          tw-h-5
                          tw-w-5
                          tw-rotate-90
                          tw-transform
                        "
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                        />
                      </svg>
                    </v-btn>
                  </transition>
                </template>
              </RenderlessFilterRepsHandle>
            </div>
          </div>
          <div
            v-for="item in virtualRoomElements"
            :key="item.uuid"
            class="tw-absolute tw-bg-transparent"
            :class="[`${item.element_type.name}-${item.uuid}`]"
            :style="styles[item.uuid]"
          >
            <template v-if="item.element_type.name == ElementTypeKeys.doorway">
              <v-card
                :key="item.uuid + '-doorway-desktop'"
                flat color="transparent" class="tw-h-full tw-w-full"
                @click="toVirtualRoom(item.doorway_item_room.slug)"
              >
                <template v-if="virtual_room.has_tooltip_room">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <!-- Aquí usamos una div vacía para activar el tooltip sin modificar la estructura del DOM adicionalmente -->
                      <div class="tw-h-full tw-w-full" v-bind="attrs" v-on="on"/>
                    </template>
                    <span>{{ item.doorway_item_room.label }}</span>
                  </v-tooltip>
                </template>
              </v-card>
            </template>
            <template v-if="item.element_type.name == ElementTypeKeys.video">
              <template v-if="item.video_item_type.name == VideoKeys.element">
                <!-- <div>{{ item.content_element }}</div> -->
                <div
                  class="tw-h-full tw-w-full tw-overflow-hidden tw-rounded-3xl"
                >
                  <ElementVideo
                    ref="element-video"
                    :video="item.content_element"
                  />
                </div>
              </template>
              <template v-if="item.video_item_style.name == 'embed'">
                <AppPlyr
                  v-if="item.video_item_type.name == 'uploaded'"
                  :key="item.uuid + '-video-item-uploaded-desktop'"
                >
                  <video
                    ref="uploaded"
                    controls
                    crossorigin
                    playsinline
                    :data-poster="item.video_item_preview"
                    :autoplay="!!item.is_autoplay"
                  >
                    <source :src="item.video_item_url" type="video/mp4" />
                  </video>
                </AppPlyr>

                <vimeo-player
                  v-if="item.video_item_type.name == 'vimeo'"
                  ref="player"
                  :key="item.uuid + '-video-item-embed-desktop'"
                  class="g-iframe-full tw-h-full tw-w-full"
                  :video-url="item.video_item_url"
                  :options="item.is_autoplay ? { autoplay: true } : {}"
                />
                <LazyYoutube
                  v-if="item.video_item_type.name == 'youtube'"
                  ref="youtube"
                  :key="item.uuid + '-video-item-embed-desktop'"
                  :src="item.video_item_url"
                  class="g-iframe-full tw-h-full tw-w-full"
                  :autoplay="!!item.is_autoplay"
                />
              </template>
              <template v-else>
                <v-card
                  :key="item.uuid + '-video-item-desktop'"
                  flat
                  color="transparent"
                  class="tw-h-full tw-w-full"
                  title
                  @click="openSimpleVideo(item)"
                />
              </template>
            </template>
            <template
              v-else-if="item.element_type.name == ElementTypeKeys.iframe"
            >
              <v-card
                v-if="item.video_item_style.name == 'embed'"
                :key="item.uuid + '-iframe-embed'"
                flat
                color="transparent"
                class="tw-h-full tw-w-full"
                title
              >
                <iframe
                  v-if="item.iframe_item_type.name == 'link'"
                  ref="iframe"
                  :src="item.iframe_item_url"
                  frameborder="0"
                  class="tw-h-full tw-w-full"
                  allow="camera *; geolocation *; microphone *; autoplay *;fullscreen *;payment *;"
                  sandbox="allow-scripts allow-same-origin allow-scripts allow-popups allow-forms allow-pointer-lock allow-top-navigation-by-user-activation allow-top-navigation	allow-presentation allow-popups-to-escape-sandbox "
                />
                <iframe
                  v-else
                  ref="iframe_srcdoc"
                  :srcdoc="item.iframe_item_html"
                  frameborder="0"
                  class="tw-h-full tw-w-full"
                />
              </v-card>
              <v-card
                v-else
                :key="item.uuid + '-iframe-desktop'"
                flat
                color="transparent"
                class="tw-h-full tw-w-full"
                title
                @click="openIframe(item)"
              />
            </template>
            <template v-if="item.element_type.name == ElementTypeKeys.image">
              <v-img
                :key="item.uuid + '-image-desktop'"
                :src="item.image_item_cropped"
              />
            </template>
            <template v-if="item.element_type.name == ElementTypeKeys.slider">
              <!-- speed: item.slider_item_timer, -->
              <RenderlessClickableHandle>
                <template slot-scope="{ slotClickActionEvent }">
                  <VueSlickCarousel
                    v-if="item.slider_item_slides.length > 0"
                    :key="item.uuid + '-slick-desktop'"
                    v-bind="{
                      ...settings,
                      autoplaySpeed: item.slider_item_timer,
                    }"
                    class="tw-h-full tw-w-full"
                  >
                    <!-- <div
                    v-for="slider_item_slide in item.slider_item_slides"
                    :key="slider_item_slide.uuid"
                    class="tw-w-full tw-h-full"
                  > -->
                    <div
                      v-for="slider_item_slide in item.slider_item_slides"
                      :key="slider_item_slide.uuid"
                      class="tw-pointer-events-auto focus:tw-outline-none"
                    >
                      <v-card
                        v-if="slider_item_slide.clickable"
                        class="
                          tw-pointer-events-auto tw-border-0 tw-bg-transparent
                        "
                        @click="
                          () => {
                            pauseVideoOnClickableContentVideo(slider_item_slide)
                            slotClickActionEvent(slider_item_slide)
                          }
                        "
                      >
                        <v-container class="tw-bg-transparent tw-p-0">
                          <img
                            :key="slider_item_slide.uuid + '-image-desktop'"
                            class="tw-h-full tw-w-full"
                            :src="slider_item_slide.image_cropped"
                          />
                        </v-container>
                      </v-card>
                      <v-card
                        v-else
                        class="tw-border-0 tw-bg-transparent"
                        flat
                        title
                      >
                        <v-container class="tw-bg-transparent tw-p-0">
                          <img
                            :key="item.uuid + '-image-desktop'"
                            class="tw-h-full tw-w-full"
                            :src="slider_item_slide.image_cropped"
                          />
                        </v-container>
                      </v-card>
                    </div>
                  </VueSlickCarousel>
                </template>
              </RenderlessClickableHandle>
            </template>
            <template v-if="item.element_type.name == ElementTypeKeys.click">
              <RenderlessClickableHandle>
                <template slot-scope="{ slotClickActionEvent }">
                  <v-card
                    :key="item.uuid + '-click-action-desktop'"
                    flat color="transparent" class="tw-h-full tw-w-full" title
                    @click="
                      () => {
                        pauseVideoOnClickableContentVideo(item)
                        slotClickActionEvent(item)
                      }
                    "
                  >
                    <template v-if="virtual_room.has_tooltip_room">
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <!-- Aquí usamos una div vacía para activar el tooltip sin modificar la estructura del DOM adicionalmente -->
                          <div class="tw-h-full tw-w-full" v-bind="attrs" v-on="on"/>
                        </template>
                        <span>{{ item.label }}</span>
                      </v-tooltip>
                    </template>
                  </v-card>
                </template>
              </RenderlessClickableHandle>
            </template>
            <template v-if="item.element_type.name == ElementTypeKeys.content">
              <v-card
                :key="item.uuid + '-content-action-desktop'"
                flat
                color="transparent"
                class="tw-h-full tw-w-full"
                title
                @click="contentWindowMethod(item)"
              />
            </template>
            <template v-if="item.element_type.name == ElementTypeKeys.chat">
              <ElementChat
                :key="item.uuid + '-chat-action-desktop'"
                :element="item"
              />
            </template>
          </div>
          <!-- Inmediate PopUp (Imgen, Texto, Video)-->
          <InmediatePopUp v-model="contentVideoModal" :virtual-room="virtual_room" />
        </div>
      </div>
    </div>
    <!-- Vista para usuarios no autorizados -->
    <div v-if="popUpNotAuthorized">
      <div class="bz-box-notauthorized" :style="stylesNotAuthorized">
        <v-card class="tw-p-6 tw-rounded-xl">
          <v-card-text class="tw-text-center">
            <v-icon style="color:#d6d6d6; font-size: 3rem" class="tw-mb-4">lock</v-icon>
            <i18n
              path="bz2.not_authorized.title"
              tag="div"
              class="text-h6 tw-font-bold"
            >
              <template #ticket>
                {{ ticketName.ticket_label_key }}
              </template>
              <template #enterprise>
                {{ ticketName.enterprise_label }}
              </template>
            </i18n>
            <div class="text-h7">{{ $t('bz2.not_authorized.description') }}</div>
            
            <v-btn small rounded dark style="background-color:#3565af" class="tw-normal-case tw-mt-4" @click="backSite()">
              {{ $t('app.back') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <!-- Vista para usuarios no autorizados -->
    <!-- Vista para usuarios no autorizados -->
    <div v-if="popUpPassword">
      <div class="bz-box-notauthorized" :style="stylesNotAuthorized">
        <v-card class="tw-p-6 tw-rounded-xl">
          <v-card-text class="tw-text-center">
            <v-icon style="color:#d6d6d6; font-size: 3rem" class="tw-mb-4">password</v-icon>
            <div class="text-h6 tw-font-semibold tw-mb-2">{{ $t('bz2.not_authorized.title_pass') }}</div>
            <div>
              <div class="ma-auto tw-relative" style="max-width: 300px">
                <v-otp-input
                  v-model="otp_dig"
                  :disabled="loading_otp"
                  length="4"
                  @finish="onValidatePassRoom"
                />
                <span v-if="snackbar">{{ snackbarMsg }}</span>
                <v-overlay absolute :value="loading_otp">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  />
                </v-overlay>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <!-- Vista para usuarios no autorizados -->
  </div>
</template>

<script>
import AppPlyr from '@/components/Video/AppPlyr.vue'
import RenderlessFilterRepsHandle from '@/components/Event/RenderlessFilterRepsHandle.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import { openWindowTab } from '@/utils/openWindowTab'
import { mapFields } from '@/helpers'
import { mapGetters, mapState } from 'vuex'
import { download } from '@/utils/download'
import { ElementTypeKeys } from '@/enums/ElementTypeKeys'
import { TargetLinkKeys } from '@/enums/TargetLinkKeys'
import { ContentTypeKeys } from '@/enums/ContentTypeKeys'
import { VideoKeys } from '@/enums/VideoKeys'
import RenderlessClickableHandle from '@/components/Event/RenderlessClickableHandle.vue'
import { isHappening } from '@/utils/dateHelpers'
import ElementVideo from '@/components/Event/ElementVideo.vue'
import ElementChat from '@/components/Event/ElementChat.vue'

import InmediatePopUp from '@/components/AppLayout/InmediatePopUp.vue'
import { ClickableTypeKeys } from '@/enums/ClickableTypeKeys'

export default {
  components: {
    AppPlyr,
    RenderlessFilterRepsHandle,
    VueSlickCarousel,
    RenderlessClickableHandle,
    ElementVideo,
    ElementChat,
    InmediatePopUp,
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    VideoKeys,
    settings: {
      arrows: false,
      dots: false,

      // dotsClass: 'slick-dots custom-dot-class',
      // variableWidth: true,
      fade: true,
      // adaptiveHeight: true,
      autoplay: true,
      // autoplaySpeed: 2000,
      infinite: true,
      // speed: 500,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
    },
    ElementTypeKeys,
    windowSize: {
      x: 0,
      y: 0,
    },
    popUpNotAuthorized: false,
    popUpPassword: false,
    otp_dig: '',
    snackbarMsg: '',
    snackbarColor: '',
    snackbar: false,
    loading_otp: false,
  }),

  head() {
    return {
      title: {
        inner: this.virtual_room.label,
      },
      style: [
        {
          type: 'text/css',
          inner: this.virtual_room.custom_css,
        },
      ],
    }
  },
  computed: {
    stylesNotAuthorized() {
      return {
        'background-color': `${this.virtual_room.footer_color}cf`,
      }
    },
    has_rep_badge_present() {
      return this.virtual_room.has_rep_badge_present
    },
    virtualRoomElements() {
      return this.virtualRoomCurrentElements
    },
    ...mapGetters({
      currentBreakPointKey: ['event/currentBreakPointKey'],
      virtualRoomCurrentElements: ['event/virtualRoomCurrentElements'],
    }),
    ...mapState({
      showLoading: (state) => state.event.show.isLoading,
      is_desktop: (state) => state.event.constants.is_desktop,
      is_preview: (state) => state.event.constants.is_preview,
      virtual_room: (state) => state.event.show.data,
      virtual_rooms: (state) => state.event.constants.virtual_rooms,
      user: (state) => state.session.user,
      registry: (state) => state.event.constants.registry,
      // 
      channels: (state) => state.event.constants.channels,
      threads: (state) => state.event.constants.threads,
    }),
    styles() {
      return this.virtual_room.elements.reduce((row, curr) => {
        const multiplier = this.room_width / 1000
        if (this.is_desktop) {
          row[curr.uuid] = {
            width: parseInt(curr.desktop_width) * multiplier + 'px',
            height: parseInt(curr.desktop_height) * multiplier + 'px',
            top: parseInt(curr.desktop_y) * multiplier + 'px',
            left: parseInt(curr.desktop_x) * multiplier + 'px',
            'transform-origin': 'top left',
            transform: `rotate(${curr.rotation}deg)`,
          }
        } else {
          row[curr.uuid] = {
            width: parseInt(curr.responsive_width) * multiplier + 'px',
            height: parseInt(curr.responsive_height) * multiplier + 'px',
            top: parseInt(curr.responsive_y) * multiplier + 'px',
            left: parseInt(curr.responsive_x) * multiplier + 'px',
            'transform-origin': 'top left',
            transform: `rotate(${curr.rotation}deg)`,
          }
        }
        return row
      }, {})
    },
    ...mapFields({
      fields: [
        'room_width',
        'room_height',
        'nav_count',
        'uuid_tracker',
        'navigation_log_virtual_room_ids',
      ],
      base: ['event', 'constants'],
      mutation: 'event/setConstants',
    }),
    ...mapFields({
      fields: [
        'iframeItemModal',
        'iframeItemHtml',
        'iframeItemUrl',
        'iframeItemType',
      ],
      base: ['event', 'iframeItem'],
      mutation: 'event/setIframeItem',
    }),
    ...mapFields({
      fields: ['contentItemModal', 'contentItemData'],
      base: ['event', 'contentItem'],
      mutation: 'event/setContentItem',
    }),
    ...mapFields({
      fields: ['contentWindowModal', 'contentWindowData'],
      base: ['event', 'contentWindow'],
      mutation: 'event/setContentWindow',
    }),
    ...mapFields({
      fields: [
        'simpleVideoItemModal',
        'simpleVideoItemType',
        'simpleVideoItemUrl',
        'simpleVideoItemPreview',
        'is_autoplay',
      ],
      base: ['event', 'simpleVideoItem'],
      mutation: 'event/setSimpleVideoItem',
    }),
    ...mapFields({
      fields: ['contentVideoModal'],
      base: ['event', 'activeInmediatePopUp'],
      mutation: 'event/setActiveInmediatePopUp',
    }),
    ...mapGetters({
      click_types: ['constants/click_types'],
    }),
    ticketName() {
      // console.log('this.virtual_room', this.registry  )
      return this.registry.registry_tickets?.find((row) => row.id === this.virtual_room.registry_room?.registry_ticket_id)
    },
  },

  mounted() {
    // TODO: user.class === virtualRoom.class pueda ingresa
    var ticket = this.user.ticket ? this.user.ticket.find(ticket => ticket.registry_id == this.registry.id) : ''
    // Validar si usuarios tienen clase
    if (this.registry.is_multi_ticket) {
      if (ticket) {
        if (!ticket.registry_ticket.is_access_total) {
          this.popUpNotAuthorized = ticket.registry_ticket_id !== this.virtual_room.registry_room?.registry_ticket_id ? true : false
        }
      } else {
        this.popUpNotAuthorized = this.virtual_room.registry_room?.registry_ticket_id ? true : false
      }
    }

    if (this.popUpNotAuthorized) {
      this.popUpPassword = false
    } else {
      if (this.virtual_room.has_password_room) {
        this.popUpPassword = true
      }
    }

    this.onResize()
    this.$emit('updateHead')
    console.log({ fromContent: this.virtual_rooms })
    // window.contentElement = this

    this.checkForAutolaunch()
    this.checkForAutoOpenChat() 
    this.navigation_log_virtual_room_ids = [
      ...this.navigation_log_virtual_room_ids,
      this.virtual_room.id,
    ]
  },
  methods: {
    onValidatePassRoom(code) {
      this.loading_otp = true
      this.snackbar = false
      this.snackbarMsg = ''
      setTimeout(() => {
        this.loading_otp = false
        if (code === this.virtual_room.otp_room) {
          this.snackbar = true
          this.snackbarMsg = this.$t('bz2.not_authorized.msg_succ')
          this.popUpPassword = false
        } else {
          this.snackbar = true
          this.snackbarMsg = this.$t('bz2.not_authorized.msg_err')
          this.popUpPassword = true
        }
      }, 3500)
    },
    pauseVideoOnClickableContentVideo(item) {
      const {
        clickable: {
          click_action_type: { name },
        },
      } = item
      if (ClickableTypeKeys.link_content !== name) {
        return
      }

      const {
        content_type: { name: content_type_name },
      } = item.clickable.content_item_element
      if (ContentTypeKeys.video != content_type_name) {
        return
      }
      this.pauseVideoModal()
    },
    // Validate auto open chat
    checkForAutoOpenChat() {
      if (this.virtual_room.has_auto_open_chat == true) {
        if (ClickableTypeKeys.open_channel === this.virtual_room.click_action_type.name) {
          const Channel = this.channels.find((row) => row.id === this.virtual_room.link_channel_id)
          this.$store.commit('chat/setState', {
            showChat: true,
            view: 'ACTIVE_PUBLIC_CHANNEL',
            public_channel: Channel,
          })
        }
        if (ClickableTypeKeys.open_public_chat === this.virtual_room.click_action_type.name) {
          const Threads = this.threads.find((row) => row.id === this.virtual_room.link_thread_id)
          this.$store.commit('chat/setState', {
            showChat: true,
            view: 'ACTIVE_QA_CHANNEL',
            parrent_thread: Threads,
            thread_has_chat: true,
          })
        }
      }
    },
    checkForAutolaunch() {
      if (!this.virtual_room.has_autolaunch) {
        this.$store.commit('event/setActiveInmediatePopUp', {
          contentVideoModal: false,
        })
        return
      }
      // time, if current time in between start and end
      // Validar si siempre hay
      if (this.virtual_room.has_autolaunch_bracket) {
        if (
          !isHappening({
            start_date_time: this.virtual_room.autolaunch_start_date_time,
            end_date_time: this.virtual_room.autolaunch_end_date_time,
          })
        ) {
          return
        }
      }
      if (this.virtual_room.can_autolaunch_repeat) {
        this.$store.commit('event/setActiveInmediatePopUp', {
          contentVideoModal: true,
        })
        return
      }
      if (!this.navigation_log_virtual_room_ids.includes(this.virtual_room.id)) {
        this.$store.commit('event/setActiveInmediatePopUp', {
          contentVideoModal: true,
        })
        return
      }
    },
    contentItemEvent(content_item_element) {
      const {
        target_link_type_id,
        download_item_url,
        doorway_item_room_id,
        link_item_url,
        content_type: { name },
      } = content_item_element

      console.log(name)
      if (ContentTypeKeys.download == name) {
        console.log(target_link_type_id)
        if (TargetLinkKeys.normal.value == target_link_type_id) {
          download(download_item_url, download_item_url.split('/').pop(0))
        }
        if (TargetLinkKeys.target.value == target_link_type_id) {
          this.contentItemModal = true
          this.contentItemData = content_item_element
        }
      }
      if (ContentTypeKeys.doorway == name) {
        const room =
          this.$store.getters['event/getRoomFromConstants'](
            doorway_item_room_id,
          )
        console.log(room)
        if (room) {
          this.toVirtualRoom(room.slug)
        }
      }

      if (ContentTypeKeys.link == name) {
        console.log(link_item_url)
        console.log(target_link_type_id)
        if (TargetLinkKeys.normal.value == target_link_type_id) {
          openWindowTab(link_item_url)
        }
        if (TargetLinkKeys.target.value == target_link_type_id) {
          this.$store.commit('event/setContentItem', {
            contentItemModal: true,
            contentItemData: content_item_element,
          })
        }
      }
      if (ContentTypeKeys.video == name) {
        this.$store.commit('event/setContentVideo', {
          contentVideoModal: true,
          contentVideoData: content_item_element,
        })
      }
    },
    contentWindowMethod(item) {
      console.log('Content window')
      console.log({ item })
      const { label, content_item_elements } = item
      this.contentWindowData = { label, content_item_elements }
      this.contentWindowModal = true
      // console.log(item.label item.content_item_elements)
    },
    openSimpleVideo(item) {
      this.pauseVideoModal()
      this.pauseVideoElemento = !this.pauseVideoElemento
      // Abre Modal Video
      this.simpleVideoItemModal = true
      this.simpleVideoItemType = item.video_item_type.name
      this.simpleVideoItemUrl = item.video_item_url
      this.simpleVideoItemPreview = item.video_item_preview
      // Añade mapFields -> ...fields -> is_autoplay
      this.is_autoplay = item.is_autoplay
      // console.log(item.is_autoplay)
    },
    pauseVideoModal() {
      // Mp4
      if (this.$refs.uploaded) {
        this.$refs.uploaded.forEach((upload) => {
          upload.plyr.pause()
        })
      }
      // Youtube
      if (this.$refs.youtube) {
        this.$refs.youtube.forEach((upload) => {
          upload.pauseVideo()
        })
      }
      // Vimeo
      if (this.$refs.player) {
        this.$refs.player.forEach((upload) => {
          upload.pause()
        })
      }
      // Elemento
      if (this.$refs['element-video']) {
        this.$refs['element-video'].forEach((videoElement) => {
          videoElement.pauseElement()
        })
      }
    },
    openIframe(item) {
      console.log(item.iframe_item_url)
      this.iframeItemModal = true
      this.iframeItemType = item.iframe_item_type.name
      this.iframeItemUrl = item.iframe_item_url
      this.iframeItemHtml = item.iframe_item_html
    },
    // toVirtualRoom(item) {
    toVirtualRoom(slug) {
      if (this.virtual_room.slug === slug) {
        return
      }
      this.$router.push({
        name: this.is_preview ? 'event-preview.route' : 'event.route',
        params: {
          slug,
          eventSlug: this.registry.slug,
          // slug: item.doorway_item_room.slug,
        },
      })
    },
    onResize() {
      console.log(
        'resiing elements, while this is showing or not showing',
        this.showLoading,
      )
      if (this.showLoading) {
        return
      }
      console.log(this.$refs.event_room_dekstop.getBoundingClientRect())
      const { width, height } = this.is_desktop
        ? this.$refs.event_room_dekstop.getBoundingClientRect()
        : this.$refs.event_room_dekstop.getBoundingClientRect()
      // : this.$refs.event_room_mobile.getBoundingClientRect()
      this.room_width = width
      this.room_height = height
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    backSite() {
      this.$router.go(-1)
    },
  },
}
</script>
<style>
.slick-current {
  z-index: 10;
}
.bz-box-notauthorized {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
