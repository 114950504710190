import $axios from '@/axios-silent.js'

export default class EventHybridService {
    baseUrl = '/api/v1/event'

    getUsersHybrid(params) {
        return $axios.get(`${this.baseUrl}/users-hybrid`, params)
    }
}

export const EventHybridServiceInstance = new EventHybridService