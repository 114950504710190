<template>
  <!-- <div class="text-xs-center"> -->
  <v-dialog
    v-model="show"
    width="400"
    content-class="tw-self-end tw-rounded-3xl sm:tw-self-auto"
  >
    <!-- <template v-slot:activator="{ on }">
        <v-btn color="red lighten-2" dark v-on="on">Click Me</v-btn>
    </template>-->

    <v-card dark class="taypa-radius tw-shadow-md">
      <!-- <v-card-title class="headline grey lighten-2 text-xs-center" primary-title>
        <h5 class="text-xs-center">¿Estas seguro?</h5>
      </v-card-title>-->
      <!--
      <v-card-title class="text-center">Use Google's location service?</v-card-title>-->
      <v-card-text class="pt-2 taypa-radius">
        <h1 class="text-center mb-2 pt-5 pb-5 tw-text-2xl tw-text-white">
          {{ $t('app.are_you_sure') }}
        </h1>
        <p class="text-center tw-text-lg tw-text-gray-400">
          {{ $t('app.logout_msg') }}
        </p>
        <!-- Esta a punto de cerrar sesion. -->
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <!-- <v-spacer></v-spacer> -->
        <!--
            class="
              tw-flex tw-flex-col tw-justify-center
              sm:tw-space-x-3 sm:tw-flex-row
            " -->
        <v-container class="tw-px-0 tw-py-2">
          <div
            class="tw-flex tw-flex-col-reverse tw-gap-3 sm:tw-grid sm:tw-grid-cols-2"
          >
            <!-- <v-btn text @click.stop="show = false">{{
              $t('app.cancel')
            }}</v-btn> -->
            <AppBtn color="transparent-dark" block @click="closeModal">
              {{ $t('app.cancel') }}
            </AppBtn>
            <AppBtn color="outlined-danger-dark" block @click="logout">
              <!-- {{ $t('app.cancel') }} -->
              <v-icon left>power_settings_new</v-icon>{{ $t('app.logout') }}
            </AppBtn>
            <!-- <v-btn
              outlined
              color="error"
              class="focus:tw-ring focus:tw-ring-red-300 focus:tw-outline-none"
              @click="logout"
            >
              <v-icon left>power_settings_new</v-icon>{{ $t('app.logout') }}
            </v-btn> -->
          </div>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- </div> -->
</template>
<script>
import AppBtn from '@/components/App/AppBtn.vue'
// import { AppCoreVitalKeys } from '@/enums/AppCoreVitalKeys'
export default {
  name: 'LogoutModal',
  components: {
    AppBtn,
  },
  props: {
    value: Boolean,
  },
  computed: {
    is_user_in_event() {
      return this.$store.state.event.constants.is_user_in_event
    },
    show: {
      get() {
        return this.$store.state.session.logoutModal
      },
      set(value) {
        return this.$store.commit('session/setLogoutModal', {
          logoutModal: value,
        })
      },
    },
  },
  methods: {
    logout() {
      // if (this.is_user_in_event) {
      //   const core_vital_type = AppCoreVitalKeys.EventFinalized
      //   this.$store.dispatch('general/recordCoreVitalV2', {
      //     payload: {
      //       core_vital_type,
      //       simple_log: 'logout',
      //       // second_tracker: this.uuids,
      //     },
      //     core_vital_type,
      //   })
      // }
      this.show = false
      this.$store.dispatch('session/logout')
    },
    closeModal() {
      console.log('show closed ')
      this.show = false
    },
  },
}
</script>
