<template>
  <renderless-layout>
    <v-container fluid class="tw-p-0">
      <sticky-header :nav="nav">
        <template #actions>
          <div class="tw-flex tw-items-center tw-space-x-3">
            <v-btn
              class="tw-bg-transparent tw-px-8 tw-normal-case tw-text-white tw-shadow-none focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-50"
              rounded
              type="button"
              @click="deleteEvent"
            >
              <g-icon class="tw-mr-2 tw--ml-1 tw-h-6 tw-w-6" name="trash_bin" />
              Eliminar
            </v-btn>
          </div>
        </template>
      </sticky-header>
      <v-row>
        <!-- <delete-registries-access-control-rule-modal
        v-if="deleteModal"
        v-model="deleteModal"
        :loading="deleteActionLoading"
        :access-control="access_control"
        @delete-confirmed="deleteEvent"
      /> -->
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="11"
          xl="9"
          class="mx-auto tw-container"
        >
          <v-container fluid class="sm:py-3">
            <RegistriesAccessControlForm
              v-model="form"
              :error-alert.sync="errorAlert"
              :error-msg="errorMsg"
              :is-loading="isLoading"
              @submit-form="submitEvent"
            />
          </v-container>
        </v-col>
      </v-row>
      <AppConfirmDialog ref="deleteModal" />
    </v-container>
  </renderless-layout>
</template>

<script>
import AppConfirmDialog from '@/components/App/AppConfirmDialog.vue'
import RegistriesAccessControlForm from './RegistriesAccessControlForm.vue'
import StickyHeader from '@/components/StickyHeader.vue'
import GIcon from '@/components/GIcon.vue'
import { rules } from '@/utils/rules'
import { mapState } from 'vuex'
import { AccessControlBase } from '@/models/AccessControl'
export default {
  components: {
    GIcon,
    AppConfirmDialog,
    StickyHeader,
    RegistriesAccessControlForm,
  },

  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
    accessControl: {
      type: [Number, String],
      default: 0,
    },
  },
  data: () => ({
    // fields
    isLoading: false,
    form_disabled_state: false,
    valid: true,
    errorAlert: false,
    errorMsg: '',
    rules,
    form: AccessControlBase({}),
    deleteModal: false,
    deleteActionLoading: false,
  }),
  computed: {
    nav() {
      return [
        {
          name: 'Registros y Landings',
          route: { name: 'admin.registries.index' },
        },
        {
          name: this.show.label,
          route: {
            name: 'admin.registries.show.home',
            params: { id: this.show.id },
          },
        },
        {
          name: 'Control de ingreso',
          route: {
            name: 'admin.configs.access-control',
            params: { id: this.show.id },
          },
        },
        {
          name: `Editando regla #${this.accessControl}`,
          current: true,
          route: {},
        },
      ]
    },
    ...mapState({
      show: (state) => state.registries.show.data,
      access_control: (state) => state.registries.access_control.show.data,
    }),
  },
  beforeMount() {
    const {
      target_string,
      control_type: { name: control_type_name },
      access_list_type: { name: access_list_type_name },
    } = this.access_control
    this.form = AccessControlBase({
      access_list_type: access_list_type_name,
      control_type: control_type_name,
      target_string,
    })
  },
  methods: {
    async deleteEvent() {
      const has_accepted = await this.$refs.deleteModal.open(
        'Eliminar',
        'Regla sera eliminada',
        {
          color: 'red',
        },
      )
      if (has_accepted) {
        this.$store
          .dispatch('registries/accessControlDestroy')
          .then(() => {
            this.deleteModal = false
            this.$router.push({
              name: 'admin.configs.access-control',
              params: { id: this.show.id },
            })
          })
          .catch((err) => {
            this.$store.dispatch('notifications/addNotification', {
              title: 'Error eliminando regla',
              body: err,
            })
          })
          .finally(() => {
            this.deleteActionLoading = false
          })
      }
    },
    submitEvent(body) {
      this.isLoading = true
      this.form_disabled_state = true

      this.$store
        .dispatch('registries/accessControlUpdate', { payload: { ...body } })
        .then(() => {
          this.$router.push({
            name: 'admin.configs.access-control',
            params: { id: this.show.id },
          })
        })
        .catch((err) => {
          this.errorAlert = true
          this.errorMsg = err
        })
        .finally(() => {
          this.isLoading = false
          this.form_disabled_state = false
        })
    },
  },
}
</script>
