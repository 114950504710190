<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="768px"
    content-class="tw-rounded-3xl"
  >
    <v-form
      ref="form"
      v-model="valid"
      :disabled="form_disabled_state"
      lazy-validation
      enctype="multipart/form-data"
      @submit.prevent="update"
    >
      <v-card class="tw-rounded-3xl tw-border-0 tw-shadow-md">
        <ModalHeader @close-modal="closeModal">{{ section.label }}</ModalHeader>
        <v-divider />
        <v-card-text class="tw-p-0" style="max-height: 700px">
          <v-responsive class="tw-py-0">
            <v-container class="tw-bg-gray-50">
              <v-row class="mb-5">
                <v-col cols="12" class="mx-auto pa-5">
                  <v-card
                    class="
                      mx-auto
                      tw-divide-y
                      tw-divide-gray-200
                      tw-rounded-3xl
                      tw-bg-transparent
                      tw-shadow-none
                    "
                  >
                    <v-container class="pa-5">
                      <div class="tw-grid tw-gap-4 lg:tw-grid-cols-2">
                        <v-text-field
                          v-model="mountedSection.data.label"
                          :rules="[rules.required]"
                          validate-on-blur
                          outlined
                          rounded
                          :label="$t('admin.app.name')"
                        />
                        <v-select
                          v-model="mountedSection.data.duration"
                          :items="duration_types"
                          :rules="[rules.required]"
                          validate-on-blur
                          :label="$t('app.slide_duration')"
                          outlined
                          rounded
                        />
                      </div>
                    </v-container>
                    <v-container
                      v-if="mountedSection.data.slides.length > 0"
                      class="pa-5"
                    >
                      <div>
                        <draggable
                          v-model="mountedSection.data.slides"
                          tag="div"
                          handle=".handle"
                          class="
                            tw-overflow-hidden tw-bg-gray-100 tw-shadow
                            sm:tw-rounded-xl
                          "
                          ghost-class="ghost"
                        >
                          <transition-group
                            type="transition"
                            :name="'flip-list'"
                            tag="ul"
                            class="tw-divide-y tw-divide-gray-200 tw-pl-0"
                          >
                            <li
                              v-for="slide in mountedSection.data.slides"
                              :key="slide.uid"
                              class="
                                tw-flex
                                tw-items-center
                                tw-justify-between
                                tw-space-x-4
                                tw-px-4
                                tw-py-4
                                sm:tw-px-6
                              "
                            >
                              <div
                                v-if="slide.image_url || slide.mobile_image_url"
                                class="
                                  tw-flex tw-items-center tw-flex-col
                                  md:tw-flex-row md:tw-space-x-4
                                "
                              >
                                <div>
                                  <span class="tw-text-xs tw-font-bold"
                                    >Desktop</span
                                  >
                                  <v-img
                                    :src="slide.image_url"
                                    class="tw-w-32 tw-h-16 sm:tw-rounded-md"
                                  />
                                </div>
                                <div>
                                  <span class="tw-text-xs tw-font-bold"
                                    >Mobile</span
                                  >
                                  <v-img
                                    :src="slide.mobile_image_url"
                                    class="tw-w-32 tw-h-16 sm:tw-rounded-md"
                                  />
                                </div>
                              </div>
                              <div
                                v-else
                                class="tw-flex tw-items-center tw-text-sm"
                              >
                                Editar elemento
                                <g-icon
                                  class="tw-text-primary-400 tw-w-5 tw-h-5 ml-2"
                                  name="support"
                                />
                              </div>
                              <div
                                class="
                                  tw-flex tw-flex-shrink-0 tw-flex-col
                                  md:tw-flex-row
                                  tw-items-center tw-space-y-2
                                "
                              >
                                <AppBtn
                                  color="transparent"
                                  icon-center="g-next"
                                  g-next="DragOrder"
                                  class="handle tw-cursor-move"
                                  fab
                                  small
                                  type="button"
                                />
                                <RegistriesLandingsSliderBodyModal
                                  :value="slide"
                                  :section="section.name"
                                  @input="updatedSlide"
                                />
                                <AppBtn
                                  color="transparent-danger"
                                  icon-center="close"
                                  fab
                                  small
                                  type="button"
                                  @click="deleteItem(slide)"
                                />
                              </div>
                            </li>
                          </transition-group>
                        </draggable>
                      </div>
                    </v-container>
                    <v-container>
                      <div class="tw-flex tw-justify-end">
                        <AppBtn
                          type="button"
                          icon="plus"
                          color="white"
                          @click="addItem"
                        >
                          {{ $t('app.add') }}
                        </AppBtn>
                      </div>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-responsive>
        </v-card-text>

        <v-card-actions>
          <v-alert
            v-model="errorAlert"
            dismissible
            color="red"
            border="left"
            elevation="2"
            colored-border
            icon="error"
            transition="fade-transition"
          >
            {{ errorMsg }}
          </v-alert>
          <v-spacer />
          <AppBtn
            type="button"
            :disabled="form_loading"
            color="transparent"
            @click="closeModal"
          >
            {{ $t('app.cancel') }}
          </AppBtn>
          <AppBtn
            color="primary"
            :loading="form_loading"
            icon="check"
            type="submit"
          >
            {{ $t('app.save') }}
          </AppBtn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ModalHeader from '@/components/ModalHeader'
import draggable from 'vuedraggable'
import { grid_items, SliderItem } from '@/models/RegistryLanding'
import AppBtn from '../App/AppBtn.vue'
// import DropzoneOverlayPreview from '../DropzoneOverlayPreview.vue'
import { rules } from '@/utils/rules'
import { cloneDeep } from '@/utils/cloneDeep'

import RegistriesLandingsSliderBodyModal from '@/components/RegistriesModals/RegistriesLandingsSliderBodyModal.vue'
import GIcon from '@/components/GIcon.vue'

import { NotificationStatusKeys } from '@/enums/NotificationStatusKeys'

export default {
  components: {
    ModalHeader,
    draggable,
    AppBtn,
    // DropzoneOverlayPreview,
    RegistriesLandingsSliderBodyModal,
    GIcon,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    grid_items: grid_items,
    isDragging: false,
    errorAlert: false,
    errorMsg: '',
    rules,
    isLoading: false,
    form_loading: false,
    form_disabled_state: false,
    valid: true,
    mountedSection: {},
    isLoadingRooms: false,

    section_slider_body_modal: false,
  }),
  computed: {
    duration_types() {
      return [
        { text: `1 ${this.$t('words.durations_second')}`, value: 1000 },
        { text: `3 ${this.$t('words.durations_seconds')}`, value: 3000 },
        { text: `5 ${this.$t('words.durations_seconds')}`, value: 5000 },
        { text: `10 ${this.$t('words.durations_seconds')}`, value: 10000 },
        { text: `15 ${this.$t('words.durations_seconds')}`, value: 15000 },
        { text: `30 ${this.$t('words.durations_seconds')}`, value: 30000 },
        { text: `1 ${this.$t('words.durations_minute')}`, value: 60000 },
        { text: `2 ${this.$t('words.durations_minutes')}`, value: 120000 },
        { text: `3 ${this.$t('words.durations_minutes')}`, value: 180000 },
      ]
    },
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  created() {
    this.mountedSection = cloneDeep(this.section)
  },
  methods: {
    updatedSlide(payload) {
      this.mountedSection.data.slides = this.mountedSection.data.slides.map(
        (row) => {
          if (row.uid != payload.uid) {
            return row
          }
          return payload
        },
      )
    },
    activeBody() {
      this.section_slider_body_modal = true
    },
    deleteItem(slide) {
      this.mountedSection.data.slides = this.mountedSection.data.slides.filter(
        (row) => row.uid != slide.uid,
      )
    },
    addItem() {
      console.log(this.mountedSection.data.slides)
      this.mountedSection.data.slides = [
        ...this.mountedSection.data.slides,
        SliderItem(),
      ]
    },
    closeModal() {
      this.open = false
    },
    validateImageSlider() {
      const res = this.mountedSection.data.slides.filter((row) => 
        !row.image_url && !row.mobile_image_url,
      ).length > 0
      return res
    },
    update() {
      this.errorAlert = false
      if (!this.$refs.form.validate()) {
        return
      }

      if (!this.validateImageSlider()) {
        this.open = false
        this.$emit('updated-section', this.mountedSection)
      } else {
        this.$store.dispatch('notifications/addNotification', {
          title: this.$t('app.title_slider_element'),
          status: NotificationStatusKeys.error,
          body: this.$t('app.notification_slider_element'),
        })
      }
      // console.log(this.mountedSection);
    },
  },
}
</script>

<style></style>
