<template>
  <div class="tw-flex tw-flex-col tw-justify-between sm:tw-flex-row">
    <div>
      <quinary-title :dark="false">
        <slot name="title" />
      </quinary-title>
      <div class="tw-mb-2 tw-h-1 tw-w-8 tw-rounded-full tw-bg-primary-200" />
      <p class="tw-mb-0 tw-font-medium tw-leading-6 tw-text-gray-500">
        <slot name="copy" />
      </p>
    </div>
  </div>
</template>

<script>
import QuinaryTitle from '@/components/Titles/QuinaryTitle'
export default {
  components: {
    QuinaryTitle,
  },
}
</script>
