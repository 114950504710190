<template>
  <div class="tw-h-full">
    <div
      class="
        tw-absolute tw-z-0 tw-h-64 tw-w-full tw-rounded-t-lg tw-bg-primary-500
      "
    />
    <div
      class="
        tw-flex tw-h-full tw-flex-col tw-space-y-4 tw-overflow-y-scroll tw-p-4
        bz-scroll
      "
    >
      <div class="tw-z-10 tw-ml-4 tw-flex tw-flex-col tw-text-white">
        <!-- <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="tw-mb-3 tw--ml-1 tw-h-10 tw-w-10 tw-rotate-90 tw-transform"
        >
          <path
            d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"
          />
        </svg> -->
        <div class="tw-mb-0 tw-mt-1 tw-text-2xl">
          {{ $t('words.hi') }} {{ firstName }} 👋
        </div>
        <!-- <div class="tw-mb-1 tw-text-sm tw-text-gray-200 tw-w-60"
                  >We help your business grow by connecting you to your
                  customers.</div
                > -->
      </div>
      <RenderlessFilterRepsHandle>
        <template
          slot-scope="{ slotFilterRepsActionEvent, hasReps, reps, onlySome }"
        >
          <transition name="fade-transition" mode="out-in">
            <div
              v-if="hasReps"
              class="
                tw-z-10
                tw-rounded
                tw-border-0
                tw-border-t-4
                tw-border-secondary-400
                tw-text-sm
                tw-shadow-md
                tw-cursor-pointer
              "
              @click="slotFilterRepsActionEvent"
            >
              <div
                class="
                  tw-flex
                  tw-flex-col
                  tw-space-y-2
                  tw-rounded-t-none
                  tw-rounded-b
                  tw-border
                  tw-border-t-0
                  tw-bg-white
                "
              >
                <div
                  class="
                    tw-flex
                    tw-flex-col
                    tw-items-start
                    tw-gap-3
                    tw-px-4
                    tw-py-2
                    tw-relative
                    bz-box-rep
                  "
                >
                  <!-- <div class="tw-font-semibold">Enviar un mensaje</div> -->
                  <div class="tw-flex tw-flex-row tw-gap-3">
                    <div class="tw-flex tw-items-center tw-space-x-2">
                      <div class="tw-flex tw-flex-row tw--space-x-10">
                        <v-img
                          v-for="rep in onlySome"
                          :key="rep.uuid"
                          :src="rep.avatar"
                          class="
                            tw-h-12
                            tw-w-12
                            tw-rounded-full
                            tw-border-2
                            tw-border-white
                          "
                          contain
                        >
                          <template #placeholder>
                            <v-row
                              class="fill-height ma-0 transparent"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                size="32"
                                indeterminate
                                color="primary"
                              />
                            </v-row>
                          </template>
                        </v-img>
                      </div>
                      <span
                        v-if="reps.length > onlySome.length"
                        class="
                          tw-flex-shrink-0
                          tw-text-xs
                          tw-font-medium
                          tw-leading-5
                        "
                      >
                        +{{ reps.length - onlySome.length }}
                      </span>
                    </div>
                    <div class="tw-flex tw-flex-col tw-justify-center">
                      <div class="tw-text-gray-400 tw-text-xs">
                        {{ $t('pages.event.chat.home.reps_heading') }}
                      </div>
                      <div
                        class="
                          tw-flex
                          tw-flex-row
                          tw-items-center
                          tw-gap-1
                          tw-font-semibold
                          tw-text-sm
                        "
                      >
                        <svg
                          class="tw-h-4 tw-w-4 tw-text-blue-700"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        {{ $t('pages.event.chat.home.reps_tagline') }}
                      </div>
                      <div
                        class="
                          tw-absolute tw-right-0
                          bz-next-icon
                          tw-text-gray-400
                          tw-bg-white
                          tw-h-full
                          tw-flex
                          tw-items-center
                          tw-px-4
                          tw-text-xs
                        "
                      >
                        {{ $t('pages.event.chat.home.reps_cta') }}
                        <v-icon class="tw-text-gray-300 tw-ml-1"
                          >arrow_forward_ios</v-icon
                        >
                      </div>
                    </div>
                  </div>
                  <!-- <button
                    v-ripple
                    type="button"
                    class="
                      tw-flex
                      tw-flex-row
                      tw-gap-2
                      tw-px-5
                      tw-py-3
                      tw-text-white
                      tw-transition
                      tw-bg-blue-700
                      tw-rounded-full
                      tw-shadow
                      focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300
                    "
                    @click="slotFilterRepsActionEvent"
                  >
                    <svg
                      class="tw-w-5 tw-h-5 tw-transform tw-rotate-90"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                      />
                    </svg>
                    {{ $t('pages.event.chat.home.reps_cta') }}
                  </button> -->
                  <!-- <AppBtn
                    color="primary-dark"
                    platform
                    class="tw-text-sm"
                    @click="slotFilterRepsActionEvent"
                  >
                    {{ $t('pages.event.chat.home.reps_cta_short') }}
                    <svg
                      class="tw-ml-2 tw--mr-1 tw-h-5 tw-w-5 tw-rotate-90 tw-transform"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                      />
                    </svg>
                  </AppBtn> -->
                </div>
              </div>
            </div>
          </transition>
        </template>
      </RenderlessFilterRepsHandle>
      <!-- TODO: Tabs -->
      <div
        v-if="computedTabs.length > 1"
        class="
          tw-z-10
          tw-rounded-md
          tw-border-0
          tw-border-t-4
          tw-border-secondary-400
          tw-shadow-md
        "
      >
        <div class="tw-block">
          <v-tabs
            v-model="tab_select"
            fixed-tabs
            class="tw-rounded"
            color="rgb(178 145 79)"
            height="40"
            show-arrows
          >
            <v-tab
              v-for="ct in computedTabs"
              :key="ct.name"
              class="tw-normal-case tw-font-semibold tw-text-xs"
              @click="home_tab = ct.name"
            >
              {{ ct.label }}
            </v-tab>
          </v-tabs>
        </div>
      </div>
      <!-- TODO: Tabs Content -->
      <transition name="fade-transition" mode="out-in">
        <!-- TODO: Online -->
        <div
          v-if="home_tab == tabs[0].name"
          key="online_home"
          class="
            tw-z-10
            tw-flex
            tw-flex-col
            tw-rounded
            tw-border-0
            tw-border-t-4
            tw-border-secondary-400
            tw-shadow-md
            lg:tw-overflow-hidden
            tw-bg-white
          "
        >
          <UsersList key="active-users-chat" :search-tab="true" />
        </div>
        <!-- TODO: Private -->
        <div
          v-if="home_tab == tabs[1].name"
          key="private_home"
          class="
            tw-z-10
            tw-flex
            tw-flex-col
            tw-rounded
            tw-border-0
            tw-border-t-4
            tw-border-secondary-400
            tw-shadow-md
            lg:tw-overflow-hidden
          "
        >
          <div
            v-if="!hasChats"
            class="
              tw-flex
              tw-flex-col
              tw-rounded-t-none
              tw-border
              tw-border-t-0
              tw-bg-white
              tw-px-6
              tw-py-6
            "
          >
            <!-- <div class="tw-flex tw-items-center tw-justify-between">
              <div class="">
                <h3 class="tw-text-md tw-font-semibold">
                  {{ $t('pages.event.chat.home.convos') }}
                </h3>
              </div>
              <div>
                <v-btn
                  v-if="hasChats"
                  class="tw-shadow focus:tw-outline-none focus:tw-ring tw-bg-secondary-400 tw-text-white"
                  icon
                  @click="toUserList"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </div>
            </div> -->
            <div class="tw-p-4 tw-text-center">
              <h2 class="tw-mb-2 tw-font-semibold tw-text-gray-900">
                {{ $t('pages.event.chat.home.no_private_convos_title') }}
              </h2>
              <p class="tw-text-sm">
                {{ $t('pages.event.chat.home.no_private_convos_body') }}
              </p>
              <v-btn
                color="secondary"
                rounded
                class="
                  tw-px-8 tw-font-bold tw-normal-case tw-text-gray-50
                  focus:tw-outline-none
                  focus:tw-ring
                  focus:tw-ring-secondary-300
                "
                @click="view = 'ACTIVE_USERS'"
              >
                {{ $t('pages.event.chat.home.view_users') }}
              </v-btn>
            </div>
          </div>
          <ul
            v-if="hasChats"
            class="
              tw-z-10 tw-h-full tw-w-full tw-divide-y tw-divide-gray-200 tw-pl-0
              lg:tw-overflow-hidden lg:tw-overflow-y-auto
              bz-scroll
            "
          >
            <UsersListItem
              v-for="user in filteredUsers"
              :key="user.email"
              :user="user"
            />
          </ul>
        </div>
        <!-- TODO: channels -->
        <div
          v-if="home_tab == tabs[2].name"
          key="channels_home"
          class="
            tw-z-10
            tw-rounded-lg
            tw-border-0
            tw-border-t-4
            tw-border-secondary-400
            tw-shadow-md
          "
        >
          <div
            v-if="virtualRoomChannels.lenght > 0"
            class="
              tw-flex
              tw-flex-col
              tw-space-y-2
              tw-rounded-t-none
              tw-border
              tw-border-t-0
              tw-bg-white
              tw-p-6
            "
          >
            <div class="">
              <h3 class="tw-text-lg tw-font-semibold">
                {{ $t('pages.event.chat.home.room_channel') }}
              </h3>
            </div>
            <div
              class="
                tw-flex
                tw-flex-row
                tw-border-t
                tw-border-solid
                tw-border-gray-200
                tw-pt-5
              "
            >
              <ul
                class="
                  tw--my-5 tw-w-full tw-divide-y tw-divide-gray-200 tw-pl-0
                "
              >
                <li
                  v-for="person in people"
                  :key="person.handle"
                  class="tw-py-4"
                >
                  <!-- <button
                            class="tw-inline-flex tw-w-full hover:tw-bg-primary-300 focus:tw-bg-primary-300 focus:tw-outline-none focus:ring focus:tw-ring-primary-500"
                          > -->
                  <div class="tw-flex tw-items-center tw-space-x-4">
                    <div class="tw-flex-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="tw-h-6 tw-w-6 tw-text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"
                        />
                      </svg>
                    </div>
                    <div class="tw-min-w-0 tw-flex-1">
                      <p
                        class="
                          tw-mb-0
                          tw-truncate
                          tw-text-sm
                          tw-font-medium
                          tw-text-gray-900
                        "
                      >
                        {{ person.name }}
                      </p>
                    </div>
                    <div>
                      <button
                        v-ripple
                        class="
                          tw-inline-flex
                          tw-items-center
                          tw-rounded-full
                          tw-border
                          tw-border-solid
                          tw-border-gray-300
                          tw-bg-white
                          tw-px-2.5
                          tw-py-0.5
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-700
                          tw-shadow-sm
                          tw-transition
                          hover:tw-bg-gray-50
                          focus:tw-outline-none
                          focus:tw-ring
                          focus:tw-ring-primary-300
                        "
                      >
                        {{ $t('app.open') }}
                      </button>
                    </div>
                  </div>
                  <!-- </button> -->
                </li>
              </ul>
            </div>
          </div>
          <div
            class="
              tw-flex
              tw-flex-col
              tw-space-y-2
              tw-rounded-t-none
              tw-rounded-b-lg
              tw-border
              tw-border-t-0
              tw-bg-white
              tw-p-6
            "
          >
            <div class="">
              <h3 class="tw-text-md tw-font-semibold">
                {{ $t('pages.event.chat.home.open_channel') }}
              </h3>
            </div>
            <div
              class="
                tw-flex
                tw-flex-row
                tw-border-t
                tw-border-solid
                tw-border-gray-200
                tw-pt-5
              "
            >
              <ul
                class="
                  tw--my-5 tw-w-full tw-divide-y tw-divide-gray-200 tw-pl-0
                "
              >
                <li
                  v-for="globalChannel in globalChannels"
                  :key="globalChannel.id"
                  class="tw-py-4"
                >
                  <!-- <button
                            class="tw-inline-flex tw-w-full hover:tw-bg-primary-300 focus:tw-bg-primary-300 focus:tw-outline-none focus:ring focus:tw-ring-primary-500"
                          > -->
                  <div class="tw-flex tw-items-center tw-space-x-4">
                    <div class="tw-flex-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="tw-h-6 tw-w-6 tw-text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"
                        />
                      </svg>
                    </div>
                    <div class="tw-min-w-0 tw-flex-1">
                      <p
                        class="
                          tw-mb-0
                          tw-truncate
                          tw-text-sm
                          tw-font-medium
                          tw-text-gray-900
                        "
                      >
                        {{ globalChannel.subject }}
                      </p>
                    </div>
                    <div>
                      <button
                        v-ripple
                        class="
                          tw-inline-flex
                          tw-items-center
                          tw-rounded-full
                          tw-border
                          tw-border-solid
                          tw-border-gray-300
                          tw-bg-white
                          tw-px-2.5
                          tw-py-0.5
                          tw-text-sm
                          tw-font-medium
                          tw-leading-5
                          tw-text-gray-700
                          tw-shadow-sm
                          tw-transition
                          hover:tw-bg-gray-50
                          focus:tw-outline-none
                          focus:tw-ring
                          focus:tw-ring-primary-300
                        "
                        @click="enterPublicChannel(globalChannel)"
                      >
                        {{ $t('app.open') }}
                      </button>
                    </div>
                  </div>
                  <!-- </button> -->
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- TODO: Groups -->
        <div
          v-if="home_tab == tabs[3].name"
          key="group_home"
          class="
            tw-z-10
            tw-rounded
            tw-border-0
            tw-border-t-4
            tw-border-secondary-400
            tw-shadow-md
          "
        >
          <div
            class="
              tw-flex
              tw-flex-col
              tw-space-y-2
              tw-rounded-t-none
              tw-rounded-b
              tw-border
              tw-border-t-0
              tw-bg-white
              tw-p-6
            "
          >
            <!-- <div class="tw-text-sm tw-font-semibold">Grupos</div> -->
            <div class="">
              <h3 class="tw-text-lg tw-font-semibold">Grupos</h3>
              <div>
                <v-btn
                  v-if="false"
                  class="tw-shadow focus:tw-outline-none focus:tw-ring"
                  icon
                  @click="view = 'ACTIVE_USERS'"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </div>
              <div v-if="true" class="tw-p-4 tw-text-center">
                <h2 class="tw-mb-2 tw-font-semibold tw-text-gray-900">
                  No tienes chats grupales
                </h2>
                <p class="tw-text-sm">
                  Conecta con mas de un usuarios del evento
                </p>
                <v-btn
                  color="secondary"
                  rounded
                  class="
                    tw-px-8 tw-font-bold tw-normal-case tw-text-gray-50
                    focus:tw-outline-none
                    focus:tw-ring
                    focus:tw-ring-secondary-300
                  "
                  @click="view = 'CREATE_GROUP_CHANNEL'"
                >
                  Crear un grupo
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <!-- TODO: Support -->
        <div
          v-if="home_tab == tabs[4].name"
          key="support_home"
          class="
            tw-z-10
            tw-rounded
            tw-border-0
            tw-border-t-4
            tw-border-secondary-400
            tw-shadow-md
          "
        >
          <div
            class="
              tw-flex
              tw-flex-col
              tw-overflow-hidden
              tw-rounded-t-none
              tw-rounded-b
              tw-border
              tw-border-t-0
              tw-bg-white
            "
          >
            <!-- <div class="tw-text-sm tw-font-semibold">Grupos</div> -->
            <div class="tw-p-6">
              <h3 class="tw-text-md tw-font-semibold tw-text-gray-900">
                {{ $t('pages.event.chat.labels.support') }}
              </h3>
              <div v-if="hasSupportUser" class="tw-mt-6 tw-text-center">
                <p class="tw-mb-0 tw-font-semibold tw-text-gray-900">
                  {{ $t('pages.event.chat.home.support_title') }}
                </p>
                <p class="tw-mt-2 tw-mb-4 tw-px-4 tw-text-sm tw-text-gray-700">
                  {{ $t('pages.event.chat.home.support_body') }}
                </p>
                <AppBtn
                  class="tw-mn-3"
                  color="secondary"
                  type="button"
                  @click="openSupport"
                >
                  {{ $t('pages.event.chat.home.support_cta') }}
                </AppBtn>
              </div>
            </div>
            <div v-if="false" class="tw-bg-gray-50 tw-p-6">
              <i18n
                path="support.phrase"
                tag="p"
                class="
                  tw-mb-0
                  tw-text-center
                  tw-text-sm
                  tw-leading-5
                  tw-text-gray-700
                "
              >
                <template #email>
                  <a :href="emailFomart">
                    {{ registry.support_email }}
                  </a>
                </template>
                <template #phone>
                  <a :href="wa_link" target="_blank">
                    +{{ registry.support_phone_number }}
                  </a>
                </template>
              </i18n>
            </div>
          </div>
        </div>
        <!-- Module notes -->
        <div
          v-if="home_tab == tabs[5].name"
          key="module_notes"
          class="tw-z-10 tw-h-full"
        >
          <QuickNotes :notes="quick_notes"/>
        </div>
      </transition>
      <!-- <div
        class="tw-z-10 tw-flex tw-items-center tw-justify-center tw-p-8 tw-bg-white tw-border tw-rounded"
      >
        Lorem ipsum dolor sit,
      </div> -->
      <!-- <div
                class="tw-z-10 tw-flex tw-items-center tw-justify-center tw-p-8 tw-bg-white tw-border tw-rounded"
              >
                this is a card
              </div> -->
    </div>
  </div>
</template>

<script>
import RenderlessFilterRepsHandle from '@/components/Event/RenderlessFilterRepsHandle.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import { mapFields } from '@/helpers'
import { mapGetters, mapState } from 'vuex'
import UsersListItem from './UsersListItem'
import UsersList from './UsersList'
import { log } from '@/utils/logging'
import QuickNotes from '@/components/Notes/QuickNotes.vue'

// const tabs = [
//   { key: 0, name: 'ONLINE', label: 'online', active: false, live: true },
//   { key: 1, name: 'PRIVATE', label: 'private', active: false, live: true },
//   { key: 2, name: 'CHANNEL', label: 'channel', active: false, live: true },
//   { key: 3, name: 'GROUPS', label: 'group', active: false, live: false },
//   { key: 4, name: 'SUPPORT', label: 'support', active: false, live: true },
//   { key: 5, name: 'NOTES', label: 'notes', active: false, live: true },
// ]

export default {
  components: {
    AppBtn,
    RenderlessFilterRepsHandle,
    UsersListItem,
    UsersList,
    QuickNotes,
},
  data: () => ({
    // people,
    // tabs,
    tab_select: null,
    usersData: [],
  }),
  computed: {
    tabs() {
      return [
        { key: 0, name: 'ONLINE', label: 'online', active: false, live: this.isRealtimeEnabled },
        { key: 1, name: 'PRIVATE', label: 'private', active: false, live: this.isRealtimeEnabled },
        { key: 2, name: 'CHANNEL', label: 'channel', active: false, live: this.globalChannels.length !== 0 },
        { key: 3, name: 'GROUPS', label: 'group', active: false, live: false },
        { key: 4, name: 'SUPPORT', label: 'support', active: false, live: this.registry.has_support },
        { key: 5, name: 'NOTES', label: 'notes', active: false, live: true },
      ]
    },
    hasSupportUser() {
      return this.registry.support_user_id
    },
    emailFomart() {
      return `mailto:${this.registry.support_email}`
    },
    wa_msg() {
      return this.$i18n.t('support.msg', {
        name: `${this.sessionUser.first_name} ${this.sessionUser.last_name}`,
        event: this.registry.label,
      })
    },
    wa_link() {
      return `https://${
        this.$vuetify.breakpoint.smAndDown ? 'api' : 'web'
      }.whatsapp.com/send?phone=${this.registry.support_phone_number}&text=${
        this.wa_msg
      }`
    },
    ...mapState({
      sessionUser: (state) => state.session.user,
      firstName: (state) => state.session.user.first_name,
      active_chats: (state) => state.chat.active_chats,
      users: (state) => state.event.socket.users,
      virtual_room: (state) => state.event.show.data,
      globalChannels: (state) => state.event.socket.globalChannels,
      current_user: (state) => state.session.user,
      registry: (state) => state.event.constants.registry,
      virtualRoomChannels: (state) => state.event.show.data.channels,
      quick_notes: (state) => state.event.constants.quick_notes,
      support_user: (state) => state.event.constants.support_user,
    }),
    ...mapGetters({
      isRealtimeEnabled: ['event/isRealtimeEnabled'],
    }),
    hasChats() {
      return this.filteredUsers.length > 0
    },
    actives_chats_uuids() {
      return this.active_chats
    },
    filteredUsers() {
      return this.users
        .filter((row) => row.id !== this.current_user.id)
        .filter((row) => this.actives_chats_uuids.includes(row.uuid))
    },
    computedTabs() {
      return this.tabs
        .filter((row) => row.live) // Filtra solo los tabs con `live` en `true`
        .map((row) => ({
          ...row,
          active: row.name === this.home_tab,
          label: this.$i18n.t(`pages.event.chat.labels.${row.label}`),
        }))

      // return tabs
      //   .filter((row) => {
      //     if (row.name == 'CHANNEL') {
      //       return this.globalChannels.length !== 0
      //     }
      //     if (row.name == 'SUPPORT') {
      //       return this.registry.has_support
      //     }

      //     return true
      //   })
      //   .filter((row) => row.live)
      //   .map((row) => ({
      //     ...row,
      //     active: row.name === this.home_tab,
      //     label: this.$i18n.t(`pages.event.chat.labels.${row.label}`),
      //   }))
    },

    ...mapFields({
      fields: ['home_tab', 'view', 'public_channel'],
      base: ['chat'],
      mutation: 'chat/setState',
    }),
  },
  mounted() {
    const active_tab = this.tabs.find((row) => row.name == this.home_tab)
    this.tab_select = active_tab.key
  },
  methods: {
    toUserList() {
      this.$store.commit('chat/setState', {
        view: 'ACTIVE_USERS',
      })
      this.$store.commit('event/setSocket', {
        filters: [],
      })
    },
    enterPublicChannel(globalChannel) {
      console.log({ globalChannel })
      this.view = 'ACTIVE_PUBLIC_CHANNEL'
      this.public_channel = globalChannel
    },
    viewAttendee(user) {
      log({ user })
      this.$store.commit('event/setCard', { attendee: user, modal: true })
    },
    talkToPerson(user) {
      log(user.id)
      this.$store.commit('chat/setState', { view: 'CHAT', talking_to: user })
    },
    openSupport() {
      const user = this.isRealtimeEnabled ? this.$store.getters['event/checkUser'](this.hasSupportUser) : this.support_user 
      this.$store.commit('chat/setState', { view: 'CHAT', talking_to: user })
    },
  },
}
</script>
<style lang="scss">
.bz-box-rep {
  .bz-next-icon {
    transition: all 0.3s;
    opacity: 0;
  }
  &:hover {
    .bz-next-icon {
      opacity: 1;
    }
  }
}
.bz-scroll {
  // &::-webkit-scrollbar {
  //     width: 2px;
  // }
  /* width */
  &::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }
}
</style>