import { myTz } from '@/utils/myTz'
import axios from 'axios'
import nprogress from 'nprogress'
import store from './store'
const $nprogress = nprogress.configure({ showSpinner: false })
// https://forum.vuejs.org/t/accessing-axios-in-vuex-module/29414/3
// https://codeburst.io/create-http-client-instance-with-axios-in-vue-da8c12c779c2
const $axios = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_LARAVEL_BACKEND_PROD
      : process.env.VUE_APP_LARAVEL_BACKEND_DEV,
})
$axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

$axios.interceptors.request.use((config) => {
  $nprogress.start()
  return config
})

$axios.interceptors.response.use(
  (response) => {
    $nprogress.done()
    return response
  },
  (error) => {
    $nprogress.done()
    if (error.response.status === 401) {
      store.dispatch('session/axiosLogout')
    }
    return Promise.reject(error)
  },
)

$axios.interceptors.request.use((config) => {
  const token = store.getters['session/token']
  const jwt_token = store.getters['session/jwt_token']
  config.headers['X-TZ'] = myTz()
  config.headers['X-Tenant'] = store.state.tenant
  config.headers['X-LOCALE'] = store.getters['language/locale']
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
    config.headers['X-JWT-ACCESS-TOKEN'] = `Bearer ${jwt_token}`
  }

  return config
})

export default $axios
