import './bootstrap'
import './filters'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueApollo from 'vue-apollo'
import vuetify from './plugins/vuetify'
import Embed from 'v-video-embed'
import LazyTube from 'vue-lazytube'
import VueMeta from 'vue-meta'

// Start sentry
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import './tw.css'
import vueVimeoPlayer from 'vue-vimeo-player'
import adapter from 'webrtc-adapter'
import VueGtag from 'vue-gtag'
import VueHead from 'vue-head'

Vue.use(Embed)
Vue.use(vueVimeoPlayer)
Vue.use(VueMeta)
Vue.use(VueHead)
Vue.use(LazyTube)

console.log(
  `Browser ${adapter.browserDetails.browser} - version ${adapter.browserDetails.version}`,
)

import RenderlessLayout from '@/layouts/RenderlessLayout.vue'
import VueCompositionAPI, { provide } from '@vue/composition-api'

import VueKonva from 'vue-konva'

import VueDraggable from 'vue-draggable'
import { apolloClient } from './apollo-client'
import i18n from './i18n'

Vue.use(VueCompositionAPI)
Vue.use(VueDraggable)
Vue.use(VueKonva)
Vue.use(VueApollo)

Vue.component('RenderlessLayout', RenderlessLayout)
Vue.config.productionTip = false

if (process.env.NODE_ENV === 'production') {
  Vue.use(
    VueGtag,
    {
      config: {
        id: process.env.VUE_APP_GA || 'G-8LQYXM3B37',
      },
      pageTrackerUseFullPath: true,
    },
    router,
  )

  Sentry.init({
    Vue,
    dsn: 'https://01d17419fda545d1a3c38005d20c26db@o1008655.ingest.sentry.io/5974796',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          'localhost',
          'localhost:8000',
          'bizz-dome.com',
          'intelcorp.xyz',
          'vercel.app',
          'nucliuz.com',
          /^\//,
        ],
      }),
    ],
    logErrors: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
  Sentry.setTag('web_app_version', process.env.VUE_APP_VERSION)
}

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})
new Vue({
  setup() {
    provide('vuex-store', store)
  },

  router,
  store,
  vuetify,
  apolloProvider,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
