<template>
  <div class="tw-pointer-events-none tw-fixed tw-inset-0 tw-z-50">
    <MobileNavDrawer
      v-model="mobileNavDrawer"
      @on-nav-click="navItemClickEvent"
    />
    <div
      class="tw-pointer-events-none tw-flex tw-p-4 md:tw-items-end md:tw-justify-start md:tw-p-6"
    >
      <div class="tw-w-full tw-max-w-sm">
        <!-- <div class="tw-relative"> -->
        <v-fab-transition>
          <!-- <a target="_blank" class="tw-pointer-events-auto"> -->
          <v-btn
            fab
            dark
            absolute
            bottom
            right
            class="tw-pointer-events-auto tw-bottom-4 tw-outline-none focus:tw-ring focus:tw-ring-primary-300"
            @click="mobileNavDrawer = true"
          >
            <v-icon>menu</v-icon>
            <!-- <img
                        src="@/assets/icons/ico-01.svg"
                        max-height="24"
                        contain
                        class="d-flex"
                        alt="John"
                      /> -->
          </v-btn>
          <!-- </a> -->
        </v-fab-transition>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import MobileNavDrawer from '@/components/Event/MobileNavDrawer'

export default {
  components: {
    MobileNavDrawer,
  },
  data: () => ({
    mobileNavDrawer: false,
  }),
  methods: {
    navItemClickEvent(nav_item) {
      this.$emit('on-nav-click', nav_item)
    },
  },
}
</script>

<style></style>
