<template>
  <div class="tw-py-4 tw-px-6">
    <ul class="tw-pl-0">
      <EventRoomContentElementChatThreadItemComment
        :has-line="true"
        :question-answer="item"
      />
      <EventRoomContentElementChatThreadItemComment
        v-if="hasReply"
        :question-answer="item.reply"
      />
    </ul>
    <div v-if="canReply" class="tw-mt-6">
      <div class="tw-flex tw-space-x-3">
        <div class="tw-flex-shrink-0">
          <div class="tw-relative">
            <img
              class="tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-400 tw-ring-8 tw-ring-white"
              :src="current_user.avatar"
              alt=""
            />
            <span
              class="tw-absolute tw--bottom-0.5 tw--right-1 tw-rounded-tl tw-bg-white tw-px-0.5 tw-py-px"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="tw-h-5 tw-w-5 tw-text-gray-400"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="tw-min-w-0 tw-flex-1">
          <form @submit.prevent="submit">
            <div>
              <v-textarea
                v-model="body"
                :disabled="loading"
                auto-grow
                dense
                outlined
                hide-details=""
                solo
                filled
                class="tw-max-h-64 tw-overflow-y-auto"
                :placeholder="$t('app.msg_placeholder')"
                rows="3"
                @keydown.enter.exact.prevent
                @keyup.enter.exact="submit"
              />
            </div>
            <div
              class="tw-mt-6 tw-flex tw-items-center tw-justify-end tw-space-x-3"
            >
              <AppBtn platform color="white" :disabled="loading" type="submit">
                Responder
              </AppBtn>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeQuestionAnswer } from '@/graphql/mutations/threadMutations'
import { mapState } from 'vuex'
import { v4 } from '@/helpers'
import AppBtn from '../App/AppBtn.vue'
import { isObjectEmpty } from '@/utils/isObjectEmpty'
import EventRoomContentElementChatThreadItemComment from './EventRoomContentElementChatThreadItemComment.vue'
export default {
  components: { AppBtn, EventRoomContentElementChatThreadItemComment },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    threadId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    body: '',
    loading: false,
  }),
  computed: {
    canReply() {
      return (
        !this.hasReply &&
        this.current_user.roles.map((row) => row.name).includes('admin')
      )
    },
    hasReply() {
      return !isObjectEmpty(this.item.reply)
    },
    ...mapState({
      current_user: (state) => state.session.user,
    }),
  },
  methods: {
    submit() {
      if (this.body === '') {
        return
      }
      this.loading = true

      // uuid get established on the frontend -> adds little clock icon -> once emited overide message and take away icon
      const uuid = v4()
      this.$apollo
        .mutate({
          mutation: storeQuestionAnswer,
          variables: {
            content: {
              body: this.body,
              thread_id: this.threadId,
              reply_to_id: this.item.id,
              uuid,
            },
          },
        })
        .finally(() => {
          this.body = ''
          this.loading = false
        })
    },
  },
}
</script>

<style></style>
