import { departments } from '@/utils/Departments'
import { provinces } from '@/utils/Provinces'
import { districts } from '@/utils/Districts'


// Add new 'model_text' field when logging
export const addModelText = (payload) => {
    return payload.map((row) => ({
        ...row,
        model_text:
            row.name == 'departments' ? departments.find((dep) => dep.id == row.model).name : row.model &&
            row.name == 'provinces' ? provinces.find((prov) => prov.id == row.model).name : row.model &&
            row.name == 'districts' ? districts.find((dist) => dist.id == row.model).name : row.model,
    }))
}

// Validate that field is entered only number
export const onlyNumber = (evt) => {
    evt = (evt) ? evt : window.event
    let expect = evt.target.value.toString() + evt.key.toString()
    if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault()
    } else {
        return true
    }
}