<template>
  <div
    class="tw-bottom-0 tw-left-0 tw-right-0 tw-z-10 tw-block tw-flex-1 tw-transition-colors tw-duration-300 tw-ease-linear"
  >
    <div class="tw-relative tw-flex tw-h-full tw-w-full tw-justify-center">
      <div class="tw-absolute tw-inset-0 tw--mt-12">
        <div class="tw-absolute tw-inset-0" :style="color_gradient" />
      </div>
    </div>
  </div>
</template>

<script>
import { hexToRgb } from '@/utils/hexToRgb'
export default {
  props: {
    colors: {
      type: Object,
      default: () => ({
        '--ui_color': '#ffffff',
        '--header_color': '#ffffff',
        '--footer_color': '#ffffff',
        '--headers_color': '#ffffff',
      }),
    },
  },
  computed: {
    color_gradient() {
      const rbg_values = hexToRgb(this.colors['--footer_color'])
      const pass_through = `${rbg_values.r},${rbg_values.g},${rbg_values.b}`
      return {
        background: `linear-gradient(180deg, rgba(${pass_through},0) 5%, rgba(${pass_through},1) 20%, rgba(${pass_through},1) 35%)`,
      }
    },
  },
}
</script>

<style></style>
