import { Extension } from '@tiptap/core'
import '@tiptap/extension-text-style'

// declare module '@tiptap/core' {
//   interface Commands<ReturnType> {
//     fontFamily: {
//       /**
//        * Set the font family
//        */
//       setFontFamily: (fontFamily: string) => ReturnType,
//       /**
//        * Unset the font family
//        */
//       unsetFontFamily: () => ReturnType,
//     }
//   }
// }

export const FontSize = Extension.create({
  name: 'fontSize',

  addOptions: {
    types: ['textStyle'],
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            renderHTML: (attributes) => {
              if (!attributes.fontSize) {
                return {}
              }
              return {
                style: `font-size: ${attributes.fontSize}`,
              }
            },
            parseHTML: (element) =>
              element.style.fontSize.replace(/['"]+/g, ''),
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setFontSize:
        (fontSize) =>
        ({ chain }) => {
          return chain().setMark('textStyle', { fontSize }).run()
        },
      unsetFontSize:
        () =>
        ({ chain }) => {
          return chain()
            .setMark('textStyle', { fontSize: null })
            .removeEmptyTextStyle()
            .run()
        },
    }
  },
})
