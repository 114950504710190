import { AccessControlBase } from '@/models/AccessControl'
import { RegistryRegisterForm } from '@/models/RegistryForm'
import { RegistryLandingBase } from '@/models/RegistryLanding'
import { RegistryThankYouBase } from '@/models/RegistryThankYou'
import AccessControlsService, {
  AccessControlsServiceInstance,
} from '@/services/AccessControlsService'
import { HelperService } from '@/services/HelperService'
import { RegistriesConfirmScreensService } from '@/services/RegistriesConfirmScreensService'
import { RegistriesEmailService } from '@/services/RegistriesEmailService'
import { RegistriesFormsServiceInstance } from '@/services/RegistriesFormsService'
import { RegistriesLandingsServiceInstance } from '@/services/RegistriesLandingsService'
import { RegistriesLoginScreensService } from '@/services/RegistriesLoginScreensService'
import { RegistriesPhotoboothsService } from '@/services/RegistriesPhotoboothsService'
import { RegistriesServiceInstance } from '@/services/RegistriesService'
import { RegistriesThankYousService } from '@/services/RegistriesThankYousService'
import { RegistriesConfigsService } from '@/services/RegistriesConfigsService'
import RegistriesResource from '@/services/RegistriesResource'
// import { axiosDownloadable } from '@/utils/axiosDownloadable'
import { formErrorsHandler } from '@/utils/formErrorsHandler'
import { RegistryForm, RegistryObj, RegistryFormFromDB } from '@/utils/Registry'
import { RegistriesNavsService } from '@/services/RegistriesNavsService'
// TODO: Registry: Ticket - User
import { RegistriesRegistryTicket } from '@/services/RegistriesRegistryTicket'
import { RegistriesTicketUser } from '@/services/RegistriesTicketUser'
// TODO: Donovann -> actions
import { RegistriesActionsUsers } from '@/services/RegistriesActionsUsers'
import { RegistriesCalendarsService } from '@/services/RegistriesCalendarsService'
import { CalendarsItemsService } from '@/services/CalendarsItemsService'
import { UsersCredentialService } from '@/services/UsersCredentialService'
import { UsersCertificateService } from '@/services/UsersCertificateService'
const formatPayload = (data_source) =>
  Object.keys(data_source)
    .filter((row) => !(typeof data_source[row] === 'string'))
    .map((row) => ({ ...data_source[row], language: row }))
// state
const initState = () => {
  return {
    create: {
      hasErrors: false,
      errors: {},
      data: RegistryForm(),
    },
    show: {
      isLoading: true,
      data: RegistryObj(),
    },
    update: {
      data: RegistryForm(),
    },
    landings: {
      update: {
        data: {
          current_config_language_key: 'es',
          es: {},
          en: {},
        },
      },
    },
    forms: {
      update: {
        data: {
          current_config_language_key: 'es',
          es: {},
          en: {},
        },
      },
    },
    thank_yous: {
      update: {
        data: {
          current_config_language_key: 'es',
          es: {},
          en: {},
        },
      },
    },
    login_screens: {
      update: {
        data: {
          current_config_language_key: 'es',
          es: {},
          en: {},
        },
      },
    },
    confirm_screens: {
      update: {
        data: {
          current_config_language_key: 'es',
          es: {},
          en: {},
        },
      },
    },

    access_control: {
      show: {
        isLoading: true,
        data: {},
      },
    },
  }
}

// getters
const getters = {}

// actions
const actions = {
  updateConfigs({ commit, state }, { payload }) {
    const registry = state.show.data.id
    const event_confgs_state = payload
    const event_configs = Object.keys(event_confgs_state)
      .filter((row) => !(typeof event_confgs_state[row] === 'string'))
      .map((row) => ({ ...event_confgs_state[row], config_language: row }))

    const form = {
      event_configs,
      // global_config: state.global_event.update.data,
    }
    // transform data from keys object to array
    console.log(form)
    commit('setAppIsLoading', true, { root: true })
    // insert service
    return RegistriesConfigsService.store(registry, form)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  updateCalendars({ commit, state }, { payload }) {
    const registry = state.show.data.id
    // const event_confgs_state = payload
    // const event_configs = Object.keys(event_confgs_state)
    //   .filter((row) => !(typeof event_confgs_state[row] === 'string'))
    //   .map((row) => ({ ...event_confgs_state[row], config_language: row }))

    // const form = {
    //   event_configs,
    //   // global_config: state.global_event.update.data,
    // }
    // transform data from keys object to array
    // console.log(form)
    commit('setAppIsLoading', true, { root: true })
    // insert service
    return RegistriesCalendarsService.store(registry, payload)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  indexCalendars({ state }, { payload }) {
    const registry = state.show.data.id
    // commit('setAppIsLoading', true, { root: true })
    // insert service
    return RegistriesCalendarsService.index(registry, payload)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        // commit('setAppIsLoading', false, { root: true })
      })
  },
  storeCalendarsItems({ commit }, { payload, calendar }) {
    // const registry = state.show.data.id
    commit('setAppIsLoading', true, { root: true })
    // insert service
    return CalendarsItemsService.store(calendar, payload)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  storeCalendarsItemsAppointmet(_, { payload, calendar }) {
    return CalendarsItemsService.storeAppointment(calendar, payload)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  destroyCalendarItems({ commit }, { payload, id, calendar }) {
    // const registry 0= state.show.data.id
    commit('setAppIsLoading', true, { root: true })
    // insert service
    return CalendarsItemsService.destroy(calendar, id, payload)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  updateNavs({ state, commit }, { payload }) {
    // const nav_items = Object.keys(state.nav_items.update.data)
    //   .filter(
    //     (lang) => !(typeof state.nav_items.update.data[lang] === "string")
    //   )
    //   .filter((lang) => state.nav_items.update.data[lang].length > 0)
    //   .map((lang) => ({
    //     ...state.nav_items.update.data[lang],
    //     event_config_id: state.show.data.event_configs.find(
    //       (config) => config.config_language.name == lang
    //     ).id,
    //   }));
    // const { current_config_language_key } = state.update.data
    // const event_config_id = state.show.data.event_configs.find(
    //   (config) => config.config_language.name == current_config_language_key,
    // ).id

    // const form = {
    //   nav_items: state.nav_items.update.data[current_config_language_key],
    // }

    const { id } = state.show.data
    // console.log(event_config_id, form);
    // console.log(state.show.virtualRoom.id);
    commit('setAppIsLoading', true, { root: true })
    return RegistriesNavsService.store(id, payload)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  // TODO: Donovann -> Function updateTicket
  updateTicket({ state, commit }, { payload }) {
    const { id } = state.show.data
    commit('setAppIsLoading', true, { root: true })
    return RegistriesRegistryTicket.store(id, payload)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  // TODO: Donovann -> Function updateTicketUser
  updateTicketUser({ state }, { payload }) {
    const { id } = state.show.data
    return RegistriesTicketUser.store(id, payload)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  deleteTicketUser({ state }, { payload }) {
    const { id } = state.show.data
    return RegistriesTicketUser.delete(id, payload)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  // TODO: Donovann -> Function deleteUsers
  deleteUsers({ state }, { payload }) {
    const { id } = state.show.data
    return RegistriesActionsUsers.delete(id, payload)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  // TODO: Donovann -> Function Check-out
  checkout({ state }, { payload }) {
    const { id } = state.show.data
    console.log(id)
    return RegistriesActionsUsers.checkout(payload.registry_id, payload)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  exportParticipantsTemplate(
    {
      state: {
        show: {
          data: { id },
        },
      },
    },
    { payload },
  ) {
    return HelperService.exportRegistryAttendeesTemplate(id, payload)
      .then((res) => {
        // axiosDownloadable(res, 'participants_')
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  importParticipants(
    {
      state: {
        show: {
          data: { id },
        },
      },
    },
    { payload },
  ) {
    return HelperService.importRegistryAttendees(id, payload)
      .then((res) => {
        // axiosDownloadable(res, 'participants_')
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  exportParticipants(
    {
      state: {
        show: {
          data: { id },
        },
      },
    },
    { payload },
  ) {
    return HelperService.exportRegistryAttendees(id, payload)
      .then((res) => {
        // axiosDownloadable(res, 'participants_')
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  exportLeaderboard(
    {
      state: {
        show: {
          data: { id },
        },
      },
    },
    { payload },
  ) {
    return HelperService.exportRegistryLeaderboard(id, payload)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  exportCheckins(
    {
      state: {
        show: {
          data: { id },
        },
      },
    },
    { payload },
  ) {
    return HelperService.exportRegistryCheckins(id, payload)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  exportCheckinsRoom(
    _,
    { payload },
  ) {
    // console.log(payload)
    return HelperService.exportRegistryCheckinsRoom(payload.registry_id, payload)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  initUpdateForm({ commit, state }) {
    commit('setRegistriesUpdate', state.show.data)
  },
  initFormsUpdate({ commit, state }) {
    commit(
      'setFormsUpdateWrapper',
      state.show.data.forms.reduce(
        (obj, item) => ({
          ...obj,
          [item.language.name]: RegistryRegisterForm({
            ...item,
            fields: item.fields ? item.fields : [],
          }),
        }),
        {},
      ),
    )
  },
  initLoginScreens({ commit, state }) {
    commit(
      'setLoginScreensUpdateWrapper',
      state.show.data.login_screens.reduce(
        (obj, item) => ({
          ...obj,
          [item.language.name]: { image_url: item.image_url },
        }),
        {},
      ),
    )
  },
  initThankYous({ commit, state }) {
    console.log(state.show.data.thank_yous)
    commit(
      'setThankYousUpdateWrapper',
      state.show.data.thank_yous.reduce(
        (obj, item) => ({
          ...obj,
          [item.language.name]: RegistryThankYouBase({ ...item }),
        }),
        {},
      ),
    )
  },
  initConfirmScreens({ commit, state }) {
    commit(
      'setConfirmScreensUpdateWrapper',
      state.show.data.confirm_screens.reduce(
        (obj, item) => ({
          ...obj,
          [item.language.name]: RegistryThankYouBase({ ...item }),
        }),
        {},
      ),
    )
  },
  initLandingsUpdate({ commit, state }) {
    commit(
      'setLandingsUpdateWrapper',
      state.show.data.landings.reduce(
        (obj, item) => ({
          ...obj,
          [item.language.name]: RegistryLandingBase(item),
        }),
        {},
      ),
    )
  },
  indexPhotobooths({ state }, payload) {
    const registry = state.show.data.id
    return RegistriesPhotoboothsService.index(registry, payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  indexPhotoboothConfigs({ state }, payload) {
    const registry = state.show.data.id
    return RegistriesResource.PhotoboothConfigs(registry)
      .index(payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  storePhotoboothConfigs({ state }, payload) {
    const registry = state.show.data.id
    return RegistriesResource.PhotoboothConfigs(registry)
      .store(payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  updatePhotoboothConfigs({ state }, payload) {
    const registry = state.show.data.id
    return RegistriesResource.PhotoboothConfigs(registry)
      .update(payload.id, payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  index(context, payload) {
    return RegistriesServiceInstance.index(payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  // Get Crendetial to all users
  credentials(context, payload) {
    return UsersCredentialService.index(payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  certificates(context, payload) {
    return UsersCertificateService.index(payload)
      .then((res) => res)
      .catch((err) => {
        throw err
      })
  },
  store({ state, commit }) {
    const form = {
      ...state.create.data,
    }
    commit('setAppIsLoading', true, { root: true })

    return RegistriesServiceInstance.store(form)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  update({ state }) {
    const form = {
      ...state.update.data,
      _method: 'PUT',
    }
    // commit("setAppIsLoading", true, { root: true });
    return RegistriesServiceInstance.update(state.show.data.id, form)
      .then((res) => {
        // commit("cleanCreateData");
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        // commit("setAppIsLoading", false, { root: true });
      })
  },
  show({ commit, dispatch }, { id, loading = false, appLoading = false }) {
    if (loading) {
      commit('setShow', { isLoading: true })
    }
    if (appLoading) {
      commit('setAppIsLoading', true, { root: true })
    }
    return RegistriesServiceInstance.show(id)
      .then((res) => {
        commit('setShow', { data: res.data.data })
        dispatch('initUpdateForm')
        dispatch('initFormsUpdate')
        dispatch('initLandingsUpdate')
        dispatch('initLoginScreens')
        dispatch('initThankYous')
        dispatch('initConfirmScreens')
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setShow', { isLoading: false })
        commit('setAppIsLoading', false, { root: true })
      })
  },
  // forms
  updateForms({ state, commit }) {
    const registry_id = state.show.data.id
    const data_source = state.forms.update.data
    const forms = Object.keys(data_source)
      .filter((row) => !(typeof data_source[row] === 'string'))
      .map((row) => ({ ...data_source[row], language: row }))
    const form = {
      forms,
    }
    console.log(form)

    commit('setAppIsLoading', true, { root: true })
    return RegistriesFormsServiceInstance.store(registry_id, form)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  updateLoginScreens({ state, commit }) {
    const registry_id = state.show.data.id
    const form = {
      login_screens: formatPayload(state.login_screens.update.data),
    }
    console.log(form)

    commit('setAppIsLoading', true, { root: true })
    return RegistriesLoginScreensService.store(registry_id, form)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  updateThankYous({ state, commit }) {
    const registry_id = state.show.data.id
    const form = {
      thank_yous: formatPayload(state.thank_yous.update.data),
    }
    console.log(form)

    commit('setAppIsLoading', true, { root: true })
    return RegistriesThankYousService.store(registry_id, form)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  updateConfirmScreens({ state, commit }) {
    const registry_id = state.show.data.id
    const form = {
      confirm_screens: formatPayload(state.confirm_screens.update.data),
    }
    console.log(form)

    commit('setAppIsLoading', true, { root: true })
    return RegistriesConfirmScreensService.store(registry_id, form)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  updateEmails({ state }, { form = {} }) {
    const registry_id = state.show.data.id
    return RegistriesEmailService.store(registry_id, form)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
  updateLandings({ state, commit }) {
    const registry_id = state.show.data.id
    const data_source = state.landings.update.data
    const landings = Object.keys(data_source)
      .filter((row) => !(typeof data_source[row] === 'string'))
      .map((row) => ({ ...data_source[row], language: row }))
    const form = {
      landings,
    }
    console.log(form)
    commit('setAppIsLoading', true, { root: true })
    return RegistriesLandingsServiceInstance.store(registry_id, form)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },

  accessControlStrategyUpdate({ state }, { body }) {
    // const roles = state.create.data.roles
    const registry_id = state.show.data.id
    const form = {
      ...body,
    }
    // commit('setAppIsLoading', true, { root: true })
    return AccessControlsService.make()
      .strategyUpdate(registry_id, form)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        // commit('setAppIsLoading', false, { root: true })
      })
  },

  setAccessControlUpdateFormFromDB({ commit }, { access_control }) {
    const {
      target_string,
      control_type: { name: control_type_name },
      access_list_type: { name: access_list_type_name },
    } = access_control
    commit(
      'setAccessControlUpdate',
      AccessControlBase({
        access_list_type: access_list_type_name,
        control_type: control_type_name,
        target_string,
      }),
    )
  },
  accessControlShow(
    { state, commit },
    { id, loading = false, appLoading = false },
  ) {
    if (loading) {
      commit('setAccessControlShow', { isLoading: true })
    }
    if (appLoading) {
      commit('setAppIsLoading', true, { root: true })
    }
    const registry_id = state.show.data.id
    return AccessControlsService.make()
      .show(registry_id, id)
      .then(({ data: { access_control } }) => {
        commit('setAccessControlShow', { data: access_control })
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAccessControlShow', { isLoading: false })
        commit('setAppIsLoading', false, { root: true })
      })
  },
  accessControlImportAction({ state, commit }, { body = {} }) {
    // const roles = state.create.data.roles
    const registry_id = state.show.data.id
    commit('setAppIsLoading', true, { root: true })
    return AccessControlsService.make()
      .importAction(registry_id, body)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  accessControlStore({ state, commit }, { payload }) {
    // const roles = state.create.data.roles
    const registry_id = state.show.data.id
    const form = {
      ...payload,
    }
    commit('setAppIsLoading', true, { root: true })
    return AccessControlsServiceInstance.store(registry_id, form)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  accessControlUpdate({ state, commit }, { payload }) {
    const registry_id = state.show.data.id
    const access_control_id = state.access_control.show.data.id
    const form = {
      ...payload,
    }
    commit('setAppIsLoading', true, { root: true })
    return AccessControlsService.make()
      .update(registry_id, access_control_id, form)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
  accessControlDestroy({ state, commit }) {
    const registry_id = state.show.data.id
    const access_control_id = state.access_control.show.data.id
    commit('setAppIsLoading', true, { root: true })
    return AccessControlsService.make()
      .destroy(registry_id, access_control_id)
      .then((res) => res)
      .catch((err) => {
        throw formErrorsHandler(err)
      })
      .finally(() => {
        commit('setAppIsLoading', false, { root: true })
      })
  },
}

// mutations
const mutations = {
  cleanCreate(state) {
    state.create.data = RegistryForm()
  },
  setRegistriesUpdate(state, obj) {
    state.update.data = RegistryFormFromDB(obj)
  },
  setFormsUpdate(state, obj) {
    state.update.data = RegistryFormFromDB(obj)
  },
  setShow(state, obj) {
    Object.keys(obj).forEach((k) => (state.show[k] = obj[k]))
  },
  setCreate(state, obj) {
    Object.keys(obj).forEach((k) => (state.create.data[k] = obj[k]))
  },
  setUpdate(state, obj) {
    Object.keys(obj).forEach((k) => (state.update.data[k] = obj[k]))
  },
  setFormsUpdateWrapper(state, obj) {
    Object.keys(obj).forEach((k) => (state.forms.update.data[k] = obj[k]))
  },
  setLoginScreensUpdateWrapper(state, obj) {
    Object.keys(obj).forEach(
      (k) => (state.login_screens.update.data[k] = obj[k]),
    )
  },
  setConfirmScreensUpdateWrapper(state, obj) {
    Object.keys(obj).forEach(
      (k) => (state.confirm_screens.update.data[k] = obj[k]),
    )
  },
  setThankYousUpdateWrapper(state, obj) {
    Object.keys(obj).forEach((k) => (state.thank_yous.update.data[k] = obj[k]))
  },
  setLandingsUpdateWrapper(state, obj) {
    Object.keys(obj).forEach((k) => (state.landings.update.data[k] = obj[k]))
  },

  setFormsUpdateForm(state, obj) {
    const { current_config_language_key } = obj
    delete obj['current_config_language_key']
    Object.keys(obj).forEach(
      (k) => (state.forms.update.data[current_config_language_key][k] = obj[k]),
    )
  },
  setLoginScreensUpdateForm(state, obj) {
    const { current_config_language_key } = obj
    delete obj['current_config_language_key']
    Object.keys(obj).forEach(
      (k) =>
      (state.login_screens.update.data[current_config_language_key][k] =
        obj[k]),
    )
  },
  setConfirmScreensUpdateForm(state, obj) {
    const { current_config_language_key } = obj
    delete obj['current_config_language_key']
    Object.keys(obj).forEach(
      (k) =>
      (state.confirm_screens.update.data[current_config_language_key][k] =
        obj[k]),
    )
  },
  setThankYousUpdateForm(state, obj) {
    const { current_config_language_key } = obj
    delete obj['current_config_language_key']
    Object.keys(obj).forEach(
      (k) =>
        (state.thank_yous.update.data[current_config_language_key][k] = obj[k]),
    )
  },
  setLandingsUpdateForm(state, obj) {
    const { current_config_language_key } = obj
    delete obj['current_config_language_key']
    Object.keys(obj).forEach(
      (k) =>
        (state.landings.update.data[current_config_language_key][k] = obj[k]),
    )
  },

  cleanAccessControlCreate(state) {
    state.access_control.create.data = AccessControlBase({})
  },
  setAccessControlShow(state, obj) {
    Object.keys(obj).forEach((k) => (state.access_control.show[k] = obj[k]))
  },
  setAccessControlCreate(state, obj) {
    Object.keys(obj).forEach(
      (k) => (state.access_control.create.data[k] = obj[k]),
    )
  },
  setAccessControlUpdate(state, obj) {
    Object.keys(obj).forEach(
      (k) => (state.access_control.update.data[k] = obj[k]),
    )
  },
}

export const Registries = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
}
