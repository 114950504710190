export const LanguageKeys = {
  es: 'es',
  en: 'en',
}
// {
//   1: 'es',
//   2: 'en',
// }

export const LanguageValues = Object.keys(LanguageKeys).reduce(
  (acc, row, currentIndex) => ({
    ...acc,
    [currentIndex + 1]: row,
  }),
  {},
)
export const LanguageValuesInverse = Object.keys(LanguageKeys).reduce(
  (acc, row, currentIndex) => ({
    ...acc,
    [row]: currentIndex + 1,
  }),
  {},
)
