<template>
    <renderless-layout>
        <v-container id="forms_index_page" fluid class="tw-p-0">
            <StickyHeader :nav="nav">
                <template #actions>
                    <div class="tw-space-x-3">
                        <AppBtn icon="check" color="white" @click="createForm">
                            {{ $t('app.save') }}
                        </AppBtn>
                    </div>
                </template>
            </StickyHeader>
            <FormsBase 
                ref="customform"
                v-model="form"
            />
        </v-container>
    </renderless-layout>
</template>
<script>
import StickyHeader from '@/components/StickyHeader'
import AppBtn from '@/components/App/AppBtn.vue'
import { mapState } from 'vuex'
import FormsBase from '@/views/Admin/Forms/FormsBase.vue'
import { CustomForm } from '@/models/CustomForm'

export default {
    name: 'CustomFormsCreate',
    components: {
        AppBtn,
        StickyHeader,
        FormsBase,
    },
    data: () => ({
        fields: [],
        form: CustomForm({}),
    }),
    computed: {
        ...mapState({
            registry: (state) => state.registries.show.data,
        }),
        nav() {
            return [
                {
                    name: this.$t('app.event'),
                    route: { name: 'admin.registries.index' },
                },
                {
                    name: this.registry.label,
                    route: {
                        name: 'admin.registries.show.home',
                        params: { id: this.registry.id },
                    },
                },
                {
                    name: this.$t('admin.home.labels.forms'),
                    route: {
                        name: 'admin.registries.custom-forms.index',
                        params: { id: this.registry.id },
                    },
                },
                {
                    name: this.$t('app.create'),
                    route: {},
                },
            ]
        },
    },
    methods: {
        createForm() {
            this.$refs.customform.submitCreateForm()
        },
    },
}
</script>