<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="768px"
    content-class="tw-rounded-3xl"
  >
    <v-form
      ref="form"
      v-model="valid"
      :disabled="form_disabled_state"
      lazy-validation
      enctype="multipart/form-data"
      @submit.prevent="update"
    >
      <v-card class="tw-rounded-3xl tw-border-0 tw-shadow-md">
        <ModalHeader @close-modal="closeModal">{{
          $t('admin.events.items.sections.Logos')
        }}</ModalHeader>
        <v-divider />
        <v-card-text class="tw-p-0" style="max-height: 700px">
          <v-responsive class="tw-py-0">
            <v-container class="tw-bg-gray-50">
              <v-row class="mb-5">
                <v-col cols="12" class="mx-auto pa-5">
                  <v-card
                    class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-bg-transparent tw-shadow-none"
                  >
                    <v-container class="pa-5">
                      <div
                        class="tw-grid tw-grid-cols-1 tw-gap-4 lg:tw-grid-cols-2"
                      >
                        <div class="">
                          <v-text-field
                            v-model="mountedSection.data.title"
                            :rules="[rules.required]"
                            validate-on-blur
                            outlined
                            rounded
                            :label="$t('app.title')"
                          />
                        </div>
                        <div class="">
                          <v-select
                            v-model="mountedSection.data.grid"
                            :items="grid_items"
                            :rules="[rules.required]"
                            validate-on-blur
                            outlined
                            rounded
                            :label="$t('admin.app.cols_nums')"
                          />
                        </div>
                      </div>
                    </v-container>
                    <v-container
                      v-if="mountedSection.data.logos.length > 0"
                      class="pa-5"
                    >
                      <div>
                        <draggable
                          v-model="mountedSection.data.logos"
                          tag="div"
                          handle=".handle"
                          class="tw-overflow-hidden tw-bg-gray-100 tw-shadow sm:tw-rounded-3xl"
                          ghost-class="ghost"
                        >
                          <transition-group
                            type="transition"
                            :name="'flip-list'"
                            tag="ul"
                            class="tw-divide-y tw-divide-gray-200 tw-pl-0"
                          >
                            <li
                              v-for="logo in mountedSection.data.logos"
                              :key="logo.uid"
                              class="tw-flex tw-space-x-4 tw-px-4 tw-py-4 sm:tw-px-6"
                            >
                              <div class="tw-flex-1">
                                <DropzoneOverlayPreview
                                  v-model="logo.logo_url"
                                  :width="300"
                                  :height="150"
                                  media-group="registries_landings_logos"
                                />
                                <v-text-field
                                  v-model="logo.url_link"
                                  outlined
                                  rounded
                                  label="Link"
                                />
                              </div>
                              <div
                                class="tw-flex tw-flex-shrink-0 tw-flex-col tw-items-center tw-space-y-2"
                              >
                                <AppBtn
                                  color="transparent"
                                  icon-center="g-next"
                                  g-next="DragOrder"
                                  class="handle tw-cursor-move"
                                  fab
                                  small
                                  type="button"
                                />
                                <AppBtn
                                  color="transparent-danger"
                                  icon-center="close"
                                  fab
                                  small
                                  type="button"
                                  @click="deleteItem(logo)"
                                />
                              </div>
                            </li>
                          </transition-group>
                        </draggable>
                      </div>
                    </v-container>
                    <v-container>
                      <div class="tw-flex tw-justify-end">
                        <AppBtn
                          type="button"
                          icon="plus"
                          color="white"
                          @click="addLogo"
                        >
                          {{ $t('app.add') }}
                        </AppBtn>
                      </div>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-responsive>
        </v-card-text>

        <v-card-actions>
          <v-alert
            v-model="errorAlert"
            dismissible
            color="red"
            border="left"
            elevation="2"
            colored-border
            icon="error"
            transition="fade-transition"
          >
            {{ errorMsg }}
          </v-alert>
          <v-spacer />
          <AppBtn
            type="button"
            :disabled="form_loading"
            color="transparent"
            @click="closeModal"
          >
            {{ $t('app.cancel') }}
          </AppBtn>
          <AppBtn
            color="primary"
            :loading="form_loading"
            icon="check"
            type="submit"
          >
            {{ $t('app.save') }}
          </AppBtn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ModalHeader from '@/components/ModalHeader'
import draggable from 'vuedraggable'
import { grid_items, Logo } from '@/models/RegistryLanding'
import AppBtn from '../App/AppBtn.vue'
import DropzoneOverlayPreview from '../DropzoneOverlayPreview.vue'
import { rules } from '@/utils/rules'
export default {
  components: {
    ModalHeader,
    draggable,
    AppBtn,
    DropzoneOverlayPreview,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    grid_items: grid_items,
    isDragging: false,
    errorAlert: false,
    errorMsg: '',
    rules,
    isLoading: false,
    form_loading: false,
    form_disabled_state: false,
    valid: true,
    mountedSection: {},
    isLoadingRooms: false,
  }),
  computed: {
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  created() {
    this.mountedSection = JSON.parse(JSON.stringify(this.section))
  },
  methods: {
    deleteItem(logo) {
      this.mountedSection.data.logos = this.mountedSection.data.logos.filter(
        (row) => row.uid != logo.uid,
      )
    },
    addLogo() {
      this.mountedSection.data.logos = [
        ...this.mountedSection.data.logos,
        Logo(),
      ]
    },
    closeModal() {
      this.open = false
    },
    update() {
      this.errorAlert = false
      if (!this.$refs.form.validate()) {
        return
      }

      this.$emit('updated-section', this.mountedSection)
      this.open = false
    },
  },
}
</script>

<style></style>
