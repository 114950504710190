<template>
  <nav
    class="tw-h tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-z-10 tw-block tw-transition-colors tw-duration-300 tw-ease-linear"
  >
    <div class="tw-flex tw-w-full tw-items-center tw-justify-center">
      <!-- tw-absolute -->
      <v-card class="tw-flex tw-rounded-3xl tw-bg-white" :class="[negativeTop]">
        <v-container class="tw-p-0">
          <div class="tw-flex tw-items-end tw-justify-between tw-px-6">
            <RenderlessClickableHandle
              v-for="nav_item in navItems"
              :key="nav_item.uuid"
            >
              <template slot-scope="{ slotClickActionEvent }">
                <button
                  v-ripple
                  type="button"
                  class="tw-block tw-w-full tw-px-2 tw-pb-4 tw-text-center tw-transition tw-duration-300 focus:tw-outline-none focus:tw-ring focus:tw-ring-inset focus:tw-ring-gray-200 md:tw-flex md:tw-justify-center md:tw-px-4"
                  @click="slotClickActionEvent(nav_item)"
                >
                  <div
                    class="tw-flex tw-transform tw-flex-col tw-items-center tw-justify-center tw-space-y-0.5 tw-transition tw-duration-75 active:tw-scale-90"
                  >
                    <div class="tw-flex tw-justify-center">
                      <!-- 2xl:tw-w-18 2xl:tw-h-18 -->
                      <v-img
                        class="tw-h-12 tw-w-12 xl:tw-h-14 xl:tw-w-14"
                        :src="nav_item.image_url"
                      />
                      <!-- 'tw-w-18 tw-h-18': nav_item.size == 'lg', -->
                      <!-- 'tw-w-12 tw-h-12': nav_item.size == 'md',
                      'tw-w-6 tw-h-6': nav_item.size == 'sm', -->
                    </div>
                    <div>
                      <p
                        class="tw-mb-0 tw-block tw-whitespace-pre tw-text-xs tw-font-medium tw-leading-none tw-transition-colors tw-duration-300 tw-ease-linear md:tw-text-base md:tw-leading-none"
                      >
                        {{ nav_item.label }}
                      </p>
                    </div>
                  </div>
                </button>
              </template>
            </RenderlessClickableHandle>
          </div>
        </v-container>
      </v-card>
    </div>
  </nav>
</template>

<script>
import RenderlessClickableHandle from '@/components/Event/RenderlessClickableHandle.vue'
export default {
  components: {
    RenderlessClickableHandle,
  },
  props: {
    windowSize: {
      type: Object,
      default: () => ({}),
    },
    navItems: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    negativeTop() {
      // based on height

      const topClass = {
        1280: 'tw--top-6',
        1366: 'tw--top-8',
        1440: 'tw--top-6',
        1536: 'tw--top-10',
        1920: 'tw--top-12',
        2560: 'tw--top-14',
      }
      // return topClass[this.windowSize.x] || 'tw--top-14'

      if (this.windowSize.x >= 2560) {
        return topClass[2560]
      }
      if (this.windowSize.x >= 1920) {
        return topClass[1920]
      }
      if (this.windowSize.x >= 1536) {
        return topClass[1536]
      }
      if (this.windowSize.x >= 1440) {
        return topClass[1440]
      }
      if (this.windowSize.x >= 1366) {
        return topClass[1366]
      }
      if (this.windowSize.x >= 1280) {
        return topClass[1280]
      }
      return 'tw--top-14'
    },
  },
  methods: {},
}
</script>
