import { format } from 'date-fns'
export const appToday = () => {
  return format(new Date(), 'yyyy-MM-dd_hh_mm_a')
}
export const axiosDownloadable = (res, name) => {
  const url = window.URL.createObjectURL(new Blob([res.data]))
  const link = document.createElement('a')
  link.href = url
  const today = appToday()
  link.setAttribute('download', `${name}_${today}.xlsx`)
  document.body.appendChild(link)
  link.click()
}
