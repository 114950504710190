<template>
  <renderless-layout>
    <v-container fluid class="tw-p-0">
      <sticky-header>
        <template #title>
          <primary-title>
            {{ $t('admin.home.labels.events') }}
          </primary-title>
        </template>
        <template #actions>
          <div class="tw-flex tw-items-center">
            <AppBtn
              icon="plus"
              :to="{ name: 'admin.registries.create' }"
              color="white"
            >
              {{ $t('admin.events.new_event') }}
            </AppBtn>
          </div>
        </template>
      </sticky-header>
      <v-row class="tw-m-0 tw-p-0">
        <v-col cols="12" class="mx-auto tw-max-w-7xl">
          <v-container fluid class="tw-space-y-6 tw-py-6">
            <v-card class="tw-rounded-3xl tw-shadow-md" white>
              <v-container class="tw-p-0" fluid>
                <div class="tw-grid tw-border-b tw-pt-3 lg:tw-grid-cols-5">
                  <div class="tw-gap-6 tw-px-4 tw-pb-3 lg:tw-col-span-2">
                    <v-text-field
                      v-model="search"
                      outlined
                      rounded
                      prepend-inner-icon="search"
                      dense
                      :label="$t('app.search')"
                      hide-details
                    />
                  </div>
                </div>
              </v-container>
              <v-container class="tw-overflow-hidden tw-p-0" fluid>
                <v-data-table
                  v-model="selected"
                  :search="search"
                  :headers="headers"
                  :items="parsedRows"
                  :options.sync="options"
                  :server-items-length="totalRows"
                  :loading="loading"
                  class="elevation-1"
                  :footer-props="footer_props"
                  :class="{ mobile: $vuetify.breakpoint.mdAndDown }"
                >
                  <template #body="{ items }">
                    <transition mode="out-in" name="fade">
                      <tbody v-if="items.length" key="main-table">
                        <tr
                          v-for="item in items"
                          :key="item.id"
                          class="tw-cursor-pointer sm:tw-py-4"
                          @click="showItem(item)"
                        >
                          <td>
                            <div class="py-4 tw-flex tw-items-center">
                              <div class="tw-flex-shrink-0">
                                <StatusDot :active="item.isAvailable" />
                              </div>
                              <div class="tw-ml-2 tw-flex tw-items-center">
                                <h5
                                  class="tw-truncate tw-text-md tw-capitalize tw-font-medium tw-leading-6 tw-text-gray-900"
                                >
                                  {{ item.label }}
                                </h5>
                                <span
                                  v-if="!!item.is_default"
                                  class="tw-ml-1 tw-rounded-lg tw-bg-gray-500 tw-px-2 tw-py-0.5 tw-text-xs tw-uppercase tw-text-primary-100 tw-shadow-sm"
                                >
                                  Default
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="tw-flex tw-justify-end">
                              <span v-if="item.start_date_time" class="tw-text-xs tw-text-right">
                                {{ item.parsedStartDateTime }}
                              </span>
                              <p
                                v-else
                                class="mb-0 text-sm text-gray-500 tw-italic tw-text-xs"
                              >
                                {{ $t('admin.app.empty') }}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div class="tw-flex tw-justify-end">
                              <span v-if="item.end_date_time" class="tw-text-xs tw-text-right">
                                {{ item.parsedEndDateTime }}
                              </span>
                              <p
                                v-else
                                class="mb-0 text-sm text-gray-500 tw-italic tw-text-xs"
                              >
                                {{ $t('admin.app.empty') }}
                              </p>
                            </div>
                          </td>

                          <td>
                            <div class="tw-flex tw-justify-start">
                              <StatusBadge :is-active="!!item.is_active" />
                            </div>
                          </td>
                          <td>
                            <div class="tw-flex">
                              <span
                                class="tw-inline-flex tw-items-center tw-whitespace-nowrap tw-rounded-full tw-border tw-border-gray-300 tw-bg-white tw-px-2.5 tw-py-0.5 tw-text-xs tw-font-medium tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-50"
                              >
                                {{ item.slug }}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div
                              class="tw-flex tw-justify-end tw-whitespace-nowrap"
                            >
                              <span class="">
                                {{ item.parsedCreatedAt }}
                              </span>
                            </div>
                          </td>
                          <td>
                            <v-btn
                              fab
                              text
                              small
                              class="tw-text-gray-300 focus:tw-text-gray-600 focus:tw-outline-none focus:tw-ring-primary-500"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                class="tw-h-6 tw-w-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M9 5l7 7-7 7"
                                />
                              </svg>
                            </v-btn>
                          </td>
                        </tr>
                        <!-- mobile list view -->
                      </tbody>
                      <tbody v-if="!items.length && !loading" key="zero_table">
                        <tr>
                          <td colspan="8">
                            <v-alert
                              class="mx-8 mt-4"
                              color="primary"
                              border="left"
                              type="info"
                              elevation="2"
                              colored-border
                            >
                              <strong>{{ $t('$vuetify.noDataText') }}</strong>
                            </v-alert>
                          </td>
                        </tr>
                      </tbody>
                      <tbody
                        v-if="!items.length && loading"
                        key="loading_table"
                      >
                        <tr>
                          <td colspan="8">
                            <v-alert
                              class="mx-8 mt-4"
                              color="primary darken-1"
                              border="left"
                              type="success"
                              elevation="2"
                              colored-border
                            >
                              <strong>{{ $t('app.loading') }}</strong>
                            </v-alert>
                          </td>
                        </tr>
                      </tbody>
                    </transition>
                  </template>
                </v-data-table>
              </v-container>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </renderless-layout>
</template>

<script>
import PrimaryTitle from '@/components/Titles/PrimaryTitle.vue'
import StickyHeader from '@/components/StickyHeader.vue'
import StatusDot from '@/components/StatusDot.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import StatusBadge from '@/components/StatusBadge.vue'
import debounce from 'lodash/debounce'
import { commonTimeFormat, isHappening } from '@/utils/dateHelpers'
export default {
  components: { StickyHeader, PrimaryTitle, AppBtn, StatusBadge, StatusDot },
  data: () => ({
    search: '',
    selected: undefined,
    totalRows: 0,
    rows: [],
    loading: false,
    options: {},
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t('admin.app.name'),
          align: 'start',
          sortable: false,
          value: 'label',
        },
        {
          text: this.$t('admin.app.opens'),
          align: 'end',
          sortable: false,
          value: 'room_type',
        },

        {
          text: this.$t('admin.app.closes'),
          value: 'protein',
          align: 'end',
          sortable: false,
        },
        { text: 'Status', value: 'date_time_consult', sortable: true },
        {
          text: this.$t('admin.app.link'),
          value: 'status',
          sortable: false,
          align: 'start',
        },
        {
          text: this.$t('admin.app.updated_at'),
          value: 'calories',
          sortable: false,
          align: 'end',
        },
        { text: '', value: 'go', sortable: false },
      ]
    },
    parsedRows() {
      return this.rows.map((row) => ({
        ...row,
        isAvailable: row.start_date_time
          ? isHappening({
              start_date_time: row.start_date_time,
              end_date_time: row.end_date_time,
            })
          : false,
        parsedCreatedAt: commonTimeFormat(row.created_at),
        parsedStartDateTime: commonTimeFormat(row.start_date_time),
        parsedEndDateTime: commonTimeFormat(row.end_date_time),
      }))
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    footer_props() {
      const obj = {
        'items-per-page-options': [
          10, 20, 30, 40, 50,
          // { text: 'Todos', value: this.totalRows },
        ],
      }
      return obj
    },
  },
  watch: {
    options: {
      handler() {
        this.getRows()
      },
      deep: true,
    },
    filter_status: {
      handler() {
        this.getRows()
      },
      deep: true,
    },
    filter_speciality: {
      handler() {
        this.getRows()
      },
      deep: true,
    },
    filter_selected: {
      handler() {
        this.getRows()
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.getRows()
      }, 1000),
      deep: true,
      // handler() {
      //   this.getRows()
      // },
      // deep: true,
    },
  },
  methods: {
    showItem(a) {
      return this.$router.push({
        name: 'admin.registries.show.home',
        params: { id: a.id },
      })
      // return this.$router.push(`/pacient/consults/show/${a.uuid}`);
    },
    getRows() {
      this.loading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      //  let page_number = page.page || this.state.pagination.page;
      // let rowsPerPage = page.rowsPerPage || this.state.pagination.rowsPerPage;
      this.$store
        .dispatch('registries/index', {
          params: {
            page: page,
            rowsPerPage: itemsPerPage,
            sortBy: sortBy,
            sortDesc: sortDesc,
            search: this.search,
          },
        })
        .then((res) => {
          const { total, data } = res.data.data
          this.rows = data
          this.totalRows = total
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style></style>
