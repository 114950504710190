import $axios from '@/axios-instance.js'

class ValidateUrlServiceClass {
  baseUrl = '/api/v1/admin/check-iframe-headers'
  // Create
  execute(params) {
    return $axios.post(this.baseUrl, params)
  }
}
export const ValidateUrlService = new ValidateUrlServiceClass()
