<template>
  <v-dialog
    v-model="open"
    persistent
    max-width="768px"
    content-class="tw-rounded-3xl"
    scrollable
  >
    <v-form
      ref="form"
      v-model="valid"
      :disabled="form_disabled_state"
      lazy-validation
      enctype="multipart/form-data"
      @submit.prevent="update"
    >
      <v-card class="tw-rounded-3xl tw-border-0">
        <ModalHeader @close-modal="closeModal">{{
          $t('admin.events.items.sections.Call to Action')
        }}</ModalHeader>
        <v-divider />
        <v-card-text class="tw-p-0" style="max-height: 700px">
          <v-responsive class="tw-py-0">
            <v-container class="tw-bg-gray-100">
              <v-row class="mb-5">
                <v-col cols="12" class="mx-auto pa-5">
                  <v-card
                    class="mx-auto tw-divide-y tw-divide-gray-200 tw-rounded-3xl tw-shadow-md"
                  >
                    <v-container class="pa-5">
                      <div class="tw-grid tw-grid-cols-1 tw-gap-1">
                        <div class="">
                          <v-text-field
                            ref="new_exam"
                            v-model="mountedSection.data.title"
                            validate-on-blur
                            outlined
                            rounded
                            color="primary darken-1"
                            :label="$t('app.title')"
                          />
                        </div>

                        <div class="">
                          <EditorTipTap
                            v-model="mountedSection.data.body"
                            :label="$t('app.title')"
                            description="titulo del home del landing"
                          />
                        </div>
                      </div>
                    </v-container>
                    <v-container class="pa-5">
                      <div class="tw-grid tw-grid-cols-1 tw-gap-1">
                        <div>
                          <v-select
                            v-model="mountedSection.data.type_of_media"
                            rounded
                            :items="types_of_media"
                            outlined
                            :label="$t('admin.events.type_media')"
                          />
                        </div>
                        <div
                          v-if="mountedSection.data.type_of_media === 'image'"
                          key="hero_cta_image_url"
                        >
                          <dropzone-preview
                            v-model="mountedSection.data.image_url"
                            :width="1280"
                            :height="720"
                            media-group="registries_landings"
                          />
                        </div>
                        <div v-else key="hero_cta_video_url">
                          <v-text-field
                            v-model="mountedSection.data.video_url"
                            :rules="[rules.testEmpty]"
                            rounded
                            outlined
                            label="Url"
                          />
                        </div>
                      </div>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-responsive>
        </v-card-text>
        <v-divider />
        <v-container>
          <transition name="fade-transition" mode="out-in">
            <v-row v-if="errorAlert">
              <v-col cols="12">
                <v-alert
                  v-model="errorAlert"
                  dismissible
                  color="red"
                  border="left"
                  elevation="2"
                  colored-border
                  icon="error"
                  transition="fade-transition"
                >
                  {{ errorMsg }}</v-alert
                >
              </v-col>
            </v-row>
          </transition>
        </v-container>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            rounded
            :disabled="form_loading"
            class="tw-px-8 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-white"
            @click="closeModal"
          >
            {{ $t('app.cancel') }}</v-btn
          >
          <v-btn
            color="primary darken-1"
            rounded
            type="submit"
            :loading="form_loading"
            class="text--white text--lighten-4 tw-px-8 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-300"
          >
            {{ $t('app.save') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import EditorTipTap from '@/components/FormInputs/EditorTipTap.vue'
import ModalHeader from '@/components/ModalHeader'
import DropzonePreview from '@/components/DropzonePreview.vue'
import { types_of_media } from '@/models/RegistryLanding'
import { simpleObjectCopy } from '@/utils/simpleObjectCopy'
export default {
  components: {
    ModalHeader,
    EditorTipTap,
    // RadioBtn,
    DropzonePreview,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    types_of_media: types_of_media,
    errorAlert: '',
    errorMsg: '',
    exam: '',
    rules: {
      numericZero: (v) => !!parseFloat(v) || 'Campo no puede ser 0',
      testEmpty: (value) => !!value || 'Campo no puede estar vacio.',
      min: (v) => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => "The email and password you entered don't match",
    },
    isLoading: false,
    form_loading: false,
    form_disabled_state: false,
    valid: true,
    mountedSection: { data: {} },
    isLoadingRooms: false,
  }),
  computed: {
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  beforeMount() {
    // this.mountedSection = Object.assign({}, this.section)
    this.mountedSection = simpleObjectCopy(this.section)
  },
  methods: {
    closeModal() {
      this.open = false
    },
    update() {
      this.errorAlert = false
      if (!this.$refs.form.validate()) {
        return
      }

      this.$emit('updated-section', this.mountedSection)
      this.open = false
    },
  },
}
</script>

<style></style>
