<template>
  <div class="tw-w-full">
    <template v-if="activeQRScanner">
      <video ref="qr-video" class="tw-h-full tw-w-full" />
      <div class="tw-px-2 tw-py-4">
        <v-select 
          v-model="camara" class="tw-text-sm" label="Camera" :items="camaras" outlined rounded dense
          hide-details @change="onUpdate" />
      </div>
    </template>
    <template v-else>
      <div class="tw-w-full bz-qrscann">
        <AppBtn color="primary" type="button" class="tw-px-5 tw-text-xs" @click="openQrScanner">
          <v-icon left>
            qr_code_scanner
          </v-icon>
          {{ $t('admin.home.labels.scann') }}
        </AppBtn>
      </div>
    </template>
  </div>
</template>

<script>
import QrScanner from 'qr-scanner'
import AppBtn from '../App/AppBtn.vue'
import throttle from 'lodash/throttle'
export default {
  components: { AppBtn },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    camaras: [],
    camara: '',
    has_checked: false,
    qrScanner: null,
    activeQRScanner: false,
  }),
  methods: {
    activeqrscann() {
      this.openQrScanner()
    },
    onUpdate(val) {
      if (!this.qrScanner) {
        return
      }
      this.qrScanner.setCamera(val)
    },
    updateHasChecked() {
      this.has_checked = false
    },
    cleanUp() {
      if (!this.qrScanner) {
        return
      }
      this.qrScanner.destroy()
      this.qrScanner = null
      this.activeQRScanner = false
    },
    openQrScanner() {
      this.activeQRScanner = true
      this.has_checked = false
      this.$nextTick(function () {
        QrScanner.listCameras(true).then((camaras) => {
          const videoElem = this.$refs['qr-video']
          this.camaras = camaras.map((row) => ({
            text: row.label,
            value: row.id,
          }))
          this.camara = this.camaras[0].value
          this.qrScanner = new QrScanner(
            videoElem,
            throttle((result) => {
              if (this.has_checked) {
                return
              }
              if (!result.data.includes(window.location.origin)) {
                return
              }
              const url = new URL(result.data)
              const pathname = url.pathname
              this.has_checked = true
              this.$emit('validate-user', { pathname })
            }),
            {
              highlightScanRegion: true,
              preferredCamera: this.camaras[0].value,
            },
          )
          this.qrScanner.start()
        })
      })
    },
  },
}
</script>

<style>
.bz-qrscann {
  background: black;
  height: 169px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
