<template>
  <h5
    class="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900"
    :class="textColor"
  >
    <slot />
  </h5>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    textColor() {
      return this.dark ? 'tw-text-gray-50' : 'tw-text-gray-900'
    },
  },
}
</script>

<style></style>
