import $axios from '@/axios-instance.js'
import $axios_s from '@/axios-silent.js'

class SearchAutocompleteServiceClass {
  baseUrl = '/api/v1/admin/search'

  virtualRooms(params) {
    return $axios.get(`${this.baseUrl}/virtual-rooms`, params)
  }

  threads(params) {
    return $axios.get(`${this.baseUrl}/threads`, params)
  }

  channels(params) {
    return $axios.get(`${this.baseUrl}/channels`, params)
  }

  contentElements(params) {
    return $axios.get(`${this.baseUrl}/content-elements`, params)
  }

  contentLibrariesTags(uuid, params) {
    return $axios.get(`${this.baseUrl}/content-libraries/${uuid}/tags`, params)
  }

  users(params) {
    return $axios.get(`${this.baseUrl}/users`, params)
  }

  // Donovann
  usersAll(params){
    return $axios_s.get(`${this.baseUrl}/users-all`, params)
  }
  // End Donovann

  photoboothConfigs(params) {
    return $axios.get(`${this.baseUrl}/photobooth-configs`, params)
  }

  formsAll(params) {
    return $axios.get(`${this.baseUrl}/custom-forms`, params)
  }
}
export const SearchAutocompleteService = new SearchAutocompleteServiceClass()
// export default SearchAutocompleteService
