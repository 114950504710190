import $axios from '@/axios-instance.js'

class LeaderboardStatsServiceClass {
  baseUrl = '/api/v1/admin/leaderboard-stats'

  // Read all - findAll
  index(params) {
    return $axios.get(this.baseUrl, params)
  }

  // Read one - show
  show(id, params) {
    return $axios.get(this.baseUrl + `/${id}`, params)
  }
}

export const LeaderboardStatsService = new LeaderboardStatsServiceClass()
