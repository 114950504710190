<template>
    <v-dialog
        v-model="open"
        scrollable
        :width="640"
        persistent
        content-class="tw-rounded-3xl"
    >
        <v-form
            ref="form"
            v-model="valid"
            :disabled="form_loading"
            lazy-validation
            enctype="multipart/form-data"
            @submit.prevent="onSubmit"
        >   
            <v-card class="tw-relative tw-rounded-3xl tw-border-0 tw-shadow-md">
                <ModalHeader @close-modal="closeModal">
                <!-- Exportar participantes -->
                {{ $t('app.attendees_actions.labels.export') }}
                </ModalHeader>
                <v-card-text>
                    <div class="tw-space-y-3">
                        <CustomFormsAutocomplete 
                            v-model="form.custom_form_id"
                        />
                    </div>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <AppBtn
                        type="submit"
                        :loading="form_loading"
                        color="green-download"
                        icon="download_cloud"
                    >
                        {{ $t('app.export') }}
                    </AppBtn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import { myTz } from '@/utils/myTz'
import ModalHeader from '@/components/ModalHeader.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import CustomFormsAutocomplete from '@/components/CustomFormsAutocomplete.vue'
import { mapState } from 'vuex'
import { axiosDownloadable } from '@/utils/axiosDownloadable'

export default {
    components: {
        AppBtn,
        ModalHeader,
        CustomFormsAutocomplete,
        // TimezonesAutocomplete,
        // DateInput,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        form_loading: false,
        valid: true,
        form: {
            custom_form_id: '',
            registry_id: '',
            start_date: '',
            start_time: '',
            end_date: '',
            end_time: '',
            tz: myTz(),
        },
    }),
    computed: {
        ...mapState({
            registry: (state) => state.registries.show.data,
        }),
        open: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
    methods: {
        closeModal() {
            this.open = false
        },
        onSubmit() {
            if (!this.$refs.form.validate()) {
                return
            }
            this.form_loading = true
            this.$store
                .dispatch('forms/export', { 
                    payload: {
                        ...this.form,
                        registry_id: this.registry.id,
                    },
                })
                .then((res) => {
                    axiosDownloadable(res, 'custom_form_')
                })
                .catch((err) => {
                    this.$store.dispatch('notifications/addNotification', {
                        title: 'Error exportando',
                        body: err,
                    })
                })
                .finally(() => {
                    this.form_loading = false
                })
        },
    },
}
</script>