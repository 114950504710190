<script>
import { matchCols } from '@/utils/formCols'
import { mapState } from 'vuex'
import { rules } from '@/utils/rules'
import DepartmentsAutocomplete from '@/components/DepartmentsAutocomplete'
import ProvincesAutocomplete from '@/components/ProvincesAutocomplete'
import DistrictsAutocomplete from '@/components/DistrictsAutocomplete'
import UsersAutocomplete from '@/components/UsersAutocomplete.vue'
import { addModelText, onlyNumber } from '@/utils/Settings'

export default {
    components: {
        DepartmentsAutocomplete,
        ProvincesAutocomplete,
        DistrictsAutocomplete,
        UsersAutocomplete,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        form_loading: false,
        matchCols,
        rules,
        custom_form_submission: [],
        itemsProvinces: null,
        itemsDistritcs: null,
        onlyNumber,
        isloading: false,
        valid: true,
    }),
    computed: {
        commonProps() {
            return (overrides = {}) => {
                return {
                    outlined: true,
                    validateOnBlur: true,
                    required: true,
                    rounded: true,
                    hideDetails: overrides.hideDetails !== undefined ? overrides.hideDetails : true,
                }
            }
        },
        ...mapState({
            custom_form: (state) => state.event.customform.form,
            current_language_key: (state) => state.registry.show.current_language_key,
            current_user: (state) => state.session.user.id,
            virtual_room: (state) => state.event.show.data.id,
            registry: (state) => state.event.constants.registry.id,
        }),
        titleForm() {
            return this.custom_form.title
        },
        descriptionForm() {
            return this.custom_form.description ? this.custom_form.description : ''
        },
        labelButton() {
            if (this.custom_form.type_form === 'bookings') {
                return 'Reservar'
            } else {
                return 'Enviar'
            }
        },
        open: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
    mounted() {
        this.initForm()
    },
    methods: {
        initForm() {
            const current_custom_form = JSON.parse(JSON.stringify(this.custom_form))
            this.custom_form_submission = current_custom_form.fields
        },
        listProvinceByDepartments(e) {
            this.itemsProvinces = e
        },
        listDistricByProvinces(e) {
            this.itemsDistritcs = e
        },
        submitForm() {
            if (!this.$refs.form.validate()) {
                return
            }

            const payload = {
                custom_form_id: this.custom_form.id,
                user_to_id: this.custom_form_submission.find((row) => row.field_type === 'domain')?.model ?? null,
                virtual_room_id: this.virtual_room,
                user_from_id: this.current_user,
                registry_id: this.registry,
                form_submission: addModelText(this.custom_form_submission),
                language: this.current_language_key,
            }

            this.isloading = true

            return this.$store.dispatch('forms/entrieCustomForm', payload)
                .then(() => {
                    this.alert(
                        this.titleForm,
                        'Tu información ha sido ingresada correctamente',
                        'success',
                    )
                })
                .catch((err) => {
                    this.alert(
                        this.titleForm,
                        err,
                        'error',
                    )
                })
                .finally(() => {
                    this.isloading = false
                    this.open = false
                })
        },
        alert(title, body, status) {
            this.$store.dispatch('notifications/addNotification', {
                title: title,
                body: body,
                status: status,
            })
        },
    },
}
</script>
<template>
    <v-dialog 
        v-model="open" 
        scrollable 
        max-width="600px" 
        persistent 
        content-class="tw-rounded-3xl tw-w-full"
    >
        <v-form
            ref="form" 
            v-model="valid" 
            lazy-validation 
            autocomplete="off" 
            :disabled="isloading" 
            @submit.prevent="submitForm"
        >
            <v-card class="fill-height tw-rounded-xl tw-border-0 tw-shadow-md">
                <!-- ====== Close ====== -->
                <v-container class="tw-p-0 tw-shadow-md tw-z-10" fluid>
                    <div class="tw-relative tw-rounded-t-xl tw-bg-primary-500 tw-pl-5">
                        <div class="tw-flex tw-w-full tw-items-center tw-justify-between tw-p-3">
                            <span class="tw-text-white tw-text-lg tw-font-bold">{{ titleForm }}</span>
                            <v-btn
                                fab
                                x-small
                                class="focus:tw-outline-none focus:tw-ring"
                                @click="open = false"
                            >
                                <v-icon>close</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-container>
                <v-card-text class="fill-height tw-border-0 tw-py-6 tw-relative">
                    <div 
                        v-if="descriptionForm" 
                        class="tw-pb-4 bz-desc-form"
                    >
                        {{ descriptionForm }}
                    </div>
                    <div class="tw-grid tw-gap-4 tw-grid-cols-12">
                        <div 
                            v-for="field in custom_form_submission" 
                            :key="field.uid" 
                            class="tw-col-span-12" 
                            :class="matchCols[field.cols]"
                        >
                            <!-- ====== Text Field ====== -->
                            <template v-if="field.field_type == 'input'">
                                <v-text-field 
                                    v-model="field.model" 
                                    :label="field.label" 
                                    :name="field.name"
                                    :rules="field.is_required ? [rules.required] : []" 
                                    type="text"
                                    v-bind="commonProps()"
                                />
                            </template>
                            <!-- ====== Text Area ====== -->
                            <template v-if="field.field_type == 'textarea'">
                                <v-textarea 
                                    v-model="field.model" 
                                    :label="field.label" 
                                    :rules="field.is_required ? [rules.required] : []"
                                    :name="field.name" 
                                    v-bind="commonProps()"
                                />
                            </template>
                            <!-- ====== Radio Buttons ====== -->
                            <template v-if="field.field_type == 'radio'">
                                <v-radio-group 
                                    v-model="field.model" 
                                    class="tw-mt-0" 
                                    :label="field.label" 
                                    :rules="field.is_required ? [rules.required] : []"
                                >
                                    <v-radio 
                                        v-for="option in field.options" 
                                        :key="option.uid" 
                                        :label="option.text"
                                        :value="option.value"
                                    />
                                </v-radio-group>
                            </template>
                            <!-- ====== Checkbox ====== -->
                            <template v-if="field.field_type == 'checkbox'">
                                <div>
                                    <label for="first_name" class="tw-block tw-text-sm tw-font-medium tw-text-slate-600">
                                        {{ field.label }}
                                    </label>
                                    <v-checkbox 
                                        v-for="option in field.options" 
                                        :key="option.uid"
                                        v-model="field.model_multiple" 
                                        :rules="field.is_required ? [rules.array_not_empty] : []" 
                                        :label="option.text" 
                                        :value="option.value"
                                        hide-details 
                                    />
                                </div>
                            </template>
                            <!-- ====== Select ====== -->
                            <template
                                v-if="field.field_type == 'select' && field.label != 'Departments'
                                && field.label != 'Provinces'
                                && field.label != 'Districts'"
                            >
                                <v-select 
                                    v-model="field.model" 
                                    :label="field.label" 
                                    :rules="field.is_required ? [rules.required] : []" 
                                    :items="field.options" 
                                    v-bind="commonProps()"
                                />
                            </template>
                            <!-- ====== Number ====== -->
                            <template v-if="field.field_type == 'number'">
                                <v-text-field 
                                    v-model="field.model" 
                                    :label="field.label" 
                                    :name="field.name"
                                    :rules="field.is_required ? [rules.required] : []"
                                    :counter="!!field.characters" 
                                    :maxlength="field.characters"
                                    v-bind="commonProps({ hideDetails: field.characters ? false : true })"
                                    @keypress="onlyNumber"
                                />
                            </template>
                            <!-- ====== Date ====== -->
                            <template v-if="field.field_type == 'date'">
                                <v-text-field 
                                    v-model="field.model" 
                                    :label="field.label" 
                                    :name="field.name"
                                    :rules="field.is_required ? [rules.required] : []" 
                                    type="date" 
                                    v-bind="commonProps()"
                                />
                            </template>
                            <!-- ====== Departments ====== -->
                            <template v-if="field.field_type == 'select' && field.label == 'Departments'">
                                <DepartmentsAutocomplete 
                                    v-model="field.model"
                                    :rules="field.is_required ? [rules.required] : []"
                                    hide-details
                                    @change="listProvinceByDepartments" 
                                />
                            </template>
                            <!-- ====== Provinces ====== -->
                            <template v-if="field.field_type == 'select' && field.label == 'Provinces'">
                                <ProvincesAutocomplete 
                                    v-model="field.model" 
                                    :search="itemsProvinces"
                                    :rules="field.is_required ? [rules.required] : []"
                                    hide-details
                                    @change="listDistricByProvinces"
                                />
                            </template>
                            <!-- ====== Districts ====== -->
                            <template v-if="field.field_type == 'select' && field.label == 'Districts'">
                                <DistrictsAutocomplete 
                                    v-model="field.model" 
                                    :search="itemsDistritcs"
                                    :rules="field.is_required ? [rules.required] : []" 
                                    hide-details
                                />
                            </template>
                            <!-- ====== Upload ====== -->
                            <template v-if="field.field_type == 'upload'">
                                <v-file-input
                                    v-model="field.model" 
                                    :label="field.label" 
                                    :name="field.name"
                                    :rules="field.is_required ? [rules.required] : []"
                                    show-size
                                    truncate-length="15"
                                    v-bind="commonProps()"
                                />
                            </template>
                            <!-- ====== Representatives ====== -->
                            <template v-if="field.field_type == 'domain'">
                                <UsersAutocomplete
                                    v-model="field.model"
                                    :label="field.label"
                                    :name="field.name"
                                    :rules="field.is_required ? [rules.required] : []"
                                    :multiple="false"
                                    :use-notes="true"
                                    filter-name="representative"
                                    hide-details
                                />
                            </template>
                        </div>
                    </div>
                    <div v-if="isloading" class="tw-absolute tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center tw-top-0 tw-left-0 tw-backdrop-blur-sm">
                        <v-progress-circular
                            :size="50"
                            color="primary"
                            indeterminate
                        />
                    </div>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        rounded
                        type="submit"
                        class="text--white text--lighten-4 tw-px-8 tw-normal-case tw-font-bold focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-300"
                    >
                        {{ labelButton }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>