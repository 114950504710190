export const AppCoreVitalKeys = {
  EventInit: 'event_init',
  EventNagivation: 'event_navigation',
  EventStillInRoom: 'event_still_in_room',
  EventClick: 'event_click',
  EventContent: 'event_content',
  EventVideoChat: 'event_video_chat',
  EventFinalized: 'event_finalized',
}
export const AppCoreVitalLabels = {
  event_init: 'Inicio Sesion',
  event_navigation: 'Navego a sala',
  event_still_in_room: 'Sigue en sala',
  event_click: 'Click en item',
  event_content: 'Tiene Contenido abierto',
  event_video_chat: 'Video Llamada',
  event_finalized: 'Finalizo navegacion',
}
