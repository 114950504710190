export const openWindowTab = (url) => {
  const win = window.open(url, '_blank')
  if (win) {
    //Browser has allowed it to be opened
    win.focus()
  } else {
    //Browser has blocked it
    alert('Please allow popups for this website')
  }
  // Object.assign(document.createElement('a'), {
  //   target: '_blank',
  //   href: url,
  // }).click()
}
