<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="640px"
    content-class="tw-rounded-3xl tw-w-full"
  >
    <v-form
      ref="form"
      v-model="valid"
      :disabled="form_loading"
      lazy-validation
      enctype="multipart/form-data"
      @submit.prevent="submitEvent"
    >
      <v-card class="tw-relative tw-rounded-3xl tw-border-0 tw-shadow-md">
        <ModalHeader @close-modal="closeModal">
          {{ $t('admin.app.import') }}
        </ModalHeader>
        <v-divider />
        <v-card-text class="tw-p-0" style="max-height: 700px">
          <v-responsive class="tw-py-0">
            <v-container fluid>
              <v-row class="mb-5">
                <v-col cols="12" class="mx-auto pa-5">
                  <ul class="tw-divide-y tw-divide-gray-200 tw-pl-0">
                    <li
                      class="tw-items-center tw-py-4 sm:tw-flex sm:tw-justify-between"
                    >
                      <div class="tw-flex tw-flex-col">
                        <!-- <p
                        id="privacy-option-1-label"
                        class="
                          tw-text-sm tw-font-medium tw-text-gray-900 tw-mb-0
                        "
                      >
                        Restabler todas las reglas
                      </p> -->
                        <h3
                          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          {{ $t('admin.app.list') }}
                        </h3>
                        <p
                          id="privacy-option-1-description"
                          class="tw-mb-0 tw-text-sm tw-text-gray-500"
                        >
                          {{ $t('admin.map.list_description') }}
                        </p>
                      </div>

                      <div
                        class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
                      >
                        <v-select
                          v-model="form.access_list_type"
                          :items="access_list_types"
                          label="Lista"
                          outlined
                          :disabled="form_strategy_loading"
                          rounded
                          hide-details=""
                          dense
                          class="tw-w-64"
                        />
                      </div>
                    </li>
                    <li
                      class="tw-items-center tw-py-4 sm:tw-flex sm:tw-justify-between"
                    >
                      <div class="tw-flex tw-flex-col">
                        <h3
                          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          {{ $t('admin.app.type') }}
                        </h3>
                        <p
                          id="privacy-option-1-description"
                          class="tw-mb-0 tw-max-w-xs tw-text-sm tw-text-gray-500"
                        >
                          {{ $t('admin.map.import_description') }}
                        </p>
                      </div>

                      <div
                        class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center"
                      >
                        <!-- active-class="tw-bg-primary-500 tw-text-gray-50" -->
                        <!-- active-class="primary" -->
                        <BtnGroup
                          v-model="form.upload_type"
                          :label="$t('app.type')"
                          :options="[
                            {
                              text: UploadTypeLabels.file,
                              value: UploadTypeKeys.file,
                            },
                            {
                              text: UploadTypeLabels.list,
                              value: UploadTypeKeys.list,
                            },
                          ]"
                        />
                      </div>
                    </li>
                  </ul>
                  <div
                    v-if="isUploadTypeFile"
                    class="tw-mt-10 tw-divide-y tw-divide-gray-200"
                  >
                    <div class="tw-space-y-1">
                      <h3
                        class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        {{ $t('admin.map.file_label') }}
                      </h3>
                      <p
                        class="tw-mb-0 tw-max-w-2xl tw-text-sm tw-text-gray-500"
                      >
                        {{ $t('admin.map.import_description') }}
                      </p>
                    </div>
                    <div class="tw-mt-2">
                      <dl class="tw-divide-y tw-divide-gray-200">
                        <div
                          class="tw-py-4 sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-4 sm:tw-py-5"
                        >
                          <dt
                            class="tw-sr-only tw-text-sm tw-font-medium tw-text-gray-500"
                          />
                          <dd
                            class="tw-mt-1 tw-items-center tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0 sm:tw-flex sm:tw-justify-between"
                          >
                            <span class="tw-flex-grow">
                              <v-file-input
                                v-model="form.file"
                                counter
                                label="File"
                                rounded
                                prepend-icon=""
                                prepend-inner-icon="attach_file"
                                placeholder="Select your files"
                                outlined
                                :show-size="1000"
                              >
                                <template #selection="{ index, text }">
                                  <v-chip v-if="index < 2" dark label small>
                                    {{ text }}
                                  </v-chip>

                                  <span
                                    v-else-if="index === 2"
                                    class="text-overline grey--text text--darken-3 mx-2"
                                  >
                                    +{{ form.file.length - 2 }} File(s)
                                  </span>
                                </template>
                              </v-file-input>
                            </span>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>

                  <div
                    v-if="isUploadTypeList"
                    class="tw-mt-10 tw-divide-y tw-divide-gray-200"
                  >
                    <div class="tw-space-y-1">
                      <h3
                        class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        {{ $t('admin.map.commas_label') }}
                      </h3>
                      <p class="tw-max-w-2xl tw-text-sm tw-text-gray-500">
                        {{ $t('admin.map.commas_separated') }}
                      </p>
                    </div>

                    <ul class="tw-mt-2 tw-divide-y tw-divide-gray-200 tw-pl-0">
                      <li
                        class="tw-items-start tw-py-4 sm:tw-flex sm:tw-justify-between"
                      >
                        <div class="tw-flex tw-w-full tw-flex-col">
                          <v-textarea
                            v-model="form.emails"
                            validate-on-blur
                            :rules="[
                              rules.required,
                              rules.commaSeparatedEmails,
                              rules.uniqueCommaSeparatedItems,
                            ]"
                            rounded
                            outlined
                            :label="$t('admin.app.emails')"
                            class="tw-w-full"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </v-col>
              </v-row>

              <transition name="fade-transition" mode="out-in">
                <v-row v-if="errorAlert">
                  <v-col cols="12">
                    <v-alert
                      v-model="errorAlert"
                      dismissible
                      color="red"
                      border="left"
                      elevation="2"
                      colored-border
                      icon="error"
                      transition="fade-transition"
                    >
                      {{ errorMsg }}
                    </v-alert>
                  </v-col>
                </v-row>
              </transition>
            </v-container>
          </v-responsive>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <AppBtn
            color="transparent"
            :disabled="form_loading"
            @click="closeModal"
          >
            {{ $t('app.cancel') }}
          </AppBtn>
          <AppBtn color="primary" type="submit">
            {{ $t('admin.app.import') }}
          </AppBtn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rules } from '@/utils/rules'
import { makeFormData } from '@/utils/makeFormData'

import ModalHeader from '@/components/ModalHeader'
import { mapGetters, mapState } from 'vuex'
import AppBtn from '@/components/App/AppBtn.vue'
import BtnGroup from '@/components/FormInputs/BtnGroup.vue'
const UploadTypeKeys = {
  list: 'list',
  file: 'file',
}
const AccessControlFormBody = () => ({
  emails: '',
  file: null,
  access_list_type: 'black_list',
  upload_type: 'list',
})
export default {
  components: {
    ModalHeader,
    AppBtn,
    BtnGroup,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    files: [],
    UploadTypeKeys,
    form: AccessControlFormBody(),
    errorAlert: '',
    errorMsg: '',
    exam: '',
    rules,
    isLoading: false,
    form_loading: false,
    form_strategy_loading: false,
    valid: true,
    mountedStrategy: {
      access_list_type: { name: '' },
    },
    access_list_type: '',
    isLoadingRooms: false,
  }),
  computed: {
    UploadTypeLabels() {
      return {
        list: this.$t('admin.app.list'),
        file: this.$t('admin.app.file'),
      }
    },
    isUploadTypeList() {
      return this.form.upload_type == UploadTypeKeys.list
    },
    isUploadTypeFile() {
      return this.form.upload_type == UploadTypeKeys.file
    },
    saveDisabled() {
      if (this.form_strategy_loading) {
        return true
      }
      return this.access_list_type == this.mountedStrategy.access_list_type.name
    },
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    ...mapGetters({
      access_list_types: ['constants/access_list_types'],
    }),

    ...mapState({
      show: (state) => state.registries.show.data,
    }),
  },

  methods: {
    closeModal() {
      // this.$store.dispatch('registries/initUpdateForm')
      this.open = false
    },
    submitEvent() {
      this.errorAlert = false
      this.form_loading = true
      this.form_strategy_loading = true
      const formData = makeFormData(this.form)
      this.$store
        .dispatch('registries/accessControlImportAction', {
          body: formData,
        })
        // .then(({ data }) => {
        //   console.log(data)
        //   // const { data } = res.data
        //   return this.$store.dispatch('registries/show', {
        //     id: this.show.id,
        //   })
        // })
        .then((res) => {
          // this.$router.push({
          //   name: 'admin.configs.access-control',
          //   params: { id: this.show.id },
          // })
          this.$emit('import-success')
          this.form = AccessControlFormBody()
          console.log('after show', { res })
          this.open = false
        })
        .catch((err) => {
          this.errorAlert = true
          this.errorMsg = err
        })
        .finally(() => {
          this.form_strategy_loading = false
          this.form_loading = false
        })
    },
  },
}
</script>
