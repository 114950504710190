<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    max-width="1366px"
    content-class="tw-rounded-3xl tw-w-full"
  >
    <v-card class="tw-relative tw-rounded-3xl tw-border-0 tw-shadow-md">
      <modal-header v-if="false">{{ iframeItemUrl }}</modal-header>
      <v-divider />
      <v-card-text class="tw-p-0">
        <v-responsive class="tw-py-0">
          <v-container id="iframe-wrapper" class="tw-bg-gray-100 tw-p-0" fluid>
            <iframe
              v-if="iframeItemType == 'link'"
              ref="iframe"
              :src="iframeItemUrl"
              frameborder="0"
              class="tw-w-full"
              allow="camera *; geolocation *; microphone *; autoplay *;fullscreen *;payment *;"
              sandbox="allow-scripts allow-same-origin allow-scripts allow-popups allow-forms allow-pointer-lock allow-top-navigation-by-user-activation allow-top-navigation	allow-presentation allow-popups-to-escape-sandbox "
            />
            <iframe
              v-else
              ref="iframe_srcdoc"
              :srcdoc="iframeItemHtml"
              frameborder="0"
              class="tw-w-full"
            />
          </v-container>
        </v-responsive>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          rounded
          class="tw-px-8 tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-white"
          @click="closeModal"
        >
          {{ $t('app.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ModalHeader from '@/components/ModalHeader'
import { mapFields } from '@/helpers'

export default {
  components: {
    ModalHeader,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loaded: false,
    iframe: {
      height: '800px',
      style: null,
      wrapperStyle: null,
    },
  }),
  computed: {
    ...mapFields({
      fields: ['iframeItemHtml', 'iframeItemUrl', 'iframeItemType'],
      base: ['event', 'iframeItem'],
      mutation: 'event/setIframeItem',
    }),
    ...mapFields({
      fields: [
        'has_time_bracket',
        'label',
        'slug',
        'start_date',
        'start_time',
        'end_date',
        'end_time',
        'time_bracket_tz',
        'has_password',
        'can_export_connections',
      ],
      base: ['registries', 'update', 'data'],
      mutation: 'registries/setUpdate',
    }),
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  mounted() {
    setTimeout(() => {
      console.log('log')
      if (this.$refs.iframe) {
        this.$refs.iframe.style.height = '640px'
        this.$refs.iframe.style.opacity = '1'
        this.$refs.iframe.parentElement.style.height = 'auto'
      } else {
        console.log(this.$refs.iframe_srcdoc.contentWindow.bodyHeight)
        this.$refs.iframe_srcdoc.style.height =
          this.$refs.iframe_srcdoc.contentWindow.bodyHeight + 'px'
        this.$refs.iframe_srcdoc.style.opacity = '1'
        this.$refs.iframe_srcdoc.parentElement.style.height = 'auto'
      }
    }, 100)
  },

  methods: {
    closeModal() {
      this.open = false
    },
  },
}
</script>

<style></style>
