import { MediaServiceInstance } from '@/services/MediaService'
import { formErrorsHandler } from '@/utils/formErrorsHandler'

const baseUrl =
  process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_LARAVEL_BACKEND_PROD
    : process.env.VUE_APP_LARAVEL_BACKEND_DEV
// state
const endpoint = `${baseUrl}/api/v1/actions/media-elements/dz`
const s3_signed_endpoint = `${baseUrl}/api/v1/actions/media-elements/signed-s3-url`

const initState = () => {
  return {
    dropzone: {
      config: {
        url: endpoint,
        thumbnailWidth: 200,
        // maxFilesize: 2,
        addRemoveLinks: true,
        headers: { Authorization: 'Bearer value' },
      },
    },
  }
}

// getters
const getters = {
  dz_config: (state, getters, rootState) => {
    return {
      ...state.dropzone.config,
      headers: {
        Authorization: `Bearer ${rootState.session.token}`,
        'X-Tenant': rootState.tenant,
        'Cache-Control': null,

        'X-Requested-With': null,
      },
    }
  },
  awss3: (state, getters, rootState) => {
    return {
      signingURL: s3_signed_endpoint,
      headers: {
        Authorization: `Bearer ${rootState.session.token}`,
        'X-Tenant': rootState.tenant,
      },
      params: {},
      sendFileToServer: false,
      withCredentials: false,
    }
  },
}

// actions
const actions = {
  storeDrop(context, payload) {
    return MediaServiceInstance.storeDrop(payload)
      .then((res) => {
        // commit("cleanCreateData");
        return res.data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
    // .finally(() => {
    // commit("setAppIsLoading", false, { root: true });
    // });
  },
  commitStorage(_, payload) {
    return MediaServiceInstance.commitStorage(payload)
      .then((res) => {
        // commit("cleanCreateData");
        return res.data
      })
      .catch((err) => {
        throw formErrorsHandler(err)
      })
  },
}

// mutations
const mutations = {}

export const MediaElements = {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
}
