<template>
    <v-container class="fill-height tw-flex tw-items-start tw-overflow-y-scroll tw-bg-gray-100" fluid>
        <v-row id="aforoMod" class="tw-py-2">
            <v-col cols="12">
                <div class="tw-mx-auto tw-max-w-lg tw-space-y-6 tw-px-4 sm:tw-px-6 md:tw-max-w-5xl lg:tw-px-8">
                    <v-card class="tw-rounded-3xl tw-shadow-md">
                        <v-simple-table>
                            <template #default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            {{ $t('bz2.event.mod.room') }}
                                        </th>
                                        <th class="text-left">
                                            {{ $t('bz2.event.mod.quantity') }}
                                        </th>
                                        <th class="text-left">
                                            {{ $t('app.option') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(group, index) in usersForRoom" :key="index">
                                        <td>{{ index }}</td>
                                        <td>{{ group.length }} {{ $t('bz2.event.mod.person') }}</td>
                                        <td>
                                            <AppBtn
                                                color="green-download"
                                                type="button"
                                                icon="download_cloud"
                                                class="tw-px-5 tw-text-xs"
                                                small
                                                @click="downloadReport(index)"
                                            >
                                                {{ $t('admin.enums.download') }}
                                            </AppBtn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapFields, groupBy } from '@/helpers'
import { mapState } from 'vuex'
import AppBtn from '@/components/App/AppBtn.vue'
import { axiosDownloadable } from '@/utils/axiosDownloadable'
export default {
    name: 'ModAforo',
    components: {
        AppBtn,
    },
    data: () => ({
    }),
    computed: {
        ...mapFields({
            fields: ['users', 'usersLocation'],
            base: ['event', 'socket'],
            mutation: 'event/setSocket',
        }),
        ...mapState({
            current_user: (state) => state.session.user,
            current_lang_key: (state) => state.event.constants.event_configs.data.current_config_language_key,
            virtual_rooms: (state) => state.event.constants.virtual_rooms,
            registry: (state) => state.event.constants.registry,
        }),
        usersHybrid() {
            return this.users.map((row) =>
                row.attendees.filter(
                (item) =>
                    item.language.name === this.current_lang_key &&
                    item.current_checkin_id !== null &&
                    item.current_checkout == null,
                ),
            ).reduce((acc, cur) => {
                return acc.concat(cur)
            }, [])
        },
        usersForRoom() {
            const new_group_user_room = groupBy(
                this.usersHybrid.map((row) => ({
                    ...row,
                    virtual_room_id: row.current_checkin.virtual_room_id,
                    virtual_room: this.virtual_rooms.find((vr) => vr.id === row.current_checkin.virtual_room_id).label,
                })),
                'virtual_room',
            )
            // console.log('Group Virtual Room',this.virtual_rooms)
            return new_group_user_room
        },
        
    },
    mounted() {
    },
    methods: {
        nameRoom(data) {
            this.virtual_rooms.find((vr) => vr.id === data)?.label
        },
        downloadReport(virtual_room) {
            const room = this.virtual_rooms.find((vr) => vr.label === virtual_room).id
            this.$store.dispatch('registries/exportCheckinsRoom', {
                payload: {
                    virtual_room_id: room,
                    registry_id: this.registry.id,
                },
            })
            .then((res) => {
                console.log(res)
                axiosDownloadable(res, 'check-ins_')
            })
            .catch((err) => {
                this.$store.dispatch('notifications/addNotification', {
                    title: 'Error exportando',
                    body: err,
                })
            })
        },
    },
}
</script>

<style lang="scss" scoped>

</style>