export const ContentTypeKeys = {
  audio: 'audio', // |sound
  download: 'download', //may open modal |document
  link: 'link', // may open modal |link
  video: 'video', // will open video modal with chat hability |video
  webinar: 'webinar', // |live
  doorway: 'doorway', // |go
  question_and_answer: 'qna', // |questions
  iframe: 'iframe', // trigger modal |web
}
