import { v4 } from '@/helpers'
import { ClickableBase, setClickableFromDB } from '@/models/Clickable'
import { DurationBase } from '@/models/Duration'
import { VirtualRoomElementContentElementBase } from '@/models/VirtualRoomElementContentElement'
import { VirtualRoomElementSlideBase } from '@/models/VirtualRoomElementSlide'
import { element_icons } from '@/utils/ElementTypes'
import {
  dd_mm_yyyy,
  has_time_bracket,
  hh_mm,
  time_bracket_type,
} from './dateHelpers'
const returnNameFromObject = (obj) => {
  return obj ? obj.name : ''
}
const BaseRoomElementModel = ({
  x = 10,
  y = 10,
  scaleX = 100,
  scaleY = 100,
  responsive_x = 10,
  responsive_y = 10,
  responsive_width = 100,
  responsive_height = 100,
  desktop_x = 10,
  desktop_y = 10,
  desktop_width = 100,
  desktop_height = 100,
  element_type_label,
  element_type_icon,
  element_type_color,
  element_type_name,

  uuid = v4(),
  item_label = '',
  breakpoints = ['desktop', 'mobile'],
  // image_item
  image_item_url = '',
  image_item_original = '',
  image_item_cropped = '',
  // doorway_item
  doorway_item_type = '',
  doorway_item_room_type = '',
  doorway_item_room_id = '',
  // video_item
  video_item_type = 'youtube',
  video_item_style = 'embed',
  video_item_preview = '',
  video_item_url = '',
  content_element_id = '',
  // slider_item
  slider_item_timer = '',
  slider_item_slides = [],
  // iframe_item
  iframe_item_type = 'link',
  iframe_item_html = '',
  iframe_item_url = '',
  // clickable
  clickable = ClickableBase({}),
  // content window
  content_item_elements = [],
  // chat
  has_channel = false,
  has_channel_id = '',
  has_thread = false,
  has_thread_id = '',
  is_autoplay = false,
  rotation = 0,
}) => {
  if (
    !element_type_label ||
    !element_type_icon ||
    !element_type_color ||
    !element_type_name
  ) {
    throw 'Missing attributes'
  }
  return {
    // ! base room element unmodified

    // stroke: 'black',
    // strokeWidth: 1,
    rotation,
    opacity: 0.75,
    draggable: true,
    width: 1,
    height: 1,
    // * dimentions
    x,
    y,
    scaleX,
    scaleY,
    responsive_x,
    responsive_y,
    responsive_width,
    responsive_height,
    desktop_x,
    desktop_y,
    desktop_width,
    desktop_height,
    breakpoints,
    // ! reqruired value
    name: uuid,
    // throwable
    kmjk_name: element_type_label,
    kmjk_image: element_type_icon,
    fill: element_type_color,
    kmjk_type: element_type_name,
    element_type: element_type_name,
    // generic
    uuid,
    item_label,
    // image_item
    image_item_url,
    image_item_original,
    image_item_cropped,
    // doorway_item
    doorway_item_type,
    doorway_item_room_type,
    doorway_item_room_id,
    // video_item
    video_item_type,
    video_item_style,
    video_item_preview,
    video_item_url,
    content_element_id,
    // slide_item
    slider_item_timer,
    slider_item_slides,
    // iframe_item
    iframe_item_url,
    iframe_item_type,
    iframe_item_html,
    // clickable_item
    clickable,
    // content window
    content_item_elements,
    // chat
    has_channel,
    has_channel_id,
    has_thread,
    has_thread_id,
    // Autoplay
    is_autoplay,
  }
}
export const RoomElementModel = ({ kmjk_type, color, text, icon }) => {
  return BaseRoomElementModel({
    element_type_label: text,
    element_type_icon: icon,
    element_type_color: color,
    element_type_name: kmjk_type,
  })
}
/**
 *
 *
 * @param {Object} DB Oject
 * @returns BaseRoomElementModel
 */
export const DBRoomElementModel = ({
  responsive_x,
  responsive_y,
  responsive_width,
  responsive_height,
  desktop_x,
  desktop_y,
  desktop_width,
  desktop_height,
  element_type,
  uuid,
  label: item_label,
  image_item_url,
  image_item_cropped,
  doorway_item_room_id,
  doorway_item_type: doorway_item_type_obj,
  doorway_item_room_type: doorway_item_room_type_obj,
  breakpoints,
  // vide_item
  video_item_url,
  video_item_type: video_item_type_obj,
  video_item_style: video_item_style_obj,
  video_item_preview,
  content_element_id,
  // slider_item
  slider_item_timer,
  slider_item_slides: slider_item_slides_collection,
  // iframe_item
  iframe_item_url,
  iframe_item_type: iframe_item_type_obj,
  iframe_item_html,
  // clickable_item
  clickable: clickable_obj,
  // content window
  content_item_elements,
  // channels
  channels,
  // threads
  threads,

  is_autoplay,
  rotation,
}) => {
  const { color, name: kmjk_type, label: text } = element_type
  const icon = element_icons[element_type.name]
  const doorway_item_type = returnNameFromObject(doorway_item_type_obj)
  const doorway_item_room_type = returnNameFromObject(
    doorway_item_room_type_obj,
  )
  const video_item_type = returnNameFromObject(video_item_type_obj)
  const video_item_style = returnNameFromObject(video_item_style_obj)
  const iframe_item_type = returnNameFromObject(iframe_item_type_obj)

  const slider_item_slides = slider_item_slides_collection
    ? slider_item_slides_collection.map((row) => {
        const {
          uuid,
          label,
          image_cropped,
          image_url,
          duration: duration_obj,
          clickable: clickable_obj,
        } = row
        const duration = duration_obj
          ? DurationBase({
              has_time_bracket: has_time_bracket(
                duration_obj.start_date_time,
                duration_obj.end_date_time,
              ),
              time_bracket_type: time_bracket_type(
                duration_obj.start_date_time,
                duration_obj.end_date_time,
              ),
              time_bracket_tz: duration_obj.time_bracket_tz,
              start_date: dd_mm_yyyy(duration_obj.start_date_time),
              start_time: hh_mm(duration_obj.start_date_time),
              end_date: dd_mm_yyyy(duration_obj.end_date_time),
              end_time: hh_mm(duration_obj.end_date_time),
            })
          : DurationBase({})
        const clickable = clickable_obj
          ? setClickableFromDB(clickable_obj)
          : ClickableBase({})
        console.log(clickable)
        return VirtualRoomElementSlideBase({
          uuid,
          label,
          image_cropped,
          image_url,
          duration,
          clickable,
        })
      })
    : []

  const clickable = clickable_obj
    ? setClickableFromDB(clickable_obj)
    : ClickableBase({})

  const has_channel_id = channels.length > 0 ? channels[0].id : ''
  const has_thread_id = threads.length > 0 ? threads[0].id : ''

  return BaseRoomElementModel({
    x: desktop_x,
    y: desktop_y,
    scaleX: desktop_width,
    scaleY: desktop_height,
    responsive_x,
    responsive_y,
    responsive_width,
    responsive_height,
    desktop_x,
    desktop_y,
    desktop_width,
    desktop_height,
    uuid,
    item_label,
    image_item_url,
    image_item_cropped,
    breakpoints: breakpoints.split(','),
    //
    doorway_item_room_type,
    doorway_item_type,
    doorway_item_room_id: parseInt(doorway_item_room_id),
    // video_item
    video_item_type,
    video_item_style,
    video_item_url,
    video_item_preview,
    content_element_id,
    // slider_item
    slider_item_timer,
    slider_item_slides,
    // iframe_item
    iframe_item_url,
    iframe_item_type,
    iframe_item_html,
    // element_type
    element_type_label: text,
    element_type_icon: icon,
    element_type_color: color,
    element_type_name: kmjk_type,
    // clickable
    clickable,
    // content window
    content_item_elements: content_item_elements.map(
      ({ uuid, content_library_element_id }) =>
        VirtualRoomElementContentElementBase({
          uuid,
          content_library_element_id,
        }),
    ),

    has_channel: !!has_channel_id,
    has_channel_id,
    has_thread: !!has_thread_id,
    has_thread_id,
    is_autoplay,
    rotation,
  })
}
