<template>
  <h1
    class="tw-title-font tw-text-2xl tw-font-medium tw-leading-6 sm:tw-py-0.5 sm:tw-text-3xl"
    :class="textColor"
  >
    <slot />
  </h1>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    textColor() {
      return this.dark ? 'tw-text-gray-50' : 'tw-text-gray-900'
    },
  },
}
</script>

<style></style>
