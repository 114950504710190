/**
 *
 * @param {*} obj
 * @returns {FormData}
 */
export const makeFormData = (obj = {}) => {
  const form = new FormData()
  Object.keys(obj).forEach((key) => {
    const value = obj[key] == null ? '' : obj[key]

    form.append(key, value)
  })
  return form
}
