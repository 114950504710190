<template>
  <v-app>
    <!-- <right-drawer v-if="appMounted && notHomeOnlyMobile" /> -->
    <left-drawer v-if="appMounted && notHomeOnlyMobile" />
    <app-bar v-if="appMounted && notHomeOnlyMobile" />

    <v-main class="tw-bg-slate-100">
      <transition name="fade-transition" mode="out-in">
        <router-view />
      </transition>

      <NotificationContainer />
      <LogoutModal />
      <PreEventModal />
      <ExpiredLicense />
    </v-main>

    <GlobalLoader />
  </v-app>
</template>

<script>
import NotificationContainer from '@/components/AppLayout/NotificationContainer'
import GlobalLoader from '@/components/GlobalLoader'
import 'vuetify/es5/services/goto/easing-patterns'
// import RightDrawer from './components/AppLayout/RightDrawer.vue'
import LeftDrawer from './components/AppLayout/LeftDrawer.vue'
import LogoutModal from './views/Auth/LogoutModal'
import PreEventModal from './components/Admin/PreEventModal.vue'
import ExpiredLicense from './components/Admin/ExpiredLicense.vue'
import AppBar from './components/AppLayout/AppBar'
// import goTo from "vuetify/es5/services/goto";
export default {
  name: 'App',
  components: {
    ExpiredLicense,
    PreEventModal,
    NotificationContainer,
    // RightDrawer,
    AppBar,
    LogoutModal,
    LeftDrawer,
    GlobalLoader,
  },
  data: () => ({
    // appMounted: false,
  }),
  computed: {
    user() {
      return this.$store.state.session.user
    },
    appMounted() {
      return this.$store.state.appReady
    },
    notHomeOnlyMobile() {
      if (this.$store.state.fullScreenForce) {
        return !this.$store.state.fullScreenForce
      }
      // console.log(this.$router);
      //  ||
      //         this.$vuetify.breakpoint.mdAndDown
      const router_name = !this.$store.state.full_screen_layout
      // const router_name = !["home", "nested.two"].includes(this.current_router);
      return router_name || this.$vuetify.breakpoint.mdAndDown
    },
    version_build() {
      // return this.$store.getters.constants.version;
      // return '0.4.0'
      return process.env.VUE_APP_VERSION
    },
  },
  mounted() {
    // if (window.localStorage.getItem("token")) {
    //   this.$store.dispatch("initUser");
    // }
    // this.appMounted = true
    console.log(`build: ${this.version_build}`)
    this.$store.dispatch('startApp')
  },
}
</script>
<style>
.g-tw-grid-cols-2fr-1fr-1fr {
  grid-template-columns: 2fr 1fr 1fr !important;
}

.g-tw-grid-cols-2fr-1fr {
  grid-template-columns: 2fr 1fr !important;
}
</style>
