<template>
    <v-autocomplete
        v-model="input_value"
        :items="forms"
        :loading="isLoading"
        :search-input.sync="search"
        item-text="title"
        item-value="id"
        :label="label"
        outlined
        rounded
        hide-no-data
        hide-details
        v-bind="$attrs"
        v-on="$listeners"
    />
</template>
<script>
import { mapState } from 'vuex'

export default {
    props: {
        value: {
            type: [Number, String],
            default: 0,
        },
        label: {
            type: String,
            default: 'Formularios',
        },
    },
    data: () => ({
        search: null,
    }),
    computed: {
        ...mapState({
            registry: (state) => state.registries.show.data,
            forms: (state) => state.constants.run_time.forms,
            isLoading: (state) => state.constants.is_loading.forms,
        }),
        input_value: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
    watch: {
        search() {
            if (this.forms.length > 0) return
            this.loadForms()
        },
    },
    mounted() {
        // if (this.forms.length > 0) return
        this.loadForms()
    },
    methods: {
        loadForms() {
            this.$store.dispatch('constants/loadForms', {
                params: {
                    registry_id: this.registry.id,
                },
            })
        },
    },
}
</script>