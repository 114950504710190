<template>
  <div class="tw-flex tw-max-w-sm tw-items-start tw-space-x-4">
    <button
      v-ripple
      type="button"
      class="overflow-hidden tw-group tw-relative tw-h-14 tw-w-14 tw-rounded-lg tw-shadow-md tw-transition focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300"
      :style="{ backgroundColor: input_value }"
      @click="picker_modal = true"
    >
      <div
        class="tw- tw-pointer-events-none tw-absolute tw-inset-0 tw-grid tw-rounded-xl tw-bg-white tw-bg-opacity-0 tw-opacity-0 tw-transition tw-duration-150 tw-ease-in-out group-hover:tw-bg-opacity-10 group-hover:tw-opacity-100"
      >
        <div
          class="tw-grid tw-rounded-xl tw-bg-white tw-bg-opacity-0 tw-shadow-sm tw-ring-1 tw-ring-black tw-ring-opacity-5"
        >
          <a
            href="#"
            class="tw-inline-flex tw-items-center tw-justify-center tw-rounded-lg tw-py-1 tw-text-sm tw-font-medium tw-text-gray-700 tw-transition tw-duration-75 tw-ease-in-out hover:tw-bg-gray-100 hover:tw-text-gray-900"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="tw-h-6 tw-w-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
              />
            </svg>
          </a>
        </div>
      </div>
    </button>
    <v-menu
      v-model="picker_modal"
      :close-on-content-click="false"
      transition="scale-transition"
      max-width="290px"
      min-width="290px"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          ref="color_input"
          v-model="input_value"
          readonly
          v-bind="attrs"
          type="text"
          :filled="isFilled"
          :rounded="isRounded"
          :outlined="isOutlined"
          label="Color HEX*"
          v-on="on"
        />
      </template>
      <v-color-picker
        v-model="input_value"
        class="ma-2"
        dot-size="30"
        hide-mode-switch
        mode="hexa"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    isRounded: {
      type: Boolean,
      default: true,
    },
    isOutlined: {
      type: Boolean,
      default: true,
    },
    isFilled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    picker_modal: false,
  }),
  computed: {
    input_value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>

<style></style>
