<template>
  <UserProfileTabWrapper ref="tab">
    <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-h-full">
      <div class="tw-bg-blue-400">
        <template v-if="eventRegistryConfig.leaderboard_image_url">
          <div class="bg-white tw-flex tw-w-full tw-overflow-hidden">
            <img :src="eventRegistryConfig.leaderboard_image_url" class="tw-w-full" />
          </div>
        </template>
        <template v-else>
          <img src="@/assets/prints/bz-bg-lead.png" class="tw-w-full" />
        </template>
      </div>
      <div class="tw-bg-white tw-p-1 lg:tw-p-6">
        <template v-if="is_loading">
          <div class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">
            <v-progress-circular :size="50" color="primary" indeterminate/>
          </div>
        </template>
        <template v-else>
          <ListUserTopPoints :users="usersPointsParsed" />
          <v-divider class="tw-py-4 tw-mx-auto" style="width: 85%;" />
          <v-tabs
v-model="tab" centered show-arrows slider-color="transparent" class="tw-flex tw-justify-center"
            active-class="tw-bg-[#F5F5F5] tw-rounded-full" color="#656565">
            <v-tab
v-for="item in itemsType" :key="item.tab" :href="`#${item.tab}`"
              class="tw-m-2 tw-capitalize tw-text-xs tw-font-semibold">
              {{ $t(`app.leaderboard.`+ item.label) }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" style="height: 270px;" class="tw-overflow-y-scroll bz-scroll">
            <v-tab-item :key="tabKeys.global" :value="tabKeys.global">
              <ListUserPoints :users="usersPointsParsed" />
            </v-tab-item>
            <v-tab-item :key="tabKeys.rules" :value="tabKeys.rules">
              <ListRulesPoints :rules="leaderboard_rules" />
            </v-tab-item>
            <v-tab-item :key="tabKeys.mypoints" :value="tabKeys.mypoints">
              <!-- {{ total_points }} -->
              <div class="tw-px-2 lg:tw-px-6 tw-py-10 tw-space-y-2">
                <div class="tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-2 tw-rounded-full tw-bg-[#FCFCFC]">
                  <div>
                    <span class="tw-text-xs tw-font-bold tw-ml-2">{{ userPointsParsed.fullName }}</span>
                  </div>
                  <div>
                    <span class="tw-text-xs tw-font-bold">{{ userPointsParsed.total }} pts.</span>
                  </div>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </template>
      </div>
    </div>
    <div v-if="false">
      <div v-if="hasLeaderboard" class="tw-flex tw-h-full tw-flex-col tw-p-0">
        <div class="tw-flex-1 tw-py-6 tw-px-4 sm:tw-p-6 lg:tw-pb-8">
          <div class="tw-col-span-3 tw-mx-auto tw-grid tw-w-full tw-max-w-3xl tw-gap-3 lg:tw-gap-6">
            <v-container class="tw-col-span-3 tw-flex tw-divide-y tw-divide-gray-200 tw-p-0" fluid>
              <section class="tw-w-full">
                <div class="tw-divide-y tw-divide-gray-200">
                  <div class="tw-flex tw-justify-between">
                    <div class="tw-space-y-1">
                      <h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                        {{ eventRegistryConfig.leaderboard_header_text }}
                      </h3>
                      <p class="tw-max-w-2xl tw-text-sm tw-text-gray-500">
                        {{ eventRegistryConfig.leaderboard_body_text }}
                      </p>
                    </div>
                    <div class="tw-flex tw-items-center">
                      <AppBtn platform color="primary" :disabled="is_loading" @click="scrollToPoints">
                        {{ $t('app.my_score') }}
                      </AppBtn>
                    </div>
                  </div>
                  <div class="tw-mt-2 tw-flex tw-w-full">
                    <div class="tw-flex tw-w-full tw-py-4">
                      <div
v-if="eventRegistryConfig.leaderboard_image_url"
                        class="bg-white tw-flex tw-w-full tw-overflow-hidden tw-rounded-xl tw-shadow-md">
                        <img :src="eventRegistryConfig.leaderboard_image_url" class="tw-w-full" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </v-container>
            <v-container
v-if="!is_loading && eventRegistryConfig.can_show_rule_names"
              class="tw-col-span-3 tw-divide-y tw-divide-gray-200 tw-p-0" fluid>
              <section class="">
                <div class="tw-divide-y tw-divide-gray-200">
                  <div class="tw-space-y-1">
                    <h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                      {{ $t('app.rules') }}
                    </h3>
                  </div>
                  <ul class="tw-mt-2 tw-divide-y tw-divide-gray-200 tw-pl-0">
                    <li
v-for="leaderboard_rule in leaderboard_rules" :key="leaderboard_rule.id"
                      class="tw-items-center tw-py-4 sm:tw-flex sm:tw-justify-between">
                      <div class="tw-flex tw-flex-col">
                        <label for="has_rep_badge_present" class="tw-mb-0 tw-text-sm tw-font-medium tw-text-gray-900">
                          {{ leaderboard_rule.label }}
                        </label>
                      </div>
                      <div class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center">
                        <p class="tw-mb-0 tw-text-sm tw-text-gray-500">
                          {{ leaderboard_rule.points }}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </section>
            </v-container>
            <v-container v-if="!is_loading" class="tw-col-span-2 tw-divide-y tw-divide-gray-200 tw-p-0" fluid>
              <section class="">
                <div class="tw-divide-y tw-divide-gray-200">
                  <div class="tw-space-y-1">
                    <h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                      Top {{ users_points.length }}
                    </h3>
                  </div>
                  <ul class="tw-mt-2 tw-divide-y tw-divide-gray-200 tw-pl-0">
                    <li
v-for="user_row in usersPointsParsed" :key="user_row.email"
                      class="tw-items-center tw-py-4 sm:tw-flex sm:tw-justify-between">
                      <div class="tw-flex tw-flex-col">
                        <label for="has_rep_badge_present" class="tw-mb-0 tw-text-sm tw-font-medium tw-text-gray-900">
                          {{ user_row.fullName }}
                        </label>
                      </div>
                      <div class="tw-mt-5 sm:tw-mt-0 sm:tw-ml-6 sm:tw-flex sm:tw-flex-shrink-0 sm:tw-items-center">
                        <p class="tw-mb-0 tw-text-sm tw-text-gray-500">
                          {{ user_row.total }}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </section>
            </v-container>
            <v-container v-if="!is_loading" ref="my_points" class="tw-divide-y tw-divide-gray-200 tw-p-0" fluid>
              <section class="">
                <div class="tw-divide-y tw-divide-gray-200">
                  <div class="tw-space-y-1">
                    <h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
                      {{ $t('app.my_score') }}
                    </h3>
                  </div>
                  <div class="tw-mt-2">
                    <div class="tw-items-center tw-justify-end tw-py-4 sm:tw-flex">
                      <div class="tw-text-right tw-text-lg tw-font-medium tw-text-gray-900">
                        {{ total_points }}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </v-container>
          </div>
        </div>
        <div v-if="is_loading" class="tw-sticky tw-bottom-0 tw-z-10">
          <v-progress-linear indeterminate color="primary darken-2" />
        </div>
      </div>
      <div v-else class="tw-mt-6 tw-flow-root">
        <div class="tw-p-16 tw-text-center">
          <h2 class="tw-mb-2 tw-font-semibold tw-text-gray-900">
            {{ $t('pages.event.profile.points.no_content_title') }}
          </h2>

          {{ $t('pages.event.profile.points.no_content_body') }}
        </div>
      </div>
    </div>
  </UserProfileTabWrapper>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AppBtn from '../App/AppBtn.vue'
import UserProfileTabWrapper from './UserProfileTabWrapper.vue'
import ListUserPoints from './ListUserPoints.vue'
import ListUserTopPoints from './ListUserTopPoints.vue'
import ListRulesPoints from './ListRulesPoints.vue'

export default {
  components: {
    UserProfileTabWrapper,
    AppBtn,
    ListUserPoints,
    ListUserTopPoints,
    ListRulesPoints,
  },
  data: () => ({
    users_points: [],
    user_points: {},
    leaderboard_rules: [],
    total_points: 0,
    is_loading: true,
    tab: null,
    s: [
      { tab: 'global', label: 'global' },
      { tab: 'rules', label: 'rules' },
      { tab: 'mypoints', label: 'mypoints' },
    ],
    tabKeys: {
      global: 'global',
      rules: 'rules',
      mypoints: 'mypoints',
    },
  }),
  computed: {
    itemsType() {
      return [
        { tab: 'global', label: 'global', live: true },
        { tab: 'rules', label: 'rules', live: this.eventRegistryConfig.can_show_rule_names},
        { tab: 'mypoints', label: 'mypoints', live: true },
      ]
      .filter((row) => row.live)
    },
    ...mapState({
      user: (state) => state.session.user,
    }),
    usersPointsParsed() {
      return this.users_points.map((user) => {
        return {
          ...user,
          fullName: `${user.first_name} ${user.last_name}`,
        }
      })
    },
    userPointsParsed() {
      return {
        ...this.user_points,
        fullName: `${this.user_points.first_name} ${this.user_points.last_name}`,
      }
    },
    hasLeaderboard() {
      return this.eventRegistryConfig.has_leaderboard
    },
    ...mapGetters({
      eventRegistryConfig: ['event/eventRegistryConfig'],
    }),
  },
  created() {
    if (!this.hasLeaderboard) {
      return
    }
    this.is_loading = true
    this.$store
      .dispatch('event/getEventLeaderboardStats')
      .then(({ data, user_points, rules }) => {
        console.log(data)
        this.users_points = data
        this.leaderboard_rules = rules
        if (user_points) {
          this.user_points = user_points
          this.total_points = user_points.total
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        this.is_loading = false
      })
  },
  methods: {
    scrollToPoints() {
      this.$vuetify.goTo(this.$refs.my_points, {
        duration: 300,
        container: this.$refs.tab,
        offset: 25,
        easing: 'easeInOutCubic',
      })
    },
  },
}
</script>

<style scoped lang="scss">
.bz-scroll {
  &::-webkit-scrollbar {
    width: 2px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid #ffffff;
    /* creates padding around scroll thumb */
  }
}

.v-slide-group__prev {
  display: none !important;
}
</style>
