<template>
    <div>
        <!-- ------------- Message ------------- -->
        <p class="tw-text-sm tw-text-gray-400 tw-px-4 tw-text-center">{{ $t('app.text_forgot_code') }}</p>
        <!-- ------------- Email ------------- -->
        <div class="tw-relative">
            <v-otp-input
              v-model="otp_code"
              length="6"
              type="text"
              :disabled="loading_code"
              @finish="submitVerification"
            />
            <v-overlay absolute :value="loading_code">
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </v-overlay>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        codeForm: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        valid: true,
        loading_code: false,
        otp_code: '',
    }),
    methods: {
        submitVerification(rsp) {
            this.loading_code = true
            setTimeout(() => {
                this.loading_code = false
                this.$emit('alert-snackbar',{
                    status:true,
                    color: (rsp === this.codeForm) ? 'success':'warning',
                    msg: (rsp === this.codeForm) ? this.$t('app.success_code') : this.$t('app.error_code'),
                })
                this.$emit('page-form',{
                    f1:false,
                    f2:false,
                    f3:(rsp === this.codeForm) ? false : true,
                    f4:(rsp === this.codeForm) ? true : false,
                })
            }, 4000)
        },
    },
}
</script>

<style>
</style>