<script>
import { UserListKeys } from '@/enums/UserListKeys'
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      virtual_room: (state) => state.event.show.data,
    }),
    hasReps() {
      return this.virtual_room.reps.length > 0
    },
    onlySome() {
      return [...this.virtual_room.reps.slice(0, 4)]
    },
    reps() {
      return this.virtual_room.reps
    },
  },
  methods: {
    filterRepsActionEvent() {
      this.$store.commit('chat/setState', {
        showChat: true,
        view: 'ACTIVE_USERS',
      })
      this.$store.commit('event/setSocket', {
        filters: [UserListKeys.reps],
      })
    },
  },
  render() {
    return this.$scopedSlots.default({
      reps: this.reps,
      onlySome: this.onlySome,
      hasReps: this.hasReps,
      slotFilterRepsActionEvent: () => this.filterRepsActionEvent(),
    })
  },
}
</script>
