import { DurationBase, setDuration } from './Duration'

export const ContentLibraryElementBase = ({
  label = '',

  private_label = '',
  will_pre_populate = false,
  can_download = false,
  can_send_data = false,
  can_chat = false,
  can_channel_id = '',
  can_thread = false,
  can_thread_id = '',

  description = '',
  duration = DurationBase({}),
  language = '',
  content_type = '',
  language_keys = [],
  folder_keys = [],
  tag_keys = [],

  /**
   * * Audio Type */
  // audio_item_type_id="",
  audio_item_type = 'uploaded',
  audio_item_image = '',
  audio_item_url = '',

  /**
   * * Link | Download Type */
  // target_link_type_id="",
  target_link_type = 'normal',

  /**
   * * Download Type */
  download_item_url = '',

  /**
   * * Link Type */
  link_item_url = '',

  /**
   * * Video Type */
  // video_item_type_id="",
  video_item_type = 'vimeo',
  video_item_url = '',
  video_item_preview = '',

  /**
   * * Webinar Type */
  // webinar_item_type_id="",
  webinar_item_type = 'zoom',
  webinar_item_url = '',
  webinar_item_code = '',
  webinar_item_icon = '',
  webinar_item_image = '',

  /**
   * * Doorway */
  doorway_item_room_id = '',

  /**
   * * Question And Answer Type */
  thread_id = '',

  /**
   * * Iframe Type */
  // iframe_item_type_id="",
  iframe_item_type = 'link',
  iframe_item_url = '',
  iframe_item_html = '',
  is_autoplay = false,
}) => ({
  label,

  private_label,
  will_pre_populate,
  can_download,
  can_send_data,
  can_chat,
  can_channel_id,
  can_thread,
  can_thread_id,
  description,
  duration,
  language,
  language_keys,
  folder_keys,
  content_type,
  tag_keys,

  audio_item_type,
  audio_item_image,
  audio_item_url,

  target_link_type,

  download_item_url,

  link_item_url,

  video_item_type,
  video_item_url,
  video_item_preview,

  webinar_item_type,
  webinar_item_url,
  webinar_item_code,
  webinar_item_icon,
  webinar_item_image,

  doorway_item_room_id,

  thread_id,

  iframe_item_url,
  iframe_item_type,
  iframe_item_html,
  is_autoplay,
})

export const ContentLibraryElementSetDB = (
  {
    label,
    private_label,

    will_pre_populate: will_pre_populate_db,
    can_download: can_download_db,
    can_send_data: can_send_data_db,
    can_chat: can_chat_db,
    can_thread,

    duration: duration_obj,
    language: language_db,
    content_type: content_type_obj_db,
    localisations: langs_db,
    folders: folders_db,
    tags: tags_db,

    description,

    audio_item_image,
    audio_item_url,

    target_link_type: target_link_type_db,
    download_item_url,
    link_item_url,

    video_item_type: video_item_type_obj_db,
    video_item_url,
    video_item_preview,

    webinar_item_type: webinar_item_type_db,
    webinar_item_url,
    webinar_item_code,
    webinar_item_icon,
    webinar_item_image,
    doorway_item_room_id: doorway_item_room_id_db,
    thread_id: thread_id_db,
    iframe_item_url,
    iframe_item_html,
    channels,
    threads,
    is_autoplay,
  },
  loadedTags = [],
) => {
  const db_tags = tags_db.map((tag) => tag.id)
  const can_channel_id = channels.length > 0 ? channels[0].id : ''
  const can_thread_id = threads.length > 0 ? threads[0].id : ''
  return ContentLibraryElementBase({
    label,
    private_label,
    can_channel_id,
    can_thread_id,
    will_pre_populate: !!will_pre_populate_db,
    can_download: !!can_download_db,
    can_send_data: !!can_send_data_db,
    can_chat: !!can_chat_db,
    can_thread,

    duration: duration_obj ? setDuration(duration_obj) : DurationBase({}),
    language: language_db ? language_db.name : '',

    content_type: content_type_obj_db ? content_type_obj_db.name : '',
    language_keys: langs_db.map((row) => row.name),
    folder_keys: folders_db.map((row) => row.id),
    tag_keys: loadedTags.filter((row) => db_tags.includes(row.value)),

    description,

    audio_item_image,
    audio_item_url,

    target_link_type: target_link_type_db ? target_link_type_db.name : '',
    download_item_url,
    link_item_url,

    video_item_type: video_item_type_obj_db
      ? video_item_type_obj_db.name
      : 'vimeo',
    video_item_url,
    video_item_preview,

    webinar_item_type: webinar_item_type_db ? webinar_item_type_db.name : '',
    webinar_item_url,
    webinar_item_code,
    webinar_item_icon,
    webinar_item_image,
    thread_id: thread_id_db || '',
    doorway_item_room_id: doorway_item_room_id_db || '',
    iframe_item_url,
    iframe_item_html,

    is_autoplay,
  })
}
