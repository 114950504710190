import { v4 } from '@/helpers'
import { ClickableBase, setClickableFromDB } from './Clickable'

export const EventNavItemBase = ({
  label = '',
  helper_text = '',
  size = 'md',
  image_url = '',
  is_active = true,
  // click_action_type = 'link_location',
  // external_url_link = '',
  // link_location_virtual_room_id = '',
  uuid = v4(),
  clickable = ClickableBase({}),
}) => ({
  label,
  helper_text,
  // external_url_link,
  // link_location_virtual_room_id,
  size,
  image_url,
  is_active,
  // click_action_type,
  uuid,
  clickable,
})

export const EventNavItemBD = ({
  label,
  helper_text,
  size,
  image_url,
  is_active,
  // click_action_type,
  // click_action_type = "link_location",
  // external_url_link,
  // link_location_virtual_room_id,
  uuid,
  clickable,
}) =>
  EventNavItemBase({
    label,
    helper_text,
    size,
    image_url,
    // click_action_type: click_action_type.name,
    is_active: !!is_active,
    clickable: setClickableFromDB(clickable),
    uuid,
    // link_location_virtual_room_id,
    // external_url_link,
  })
