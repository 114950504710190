<template>
    <div class="tw-block tw-w-full lg:tw-flex tw-items-center tw-space-y-2 lg:tw-space-y-0 tw-cursor-pointer">
        <RenderlessClickableBzdHandle>
            <template slot-scope="{ slotViewAttendee }">
                <v-chip class="tw-w-full tw-bg-gray-100 tw-px-6 tw-mr-2" @click="slotViewAttendee(user)">
                    {{ $t('bz2.event.matchmaking.match_automatic') }} <b class="tw-ml-1">{{ fullname }}</b>
                </v-chip>
            </template>
        </RenderlessClickableBzdHandle>
        <RenderlessClickableBzdHandle>
            <template slot-scope="{ slotAddContact, slotOpenChat, contact_loading }">
                <div class="tw-flex tw-space-x-2 tw-justify-center lg:tw-justify-start">
                    <v-btn 
                        color="success" 
                        icon 
                        elevation="0" 
                        :loading="contact_loading" 
                        rounded 
                        :disabled="added"
                        @click="slotAddContact(user)"
                    >
                        <v-icon>
                            {{ added ? 'how_to_reg': 'person_add' }}
                        </v-icon>
                    </v-btn>
                    <v-btn color="secondary" icon elevation="0" rounded>
                        <v-icon>calendar_month</v-icon>
                    </v-btn>
                    <v-btn 
                        color="primary" 
                        icon 
                        elevation="0" 
                        rounded
                        @click="slotOpenChat(user)"
                    >
                        <v-icon>chat</v-icon>
                    </v-btn>
                </div>
            </template>
        </RenderlessClickableBzdHandle>
    </div>
</template>
<script>
import RenderlessClickableBzdHandle from '@/components/Event/RenderlessClickableBzdHandle.vue'
export default {
    components: {
        RenderlessClickableBzdHandle,
    },
    props: {
        user: {
            type: Object,
            default: () => ({}),
        }, 
    },
    computed: {
        fullname() {
            return this.user.first_name + ' ' + this.user.last_name
        },
        userContactList() {
            return this.$store.state.event.constants.event_connections.map(
                (row) => row.event_user_id,
            )
        },
        added() {
            return this.userContactList.includes(this.user.id)
        },
    },
}
</script>
<style>
.bz-connect-user-text {
    background: #f3f3f3;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
}
</style>