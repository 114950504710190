import $axios from '@/axios-instance.js'

class LeaderboardServiceClass {
  baseUrl = '/api/v1/admin/leaderboards'
  // Create
  store(params) {
    return $axios.post(this.baseUrl, params)
  }

  storeAll(id, params) {
    return $axios.post(`${this.baseUrl}/bulk/${id}`, params)
  }

  showUser(registry_id, user_id, params) {
    return $axios.get(this.baseUrl + `/users/${user_id}/${registry_id}`, params)
  }

  // Read all - findAll
  index(params) {
    return $axios.get(this.baseUrl, params)
  }

  // Read one - show
  show(id, params) {
    return $axios.get(this.baseUrl + `/${id}`, params)
  }

  // Update
  update(id, params) {
    return $axios.put(this.baseUrl + `/${id}`, params)
  }

  // Delete - delete
  destroy(id, params) {
    return $axios.delete(this.baseUrl + `/${id}`, params)
  }
}

export const LeaderboardService = new LeaderboardServiceClass()
