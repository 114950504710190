/**
 *
 * @param {Array} array
 * @param {string} key
 * @returns { Object }
 */
export const arrayToObject = (array, key) =>
  array.reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item,
    }),
    {},
  )
