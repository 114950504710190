export const CredentialBase = ({
  width = 300,
  height = 440,
  qr_size = 3,
  ui_background = '#3565AF',
  url_background = '',
  url_logo = '',
  logo_size = 100,
  orientation = 1,
  user_form = {},
  custom_css = '',
}) => ({
  width,
  height,
  qr_size,
  ui_background,
  url_background,
  url_logo,
  logo_size,
  orientation,
  user_form,
  custom_css,
})

const valid_prefixes = ['fontsize', 'color', 'is_active']
const current_imporant_keys = ['company', 'email', 'fullname','category']

// TODO: Valid Keys
const validKeys = (fields) => {
  return Object.keys({ ...fields, fullname: '' }).reduce((acc, curr) => {
    if (current_imporant_keys.includes(curr)) {
      return {
        ...acc,
        [curr]: '',
      }
    }
    return acc
  }, {})
}

// TODO: setFields
const setFields = (fields, UserForm) => {
  const generateKeyJson = (keys, values) => {
    return Object.keys(keys).reduce((acc, curr) => {
      const formated_keys = valid_prefixes.reduce((accFormatedKeys, currPrefix) => {
        let key = curr + '__' + currPrefix
        let value = values[key] ? values[key] : ''
        return {
          ...accFormatedKeys,
          [key]: value,
        }
      }, {})
      return {
        ...acc,
        ...formated_keys,
      }
    }, {})
  }
  return generateKeyJson(fields, UserForm)
}

// TODO: validateFormKeys
export const validateFormKeys = (RegistryForms) => {
  // Default Fields
  const default_fields = RegistryForms.map(row => row.default_fields)
  const defaultFieldsRows = default_fields.flatMap(row => row).reduce((acc, curr) => {
    if (curr.is_active) {
      return {
        ...acc,
        [curr.name]: curr.name,
      }
    }
    return acc
  }, {})
  // Custom Fields
  const custom_fields = RegistryForms.map(row => row.fields)
  const customFieldsRows = custom_fields.flatMap(row => row).reduce((acc, curr) => {
    if (curr.is_active) {
      return {
        ...acc,
        [curr.name]: curr.name,
      }
    }
    return acc
  }, {})
  return {
    ...defaultFieldsRows,
    ...customFieldsRows,
  }
}

// TODO: parseValidKeys
export const parseValidKeys = (RegistryForms) => {
  const default_fields = validateFormKeys(RegistryForms)
  return validKeys(default_fields)
}

// TODO: CredentialHydrate
export const CredentialHydrate = (CredentialDB, RegistryForms) => {
  if (CredentialDB && RegistryForms) {
    const valid_keys = parseValidKeys(RegistryForms)
    let userForm = CredentialDB['user_form'] ? CredentialDB['user_form'] : {}
    const user_form = setFields(valid_keys, userForm)
    const payload = { ...CredentialDB, user_form }
    return CredentialBase(payload)
  }
  return CredentialBase({})
}