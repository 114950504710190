<template>
  <v-card ref="logins_list" class="tw-rounded-3xl tw-border-0 tw-shadow-md">
    <v-container class="tw-p-0" fluid>
      <div class="tw-grid tw-grid-cols-1 tw-border-b tw-pt-3 lg:tw-grid-cols-2">
        <div
          class="tw-row-start-2 tw-flex tw-items-end lg:tw-col-span-1 lg:tw-row-start-auto"
        >
          <v-tabs v-model="form.filter_type" show-arrows>
            <v-tab
              v-for="filter_row_type in filter_types"
              :key="filter_row_type.text"
              :href="'#' + filter_row_type.value"
            >
              {{ filter_row_type.text }}
            </v-tab>
          </v-tabs>
        </div>
        <div
          v-if="false"
          class="tw-grid tw-grid-cols-1 tw-gap-6 tw-px-4 tw-pb-3 lg:tw-col-span-1 lg:tw-grid-cols-2"
        >
          <v-select
            v-model="form.filter_selected"
            :items="filters"
            :label="$t('admin.app.filter_by')"
            prepend-inner-icon="sort"
            hide-details
            outlined
            rounded
          />

          <v-select
            v-if="form.filter_selected == 'sent_at'"
            v-model="form.search_enum"
            :items="search_enums"
            :label="$t('admin.app.filter_by')"
            prepend-inner-icon="sort"
            hide-details
            outlined
            rounded
          />
          <v-text-field
            v-else
            v-model="form.search"
            outlined
            rounded
            append-icon="search"
            :label="$t('app.search')"
            hide-details
          />
        </div>
      </div>
    </v-container>
    <v-container class="tw-overflow-hidden tw-p-0" fluid>
      <!-- :footer-props="{ itemsPerPageOptions }" -->
      <v-data-iterator
        :footer-props="footer_props"
        :items="formatedRows"
        :options.sync="options"
        :search="search"
        :loading="loading"
        v-bind="$attrs"
      >
        <template #default="props">
          <ul
            role="list"
            class="tw-divide-y tw-divide-gray-200 tw-border-b tw-border-gray-200 tw-pl-0"
          >
            <li v-for="item in props.items" :key="item.id">
              <button
                v-ripple
                class="tw-block tw-w-full hover:tw-bg-gray-50"
                @click="showItem(item)"
              >
                <div class="tw-w-full tw-px-4 tw-py-4 sm:tw-px-6">
                  <div class="tw-flex tw-items-center tw-justify-between">
                    <p
                      class="tw-mb-0 tw-truncate tw-text-sm tw-font-medium tw-text-indigo-600"
                    >
                      {{ item.agent.platform }} - {{ item.agent.browser }}
                    </p>
                    <div class="tw-ml-2 tw-flex tw-flex-shrink-0">
                      <p
                        class="tw-mb-0 tw-inline-flex tw-rounded-full tw-bg-green-100 tw-px-2 tw-text-xs tw-font-semibold tw-leading-5 tw-text-green-800"
                      >
                        {{ item.registry.label }}
                      </p>
                    </div>
                  </div>
                  <div class="tw-mt-2 sm:tw-flex sm:tw-justify-between">
                    <div class="sm:tw-flex">
                      <p
                        class="tw-mb-0 tw-flex tw-items-center tw-text-sm tw-text-gray-500"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="tw-mr-1.5 tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
                          />
                        </svg>
                        {{ item.ip_address }}
                      </p>
                      <p
                        class="tw-mb-0 tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500 sm:tw-mt-0 sm:tw-ml-6"
                      >
                        <svg
                          class="tw-mr-1.5 tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        {{ item.timezone }}, {{ item.country_code }}
                      </p>
                      <p
                        class="tw-mb-0 tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500 sm:tw-mt-0 sm:tw-ml-6"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="tw-mr-1.5 tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"
                          />
                        </svg>
                        {{ item.user_locale }}
                      </p>
                    </div>
                    <div
                      class="tw-mt-2 tw-flex tw-items-center tw-text-sm tw-text-gray-500 sm:tw-mt-0"
                    >
                      <svg
                        class="tw-mr-1.5 tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <p class="tw-mb-0">
                        {{ $t('app.last_activity') }}
                        <time datetime="2020-01-07">
                          {{ item.timeSinceLastUsedAt }}
                        </time>
                      </p>
                    </div>
                  </div>
                </div>
              </button>
            </li>
          </ul>
        </template>
        <template #loading>
          <div class="tw-py-20">
            <div class="tw-flex tw-h-full tw-items-center tw-justify-center">
              <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
              />
            </div>
          </div>
        </template>
        <template #no-data>
          <div class="tw-py-20">
            <div class="tw-flex tw-h-full tw-items-center tw-justify-center">
              {{ $t('$vuetify.noDataText') }}
            </div>
          </div>
        </template>
      </v-data-iterator>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { commonTimeFormat, diffForHumans } from '@/utils/dateHelpers'
import EventRegistryResource from '@/services/EventRegistryResource'
export default {
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    registry: {
      type: Object,
      default: () => ({}),
    },
    isMod: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    search: '',
    imageModal: false,
    rows: [],
    form: {
      search_enum: 1,
      filter_by: 'first_name',
      filter_type: 'all',
    },
    loading: false,
    options: {},
    filters: [
      { text: 'Email', value: 'email' },
      { text: 'Nombre', value: 'first_name' },
      { text: 'Apellidos', value: 'last_name' },
    ],
  }),
  computed: {
    formatedRows() {
      return this.rows.map((row) => {
        return {
          ...row,
          created_at: commonTimeFormat(row.created_at),
          timeSinceLastUsedAt: diffForHumans(row.last_used_at),
        }
      })
    },
    footer_props() {
      const obj = {
        'items-per-page-options': [10, 20, 30, 40, 50],
      }
      return obj
    },
    filter_types() {
      return [
        { text: this.$t('admin.enums.all'), value: 'all' },
        ...this.languages,
      ].map((row) => ({ ...row, text: this.$t(`admin.enums.${row.value}`) }))
    },
    ...mapGetters({
      languages: ['constants/languages'],
    }),
  },
  watch: {
    options: {
      handler() {
        this.getRows()
      },
      deep: true,
    },
    // form: {
    //   handler() {
    //     this.getRows()
    //   },
    //   deep: true,
    // },
    search: {
      handler() {
        this.getRows()
      },
      deep: true,
    },
  },
  methods: {
    showItem(login) {
      console.log({ login })
    },
    getRows() {
      this.loading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      if (this.registry.id) {
        this.form.filter_by = 'registry'
        this.form.search_enum = this.registry.id
      }

      const params = {
        user: this.user,
        payload: {
          params: {
            page: page,
            rows_per_page: itemsPerPage,
            sort_by: sortBy[0],
            sort_desc_or_asc: sortDesc[0]
              ? sortDesc[0]
                ? 'desc'
                : 'asc'
              : 'desc',
            search: this.search,
            ...this.form,
          },
        },
      }
      const service = this.isMod
        ? EventRegistryResource.Logins(this.registry.id, this.user.uuid).index(
            params.payload,
          )
        : this.$store.dispatch('users/indexLogins', params)

      service
        .then((res) => {
          const { total, data } = res.data.data
          this.rows = data
          this.totalRows = total
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style></style>
