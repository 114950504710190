<template>
<div>
    <!-- Certificate -->
    <!-- <pre>
        {{ config }}
    </pre> -->
    <div v-if="true" class="cert_bzd" :style="stylesCert">
        <img v-if="config.url_background" :src="config.url_background" />
        <div class="cert_bzd_data">
            <div class="cert_bzd_body">
                <p v-if="!userForm.title_is_image" class="cert_bzd_title" :style="stylesTitle" >{{ userForm.title_text }}</p>
                <img v-if="userForm.title_is_image" :src="userForm.title_is_image" alt="Logo1" class="cert_bzd_title_img"/>
                <div class="cert_bzb_separater" />
                <p class="cert_bzd_to" :style="stylesSubtitle">{{ userForm.to_text }}</p>
                <p class="cert_bzd_fullname" :style="stylesFullname">{{ fullname }} </p>
                <p class="cert_bzd_description" :style="stylesDescription">
                    {{ userForm.description_text }}
                </p>
            </div>
            <div class="cert_bzd_logos">
                <img v-if="userForm.logo_pry_is_active" :src="userForm.logo_pry_url" class="cert_bzd_logos_1" :style="stylesLogo1"/>
                <img v-if="userForm.logo_sec_is_active" :src="userForm.logo_sec_url" class="cert_bzd_logos_2" :style="stylesLogo2"/>
            </div>
            <div class="cert_bzd_footer">
                <div v-if="userForm.firma_pry_is_active" class="cert_bzd_firma_pry" :style="stylesFirma1">
                    <img v-if="userForm.firma_pry_url" :src="userForm.firma_pry_url" class="cert_bzd_firma_1" />
                    <div class="cert_bzd_border_top">
                        <p class="cert_bzd_firma_pry_name">{{ userForm.firma_pry_name }}</p>
                        <p class="cert_bzd_firma_pry_detail">{{ userForm.firma_pry_detail }}</p>
                    </div>
                </div>
                <div v-if="userForm.firma_sec_is_active" class="cert_bzd_firma_pry" :style="stylesFirma2">
                    <img v-if="userForm.firma_sec_url" :src="userForm.firma_sec_url" class="cert_bzd_firma_2" />
                    <div class="cert_bzd_border_top">
                        <p class="cert_bzd_firma_pry_name">{{ userForm.firma_sec_name }}</p>
                        <p class="cert_bzd_firma_pry_detail">{{ userForm.firma_sec_detail }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// import {
//     mapState,
// } from 'vuex'

export default {
    name: 'CertificateCard',
    props: {
        checkinUser: {
            type: Object,
            default: () => ({}),
        },
        config: {
            type: [Array, Object],
            default: () => ({}),
        },
        userForm: {
            type: [Array, Object],
            default: () => ({}),
        },
    },
    computed: {
        stylesCert() {
            return {
                background: `${this.config.ui_background}`,
                width: `${this.config.width}px`,
                height: `${this.config.height}px`,
            }
        },
        stylesTitle() {
            return {
                'font-size': `${this.userForm.title_size}px`,
                color: `${this.userForm.title_color}`,
            }
        },
        stylesSubtitle() {
            return {
                'font-size': `${this.userForm.to_size}px`,
                color: `${this.userForm.to_color}`,
            }
        },
        stylesFullname() {
            return {
                'font-size': `${this.userForm.fullname_size}px`,
                color: `${this.userForm.fullname_color}`,
            }
        },
        stylesDescription() {
            return {
                'font-size': `${this.userForm.description_size}px`,
                color: `${this.userForm.description_color}`,
            }
        },
        stylesLogo1() {
            return {
                width: `${this.userForm.logo_pry_size}px`,
            }
        },
        stylesLogo2() {
            return {
                width: `${this.userForm.logo_sec_size}px`,
            }
        },
        stylesFirma1() {
            return {
                color: `${this.userForm.firma_pry_color}`,
            }
        },
        stylesFirma2() {
            return {
                color: `${this.userForm.firma_sec_color}`,
            }
        },
        fullname() {
            return `${this.checkinUser.first_name} ${this.checkinUser.last_name}`
        },
    },
}
</script>

<style lang="scss" scoped media="print">
// Title
@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');
// Fullname
@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');
// Text
@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');

// New CSS
.cert_bzd {
    position: relative;
    .cert_bzd_data {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 4rem;
        text-align: center;
    }
    .cert_bzd_body {
        .cert_bzd_title {
            font-family: 'Cinzel', serif;
            margin: 0;
        }
        .cert_bzd_title_img {
            width: 200px;
            margin: auto;
            margin-bottom: 2rem;
        }
        .cert_bzd_fullname {
            font-family: 'Parisienne', cursive;
            margin: 0;
            // color: #dbb24b;
        }
        .cert_bzd_description,
        .cert_bzd_to {
            font-family: 'Roboto', sans-serif;
            margin: 0;
        }
        .cert_bzd_to {
            font-weight: bold;
            letter-spacing: 3px;
        }
        .cert_bzd_description {
            padding: 0 3rem;
        }
    }
    .cert_bzd_logos {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        margin-bottom: 5rem;
        .cert_bzd_logos_1, .cert_bzd_logos_2 {
            margin: 0 2rem;
        }
        .cert_bzd_logos_1 {
            width: 200px;
        }
        .cert_bzd_logos_2 {
            width: 200px;
        }
    }
    .cert_bzd_footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .cert_bzd_firma_pry {
            padding: 0 4rem;
            position: relative;
            .cert_bzd_firma_1, .cert_bzd_firma_2 {
                width: 180px;
                height: 80px;
                margin-bottom: .2rem;
            }
            .cert_bzd_border_top {
                &::before {
                    content: '';
                    width: 60%;
                    height: .5px;
                    background: black;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
                .cert_bzd_firma_pry_name {
                    padding-top: 0.5rem;
                    font-size: 14px;
                    font-weight: bold;
                    margin: 0;
                }
                .cert_bzd_firma_pry_detail {
                    font-size: 11px;
                    font-style: italic;
                    font-weight: 600;
                    margin: 0;
                }
            }
        }
    }
}
</style>
