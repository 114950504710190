<template>
  <div
    class="tw-pointer-events-none tw-fixed tw-inset-0 tw-z-50 tw-flex tw-items-end tw-justify-start tw-p-4 lg:tw-z-10 lg:tw-justify-end lg:tw-p-2 2xl:tw-p-6"
  >
    <div
      class="tw-flex tw-h-full tw-w-full tw-flex-col-reverse tw-items-end lg:tw-h-auto lg:tw-max-w-sm"
    >
      <div
        class="tw-absolute tw-bottom-4 tw-left-4 tw-z-0 tw-flex tw-w-full tw-justify-start lg:tw-relative lg:tw-bottom-0 lg:tw-left-0 lg:tw-z-10 lg:tw-justify-end"
      >
        <button
          v-ripple
          type="button"
          class="tw-pointer-events-auto tw-flex tw-h-14 tw-w-14 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-shadow-xl tw-transition focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300 lg:tw-m-2 lg:tw-h-14 lg:tw-w-14 2xl:tw-m-4 2xl:tw-h-16 2xl:tw-w-16"
          @click="openChat"
        >
          <svg
            class="tw-h-8 tw-w-8 lg:tw-h-8 lg:tw-w-8 2xl:tw-h-10 2xl:tw-w-10"
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient id="a" x1="50%" y1="0%" x2="50%" y2="100%">
                <stop stop-color="#2397B3" offset="0%" />
                <stop stop-color="#13577E" offset="100%" />
              </linearGradient>
              <linearGradient id="b" x1="50%" y1="0%" x2="50%" y2="100%">
                <stop stop-color="#73DFF2" offset="0%" />
                <stop stop-color="#47B1EB" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="none" fill-rule="evenodd">
              <path
                d="M28.872 22.096c.084.622.128 1.258.128 1.904 0 7.732-6.268 14-14 14-2.176 0-4.236-.496-6.073-1.382l-6.022 2.007c-1.564.521-3.051-.966-2.53-2.53l2.007-6.022A13.944 13.944 0 0 1 1 24c0-7.331 5.635-13.346 12.81-13.95A9.967 9.967 0 0 0 13 14c0 5.523 4.477 10 10 10a9.955 9.955 0 0 0 5.872-1.904z"
                fill="url(#a)"
                transform="translate(1 1)"
              />
              <path
                d="M35.618 20.073l2.007 6.022c.521 1.564-.966 3.051-2.53 2.53l-6.022-2.007A13.944 13.944 0 0 1 23 28c-7.732 0-14-6.268-14-14S15.268 0 23 0s14 6.268 14 14c0 2.176-.496 4.236-1.382 6.073z"
                fill="url(#b)"
                transform="translate(1 1)"
              />
              <path
                d="M18 17a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM24 17a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM30 17a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                fill="#FFF"
              />
            </g>
          </svg>
        </button>
      </div>
      <div class="tw-relative tw-h-full tw-w-full lg:tw-w-auto lg:tw-px-0">
        <div
          class="tw-absolute tw--right-2 tw--top-4 tw-z-10 tw-flex lg:tw-right-0 lg:tw-mr-10 2xl:tw-mr-12"
        >
          <transition name="fade-transition" mode="out-in">
            <v-btn
              v-if="showChat"
              fab
              x-small
              class="tw-pointer-events-auto focus:tw-outline-none focus:tw-ring"
              @click="showChat = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </transition>
        </div>
        <transition
          enter-active-class="tw-duration-300 tw-ease-out tw-origin-bottom-left"
          enter-from-class="tw-translate-y-4 tw-opacity-0 sm:tw-translate-y-0 sm:tw-scale-95"
          enter-to-class="tw-translate-y-0 tw-opacity-100 sm:tw-scale-100"
          leave-active-class="tw-duration-200 tw-ease-in"
          leave-from-class="tw-translate-y-0 tw-opacity-100 sm:tw-scale-100"
          leave-to-class="tw-translate-y-4 tw-opacity-0 sm:tw-translate-y-0 sm:tw-scale-95"
          mode="out-in"
        >
          <!-- lg:tw-h-[600px] -->
          <!-- style="height: 600px" -->
          <div
            v-show="showChat"
            key="chat-wrapper"
            :style="heightStyle"
            class="tw-g-chat-wrapper tw-pointer-events-auto tw-relative tw-ml-0 tw-h-full tw-w-full tw-flex-1 tw-overflow-hidden tw-rounded-lg tw-bg-white tw-shadow-xl lg:tw-mr-12 lg:tw-ml-0 lg:tw--mb-12 lg:tw-w-96 2xl:tw-mr-16 2xl:tw--mb-10"
          >
            <transition name="slide-x-transition" mode="out-in">
              <ChatHome v-if="view === 'HOME'" key="home-chat" ref="chat-sort"/>
              <ChatConversation v-if="view === 'CHAT'" key="convo-chat" />
              <UsersList
                v-if="view === 'ACTIVE_USERS'"
                key="active-users-chat"
              />
              <ChatPublicChannel
                v-if="view === 'ACTIVE_PUBLIC_CHANNEL'"
                key="active-public-channel-chat"
              />
              <ChatCreateGroupChannel
                v-if="view === 'CREATE_GROUP_CHANNEL'"
                key="create-private-group-chat"
              />
              <!-- Donovann: Añadir Q&A al Chat -->
              <EventRoomContentElementChatThread
                v-if="view === 'ACTIVE_QA_CHANNEL'"
                key="threads"
                :parrent-thread-id="parrent_thread.id"
                :parrent-thread="parrent_thread"
                :parrent-thread-chat="thread_has_chat"
              />
            </transition>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import ChatHome from './ChatHome'
import ChatConversation from './ChatConversation'
import UsersList from './UsersList'
import ChatPublicChannel from './ChatPublicChannel.vue'
import ChatCreateGroupChannel from './ChatCreateGroupChannel.vue'
import EventRoomContentElementChatThread from './EventRoomContentElementChatThread.vue'
import { mapFields } from '@/helpers'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    ChatHome,
    ChatConversation,
    UsersList,
    ChatPublicChannel,
    ChatCreateGroupChannel,
    EventRoomContentElementChatThread,
  },

  computed: {
    heightStyle() {
      let height = 600
      console.log(this.window_height)
      if (this.window_height < 700) {
        const discount = this.window_height * 0.2
        height = this.window_height - discount
      }
      return {
        '--chat-wrapper-height': `${height}px`,
      }
    },
    ...mapState({
      window_width: (state) => state.event.constants.window_width,
      window_height: (state) => state.event.constants.window_height,
    }),
    ...mapFields({
      fields: ['showChat', 'view', 'home_tab', 'parrent_thread','thread_has_chat'],
      base: ['chat'],
      mutation: 'chat/setState',
    }),
    ...mapGetters({
      isRealtimeEnabled: ['event/isRealtimeEnabled'],
    }),
  },
  methods: {
    userChat() {
      this.$refs['chat-sort'].sortUsers()
    },
    openChat() {
      if (!this.showChat) {
        this.view = 'HOME'
        this.home_tab = this.isRealtimeEnabled ? 'PRIVATE' : 'SUPPORT'
      }
      this.showChat = !this.showChat
    },
  },
}
</script>

<style>
/* .scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
} */
/* @media (min-width: 1024px) {
  .tw-g-chat-wrapper {
    height: var(--chat-wrapper-height) !important;
  }
} */
</style>
