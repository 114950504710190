<template>
  <v-row class="mb-5">
    <v-col cols="12" class="tw-py-0">
      <div class="tw-h-px tw-border" />
    </v-col>
  </v-row>
</template>

<script>
export default {}
</script>

<style></style>
