import $axios from '@/axios-instance.js'

export default class AccessControlsService {
  baseUrl = '/api/v1/admin/registries'
  static make() {
    return new AccessControlsService()
  }
  // /registries
  // Create
  store(registry, params) {
    return $axios.post(`${this.baseUrl}/${registry}/access-controls`, params)
  }
  importAction(registry, params) {
    return $axios.post(
      `${this.baseUrl}/${registry}/access-controls/import`,
      params,
    )
  }

  strategyUpdate(registry, params) {
    return $axios.post(`${this.baseUrl}/${registry}/access-control-strategy`, {
      ...params,
      _method: 'PUT',
    })
  }

  // Read all - findAll
  index(registry, params) {
    return $axios.get(`${this.baseUrl}/${registry}/access-controls`, params)
  }

  // Read one - show
  show(registry, id, params) {
    return $axios.get(
      `${this.baseUrl}/${registry}/access-controls/${id}`,
      params,
    )
  }

  // Update
  update(registry, id, params) {
    return $axios.post(`${this.baseUrl}/${registry}/access-controls/${id}`, {
      ...params,
      _method: 'PUT',
    })
  }

  // Delete - delete
  destroy(registry, id, params) {
    return $axios.post(`${this.baseUrl}/${registry}/access-controls/${id}`, {
      ...params,
      _method: 'DELETE',
    })
  }
}
export const AccessControlsServiceInstance = new AccessControlsService()
