<template>
<v-dialog v-model="open" persistent scrollable max-width="600px" content-class="bz2-scroll tw-rounded-xl">
    <v-form
      ref="form"
      v-model="valid"
      :disabled="is_loading"
      lazy-validation
      enctype="multipart/form-data"
      @submit.prevent="create"
    >
        <v-card class="tw-relative tw-rounded-xl tw-border-0 tw-shadow-md">
            <ModalHeader @close-modal="closeCreate"> Agendar </ModalHeader>
            <v-card-text class="tw-px-4 tw-py-6 tw-bg-[#ffffff]">
                <div class="tw-grid tw-grid-cols-12 tw-gap-3">
                    <!-- Title -->
                    <div class="tw-col-span-12">
                        <v-text-field 
                            v-model="form.label"
                            label="Añade un título" 
                            class="tw-font-semibold" 
                            flat 
                            single-line 
                            hide-details 
                            rounded 
                            outlined 
                            dense 
                        />
                    </div>
                    <!-- Description -->
                    <div class="tw-col-span-12">
                        <v-textarea
                            v-model="form.description"
                            label="Description"
                            counter
                            maxlength="255"
                            full-width
                            flat single-line hide-details rounded outlined dense
                        />
                    </div>
                    <!-- Guests -->
                    <div class="tw-col-span-12">
                        <v-autocomplete
                            ref="users"
                            v-model="form.user_id"
                            :items="formUsers"
                            label="Añade invitado"
                            item-text="fullname"
                            item-value="id"
                            flat
                            single-line
                            hide-details
                            rounded
                            outlined
                            dense
                        />
                    </div>
                    <!-- Start and End DateTime -->
                    <div class="tw-col-span-12">
                        <label class="tw-block tw-pb-2 tw-ml-2 tw-text-gray-400">
                            {{ $t('app.start_time') }}
                        </label>
                        <div class="tw-grid tw-grid-cols-2 tw-gap-3">
                            <v-menu v-model="date_menu_start" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                <template #activator="{ on, attrs }">
                                    <v-text-field v-model="form.start_date" label="Fecha" append-icon="event" flat single-line hide-details rounded readonly outlined dense v-bind="attrs" v-on="on" />
                                </template>
                                <v-date-picker v-model="form.start_date" no-title scrollable class="taypa-radius" />
                            </v-menu>
                            <v-text-field v-model="form.start_time" label="Hora" class="tw-col-span-2 lg:tw-col-span-1" value="09:00:00" type="time" flat single-line hide-details rounded outlined dense />
                        </div>
                    </div>
                    <div class="tw-col-span-12">
                        <label class="tw-block tw-pb-2 tw-ml-2 tw-text-gray-400">
                            {{ $t('app.end_time') }}
                        </label>
                        <div class="tw-grid tw-grid-cols-2 tw-gap-3">
                            <v-menu v-model="date_menu_end" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                <template #activator="{ on, attrs }">
                                    <v-text-field v-model="form.end_date" label="Fecha" :min="form.start_date" append-icon="event" flat single-line hide-details rounded readonly outlined dense v-bind="attrs" v-on="on" />
                                </template>
                                <v-date-picker v-model="form.end_date" no-title scrollable class="taypa-radius" />
                            </v-menu>
                            <v-text-field v-model="form.end_time" label="Hora" class="tw-col-span-2 lg:tw-col-span-1" value="09:00:00" type="time" flat single-line hide-details rounded outlined dense />
                        </div>
                    </div>
                    <div class="tw-col-span-12">
                        <label class="tw-block tw-pb-2 tw-ml-2 tw-text-gray-400">
                            {{ $t('admin.app.tz') }}
                        </label>
                        <TimezonesAutocomplete v-model="form.time_bracket_tz" :rules="[rules.required]" flat single-line hide-details dense />
                    </div>
                    <!-- Color Event -->
                    <div class="tw-grid tw-mt-4 tw-gap-2">
                        <div>
                            <div class="tw-flex tw-items-center tw-relative">
                                <v-icon>event</v-icon>
                                <span class="tw-ml-2 tw-mr-4">{{ fullname }}</span>
                                <v-menu
                                    v-model="event_color_modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-x
                                    class="tw-relative"
                                >
                                    <template #activator="{ on, attrs }">
                                        <v-btn
                                            tyoe="button"
                                            class="tw-overflow-hidden tw-relative tw-rounded-full tw-p-0 bz2-btn-color"
                                            v-bind="attrs"
                                            :style="{backgroundColor: form.color_appointment}"
                                            v-on="on"
                                        />
                                    </template>
                                    <div class="tw-bg-white">
                                        <v-color-picker
                                            v-model="form.color_appointment"
                                            class="ma-2"
                                            dot-size="30"
                                            hide-mode-switch
                                            mode="hexa"
                                        />
                                    </div>
                                </v-menu>
                            </div>
                        </div>
                        <!-- Visibility -->
                        <div>
                            <div class="tw-flex tw-items-center">
                                <v-icon>lock</v-icon>
                                <v-select
                                    v-model="form.visibility_appointment"
                                    :items="visibility_appointment_items"
                                    solo flat single-line hide-details dense 
                                    class="tw-text-sm"
                                />
                            </div>
                        </div>
                        <!-- Notification Gmail or Platform -->
                        <div>
                            <div class="tw-flex tw-items-center">
                                <v-icon>notifications</v-icon>
                                <v-select
                                    v-model="form.notify_type_appointment"
                                    :items="notify_type_appointment_items"
                                    solo flat single-line hide-details dense 
                                    class="tw-text-sm"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <AppBtn
                    type="button"
                    color="transparent"
                    :disabled="is_loading"
                    @click="closeCreate"
                >
                    {{ $t('app.cancel') }}
                </AppBtn>
                <AppBtn type="submit" :loading="is_loading" color="primary">
                    {{ $t('app.save') }}
                </AppBtn>
            </v-card-actions>
        </v-card>
    </v-form>
</v-dialog>
</template>

<script>
// import {
//     DurationBase,
// } from '@/models/Duration'
import ModalHeader from '@/components/ModalHeader'
import TimezonesAutocomplete from '@/components/TimezonesAutocomplete.vue'
import { rules } from '@/utils/rules'
import { mapState } from 'vuex'
import { mapFields } from '@/helpers'
import { CalendarItemBase } from '@/models/Calendar'
import AppBtn from '@/components/App/AppBtn.vue'
import { NotificationStatusKeys } from '@/enums/NotificationStatusKeys'

export default {
    name: 'EventBookAppointmentForm',
    components: {
        ModalHeader,
        TimezonesAutocomplete,
        AppBtn,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        date_menu_start: false,
        date_menu_end: false,
        rules,
        is_loading: false,
        valid: true,
        // ------------ Data Form Calendar ------------ //
        form: CalendarItemBase({}),
        type_appointment_items: [
            {value: 0, text: 'Event'},
            {value: 1, text: 'Appointment'},
        ],
        event_color_modal: false,
        visibility_appointment_items: [
            {value: 0, text: 'Público'},
            {value: 1, text: 'Privado'},
        ],
        notify_type_appointment_items: [
            {value:'five_min', text:'5 minutos antes'}, 
            {value:'ten_min', text:'10 minutos antes'}, 
            {value:'fifteen_min', text:'15 minutos antes'}, 
            {value:'thirty_min', text:'30 minutos antes'}, 
            {value:'one_hour', text:'1 hora antes'}, 
            {value:'one_day', text:'1 día antes'},
        ],
    }),
    computed: {
        open: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
        ...mapState({
            profile: (state) => state.session.user,
            registry: (state) => state.event.constants.registry,
            current_config_language_key: (state) => state.event.constants.event_configs.data.current_config_language_key,
        }),
        ...mapFields({
            fields: [
                'users',
            ],
            base: ['event', 'socket'],
            mutation: 'event/setSocket',
        }),
        currentCalendar() {
            return this.registry.calendars.find(
                (row) => row.language.name == this.current_config_language_key,
            )
        },
        fullname() {
            return `${this.profile.first_name} ${this.profile.last_name}`
        },
        formUsers() {
            return this.users.map((row) => ({
                ...row,
                fullname: `${row.first_name} ${row.last_name}`,
            })).filter((user) => user.id != this.profile.id)
        },
    },
    methods: {
        closeCreate() {
            this.open = false
        },
        create() {
            if (!this.$refs.form.validate()) {
                return
            }
            this.is_loading = true
            this.$store.dispatch('registries/storeCalendarsItemsAppointmet', {
                payload: this.form,
                calendar: this.currentCalendar.id,
            })
            .then(() => {
                this.form = CalendarItemBase({})
                this.$emit('submit-create')
            })
            .finally(() => {
                this.$store.dispatch('notifications/addNotification', {
                    // title: this.$t(`pages.event.profile.magic_link.title`),
                    title: 'Cita',
                    status: NotificationStatusKeys.success,
                    // body: this.$t(`pages.event.profile.magic_link.error`),
                    body: 'Solicitud enviada correctamente',
                })
                this.is_loading = false
                this.open = false
            })
        },
    },
}
</script>

<style lang="scss">
.bz2-scroll::-webkit-scrollbar {
    -webkit-appearance: none;
}

.bz2-scroll::-webkit-scrollbar:vertical {
    width: 6px;
}

.bz2-scroll::-webkit-scrollbar-button:increment,
.bz2-scroll::-webkit-scrollbar-button {
    display: none;
}

.bz2-scroll::-webkit-scrollbar:horizontal {
    height: 6px;
}

.bz2-scroll::-webkit-scrollbar-thumb {
    background-color: #bcbcbc;
    border-radius: 20px;
    // border: 2px solid #f1f2f3;
}

.bz2-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
}

.bz2-scroll {
    &::-webkit-scrollbar {
        width: 0px;
    }
}
.bz2-btn-color {
    height: 20px !important;
    min-width: 20px !important;
}
</style>
