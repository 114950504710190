import $axios from '@/axios-instance.js'

class RegistriesNavsServiceClass {
  baseUrl = '/api/v1/admin/registries'
  child = 'navs'

  // Create
  store(event_config, params) {
    return $axios.post(`${this.baseUrl}/${event_config}/${this.child}`, params)
  }
}
export const RegistriesNavsService = new RegistriesNavsServiceClass()
