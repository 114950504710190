<template>
  <v-dialog
    v-model="open"
    scrollable
    :width="640"
    persistent
    content-class="tw-rounded-3xl"
  >
    <v-form
      ref="form"
      v-model="valid"
      :disabled="form_loading"
      lazy-validation
      enctype="multipart/form-data"
      @submit.prevent="onSubmit"
    >
      <v-card class="tw-relative tw-rounded-3xl tw-border-0 tw-shadow-md">
        <ModalHeader @close-modal="closeModal">
          <!-- Exportar participantes -->
          {{ $t('app.attendees_actions.labels.export') }}
        </ModalHeader>
        <v-divider />
        <v-card-text class="tw-p-0">
          <v-responsive class="tw-py-0">
            <v-container class="tw-p-0" fluid>
              <dl class="tw-divide-solid sm:tw-divide-y sm:tw-divide-gray-200">
                <div
                  class="tw-py-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-py-5 sm:tw-px-6"
                >
                  <dt class="tw-text-sm tw-font-medium tw-text-gray-700">
                    {{ $t('app.attendees_actions.labels.type_export') }}
                  </dt>
                  <dd
                    class="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0"
                  >
                    <v-select
                      v-model="form.export_type"
                      :items="exportTypes"
                      validate-on-blur
                      :rules="[rules.required]"
                      outlined
                      rounded
                    />
                  </dd>
                </div>
                <div
                  v-if="form.export_type == 'bracket'"
                  class="tw-py-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-py-5 sm:tw-px-6"
                >
                  <dt class="tw-text-sm tw-font-medium tw-text-gray-700">
                    {{ $t('app.attendees_actions.labels.time_range') }}
                  </dt>
                  <dd
                    class="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0"
                  >
                    <label class="tw-block tw-pb-2 tw-text-gray-600">
                      {{ $t('app.attendees_actions.labels.start_time') }}
                    </label>
                    <div class="tw-grid tw-grid-cols-2 tw-gap-4">
                      <div>
                        <DateInput v-model="form.start_date" date-min="" />
                      </div>
                      <div>
                        <v-text-field
                          v-model="form.start_time"
                          label="Hora"
                          outlined
                          rounded
                          type="time"
                        />
                      </div>
                    </div>
                    <label class="tw-block tw-pb-2 tw-text-gray-600">
                      {{ $t('app.attendees_actions.labels.end_time') }}
                    </label>
                    <div class="tw-grid tw-grid-cols-2 tw-gap-4">
                      <div>
                        <DateInput
                          v-model="form.end_date"
                          :date-min="form.start_date"
                        />
                      </div>
                      <div>
                        <v-text-field
                          v-model="form.end_time"
                          label="Hora"
                          outlined
                          rounded
                          type="time"
                        />
                      </div>
                    </div>
                  </dd>
                </div>
                <div
                  class="tw-py-4 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-py-5 sm:tw-px-6"
                >
                  <dt class="tw-text-sm tw-font-medium tw-text-gray-700">
                    {{ $t('app.attendees_actions.labels.time_zone') }}
                  </dt>
                  <dd
                    class="tw-mt-1 tw-text-sm tw-text-gray-900 sm:tw-col-span-2 sm:tw-mt-0"
                  >
                    <TimezonesAutocomplete
                      v-model="form.tz"
                      :rules="[rules.required]"
                    />
                  </dd>
                </div>
              </dl>
            </v-container>
          </v-responsive>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <AppBtn
            type="button"
            :disabled="form_loading"
            color="transparent"
            @click="closeModal"
          >
            {{ $t('app.cancel') }}
          </AppBtn>
          <AppBtn
            type="submit"
            :loading="form_loading"
            color="green-download"
            icon="download_cloud"
          >
            {{ $t('app.export') }}
          </AppBtn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ModalHeader from '@/components/ModalHeader.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import { rules } from '@/utils/rules'
import { myTz } from '@/utils/myTz'
import TimezonesAutocomplete from '../TimezonesAutocomplete.vue'
import DateInput from '../FormInputs/DateInput.vue'
import { axiosDownloadable } from '@/utils/axiosDownloadable'

export default {
  components: {
    AppBtn,
    ModalHeader,
    TimezonesAutocomplete,
    DateInput,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    errorAlert: '',
    errorMsg: '',
    form_loading: false,
    form_disabled_state: false,
    valid: true,
    form: {
      export_type: 'all',
      start_date: '',
      start_time: '',
      end_date: '',
      end_time: '',
      tz: myTz(),
    },
    rules,
  }),
  computed: {
    exportTypes() {
      return [
        { value: 'all', text: this.$t('admin.enums.all') },
        { value: 'bracket', text: this.$t('admin.app.by_range') },
      ]
    },
    open: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    closeModal() {
      this.open = false
    },
    onSubmit() {
      this.errorAlert = false
      if (!this.$refs.form.validate()) {
        return
      }
      this.form_loading = true
      this.$store
        .dispatch('registries/exportParticipants', { payload: this.form })
        .then((res) => {
          axiosDownloadable(res, 'participants_')
        })
        .catch((err) => {
          this.$store.dispatch('notifications/addNotification', {
            title: 'Error exportando',
            body: err,
          })
        })
        .finally(() => {
          this.form_loading = false
        })
    },
  },
}
</script>

<style></style>
