<template>
    <v-container>
        <v-row class="tw-m-0 tw-p-0 mx-auto tw-max-w-5xl tw-py-3">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="">
                <v-container fluid class="tw-space-y-6 tw-pb-6">
                    <v-row>
                        <v-col cols="12" md="3" class="pt-5">
                            <FormDescription>
                                <template #title>
                                    {{ $t('app.title_description') }}
                                </template>
                                <template #copy>
                                    {{ $t('app.description_area') }}
                                </template>
                            </FormDescription>
                        </v-col>
                        <v-col cols="12" md="9">
                            <v-card class="tw-rounded-3xl tw-shadow-md tw-p-6">
                                <div class="tw-space-y-4">
                                    <v-text-field 
                                        v-model="form.title" type="text" :label="$t('app.title')"
                                        color="primary darken-1" outlined rounded hide-details />
                                    <v-select 
                                        v-model="form.type_form" :items="typesForm" item-text="label"
                                        item-value="value" color="primary darken-1" :label="$t('app.type_form.title')"
                                        outlined rounded hide-details />
                                    <v-textarea 
                                        v-model="form.description" validate-on-blur type="text" rounded
                                        auto-grow color="primary darken-1" :label="$t('app.description')" outlined
                                        hide-details />
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
            <v-col cols="12">
                <v-card class="tw-rounded-3xl tw-shadow-md">
                    <div class="tw-bg-gradient-to-r tw-from-bizzdome-terciary tw-to-bizzdome-light-brown tw-p-6">
                        <h2 class="tw-title-font tw-mb-2 tw-text-xl tw-font-medium tw-text-gray-50 sm:tw-text-2xl">
                            {{ $t('app.custom_fields') }}
                        </h2>
                        <p class="tw-mt-1 tw-max-w-xl tw-text-sm tw-text-gray-100">{{ $t('app.fields_description') }}
                        </p>
                        <AppBtn icon="plus" color="white" @click="addField">
                            {{ $t('app.add_field') }}
                        </AppBtn>
                    </div>
                    <div class="tw-bg-white tw-p-6">
                        <template v-if="form.fields && form.fields.length > 0">
                            <draggable 
                                v-model="form.fields" tag="div"
                                class="tw-overflow-hidden tw-bg-white tw-shadow-none" :move="onMove"
                                @start="isDragging = true" @end="isDragging = false">
                                <transition-group 
                                    type="transition" :name="'flip-list'" tag="ul"
                                    class="tw-divide-y tw-divide-gray-200 tw-pl-0">
                                    <li v-for="row in form.fields" :key="row.uid">
                                        <div class="tw-flex tw-items-center tw-justify-between tw-py-3">
                                            <div>
                                                <span class="tw-text-gray-500">{{ row.label }}</span>
                                                <div class="tw-flex tw-text-xs tw-items-center">
                                                    <v-icon class="tw-mr-1.5 tw-text-[14px]">
                                                        {{ look_up_fields[row.field_type].icon }}
                                                    </v-icon>
                                                    <p class="tw-mb-0">
                                                        {{ look_up_fields[row.field_type].text }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="tw-flex tw-space-x-2">
                                                <AppBtn 
                                                    icon="pencil_edit" 
                                                    color="transparent" 
                                                    small
                                                    @click="updateItem(row)"
                                                >
                                                    {{ $t('app.edit') }}
                                                </AppBtn>
                                                <AppBtn 
                                                    icon="close" 
                                                    color="outlined-danger-dark" 
                                                    :loading="isLoading"
                                                    small
                                                    @click="deleteItem(row)" 
                                                >
                                                    {{ $t('app.delete') }}
                                                </AppBtn>
                                            </div>
                                        </div>
                                    </li>
                                </transition-group>
                            </draggable>
                        </template>
                        <template v-else>
                            <EmptyState>
                                {{ $t('$vuetify.noDataText') }}
                            </EmptyState>
                        </template>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <CustomFormInputModal 
            v-if="customform_input_modal" 
            v-model="customform_input_modal" 
            :form-input="selectedField"
            :type-form="form.type_form"
            @updated-form-input="updateFormItem" 
        />
    </v-container>
</template>
<script>
import AppBtn from '@/components/App/AppBtn.vue'
import FormDescription from '@/components/FormDescription.vue'
import EmptyState from '@/components/EmptyState.vue'
import CustomFormInputModal from '@/components/Forms/CustomFormInputModal.vue'
import { CustomFormInputBase, LookUpFields } from '@/models/CustomForm'
import draggable from 'vuedraggable'
import { mapState } from 'vuex'

export default {
    name: 'CustomFormsBase',
    components: {
        AppBtn,
        FormDescription,
        EmptyState,
        CustomFormInputModal,
        draggable,
    },
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        customform_input_modal: false,
        look_up_fields: { ...LookUpFields },
        isLoading: false,
    }),
    computed: {
        ...mapState({
            registry: (state) => state.registries.show.data,
        }),
        typesForm() {
            return [
                { value: 'single', label: this.$t('app.type_form.single') },
                { value: 'bookings', label: this.$t('app.type_form.bookings') },
                { value: 'evaluation', label: this.$t('app.type_form.evaluation') },
                { value: 'surveys', label: this.$t('app.type_form.surveys') },
            ]
        },
        nav() {
            return [
                {
                    name: this.$t('admin.home.labels.forms'),
                    route: {
                        name: 'admin.forms.index',
                    },
                },
                {
                    name: this.$t('app.create'),
                    route: {},
                },
            ]
        },
        form: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue)
            },
        },
    },
    methods: {
        onMove({ relatedContext, draggedContext }) {
            const relatedElement = relatedContext.element
            const draggedElement = draggedContext.element
            return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
        },
        addField() {
            this.selectedField = CustomFormInputBase({})
            this.customform_input_modal = true
        },
        validateForm() {
            if (!this.form.title || !this.form.type_form) {
                return false
            }
            return true
        },
        updateFormItem(field) {
            if (this.form.fields.find((r) => r.uid === field.uid)) {
                this.form.fields = this.form.fields.map((row) =>
                    row.uid === field.uid ? field : row,
                )
                return
            }
            this.form.fields = [...this.form.fields, field]
            console.log('update', this.form.fields)
        },
        updateItem(field) {
            this.selectedField = field
            this.customform_input_modal = true
        },
        deleteItem(field) {
            this.isLoading = true
            this.form.fields = this.form.fields.filter((n) => n.uid !== field.uid)
            this.isLoading = false
        },
        submitCreateForm() {
            if (!this.validateForm()) {
                return
            }
            this.$store
                .dispatch('forms/store', { 
                    payload: { 
                        ...this.form, 
                        registry_id: this.registry.id,
                    },
                })
                .then(() => {
                    this.$router.push({ name: 'admin.registries.custom-forms.index' })
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        submitUpdateForm() {
            if (!this.validateForm()) {
                return
            }
            this.$store
                .dispatch('forms/update', { 
                    payload: { 
                        ...this.form, 
                        registry_id: this.registry.id,
                    },
                })
                .then(() => {
                    // this.$router.push({ name: 'admin.forms.index' })
                })
                .catch((err) => {
                    console.error(err)
                })
        },
    },
}
</script>